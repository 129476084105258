import { Stack, Text, Modal } from '@fluentui/react';
import React from 'react';

import PhaseReviewPlayerColumn from './PhaseReviewPlayerColumn';
import Button from '../../Global/Button/Button';
import getFontVariant, { TextComponent } from '../../Global/Text/getFontVariant';

import type { AppliedDataEntity, AppliedDataEntityType } from '../../../lib/multiplayer/schemas';

interface IPhaseReviewDataModalProps {
  isOpen: boolean;
  heading: string;
  description: string;
  buttonLabel: string;
  playerData: Record<string, AppliedDataEntity[]>;
  entityType: AppliedDataEntityType;
  onClick?: () => void;
}

const PhaseReviewDataModal: React.FC<IPhaseReviewDataModalProps> = ({
  isOpen,
  heading,
  description,
  buttonLabel,
  playerData,
  entityType,
  onClick,
}) => {
  return (
    <Modal isOpen={isOpen} isBlocking styles={{ main: { maxWidth: '1040px' } }}>
      <Stack tokens={{ padding: '40px', childrenGap: '8px' }}>
        <Stack.Item>
          <Text variant={getFontVariant(TextComponent.enum.Heading, 2)}>{heading}</Text>
        </Stack.Item>
        <Stack.Item>
          <Text variant={getFontVariant(TextComponent.enum.Body, 1)}>{description}</Text>
        </Stack.Item>

        <Stack horizontal horizontalAlign="center" tokens={{ padding: '32px', childrenGap: '32px' }}>
          {Object.entries(playerData).map(([username, data]) => (
            <PhaseReviewPlayerColumn key={username} username={username} type={entityType} data={data} />
          ))}
        </Stack>
        <Stack.Item align="end">
          <Button isPrimary label={buttonLabel} onClick={onClick} />
        </Stack.Item>
      </Stack>
    </Modal>
  );
};

export default PhaseReviewDataModal;
