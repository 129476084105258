import { isEqual } from 'lodash';
/* eslint-disable @typescript-eslint/no-use-before-define */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { batch, shallowEqual } from 'react-redux';
import type { PayloadAction } from '@reduxjs/toolkit';

import { appliedDataAdapter, applyDataCR } from './applied-data';
import {
  getDefaultUserStatusEventParameters,
  ClientEventUserStatus,
  ClientEventRoomInfo,
  UserData,
  ClientEventStartLead,
  UserInConsensus,
  ClientEventUpdateScores,
  ClientEventUpdateConsensus,
  LeadInfo,
  ClientEventGameUpdate,
} from '../../lib/socket/schemas';

import { APIManager } from '../../lib/api/APIManager';
import { actions as userSliceAction, selectAllUsers, selectUserIsRegistered } from '../user-slice';
import {
  AppliedDataEntity,
  ApplyDataPayload,
  Consensus,
  ConsensusBasics,
  ConsensusKeys,
  ConsensusStatus,
  ConsensusUpdatePayload,
  NILAnswer,
  NoAnswer,
  UserConsensus,
} from '../../lib/multiplayer/schemas';
import { coerceArray, devLog } from '../../lib/util';
import { ClientEmitCallback, DefaultEmitInput } from '../../lib/socket/typed-events';
import { ArrayOrSingle, SafeDictionary } from '../../lib/types';
import { errorCodes } from '../../static/error-codes';
import { gameData } from '../../static/game-data';
import { addPhaseScore, addPlayerScore, ScoreUpdate, ScoreUpdateWithId } from '../game-slice';
import type { ThunkApiConfig, RootState, AppDispatch } from '..';
import type { PhaseData } from '../../lib/game-data/phase-data';
import { EpisodeData } from '../../lib/game-data/episode-data';
import { GameData } from '../../lib/game-data/game-data';

/**
 *
 * SECTION: Socket.IO actions.
 *
 * NOTE: These currently can't all be moved into a separate file, since some
 * of them use the slice's `action` object below to dispatch new actions. It's
 * not clear how to untie this circular dependency.
 */

/** Action: Emit Socket.IO 'chat:send' event. */
export const emitChatMessage = createAsyncThunk<void, string, ThunkApiConfig>(
  'multiplayer/chat:send',
  async (message, thunkApi) => thunkApi.extra.emitPromise('chat:send', { message }, () => {}),
);

/** Action: Emit Socket.IO 'status:room' event. */
export const emitRoomStatus = createAsyncThunk<void, string, ThunkApiConfig>(
  'multiplayer/status:room',
  (room, { extra }) => extra.emitPromise('status:room', { room }, () => {}),
);

/** Action: Emit Socket.IO 'status:user' event. */
export const emitUserStatus = createAsyncThunk<void, undefined, ThunkApiConfig>(
  'multiplayer/status:user',
  (message, thunkApi) =>
    thunkApi.extra.emitPromise('status:user', (result) => {
      const args = ClientEventUserStatus.safeParse(result?.data);
      if (!args.success) {
        throw new Error(`ClientEventUserStatus validation error: ${args.error.message}`);
      }
      const newAction = actions.updateUserStatus(args.data);
      thunkApi.dispatch(newAction);
    }),
);

/** Action: Emit Socket.IO 'login:user' event. */
export const emitLoginUser = createAsyncThunk<void, undefined, ThunkApiConfig>(
  'multiplayer/login:user',
  (message, thunkApi) => {
    const { username, key, event_slug } = APIManager.getConfig();
    thunkApi.extra.emitPromise('login:user', { username, key, slug: event_slug }, (result) => {
      const args = ClientEventRoomInfo.safeParse(result?.data);
      if (!args.success) {
        throw new Error(`ClientEventUserStatus validation error: ${args.error.message}`);
      }

      const { status } = args.data.event;
      const { session_slug, need_for_update } = args.data.user;
      batch(() => {
        thunkApi.extra.gameState(status);
        if (session_slug) {
          thunkApi.extra.dispatch(userSliceAction.setUserValues({ session_slug }));
        }
        if (need_for_update) {
          thunkApi.extra.dispatch(emitGetGameUpdate());
        }
      });
    });
  },
);

/** Action: Emit Socket>IO 'update:user' event */
export const emitUserUpdate = createAsyncThunk<void, UserData, ThunkApiConfig>(
  'multiplayer/update:user',
  (userData, thunkApi) => {
    const { username, event_slug } = APIManager.getConfig();
    // const { session_slug } = thunkApi.getState().user;
    thunkApi.extra.emitPromise('update:user', { username, slug: event_slug, ...userData }, () => {});
  },
);

/** Action: Emit 'multiplayer/leadscore:update:game' event. Contains score data (lead) */
export const emitLeadScoreUpdate = createAsyncThunk<void, ScoreUpdateWithId, ThunkApiConfig>(
  'multiplayer/leadscore:update:game',
  (scoreData, thunkApi) => {
    const addPlayerScoreAction = addPlayerScore(scoreData);
    thunkApi.dispatch(addPlayerScoreAction);

    const leadScores = thunkApi.getState().game.leadScores;

    thunkApi.extra.emitPromise('score:update:game', { leadScores }, () => {});
  },
);

/** Action: Emit 'multiplayer/leadscoreWithoutUpdate:update:game' event. Update lead score for hyrbid leads without adding score at the end */
export const emitLeadScoreWithoutScoreUpdate = createAsyncThunk<void, ScoreUpdateWithId, ThunkApiConfig>(
  'multiplayer/leadscoreWithoutUpdate:update:game',
  (scoreData, thunkApi) => {
    const leadScores = thunkApi.getState().game.leadScores;

    thunkApi.extra.emitPromise('score:update:game', { leadScores }, () => {});
  },
);

/** Action: Emit 'multiplayer/phasescore:update:game' event. Contains score data (phase) */
export const emitPhaseScoreUpdate = createAsyncThunk<void, ScoreUpdate, ThunkApiConfig>(
  'multiplayer/phasescore:update:game',
  (scoreData, thunkApi) => {
    // updating local redux in the game slice
    // multiplayer slice also contains leadscore
    const addPhaseScoreAction = addPhaseScore(scoreData);
    thunkApi.dispatch(addPhaseScoreAction);

    const teamScores = thunkApi.getState().game.phaseScores;

    thunkApi.extra.emitPromise('score:update:game', { teamScores }, () => {});
  },
);

/** Action: Emit Socket.IO 'early:kick:user' event. kicking a user that logged in early */
export const emitKickEarlyUser = createAsyncThunk<void, ClientEmitCallback, ThunkApiConfig>(
  'multiplayer/early:kick:user',
  (emitCallback, thunkApi) => {
    const { username, event_slug } = APIManager.getConfig();

    thunkApi.extra.emitPromise('early:kick:user', { username, slug: event_slug }, (result) => {
      if (result?.status === 'success') {
        emitCallback();
      }
    });
  },
);

/** Action: Emit Socket.IO 'update:game' event. */
export const emitGameUpdate = createAsyncThunk<
  void,
  DefaultEmitInput<AppliedDataEntity[], AppliedDataEntity[]>,
  ThunkApiConfig
>('multiplayer/update:game', (appliedEntity, thunkApi) => {
  const { session_slug } = thunkApi.getState().user;
  thunkApi.extra.emitPromise('update:game', { session_slug, data: appliedEntity.data }, (result) => {
    if (result?.status !== 'success') {
      if (result?.code === errorCodes.game['cards-alread-applied']) {
        const exsitedCards = ClientEventGameUpdate.safeParse(result.data);
        if (exsitedCards.success) {
          appliedEntity.cb?.call(undefined, exsitedCards.data.applied_entities as AppliedDataEntity[]);
        }
      }
      return;
    }

    appliedEntity.cb?.call(undefined, result.data.applied_entities as AppliedDataEntity[]);
  });
});

/** Action: Emit Socket.IO 'get:update:game' event. When the user recives a need for update in the login. */
export const emitGetGameUpdate = createAsyncThunk<void, undefined, ThunkApiConfig>(
  'multiplayer/get:update:game',
  (message, thunkApi) => {
    const { session_slug } = thunkApi.getState().user;
    const { event_slug } = APIManager.getConfig();
    thunkApi.extra.emitPromise('get:update:game', { slug: event_slug, session_slug }, (result) => {
      if (result?.status !== 'success') {
        return;
      }

      const { leads_info, all_applied_entities, consensuses, current_phase_id, scores } = result.data;

      if (leads_info) {
        thunkApi.dispatch(actions.replaceLeadsInfo(leads_info));
      }

      if (all_applied_entities) {
        thunkApi.dispatch(
          actions.applyData({
            options: { fromSocket: true },
            data: all_applied_entities as AppliedDataEntity[],
          }),
        );
      }

      if (consensuses) {
        thunkApi.dispatch(actions.updateConsensuses(consensuses));
      }

      if (current_phase_id) {
        thunkApi.dispatch(actions.setCurrentPhase(current_phase_id));
      }

      if (scores) {
        thunkApi.dispatch(actions.setScore(scores));
      }
    });
  },
);

export const emitStartLead = createAsyncThunk<void, string, ThunkApiConfig>(
  'multiplayer/start:update:game',
  (leadId, thunkApi) => {
    const state = thunkApi.getState();

    const onSuccess = () => {
      batch(() => {
        thunkApi.dispatch(actions.updateLeadsInfo({ leadId, ownerId: state.user.username }));
        thunkApi.extra.gameStateSend({ type: 'CHOOSE.LEAD', leadId });

        // Create the initial leadScore entry in the game-slice. Ensures a user who scores 0 overall will emit
        // the proper score-update
        const addPlayerScoreAction = addPlayerScore({
          id: leadId,
          userId: state.user.username,
          amount: 0,
        });

        thunkApi.dispatch(addPlayerScoreAction);
      });
    };

    // If user is not registered, assume local play and skip server-side validation.
    if (!selectUserIsRegistered(state)) {
      devLog('emitStartLead: user not registered, assuming local');
      onSuccess();
      return;
    }

    const { session_slug } = state.user;
    const { event_slug } = APIManager.getConfig();
    const startLeadEmit = () =>
      thunkApi.extra.emitPromise('start:update:game', { slug: event_slug, session_slug, leadId }, (result) => {
        if (result?.status !== 'success') {
          // code 300 means the lead was selected before
          if (result?.code === errorCodes.game['lead-already-started']) {
            const leadOwner = ClientEventStartLead.safeParse(result.data);
            if (leadOwner.success) {
              if (leadOwner.data) thunkApi.dispatch(actions.updateLeadsInfo(leadOwner.data));
            }
            // TODO: show show an error alert
            return;
          }
        }
        onSuccess();
      });

    // abandon the lead they have locked first
    const { leads } = state.multiplayer;
    const lockedLeadIds = leads
      .filter((userLead) => userLead.ownerId === state.user.username && userLead.status === 'started')
      .map((userLead) => userLead.leadId);
    if (!lockedLeadIds || lockedLeadIds.length < 1) {
      startLeadEmit();
    } else {
      thunkApi.dispatch(emitAbandonUpdate({ data: lockedLeadIds, cb: startLeadEmit }));
    }
  },
);

/** Action: Emit Socket.IO 'send:update:game' event. sending the updated data to a user in need of update */
export const emitAbandonUpdate = createAsyncThunk<void, DefaultEmitInput<ArrayOrSingle<string>>, ThunkApiConfig>(
  'multiplayer/abandon:update:game',
  (emitInput, thunkApi) => {
    const state = thunkApi.getState();
    const { data, cb } = emitInput;

    const onSuccess = () => {
      batch(() => {
        thunkApi.dispatch(actions.abandonLead(data));
      });
      cb?.call(undefined);
    };

    // If user is not registered, assume local play and skip server-side validation.
    if (!selectUserIsRegistered(state)) {
      devLog('emitAbandonUpdate: user not registered, assuming local');
      onSuccess();
      return;
    }

    const { session_slug } = state.user;
    const { event_slug } = APIManager.getConfig();
    thunkApi.extra.emitPromise('abandon:update:game', { slug: event_slug, session_slug, leadId: data }, (result) => {
      if (result?.status !== 'success') {
        return;
      }
      onSuccess();
    });
  },
);

export const emitCompleteLead = createAsyncThunk<void, string, ThunkApiConfig>(
  'multiplayer/complete:update:game',
  (leadId, thunkApi) => {
    const state = thunkApi.getState();

    const { session_slug } = state.user;
    thunkApi.extra.emitPromise('complete:update:game', { session_slug, leadId }, (result) => {
      if (result?.status === 'success') {
        thunkApi.dispatch(actions.completeLead(leadId));
      }
    });
  },
);

export const emitCurrentPhase = createAsyncThunk<void, DefaultEmitInput<string, string>, ThunkApiConfig>(
  'multiplayer/phase:update:game',
  (phaseInput, thunkApi) => {
    const state = thunkApi.getState();
    const { event_slug } = APIManager.getConfig();
    const { cb, data } = phaseInput;
    const { session_slug } = state.user;

    // If user is not registered, assume local play and skip server-side validation.
    if (!selectUserIsRegistered(state)) {
      devLog('applyData: user not registered, assuming local');

      cb?.call(undefined, data);
      return;
    }

    thunkApi.extra.emitPromise(
      'phase:update:game',
      { session_slug, slug: event_slug, currentPhaseId: data },
      (result) => {
        if (result?.status === 'success') {
          cb?.call(undefined, data);
        }
      },
    );
  },
);

export const emitConsensusUpdate = createAsyncThunk<void, DefaultEmitInput<ConsensusUpdatePayload>, ThunkApiConfig>(
  'multiplayer/consensus:update:game',
  (emitInput, thunkApi) => {
    const state = thunkApi.getState();
    const { session_slug } = state.user;
    const { event_slug } = APIManager.getConfig();
    const { data } = emitInput;
    const { action, condition, ...serverData } = data;

    if (!selectUserIsRegistered(state)) {
      action?.call(undefined);
      return;
    }

    handleNewConsensus(thunkApi, data);
    thunkApi.extra.emitPromise(
      'consensus:update:game',
      { slug: event_slug, session_slug, data: serverData },
      (result) => {
        if (result?.status !== 'success') {
          return;
        }
        const args = ClientEventUpdateConsensus.safeParse(result?.data);
        if (!args.success) {
          throw new Error(`ClientEventUpdateConsensus validation error: ${args.error.message}`);
        }
        const userLastVote = selectAllUsersConsensus(thunkApi.getState()).localUserConsensuses.find(
          (consensus) => consensus.key === data.key,
        );
        handleNewConsensus(
          thunkApi,
          userLastVote ? (userLastVote as ConsensusUpdatePayload) : data,
          args.data.activeUsers,
        );
      },
    );
  },
);

export const emitAnswerHistory = createAsyncThunk<void, DefaultEmitInput<string>, ThunkApiConfig>(
  'multiplayer/consensus:update:game',
  (emitInput, thunkApi) => {
    const state = thunkApi.getState();
    const { session_slug } = state.user;
    const { data } = emitInput;
    const { event_slug } = APIManager.getConfig();
    const userVote = selectAllUsersConsensus(state).localUserConsensuses.find((vote) => vote.key === data);
    if (!userVote) return;

    const { status, action, condition, ...serverData } = userVote;

    thunkApi.extra.emitPromise(
      'history:consensus:update:game',
      { session_slug, slug: event_slug, data: serverData },
      () => {},
    );
  },
);

export const applyData = createAsyncThunk<void, ApplyDataPayload, ThunkApiConfig>(
  'multiplayer/applyData',
  (payload, thunkApi) => {
    const entities = coerceArray('data' in payload ? payload.data : payload);
    const options = 'options' in payload ? payload.options : undefined;
    const state = thunkApi.getState();
    // If user is not registered, assume local play and skip server-side validation.
    if (!selectUserIsRegistered(state)) {
      devLog('applyData: user not registered, assuming local');
      // console.log('apply data done');

      thunkApi.dispatch(applyDataAction(payload));
      return;
    }

    batch(() => {
      if (!options?.fromSocket) {
        thunkApi.dispatch(
          emitGameUpdate({
            data: entities,
            cb: (result: AppliedDataEntity[]) => {
              thunkApi.dispatch(applyDataAction(result));
            },
          }),
        );

        if (state.game.currentLeadId) {
          thunkApi.dispatch(actions.emitCompleteLead(state.game.currentLeadId));
        }
      } else {
        thunkApi.dispatch(applyDataAction(payload));
      }
    });
  },
);

/**
 *
 * SECTION: Multiplayer State/Slice
 *
 */

/**
 * Multiplayer state interface.
 */
export interface MultiplayerStateInterface {
  status: ClientEventUserStatus;
  appliedData: ReturnType<typeof appliedDataAdapter.getInitialState>;
  leads: LeadInfo[];
  currentPhaseId: string | null;
  consensuses: Consensus[];
  leadScores: SafeDictionary<MPLeadScore>;
  teamScores: SafeDictionary<number>;
}

export interface MPLeadScore {
  userId: string | null;
  score: number;
}
/**

 * Multiplayer slice state.
 */
const initialState: MultiplayerStateInterface = {
  status: getDefaultUserStatusEventParameters(),
  appliedData: appliedDataAdapter.getInitialState(),
  leads: [],
  // FIXME: This hard-coded initial value should be replaced by logic that determines the first Phase of the appropriate Episode (or similar).
  currentPhaseId: '',
  consensuses: [],
  leadScores: {},
  teamScores: {},
};

/**
 * Multiplayer slice definition
 */
export const multiplayerSlice = createSlice({
  name: 'multiplayer',
  initialState,
  reducers: {
    resetApplyData: (state) => {
      state.appliedData = appliedDataAdapter.getInitialState();
      // console.log('reset done');
    },
    updateUserStatus: (state, { payload }: PayloadAction<Partial<ClientEventUserStatus>>) => {
      Object.assign(state.status, payload);
    },
    updateLeadsInfo: (state, { payload }: PayloadAction<ArrayOrSingle<LeadInfo>>) => {
      const startedLeads = coerceArray(payload);
      startedLeads.forEach((lead) => {
        const existingLead = state.leads.find(({ leadId }) => leadId === lead.leadId);
        if (existingLead) {
          existingLead.status = 'started';
          existingLead.ownerId = lead.ownerId;
          existingLead.timestamp = lead.timestamp;
        } else state.leads.push(lead);
      });
    },
    replaceLeadsInfo: (state, { payload }: PayloadAction<LeadInfo[]>) => {
      state.leads = payload;
    },
    abandonLead: (state, { payload }: PayloadAction<ArrayOrSingle<string>>) => {
      const leadIds = coerceArray(payload);
      state.leads.forEach((lead) => {
        if (leadIds.includes(lead.leadId)) {
          // eslint-disable-next-line no-param-reassign
          lead.status = 'abandoned';
        }
      });
    },
    abandonMultipleLeads: (state, { payload }: PayloadAction<string>) => {
      state.leads
        .filter((lead) => lead.ownerId === payload)
        .forEach((lead) => {
          // eslint-disable-next-line no-param-reassign
          lead.status = 'abandoned';
        });
    },
    completeLead: (state, { payload }: PayloadAction<ArrayOrSingle<string>>) => {
      const leadIds = coerceArray(payload);
      state.leads.forEach((lead) => {
        if (leadIds.includes(lead.leadId)) {
          // eslint-disable-next-line no-param-reassign
          lead.status = 'completed';
        }
      });
    },
    applyDataAction: applyDataCR,
    setCurrentPhase: (state, { payload }: PayloadAction<string | PhaseData | null | undefined>) => {
      if (typeof payload === 'string') {
        state.currentPhaseId = gameData.get(payload, 'phase')?.id ?? payload;
      } else if (payload != null) {
        state.currentPhaseId = payload.id;
      } else {
        state.currentPhaseId = null;
      }
      return state;
    },
    addConsensus: (state, { payload }: PayloadAction<ConsensusBasics>) => {
      const { consensuses } = state;
      const consensus = consensuses.find((consAct) => consAct.key === payload.key);
      if (!consensus) {
        // add a new consensus
        const newConsensus: Consensus = { ...payload, context: [], status: 'waiting', key: payload.key };
        consensuses.push(newConsensus);
      }
    },
    updateConsensus: (state, { payload }: PayloadAction<ConsensusUpdatePayload>) => {
      const { consensuses } = state;
      const consensus = consensuses.find((consen) => consen.key === payload.key);
      if (consensus) {
        let contextIndex = -1;
        const previous = consensus.context.find((singleCont, index) => {
          if (singleCont.participantId === payload.singleContext.participantId) {
            contextIndex = index;
            return true;
          }
          return false;
        });

        if (!(previous?.answer === NILAnswer && payload.singleContext?.answer === NILAnswer)) {
          if (contextIndex > -1) {
            consensus.context.splice(contextIndex, 1);
          }

          if (previous?.answer === NILAnswer) {
            consensus.context.push({ ...payload.singleContext, triedAgain: true });
          } else if (payload.singleContext?.answer !== NoAnswer) {
            consensus.context.push({ ...payload.singleContext, triedAgain: previous?.triedAgain });
          }
        }

        consensus.action = payload.action || consensus.action;
        consensus.condition = payload.condition || consensus.condition;

        const { finalAnswer, status, allTriedAgain } = updateConsensusStatus(consensus);
        consensus.status = status;
        consensus.finalAnswer = finalAnswer;
        consensus.tryAgain = consensus.tryAgain && !allTriedAgain;
      }
    },
    updateConsensuses: (state, { payload }: PayloadAction<Consensus[]>) => {
      if (payload && payload.length > 0) {
        state.consensuses = payload;
      }
    },
    removeFromConsensus: (state, { payload }: PayloadAction<string>) => {
      const userUnfinishedConsensusesConditions = state.consensuses.filter(
        ({ condition, status }) => condition.participantIds.includes(payload) && status !== 'completed',
      );

      userUnfinishedConsensusesConditions.forEach((consensus) => {
        const { participantIds } = consensus.condition;
        const idIndex = participantIds.findIndex((participantId) => participantId === payload);
        if (idIndex > -1) {
          participantIds.splice(idIndex, 1);
          const { finalAnswer, status } = updateConsensusStatus(consensus);
          // eslint-disable-next-line no-param-reassign
          consensus.status = status;
          // eslint-disable-next-line no-param-reassign
          consensus.finalAnswer = finalAnswer;
        }
      });
    },
    resetConsensus: (state, { payload }: PayloadAction<string>) => {
      const consensus = state.consensuses.find((consen) => consen.key === payload);
      if (consensus && !consensus.tryAgain) {
        consensus.tryAgain = true;
        consensus.context.forEach((context) => {
          context.triedAgain = false;
          context.answer = NILAnswer;
        });
      }
    },
    setScore: (state, { payload }: PayloadAction<ClientEventUpdateScores>) => {
      state.leadScores = payload.leadScores;
      state.teamScores = payload.teamScores;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(emitChatMessage.fulfilled, () => {}).addCase(emitLoginUser.fulfilled, () => {});
  },
});

export const selectCurrentPhase = (state: RootState | MultiplayerStateInterface) => {
  const { currentPhaseId } = 'multiplayer' in state ? state.multiplayer : state;
  return gameData.get(currentPhaseId, 'phase');
};

export const selectCurrentEpisode = (
  state: RootState | MultiplayerStateInterface,
  devCurrentEpisode?: string,
  fallback?: string,
): EpisodeData => {
  const currentPhase = selectCurrentPhase(state);
  return gameData.get(devCurrentEpisode || currentPhase?.parent?.id || fallback, 'episode') as EpisodeData;
};

export const selectCurrentGame =
  (state: RootState | MultiplayerStateInterface, fallback?: string) : GameData => {
  const currentEpisode = selectCurrentEpisode(state);
  return gameData.get(currentEpisode?.parent?.id || fallback, 'game') as GameData;
};

export const selectConsensus = (state: RootState | MultiplayerStateInterface, key: string | ConsensusKeys) => {
  const { consensuses } = 'multiplayer' in state ? state.multiplayer : state;
  return consensuses.find((consensus) => consensus.key === key);
};

export interface SelectAllUsersConsensusResult {
  /* _All_ users consensuses (local and remote). Sorted by key. */
  allUsersConsensuses: UserConsensus[];
  /* The local user consensuses. */
  localUserConsensuses: UserConsensus[];
  /* Remote users consensuses (users in the session that aren't the local user). */
  remoteUsersConsensuses: UserConsensus[];
  /* _All_ users unfinished consensuses (local and remote). Sorted by key. */
  allUsersUnfinishedConsensuses: UserConsensus[];
  /* The local user unfinished consensuses. */
  localUserUnfinishedConsensuses: UserConsensus[];
  /* Remote users unfinished consensuses (users in the session that aren't the local user). */
  remoteUsersUnfinishedConsensuses: UserConsensus[];
  /* _All_ unfinished consensuses . */
  allUnfinishedConsensuses: Consensus[];
  /* Remote users unfinished consensuses (users in the session that aren't the local user). */
  usersWithNoVoteInConsensuses: UserInConsensus[];
}

/** Fancy memoizing selector for getting info about consensus. */
export const selectAllUsersConsensus = createSelector(
  [
    ({ user }: RootState) => user,
    ({ multiplayer }: RootState) => multiplayer.consensuses,
    ({ session }: RootState) => session.users,
  ],
  (localUser, consensuses, sessionsUsers): SelectAllUsersConsensusResult => {
    if (!consensuses) {
      return Object.freeze({
        allUsersConsensuses: [],
        localUserConsensuses: [],
        remoteUsersConsensuses: [],
        allUsersUnfinishedConsensuses: [],
        localUserUnfinishedConsensuses: [],
        remoteUsersUnfinishedConsensuses: [],
        allUnfinishedConsensuses: [],
        usersWithNoVoteInConsensuses: [],
      });
    }
    const localUserConsensuses: UserConsensus[] = [];
    const remoteUsersConsensuses: UserConsensus[] = [];
    const localUserUnfinishedConsensuses: UserConsensus[] = [];
    const remoteUsersUnfinishedConsensuses: UserConsensus[] = [];
    const allUnfinishedConsensuses: Consensus[] = [];
    const usersWithNoVoteInConsensuses: UserInConsensus[] = [];

    consensuses.forEach((consensus) => {
      const { context, condition } = consensus;

      const localUserConsensus = context.find(({ participantId }) => participantId === localUser.username);
      if (localUserConsensus && localUserConsensus.answer !== NoAnswer) {
        const singleConsensus: UserConsensus = {
          status: consensus.status,
          singleContext: localUserConsensus,
          ...getBasicConsensus(consensus),
        };
        localUserConsensuses.push(singleConsensus);

        if (consensus.status !== 'completed') {
          localUserUnfinishedConsensuses.push(singleConsensus);
        }
      }

      const remoteUsersConsensus: UserConsensus[] = context
        .filter(
          ({ participantId, answer }) =>
            participantId !== localUser.username &&
            condition.participantIds.includes(participantId) &&
            answer !== NoAnswer,
        )
        .map((singleContext) => {
          return {
            status: consensus.status,
            singleContext,
            ...getBasicConsensus(consensus),
          };
        });
      remoteUsersConsensuses.concat(remoteUsersConsensus);

      if (consensus.status !== 'completed') {
        remoteUsersUnfinishedConsensuses.concat(remoteUsersConsensuses);
        allUnfinishedConsensuses.push(consensus);

        const usersIdWithVote = context
        .filter((cont) => cont.answer !== NILAnswer && cont.answer !== NoAnswer)
        .map((cont) => cont.participantId);
        const usersIdWithNoVote = condition.participantIds.filter((cond) => !usersIdWithVote.includes(cond));
        const usersWithNoVote: UserInConsensus = {
          key: consensus.key as ConsensusKeys,
          users: sessionsUsers.filter((user) => usersIdWithNoVote.includes(user.username)),
        };
        usersWithNoVoteInConsensuses.push(usersWithNoVote);
      }
    });

    const allUsersConsensuses = [...localUserConsensuses, ...remoteUsersConsensuses];
    const allUsersUnfinishedConsensuses = [...localUserUnfinishedConsensuses, ...remoteUsersUnfinishedConsensuses];
    return Object.freeze({
      allUsersConsensuses,
      localUserConsensuses,
      remoteUsersConsensuses,
      allUsersUnfinishedConsensuses,
      localUserUnfinishedConsensuses,
      remoteUsersUnfinishedConsensuses,
      allUnfinishedConsensuses,
      usersWithNoVoteInConsensuses,
    } as const);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: (a: SelectAllUsersConsensusResult, b: SelectAllUsersConsensusResult) =>
        shallowEqual(a.allUsersConsensuses, b.allUsersConsensuses),
    },
  },
);

export const handleNewConsensus = (
  operator: { dispatch: AppDispatch; getState: () => RootState },
  data: ConsensusUpdatePayload,
  activeUsers?: string[],
) => {
  const { dispatch, getState } = operator;
  const { consensuses } = getState().multiplayer;
  const currentConsensus = consensuses.find((consensus) => consensus.key === data.key);
  if (!currentConsensus) {
    const { allUsers } = selectAllUsers(getState());
    dispatch(
      actions.addConsensus({
        key: data.key,
        action: data.action,
        condition: {
          participantIds: activeUsers || allUsers.map((user) => user.username),
          answer: data.correctAnswer || data.singleContext.answer,
        },
      }),
    );
  }

  const newConsensus = getState().multiplayer.consensuses;
  const consensusUpdated = newConsensus.find((consen) => consen.key === data.key);
  if (!consensusUpdated?.tryAgain && data.singleContext.answer === NILAnswer) {
    dispatch(actions.resetConsensus(data.key));
  }

  dispatch(
    actions.updateConsensus(
      activeUsers
        ? { ...data, condition: { answer: consensusUpdated?.condition.answer || '', participantIds: activeUsers } }
        : data,
    ),
  );
  compeletedConsensusActionCall(getState(), consensusUpdated);
};

export const handleRemoveUserFromConsensus = (
  operator: { dispatch: AppDispatch; getState: () => RootState },
  username: string,
) => {
  const { dispatch, getState } = operator;
  const { consensuses } = getState().multiplayer;
  const consensusUpdated = consensuses.filter(
    ({ condition, status }) => condition.participantIds.includes(username) && status !== 'completed',
  );
  dispatch(actions.removeFromConsensus(username));
  compeletedConsensusActionCall(getState(), consensusUpdated);
};

/**
 * Grouped export for multiplayerSlice actions (include thunk actions).
 */
export const actions = Object.freeze({
  ...multiplayerSlice.actions,
  emitChatMessage,
  emitRoomStatus,
  emitUserStatus,
  emitLoginUser,
  emitGameUpdate,
  applyData,
  emitUserUpdate,
  emitLeadScoreUpdate,
  emitLeadScoreWithoutScoreUpdate,
  emitPhaseScoreUpdate,
  emitCompleteLead,
  emitAnswerHistory,
});

/**
 * Individual export for multiplayerSlice actions (except those exported directly above).
 */

export const {
  updateUserStatus,
  applyDataAction,
  setCurrentPhase,
  updateLeadsInfo,
  replaceLeadsInfo,
  abandonMultipleLeads,
  addConsensus,
  updateConsensus,
  updateConsensuses,
  resetConsensus,
  resetApplyData,
  completeLead,
} = actions;

// Helpers
export const getLeadInfo = (state: RootState, lead: ClientEventStartLead) => {
  const { users } = state.session;
  const leadOwner = users.find((user) => user.username === lead.ownerId);
  if (!leadOwner) {
    return null;
  }

  return { ...lead, owner: leadOwner } as LeadInfo;
};

const updateConsensusStatus = (consensus: Consensus) => {
  const { condition, context } = consensus;
  const userCorrectlyVoted = [];
  if (context.length < 1) {
    return {
      status: 'waiting' as ConsensusStatus,
    };
  }
  const userAnswer = context.find((cont) => condition.participantIds.includes(cont.participantId))?.answer;
  if (userAnswer === NILAnswer || userAnswer === NoAnswer) {
    return {
      status: 'waiting' as ConsensusStatus,
    };
  }
  // Checks everyone in the condition
  condition.participantIds.forEach((participantId) => {
    const userVoted = context.find((cont) => {
      const answerCheck = Array.isArray(userAnswer) ? isEqual(userAnswer, cont.answer) : cont.answer === userAnswer;
      return cont.participantId === participantId && answerCheck;
    });
    // adds whoever answered correctly to the array
    if (userVoted && (!consensus.tryAgain || userVoted.triedAgain)) {
      userCorrectlyVoted.push(userVoted);
    }
  });

  return {
    status: (userCorrectlyVoted.length === condition.participantIds.length
      ? 'completed'
      : 'waiting') as ConsensusStatus,
    finalAnswer: userAnswer,
    allTriedAgain: !consensus.context.find((cont) => !cont.triedAgain),
  };
};

export const getUserVote = (operator: { getState: () => RootState }, usrename: string, key: string | ConsensusKeys) => {
  const { getState } = operator;
  const { user } = getState();
  const { localUserConsensuses, remoteUsersConsensuses } = selectAllUsersConsensus(getState());
  if (user.username === usrename) {
    return (localUserConsensuses as UserConsensus[])
      .filter((consensus) => consensus.key === key)
      .map((consensus) => consensus.singleContext);
  }

  return (remoteUsersConsensuses as UserConsensus[])
    .filter((consensus) => consensus.key === key && consensus.singleContext.participantId === usrename)
    .map((consensus) => consensus.singleContext);
};

export const validateUserVote = (consensusPayload: ConsensusUpdatePayload | undefined) => {
  return (
    consensusPayload != null &&
    consensusPayload !== undefined &&
    consensusPayload.action != null &&
    consensusPayload.action !== undefined
  );
};

export const getBasicConsensus = (consensus: Consensus | UserConsensus) => {
  const basicConsensus: ConsensusBasics = consensus;
  return basicConsensus;
};

export const compeletedConsensusActionCall = (state: RootState, data?: ArrayOrSingle<Consensus | undefined>) => {
  if (!data) {
    return;
  }
  const consensuses = coerceArray(data);
  const { username } = state.user;
  const updatedConsensus = consensuses.map((consensus) =>
    state.multiplayer.consensuses.find((stateConsensus) => stateConsensus.key === consensus?.key),
  );

  updatedConsensus.forEach((consensus) => {
    if (consensus) {
      if (consensus.status === 'completed') {
        consensus.action?.call(undefined, consensus.key);
      } else if (!consensus.condition.participantIds.includes(username)) {
        consensus.action?.call(undefined, consensus.key);
      }
    }
  });
};

export const getConsensusKeyPredefined = (state: RootState | MultiplayerStateInterface, key: ConsensusKeys) => {
  const { currentPhaseId } = 'multiplayer' in state ? state.multiplayer : state;
  return `${currentPhaseId}.${key}`;
};

export const getMPLeadScore = (leadscore: SafeDictionary<MPLeadScore>) => {
  const totalPlayerScore = Object.values(leadscore).reduce<SafeDictionary<number>>((accumulator, value) => {
    if (value == null || value.score == null || value.userId == null) {
      return accumulator;
    }
    if (!accumulator[value.userId]) {
      accumulator[value.userId] = value.score;
    } else {
      const currentScore = accumulator[value.userId] || 0;
      accumulator[value.userId] = value.score + currentScore;
    }
    return accumulator;
  }, {});

  return totalPlayerScore;
};

export const getMPLeadCount = (count: SafeDictionary<MPLeadScore>) => {
  const leadCount = Object.values(count).reduce<SafeDictionary<number>>((accumulator, value) => {
    if (value == null || value.score == null || value.userId == null) {
      return accumulator;
    }
    if (!accumulator[value.userId]) {
      accumulator[value.userId] = 1;
    } else {
      const currentScore = accumulator[value.userId] || 0;
      accumulator[value.userId] = 1 + currentScore;
    }
    return accumulator;
  }, {});

  return leadCount;
};

export const getMPTeamScore = (scores: SafeDictionary<number>) => {
  let teamScore = 0;

  teamScore += Object.values(scores).reduce<number>((accumulator, value) => {
    if (value != null) {
      return accumulator + value;
    }
    return accumulator;
  }, 0);

  return teamScore;
};

export const getConsensusKey = (state: RootState | MultiplayerStateInterface, key: string) => {
  const { currentPhaseId } = 'multiplayer' in state ? state.multiplayer : state;
  return `${currentPhaseId}.${key}`;
};
