import Episode01 from './Episode01';
import Episode02 from './Episode02';

/**
 * Episode01: All task components in a single namespace.
 */

export default {
  Episode01,
  Episode02,
};
