import { Stack, mergeStyles } from '@fluentui/react';
import * as React from 'react';
import Button from '../../Button/Button';
import { ScreenProps } from '../../Screen/Screen';
import { Heading, P } from '../../Text';
import Panel from '../../Panel/Panel';
import useActivityFocus from '../../../../lib/hooks/useActivityFocus';
import InteractionScreenContent from '../InteractionScreenContent/InteractionScreenContent';

interface Props extends ScreenProps {
  title: string;
  body?: string | JSX.Element;
  useDark?: boolean;
  nextLabel?: string;
  onClick: () => void;
}

export default function MultipleChoice({
  title,
  body,
  useDark = false,
  nextLabel = 'Next',
  onClick,
  backgroundColor = 'transparent',
  backgroundImage,
}: React.PropsWithChildren<Props>) {
  const { focusRef } = useActivityFocus();

  const headerStyles = mergeStyles({
    margin: '0px 4em',
  });

  return (
    <Panel useDark={useDark} gap={16}>
      <Stack className={headerStyles} verticalAlign="center" tokens={{ childrenGap: 16 }}>
        <Stack.Item align="start">
          <span ref={focusRef} tabIndex={-1}>
            <Stack tokens={{ childrenGap: 16 }}>
              <Stack.Item>
                <Heading level={4} useDark={useDark}>
                  {title}
                </Heading>
              </Stack.Item>
              {body && (
                <Stack.Item>
                  <P level={3} useDark={useDark}>
                    {body}
                  </P>
                </Stack.Item>
              )}
            </Stack>
          </span>
        </Stack.Item>
        <Stack.Item align="start">
          <Stack horizontal wrap tokens={{ childrenGap: 24 }}>
            <Button isPrimary label={nextLabel} onClick={onClick} />
          </Stack>
        </Stack.Item>
      </Stack>
      {backgroundImage && (
        <InteractionScreenContent backgroundColor={backgroundColor} backgroundImage={backgroundImage} />
      )}
    </Panel>
  );
}
