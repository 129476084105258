import React from 'react';

import _clamp from 'lodash/clamp';

/**
 * Hook for tracking state over a number of _named_ steps.
 */
export function useSteps<TStep extends string>(steps: TStep[], initialStep: number | TStep = 0) {
  // Store initial `steps` value as array.
  const stepsRef = React.useRef<ReadonlyArray<TStep>>(Object.freeze([...steps]));

  // Use `useNumSteps()` internally.
  const { currentStep, gotoNextStep, gotoPreviousStep, gotoStep } = useNumSteps(
    stepsRef.current.length,
    typeof initialStep === 'number' ? initialStep : stepsRef.current.indexOf(initialStep),
  );

  // Wrapped `gotoStep()` that accepts a named step.
  const wrappedGotoStep = React.useCallback(
    (step: TStep | number) => {
      // Pass-through numeric (index) argument.
      if (typeof step === 'number') {
        gotoStep(step);
        return;
      }
      const targetStepIndex = stepsRef.current.indexOf(step);
      if (targetStepIndex >= 0) {
        gotoStep(targetStepIndex);
      }
    },
    [gotoStep],
  );

  return {
    currentStep: stepsRef.current[currentStep],
    currentStepIndex: currentStep,
    steps: stepsRef.current,
    gotoNextStep,
    gotoPreviousStep,
    gotoStep: wrappedGotoStep,
  } as const;
}

/**
 * Hook for tracking state over a number of steps (starting at `0`).
 */
export function useNumSteps(numSteps: number, initialStepIndex: number = 0) {
  // Store initial values in refs.
  const numStepsRef = React.useRef(_clamp(Math.round(numSteps), 0, Number.MAX_SAFE_INTEGER));
  const maxIndex = numStepsRef.current - 1;

  // Store current step in state.
  const [currentStep, setStep] = React.useState(_clamp(Math.round(initialStepIndex), 0, maxIndex));

  return {
    currentStep,
    numSteps: numStepsRef.current,
    gotoNextStep: React.useCallback(() => setStep((value) => _clamp(value + 1, 0, maxIndex)), [maxIndex]),
    gotoPreviousStep: React.useCallback(() => setStep((value) => _clamp(value - 1, 0, maxIndex)), [maxIndex]),
    gotoStep: React.useCallback(
      (stepIndex: number) =>
        setStep((value) => {
          const targetIndex = Math.round(stepIndex);
          return targetIndex >= 0 && targetIndex <= maxIndex ? targetIndex : value;
        }),
      [maxIndex],
    ),
  } as const;
}
