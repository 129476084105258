import * as React from 'react';
import Screen from '../../../../../components/Global/Screen/Screen';
// import { ComponentProps } from '../interface';
import AddEvidence, { Checkbox, useAddEvidence } from '../../../../../components/Game01/AddEvidence/AddEvidence';
import { ComponentProps } from '../../../../task-types';
import Modal from '../../../../../components/Game01/Modal/Modal';
import Button from '../../../../../components/Global/Button/Button';

export default function A090({ t, send, match }: ComponentProps): JSX.Element {
  const evidence = [
    {
      id: 'one',
      title: t('A090.evidence.one.title'),
      entry: {
        id: 'Episode02.Task02.eCommerceAppCode',
        states: ['CodeOwner'],
      },
      isCritical: true,
    },
    {
      id: 'two',
      title: t('A090.evidence.two.title'),
      entry: {
        id: 'Episode02.Task02.eCommerceAppCode',
        states: ['UpdatedFiles'],
      },
    },
    {
      id: 'three',
      title: t('A090.evidence.three.title'),
      entry: {
        id: 'Episode02.Task02.DefaultASPXCSStatus',
        states: ['MaliciousShellcode'],
      },
      isCritical: true,
    },
    {
      id: 'four',
      title: t('A090.evidence.four.title'),
      entry: {
        id: 'Episode02.Task02.DefaultASPXCSStatus',
        states: ['LastUpdate'],
      },
      isCritical: true,
    },
  ];

  const { isComplete, handleToggleEvidence, saveEvidence } = useAddEvidence(evidence);

  const isFeedback = match('feedback') || match('feedbackComplete');

  const handleSubmit = () => {
    if (isComplete || isFeedback) {
      send('NEXT');
      saveEvidence();
    } else {
      send('CLICK.SUBMIT_INCORRECT');
    }
  };

  return (
    <Screen>
      <AddEvidence
        title={isFeedback ? t('A090.feedback.title') : t('A090.title')}
        description={isFeedback ? t('A090.feedback.body') : t('A090.body')}
        onSubmit={handleSubmit}
        useDark
      >
        {evidence.map((item) => (
          <Checkbox
            key={item.id}
            label={`${item.title}`}
            onChange={(_, checked?: boolean) => handleToggleEvidence(item.id, checked)}
            forceWrap
            showAnswer={isFeedback}
            isCorrect={item.isCritical}
          />
        ))}
      </AddEvidence>

      <Modal
        sidebar="character"
        title={t('A090.evidenceAdded.title')}
        body={t('A090.evidenceAdded.body')}
        isOpen={match('success') || match('feedbackComplete')}
        footer={<Button isPrimary label={t('A090.evidenceAdded.nextLabel')} onClick={() => send('NEXT')} />}
        onDismiss={() => send('NEXT')}
        hideDismissButton
      />
    </Screen>
  );
}
