import React from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, LayerHost, IStackItemStyles, mergeStyles, IStyle } from '@fluentui/react';
import EvidenceMap from '../../Game01/EvidenceMap';

/**
 * Container for LayerHosts and related content.
 */
const layerContainerStackItemStyles: IStackItemStyles = {
  root: {
    position: 'absolute',
    inset: '0px',
    pointerEvents: 'none', // Ensure layer containers are click-through.
    zIndex: 10, // Base z-index for all LayerHosts
  },
};

/**
 * Base style for LayerHosts.
 */
const layerHostBaseStyles: IStyle = {
  position: 'absolute',
  top: '0',
  left: '0',
  bottom: '0',
  right: '0',
  overflow: 'hidden',
};

/** Generate classNames for LayerHosts with different z-indexes */
const layerHostStyles = {
  panel: mergeStyles(layerHostBaseStyles, { zIndex: 2 }),
  evidenceMap: mergeStyles(layerHostBaseStyles, { zIndex: 1 }),
};

const GameLayerContainer: React.FC = () => {
  const { t } = useTranslation(['evidenceMap']); // Leaving open to other namespaces
  return (
    <Stack.Item grow={0} styles={layerContainerStackItemStyles}>
      <LayerHost id="panel-content" className={layerHostStyles.panel} />
      <LayerHost
        id="evidence-map-content"
        role="complementary"
        aria-label={t('title', { ns: 'evidenceMap' })}
        className={layerHostStyles.evidenceMap}
      />
      {/* NOTE: This and other game specific elements should be game dependant */}
      <EvidenceMap />
    </Stack.Item>
  );
};

export default GameLayerContainer;
