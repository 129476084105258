import { palette } from './colors';

const Modal = {
  styles: {
    root: {},
    main: {
      maxWidth: 800,
      display: 'flex',
      color: palette.themePrimary,
    },
    scrollableContent: {},
    layer: {},
  },
};

export default Modal;
