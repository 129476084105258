import * as React from 'react';
import { ComponentProps } from '../../../../task-types';
import Interstitial from '../../../../../components/Global/Interactions/Interstitial/Interstitial';
import background from '../images/02.04.080.png';

export default function A080({ t, send }: ComponentProps): JSX.Element {
  return (
    <Interstitial title={t('A080.body')} onClick={() => send('NEXT')} nextLabel={t('common.skipAhead')} backgroundImage={background} />
  );
}
