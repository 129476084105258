import i18n from '../../../../i18n';
import { ReviewItemDefinition } from '../../../../lib/game-data/types';

export const Phase03Review: ReviewItemDefinition[] = [
  {
    type: 'Dialog',
    title: i18n.t('Game01.Episode02.Phase03Review:dialog.intro.title'),
    body: i18n.t('Game01.Episode02.Phase03Review:dialog.intro.body'),
    nextLabel: i18n.t('common:buttons.letsGo'),
    character: 'andrea',
  },
  {
    type: 'Question',
    title: i18n.t('Game01.Episode02.Phase03Review:question1.title'),
    body: i18n.t('Game01.Episode02.Phase03Review:question1.body'),
    nextLabel: i18n.t('common:buttons.submit'),
    scoreMultiplier: 4,
    feedbackTitle: i18n.t('Game01.Episode02.Phase03Review:question1.feedbackTitle'),
    feedbackBody: i18n.t('Game01.Episode02.Phase03Review:question1.feedbackBody'),
    feedbackNextLabel: i18n.t('Game01.Episode02.Phase03Review:question1.feedbackNextLabel'),
    answers: [
      {
        text: i18n.t('Game01.Episode02.Phase03Review:question1.optionOne'),
        isCorrect: false,
      },
      {
        text: i18n.t('Game01.Episode02.Phase03Review:question1.optionTwo'),
        isCorrect: true,
      },
      {
        text: i18n.t('Game01.Episode02.Phase03Review:question1.optionThree'),
        isCorrect: false,
      },
    ],
    character: 'andrea',
  },
  {
    // proide proof
    type: 'Question',
    title: i18n.t('Game01.Episode02.Phase03Review:proof.title'),
    body: i18n.t('Game01.Episode02.Phase03Review:proof.body'),
    nextLabel: i18n.t('common:buttons.submit'),
    scoreMultiplier: 4,
    feedbackTitle: i18n.t('Game01.Episode02.Phase03Review:proof.feedbackTitle'),
    feedbackBody: i18n.t('Game01.Episode02.Phase03Review:proof.feedbackBody'),
    feedbackNextLabel: i18n.t('Game01.Episode02.Phase03Review:proof.feedbackNextLabel'),
    answers: [
      {
        text: i18n.t('Game01.Episode02.Phase03Review:proof.optionOne'),
        isCorrect: false,
        evidenceId: '',
      },
      {
        text: i18n.t('Game01.Episode02.Phase03Review:proof.optionTwo'),
        isCorrect: true,
        evidenceId: '',
      },
      {
        text: i18n.t('Game01.Episode02.Phase03Review:proof.optionThree'),
        isCorrect: false,
        evidenceId: '',
      },
      {
        text: i18n.t('Game01.Episode02.Phase03Review:proof.optionFour'),
        isCorrect: false,
        evidenceId: '',
      },
    ],
    character: 'andrea',
  },
  {
    type: 'Question',
    title: i18n.t('Game01.Episode02.Phase03Review:question2.title'),
    body: i18n.t('Game01.Episode02.Phase03Review:question2.body'),
    nextLabel: i18n.t('common:buttons.submit'),
    scoreMultiplier: 4,
    feedbackTitle: i18n.t('Game01.Episode02.Phase03Review:question2.feedbackTitle'),
    feedbackBody: i18n.t('Game01.Episode02.Phase03Review:question2.feedbackBody'),
    feedbackNextLabel: i18n.t('Game01.Episode02.Phase03Review:question2.feedbackNextLabel'),
    answers: [
      {
        text: i18n.t('Game01.Episode02.Phase03Review:question2.optionOne'),
        isCorrect: false,
      },
      {
        text: i18n.t('Game01.Episode02.Phase03Review:question2.optionTwo'),
        isCorrect: true,
      },
    ],
    character: 'andrea',
  },
  {
    type: 'Dialog',
    title: i18n.t('Game01.Episode02.Phase03Review:dialog.final.title'),
    body: i18n.t('Game01.Episode02.Phase03Review:dialog.final.body'),
    nextLabel: i18n.t('Game01.Episode02.Phase03Review:dialog.final.nextBtn'),
    character: 'andrea',
  },
];
