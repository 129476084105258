/**
 * i18next NS: `Episode01.Task01`
 */
export default {
  common: {
    errorTitle: "Try Again",
    next: "Next",
    submit: "Submit",
    start: "Start",
    skipAhead: "Skip Ahead",
    ok: "OK",
    done: "Done",
  },
  A020: {
    error: {
      1: {
        title: "Try again.",
        body: "That's not the right place.",
      },
      2: {
        title: "Try again.",
        body: "That's not right either.",
      },
      3: {
        title: "Go Here!",
        body: "I need you to go to Azure Monitor.",
      },
      4: {
        title: "You are in the right area.",
        body: "Let's skip ahead and go straight to Azure Monitor.",
      },
    },
  },
  A031: {
    introModalHeading: "Before you talk to Wenny, let’s gather your notes to make sure you are prepared.",
    introModalBody: "Click NEXT to look at the evidence.",
    collectedcardModalHeading: "You completed the following evidence:",
    collectedcardModalDescription: "This evidence is already documented in your journal. Click NEXT when you’re done reviewing.",
    collectedcardAlternateModalHeading: "You didn't complete any evidence",
    collectedcardAlternateModalDescription: "Try to find and document as much evidence as you can! Remember: the more evidence you find, the better your final score.",
    uncollectedcardModalHeading: "Here’s the evidence you missed:",
    uncollectedcardDescription: "Click ADD EVIDENCE to document all outstanding evidence in your journal.",
    next: "NEXT",
    addEvidence: "ADD EVIDENCE",
  },
  A035: {
    title: "Now, let’s talk to Wenny",
    body: "Why did Wenny grant access to the OAuth app - do you think she did it maliciously?",
  },
  A040: {
    title: "(startled) \"Oh, hello. Can I help you with something?\"",
    body: "WENNY:",
    options: {
      1: {
        label: "\"Can I ask you a question?\"",
      },
      2: {
        label: "\"You seem stressed.\"",
      },
    },
  },
  A050: {
    title: "WENNY:",
    body: "“Sure. But I’m under a really tight deadline so can you make it quick?”",
    nextLabel: "\"Why did you grant access to BFYOVacationTool?\"",
  },
  A060: {
    title: "WENNY:",
    body: "“Well yeah, I’m under a really tight deadline so what’s up?”",
    nextLabel: "\"Why did you grant access to BFYOVacationTool?\"",
  },
  A070: {
    title: "WENNY:",
    body: "“You don't have to rub it in. I know—total rookie mistake! I.T. has already slapped my wrist for clicking that link. My only defense is wishful thinking. I thought having a vacation planner installed on my system would get me one step closer to actually having a vacation. I’m so behind on everything. You see this pile? It’s all the leftover work from the last dev. I’ll never catch up!”",
    nextLabel: "\"Thanks.\"",
  },
  A080: {
    title: "Nice work!",
    body: "You investigated Wenny's account and got some critical information by interviewing her. Now it's time to put the information you found on the Evidence Map.",
  },

};
