import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTaskMachine } from '../../../../lib/hooks/useTaskMachine';
import C020 from './components/C020';
import C030 from './components/C030';
import C034 from './components/C034';
import C036 from './components/C036';
import C040 from './components/C040';
import C060 from './components/C060';
import C070 from './components/C070';
import C080 from './components/C080';
import { createTask03 } from './machine';

const baseNS = 'Game01.Episode02.Task03';
const taskKey = 'Game01.Episode02.Task03';

function createTaskMatch(parent: string, state: any) {
  return (matchState: string) => state.matches(`${parent}.${matchState}`);
}

const Task03: React.FC = () => {
  const { t } = useTranslation([baseNS, 'common']);

  // Setup task machine
  const [state, send] = useTaskMachine(createTask03, {
    taskKey,
  });

  return (
    <>
      {state.matches('C020') && <C020 t={t} send={send} state={state.context} match={createTaskMatch('C020', state)} />}
      {state.matches('C030') && <C030 t={t} send={send} state={state.context} match={createTaskMatch('C030', state)} />}
      {state.matches('C034') && <C034 t={t} send={send} state={state.context} match={createTaskMatch('C034', state)} />}
      {state.matches('C036') && <C036 t={t} send={send} state={state.context} match={createTaskMatch('C036', state)} />}
      {state.matches('C040') && <C040 t={t} send={send} state={state.context} match={createTaskMatch('C040', state)} />}
      {state.matches('C060') && <C060 t={t} send={send} state={state.context} match={createTaskMatch('C060', state)} />}
      {state.matches('C070') && <C070 t={t} send={send} state={state.context} match={createTaskMatch('C070', state)} />}
      {state.matches('C080') && <C080 t={t} send={send} state={state.context} match={createTaskMatch('C080', state)} />}
    </>
  );
};

export default Task03;
