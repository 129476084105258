import office from '../../static/images/Game01/Episode01/backgrounds/office-desk_large.jpg';

import accuseAll from '../../static/images/Game01/Episode01/backgrounds/wh_ep1_accuse_character__aat.jpg';
import accuseAmari from '../../static/images/Game01/Episode01/backgrounds/wh_ep1_accuse_character__amari2.jpg';
import accuseAngel from '../../static/images/Game01/Episode01/backgrounds/wh_ep1_accuse_character__angel2.jpg';
import accuseTomo from '../../static/images/Game01/Episode01/backgrounds/wh_ep1_accuse_character__tomo2.jpg';

import eps2AccuseAll from '../../static/images/Game01/Episode02/backgrounds/wh_ep2_accuse_all.jpg';
import eps2AccuseFrey from '../../static/images/Game01/Episode02/backgrounds/wh_ep2_accuse_frey.jpg';
import eps2AccuseHacker from '../../static/images/Game01/Episode02/backgrounds/wh_ep2_accuse_hacker.jpg';
import eps2AccuseWenny from '../../static/images/Game01/Episode02/backgrounds/wh_ep2_accuse_wenny.jpg';
import eps2AccuseAlex from '../../static/images/Game01/Episode02/backgrounds/wh_ep2_alex.jpg';
import eps2AccuseQuinn from '../../static/images/Game01/Episode02/backgrounds/wh_ep2_quinn.jpg';

import summary from '../../static/images/Game01/Episode01/backgrounds/final_bg.jpg';
import desk from '../../static/images/Game01/Episode02/backgrounds/bgs.jpg';
import annoyedAndrea from '../../static/images/Game01/Episode02/backgrounds/ep2_andrea_annoyed.jpg';
import pleasedAndrea from '../../static/images/Game01/Episode02/backgrounds/ep2_andrea_pleased.jpg';

export type Backgrounds =
  'office' |
  'none' |
  'accuseAll' |
  'accuseAmari' |
  'accuseAngel' |
  'accuseTomo' |
  'eps2AccuseAll' |
  'eps2AccuseFrey' |
  'eps2AccuseHacker' |
  'eps2AccuseWenny' |
  'eps2AccuseAlex' |
  'eps2AccuseQuinn' |
  'summary' |
  'desk' |
  'annoyedAndrea' |
  'pleasedAndrea';

const backgrounds: { [key: string]: string } = {
  office,
  accuseAll,
  accuseAmari,
  accuseAngel,
  accuseTomo,
  eps2AccuseAll,
  eps2AccuseFrey,
  eps2AccuseHacker,
  eps2AccuseWenny,
  eps2AccuseAlex,
  eps2AccuseQuinn,
  summary,
  desk,
  annoyedAndrea,
  pleasedAndrea,
};

export default function useBackground(background?: string): string | undefined {
  if (!background || background === 'none') {
    return undefined;
  }

  return backgrounds[background];
}
