import React, { useEffect } from 'react';
import { Stack, IStackStyles } from '@fluentui/react';
import { isDev } from '../../../lib/util';
import GameLayerContainer from './GameLayerContainer';
import GameContent from './GameContent';
import GameSidebar from './GameSidebar';

/**
 * TODO: I've sectioned off the Dev related routes to a different component triggered by a child prop
 * so that Ideally we can control this component through other means with the Event Component
 */

/** Root stack should fill viewport. */
const rootStackStyles: IStackStyles = {
  root: {
    height: '100vh',
    minWidth: '100vw',
    overflow: 'hidden',
  },
};

/**
 * Content area fills non-navbar part of viewport.
 * LayerHosts can be positioned in this area to fill viewport without blocking navbar.
 */
const contentOuterStyles: IStackStyles = {
  root: {
    position: 'relative',
    // a11y: Enabling scroll for zoom
    overflow: 'auto',
    width: '100%',
    height: '100%',
  },
};

function swallowBackButtonAndReload() {
  // prevent doing this in dev
  if (isDev) {
    return function cleanup() {};
  }
  function preventUnload(e: BeforeUnloadEvent) {
    e.preventDefault();
    e.returnValue = '';
  }
  function preventPopState() {
    window.history.pushState(null, document.title, window.location.href);
  }
  window.addEventListener('popstate', preventPopState);
  window.addEventListener('beforeunload', preventUnload);
  return function cleanup() {
    window.removeEventListener('popstate', preventPopState);
    window.removeEventListener('beforeunload', preventUnload);
  };
}

interface IGameProps {
  useDevRoutes?: boolean;
}

const Game: React.FC<IGameProps> = ({ useDevRoutes }) => {
  useEffect(swallowBackButtonAndReload);
  return (
    <Stack horizontal styles={rootStackStyles}>
      <Stack.Item styles={contentOuterStyles}>
        <Stack grow={1}>
          <GameContent useDevRoutes={useDevRoutes} />
          <GameLayerContainer />
        </Stack>
      </Stack.Item>
      <GameSidebar />
    </Stack>
  );
};

export default Game;
