import * as React from 'react';
import { ComponentProps } from '../../../../task-types';
import Interstitial from '../../../../../components/Global/Interactions/Interstitial/Interstitial';
import background from '../images/14B.050.png';

export default function B035({ t, send }: ComponentProps): JSX.Element {
  return (
    <Interstitial
      title={t('B035.title')}
      body={t('B035.body')}
      onClick={() => send('NEXT')}
      nextLabel={t('common.next')}
      useDark
      backgroundImage={background}
    />
  );
}
