import Model1 from './010100B';
import Model2 from './010104';
import Model3 from './010201';
import Model4 from './010401';
import Start from './Start';

/**
 * Episode01: All task components in a single namespace.
 */

export default {
  'StartB': Start,
  '010100B': Model1,
  '010104': Model2,
  '010201': Model3,
  '010401': Model4,
};
