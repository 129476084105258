import * as React from 'react';
import { Dropdown as FluentDropdown, IDropdown, IDropdownStyles, IStackStyles, Stack, Theme } from '@fluentui/react';
import Hint from '../../Hint/Hint';
import { DropdownItem } from './MultipleDropdown';
import { getCurrentGameTheme } from '../../../../theme';

interface Props {
  id: string;
  label: string;
  onChange: (optionKey: number | string) => void;
  hint: string;
  // correctState: boolean;
  // disabled?: boolean;
  options: DropdownItem[];
  displayInlineFlex?: boolean;
  walkthroughCallback?: () => void;
}

const dropdownStyles = (displayInlineFlex: boolean = false, theme: Theme): Partial<IDropdownStyles> => ({
  root: {
    // display: 'flex',
    // flexDirection: 'row',
  },
  caretDownWrapper: {
    top: '50%',
    transform: 'translate(0%, -50%)',
    right: 16,
  },
  title: {
    // Todo: This seems to be the only place to target the dropdown box
    borderColor: '#d2d2d2',
    borderWidth: 1,
    padding: 12,
    borderRadius: 6,
    height: 'auto',
  },
  label: {
    color: 'white',
    fontSize: theme.fonts.xxLargePlus.fontSize,
    fontWeight: theme.fonts.xxLargePlus.fontWeight,
    lineHeight: theme.fonts.xxLargePlus.lineHeight,
    marginRight: 10,
    marginBottom: 18,
    width: '100%',
  },
  dropdownItem: {
    fontSize: theme.fonts.large.fontSize,
    fontWeight: theme.fonts.smallPlus.fontWeight,
    lineHeight: theme.fonts.smallPlus.lineHeight,
  },
  dropdown: {
    minWidth: 300,
    display: displayInlineFlex ? 'inline-flex' : 'block',
    height: 'auto',
    fontSize: theme.fonts.large.fontSize,
    fontWeight: theme.fonts.smallPlus.fontWeight,
    lineHeight: theme.fonts.smallPlus.lineHeight,
  },
});

const dropdownWrapper: IStackStyles = {
  root: [
    {
      position: 'relative',
    },
  ],
};

const Dropdown = React.forwardRef<any, Props>(
  ({ id, label, onChange, options, hint, walkthroughCallback, displayInlineFlex }, ref) => {
    const theme = getCurrentGameTheme();
    const toggleId = `toggle_${id}`;
    const dropdownRef = React.useRef<IDropdown>(null);
    // const isUnlocked = React.useRef(false);
    const [showHint, setShowHint] = React.useState(false);
    const [isDisabled, setIsDisabled] = React.useState(false);
    // const [optionIndex, setOptionIndex] = React.useState<number | null>(null);
    const [optionKey, setOptionKey] = React.useState<string | number | null>(null);

    React.useImperativeHandle(
      ref,
      () => ({
        reset: () => {
          setOptionKey(null);
        },
        disable: () => {
          setIsDisabled(true);
          setShowHint(false);
        },
        displayHint: () => {
          setIsDisabled(false);
          setShowHint(true);
          if (dropdownRef?.current) {
            dropdownRef.current.focus(true);
          }
        },
      }),
      [],
    );

    return (
      <Stack styles={dropdownWrapper} id={toggleId}>
        <FluentDropdown
          componentRef={dropdownRef}
          label={label}
          styles={dropdownStyles(displayInlineFlex, theme)}
          options={options}
          placeholder="Drop down to select"
          onChange={(event, option, index) => {
            if (index == null) {
              return;
            }
            if (option != null) {
              onChange(index);
              setOptionKey(option.key);
              // setOptionIndex(index);
            }

            if (options[index].isCorrect && showHint && walkthroughCallback) {
              // isUnlocked.current = true;
              // before we change local state, check that the option we're rolling into is correct and fire the optional walkthrough callback
              walkthroughCallback();
            }
          }}
          // errorMessage={hint}
          disabled={isDisabled}
          selectedKey={optionKey}
        />
        {showHint && <Hint target={`#${toggleId}`}>{hint}</Hint>}
      </Stack>
    );
  },
);

export default Dropdown;
