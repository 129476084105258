import * as React from 'react';
import Screen from '../../../../../components/Global/Screen/Screen';
import Modal from '../../../../../components/Game01/Modal/Modal';
import { ComponentProps } from '../../../../task-types';
import MultipleSelect, {
  Checkbox,
  useMultipleSelect,
} from '../../../../../components/Global/Interactions/MultipleSelect/MultipleSelect';
import Button from '../../../../../components/Global/Button/Button';

export default function I080({ t, send, match }: ComponentProps): JSX.Element {
  const { isCorrect, handleError, handleCorrect, noValuesChecked } = useMultipleSelect(2);

  const isFeedback = match('feedback');

  const handleSubmit = () => {
    if (isCorrect || isFeedback) {
      send('NEXT');
    } else {
      send('CLICK.SUBMIT_INCORRECT');
    }
  };

  return (
    <Screen>
      <MultipleSelect
        title={isFeedback ? t('I080.feedback.title') : t('I080.title')}
        body={isFeedback ? t('I080.feedback.body') : t('I080.body')}
        onSubmit={handleSubmit}
        submitDisabled={noValuesChecked}
        useDark
        nextLabel={isFeedback ? t('common.ok') : t('common.done')}
      >
        <Checkbox isCorrect label={t('I080.options.1.label')} onChange={handleCorrect} showAnswer={isFeedback} />
        <Checkbox label={t('I080.options.2.label')} onChange={handleError} showAnswer={isFeedback} />
        <Checkbox isCorrect label={t('I080.options.3.label')} onChange={handleCorrect} showAnswer={isFeedback} />
        <Checkbox label={t('I080.options.4.label')} onChange={handleError} showAnswer={isFeedback} />
      </MultipleSelect>
      <Modal
        sidebar="error"
        isOpen={match('incorrect')}
        footer={<Button isPrimary label={t('common.errorTitle')} onClick={() => send('NEXT')} />}
        onDismiss={() => send('NEXT')}
        title={t('I080.error.title')}
        body={t('I080.error.body')}
        hideDismissButton
      />
    </Screen>
  );
}
