import { GameDataStore } from '../../lib/game-data/game-data-store';
import { devLog, isDev, isLocalDev } from '../../lib/util';
import { Game01 } from './Game01';
import { Game02 } from './Game02';

/**
 * Static game data store.
 */
export const gameData = new GameDataStore([Game01, Game02]);

/** Test various things in dev only */
(function testGameDataQueries() {
  if (!isLocalDev || !isDev) {
    return;
  }

  devLog('GameData', gameData);

  /**
   * Check all locations, links, and unlocks for Evidence and Facts
   * (will put dev warnings in the console)
   */

  devLog('Episode01: Loading all locations, links, and unlocks for gameData Evidence and Facts...');
  gameData.values.forEach((value) => {
    if (value.kind === 'evidence' || value.kind === 'fact') {
      value.getLocation();
      value.getLinks();
      value.getUnlocks();
    }
  });
  devLog('Done!');

  // Testing gameData.get()...
  const e01 = gameData.get('Game01.Episode01', 'episode');
  if (e01 == null || e01.kind !== 'episode') {
    throw new Error('Assert Failed: Episode01');
  }
  const e01t01 = gameData.get('Game01.Episode01.Task01', 'lead');
  if (e01t01 == null || e01t01.kind !== 'lead') {
    throw new Error('Assert Failed: Episode01.Task01');
  }
  const e01p01 = gameData.get('Game01.Episode01.Phase01', 'phase');
  if (e01p01 == null || e01p01.kind !== 'phase') {
    throw new Error('Assert Failed: Episode01.Phase01');
  }

  //
  // Testing GameDataBase.find()...
  //

  // Absolute paths
  if (e01t01.find('#Game01.Episode01.Task01', 'lead') !== e01t01) {
    throw new Error("Game01.Episode01.Phase01.find('#Game01.Episode01.Task01', 'lead') !== Game01.Episode01.Task01");
  }
  if (e01p01.find('#Game01.Episode01') !== e01) {
    throw new Error("Game01.Episode01.Phase01.find('#Game01.Episode01') !== Game01.Episode01");
  }

  // Relative paths...
  if (e01p01.find('..') !== e01) {
    throw new Error("Game01.Episode01.Phase01.find('..') !== Game01.Episode01");
  }
  if (e01p01.find('../') !== e01) {
    throw new Error("Game01.Episode01.Phase01.find('../') !== Game01.Episode01");
  }
  if (e01p01.find('../Task01') !== e01t01) {
    throw new Error("Game01.Episode01.Phase01.find('../Task01') !== Game01.Episode01.Task01");
  }
  if (e01t01.find('../../Episode01') !== e01) {
    throw new Error("Game01.Episode01.Task01.find('../../Game01.Episode01') !== Game01.Episode01");
  }
  if (e01p01.find('../Task01.LeakedFileSearchResults', 'evidence')?.shortId !== 'LeakedFileSearchResults') {
    throw new Error(
      "Game01.Episode01.Phase01.find('../Task01.LeakedFileSearchResults', 'evidence').shortId !== 'LeakedFileSearchResults'",
    );
  }
  if (e01p01.find('../Task01/./LeakedFileSearchResults', 'evidence')?.shortId !== 'LeakedFileSearchResults') {
    throw new Error(
      "Game01.Episode01.Phase01.find('../Task01/./LeakedFileSearchResults', 'evidence').shortId !== 'LeakedFileSearchResults'",
    );
  }
  if (e01p01.find('../Task01.LeakedFileSearchResults/..', 'lead') !== e01t01) {
    throw new Error("Game01.Episode01.Phase01.find('../Task01.LeakedFileSearchResults/..', 'lead') !== Game01.Episode01.Task01");
  }

  // Test GameDataBase.getAllChildren()
  const e01AllChildren = e01.getAllChildren();
  if (e01AllChildren.length === 0) {
    throw new Error('Game01.Episode01.getAllChildren() is Empty!');
  }
  const e01AllChildrenFacts = e01.getAllChildren('fact');
  const e01AllChildrenFactsByFilter = e01AllChildren.filter(({ kind }) => kind === 'fact');
  if (e01AllChildrenFacts.length !== e01AllChildrenFactsByFilter.length) {
    throw new Error("Game01.Episode01.getAllChildren('fact') doesn't match Game01.Episode01.getAllChildren().filter(...)!");
  }
  if (e01AllChildrenFacts.some((child) => child.kind !== 'fact')) {
    throw new Error("Game01.Episode01.getAllChildren('fact') contains a non-fact!");
  }
})();
