import { State } from 'xstate';
import { useActor } from '@xstate/react';
import { useCallback } from 'react';

import type { EventObject, Interpreter, StateValue, Typestate } from 'xstate';

import { setServiceState } from '../../xstate/set-service-state';

/** Type used for `setState()` callback. */
export type SetStateCallback<
  TContext,
  TEvent extends EventObject,
  TTypestate extends Typestate<TContext> = { value: any; context: TContext },
> = (stateValue: StateValue | State<TContext, TEvent, any, TTypestate>, context?: Partial<TContext>) => void;

/**
 * Hook to directly provide an interpreter with state/send and a state-setting callback.
 */
export function useServiceSettable<
  TContext,
  TEvent extends EventObject,
  TTypestate extends Typestate<TContext> = { value: any; context: TContext },
>(service: Interpreter<TContext, any, TEvent, TTypestate>) {
  const [state, send] = useActor(service);

  /** Load a new state/context into the machine without stopping it. */
  const setState = useCallback<SetStateCallback<TContext, TEvent, TTypestate>>(
    (stateValue, context) => setServiceState(service, stateValue, context),
    [service],
  );

  return { state, send, service, setState } as const;
}
