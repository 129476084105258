/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState } from 'react';
import { IStackStyles, Stack } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useBoolean } from '@fluentui/react-hooks';
import { Heading } from '../../Text';
import { UserInfo } from '../schemas';
import FacilitatorUserListItem from './FacilitatorUserListItem';
import FacilitatorResolveHelpDialog from './FacilitatorResolveHelpDialog';
import FacilitatorConfirmDialog from './FacilitatorConfirmDialog';
import { getCurrentGameTheme } from '../../../../theme';

const theme = getCurrentGameTheme('Global');

interface UserListProps {
  users?: UserInfo[];
  showDisconnect: boolean;
  showLogout: boolean;
  onDisconnectUser: Function;
  onLogoutUser: Function;
  onRemoveUser: Function;
}

const panelStyles: IStackStyles = {
  root: {
    padding: 24,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    borderRadius: 5,
    boxShadow: theme.effects.elevation8,
    height: '100%',
    minHeight: 400,
    overflowY: 'auto',
    h4: {
      marginTop: 0,
      marginBottom: 12,
    },
  },
};

const setUserIntoStateAndToggle = (user: UserInfo | null, set: Function, toggle: Function) => {
  set(user);
  toggle();
};

const UserListPanel: React.FC<UserListProps> = (
  { users = [], showDisconnect = false, showLogout = false, onDisconnectUser, onLogoutUser, onRemoveUser },
) => {
  const { t } = useTranslation('common');
  const color = theme.palette.themePrimary;
  const [hideResolveHelpDialog, { toggle: toggleHelpDialog }] = useBoolean(true);
  const [userToResolve, setUserToResolve] = useState<UserInfo | null>(null);
  const [showDisconnectDialog, { toggle: toggleDisconnectDialog }] = useBoolean(false);
  const [showReleaseDialog, { toggle: toggleReleaseDialog }] = useBoolean(false);
  const [showLogoutDialog, { toggle: toggleLogoutDialog }] = useBoolean(false);
  const [showRemoveDialog, { toggle: toggleRemoveDialog }] = useBoolean(false);
  return (
    <>
      <Stack styles={panelStyles}>
        <Heading level={4} color={color}>
          {t('facilitator.users')}
        </Heading>
        <Stack.Item>
          {(users.map((u) => (
            <FacilitatorUserListItem
              key={`ulpfuli_${u.username}`}
              user={u}
              showDisconnect={showDisconnect}
              showLogout={showLogout}
              onDisconnect={() => {
                setUserIntoStateAndToggle(u, setUserToResolve, toggleDisconnectDialog);
              }}
              onRelease={() => {
                setUserIntoStateAndToggle(u, setUserToResolve, toggleReleaseDialog);
              }}
              onLogout={() => {
                setUserIntoStateAndToggle(u, setUserToResolve, toggleLogoutDialog);
              }}
              onRemove={() => {
                setUserIntoStateAndToggle(u, setUserToResolve, toggleRemoveDialog);
              }}
              onResolveHelp={() => {
                setUserIntoStateAndToggle(u, setUserToResolve, toggleHelpDialog);
              }}
            />
          )))}
        </Stack.Item>
      </Stack>
      {userToResolve !== null && !hideResolveHelpDialog && (
        <FacilitatorResolveHelpDialog
          user={userToResolve}
          hideResolveHelpDialog={hideResolveHelpDialog}
          toggleResolveHelpDialog={
            () => {
              setUserIntoStateAndToggle(null, setUserToResolve, toggleHelpDialog);
            }
          }
        />
      )}
      {showDisconnectDialog && (
        <FacilitatorConfirmDialog
          title={t('facilitator.disconnectUser')}
          subText={t('facilitator.confirmDisconnectDialogText', { name: userToResolve?.display_name ? userToResolve?.display_name : userToResolve?.username })}
          showDialog={showDisconnectDialog}
          onDismiss={() => toggleDisconnectDialog()}
          onConfirm={
            () => {
              onDisconnectUser(userToResolve?.username);
              toggleDisconnectDialog();
            }
          }
        />
      )}
      {showReleaseDialog && (
        <FacilitatorConfirmDialog
          title={t('facilitator.releaseUser')}
          subText={t('facilitator.confirmReleaseDialogText', { name: userToResolve?.display_name ? userToResolve?.display_name : userToResolve?.username })}
          showDialog={showReleaseDialog}
          onDismiss={() => toggleReleaseDialog()}
          onConfirm={
            () => {
              onDisconnectUser(userToResolve?.username);
              toggleReleaseDialog();
            }
          }
        />
      )}
      {showLogoutDialog && (
        <FacilitatorConfirmDialog
          title={t('facilitator.logoutUser')}
          subText={t('facilitator.confirmLogoutDialogText', { name: userToResolve?.display_name ? userToResolve?.display_name : userToResolve?.username })}
          showDialog={showLogoutDialog}
          onDismiss={() => toggleLogoutDialog()}
          onConfirm={
            () => {
              onLogoutUser(userToResolve?.username);
              toggleLogoutDialog();
            }
          }
        />
      )}
      {showRemoveDialog && (
        <FacilitatorConfirmDialog
          title={t('facilitator.removeUser')}
          subText={t('facilitator.confirmRemoveDialogText', { name: userToResolve?.display_name ? userToResolve?.display_name : userToResolve?.username })}
          showDialog={showRemoveDialog}
          onDismiss={() => toggleRemoveDialog()}
          onConfirm={
            () => {
              onRemoveUser(userToResolve?.username);
              toggleRemoveDialog();
            }
          }
        />
      )}
    </>

  );
};

export default UserListPanel;
