import * as React from 'react';
import { mergeStyles } from '@fluentui/react';
import { PrimaryButton, DefaultButton, CompoundButton, IButtonProps } from '@fluentui/react/lib/Button';
import { IconEnum } from '../CustomIconProvider/CustomIconProvider';

interface Props {
  onClick?: IButtonProps['onClick'];
  icon?: IconEnum;
  type?: 'submit' | 'button' | 'reset';
  label: string;
  isPrimary?: boolean;
  subtext?: string | null;
  disabled?: boolean;
  styles?: any;
}

export default function Button({
  children,
  onClick,
  type = 'button',
  isPrimary = false,
  label,
  subtext = null,
  disabled = false,
  icon,
  styles,
}: React.PropsWithChildren<Props>): JSX.Element {
  const buttonLabel = children || label.toUpperCase();
  const buttonStyle = mergeStyles(styles);
  if (subtext) {
    return (
      <CompoundButton
        primary={isPrimary}
        onClick={onClick}
        type={type}
        secondaryText={subtext}
        disabled={disabled}
        ariaLabel={label}
      >
        {buttonLabel}
      </CompoundButton>
    );
  }

  const iconProps = icon
    ? {
        iconName: icon,
      }
    : {};

  if (isPrimary) {
    return (
      <PrimaryButton className={buttonStyle} onClick={onClick} type={type} disabled={disabled} iconProps={iconProps}>
        {buttonLabel}
      </PrimaryButton>
    );
  }
  return (
    <DefaultButton className={buttonStyle} onClick={onClick} type={type} disabled={disabled} iconProps={iconProps}>
      {buttonLabel}
    </DefaultButton>
  );
}
