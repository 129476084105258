import { createMachine, assign, send } from 'xstate';
import { TaskConfig, TaskOptions, InteractionTaskContext, TaskEvent } from '../../../task-types';
import { addScore, getDefaultOptions } from '../../../task-options';
import { getCurrentLeadId } from '../../../../store';

const initialState: InteractionTaskContext = {
  // One tracker for all attempts; will get reset. Consider changing this
  attempts: 0,
  walkthroughStep: 0,
};

const POINTS_VALUE = 21;
const TASK_ID = 'Game02.Episode01.Task05';

export const Task05Config: TaskConfig<InteractionTaskContext, TaskEvent> = {
  id: 'Task05',
  context: initialState,
  initial: 'Q01',
  states: {
    Q01: {
      // Multiple Choice/Multiple Answer
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.SUBMIT_INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          always: {
            target: 'feedback',
            cond: (ctx) => ctx.attempts >= 2,
          },
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        feedback: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: {
              target: '#Task05.Q02',
            },
          },
        },
      },
    },
    Q02: {
      // Multi-choice single answer 2 buttons
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.HINT': {
              target: 'hint',
            },
            'NEXT': {
              target: 'addScore',
            },
          },
        },
        hint: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        addScore: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          always: {
            target: '#Task05.Q03',
          },
        },
      },
    },
    Q03: {
      // Multi-choice single answer 2 buttons
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.HINT': {
              actions: 'incrementAttempts',
              target: 'hint',
            },
            'NEXT': {
              target: 'addScore',
            },
          },
        },
        hint: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        addScore: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          always: {
            target: '#Task05.Q04',
          },
        },
      },
    },
    Q04: {
      // Multi-choice single answer 2 buttons
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        final: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: {
              target: '#Task05.Q05',
            },
          },
        },
      },
    },
    Q05: {
      // Multi-choice single answer 3 buttons
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          always: {
            target: 'hint',
            cond: (ctx) => ctx.attempts === 2,
          },
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        hint: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: {
              target: '#Task05.Q06',
            },
          },
        },
      },
    },
    Q06: {
      // Multi-choice single answer 2 buttons
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        final: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: {
              target: '#Task05.Q07',
            },
          },
        },
      },
    },
    Q07: {
      // Multi-choice single answer 2 buttons
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        final: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: {
              target: '#Task05.Q01',
            },
          },
        },
      },
    },
  },
};

/**
 * Additional machine options.
 */
export const Task05Options: TaskOptions<InteractionTaskContext, TaskEvent> = {
  actions: {
    resetContext: assign({ attempts: () => 0, walkthroughStep: () => 0 }),
    incrementAttempts: assign({ attempts: (context) => context.attempts + 1 }),
    incrementWalkthroughStep: assign({ walkthroughStep: (context) => context.walkthroughStep + 1 }),
  },
  guards: {},
};

/**
 * Machine constructor.
 */
export function createTask05() {
  return createMachine<InteractionTaskContext, TaskEvent>(Task05Config, getDefaultOptions()).withConfig(Task05Options);
}
