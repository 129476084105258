import { useTranslation } from 'react-i18next';
import { Stack } from '@fluentui/react';
import type { IStackStyles, IStackTokens } from '@fluentui/react';
// import { NIL as NIL_UUID } from 'uuid';
import React, { useEffect } from 'react';
import { getMPTeamScore } from '../../../store/multiplayer-slice';
import { useAppSelector } from '../../../store';
import Screen from '../../Global/Screen/Screen';
import Panel from '../../Global/Panel/Panel';
import { Heading } from '../../Global/Text';
// import { selectAllUsers } from '../../store/user-slice';
import useBackground from '../../../lib/hooks/useBackground';
import SummaryUserList from './SummaryUserList';
import { MPManager } from '../../../lib/socket';
import { APIManager } from '../../../lib/api/APIManager';

// import { ScoreVariables } from '../../tasks/task-common';
// import {
//   selectAllAppliedCards,
//   selectAllAppliedClues,
//   selectAllAppliedLeadIds,
// } from '../../store/multiplayer-slice/applied-data';
/**
 * This is a stub for the score summary screen
 */
const contentStackStyles: IStackStyles = {
  root: { width: '100%', zIndex: 2, position: 'absolute', top: '180px', right: '200px' },
};
const contentStackTokens: IStackTokens = {
  padding: '',
  childrenGap: '',
};

// const interstitialWrapper: IStackStyles = {
//   root: {
//     'top': 500,
//     'backgroundColor': 'white',
//     'borderRadius': 6,
//     'position': 'absolute',
//     'zIndex': 2,
//     '@media(min-width: 1040px)': {
//       top: 50,
//       maxWidth: 500,
//     },
//     '@media(min-width: 1240px)': {
//       top: 150,
//       maxWidth: 667,
//     },
//     '@media(min-width: 1650px)': {
//       top: 150,
//       maxWidth: 840,
//     },
//   },
// };

// const interstitialTokens: IStackTokens = {
//   padding: 48,
//   childrenGap: 32,
// };

const Summary: React.FC = () => {
  // const leadScore = useAppSelector((state) => getLeadScores(state.game.leadScores));
  // const leadCount = useAppSelector((state) => getLeadCount(state.game.leadScores));
  const teamScore = useAppSelector((state) => getMPTeamScore(state.multiplayer.teamScores));
  const { t } = useTranslation('game01Common', { keyPrefix: 'summary' });
  // const current = new Date();
  // const date = `${current.getMonth() + 1}/${current.getDate()}/${current.getFullYear()}`;
  // const playerName = useAppSelector((state) => state.user.display_name);
  // const { allUsers } = useAppSelector(selectAllUsers);
  const background = useBackground('summary');

  // // const completedLeads = useAppSelector(selectAllAppliedLeadIds);
  // // const cardsAdded = useAppSelector(selectAllAppliedCards);
  // // const cluesAdded = useAppSelector(selectAllAppliedClues);
  // const cardCount = cardsAdded.filter(
  //   (card) => card.user && card.user !== NIL_UUID && card.user !== 'devLoadGate',
  // ).length;
  // const clueCount = cluesAdded.filter(
  //   (clue) => clue.user && clue.user !== NIL_UUID && clue.user !== 'devLoadGate',
  // ).length;

  const socket = MPManager.instance.setupSocket(process.env.REACT_APP_SOCKET_URL);
  const { event_slug, username, key } = APIManager.getConfig();

  useEffect(() => {
    socket.emit('update:user', {
      username,
      key,
      slug: event_slug,
      end_time: new Date(),
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Screen backgroundImage={background} cover ignoreOffset anchor="left">
      <Panel useDark>
        <Stack styles={contentStackStyles} tokens={contentStackTokens} verticalAlign="stretch" horizontalAlign="end">
          <Stack
            grow={1}
            tokens={{ padding: '' }}
            styles={{
              root: {
                width: '600px',
              },
            }}
          >
            <Stack.Item align="center">
              <Heading level={1} block useDark>
                {t('thankYouMsg')}
              </Heading>
            </Stack.Item>
            <Stack.Item align="center">
              <Heading block level={2} useDark center>
                {t('teamScore')}
              </Heading>
              <Heading level={2} useDark center block>
                {teamScore}
              </Heading>
              {/* <Text styles={{ root: { fontSize: 100 } }}>{teamScore}</Text> */}
            </Stack.Item>
            <Stack.Item align="center">
              <SummaryUserList />
            </Stack.Item>
          </Stack>
        </Stack>
      </Panel>
    </Screen>
  );
};

export default Summary;
