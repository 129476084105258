import * as React from 'react';
import { ComponentProps } from '../../../../task-types';
import Screen from '../../../../../components/Global/Screen/Screen';
import Button from '../../../../../components/Global/Button/Button';
import Modal from '../../../../../components/Game01/Modal/Modal';
import SingleSelect from '../../../../../components/Global/Interactions/SingleSelect/SingleSelect';
import { useMultipleChoice } from '../../../../../components/Global/Interactions/MultipleChoice/MultipleChoice';

export default function A075({ t, send, match }: ComponentProps): JSX.Element {
  const { errorKey, handleIncorrect } = useMultipleChoice(() => send('CLICK.INCORRECT'));

  const handleSubmit = (isCorrect: boolean, selectedIndex: number) => {
    if (isCorrect) {
      send('NEXT');
    } else {
      handleIncorrect(`${selectedIndex}`);
    }
  };

  const selectOptions = [
    {
      key: 1,
      text: t('A075.radios.one'),
    },
    {
      key: 2,
      text: t('A075.radios.two'),
      isCorrect: true,
    },
    {
      key: 3,
      text: t('A075.radios.three'),
    },
  ];

  return (
    <Screen>
      <SingleSelect
        title={t('A075.header')}
        subtitle={t('A075.body')}
        body=""
        onSubmit={handleSubmit}
        useDark
        nextLabel={t('common.done')}
        options={selectOptions}
        // showAnswer={isFeedback}
      />
      <Modal
        sidebar="character"
        title={t(`A075.error.${errorKey}.title`)}
        body={t(`A075.error.${errorKey}.body`)}
        isOpen={match('incorrect')}
        footer={<Button isPrimary label={t('common.errorTitle')} onClick={() => send('NEXT')} />}
        onDismiss={() => send('NEXT')}
        hideDismissButton
      />
    </Screen>
  );
}
