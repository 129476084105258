/**
 * i18next NS: `Episode01.Task02`
 */
 export default {
  N01: {
    body: `<p>You probably have a lot of questions about how you're going to set up the modeling for this data initiative.</p>

               <p>I've set up a meeting with you, Mr. Trentini, and Floris Krigel, our Chief Data Officer. They can give you all the information you need for this initial build—as long as you ask the right questions!</p>

               <p>-Suchaow</p>`,
    branches: [
      {
        option: "Start",
        goTo: "N02",
      },
    ],
  },

  N02: {
    body: `<p>You arrive in the meeting room a few minutes early. Mattia and Floris join you shortly after.</p>

    <p>"After you exchange greetings, Mattia directs the conversation to the topic of data modeling.</p>
            
    <p>"MATTIA TRENTINI: We're counting on your capabilities and the data team's skills overall. During our previous meaning, I implied that I want PRESERVERON to have global, world-wide impact—and I really mean it.</p>
            
            "<p>You say...</p>`,
    branches: [
      {
        option: "That's a big goal.",
        goTo: "N03",
      },
      {
        option: "Where should we start?",
        goTo: "N03",
      },
      {
        option: "We'll need good data.",
        goTo: "N03",
      },
    ],
  },

  N03: {
    body: `<p>MATTIA TRENTINI: It's ambitious vision, I know. We'll need to start small and validate our approach iteratively. We'll begin by analyzing food waste data from a specific country, and exploring our opportunities therein.</p>

    <p>"FLORIS KRIGEL: We've obtained data from ReFED.org, a national nonprofit working to end food loss and waste across the US food system. They've performed an impact assessment of 42 different food waste reduction solutions, and they've made the data available as CSVs via HTTP.</p>
            
    <p>"HINT: Background information on Trey Research's infrastructure is available in the game's sidebar menu.</p>
            
    <p>"You say...</p>`,
    branches: [
      {
        option: "I have some follow-up questions...",
        goTo: "N04",
      },
    ],
  },

  N04: {
    body: ``,
    branches: [
      {
        option: "Can you tell me more about your decision-making process?",
        goTo: "N05",
      },
      {
        option: "What type or types of data modeling should I use?",
        goTo: "N06",
      },
      {
        option: "What are the company's priorities regarding this initiative?",
        goTo: "N07",
      },
      {
        option: "Which devices should we support for our initial build?",
        goTo: "N08",
      },
      {
        option: "Tell me about the tools we have available to us.",
        goTo: "N09",
      },
      {
        option: "How would you like us to present our findings?",
        goTo: "N10",
      },
    ],
  },

  N05: {
    body: `<p>MATTIA TRENTINI: We're going to select a strategy based on multiple metrics, including meals diverted, jobs created, cost, net financial benefit, et cetera.</p>

    <p>FLORIS KRIGEL: But if our analysis gets too detailed, it will be difficult to compare our options.</p>

    <p>MATTIA TRENTINI: Right. There are many targeted food waste solutions that are already in play at various points along the supply chain. We need to be able to evaluate opportunities within and across these points.</p>

    <p>You say...</p>`,
    branches: [
      {
        option: "It seems we'll want to look at categories or groupings of solutions based on our metrics.",
        goTo: "N11",
      },
      {
        option: "So what type or types of predictive modeling should I use?",
        goTo: "N06",
      },
      {
        option: "Can you tell me more about the metrics you just mentioned?",
        goTo: "N12",
      },
    ],
  },

  N06: {
    body: `<p>Mattia and Floris exchange a look. You get the sense that you made a mistake.</p>

    <p>MATTIA: We're expecting you to determine the best approach based on your findings.</p>

    <p>Floris nods and says nothing.</p>

    <p>You say...</p>`,
    branches: [
      {
        option: "Of course.",
        goTo: "N13",
      },
      {
        option: "I can do that.",
        goTo: "N13",
      },
    ],
  },

  N07: {
    body: `<p>MATTIA TRENTINI: In selecting a direction for this initiative, we must balance across our top three priorities.<p>

                <ul>
                
                    <li>We want PRESERVERON to be impactful (meaning it should achieve a large amount of meals diverted).</li>
                    
                    <li>PRESERVERON should be profitable (meaning costs as a percentage of gross revenue should be low).</li>
                    
                    <li>We need PRESERVERON to be be scalable (meaning it should have a high gross revenue value and be something that generates jobs).</li>
                    
                </ul>      
                
                <p>You ask...</p>`,
    branches: [
      {
        option: "Did you list these in order of importance?",
        goTo: "N14",
      },
      {
        option: "Of these three, which would you say is most important?",
        goTo: "N14",
      },
      {
        option: "Would you say that profitability is the highest priority?",
        goTo: "N15",
      },
    ],
  },

  N08: {
    body: `<p>MATTIA TRENTINI: Laptops and tablet support is what we'll need first.</p>

    <p>FLORIS KRIGEL: I doubt we'll ever need to use this data solution on mobile devices.</p>

    <p>MATTIA TRENTINI: Not in the near future, no. I wouldn't put mobile support on our current roadmap.</p>

    <p>You say...</p>`,
    branches: [
      {
        option: "That makes sense to me. Let me ask about something else...",
        goTo: "N04",
      },
      {
        option: "Understood. That was my last question for now!",
        goTo: "N18",
      },
    ],
  },

  N09: {
    body: `<p>FLORIS KRIGEL: We're using the Microsoft Azure Data platform. My understanding is that the other members of your team have experience with certain tools on that platform. You should check with them.</p>

    <p>You say...</p>`,
    branches: [
      {
        option: "I definitely will. Can I ask something else?",
        goTo: "N04",
      },
      {
        option: "I'll ask them. For now, I think I have all the information I need to get started.",
        goTo: "N18",
      },
    ],
  },

  N10: {
    body: `<p>MATTIA TRENTINI: I'd like a dashboard that allows us to manipulate the data and analyze it from different angles. We'll need to break it down in different ways to make comparisons.</p>

    <p>You ask...</p>`,
    branches: [
      {
        option: "Can you be more specific? I need to know what features you want.",
        goTo: "N16",
      },
      {
        option: "Could you give me a hypothetical comparison?",
        goTo: "N17",
      },
    ],
  },

  N11: {
    body: `<p>FLORIS KRIGEL: Yes, that makes sense. Some kind of clustering model seems appropriate to me, based on what we know so far.</p>

    <p>You say..</p>`,
    branches: [
      {
        option: "Mr. Trentini, can you tell me more about the metrics you just mentioned?",
        goTo: "N12",
      },
      {
        option: "Let me pivot to a different topic.",
        goTo: "N04",
      },
      {
        option: "That's all the questions I have for now.",
        goTo: "N18",
      },
    ],
  },

  N12: {
    body: `<p>MATTIA TRENTINI: I'd be happy to, absolutely.</p>

                <ul>
                    <li>Meals diverted refers to the amount of food waste, as measured in meal-equivalents, has been diverted from landfills.</li>

                    <li>Jobs created seems self-explanatory. It's a benefit to all if the solution we pursue creates net job growth.</li>

                    <li>CO2 reduction would be beneficial for the environment, as would water savings.</li>

                    <li>We also have to consider the cost, gross financial benefit, and net financial benefit of the solutions we explore.</li>

                </ul>

                <p>You say...</p>`,
    branches: [
      {
        option: "Thanks! Now I have some additional questions...",
        goTo: "N04",
      },
      {
        option: "Thanks! I think that gives me everything I need for now.",
        goTo: "N18",
      },
    ],
  },

  N13: {
    body: `You reassure Mattia and Floris that you're capable of determining appropriate data models for the initiative. They seem to relax, and they wait for your next question.`,
    branches: [
      {
        option: "Can you tell me more about the metrics you mentioned?",
        goTo: "N12",
      },
      {
        option: "Let me pivot to a different topic.",
        goTo: "N04",
      },
    ],
  },

  N14: {
    body: `<p>MATTIA TRENTINI: I haven't ranked these priorities, no. Impact, profitability, and scalability are all important. We'll have to make some trade-offs between the three, of course.</p>

    <p>FLORIS KRIGEL: It sounds like the team's data solution should enable leadership to compare potential food waste solutions—or groups of solutions—based on these priorities. Is that right?</p>

    <p>MATTIA TRENTINI: Yes, that sounds correct. In order to come to a decision about the shape of this initiative going forward, we need to be able to analyze the trade-offs between these priorities.</p>

    <p>You say...</p>`,
    branches: [
      {
        option: "Okay, that makes sense. Now, let me ask about something else...",
        goTo: "N04",
      },
      {
        option: "I think that's the last piece of information I needed today.",
        goTo: "N18",
      },
    ],
  },

  N15: {
    body: `<p>The CEO gives you a critical look.</p>

    <p>MATTIA TRENTINI: No, I wouldn't say that. I haven't ranked these priorities. Impact, profitability, and scalability are all important.</p>`,
    branches: [
      {
        option: "Right. Let's pivot to a different topic, then...",
        goTo: "N04",
      },
      {
        option: "All right. Well, thanks for meeting with me today.",
        goTo: "N18",
      },
    ],
  },

  N16: {
    body: `<p>MATTIA TRENTINI: I can try. So, imagine we're looking at opportunities to reduce food waste along different stages of the food supply chain. We might need to compare an opportunity in the food processing industry with an opportunity within consumer-facing grocery stores.</p>

    <p>FLORIS KRIGEL: Right. The data solution needs to allow us to compare groups of solutions using similar characteristics.</p>

    <p>MATTIA TRENTINI: I think that sounds right. I'll know it when I see it.</p>`,
    branches: [
      {
        option: "Okay, we'll do our best. Let me ask about something else...",
        goTo: "N04",
      },
      {
        option: "I think I understand where we're headed. That's all I need for now.",
        goTo: "N18",
      },
    ],
  },

  N17: {
    body: `<p>MATTIA TRENTINI: So, imagine we're looking at opportunities to reduce food waste along different stages of the food supply chain. In this hypothetical, we need to compare an opportunity that involves composting in the food processing industry with an opportunity that involves selling imperfect foods in grocery stores.</p>

    <p>FLORIS KRIGEL: So you'll need some detailed visuals.</p>

    <p>MATTIA TRENTINI: Yes, robust visualizations are a must. I need to be able to see and group different solutions and their characteristics.</p>`,
    branches: [
      {
        option: "That's helpful to know, thank you.",
        goTo: "N19",
      },
      {
        option: "I'll work with the Analyst to make it happen.",
        goTo: "N19",
      },
    ],
  },

  N18: {
    body: `<p>MATTIA TRENTINI: Thank you for your efforts. I'm looking forward to seeing what you and your team produce.</p>

    <p>FLORIS KRIGEL: Is there anything else you want to ask before we wrap up our meeting?</p>`,
    branches: [
      {
        option: "No, I think I'm set. Thank you!",
        goTo: "N20",
      },
      {
        option: "Actually, now that you mention it...",
        goTo: "N04",
      },
    ],
  },

  N19: {
    body: `MATTIA TRENTINI: I believe in your team. We're counting on you.`,
    branches: [
      {
        option: "Thanks. I have another question...",
        goTo: "N04",
      },
      {
        option: "We'll do our best! Now, to shift topics...",
        goTo: "N04",
      },
      {
        option: "Thanks for your time today. I think I have all I need.",
        goTo: "N18",
      },
    ],
  },

  N20: {
    body: `ACTIVITY COMPLETE

                Your meeting with Floris and Mattia concludes. Here’s what you learned about the data model requirements for your upcoming build:</br></br>

                ???
                
                ???
                
                <ul>
                
                    <li>Your team will only need to support laptops and tablets for the initial build of the data solution.</li>
                    
                </ul>
                
                ???
                
                ???
                
                ???`,
    branches: [
      {
        option: "Start Over",
        goTo: "N01",
      },
    ],
  },
};
