import { createMachine, assign, send } from 'xstate';
import { TaskConfig, TaskOptions, InteractionTaskContext, TaskEvent } from '../../../task-types';
import { addScore, getDefaultOptions } from '../../../task-options';
import { getCurrentLeadId } from '../../../../store';
// import { PointValue } from '../../task-common';

/**
 * Partially applied `addScore` action creator with
 * `{ type: 'group', id?: ``Game01.GA01.${id}`` }`
 */
// const addScoreP = (amount: PointValue, id?: string) => {
//   const computedId = id == null ? undefined : `Episode01.Task01.${id}`;
//   return addScore({ type: 'group', amount, id: computedId });
// };

const initialState: InteractionTaskContext = {
  // One tracker for all attempts; will get reset. Consider changing this
  attempts: 0,
  walkthroughStep: 0,
};
const POINTS_VALUE = 62.5;
const TASK_ID = 'Game02.Episode01.Task07';

export const Task07Config: TaskConfig<InteractionTaskContext, TaskEvent> = {
  id: 'Task07',
  context: initialState,
  initial: 'Q01',
  states: {
    Q01: {
      // Multi-choice single answer 4 buttons
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              target: 'error',
            },
            'NEXT': {
              target: 'addScore',
            },
          },
        },
        error: {
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        addScore: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          always: {
            target: '#Task07.Q02',
          },
        },
      },
    },
    Q02: {
      // Multi-choice, single answer 3 buttons, all right (I just use incorrect because it matches the hook)
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              target: 'final',
            },
          },
        },
        final: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
          ],
          on: {
            NEXT: {
              target: '#Task07.Q03',
            },
          },
        },
      },
    },
    Q03: {
      // Multi-choice, single answer 2 buttons, all right (I just use incorrect because it matches the hook)
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'error',
            },
            'NEXT': {
              target: 'feedback',
            },
          },
        },
        error: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        feedback: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: {
              target: '#Task07.Q05',
            },
          },
        },
      },
    },
    Q04: {
      // Multi-choice, single answer 2 buttons
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'error',
            },
          },
        },
        error: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          always: {
            target: '#Task07.Q05',
          },
        },
      },
    },
    Q05: {
      // Multiple Choice/Multiple Answer
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.SUBMIT_INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          always: {
            target: 'feedback',
            cond: (ctx) => ctx.attempts >= 2,
          },
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        feedback: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: {
              target: '#Task07.Q06',
            },
          },
        },
      },
    },
    Q06: {
      // Multi-choice single answer 3 buttons
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.SUBMIT_INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          always: {
            target: 'feedback',
            cond: (ctx) => ctx.attempts === 2,
          },
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        feedback: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: {
              target: '#Task07.Q07',
            },
          },
        },
      },
    },
    Q07: {
      // Multiple Choice, 4 buttons
      initial: 'decision',
      entry: 'reetContext',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              target: 'incorrect',
            },
            'NEXT': {
              target: 'feedback',
            },
          },
        },
        incorrect: {
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        feedback: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: {
              target: '#Task07.Q08',
            },
          },
        },
      },
    },
    Q08: {
      entry: 'resetContext',
      on: {
        NEXT: {
          target: 'Q01', // TODO: Go to next phase (will be a sim)
        },
      },
    },
  },
};

/**
 * Additional machine options.
 */
export const Task07Options: TaskOptions<InteractionTaskContext, TaskEvent> = {
  actions: {
    resetContext: assign({ attempts: () => 0, walkthroughStep: () => 0 }),
    incrementAttempts: assign({ attempts: (context) => context.attempts + 1 }),
    incrementWalkthroughStep: assign({ walkthroughStep: (context) => context.walkthroughStep + 1 }),
  },
  guards: {},
};

/**
 * Machine constructor.
 */
export function createTask07() {
  return createMachine<InteractionTaskContext, TaskEvent>(Task07Config, getDefaultOptions()).withConfig(Task07Options);
}
