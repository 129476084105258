import { createMachine, assign, send } from 'xstate';
import { TaskConfig, TaskOptions, InteractionTaskContext, TaskEvent } from '../../../task-types';
import { addScore, getDefaultOptions } from '../../../task-options';
import { store as reduxStore, getCurrentLeadId } from '../../../../store';

const initialState: InteractionTaskContext = {
  // One tracker for all attempts; will get reset. Consider changing this
  attempts: 0,
  walkthroughStep: 0,
  maxAttempts: 3,
  store: reduxStore,
};
const POINTS_VALUE = 36;
const TASK_ID = 'Game01.Episode02.Task04';

export const Task04Config: TaskConfig<InteractionTaskContext, TaskEvent> = {
  id: 'Task04',
  context: initialState,
  initial: 'A020',
  states: {
    A020: {
      // Desktop Select - 3 chances to click correctly
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          always: {
            target: 'hint',
            cond: (ctx) => ctx.attempts === 3,
          },
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        hint: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          entry: [addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }), send('NEXT')],
          on: {
            NEXT: '#Task04.A030',
          },
        },
      },
    },
    A030: {
      // Multi-choice single answer 3 buttons
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        hint: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          entry: [addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }), send('NEXT')],
          on: {
            NEXT: {
              target: '#Task04.A040',
            },
          },
        },
      },
    },
    A040: {
      // Multi-choice single answer 2 buttons
      initial: 'decision',
      entry: ['resetContext', 'setMaxAttemptsToTwo'],
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        hint: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          entry: [addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }), send('NEXT')],
          on: {
            NEXT: {
              target: '#Task04.A050',
            },
          },
        },
      },
    },
    A050: {
      // Multi-choice single answer 3 buttons
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        hint: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          entry: [addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }), send('NEXT')],
          on: {
            NEXT: {
              target: '#Task04.A060',
            },
          },
        },
      },
    },
    A060: {
      // Multi-choice single answer 3 buttons
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        hint: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          entry: [addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }), send('NEXT')],
          on: {
            NEXT: {
              target: '#Task04.A070',
            },
          },
        },
      },
    },
    A070: {
      // Multi-choice single answer 2 buttons
      initial: 'decision',
      entry: ['resetContext', 'setMaxAttemptsToTwo'],
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        hint: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          entry: [addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }), send('NEXT')],
          on: {
            NEXT: {
              target: '#Task04.A080',
            },
          },
        },
      },
    },
    A080: {
      // Interstitial
      entry: 'resetContext',
      on: {
        NEXT: {
          target: '#Task04.A090',
        },
      },
    },
    A090: {
      // Multi-choice single answer 2 buttons
      initial: 'decision',
      entry: ['resetContext', 'setMaxAttemptsToTwo'],
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        hint: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          entry: [addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }), send('NEXT')],
          on: {
            NEXT: {
              target: '#Task04.A100',
            },
          },
        },
      },
    },
    A100: {
      // Multi-choice single answer 2 buttons
      initial: 'decision',
      entry: ['resetContext', 'setMaxAttemptsToTwo'],
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        hint: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          entry: [addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }), send('NEXT')],
          on: {
            NEXT: {
              target: '#Task04.A110',
            },
          },
        },
      },
    },
    A110: {
      // Multi-choice single answer 2 buttons
      initial: 'decision',
      entry: ['resetContext', 'setMaxAttemptsToTwo'],
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        hint: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          entry: [addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }), send('NEXT')],
          on: {
            NEXT: {
              target: '#Task04.A120',
            },
          },
        },
      },
    },
    A120: {
      // Interstitial
      entry: 'resetContext',
      on: {
        NEXT: {
          target: '#Task04.A130',
        },
      },
    },
    A130: {
      // Interstitial
      entry: 'resetContext',
      on: {
        NEXT: {
          target: '#Task04.A140',
        },
      },
    },
    A140: {
      // Multi-choice single answer 2 buttons
      initial: 'decision',
      entry: ['resetContext', 'setMaxAttemptsToTwo'],
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        hint: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          entry: [addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }), send('NEXT')],
          on: {
            NEXT: {
              target: '#Task04.A160',
            },
          },
        },
      },
    },
    A160: {
      // Interstitial
      entry: 'resetContext',
      on: {
        NEXT: {
          target: '#Task04.A170',
        },
      },
    },
    A170: {
      // Interstitial
      entry: 'resetContext',
      on: {
        NEXT: {
          target: '#Task04.A180',
        },
      },
    },
    A180: {
      // Interstitial
      entry: 'resetContext',
      on: {
        NEXT: {
          target: 'A020',
        },
      },
    },
  },
};

/**
 * Additional machine options.
 */
export const Task04Options: TaskOptions<InteractionTaskContext, TaskEvent> = {
  actions: {
    resetContext: assign({ attempts: () => 0, walkthroughStep: () => 0 }),
    incrementAttempts: assign({ attempts: (context) => context.attempts + 1 }),
    incrementWalkthroughStep: assign({ walkthroughStep: (context) => context.walkthroughStep + 1 }),
    setMaxAttemptsToTwo: assign({
      attempts: (context) => context.attempts,
      walkthroughStep: (context) => context.walkthroughStep,
      maxAttempts: (context) => {
        if (context.maxAttempts) {
          context.maxAttempts -= 1;
        }
        return 2;
      },
    }),
  },
  guards: {},
};

/**
 * Machine constructor.
 */
export function createTask04() {
  return createMachine<InteractionTaskContext, TaskEvent>(Task04Config, getDefaultOptions()).withConfig(Task04Options);
}
