import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { INavLink, INavLinkGroup, IStackTokens, Nav, Stack, Text } from '@fluentui/react';
import { cloneDeep } from 'lodash';
import { taskLinks } from '../../../tasks';
import { testLinks } from '../../../tests';
import { useGameService } from '../../../state/GlobalStateProvider';

const homeSectionStackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

function generateNavLinks(origLinks: INavLink[], name: string): INavLinkGroup[] {
  const links = cloneDeep(origLinks);
  return [
    {
      name,
      collapseByDefault: false,
      links,
    },
  ];
}

const HomeScreen: React.FC = () => {
  const { state, setState } = useGameService();

  const tasksGroup = generateNavLinks(taskLinks, 'Tasks');
  const testsGroup = generateNavLinks(testLinks, 'Tests');
  const history = useHistory();

  const handleNavClick = (event?: React.MouseEvent, element?: INavLink) => {
    event?.preventDefault();
    if (element != null) {
      history?.push(element.url);
    }
    return true;
  };

  useEffect(() => {
    // setting to a discrete state in the game machine to disable unwanted functionality
    if (!state.matches('devHome')) {
      setState('devHome');
    }
  });

  return (
    <Stack verticalFill tokens={homeSectionStackTokens}>
      <Stack.Item>
        <Text variant="xxLarge">Development Home Screen</Text>
      </Stack.Item>
      <Stack.Item>
        <Nav groups={tasksGroup} onLinkClick={handleNavClick} />
        <Nav groups={testsGroup} onLinkClick={handleNavClick} />
      </Stack.Item>
    </Stack>
  );
};

export default HomeScreen;
