import * as yup from 'yup';

export const SCHEMA = {
  config: yup.object().shape({
    url: yup.string(),
    domain: yup.string(),
    key: yup.string(),
    id: yup.number(),
    event_slug: yup.string(),
    username: yup.string(),
    // eslint-disable-next-line react/forbid-prop-types
    gameData: yup.object(),
  }),
};

/**
 * Validate input given to functions
 * Doesn't say why, returns true or throws an error if false
 * @param {Object} params variable to be validated
 * @param {Object} schema the yup validation schema chosen
 * @param {String} comment optional comment
 */

export const validateSchema = async (params: any, schema: any, comment = '') => {
  const s = (SCHEMA as any)[schema];
  if (!s) {
    throw new Error(`No schema found with key ${schema}`);
  }
  const v = await s.isValid(params);
  if (!v) {
    throw new Error(`APIManager : ${schema} : invalid parameters : ${comment}`);
  }
  return v;
};
