import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTaskMachine } from '../../../../lib/hooks/useTaskMachine';
import A005 from './components/A005';
import A010 from './components/A010';
import B010 from './components/B010';
import B050 from './components/B050';
import B060 from './components/B060';
import C010 from './components/C010';
import C040a from './components/C040a';
import C050 from './components/C050';
import { createTask01 } from './machine';

const baseNS = 'Game01.Episode01.Task01';
const taskKey = 'Game01.Episode01.Task01';

function createTaskMatch(parent: string, state: any) {
  return (matchState: string) => state.matches(`${parent}.${matchState}`);
}

const Task01: React.FC = () => {
  const { t } = useTranslation([baseNS, 'common']);

  // Setup task machine
  const [state, send] = useTaskMachine(createTask01, {
    taskKey,
  });

  return (
    <>
      {state.matches('A005') && <A005 t={t} send={send} state={state.context} match={createTaskMatch('A005', state)} />}
      {state.matches('A010') && <A010 t={t} send={send} state={state.context} match={createTaskMatch('A010', state)} />}
      {state.matches('B010') && <B010 t={t} send={send} state={state.context} match={createTaskMatch('B010', state)} />}
      {state.matches('B050') && <B050 t={t} send={send} state={state.context} match={createTaskMatch('B050', state)} />}
      {state.matches('B060') && <B060 t={t} send={send} state={state.context} match={createTaskMatch('B060', state)} />}
      {state.matches('C010') && <C010 t={t} send={send} state={state.context} match={createTaskMatch('C010', state)} />}
      {state.matches('C040a') && (
        <C040a t={t} send={send} state={state.context} match={createTaskMatch('C040a', state)} />
      )}
      {state.matches('C050') && <C050 t={t} send={send} state={state.context} match={createTaskMatch('C050', state)} />}
    </>
  );
};

export default Task01;
