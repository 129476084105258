import { Memoize } from 'typescript-memoize';
import _round from 'lodash/round';

import type { XYPosition } from 'react-flow-renderer';

import { GameDataBase } from './game-data-base';
import { FactData } from './fact-data';
import { devLogWarn } from '../util';
import { extractIdsFromEntry } from '../journal/journal-entry';

import type { EpisodeData } from './episode-data';
import type { LeadData } from './lead-data';
import type {
  EvidenceCollectionStatus,
  EvidenceDefinition,
  EvidenceHybridLeadType,
  EvidenceImportance,
  EvidenceType,
  GameDataInstance,
  GameDataInstanceOf,
  GameDataKind,
} from './types';
import type { JournalEntry } from '../journal/types';
import type { AppliedDataEntity } from '../multiplayer/schemas';

export type EvidenceDataCard = EvidenceData & { type: 'card' };
export type EvidenceDataClue = EvidenceData & { type: 'clue' };

const allImportances: ReadonlyArray<EvidenceImportance> = Object.freeze(['critical', 'bonus', 'irrelevant']);

/**
 * Evidence Data
 */
export class EvidenceData extends GameDataBase {
  readonly kind = 'evidence';

  declare readonly parent: EpisodeData | LeadData | undefined;

  readonly type: EvidenceType;

  readonly image?: string;

  readonly detailImage?: string;

  readonly importance: EvidenceImportance;

  readonly facts: readonly FactData[] = [];

  readonly locationId: string | undefined;

  readonly linkIds: readonly string[] = [];

  readonly unlockIds: readonly string[] = [];

  readonly hybridLeadType?: string = '';

  constructor(data: EvidenceDefinition, parent?: EpisodeData | LeadData) {
    super(data, parent);

    const { type, importance, facts, locationId, linkIds, unlockIds, image, detailImage, hybridLeadType } = data;
    this.type = type ?? 'clue';
    this.importance = importance ?? 'irrelevant';
    this.image = image;
    this.detailImage = detailImage;
    this.hybridLeadType = hybridLeadType;

    // Handle locationId
    if (locationId != null) {
      if (this.type === 'card') {
        devLogWarn(`EvidenceData() a card's locationId is itself. '${locationId}' will be ignored.`, this);
      }
      this.locationId = type !== 'card' ? locationId : undefined;
    }

    // Parse fact definitions
    if (Array.isArray(facts)) {
      this.facts = facts.map((def) => new FactData(def, this));
    } else if (facts != null) {
      this.facts = Object.entries(facts).map(([key, def]) => new FactData({ id: key, ...def }, this));
    }
    Object.freeze(this.facts);

    // Parse link IDs
    if (Array.isArray(linkIds)) {
      this.linkIds = [...linkIds];
    }
    Object.freeze(this.linkIds);

    // Parse unlock IDs
    if (Array.isArray(unlockIds)) {
      this.unlockIds = [...unlockIds];
    }
    Object.freeze(this.unlockIds);
  }

  getChildren(): readonly FactData[];
  getChildren<TKind extends GameDataKind | undefined>(filter?: TKind): readonly GameDataInstanceOf<TKind>[];
  @Memoize()
  getChildren(filter?: GameDataKind): readonly GameDataInstance[] {
    return filter == null || filter === 'fact' ? this.facts : Object.freeze([]);
  }

  /** Check this is a Card (TS type predicate). */
  isCard(): this is EvidenceDataCard {
    return this.type === 'card';
  }

  /** Check this is a Clue (TS type predicate). */
  isClue(): this is EvidenceDataClue {
    return this.type === 'clue';
  }

  /**
   * Get the Evidence Map Card location for this instance.
   * A card's location is _always_ itself.
   * This will only return 'card' type instances.
   */
  @Memoize()
  getLocation(): EvidenceDataCard | undefined {
    if (this.type === 'card') {
      return this as EvidenceDataCard;
    }

    if (this.locationId == null) {
      return undefined;
    }

    const result = this.find(this.locationId);
    if (result == null) {
      devLogWarn(`EvidenceData.getLocation() location '${this.locationId}' not found`, this);
      return undefined;
    }

    if (result.kind !== 'evidence' || result.type !== 'card') {
      devLogWarn(`EvidenceData.getLocation() location '${this.locationId}' is not a Card`, this);
      return undefined;
    }

    return result as EvidenceDataCard;
  }

  /**
   * Get the Evidence Map Card links for this instance.
   * This will only return 'card' type instances.
   */
  @Memoize()
  getLinks(): readonly EvidenceDataCard[] {
    return Object.freeze([
      ...this.linkIds.reduce((accumulator, linkId) => {
        const result = this.find(linkId);
        if (result == null) {
          devLogWarn(`EvidenceData.getLinks() link '${linkId}' not found`, this);
        } else if (result.kind !== 'evidence' || result.type !== 'card') {
          devLogWarn(`EvidenceData.getLinks() link '${linkId}' is not a Card`, this);
        } else {
          accumulator.add(result as EvidenceDataCard);
        }
        return accumulator;
      }, new Set<EvidenceDataCard>()),
    ]);
  }

  /**
   * Get the unlocks for this instance.
   */
  @Memoize()
  getUnlocks() {
    return Object.freeze([
      ...this.unlockIds.reduce((accumulator, unlockId) => {
        const result = this.find(unlockId);
        if (result == null) {
          devLogWarn(`EvidenceData.getUnlocks() unlock '${unlockId}' not found`, this);
        } else if (result.kind !== 'evidence' && result.kind !== 'fact') {
          devLogWarn(`EvidenceData.getUnlocks() unlock '${unlockId}' is not Evidence or Fact`, this);
        } else {
          accumulator.add(result);
        }
        return accumulator;
      }, new Set<EvidenceData | FactData>()),
    ]);
  }

  /**
   *
   */
  getCollectionStatus<TEntity extends JournalEntry | AppliedDataEntity>(entities: TEntity[]) {
    const output: EvidenceCollectionStatus<TEntity> = {
      id: this.id,
      importance: this.importance,
      collected: false,
      completed: false,
      leadType: 'nonSim',
      entities: [],
      critical: {
        collected: [],
        total: [],
        percent: 0,
      },
      bonus: {
        collected: [],
        total: [],
        percent: 0,
      },
      irrelevant: {
        collected: [],
        total: [],
        percent: 0,
      },
    };

    // If no matching entity is found, then it hasn't been collected.
    const matchingEntity = entities.find(({ id }) => extractIdsFromEntry(id).entryId === this.id);

    // Extract fact shortIds from different entity types.
    let entityFacts: string[] = [];

    if (matchingEntity != null) {
      output.entities.push(matchingEntity);
      output.collected = true;
      if ('states' in matchingEntity) {
        entityFacts = matchingEntity.states;
      } else if ('facts' in matchingEntity) {
        entityFacts = matchingEntity.facts ?? [];
      }
    }
    if (this.hybridLeadType) {
      output.leadType = this.hybridLeadType as EvidenceHybridLeadType;
    }

    // Process facts
    this.facts.forEach((fact) => {
      output[fact.importance].total.push(fact);
      if (entityFacts.includes(fact.shortId)) {
        output[fact.importance].collected.push(fact);
      }
    });

    // Calculate completion percentages
    allImportances.forEach((importance) => {
      if (output[importance].total.length === 0) {
        output[importance].percent = 1;
        return;
      }
      output[importance].percent = _round(output[importance].collected.length / output[importance].total.length, 3);
    });

    if (output.collected && output.critical.percent >= 1) {
      output.completed = true;
    }

    return output;
  }

  /**
   * Get the evidence map position for this instance's Evidence Map location.
   */
  getMapPosition(defaultValue: XYPosition = { x: 0, y: 0 }): XYPosition {
    return this.getEpisode()?.getMapPosition(this.shortId, defaultValue) ?? defaultValue;
  }

  /**
   * Get dependent clues/facts for this instance.
   */
  getDependencies(): readonly (EvidenceData | FactData)[];
  getDependencies(filter: EvidenceImportance): readonly (EvidenceData | FactData)[];
  @Memoize()
  /** Implementation signature */
  getDependencies(filter?: EvidenceImportance): readonly (EvidenceData | FactData)[] {
    // Process filter
    if (filter != null) {
      const allDependencies = this.getDependencies();
      return allDependencies.length === 0
        ? allDependencies
        : Object.freeze(allDependencies.filter(({ importance }) => importance === filter));
    }

    // A Clue's dependencies are just its facts.
    if (this.type === 'clue') {
      return this.facts;
    }

    // Find parent Episode data.
    const episode = this.getEpisode();
    if (episode == null) {
      return Object.freeze([]);
    }

    const episodeEvidence = episode.getAllChildren('evidence');
    return Object.freeze(episodeEvidence.filter((evidence) => evidence !== this && evidence.getLocation() === this));
  }

  /** Get associated EpisodeData */
  getEpisode() {
    switch (this.parent?.kind) {
      case 'episode':
        return this.parent;
      case 'lead':
        return this.parent.parent;
      default:
        return undefined;
    }
  }

  /** Get associated LeadData */
  getLead() {
    return this.parent?.isKind('lead') ? this.parent : undefined;
  }

  /** Get associated PhaseData */
  getPhase() {
    return this.getLead()?.getPhase();
  }
}
