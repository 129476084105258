import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTaskMachine } from '../../../../lib/hooks/useTaskMachine';
import Q01 from './components/Q01';
import Q02 from './components/Q02';
import Q03 from './components/Q03';
import Q04 from './components/Q04';
import Q05 from './components/Q05';
import Q06 from './components/Q06';
import Q07 from './components/Q07';
import Q08 from './components/Q08';

import { createTask07 } from './machine';

const baseNS = 'Game02.Episode01.Task07';
const taskKey = 'Game02.Episode01.Task07';

// Todo: Refactor this so it's less cumbersome to get a scoped match function
function createTaskMatch(parent: string, state: any) {
  return (matchState: string) => state.matches(`${parent}.${matchState}`);
}

const Task07: React.FC = () => {
  const { t } = useTranslation([baseNS, 'common']);

  // Setup task machine
  const [state, send] = useTaskMachine(createTask07, {
    taskKey,
  });

  return (
    <>
      {state.matches('Q01') && <Q01 t={t} send={send} state={state.context} match={createTaskMatch('Q01', state)} />}
      {state.matches('Q02') && <Q02 t={t} send={send} state={state.context} match={createTaskMatch('Q02', state)} />}
      {state.matches('Q03') && <Q03 t={t} send={send} state={state.context} match={createTaskMatch('Q03', state)} />}
      {state.matches('Q04') && <Q04 t={t} send={send} state={state.context} match={createTaskMatch('Q04', state)} />}
      {state.matches('Q05') && <Q05 t={t} send={send} state={state.context} match={createTaskMatch('Q05', state)} />}
      {state.matches('Q06') && <Q06 t={t} send={send} state={state.context} match={createTaskMatch('Q06', state)} />}
      {state.matches('Q07') && <Q07 t={t} send={send} state={state.context} match={createTaskMatch('Q07', state)} />}
      {state.matches('Q08') && <Q08 t={t} send={send} state={state.context} match={createTaskMatch('Q08', state)} />}
    </>
  );
};

export default Task07;
