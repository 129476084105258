import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTaskMachine } from '../../../../lib/hooks/useTaskMachine';
import A020 from './components/A020';
import A030 from './components/A030';
import A040 from './components/A040';
import A050 from './components/A050';
import A060 from './components/A060';
import A080 from './components/A080';
import A081 from './components/A081';
import A082 from './components/A082';
import A083 from './components/A083';
import A090 from './components/A090';
import A100 from './components/A100';
import { createTask02 } from './machine';

const baseNS = 'Game01.Episode02.Task02';
const taskKey = 'Game01.Episode02.Task02';

function createTaskMatch(parent: string, state: any) {
  return (matchState: string) => state.matches(`${parent}.${matchState}`);
}

const Task02: React.FC = () => {
  const { t } = useTranslation([baseNS, 'common']);

  // Setup task machine
  const [state, send] = useTaskMachine(createTask02, {
    taskKey,
  });

  return (
    <>
      {state.matches('A020') && <A020 t={t} send={send} state={state.context} match={createTaskMatch('A020', state)} />}
      {state.matches('A030') && <A030 t={t} send={send} state={state.context} match={createTaskMatch('A030', state)} />}
      {state.matches('A040') && <A040 t={t} send={send} state={state.context} match={createTaskMatch('A040', state)} />}
      {state.matches('A050') && <A050 t={t} send={send} state={state.context} match={createTaskMatch('A050', state)} />}
      {state.matches('A060') && <A060 t={t} send={send} state={state.context} match={createTaskMatch('A060', state)} />}
      {state.matches('A080') && <A080 t={t} send={send} state={state.context} match={createTaskMatch('A080', state)} />}
      {state.matches('A081') && <A081 t={t} send={send} state={state.context} match={createTaskMatch('A081', state)} />}
      {state.matches('A082') && <A082 t={t} send={send} state={state.context} match={createTaskMatch('A082', state)} />}
      {state.matches('A083') && <A083 t={t} send={send} state={state.context} match={createTaskMatch('A083', state)} />}
      {state.matches('A090') && <A090 t={t} send={send} state={state.context} match={createTaskMatch('A090', state)} />}
      {state.matches('A100') && <A100 t={t} send={send} state={state.context} match={createTaskMatch('A100', state)} />}
    </>
  );
};

export default Task02;
