import { selectCurrentGame } from '../../../store/multiplayer-slice';
import { useAppSelector } from '../../../store';

export default function GetLocaleRouter() {
  const currentGame = useAppSelector(selectCurrentGame);
  const currentGameId = currentGame?.shortId;
  let currentGameLocaleText = '';

  if (currentGameId === 'Game01') {
    currentGameLocaleText = 'game01Common';
  } else {
    currentGameLocaleText = 'game02Common';
  }

  return currentGameLocaleText;
}
