import { DefaultButton, Dialog, DialogFooter, DialogType, IDialogContentProps, IDialogStyles, IModalProps, ITextFieldStyles, PrimaryButton, TextField } from '@fluentui/react';
import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { getCurrentGameTheme } from '../../../../theme';
import { greyScale } from '../../../../theme/Global/colors';
import RTrans from '../../RTrans/RTrans';
import { P } from '../../Text';

const theme = getCurrentGameTheme('Global');

export interface IFacilitatorConfirmDialogProps {
  title: string;
  subText: string;
  matchText?: string;
  showDialog: boolean;
  onDismiss: () => void;
  onConfirm: () => void;
}

const modalProps: IModalProps = {
  isBlocking: true,
};

const dialogStyles: IDialogStyles = {
  root: {},
  main: {
    minWidth: '550px !important',
    backgroundColor: greyScale[2],
  },
};

const textFieldStyles: Partial<ITextFieldStyles> = {
  root: {
    marginTop: 20,
  },
  field: {
    fontWeight: 'normal',
  },
};

function generateDialogContentProps(
  t: TFunction, color: string, title: string,
): IDialogContentProps {
  return {
    type: DialogType.normal,
    theme,
    title,
    closeButtonAriaLabel: t('facilitator.close'),
    styles: {
      title: {
        fontSize: 24,
        color,
      },
    },
  };
}

const FacilitatorConfirmDialog: React.FC<IFacilitatorConfirmDialogProps> = (
  { title, subText, matchText, showDialog, onDismiss, onConfirm },
) => {
  const { t } = useTranslation('common');
  const color = theme.palette.themePrimary;
  const [confirmFieldValue, setConfirmFieldValue] = React.useState('');
  const [enableConfirm, setEnableConfirm] = React.useState(false);
  const onChangeConfirmFieldValue = React.useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      setConfirmFieldValue(newValue || '');
      if (matchText !== undefined) {
        if (newValue?.toLowerCase() === matchText.toLowerCase()) {
          setEnableConfirm(true);
        } else {
          setEnableConfirm(false);
        }
      }
    },
    [matchText],
  );
  return (
    <Dialog
      hidden={!showDialog}
      onDismiss={onDismiss}
      dialogContentProps={generateDialogContentProps(t, color, title)}
      modalProps={modalProps}
      theme={theme}
      styles={dialogStyles}
    >
      <P level={3}><RTrans t={t} i18nKey={subText} components={{ bold: <strong />, subText }} /></P>
      {matchText !== undefined && (
        <TextField value={confirmFieldValue} onChange={onChangeConfirmFieldValue} placeholder={t('facilitator.typeConfirm')} styles={textFieldStyles} />
      )}
      <DialogFooter>
        <DefaultButton onClick={onDismiss} text="Cancel" />
        <PrimaryButton
          disabled={matchText !== undefined && !enableConfirm}
          onClick={onConfirm}
          text="Confirm"
        />
      </DialogFooter>
    </Dialog>
  );
};
export default FacilitatorConfirmDialog;
