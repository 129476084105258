import React from 'react';
import { motion } from 'framer-motion';
import { Callout, Text, DirectionalHint, FontWeights } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { NOTIFICATION_TRANSITION_TIME } from '../../../lib/anim-timings';
import { noOp } from '../../../lib/util';
import GetLocaleRouter from '../LocaleRouter/LocaleRouter';
import { getCurrentGameTheme } from '../../../theme';

export interface NavBarButtonProps {
  unread: number;
  target: string;
  isActive: boolean;
  onClick: Function;
  unreadCallout: boolean;
}

const variants = {
  read: { opacity: 0, scale: 0 },
  unread: { opacity: 1, scale: 1 },
};

const NavBarUnread: React.FC<NavBarButtonProps> = ({ unread, target, isActive, onClick = noOp, unreadCallout }) => {
  const show = unread !== -1 && !isActive;
  const currentGameIdText = GetLocaleRouter();
  const theme = getCurrentGameTheme();
  const { t } = useTranslation(currentGameIdText, { keyPrefix: 'navBar' });
  return (
    <>
      {show && unreadCallout && (
        <Callout
          target={`#${target}`}
          isBeakVisible
          // doNotLayer
          onClick={() => onClick()}
          beakWidth={16}
          directionalHint={DirectionalHint.leftCenter}
          styles={{
            root: {
              width: 200,
              padding: '20px',
              textAlign: 'center',
              cursor: 'pointer',
            },
          }}
        >
          <Text
            block
            variant="medium"
            styles={{
              root: {
                fontWeight: FontWeights.bold,
              },
            }}
          >
            {`${unread} ${t(`unread.new${unread < 2 ? 'Entry' : 'Entries'}`)}`}
          </Text>
          <Text block variant="small">
            {t('unread.clickToView')}
          </Text>
        </Callout>
      )}
      <motion.div
        variants={variants}
        initial={{ opacity: 0, scale: 0 }}
        animate={show ? 'unread' : 'read'}
        style={{
          width: 10,
          height: 10,
          left: 8,
          top: -8,
          transformOrigin: 'center',
          position: 'absolute',
          backgroundColor: theme.semanticColors.navbarUnreadNotificationCircle,
          border: '2px solid white',
          borderRadius: 120,
        }}
        transition={{ duration: NOTIFICATION_TRANSITION_TIME / 1000 }}
      />
    </>
  );
};

export default NavBarUnread;
