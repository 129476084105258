import * as React from 'react';
import Screen from '../../../../../components/Global/Screen/Screen';
import Modal from '../../../../../components/Game01/Modal/Modal';
import { ComponentProps } from '../../../../task-types';
import { useMultipleChoice } from '../../../../../components/Global/Interactions/MultipleChoice/MultipleChoice';
import Button from '../../../../../components/Global/Button/Button';
import Dialog from '../../../../../components/Global/Dialog/Dialog';

export default function Q04({ t, send, match }: ComponentProps): JSX.Element {
  const { errorKey, handleIncorrect } = useMultipleChoice(() => send('CLICK.INCORRECT'));
  return (
    <Screen>
      <Dialog title={t('Q04.title')} body={t('Q04.body')} onClick={() => {}} character="andrea" background="office">
        <Button
          isPrimary
          onClick={() => handleIncorrect(1)}
          label={t('Q04.options.1.label')}
          icon={match('final') ? 'check' : undefined}
        />
        <Button
          isPrimary
          label={t('Q04.options.2.label')}
          onClick={() => handleIncorrect(2)}
          disabled={match('final')}
        />
      </Dialog>
      <Modal
        sidebar="character"
        isOpen={match('error')}
        footer={<Button isPrimary label={t('buttons.ok')} onClick={() => send('NEXT')} />}
        onDismiss={() => send('NEXT')}
        title={t(`Q04.options.${errorKey}.error.title`)}
        body={t(`Q04.options.${errorKey}.error.body`)}
        hideDismissButton
      />
    </Screen>
  );
}
