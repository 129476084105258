import { EpisodeDefinition } from '../../../../lib/game-data/types';
import { Phase01Review } from './Phase01Review';
import { Phase02Review } from './Phase02Review';

export const Episode01: EpisodeDefinition = {
  id: 'Episode01',
  defaultcardsIDs: [
    'Game01.Episode01.Player1Card',
    'Game01.Episode01.Player2Card',
    'Game01.Episode01.Player3Card',
    'Game01.Episode01.BestForYouOrganics',
    'Game01.Episode01.EULaunch',
    'Game01.Episode01.AndreaDivkovic',
    'Game01.Episode01.AbigailJackson',
    'Game01.Episode01.OliviaJackson',
    'Game01.Episode01.MontyDubey',
    'Game01.Episode01.TomoTakanashi',
    'Game01.Episode01.TheLeakedFile',
    'Game01.Episode01.AmariRivera',
    'Game01.Episode01.TheRansomer',
  ],
  defaultclueIDs: [
    'Game01.Episode01.AboutBFYO',
    'Game01.Episode01.AboutAndrea',
    'Game01.Episode01.AboutAbigail',
    'Game01.Episode01.AboutOlivia',
    'Game01.Episode01.AboutMonty',
    'Game01.Episode01.AboutTomo',
    'Game01.Episode01.AboutRansomer',
    'Game01.Episode01.TheLeakedFileProperties',
    'Game01.Episode01.AboutAmari',
  ],
  phases: [
    {
      id: 'Phase01',
      leadIds: ['../Task01', '../Task02', '../Task03', '../Task04'],
      review: Phase01Review,
      reviewBasePointValue: 125,
      // reviewTotalValue: 1000
    },
    {
      id: 'Phase02',
      leadIds: ['../Task05', '../Task06', '../Task07', '../Task08', '../Task09'],
      review: Phase02Review,
      reviewBasePointValue: 42,
    },
    {
      id: 'Phase03',
      leadIds: ['../Task10', '../Task11', '../Task12', '../Task13', '../Task14'],
      review: [], // No review for phase03
      reviewBasePointValue: 125,
    },
  ],
  evidence: {
    //
    // Cards & Related Non-Lead Clues for Intro
    //
    Player1Card: {
      type: 'card',
      importance: 'critical',
      linkIds: ['../AndreaDivkovic'],
      image: 'wh_ep1_evidence_player1card',
      detailImage: 'wh_ep1_evidence_back_player1card',
    },
    Player2Card: {
      type: 'card',
      importance: 'critical',
      linkIds: ['../AndreaDivkovic'],
      image: 'wh_ep1_evidence_player2card',
      detailImage: 'wh_ep1_evidence_back_player2card',
    },
    Player3Card: {
      type: 'card',
      importance: 'critical',
      linkIds: ['../AndreaDivkovic'],
      image: 'wh_ep1_evidence_player3card',
      detailImage: 'wh_ep1_evidence_back_player3card',
    },
    BestForYouOrganics: {
      type: 'card',
      importance: 'critical',
      linkIds: [],
      unlockIds: ['../AboutBFYO.BFYOFact1', '../AboutBFYO.BFYOFact2', '../AboutBFYO.BFYOFact3'],
      image: 'wh_ep1_evidence_bestforyouorganics',
      detailImage: 'wh_ep1_evidence_back_bestforyouorganics',
    },
    AboutBFYO: {
      type: 'clue',
      importance: 'critical',
      locationId: '../BestForYouOrganics',
      facts: {
        BFYOFact1: {
          importance: 'critical',
          linkIds: ['../../AbigailJackson', '../../OliviaJackson'],
        },
        BFYOFact2: { importance: 'critical' },
        BFYOFact3: {
          importance: 'critical',
          unlockIds: ['../../EULaunch'],
        },
      },
    },
    EULaunch: {
      type: 'card',
      importance: 'critical',
      linkIds: ['../BestForYouOrganics'],
      image: 'wh_ep1_evidence_eulaunch',
      detailImage: 'wh_ep1_evidence_back_eulaunch',
    },
    AndreaDivkovic: {
      type: 'card',
      importance: 'critical',
      unlockIds: ['../AboutAndrea.AndreaFact1', '../AboutAndrea.AndreaFact2', '../AboutAndrea.AndreaFact3'],
      image: 'wh_ep1_evidence_andreadivkovic',
      detailImage: 'wh_ep1_evidence_back_andreadivkovic',
    },
    AboutAndrea: {
      type: 'clue',
      importance: 'critical',
      locationId: '../AndreaDivkovic',
      unlockIds: ['../AndreaDivkovic'],
      facts: {
        AndreaFact1: {
          importance: 'critical',
          linkIds: ['../../AbigailJackson'],
        },
        AndreaFact2: {
          importance: 'critical',
          linkIds: ['../../EULaunch'],
          unlockIds: ['../../EULaunch'],
        },
        AndreaFact3: {
          importance: 'critical',
          linkIds: ['../../Player1Card', '../../Player2Card', '../../Player3Card'],
        },
      },
    },
    AbigailJackson: {
      type: 'card',
      importance: 'critical',
      unlockIds: ['../AboutAbigail.AbigailFact1', '../AboutAbigail.AbigailFact2', '../AboutAbigail.AbigailFact3'],
      image: 'wh_ep1_evidence_abigailjackson',
      detailImage: 'wh_ep1_evidence_back_abigailjackson',
    },
    AboutAbigail: {
      type: 'clue',
      importance: 'critical',
      locationId: '../AbigailJackson',
      facts: {
        AbigailFact1: {
          importance: 'critical',
          linkIds: ['../../BestForYouOrganics'],
        },
        AbigailFact2: {
          importance: 'critical',
          linkIds: ['../../OliviaJackson'],
        },
        AbigailFact3: { importance: 'critical' },
      },
    },
    OliviaJackson: {
      type: 'card',
      importance: 'critical',
      unlockIds: ['../AboutOlivia.OliviaFact1', '../AboutOlivia.OliviaFact2', '../AboutOlivia.OliviaFact3'],
      image: 'wh_ep1_evidence_oliviajackson',
      detailImage: 'wh_ep1_evidence_back_oliviajackson',
    },
    AboutOlivia: {
      type: 'clue',
      importance: 'critical',
      locationId: '../OliviaJackson',
      facts: {
        OliviaFact1: { importance: 'critical' },
        OliviaFact2: { importance: 'critical' },
        OliviaFact3: { importance: 'critical' },
      },
    },
    MontyDubey: {
      type: 'card',
      importance: 'critical',
      unlockIds: ['../AboutMonty.MontyFact1', '../AboutMonty.MontyFact2', '../AboutMonty.MontyFact3'],
      image: 'wh_ep1_evidence_montydubey',
      detailImage: 'wh_ep1_evidence_back_montydubey',
    },
    AboutMonty: {
      type: 'clue',
      importance: 'critical',
      locationId: '../MontyDubey',
      facts: {
        MontyFact1: {
          importance: 'critical',
          linkIds: ['../../AbigailJackson'],
        },
        MontyFact2: { importance: 'critical' },
        MontyFact3: { importance: 'critical' },
      },
    },
    TomoTakanashi: {
      type: 'card',
      importance: 'critical',
      unlockIds: ['../AboutTomo.TomoFact1', '../AboutTomo.TomoFact2', '../AboutTomo.TomoFact3'],
      image: 'wh_ep1_evidence_tomotakanashi',
      detailImage: 'wh_ep1_evidence_back_tomotakanashi',
    },
    AboutTomo: {
      type: 'clue',
      importance: 'critical',
      locationId: '../TomoTakanashi',
      facts: {
        TomoFact1: {
          importance: 'critical',
          linkIds: ['../../AbigailJackson'],
        },
        TomoFact2: { importance: 'critical' },
        TomoFact3: { importance: 'critical' },
      },
    },
    TheRansomer: {
      type: 'card',
      importance: 'critical',
      unlockIds: ['../AboutRansomer.RansomerGoal', '../AboutRansomer.RansomerFile'],
      image: 'wh_ep1_evidence_theransomer',
      detailImage: 'wh_ep1_evidence_back_theransomer',
    },
    AboutRansomer: {
      type: 'clue',
      importance: 'critical',
      locationId: '../TheRansomer',
      facts: {
        RansomerGoal: {
          importance: 'critical',
          linkIds: ['../../BestForYouOrganics'],
        },
        RansomerFile: {
          importance: 'critical',
          linkIds: ['../../TheLeakedFile'],
        },
      },
    },
    TheLeakedFile: {
      type: 'card',
      importance: 'critical',
      unlockIds: [
        '../TheLeakedFileProperties.LeakedFileTitle',
        '../TheLeakedFileProperties.LeakedFileAuthor',
        '../TheLeakedFileProperties.LeakedFileProgram',
        '../TheLeakedFileProperties.LeakedFileCreated',
        '../TheLeakedFileProperties.LeakedFileSaved',
      ],
      image: 'wh_ep1_evidence_theleakedfile',
      detailImage: 'wh_ep1_evidence_back_theleakedfile',
    },
    TheLeakedFileProperties: {
      type: 'clue',
      importance: 'bonus',
      locationId: '../TheLeakedFile',
      facts: {
        LeakedFileTitle: { importance: 'critical' },
        LeakedFileAuthor: {
          importance: 'critical',
          linkIds: ['../../AmariRivera'],
          unlockIds: ['../../AmariRivera'],
        },
        LeakedFileProgram: { importance: 'irrelevant' },
        LeakedFileCreated: { importance: 'bonus' },
        LeakedFileSaved: { importance: 'bonus' },
      },
    },
    //
    // Cards & Related Non-Lead Clues for Phase 1+
    //
    AmariRivera: {
      type: 'card',
      importance: 'critical',
      unlockIds: ['../AboutAmari.AmariReport'],
      image: 'wh_ep1_evidence_amaririvera',
      detailImage: 'wh_ep1_evidence_back_amaririvera',
    },
    AboutAmari: {
      type: 'clue',
      importance: 'bonus',
      locationId: '../AmariRivera',
      facts: {
        AmariReport: { importance: 'bonus' },
      },
    },
    pc105: {
      type: 'card',
      importance: 'critical',
      image: 'wh_ep1_evidence_pc105',
      detailImage: 'wh_ep1_evidence_back_pc105',
    },
    BFYOAdmin: {
      type: 'card',
      importance: 'critical',
      image: 'wh_ep1_evidence_bfyoadmin',
      detailImage: 'wh_ep1_evidence_back_bfyoadmin',
    },
    PatchEXE: {
      type: 'card',
      importance: 'critical',
      linkIds: ['../pc105'],
      image: 'wh_ep1_evidence_patchexe',
      detailImage: 'wh_ep1_evidence_back_patchexe',
    },
    SuspiciousIP: {
      type: 'card',
      importance: 'critical',
      image: 'wh_ep1_evidence_suspiciousip',
      detailImage: 'wh_ep1_evidence_back_suspiciousip',
    },
    NestorWilke: {
      type: 'card',
      importance: 'critical',
      unlockIds: ['../AboutNestor.NestorReport'],
      image: 'wh_ep1_evidence_nestorwilke',
      detailImage: 'wh_ep1_evidence_back_nestorwilke',
    },
    AboutNestor: {
      type: 'clue',
      importance: 'bonus',
      locationId: '../NestorWilke',
      facts: {
        NestorReport: {
          importance: 'bonus',
          linkIds: ['../../GaelTorres'],
          unlockIds: ['../../GaelTorres'],
        },
      },
    },
    GaelTorres: {
      type: 'card',
      importance: 'bonus',
      unlockIds: ['../AboutGael.GaelReport'],
      image: 'wh_ep1_evidence_gaeltorres',
      detailImage: 'wh_ep1_evidence_back_gaeltorres',
    },
    AboutGael: {
      type: 'clue',
      importance: 'bonus',
      locationId: '../GaelTorres',
      facts: {
        GaelReport: {
          importance: 'bonus',
          linkIds: ['../../AbigailJackson'],
        },
      },
    },
    EmilyBraun: {
      type: 'card',
      importance: 'critical',
      unlockIds: ['../AboutEmily.EmilyReport'],
      image: 'wh_ep1_evidence__emilybraun',
      detailImage: 'wh_ep1_evidence_back__emilybraun',
    },
    AboutEmily: {
      type: 'clue',
      importance: 'bonus',
      locationId: '../EmilyBraun',
      facts: {
        EmilyReport: {
          importance: 'bonus',
          linkIds: ['../../GaelTorres'],
          unlockIds: ['../../GaelTorres'],
        },
      },
    },
    //
    // Cards & Related Non-Lead Clues for Phase 2+
    //
    ShoppingListZIP: {
      type: 'card',
      importance: 'critical',
      linkIds: ['../pc105'],
      image: 'wh_ep1_evidence__shoppinglistzip',
      detailImage: 'wh_ep1_evidence_back__shoppinglistzip',
    },
    AngelBrown: {
      type: 'card',
      importance: 'critical',
      unlockIds: ['../AboutAngel.AngelReport'],
      image: 'wh_ep1_evidence_angelbrown',
      detailImage: 'wh_ep1_evidence_back_angelbrown',
    },
    AboutAngel: {
      type: 'clue',
      importance: 'bonus',
      locationId: '../AngelBrown',
      facts: {
        AngelReport: {
          importance: 'bonus',
        },
      },
    },
    //
    // Cards & Related Non-Lead Clues for Phase 3+
    //
    PolicySettings: {
      type: 'card',
      importance: 'critical',
      image: 'wh_ep1_evidence_policysettings',
      detailImage: 'wh_ep1_evidence_back_policysettings',
    },
    pc067: {
      type: 'card',
      importance: 'critical',
      image: 'wh_ep1_evidence_pc067',
      detailImage: 'wh_ep1_evidence_back_pc067',
    },
    RDPConnection: {
      type: 'card',
      importance: 'critical',
      image: 'wh_ep1_evidence_rdpconnection',
      detailImage: 'wh_ep1_evidence_back_rdpconnection',
    },
    pc034: {
      type: 'card',
      importance: 'critical',
      image: 'wh_ep1_evidence_pc034',
      detailImage: 'wh_ep1_evidence_back_pc034',
    },
    AlexsBirthdayParty: {
      type: 'card',
      importance: 'critical',
      linkIds: ['../AlexGagnon'],
      image: 'wh_ep1_evidence_alexsbirthdayparty',
      detailImage: 'wh_ep1_evidence_back_alexsbirthdayparty',
    },
    AlexGagnon: {
      type: 'card',
      importance: 'bonus',
      unlockIds: ['../AboutAlex'],
      image: 'wh_ep1_evidence_alexgagnon',
      detailImage: 'wh_ep1_evidence_back_alexgagnon',
    },
    AboutAlex: {
      type: 'clue',
      importance: 'bonus',
      locationId: '../AlexGagnon',
    },
    QuinnAnderson: {
      type: 'card',
      importance: 'bonus',
      unlockIds: ['../AboutQuinn'],
      image: 'wh_ep1_evidence__quinnanderson',
      detailImage: 'wh_ep1_evidence_back__quinnanderson',
    },
    AboutQuinn: {
      type: 'clue',
      importance: 'bonus',
      locationId: '../QuinnAnderson',
    },
  },
  leads: {
    Task01: {
      // not using for non-sim tasks
      baseScore: 10,
      evidence: {
        LeakedFileSearchResults: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../TheLeakedFile',
          unlockIds: ['../../TheLeakedFile'],
          facts: {
            AmariNotes: { importance: 'irrelevant' },
            PurchasingDataQ1XLSX: {
              importance: 'critical',
              linkIds: ['../../../AmariRivera'],
            },
            SharedNotes: { importance: 'irrelevant' },
            DataRequest: { importance: 'irrelevant' },
          },
        },
      },
    },
    Task02: {
      flags: ['simulation'],
      baseScore: 10,
      // Evidence from simulations should use IDs for items and facts that match 3Sharp variable IDs *exactly*.
      // This way, evidence collection event handling can be (mostly) automated.
      evidence: {
        SentinelLogSecurityAlertDetails: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../pc105',
          facts: {
            SentinelLogSecAlertTime: { importance: 'critical' },
            SentinelLogSecAlertDisplayname: { importance: 'critical' },
            SentinelLogSecAlertLink: { importance: 'critical' },
            SentinelLogSecAlertCompromisedEntity: {
              importance: 'critical',
              unlockIds: ['../../../pc105'],
            },
            SentinelLogSecVendorName: { importance: 'irrelevant' },
            SentinelLogSecTenantID: { importance: 'irrelevant' },
          },
        },
        UnfamiliarSignInIncidentDetails: {
          type: 'clue',
          importance: 'irrelevant',
          locationId: '../../BFYOAdmin',
          facts: {
            UnfamSignInTime: { importance: 'irrelevant' },
            UnfamSignInName: {
              importance: 'irrelevant',
              unlockIds: ['../../../BFYOAdmin'],
            },
            UnfamSignInIP: { importance: 'irrelevant' },
          },
        },
        MultiStageIncidentDetails: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../pc105',
          facts: {
            MultiStageIncidentTime: { importance: 'critical' },
            MultiStageIncidentEntity1: {
              importance: 'critical',
              linkIds: ['../../../AmariRivera'],
            },
            MultiStageIncidentEntity2: { importance: 'critical' },
            MultiStageIncidentEntity3: {
              importance: 'critical',
              linkIds: ['../../../PatchEXE'],
              unlockIds: ['../../../PatchEXE'],
            },
          },
        },
        PasswordSprayIncident: {
          type: 'clue',
          importance: 'bonus',
          locationId: '../../AmariRivera',
          facts: {
            PasswordSprayIncidentTime: { importance: 'bonus' },
            PasswordSprayIncidentEntity1: { importance: 'bonus' },
            PasswordSprayIncidentEntity2: { importance: 'bonus' },
          },
        },
        DefenderIncidentDetailsforEvidenceandResponse: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../pc105',
          facts: {
            DefenderEvidenceVerdict: { importance: 'critical' },
            DefenderEvidenceProcess: {
              importance: 'critical',
              linkIds: ['../../../PatchEXE'],
              unlockIds: ['../../../PatchEXE'],
            },
            DefenderEvidenceProcessID: { importance: 'critical' },
            DefenderEvidenceDevice: { importance: 'critical' },
          },
        },
        DeviceTimelineResults: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../pc105',
          facts: {
            DeviceTimelineEventUpdatesXML: { importance: 'irrelevant' },
            DeviceTimelineEventUpdatesXMLTime: { importance: 'irrelevant' },
            DeviceTimelineEventPatchRead: {
              importance: 'critical',
              linkIds: ['../../../PatchEXE', '../../../ShoppingListZIP'],
              unlockIds: ['../../../PatchEXE'],
            },
            DeviceTimelineEventPatchReadTime: { importance: 'critical' },
            DeviceTimelineEventMaliciousPowerShell: { importance: 'critical' },
            DeviceTimelineEventMaliciousPowerShellTime: { importance: 'critical' },
            DeviceTimelineEventMeterpreter: { importance: 'critical' },
            DeviceTimelineEventMeterpreterTime: { importance: 'critical' },
            DeviceTimelineEventPatchConnection443: {
              importance: 'critical',
              linkIds: ['../../../PatchEXE', '../../../SuspiciousIP'],
              unlockIds: ['../../../PatchEXE', '../../../SuspiciousIP'],
            },
            DeviceTimelineEventPatchConnection443Time: { importance: 'critical' },
            DeviceTimelineEventCurlCreated: {
              importance: 'critical',
              linkIds: ['../../../PatchEXE'],
              unlockIds: ['../../../PatchEXE'],
            },
            DeviceTimelineEventCurlCreatedTime: { importance: 'critical' },
            DeviceTimelineEventCurlCommand: { importance: 'critical' },
          },
        },
      },
    },
    Task03: {
      flags: ['simulation'],
      baseScore: 16,
      // Evidence from simulations should use IDs for items and states that match 3Sharp variable IDs *exactly*.
      // This way, evidence collection event handling can be (mostly) automated.
      evidence: {
        RiskyUserDetailsNestor: {
          type: 'clue',
          importance: 'irrelevant',
          locationId: '../../NestorWilke',
          unlockIds: ['../../NestorWilke'],
          facts: {
            RiskyUserDetailsUsernameNestor: { importance: 'irrelevant' },
            RiskyUserDetailsRisklevelNestor: { importance: 'irrelevant' },
            RiskyUserDetailsRisklastupdatedNestor: { importance: 'irrelevant' },
          },
        },
        RiskyUserDetailsAmari: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../AmariRivera',
          unlockIds: ['../../AmariRivera'],
          facts: {
            RiskyUserDetailsUsernameAmari: { importance: 'critical' },
            RiskyUserDetailsRisklevelAmari: { importance: 'critical' },
            RiskyUserDetailsRisklastupdatedAmari: { importance: 'critical' },
          },
        },
        RiskyUserDetailsEmily: {
          type: 'clue',
          importance: 'irrelevant',
          locationId: '../../EmilyBraun',
          unlockIds: ['../../EmilyBraun'],
          facts: {
            RiskyUserDetailsUsernameEmily: { importance: 'irrelevant' },
            RiskyUserDetailsRisklevelEmily: { importance: 'irrelevant' },
            RiskyUserDetailsRisklastupdatedEmily: { importance: 'irrelevant' },
          },
        },
        RiskInfoNestor: {
          type: 'clue',
          importance: 'bonus',
          locationId: '../../NestorWilke',
          unlockIds: ['../../NestorWilke'],
          facts: {
            RiskInfoRiskLevelNestor: { importance: 'bonus' },
            RiskInfoSigninTimeNestor: { importance: 'bonus' },
            RiskInfoIPaddressNestor: { importance: 'bonus' },
            RiskInfoSigninLocationNestor: { importance: 'bonus' },
          },
        },
        RiskInfoEmily: {
          type: 'clue',
          importance: 'bonus',
          locationId: '../../EmilyBraun',
          unlockIds: ['../../EmilyBraun'],
          facts: {
            RiskInfoRiskLevelEmily: { importance: 'bonus' },
            RiskInfoSigninTimeEmily: { importance: 'bonus' },
            RiskInfoIPaddressEmily: { importance: 'bonus' },
            RiskInfoSigninLocationEmily: { importance: 'bonus' },
          },
        },
        RiskDetectionsAmari: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../AmariRivera',
          unlockIds: ['../../AmariRivera'],
          facts: {
            RiskDetectionAmariDetectionType: { importance: 'critical' },
            RiskDetectionsAmariRisklevel: { importance: 'critical' },
            RiskDetectionsAmariDetectiontiming: { importance: 'critical' },
            RiskDetectionsAmariSigninTime: { importance: 'critical' },
            RiskDetectionsAmariIPaddress: { importance: 'critical' },
            RiskDetectionsAmariSigninLocation: { importance: 'critical' },
            RiskDetectionsAmariSigninRequestID: { importance: 'critical' },
          },
        },
        AmariCompromiseConfirmed: {
          type: 'clue',
          importance: 'bonus',
          locationId: '../../AmariRivera',
          unlockIds: ['../../AmariRivera'],
          facts: {
            AmariCompromiseconfirmed: { importance: 'bonus' },
          },
        },
        AmariTemporaryPassword: {
          type: 'clue',
          importance: 'bonus',
          locationId: '../../AmariRivera',
          unlockIds: ['../../AmariRivera'],
          facts: {
            AmariTemporaryPassword: { importance: 'bonus' },
          },
        },
      },
    },
    Task04: {
      // not using for non-sim
      baseScore: 10,
      flags: ['protection'],
      evidence: {},
    },
    Task05: {
      // not using for non-sim
      baseScore: 10,
      flags: ['protection'],
      evidence: {},
    },
    Task06: {
      flags: ['simulation'],
      baseScore: 4.5,
      // Evidence from simulations should use IDs for items and states that match 3Sharp variable IDs *exactly*.
      // This way, evidence collection event handling can be (mostly) automated.
      evidence: {
        DeviceNetworkEvent1: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../SuspiciousIP',
          unlockIds: ['../../SuspiciousIP'],
          facts: {
            DNE1DeviceName: {
              importance: 'critical',
              linkIds: ['../../../pc105'],
              unlockIds: ['../../../pc105'],
            },
            DNE1_User: {
              importance: 'critical',
              linkIds: ['../../../AmariRivera'],
              unlockIds: ['../../../AmariRivera'],
            },
            DNE1_Table: { importance: 'critical' },
            DNE1_Time: { importance: 'critical' },
            DNE1_RemoteIP: { importance: 'critical' },
            DNE1_ActionType: { importance: 'critical' },
            DNE1_Process: {
              importance: 'critical',
              linkIds: ['../../../PatchEXE'],
              unlockIds: ['../../../PatchEXE'],
            },
            DNE1_FolderPath: {
              importance: 'critical',
              linkIds: ['../../../PatchEXE'],
              unlockIds: ['../../../PatchEXE'],
            },
            DNE1_RemotePort: { importance: 'bonus' },
          },
        },
        DeviceNetworkEvent2: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../SuspiciousIP',
          unlockIds: ['../../SuspiciousIP'],
          facts: {
            DNE2_DeviceName: {
              importance: 'critical',
              linkIds: ['../../../pc105'],
              unlockIds: ['../../../pc105'],
            },
            DNE2_User: {
              importance: 'critical',
              linkIds: ['../../../AmariRivera'],
              unlockIds: ['../../../AmariRivera'],
            },
            DNE2_Table: { importance: 'critical' },
            DNE2_Time: { importance: 'critical' },
            DNE2_RemoteIP: { importance: 'critical' },
            DNE2_ActionType: { importance: 'critical' },
            DNE2_Process: {
              importance: 'critical',
              linkIds: ['../../../PatchEXE'],
              unlockIds: ['../../../PatchEXE'],
            },
            DNE2_FolderPath: {
              importance: 'critical',
              linkIds: ['../../../PatchEXE'],
              unlockIds: ['../../../PatchEXE'],
            },
            DNE2_RemotePort: { importance: 'bonus' },
          },
        },
        DeviceEvents1: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../SuspiciousIP',
          unlockIds: ['../../SuspiciousIP'],
          facts: {
            DE1_DeviceName: {
              importance: 'critical',
              linkIds: ['../../../pc105'],
              unlockIds: ['../../../pc105'],
            },
            DE1_User: {
              importance: 'critical',
              linkIds: ['../../../AmariRivera'],
              unlockIds: ['../../../AmariRivera'],
            },
            DE1_Table: { importance: 'critical' },
            DE1_Time: { importance: 'critical' },
            DE1_FileName: { importance: 'critical' },
            DE1_ProcessCommandLine: {
              importance: 'critical',
              linkIds: ['../../../PatchEXE'],
              unlockIds: ['../../../PatchEXE'],
            },
            DE1_ProcessCreationTime: { importance: 'bonus' },
          },
        },
        DeviceEvents2: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../SuspiciousIP',
          unlockIds: ['../../SuspiciousIP'],
          facts: {
            DE2_DeviceName: {
              importance: 'critical',
              linkIds: ['../../../pc105'],
              unlockIds: ['../../../pc105'],
            },
            DE2_User: {
              importance: 'critical',
              linkIds: ['../../../AmariRivera'],
              unlockIds: ['../../../AmariRivera'],
            },
            DE2_Table: { importance: 'critical' },
            DE2_Time: { importance: 'critical' },
            DE2_FileName: {
              importance: 'critical',
              linkIds: ['../../../PatchEXE'],
              unlockIds: ['../../../PatchEXE'],
            },
            DE2_InitiatingProcessCommandLine: {
              importance: 'critical',
              linkIds: ['../../../PatchEXE'],
              unlockIds: ['../../../PatchEXE'],
            },
            DE2_InitiatingProcessCreationTime: { importance: 'bonus' },
          },
        },
        DeviceEvents3: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../SuspiciousIP',
          unlockIds: ['../../SuspiciousIP'],
          facts: {
            DE3_DeviceName: {
              importance: 'critical',
              linkIds: ['../../../pc105'],
              unlockIds: ['../../../pc105'],
            },
            DE3_User: {
              importance: 'critical',
              linkIds: ['../../../AmariRivera'],
              unlockIds: ['../../../AmariRivera'],
            },
            DE3_Table: { importance: 'critical' },
            DE3_Time: { importance: 'critical' },
            DE3_FileName: { importance: 'critical' },
            DE3_ProcessCommandLine: {
              importance: 'critical',
              linkIds: ['../../../PatchEXE'],
              unlockIds: ['../../../PatchEXE'],
            },
          },
        },
        DeviceFileEvents1: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../SuspiciousIP',
          facts: {
            DFE1_DeviceName: {
              importance: 'critical',
              linkIds: ['../../../pc105'],
              unlockIds: ['../../../pc105'],
            },
            DFE1_User: {
              importance: 'critical',
              linkIds: ['../../../AmariRivera'],
              unlockIds: ['../../../AmariRivera'],
            },
            DFE1_Table: { importance: 'critical' },
            DFE1_Time: { importance: 'critical' },
            DFE1_FileName: {
              importance: 'critical',
              linkIds: ['../../../PatchEXE'],
              unlockIds: ['../../../PatchEXE'],
            },
            DFE1_FolderPath: {
              importance: 'critical',
              linkIds: ['../../../PatchEXE'],
              unlockIds: ['../../../PatchEXE'],
            },
            DFE1_ActionType: { importance: 'critical' },
            DFE1_InitiatingProcessFileName: { importance: 'critical' },
            DFE1_InitiatingProcessCommandLine: {
              importance: 'critical',
              linkIds: ['../../../PatchEXE'],
              unlockIds: ['../../../PatchEXE'],
            },
          },
        },
        pc105InventoryStatus: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../pc105',
          facts: {
            pc105RiskLevel: { importance: 'irrelevant' },
            pc105Alerts: { importance: 'irrelevant' },
            pc105Alert1Title: { importance: 'critical' },
            pc105Alert1Severity: { importance: 'bonus' },
            pc105Alert1Status: { importance: 'irrelevant' },
            pc105Alert2Title: { importance: 'critical' },
            pc105Alert2Severity: { importance: 'bonus' },
            pc105Alert2Status: { importance: 'irrelevant' },
            pc105Alert3Title: { importance: 'critical' },
            pc105Alert3Severity: { importance: 'bonus' },
            pc105Alert3Status: { importance: 'irrelevant' },
          },
        },
        pc105LiveResponse: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../ShoppingListZIP',
          unlockIds: ['../../ShoppingListZIP'],
          facts: {
            pc105LRFileName: {
              importance: 'critical',
              linkIds: ['../../../PatchEXE'],
              unlockIds: ['../../../PatchEXE'],
            },
            pc105LRFolder: { importance: 'critical' },
            pc105LRZipFile: { importance: 'critical' },
            pc105ExfiltratedFile1: {
              importance: 'critical',
              linkIds: ['../../../TheLeakedFile'],
            },
            pc105ExfiltratedFile2: { importance: 'bonus' },
            pc105ExfiltratedFile3: { importance: 'bonus' },
            pc105ExfiltratedFile4: { importance: 'bonus' },
            pc105ExfiltratedFile5: { importance: 'bonus' },
            pc105ExfiltratedFile6: { importance: 'bonus' },
            pc105ExfiltratedFile7: { importance: 'bonus' },
          },
        },
      },
    },
    Task07: {
      flags: ['simulation'],
      baseScore: 62.5,
      evidence: {
        TeamsMessage: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../AngelBrown',
          unlockIds: ['../../AngelBrown'],
          facts: {
            TeamsMessageName: { importance: 'critical' },
            TeamsMessageDate: { importance: 'critical' },
            TeamsMessageBody: {
              importance: 'critical',
              linkIds: ['../../../PatchEXE', '../../../AmariRivera', '../../../SuspiciousIP'],
            },
          },
        },
      },
    },
    Task08: {
      flags: ['simulation'],
      baseScore: 62.5,
      evidence: {
        SentinelLogOtherConnectionsToIP: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../SuspiciousIP',
          facts: {
            SentinelLogNoEvidenceOfOtherConnections: {
              importance: 'critical',
              linkIds: ['../../../pc105'],
              unlockIds: ['../../../pc105'],
            },
          },
        },
        NewAnalyticsRule: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../SuspiciousIP',
          facts: {
            NewAnalyticsRuleName: { importance: 'critical' },
            NewAnalyticsRuleQuery: { importance: 'critical' },
          },
        },
      },
    },
    Task09: {
      // not using for non-sim
      baseScore: 10,
      flags: ['protection'],
      evidence: {},
    },
    Task10: {
      baseScore: 19.5,
      flags: ['simulation', 'protection'],
      evidence: {
        UserRiskPolicySettings: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../PolicySettings',
          unlockIds: ['../../PolicySettings'],
          facts: {
            IdentityProtectionUserriskpolicyAssignmentsUsers: {
              importance: 'critical',
              unlockIds: ['../../../PolicySettings'],
            },
            IdentityProtectionUserriskpolicyAssignmentsUserrisk: {
              importance: 'critical',
              unlockIds: ['../../../PolicySettings'],
            },
            IdentityProtectionUserriskpolicyControlsAccess: {
              importance: 'critical',
              unlockIds: ['../../../PolicySettings'],
            },
            IdentityProtectionUserriskpolicyEnforcepolicy: {
              importance: 'critical',
              unlockIds: ['../../../PolicySettings'],
            },
          },
        },
        IPSigninRiskPolicy: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../PolicySettings',
          unlockIds: ['../../PolicySettings'],
          facts: {
            IPSigninRiskPolicyAssignmentUsers: {
              importance: 'critical',
              unlockIds: ['../../../PolicySettings'],
            },
            IPSigninRiskPolicyAssignmentSignInRiskMedium: {
              importance: 'bonus',
              unlockIds: ['../../../PolicySettings'],
            },
            IPSigninRiskPolicyAssignmentSignInRiskHigh: {
              importance: 'bonus',
              unlockIds: ['../../../PolicySettings'],
            },
            IPSigninRiskPolicyControlsRequireMFA: {
              importance: 'critical',
              unlockIds: ['../../../PolicySettings'],
            },
            IPSigninRiskPolicyEnforcePolicyOn: {
              importance: 'critical',
              unlockIds: ['../../../PolicySettings'],
            },
          },
        },
      },
    },
    Task11: {
      // not using for non-sim
      baseScore: 10,
      flags: ['protection'],
      evidence: {
        User2Secure: {
          importance: 'critical',
          locationId: '../../AngelBrown',
          unlockIds: ['../../AngelBrown'],
        },
      },
    },
    Task12: {
      baseScore: 17,
      flags: ['simulation'],
      evidence: {
        SecurityAlertsAngelBrown: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../AngelBrown',
          unlockIds: ['../../AngelBrown'],
          facts: {
            AngelHasNoSecurityAlerts: {
              importance: 'critical',
              unlockIds: ['../../../AngelBrown'],
            },
          },
        },
        DeviceNameAngelBrown: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../AngelBrown',
          unlockIds: ['../../AngelBrown'],
          facts: {
            AngelDeviceispc067: {
              importance: 'critical',
              linkIds: ['../../../pc067'],
              unlockIds: ['../../../AngelBrown'],
            },
          },
        },
        SecurityAlertspc067: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../pc067',
          unlockIds: ['../../pc067'],
          facts: {
            pc067HasNoSecurityAlerts: {
              importance: 'critical',
              unlockIds: ['../../../pc067'],
            },
          },
        },
        AlertInfopc067: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../pc067',
          unlockIds: ['../../pc067'],
          facts: {
            pc067NoAlertInfo: {
              importance: 'critical',
              unlockIds: ['../../../pc067'],
            },
          },
        },
        AlertEvidencepc067: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../pc067',
          unlockIds: ['../../pc067'],
          facts: {
            pc067NoAlertEvidence: {
              importance: 'critical',
              unlockIds: ['../../../pc067'],
            },
          },
        },
        pc067DeviceSummary: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../pc067',
          unlockIds: ['../../pc067'],
          facts: {
            pc067ZeroOpenIncidents: { importance: 'critical' },
            pc067ZeroActiveAlerts: { importance: 'critical' },
            pc067NoRiskLevel: { importance: 'critical' },
            pc067IPaddress: { importance: 'critical' },
          },
        },
        SourceIPRDPConnection: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../RDPConnection',
          unlockIds: ['../../RDPConnection'],
          facts: {
            SourceIPaddressofRDPConnection: {
              importance: 'critical',
              linkIds: ['../../../pc034'],
              unlockIds: ['../../../RDPConnection'],
            },
          },
        },
        pc067RecordInspection: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../RDPConnection',
          linkIds: ['../../pc067'],
          facts: {
            DeviceNamepc034: {
              importance: 'critical',
              linkIds: ['../../../pc034'],
              unlockIds: ['../../../pc034'],
            },
            pc034PublicIP: {
              importance: 'critical',
              linkIds: ['../../../pc034'],
              unlockIds: ['../../../pc034'],
            },
            pc034OwnerTomotakanashi: {
              importance: 'critical',
              linkIds: ['../../../pc034'],
              unlockIds: ['../../../pc034'],
            },
          },
        },
        pc034ExposureLevel: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../pc034',
          unlockIds: ['../../pc034'],
          facts: {
            pc034Exposurelevel: {
              importance: 'critical',
              unlockIds: ['../../../pc034'],
            },
          },
        },
      },
    },
    Task13: {
      baseScore: 50,
      flags: ['simulation'],
      evidence: {
        QuinnGatheringInvite: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../AlexsBirthdayParty',
          linkIds: ['../../QuinnAnderson'],
          unlockIds: ['../../AlexsBirthdayParty', '../../QuinnAnderson'],
          facts: {
            QuinnAGatheringInviteAttendees: {
              importance: 'critical',
              linkIds: ['../../../AmariRivera', '../../../AngelBrown', '../../../GaelTorres', '../../../TomoTakanashi'],
              unlockIds: ['../../../GaelTorres'],
            },
            QuinnAGatheringInviteMtgDate: {
              importance: 'critical',
              unlockIds: ['../QuinnAGatheringInviteMtgTime'],
            },
            QuinnAGatheringInviteMtgTime: { importance: 'critical' },
            QuinnAGatheringInviteWhere: { importance: 'critical' },
          },
        },
        AngelBrownGatheringAcceptance: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../AngelBrown',
          linkIds: ['../../AlexsBirthdayParty'],
          facts: {
            AngelGatheringAcceptance: { importance: 'critical' },
          },
        },
      },
    },
    Task14: {
      // not using for non-sim
      baseScore: 10,
      flags: ['protection'],
      evidence: {
        TomosSecurityAlerts: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../TomoTakanashi',
          facts: {
            TomoNoAlerts: {
              importance: 'critical',
            },
          },
        },
        TomosDevice: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../TomoTakanashi',
          facts: {
            OnlyDevice: {
              importance: 'critical',
              linkIds: ['../../../pc034'],
            },
          },
        },
        pc034sSecurityAlerts: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../pc034',
          unlockIds: ['../../pc034'],
          facts: {
            pc034NoAlerts: {
              importance: 'critical',
            },
          },
        },
        pc034sRDPLog: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../RDPConnection',
          unlockIds: ['../../RDPConnection'],
          facts: {
            pc034RDP: {
              importance: 'critical',
              linkIds: ['../../../pc034'],
            },
            PartyTime: {
              importance: 'critical',
              linkIds: ['../../../AlexsBirthdayParty'],
            },
          },
        },
      },
    },
  },
  mapPositions: {
    ProtectionNode: {
      x: -720,
      y: 208,
    },
    Player1Card: {
      x: -224,
      y: 352,
    },
    Player2Card: {
      x: -224,
      y: 592,
    },
    Player3Card: {
      x: -224,
      y: 832,
    },
    BestForYouOrganics: {
      x: 1344,
      y: 0,
    },
    EULaunch: {
      x: 128,
      y: 0,
    },
    AndreaDivkovic: {
      x: 128,
      y: 592,
    },
    AbigailJackson: {
      x: 528,
      y: 160,
    },
    OliviaJackson: {
      x: 1344,
      y: 592,
    },
    MontyDubey: {
      x: 384,
      y: 592,
    },
    TomoTakanashi: {
      x: 656,
      y: 592,
    },
    TheRansomer: {
      x: 1936,
      y: 0,
    },
    TheLeakedFile: {
      x: 1952,
      y: 1296,
    },
    AmariRivera: {
      x: 1680,
      y: 1296,
    },
    pc105: {
      x: 1680,
      y: 1584,
    },
    BFYOAdmin: {
      x: -224,
      y: 1296,
    },
    PatchEXE: {
      x: 1360,
      y: 2000,
    },
    SuspiciousIP: {
      x: 1360,
      y: 1584,
    },
    NestorWilke: {
      x: 1648,
      y: 1008,
    },
    GaelTorres: {
      x: 928,
      y: 592,
    },
    EmilyBraun: {
      x: 1024,
      y: 1024,
    },
    ShoppingListZIP: {
      x: 1952,
      y: 2000,
    },
    AngelBrown: {
      x: 848,
      y: 1584,
    },
    PolicySettings: {
      x: -224,
      y: 1584,
    },
    pc067: {
      x: 480,
      y: 1584,
    },
    RDPConnection: {
      x: 480,
      y: 1296,
    },
    pc034: {
      x: 160,
      y: 1296,
    },
    AlexsBirthdayParty: {
      x: 848,
      y: 1296,
    },
    AlexGagnon: {
      x: 608,
      y: 1024,
    },
    QuinnAnderson: {
      x: 1344,
      y: 1024,
    },
  },
};
