/**
 * i18next NS: `Episode01.Task04`
 */
 export default {
  common: {
    errorTitle: "Try Again",
    next: "Next",
    submit: "Submit",
    start: "Start",
    skipAhead: "Skip Ahead",
    ok: "OK",
    done: "Done",
  },
  A020: {
    error: {
      1: {
        title: "Try again.",
        body: "That's not the right place.",
      },
      2: {
        title: "Try again.",
        body: "That's not right either.",
      },
      3: {
        title: "Go Here!",
        body: "Start in Microsoft Sentinel.",
      },
    },
  },
  A030: {
    body: "Okay, here we are in Microsoft Sentinel. Where to next?",
    labels: {
      threatManagement: "Threat management > Threat intelligence",
      analytics: "Configuration > Analytics",
      automation: "Configuration > Automation",
    },
    errors: {
      threatManagement: {
        title: "No, not Threat Management.",
        body: "You want to set up alerts for possible threats. This category won't give you what you need.",
      },
      automation: {
        title: "No, not Automation.",
        body: "You're in the right category, but you need to configure what Microsoft Sentinel will look for when it sends alerts.",
      },
    },
  },
  A040: {
    body: "You need to create a new rule, but to save time you’d like to avoid starting from scratch. From this screen, where would be the best place to continue this process?",
    labels: {
      createDropdown: 'Select the "Create rule" button',
      ruleTemplates: 'The "Rule templates" tab',
    },
    errors: {
      createDropdown: {
        title: 'The "Create rule" button isn’t the most efficient option.',
        body: "You could use this option to create a wholly new rule, but you'll save time if you build upon a rule that already exists.",
      },
    },
  },
  A050: {
    body: "There are a lot of templates to choose from! Which search query will give you the best results for what you're trying to accomplish?",
    labels: {
      consent: "\"Consent\"",
      application: "\"Application\"",
      permissions: "\"Permissions\"",
    },
    errors: {
      application: {
        title: "Get more specific.",
        body: "This search query will return more results than you need. Define your search more specifically.",
      },
      permissions: {
        title: "There's a better choice.",
        body: "Remember the Brief. You want to set up alerts for app consent requests.",
      },
    },
  },
  A060: {
    body: "To effectively protect Best for You Organics, you should activate all five of these consent-related rule templates. First, which one addresses the highest severity situation?",
    labels: {
      consent: "Rare application consent",
      attackToolkit: "Suspicious application consent similar to O365 Attack Toolkit",
      offlineAccess: "Suspicious application consent for offline access",
    },
    errors: {
      consent: {
        title: "There's a higher-severity option available.",
        body: "It makes sense to create a rule that will send alerts for unusual application consent activity. But there's a different rule you want to activate first.",
      },
      offlineAccess: {
        title: "This rule is low severity.",
        body: "Start with the highest-severity rule.",
      },
    },
  },
  A070: {
    body: "You've selected the “Suspicious application consent similar to O365 Attack Toolkit” template. What's next?",
    labels: {
      consent: "Select the \"+ Create\" dropdown",
      attackToolkit: "Select Create Rule",
    },
    errors: {
      consent: {
        title: "No, not \"+ Create.\"",
        body: "That will take you all the way back to the beginning! There is a different way forward.",
      },
    },
  },
  A080: {
    body: "Now you’re in the rule creation wizard. Let's look at the “Suspicious application consent similar to O365 Attack Toolkit” rule logic.",
  },
  A090: {
    body: "Interpret this rule logic. Then, fill in the blank: “Suspicious application consent similar to O365 Attack Toolkit” will create an alert when __________________________________.",
    labels: {
      mailboxSettings: "Application can access “mailboxsettings” and it is not on a specific whitelist",
      allPrincipals: "Application can edit a whitelist of known applications when created by a user in the \"AllPrincipals\" group",
    },
    errors: {
      allPrincipals: {
        title: "Not quite!",
        body: "It is true that you should restrict this action, but this KQL statement does not specifically address it. Although it references a whitelist and an \"AllPrincipals\" group, it uses them in a different way.",
      },
    },
  },
  A100: {
    body: "If the rule does create an alert, the query results will contain detailed information about the “mailboxsettings” permissions that were granted to the application. In the KQL statement, this content is defined by the lines starting with:",
    labels: {
      consentFull: "<0>extend</0> ConsentFull...<br><0>parse</0> ConsentFull... <br><0>where</0> ConsentFull...",
      grantConsent: "<0>where</0> GrantConsentType...<br><0>extend</0> GrantIpAddress...<br><0>extend</0> GrantInitiatedBy...",
    },
    errors: {
      grantConsent: {
        title: "Not exactly.",
        body: "The lines you've identified will add user info and IP addresses to the alert message.",
      },
    },
  },
  A110: {
    body: "Take a look at the part of the query that starts with <0>on</0> AppClientId. What information does this add to the alert?",
    labels: {
      systemLogs: "The system logs of the user who initiated the consent operation",
      information: "Information about similar recent consent operations",
    },
    errors: {
      systemLogs: {
        title: "That's not right.",
        body: "This part of the query collects information that's a bit more specific.",
      },
    },
  },
  A120: {
    body: "We’ve jumped to the end of the “Create rule” wizard. From here, you’d finalize the rule that you’ve been working on. Let’s return to the Rule templates screen and enable the next rule.",
  },
  A130: {
    body: "We’ll implement the “Rare application consent” rule next, but we’ll skip ahead a little bit. Let’s continue to analyze the rule logic.",
  },
  A140: {
    body: "Interpret this “Rare application consent” rule logic. It creates an alert when what criteria is met?",
    labels: {
      frequently: "A user grants permissions more frequently than specified",
      outside: "A user grants permissions outside of expected patterns",
    },
    errors: {
      frequently: {
        title: "That's not right.",
        body: "Here's a hint. This rule sends an alert under a few conditions—one of which is when consent is granted by a user who is not typically involved with a particular app.",
      },
    },
  },
  A160: {
    body: "It looks like you’re getting the hang of this, so we’ll skip ahead.  Select OK to create and enable the remaining app consent analytical rules shown here.",
  },
  A170: {
    body: "Before you move on, check the “Active rules” list. Verify that all five of the new app consent analytical rules are now in that list.",
  },
  A180: {
    title: "Nice work!",
    body: "You enabled five app consent analytical rules in Microsoft Sentinel.  Remember, you can review your Protection tasks on the Evidence Map.",
  },
};
