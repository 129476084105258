import * as React from 'react';
import { Trans } from 'react-i18next';
import { BranchProps, ComponentProps } from '../../../../task-types';
import Button from '../../../../../components/Global/Button/Button';
import Screen from '../../../../../components/Global/Screen/Screen';
import BranchingDialog from '../../Task02/components/BranchingDialog';

export default function N03({ t, send, state }: ComponentProps): JSX.Element {
  return (
    <Screen>
      <BranchingDialog
        title=""
        body={<Trans t={t} i18nKey={`${state.tKey}.body`} components={[<strong />]} />}
        useDark
        background="office"
      >
        {
          t<string, BranchProps[]>(`${state.tKey}.branches`, { returnObjects: true }).map((val, index) => {
            return (
              <Button
                key={index}
                isPrimary
                onClick={() => send(`CLICK.BRANCH${index + 1}`)}
                label={val.option}
              />
            );
          })
        }
      </BranchingDialog>
    </Screen>
  );
}
