import React from 'react';
import { Stack } from '@fluentui/react';
import type { IStackTokens } from '@fluentui/react';

import PlayerPersonaLive from '../../Global/PlayerPersona/PlayerPersonaLive';
import { useAppSelector } from '../../../store';
import { selectAllUsers } from '../../../store/user-slice';

export interface ILeadSelectionUserListProps {
  minSize?: number;
  addPlaceholders?: boolean;
}

const stackTokens: IStackTokens = {
  padding: '16px 24px 0 0',
  childrenGap: '24px',
};

const LeadSelectionUserList: React.FC<ILeadSelectionUserListProps> = () => {
  const { allUsers } = useAppSelector(selectAllUsers);

  return (
    <Stack horizontal tokens={stackTokens} wrap>
      {allUsers.map(({ username }) => (
        <Stack.Item key={username}>
          <PlayerPersonaLive useDark username={username} size="medium" />
        </Stack.Item>
      ))}
    </Stack>
  );
};

export default LeadSelectionUserList;
