import { IStackStyles, Stack } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../../Modal/Modal';
import Screen from '../../../Global/Screen/Screen';
import Button from '../../../Global/Button/Button';
import { useGameService } from '../../../../state/GlobalStateProvider';

import Dialog from '../../../Global/Dialog/Dialog';
import { Backgrounds } from '../../../../lib/hooks/useBackground';
import { useConsensus } from '../../../../lib/hooks/useConsensus';
import { getConsensusKeyPredefined } from '../../../../store/multiplayer-slice';
import { useAppStore } from '../../../../store';
import PlayerPersonaLive from '../../../Global/PlayerPersona/PlayerPersonaLive';
import { P } from '../../../Global/Text';
import RTrans from '../../../Global/RTrans/RTrans';
import { ConsensusKeys, NILAnswer, NoAnswer } from '../../../../lib/multiplayer/schemas';
import { useAppSelector } from '../../../../lib/hooks/useRedux';
import { selectAllUsers } from '../../../../store/user-slice';
import Checkbox from '../../../Global/Interactions/Checkbox/Checkbox';
import { ArrayOrSingle } from '../../../../lib/types';
import { coerceArray } from '../../../../lib/util';

const choiceStyle: IStackStyles = {
  root: {
    marginTop: '0 !important',
  },
};

function getCorrectAnswer(question: { key: number, displayName: string, isCorrect?: boolean }[]) {
  const correctAnswers: number[] = [];
  question.forEach((answer) => {
    if (answer.isCorrect) correctAnswers.push(answer.key);
  });
  return correctAnswers;
}

function getIsCorrectAnswer
  <QType extends { key: number, displayName: string, isCorrect?: boolean }>(
    answers: ArrayOrSingle<number>, questions: QType[]) {
  const answerArray = coerceArray(answers);
  const allCorrectAnswers = questions.filter((question) => question.isCorrect);
  if (answerArray.length !== allCorrectAnswers.length) {
    return false;
  }

  const wrongAnswer = answerArray.find((answer) =>
  !allCorrectAnswers.find((question) => question.key === answer));

  return !wrongAnswer;
}

export default function Conclusion() {
  const [showUnanimous, setShowUnanimous] = React.useState(false);
  const [accuseState, setAccuseState] = React.useState('accuse');
  const [accuseKey, setAccuseKey] = React.useState<ArrayOrSingle<number>>(0);
  const backgroundNumber = React.useRef(0);
  const { t } = useTranslation(['Game01.Episode02.Conclusion', 'entries', 'game01Common', 'common']);
  const { send } = useGameService();
  const appState = useAppStore();
  const { localUser } = useAppSelector(selectAllUsers);
  const isAccuse = ['accuse', 'confirm'].some((state) => accuseState.includes(state));
  const selectOptions = React.useMemo(() => {
    return isAccuse ?
      [
        {
          key: 1,
          displayName: 'Quinn',
        },
        {
          key: 2,
          displayName: 'Frey',
        },
        {
          key: 3,
          displayName: 'Alex',
        },
        {
          key: 4,
          displayName: 'Wenny',
        },
        {
          key: 5,
          displayName: 'Hacker',
          isCorrect: true,
        },
      ] :
      [
        {
          key: 1,
          displayName: '',
        },
        {
          key: 2,
          displayName: '',
          isCorrect: true,
        },
        {
          key: 3,
          displayName: '',
          isCorrect: true,
        },
        {
          key: 4,
          displayName: '',
        },
      ];
    }, [isAccuse]);
  const { answerConsensusKey, submitConsensusKey, nextState } = isAccuse ?
    { answerConsensusKey: 'accuse_conclusion' as ConsensusKeys, submitConsensusKey: 'accuse_confirm_conclusion' as ConsensusKeys, nextState: { success: 'proof', fail: 'incorrect' } } :
    { answerConsensusKey: 'proof_conclusion' as ConsensusKeys, submitConsensusKey: 'proof_confirm_conclusion' as ConsensusKeys, nextState: { success: 'correct', fail: 'partialCorrect' } };
  const correctAnswer = selectOptions.find((option) => option.isCorrect);
  const answerConsensus = useConsensus(
    getConsensusKeyPredefined(appState.getState(), answerConsensusKey), () => {}, true, false, true);
  const voteConfirmationCallback = React.useCallback(() => {
    if (answerConsensus.status === 'completed') {
      if (getIsCorrectAnswer(
        answerConsensus.finalAnswer as ArrayOrSingle<number>,
        selectOptions)) {
        setAccuseState(nextState.success);
      } else setAccuseState(nextState.fail);
    } else {
      setShowUnanimous(true);
    }
  }, [setAccuseState, setShowUnanimous, selectOptions, answerConsensus.finalAnswer, answerConsensus.status, nextState]);
  const submitConsensus = useConsensus(
    getConsensusKeyPredefined(appState.getState(), submitConsensusKey),
    isAccuse ? () => {} : voteConfirmationCallback, false, false, true,
  );

  const handleButtonClick = (state: string, selected?: number) => {
    switch (state) {
      case 'accuse':
        setAccuseState(state);
        setAccuseKey(0);
        backgroundNumber.current = 0;
        answerConsensus.handleConsensus(NoAnswer, undefined, undefined, undefined, correctAnswer?.key);
        break;
      case 'confirm':
        setAccuseState('confirm');
        if (selected) {
          answerConsensus.handleConsensus(selected, undefined, undefined, undefined, correctAnswer?.key);
          setAccuseKey(selected);
          backgroundNumber.current = selected;
        }
        break;
      case 'proof':
        setAccuseState('accuse');
        submitConsensus.handleConsensus('confirm', undefined, undefined, answerConsensus.key, 'confirm');
        break;
      case 'correct':
      case 'partialCorrect':
        setAccuseState('proof');
        if (selected) {
          const userVote = answerConsensus.userVote as number[];
          const answerIndex = userVote.indexOf(selected);
          const nextQuestionCorrectAnswer = getCorrectAnswer(selectOptions);
          const updatedAnswers =
            answerIndex === -1
              ? [...userVote, selected]
              : [...userVote.slice(0, answerIndex), ...userVote.slice(answerIndex + 1)];
          answerConsensus.handleConsensus(
            Array.from(new Set(updatedAnswers)).sort(),
            undefined,
            undefined,
            undefined,
            nextQuestionCorrectAnswer,
          );
        }
        break;
      case 'end':
        send('DONE.CONCLUSION');
        break;
      default:
        break;
    }
  };

  const getCurrentBackground = (key: number) => {
    let background : Backgrounds = 'eps2AccuseAll';
    switch (key) {
      case 1:
        background = 'eps2AccuseQuinn';
        break;
      case 2:
        background = 'eps2AccuseFrey';
        break;
      case 3:
        background = 'eps2AccuseAlex';
        break;
      case 4:
        background = 'eps2AccuseWenny';
        break;
      case 5:
        background = 'eps2AccuseHacker';
        break;
      default:
        break;
    }
    return background;
  };

  const renderAccuseAnswers = () =>
    selectOptions.map((choice) => {
      const answerId = choice.key;

      const userVotes = () =>
        answerConsensus.allVotes &&
        answerConsensus.allVotes.reduce<React.ReactNode[]>((acc, vote) => {
          if (vote.answer === answerId ||
            (Array.isArray(vote.answer) && vote.answer.includes(answerId))) {
            acc.push(
              <Stack.Item key={vote.participantId}>
                <PlayerPersonaLive hideName username={vote.participantId} />
              </Stack.Item>,
            );
          }
          return acc;
        }, []);

      const answerIndexId = coerceArray(answerConsensus.userVote).indexOf(choice.key);
      return (
        isAccuse ?
          <Stack key={`votes-buttons.${choice.displayName}`} tokens={{ childrenGap: 20 }} horizontalAlign="center">
            <Stack tokens={{ childrenGap: 20 }} horizontal verticalAlign="center">
              {userVotes()}
            </Stack>
            <Stack.Item>
              <Button
                isPrimary
                label={choice.displayName}
                onClick={() => {
                handleButtonClick('confirm', choice.key);
              }}
                disabled={
                  (submitConsensus.status === 'completed' && answerConsensus.status === 'completed') ||
                  (submitConsensus.hasUserVoted && submitConsensus.userVote !== NILAnswer)
                }
              />
            </Stack.Item>
          </Stack> :
          <Stack key={`votes-buttons.${choice.key}`} tokens={{ childrenGap: 20 }} horizontalAlign="start">
            <Stack.Item>
              <Checkbox
                label={t(`proof.options.${choice.key}.label`)}
                checked={answerIndexId > -1}
                onChange={() => {
                  handleButtonClick('partialCorrect', choice.key);
                }}
                disabled={
                  (submitConsensus.status === 'completed' && answerConsensus.status === 'completed') ||
                  (submitConsensus.hasUserVoted && submitConsensus.userVote !== NILAnswer)
                }
              />
            </Stack.Item>
            <Stack tokens={{ childrenGap: 20 }} horizontal verticalAlign="center">
              {userVotes()}
            </Stack>
          </Stack>
      );
    });

  const proofStateWaitingList = answerConsensus.waitingFor.length > 0 ? answerConsensus : submitConsensus;
  const waitingList = (isAccuse ? submitConsensus : proofStateWaitingList).waitingFor;
  const waitingForUsersVoteText = t('consensus.waitingForFinalAnswer', {
    names: `${waitingList
    .map(({ display_name, username }) => {
      return localUser.username !== username ? display_name : t('consensus.currentUser');
    })
    .join(',')}` });

  return (
    <Screen>
      <Dialog
        title={accuseState === 'confirm' && accuseKey === 5 ? t('confirmHacker.title') : t(`${accuseState}.title`, { name: accuseKey ? selectOptions.find((o) => o.key === accuseKey)?.displayName : '' })}
        body={accuseState === 'confirm' && accuseKey === 5 ? t('confirmHacker.body') : t(`${accuseState}.body`, { name: accuseKey ? selectOptions.find((o) => o.key === accuseKey)?.displayName : '' })}
        background={getCurrentBackground(backgroundNumber.current)}
        disableSubmit={accuseState === 'accuse' ?
          /**
           * in accuse state next button is disabled in situations where:
           * the user didn't answer (answerConsensus.hasUserVoted = false) or
           * is wating for other users to answer (submitConsensus.status !== 'completed') :
           * * waiting can be for other users to answer for the first time or
           * * retry and answer again
           * */
          !answerConsensus.hasUserVoted ||
            (submitConsensus.status !== 'completed' &&
              ((submitConsensus.hasUserVoted && !submitConsensus.tryAgainConsensus) ||
                submitConsensus.userTriedAgain)) :
          !(submitConsensus.status === 'completed' && answerConsensus.status === 'completed') &&
          !((answerConsensus.hasUserVoted &&
              !submitConsensus.hasUserConfirmed) || submitConsensus.userVote === NILAnswer)}
        hideNext={accuseState !== 'accuse' && accuseState !== 'proof'}
        onClick={accuseState === 'accuse' ?
          () => {
            return submitConsensus.userVote === NILAnswer ?
              submitConsensus.handleConsensus('confirm', undefined, undefined, answerConsensus.key, 'confirm') :
              voteConfirmationCallback();
          } :
          () => {
            submitConsensus.handleConsensus('confirm', undefined, undefined, answerConsensus.key, 'confirm');
          }}
        customStyle={accuseState === 'accuse' ? choiceStyle : undefined}
      >
        {(accuseState === 'accuse' || accuseState === 'proof') && (
          <Stack horizontal verticalAlign="end" horizontalAlign="stretch" tokens={{ childrenGap: 16 }} wrap>
            <Stack horizontal verticalAlign="end" horizontalAlign="stretch" tokens={{ childrenGap: 16 }} wrap>
              {renderAccuseAnswers()}
            </Stack>
            <Stack.Item>
              <P level={3}>
                {' '}
                {
                  waitingList &&
                  waitingList.length > 0 &&
                  <RTrans
                    t={t}
                    i18nKey={waitingForUsersVoteText}
                    components={{ bold: <strong />, waitingForUsersVoteText }}
                  />
                }
              </P>
            </Stack.Item>
          </Stack>
        )}
        {accuseState === 'confirm' && (
          <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 16 }}>
            <Button
              isPrimary
              label={t('confirm.nextLabel')}
              onClick={() => {
                handleButtonClick('proof');
              }}
            />
            <Button
              isPrimary
              label={t('confirm.backLabel')}
              onClick={() => {
                handleButtonClick('accuse');
              }}
            />
          </Stack>
        )}
        {accuseState.match(/^(incorrect|partialCorrect|correct|)$/) && (
          <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 16 }}>
            <Button
              isPrimary
              label={t('buttons.next')}
              onClick={() => {
                handleButtonClick('end');
              }}
            />
          </Stack>
        )}
      </Dialog>

      <Modal
        sidebar="character"
        isOpen={showUnanimous}
        hideDismissButton
        title={t('consensus.unanimous.title')}
        body={t('consensus.unanimous.body')}
        footer={
          <Button
            isPrimary
            label={t('consensus.unanimous.nextLabel')}
            onClick={() => {
              setShowUnanimous(false);
              submitConsensus.handleConsensus(NILAnswer);
            }}
          />
        }
      />
    </Screen>
  );
}
