import * as React from 'react';
import Screen from '../../../../../components/Global/Screen/Screen';
import Modal from '../../../../../components/Game01/Modal/Modal';
import { useMultipleChoice } from '../../../../../components/Global/Interactions/MultipleChoice/MultipleChoice';
import Button from '../../../../../components/Global/Button/Button';
import { ComponentProps } from '../../../../task-types';
import Dialog from '../../../../../components/Global/Dialog/Dialog';

export default function A030({ t, send, match }: ComponentProps): JSX.Element {
  const { errorKey, handleIncorrect } = useMultipleChoice(() => send('CLICK.INCORRECT'));

  return (
    <Screen>
      <Dialog title={t('A030.body')} onClick={() => {}} hideNext character="andrea" background="office">
        <Button
          isPrimary
          onClick={() => handleIncorrect(1)}
          label={t('A030.options.1.label')}
          disabled={match('final')}
        />
        <Button
          isPrimary
          onClick={() => !match('final') && send('NEXT')}
          label={t('A030.options.2.label')}
          icon={match('final') ? 'check' : undefined}
        />
        <Button
          isPrimary
          onClick={() => handleIncorrect(3)}
          label={t('A030.options.3.label')}
          disabled={match('final')}
        />
      </Dialog>
      <Modal
        sidebar="character"
        isOpen={match('incorrect')}
        footer={<Button isPrimary label={t('common.errorTitle')} onClick={() => send('NEXT')} />}
        onDismiss={() => send('NEXT')}
        title={t(`A030.options.${errorKey}.error.title`)}
        body={t(`A030.options.${errorKey}.error.body`)}
        hideDismissButton
      />
    </Screen>
  );
}
