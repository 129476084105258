import { IEffects } from '@fluentui/react';

const effects: Partial<IEffects> = {
  elevation4: '0px 1.6px 3.6px 0px rgb(0 0 0 / 13%), 0px .3px .9px 0px rgb(0 0 0 / 10%)',
  elevation8: '0px 3.2px 7.2px 0px rgb(0 0 0 / 13%), 0px .6px 1.8px 0px rgb(0 0 0 / 10%)',
  elevation16: '0px 16.4px 14.4px 0px rgb(0 0 0 / 13%), 0px 1.2px 3.6px 0px rgb(0 0 0 / 10%)',
  elevation64: '0px 12px 57.6px 0px rgb(0 0 0 / 25%), 0px 25.6px 14.4px 0px rgb(0 0 0 / 22%)',
  roundedCorner2: '6px',
  roundedCorner4: '6px',
  roundedCorner6: '6px ',
};

export default effects;
