import React from 'react';
import { IStyle, mergeStyles, Stack } from '@fluentui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen } from '@fortawesome/pro-solid-svg-icons';

import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { getCurrentGameTheme } from '../../../theme';

export interface INavPanelHeaderProps {
  className?: string;
  headerTextId?: string;
  headerText?: string;
  icon?: IconProp;
  styles?: Partial<INavPanelStyles>;
}

const theme = getCurrentGameTheme();

export interface INavPanelStyles {
  /**
   * Style for the outermost Stack.
   * (This is where the children are rendered.)
   */
  root: IStyle;

  /**
   * Style for the header text.
   */
  headerText: IStyle;
}

const defaultRootStyles: IStyle = {
  button: {
    'color': theme.palette.white,
    ':hover': {
      color: theme.palette.white,
      backgroundColor: 'rgba(0,0,0,0.2)',
    },
    ':active': {
      color: theme.palette.white,
      backgroundColor: 'rgba(0,0,0,0.4)',
    },
    ':disabled': {
      backgroundColor: 'transparent',
    },
  },
};

const defaultHeaderTextStyles: IStyle = {
  fontSize: '44px',
  lineHeight: '44px',
  color: theme.semanticColors.navPanelHeaderText,
  fontWeight: 100,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

const NavPanelHeader: React.FC<INavPanelHeaderProps> = ({
  headerTextId,
  headerText,
  icon = faBookOpen,
  children,
  styles,
}) => {
  const outerStackStyleClass = React.useMemo(() => mergeStyles(defaultRootStyles, styles?.root), [styles?.root]);
  const headerFontStyleClass = React.useMemo(
    () => mergeStyles(defaultHeaderTextStyles, styles?.headerText),
    [styles?.headerText],
  );

  return (
    <Stack className={`ms-Panel-header ${outerStackStyleClass}`} grow={1} tokens={{ padding: '32px' }}>
      <Stack horizontal tokens={{ childrenGap: '16px' }} verticalAlign="end">
        <FontAwesomeIcon className={headerFontStyleClass} icon={icon} />
        <Stack.Item
          className={`ms-Panel-headerText ${headerFontStyleClass}`}
          id={headerTextId}
          role="heading"
          aria-level={1}
        >
          {headerText ?? 'headerText'}
        </Stack.Item>
      </Stack>
      {children}
    </Stack>
  );
};

export default NavPanelHeader;
