/**
 * i18next NS: `Episode02.Task11`
 */
export default {
  common: {
    errorTitle: "Try Again",
    next: "Next",
    submit: "Submit",
    start: "Start",
    skipAhead: "Skip Ahead",
    ok: "OK",
    done: "Done",
  },
  K020: {
    error: {
      1: {
        title: "Try again.",
        body: "That's not the right place.",
      },
      2: {
        title: "Try again.",
        body: "That's not right either.",
      },
      3: {
        title: "Go Here!",
        body: "I need you to go to Azure Monitor.",
      },
      4: {
        title: "You are in the right area.",
        body: "Let's skip ahead and go straight to Azure Monitor.",
      },
    },
  },
  K030: {
    body: "You've launched Azure Monitor. What's your best next step for this investigation?",
    labels: {
      Activitylog: "Activity log",
      Alerts: "Alerts",
      Logs: "Logs",
    },
    errors: {
      Activitylog: {
        title: "Not quite.",
        body: "You're looking for a slightly different option in the navigation.",
      },
      Alerts: {
        title: "No, not Alerts.",
        body: "The information you're looking for is unlikely to be in Alerts.",
      },
    },
  },
  K035: {
    title: "You can configure your Azure Monitor query from here. Let's go!",
    body: "",
  },
  K040: {
    title: "Run a query to find out who created the app registration and when.",
    body: "Move the dials to set the parameters for your query.",
    attempts: "Attempt % of 3",
    subhead: "",
    placeholder: "Select the correct answer",
    locks: {
      1: {
        label: "Tables",
        options: {
          1: "AuditLogs",
          2: "AzureActivity",
          3: "SecurityAlert",
          4: "Anomalies",
        },
        hint: "First, set the table(s) in which you want your query to run. The AuditLogs table gives you access to records of system activities. That's what you need here.",
      },
      2: {
        label: "Time Range",
        options: {
          1: "Last 25 hours",
          2: "Last 48 hours",
          3: "Last 7 days",
          4: "Custom",
        },
        hint: "We don't know when the app registration was created, and it might be more than 7 days ago. You'll need to set a custom time range. ",
      },
      3: {
        label: "Query Text",
        options: {
          1: "quinn.anderson",
          2: "eshopidentity",
          3: "bfyovacationtool",
          4: "ecommerceTool",
        },
        hint: "The text of your query should be the name of the app.",
      },
    },
    error: {
      title: "Take another look at the Brief.",
      body: "You can always find the Brief in your Journal. It should give you the key information you need for this part of the investigation.",
    },
    walkthrough: {
      title: "Those parameters are also incorrect.",
      body: "How's the investigation coming along?",
      nextLabel: "Guide Me",
    },
    success: {
      title: "Query complete!",
      body: "Let's take a look at the results.",
      nextLabel: "Next",
    },
  },
  K050: {
    body: "Here are the query results. Which column heading (property) should you use to sort them?",
    labels: {
      TimeGenerated: "TimeGenerated",
      ResourceID: "ResourceID",
      OperationName: "OperationName",
    },
    errors: {
      ResourceID: {
        title: "No, not ResourceID.",
        body: "Remember the goal of your search—to find who created the app registration.",
      },
      OperationName: {
        title: "OperationName isn't it.",
        body: "There's a better way to find what you need.",
      },
    },
  },
  K060: {
    body: "You sorted the results by “TimeGenerated.” Which of the displayed events is most relevant to your investigation right now?",
    labels: {
      A30335: "11/08/2021, 11:19:30.335 PM",
      A30444: "11/08/2021, 11:19:30.444 PM",
      A31158: "11/08/2021, 11:19:31.158 PM",
    },
    errors: {
      A30444: {
        title: "No, not that one.",
        body: "This event will show details regarding an owner being added to the app registration, but that's not what you need right now.",
      },
      A31158: {
        title: "This isn't right.",
        body: "You're not looking for an \"update\" event.",
      },
    },
  },
  K070: {
    body: "You expanded the first log event. What information is most relevant? What are you looking for?",
    labels: {
      Category: "Category",
      AdditionalDetails: "AdditionalDetails",
      InitiatedBy: "InitiatedBy",
    },
    errors: {
      Category: {
        title: "Not Category.",
        body: "This information won't help you identify a specific user.",
      },
      AdditionalDetails: {
        title: "Not AdditionalDetails.",
        body: "There's a more direct way to get the information you need.",
      },
    },
  },
  K080: {
    title: "The \"InitiatedBy\" property shows you who created the app registration.",
    body: "Select the critical evidence to add it to your Journal, then select DONE.",
    evidenceAdded: {
      title: "All set!",
      body: "Evidence added to journal.",
      nextLabel: "OK",
    },
    feedback: {
      title: "You didn't get all the critical information.",
      body: "Add this evidence to your journal.",
    },
    evidence: {
      one: {
        title: "TimeGenerated [UTC] 2021-11-08T23:19:30.335Z",
        subtitle: "Time Generated: 2021-11-08T23:19:30.335Z",
      },
      two: {
        title: "\"userPrincipalName\":\"frey.munch@bestforyouorganic.onmicrosoft.com\"",
        subtitle: "freymunch@bestforyouorganic.onmicrosoft.com initiated the app registration used in the OAuth attack",
      },
      three: {
        title: "\"ipAddress\":\"40.126.26.160\"",
        subtitle: "IP address: 40.126.26.160",
      },
      four: {
        title: "\"id\":\"cd29a54a-d7eb-47c2-903c-efae483a298a\"",
        subtitle: "id: cd29a54a-d7eb-47c2-903c-efae483a298a",
      },
    },
  },
  K090: {
    title: "Lead complete!",
    body: "You found out who created the app registration. Now it's time to put the information you found on the Evidence Map.",
    nextLabel: "Apply Evidence",
  },
};
