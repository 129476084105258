import Dashboard1 from './010100C';
import Dashboard2 from './010105';
import Dashboard3 from './010203';
import Dashboard4 from './010401';
import Start from './Start';

/**
 * Episode01: All task components in a single namespace.
 */

export default {
  'StartC': Start,
  '010100C': Dashboard1,
  '010105': Dashboard2,
  '010203': Dashboard3,
  '010401': Dashboard4,
};
