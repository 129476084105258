import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ConnectionTest from '../ConnectionTest/ConnectionTest';
import Facilitator from '../Facilitator/Facilitator';
import FacilitatorLoadGate from '../LoadGate/FacilitatorLoadGate';
import Event from '../Event/Event';
import Game from '../Game/Game';
import EventLoadGate from '../LoadGate/EventLoadGate';
import { isDev, isStaging } from '../../../lib/util';
import DevGameLoadGate from '../LoadGate/DevGameLoadGate';
import ForceThemeOverride from '../ForceThemeOverride/ForceThemeOverride';

/**
 * TODO: There should only be two true routes for production,
 * the Facilitator Route and the Event Route. The "Game" route is here
 * out of necessity for developers and to allow the showing of vertical
 * slice deliverables. It should be setup before release to only be accessible if not in the
 * final production environment
 * There are also three different variants of the LoadGate. Ideally there is only 1 or 2.
 */

const App: React.FC = () => (
  <Switch>
    <Route path={['/facilitator/:facilitator_slug', '/facilitator']}>
      <FacilitatorLoadGate>
        <ForceThemeOverride themeId="Global">
          <Facilitator />
        </ForceThemeOverride>
      </FacilitatorLoadGate>
    </Route>
    <Route exact path="/connection-test">
      <ForceThemeOverride themeId="Global">
        <ConnectionTest />
      </ForceThemeOverride>
    </Route>
    <Route path={['/event/:event_slug', '/event']}>
      <EventLoadGate>
        <Event />
      </EventLoadGate>
    </Route>
    {(isDev || isStaging) && (
      <Route path={['/']}>
        <DevGameLoadGate>
          <Game useDevRoutes />
        </DevGameLoadGate>
      </Route>
    )}
  </Switch>
);

export default App;
