import { IKeyframes, keyframes } from '@fluentui/merge-styles';
import { IStyle, mergeStyles } from '@fluentui/react';
import React from 'react';

import { TFunction } from 'react-i18next';

import { ElementDataNode } from '../../../lib/game-data/element-data';
import { imageList } from '../../../static/images';
import { ImageComponentCircuitLock } from '../../../static/images/image-types';
import { useAppSelector } from '../../../store';
import { selectCircuitStatus } from '../../../store/multiplayer-slice/applied-data';

export interface IMapCircuitLockProps {
  data: MapCircuitLockNodeData;
}

export interface MapCircuitLockNodeData {
  element: ElementDataNode;
  onFocus?: () => void;
  t: TFunction;
}

export type NodeStatusType = 'deactivated' | 'activated' | 'completed';

const nodeSizeBase = 28;
const midLayerPos = '43%';
const topLayerPos = '38%';

const lockStyles = {
  minWidth: `${nodeSizeBase * 5}px`,
  maxWidth: `${nodeSizeBase * 5}px`,
  minHeight: `${nodeSizeBase * 5}px`,
  maxHeight: `${nodeSizeBase * 5}px`,
};
const midKeyframes: IKeyframes = {
  '0%': {
    top: '50%',
  },
  '100%': {
    top: midLayerPos,
  },
};

const topKeyFrames: IKeyframes = {
  '0%': {
    top: '50%',
  },
  '50%': {
    top: midLayerPos,
  },
  '100%': {
    top: topLayerPos,
  },
};

const layerBaseStyle: IStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
};

const BaseLayerClassname = mergeStyles(layerBaseStyle, {});

const midLayerClassname = mergeStyles(
  layerBaseStyle,
  {
    top: midLayerPos,
    animation: `${keyframes(midKeyframes)} 1s ease-in-out`,
  },
);

const topLayerClassname = mergeStyles(
  layerBaseStyle,
  {
    top: topLayerPos,
    animation: `${keyframes(topKeyFrames)} 2s ease-in-out`,
  },
);

/**
 * Evidence Map React-Flow card component.
 */
const MapCircuitLock: React.FC<IMapCircuitLockProps> = ({ data: { element } }) => {
  const status = useAppSelector((state) => selectCircuitStatus(state, element.id));

  const circuitBaseElement = imageList.find((image) => image.name === `${element.parent?.id}.Circuit`) as ImageComponentCircuitLock;
  if (!circuitBaseElement) return (<></>);

  const circuitElements = circuitBaseElement.Component;
  return (
    <>
      <div style={lockStyles}>
        <circuitElements.baseBack
          className={BaseLayerClassname}
          width="100%"
          height="100%"
        />
        <circuitElements.midBack
          className={status === 'completed' ? midLayerClassname : BaseLayerClassname}
          width="100%"
          height="100%"
        />
        <circuitElements.topSingle
          className={status === 'completed' ? topLayerClassname : BaseLayerClassname}
          width="100%"
          height="100%"
        />
        <circuitElements.midFore
          className={status === 'completed' ? midLayerClassname : BaseLayerClassname}
          width="100%"
          height="100%"
        />
        <circuitElements.baseFore
          className={BaseLayerClassname}
          width="100%"
          height="100%"
        />
      </div>
    </>
  );
};

export default MapCircuitLock;
