import type { LocaleContent, CardOrEntryLocaleContent } from "../../types";

/**
 * i18Next `entries:Episode01.Task04` lead text content
 */
const Task04Lead: LocaleContent = {
  header: "Enable App Consent Analytical Rules in Sentinel",
  role: "Security Operations Analyst",
  time: "7m",
  brief: "Can you activate app consent analytical rules in Microsoft Sentinel? Let’s make sure we are alerted whenever there is a suspicious app consent request.<br><br>-Andrea",
};

/**
 * i18Next `entries:Episode01.Task04` entry content
 */
const Task04Entries: CardOrEntryLocaleContent = {

};

export default {
  ...Task04Lead,
  ...Task04Entries,
};
