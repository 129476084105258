import ModelA from './ModelA';
import ModelB from './ModelB';
import ModelC from './ModelC';
import ModelD from './ModelD';
import ModelE from './ModelE';
import ModelF from './ModelF';
import Start from './Start';
/**
 * Episode01: All task components in a single namespace.
 */

export default {
  ModelA,
  ModelB,
  ModelC,
  ModelD,
  ModelE,
  ModelF,
  Model: Start,
};
