import React from 'react';
import { IStackStyles, Stack } from '@fluentui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faShieldAlt } from '@fortawesome/pro-regular-svg-icons';

import type { TFunction } from 'react-i18next';

import { Heading, P } from '../../Global/Text';

import type { LeadData } from '../../../lib/game-data/lead-data';

export interface IMapCardProtectionProps {
  data: MapCardProtectionNodeData;
}

export interface MapCardProtectionNodeData {
  leads: ReadonlyArray<{
    lead: LeadData;
    completed: boolean;
  }>;
  t: TFunction;
}

const cardSizeBase = 16;

const cardStackStyles: IStackStyles = {
  root: {
    minWidth: `${cardSizeBase * 24}px`,
    maxWidth: `${cardSizeBase * 24}px`,
    minHeight: `${cardSizeBase * 20}px`,
    backgroundColor: '#f2f2f2',
    border: '6px solid #e8e0d0',
    borderRadius: '6px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    cursor: 'default',
  },
};

/**
 * Evidence Map React-Flow "Protection Tasks" card component.
 */
const MapCardProtection: React.FC<IMapCardProtectionProps> = ({ data: { leads, t } }) => {
  return (
    <>
      <Stack styles={cardStackStyles} tokens={{ padding: '32px', childrenGap: '16px' }}>
        <Stack horizontal tokens={{ childrenGap: '16px' }} verticalAlign="center">
          <Stack.Item>
            <FontAwesomeIcon icon={faShieldAlt} style={{ fontSize: '64px' }} />
          </Stack.Item>
          <Stack>
            <Stack.Item>
              <Heading level={3} noMargin>
                {t('commonEntry.ProtectionNode.header')}
              </Heading>
            </Stack.Item>
            <Stack.Item>
              <P level={3} noMargin>
                {t('commonEntry.ProtectionNode.description')}
              </P>
            </Stack.Item>
          </Stack>
        </Stack>
        <Stack.Item>
          <hr style={{ margin: 0, height: '1px', background: '#000000', border: 'none' }} />
        </Stack.Item>
        {leads.map(({ lead, completed }) => (
          <Stack key={lead.id} horizontal tokens={{ childrenGap: '8px' }}>
            <Stack.Item>
              <FontAwesomeIcon
                icon={faCheck}
                style={{
                  fontSize: '24px',
                  visibility: completed ? 'visible' : 'hidden',
                }}
              />
            </Stack.Item>
            <Stack.Item>
              <P level={3}>{t(`${lead.id}.header`)}</P>
            </Stack.Item>
          </Stack>
        ))}
      </Stack>
    </>
  );
};

export default MapCardProtection;
