import React from 'react';
import { useTranslation } from 'react-i18next';
import LeadSelectionButton from './LeadSelectionButton';
import type { PhaseData } from '../../../lib/game-data/phase-data';
import type { LeadData } from '../../../lib/game-data/lead-data';
import Pane from '../Pane/Pane';

export interface ILeadSelectionPaneProps {
  selectedLead?: LeadData;
  onSelect?: (lead?: LeadData) => void;
  phase?: PhaseData;
  isComplete?: boolean;
}

const LeadSelectionPane: React.FC<ILeadSelectionPaneProps> = ({
  selectedLead,
  phase,
  onSelect,
  isComplete = false,
}) => {
  const { t } = useTranslation('game01Common', { keyPrefix: 'leadSelection' });
  const [selectedLeadId, setSelectedLeadId] = React.useState<string | null>(null);
  const phaseLeads = React.useMemo(() => phase?.getLeads() ?? [], [phase]);
  const phaseLeadsRendered = phaseLeads.map((lead) => {
    return (
      <LeadSelectionButton
        key={lead.id}
        checked={selectedLead === lead}
        lead={lead}
        isSelected={selectedLeadId === lead.id}
        onSelect={() => {
          setSelectedLeadId(lead.id !== selectedLeadId ? lead.id : null);
          if (onSelect) {
            onSelect(lead);
          }
        }}
        disabled={isComplete}
      />
    );
  });

  return <Pane items={phaseLeadsRendered} title={t('leadSelectionHeading')} width={400} />;
};

export default LeadSelectionPane;
