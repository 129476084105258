import type { EntityState } from '@reduxjs/toolkit';

import i18n from '../i18n';

import { gameData } from '../static/game-data';
import { devLogErr } from './util';

import { extractIdsFromEntry } from './journal/journal-entry';

import type { GameDataInstance } from './game-data/types';
import type { JournalEntry } from './journal/types';
import type { EvidenceData } from './game-data/evidence-data';
import type { PolicyData } from './game-data/policy-data';
import { ElementData } from './game-data/element-data';
import { NotebookEntry } from './notebook/types';

export interface EntryComponentProps {
  /** The GameData instance for the entry. */
  entryData: EvidenceData | ElementData | PolicyData;
  /** The GameData instance for grouping the entry. */
  baseData: GameDataInstance;
  entity: JournalEntry | NotebookEntry;
  derivedType: string;
  date: number;
  heading: string | string[];
  content: string[];
  more?: string[];
  unread: boolean;
}

export interface JournalEntryComponentProps extends EntryComponentProps {
  entryData: EvidenceData;
}

export interface NotebookEntryComponentProps extends EntryComponentProps {
  entryData: ElementData;
}

export interface PolicyEntryComponentProps extends EntryComponentProps {
  entryData: PolicyData;
}

export interface EntryUnreadNotificationProps {
  unread: boolean;
}

export interface EntryContentProps {
  date: number;
  heading: string | string[];
  content: string[];
  entry_id: string;
}

export function generateJournalEntryProps(entity: JournalEntry): EntryComponentProps | undefined;
export function generateJournalEntryProps(entities: JournalEntry[]): EntryComponentProps[];
export function generateJournalEntryProps(journal: EntityState<JournalEntry>): EntryComponentProps[];
export function generateJournalEntryProps(
  inputData: JournalEntry | JournalEntry[] | EntityState<JournalEntry>,
): EntryComponentProps | EntryComponentProps[] | undefined {
  // Handle JournalEntry[] overload.
  if (Array.isArray(inputData)) {
    return inputData.reduce((acc: EntryComponentProps[], entity) => {
      const entry = generateSingleEntryProps(entity);
      if (entry != null) {
        acc.push(entry);
      }
      return acc;
    }, []);
  }

  // Handle EntityState<JournalEntry> overload.
  if ('entities' in inputData) {
    const { ids, entities } = inputData;
    return ids.reduce((acc: EntryComponentProps[], id) => {
      const entry = generateSingleEntryProps(entities[id]!);
      if (entry != null) {
        acc.push(entry);
      }
      return acc;
    }, []);
  }

  // Handle single JournalEntry overload.
  return generateSingleEntryProps(inputData);
}

export function generateNotebookEntryProps(entity: NotebookEntry): EntryComponentProps | undefined;
export function generateNotebookEntryProps(entities: NotebookEntry[]): EntryComponentProps[];
export function generateNotebookEntryProps(notebook: EntityState<NotebookEntry>): EntryComponentProps[];
export function generateNotebookEntryProps(
  inputData: NotebookEntry | NotebookEntry[] | EntityState<NotebookEntry>,
): EntryComponentProps | EntryComponentProps[] | undefined {
  // Handle NotebookEntry[] overload.
  if (Array.isArray(inputData)) {
    return inputData.reduce((acc: EntryComponentProps[], entity) => {
      const entry = generateSingleEntryProps(entity);
      if (entry != null) {
        acc.push(entry);
      }
      return acc;
    }, []);
  }

  // Handle EntityState<JournalEntry | NotebookEntry> overload.
  if ('entities' in inputData) {
    const { ids, entities } = inputData;
    return ids.reduce((acc: EntryComponentProps[], id) => {
      const entry = generateSingleEntryProps(entities[id]!);
      if (entry != null) {
        acc.push(entry);
      }
      return acc;
    }, []);
  }

  // Handle single NotebookEntry overload.
  return generateSingleEntryProps(inputData);
}

function generateSingleEntryProps(entity: JournalEntry | NotebookEntry) {
  const { entryId, baseId } = extractIdsFromEntry(entity.id);
  const entryData = gameData.get(entryId);
  // Validate EntryData
  if (entryData == null) {
    devLogErr(`Could not find entry data for ${entryId}`);
    return undefined;
  }
  if (entryData.kind !== 'evidence' && entryData.kind !== 'policy') {
    devLogErr(`Entry data for ${entryId} is not Evidence or Policy.`);
    return undefined;
  }

  // Get specified baseData, or default to the entryData's parent or itself.
  const baseData = baseId != null ? gameData.get(baseId) : entryData.parent ?? entryData;

  // Validate baseData (if it is still null here, it was specified but not found)
  if (baseData == null) {
    devLogErr(`Could not find base data @ ${baseId} for entry data ${entryId}.`);
    return undefined;
  }

  const content = new Set<string>();
  const collectedStates = new Set<string>();

  // Push default content key if it exists
  const dKey = `${entryId}.default`;
  if (i18n.exists(dKey, { ns: 'entries' })) {
    content.add(dKey);
  }

  // Iterate over facts defined on Evidence data and add _in order_.
  // Use `shortId` of fact (since everything else is the same as the parent)
  if (entryData.kind === 'evidence') {
    entryData.facts.forEach((fact) => {
      if (entity.states.indexOf(fact.shortId) !== -1) {
        collectedStates.add(fact.shortId);
        content.add(`${entryId}.${fact.shortId}`);
      }
    });
  }

  const result: EntryComponentProps = {
    entryData,
    baseData,
    entity,
    derivedType: 'type' in entryData ? entryData.type : entryData.kind,
    date: entity.date,
    heading: `${entryId}.heading`,
    content: [...content],
    more: [],
    unread: entity.unread,
  };
  return result;
}
