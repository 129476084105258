import { getTheme, ITheme, mergeThemes } from '@fluentui/react';
import { IComponentColors, palette, semanticColors } from './colors';
import fonts from './fonts';
import spacing from './spacing';
import effects from './effects';
import buttons from './buttons';
import Modal from './modal';

const theme = mergeThemes(getTheme(), {
  palette,
  fonts,
  spacing,
  effects,
  semanticColors,
  components: {
    ...buttons,
    Modal,
  },
});

export interface ICustomTheme extends ITheme {
  semanticColors: IComponentColors;
}

export default theme as ICustomTheme;
