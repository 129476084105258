import { DefaultButton, Dialog, DialogFooter, DialogType, IDialogContentProps, IDialogStyles, IModalProps, PrimaryButton } from '@fluentui/react';
import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { APIManager } from '../../../../lib/api/APIManager';
import { MPManager } from '../../../../lib/socket';
import { getCurrentGameTheme } from '../../../../theme';
import { greyScale } from '../../../../theme/Global/colors';
import RTrans from '../../RTrans/RTrans';
import { P } from '../../Text';
import { UserInfo } from '../schemas';

export interface IFacilitatorResolveHelpDialogProps {
  user: UserInfo;
  hideResolveHelpDialog: boolean;
  toggleResolveHelpDialog: () => void;
}

const theme = getCurrentGameTheme('Global');

function resolvePlayerIssue(user: UserInfo) {
  const { socket } = MPManager.instance;
  const { username, key } = user;
  if (socket.connected) {
    socket.emit('update:user', {
      username,
      key,
      slug: APIManager.getConfig().event_slug,
      requesting_help: false,
    });
  }
}

const modalProps: IModalProps = {
  isBlocking: true,
};

const dialogStyles: IDialogStyles = {
  root: {},
  main: {
    minWidth: '550px !important',
    backgroundColor: greyScale[2],
  },
};

function generateDialogContentProps(
  name: string, t: TFunction, color: string,
): IDialogContentProps {
  return {
    type: DialogType.normal,
    theme,
    title: t('facilitator.resolveHelpDialogTitle'),
    closeButtonAriaLabel: t('facilitator.close'),
    styles: {
      title: {
        fontSize: 24,
        color,
      },
    },
  };
}

const FacilitatorResolveHelpDialog: React.FC<IFacilitatorResolveHelpDialogProps> = (
  { user, hideResolveHelpDialog, toggleResolveHelpDialog },
) => {
  const { t } = useTranslation('common');
  const name = user.display_name ? user.display_name : user.username;
  const color = theme.palette.themePrimary;
  return (
    <Dialog
      hidden={hideResolveHelpDialog}
      onDismiss={toggleResolveHelpDialog}
      dialogContentProps={generateDialogContentProps(name, t, color)}
      modalProps={modalProps}
      theme={theme}
      styles={dialogStyles}
    >
      <P level={3}><RTrans t={t} i18nKey="facilitator.resolveHelpDialogText" components={{ bold: <strong />, name }} /></P>
      <DialogFooter>
        <DefaultButton onClick={toggleResolveHelpDialog} text="Cancel" />
        <PrimaryButton
          onClick={
            () => {
              resolvePlayerIssue(user);
              toggleResolveHelpDialog();
            }
          }
          text="Confirm"
        />
      </DialogFooter>
    </Dialog>
  );
};
export default FacilitatorResolveHelpDialog;
