import * as React from 'react';
import Screen from '../../../../../components/Global/Screen/Screen';
import AddEvidence, { Checkbox, useAddEvidence } from '../../../../../components/Game01/AddEvidence/AddEvidence';
import { ComponentProps } from '../../../../task-types';
import Modal from '../../../../../components/Game01/Modal/Modal';
import Button from '../../../../../components/Global/Button/Button';

export default function A100({ t, send, match }: ComponentProps): JSX.Element {
  const evidence = [
    {
      id: 'one',
      label: t('A100.labels.evidenceOne'),
      entry: {
        id: 'Episode02.Task14.AppRegistrationRequest',
        states: ['RequestSender'],
      },
      isCritical: true,
    },
    {
      id: 'two',
      label: t('A100.labels.evidenceTwo'),
      entry: {
        id: 'Episode02.Task14.AppRegistrationRequest',
        states: ['RequestSubject'],
      },
      isCritical: true,
    },
  ];

  const { isComplete, handleToggleEvidence, saveEvidence } = useAddEvidence(evidence);

  const isFeedback = match('feedbackComplete');

  const handleSubmit = () => {
    if (isComplete || isFeedback) {
      send('NEXT');
      saveEvidence();
    } else {
      send('CLICK.SUBMIT_INCORRECT');
    }
  };

  return (
    <Screen>
      <AddEvidence
        title={isFeedback ? t('A100.feedback.title') : t('A100.title')}
        description={isFeedback ? t('A100.feedback.body') : t('A100.body')}
        onSubmit={handleSubmit}
        useDark
      >
        {evidence.map((item) => (
          <Checkbox
            key={item.id}
            label={`${item.label}`}
            onChange={(_, checked?: boolean) => handleToggleEvidence(item.id, checked)}
            forceWrap
            showAnswer={isFeedback || match('final')}
            isCorrect={item.isCritical}
          />
        ))}
      </AddEvidence>

      <Modal
        sidebar="character"
        title={t('A100.evidenceAdded.title')}
        body={t('A100.evidenceAdded.body')}
        isOpen={match('final')}
        footer={<Button isPrimary label={t('common.ok')} onClick={() => send('NEXT')} />}
        onDismiss={() => send('NEXT')}
        hideDismissButton
      />
    </Screen>
  );
}
