import { IStackStyles, Stack } from '@fluentui/react';
import { IToggle, IToggleStyles, Toggle } from '@fluentui/react/lib/Toggle';
import * as React from 'react';
import Hint from '../../Hint/Hint';

interface Props {
  id: number;
  label: string;
  onChange: (event: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean) => void;
  hint: string;
  correctState: boolean;
  disabled: boolean;
}

const ToggleStyles: Partial<IToggleStyles> = {
  label: {
    marginRight: '1em',
  },
};

const switchWrapper: IStackStyles = {
  root: [
    {
      position: 'relative',
      margin: '0 20px',
    },
  ],
};

const SwitchInput = React.forwardRef<any, Props>(({ id, label, onChange, hint, correctState, disabled }, ref) => {
  const toggleId = `toggle_${id}`;
  const [isToggled, setIsToggled] = React.useState(false);
  const [showHint, setShowHint] = React.useState(false);
  const toggleRef = React.useRef<IToggle>(null);

  React.useImperativeHandle(
    ref,
    () => ({
      disable() {
        setShowHint(false);
      },
      displayHint() {
        if (toggleRef?.current) {
          toggleRef.current.focus();
        }
        setShowHint(true);
      },
      getIsCorrect() {
        return isToggled === correctState;
      },
    }),
    [isToggled, correctState, setShowHint],
  );

  return (
    <Stack styles={switchWrapper}>
      <Toggle
        id={toggleId}
        componentRef={toggleRef || undefined}
        label={label}
        onChange={(event, checked) => {
          setIsToggled(!!checked);
          onChange(event, checked);
        }}
        inlineLabel
        disabled={disabled}
        styles={ToggleStyles}
      />
      {showHint && <Hint target={`#${toggleId}`}>{hint}</Hint>}
    </Stack>
  );
});

export default SwitchInput;
