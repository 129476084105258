import * as React from 'react';
import {
  ChoiceGroup,
  IChoiceGroupOption,
  IChoiceGroupOptionProps,
  IChoiceGroupOptionStyleProps,
  IRenderFunction,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  mergeStyles,
  Stack,
  Theme,
} from '@fluentui/react';
import isNil from 'lodash/isNil';
import Button from '../../Button/Button';
import Panel from '../../Panel/Panel';
import { Heading, P } from '../../Text';
import { gradients, greyScale } from '../../../../theme/Global/colors';
import { getCurrentGameTheme } from '../../../../theme';

interface SingleSelectProps {
  title: string;
  subtitle?: string;
  body: string;
  onSubmit: (isCorrect: boolean, selectedIndex: number) => void;
  useDark?: boolean;
  nextLabel?: string;
  options: any;
  showAnswer?: boolean;
}

const optionWrapper: IStackStyles = {
  root: [{ marginBottom: 48, width: '100%' }],
};

const questionWrapper: IStackStyles = {
  root: {
    background: gradients.primaryLightReversed,
    borderRadius: 6,
  },
};

const questionWrapperTokens: IStackTokens = {
  padding: 48,
};

const instructionWrapper: IStackItemStyles = {
  root: {
    borderBottom: `1px solid ${greyScale[7]}`,
    marginBottom: '2rem',
    paddingBottom: '2rem',
  },
};

// pieces of code necessary to create mapped options
// custom type for single select option, add more custom props if necessary
type SingleSelectOption = IChoiceGroupOption & { isCorrect: boolean };

// root class for the render function, maybe don't need it
const fieldRootClass = mergeStyles({});

const onRenderField: IRenderFunction<IChoiceGroupOptionProps> = (props?, render?) => {
  return <div className={fieldRootClass}>{render!(props)}</div>;
};

// this where all the func styles are applied, i had to !important a bunch of stuff
function generateMappedOptions(options: SingleSelectOption[], showAnswer: boolean = false, theme: Theme) {
  return options.map(
    (o: SingleSelectOption): SingleSelectOption => ({
      ...o,
      styles: ({ checked, disabled }: IChoiceGroupOptionStyleProps) => {
        return {
          root: {
            pointerEvents: disabled || showAnswer ? 'none' : 'initial',
          },
          field: [
            {
              'display': 'flex',
              'alignItems': 'center',
              'margin': '12px 0',
              'fontSize': 20,
              'fontWeight': 400,
              ':before, :after': {
                top: '50% !important',
                transform: 'translate(0%, -50%) !important',
              },
              ':before': {
                height: 29,
                width: 29,
                border: '3px solid #222',
              },
              ':after': {
                height: '25px !important',
                width: '25px !important',
                left: '2px !important',
              },
              ':hover': {
                '::before': {
                  backgroundColor: theme.palette?.themeSecondary,
                },
                '::after': {
                  backgroundColor: 'transparent',
                },
              },
              ':focus': {
                '::before': {
                  backgroundColor: theme.palette?.themeSecondary,
                },
                '::after': {
                  backgroundColor: 'transparent',
                },
              },
              'span': {
                paddingLeft: '48px !important',
              },
            },
            checked
              ? {
                  ':after': {
                    border: '7px solid #303F88',
                    backgroundColor: theme.palette?.white,
                  },
                  ':hover': {
                    '::before': {
                      backgroundColor: theme.palette?.white,
                      border: `3px solid ${theme.palette?.themeSecondary}`,
                    },
                  },
                  ':active': {
                    '::after': {
                      backgroundColor: theme.palette?.white,
                      border: `5px solid ${theme.palette?.themeSecondary}`,
                    },
                  },
                }
              : {},
            disabled
              ? {
                  ':after': {
                    opacity: 0.5,
                  },
                  ':before': {
                    opacity: 0.5,
                  },
                }
              : {},
          ],
        };
      },
      onRenderField,
    }),
  );
}

export default function SingleSelect({
  title,
  subtitle,
  body,
  onSubmit,
  useDark = false,
  nextLabel = 'Done',
  options,
  showAnswer = false,
}: SingleSelectProps): JSX.Element {
  const [currentSelection, setCurrentSelection] = React.useState<number>();
  const theme = getCurrentGameTheme();

  const correctOption = React.useMemo(() => {
    return options.find((val: SingleSelectOption) => {
      if (val.isCorrect) {
        return val;
      }
      return false;
    });
  }, [options]);

  const isCorrect = currentSelection === correctOption.key;
  const onChange = (ev?: React.SyntheticEvent<HTMLElement>, option?: IChoiceGroupOption) => {
    if (option && !showAnswer) {
      setCurrentSelection(parseInt(option.key, 10));
    }
  };
  const mappedOptions = generateMappedOptions(options, showAnswer, theme);
  // if we need to show the Correct Answer we disable other options
  if (showAnswer) {
    mappedOptions.forEach((mo: SingleSelectOption) => {
      if (!mo.isCorrect) {
        // eslint-disable-next-line no-param-reassign
        mo.disabled = true;
      }
    });
  }
  const selectedKey = showAnswer ? correctOption.key : currentSelection;
  const noKeySelected = isNil(selectedKey);
  return (
    <Panel fullscreen useDark={useDark}>
      <Stack styles={questionWrapper} tokens={questionWrapperTokens}>
        <Stack.Item align="stretch" grow>
          <Heading level={2} block>
            {title}
          </Heading>
          {subtitle && <P level={1}>{subtitle}</P>}
          {/* </span> */}
        </Stack.Item>
        <Stack.Item grow styles={instructionWrapper}>
          <P level={1} block>
            {body}
          </P>
        </Stack.Item>
        <Stack.Item grow verticalFill>
          <Stack verticalAlign="center" horizontalAlign="center" verticalFill>
            <Stack styles={optionWrapper}>
              {/* blowing this whole thing out was the only thing that worked unfortunately, maybe someone else has a clever idea */}
              {!showAnswer && <ChoiceGroup options={mappedOptions} onChange={onChange} selectedKey={selectedKey} />}
              {showAnswer && <ChoiceGroup options={mappedOptions} selectedKey={selectedKey} />}
            </Stack>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Button
            isPrimary
            disabled={noKeySelected}
            label={nextLabel}
            onClick={() => onSubmit(isCorrect, selectedKey)}
          />
        </Stack.Item>
      </Stack>
    </Panel>
  );
}
