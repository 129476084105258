import type { LocaleContent, CardOrEntryLocaleContent } from "../../types";

/**
 * i18Next `entries:Episode01.Task04` lead text content
 */
const Task04Lead: LocaleContent = {
  header: "Set Up Insider Risk Policy",
  role: "Compliance Administrator",
  time: "5-10m",
  brief: "Let's keep an eye on Amari and his team. Set up an insider risk policy for the eCommerce app team. We haven’t configured sensitivity labels yet, but make sure it protects any credit card information stored on their SharePoint site. <br><br>-Andrea",
};

/**
 * i18Next `entries:Episode01.Task04` entry content
 */
const Task04Entries: CardOrEntryLocaleContent = {};

export default {
  ...Task04Lead,
  ...Task04Entries,
};
