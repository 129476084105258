import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Text } from '@fluentui/react';

import PlayerPersonaLive from '../../Global/PlayerPersona/PlayerPersonaLive';
import getFontVariant, { TextComponent } from '../../Global/Text/getFontVariant';

import type { AppliedDataEntity, AppliedDataEntityType } from '../../../lib/multiplayer/schemas';

interface IPhaseReviewPlayerColumnProps {
  username: string;
  type: AppliedDataEntityType;
  data: AppliedDataEntity[];
}

const baseNS = ['evidenceMap', 'entries'];

const PhaseReviewPlayerColumn: React.FC<IPhaseReviewPlayerColumnProps> = ({ username, type, data }) => {
  const { t } = useTranslation(baseNS);

  const filteredData = React.useMemo(() => data.filter((entity) => entity.type === type), [type, data]);

  return (
    <Stack grow={1} tokens={{ childrenGap: '8px' }}>
      <Stack.Item align="center">
        <PlayerPersonaLive username={username} size="small" vertical />
      </Stack.Item>
      {filteredData.map((entity) => (
        <Stack.Item key={entity.id}>
          <Text variant={getFontVariant(TextComponent.Enum.Body, 2)} block>
            • {t(`${entity.id}.heading`)}
          </Text>
        </Stack.Item>
      ))}
    </Stack>
  );
};

export default PhaseReviewPlayerColumn;
