// Game 01 - Episode 01
import andrea from '../../static/images/Game01/Episode01/characters/andrea/andrea_full_large.png';

// Game 01 - Episode 02
import wennyStare from '../../static/images/Game01/Episode02/characters/wenny/wh_ep2_wenny_stare.png';
import wennyAnnoyed from '../../static/images/Game01/Episode02/characters/wenny/wh_ep2_wenny_annoyed.png';
import wennySurprised from '../../static/images/Game01/Episode02/characters/wenny/wh_ep2_wenny_surprised.png';

// Game 02 - Episode 01
import floris from '../../static/images/Game02/Episode01/characters/floris/DF_Characters_Floris1.png';
import mattei from '../../static/images/Game02/Episode01/characters/mattei/DF_Characters_Mattei1.png';
import suchaow from '../../static/images/Game02/Episode01/characters/suchaow/DF_Characters_Suchaow1.png';
import courtney from '../../static/images/Game02/Episode01/characters/courtney/DF_Characters_Courtney1.png';
import sam from '../../static/images/Game02/Episode01/characters/sam/sam.png';

export type Characters =
  | 'andrea'
  | 'olivia'
  | 'abigail'
  | 'none'
  | 'wennyStare'
  | 'wennyAnnoyed'
  | 'wennySurprised'
  | 'floris'
  | 'mattei'
  | 'suchaow'
  | 'courtney'
  | 'sam';

const characters: { [key: string]: string } = {
  andrea,
  wennyStare,
  wennyAnnoyed,
  wennySurprised,
  floris,
  mattei,
  suchaow,
  courtney,
  sam,
};

export default function useCharacter(character?: string): string | undefined {
  if (!character || character === 'none') {
    return undefined;
  }

  return characters[character];
}
