import React from 'react';
import { useTranslation } from 'react-i18next';
import { mergeStyles, Stack } from '@fluentui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle } from '@fortawesome/pro-solid-svg-icons';

import type { IStackStyles, IStackTokens } from '@fluentui/react';

import { useAppSelector } from '../../../store';
import { selectAllAppliedLeadIds } from '../../../store/multiplayer-slice/applied-data';

import type { LeadData } from '../../../lib/game-data/lead-data';
import PaneListItem from '../Pane/PaneListItem';
import { Heading } from '../../Global/Text';

export interface ILeadSelectionButtonProps {
  checked?: boolean;
  disabled?: boolean;
  lead: LeadData;
  onSelect?: (lead?: LeadData) => void;
  isSelected?: boolean;
}

const buttonStackStyles: IStackStyles = { root: { position: 'relative', textAlign: 'left', cursor: 'pointer' } };

const buttonStackTokens: IStackTokens = { padding: 16, childrenGap: '16px' };

const DialogIconStyles = mergeStyles({
  right: '8px',
  fontSize: '42px',
  marginRight: 20,
});

const LeadSelectionButton: React.FC<ILeadSelectionButtonProps> = ({
  isSelected = false,
  lead,
  onSelect,
  disabled = false,
}) => {
  const { t } = useTranslation('entries');
  const completedLeads = useAppSelector(selectAllAppliedLeadIds);
  const clickHandler = React.useCallback(() => onSelect?.call(undefined, lead), [onSelect, lead]);

  return (
    <PaneListItem button onClick={clickHandler} isSelected={isSelected} disabled={disabled}>
      <Stack horizontal styles={buttonStackStyles} tokens={buttonStackTokens} verticalAlign="center" verticalFill>
        <Stack.Item grow={1}>
          <Heading level={4} as={3} block noMargin useDark={!isSelected}>
            {t(`${lead.id}.header`)}
          </Heading>
        </Stack.Item>
        {completedLeads.includes(lead.id) && (
          <Stack.Item className={`fa-layers fa-fw ${DialogIconStyles}`}>
            <FontAwesomeIcon icon={faCircle} color="white" transform="grow-2" />
            <FontAwesomeIcon icon={faCheckCircle} color="#45B847" />
          </Stack.Item>
        )}
      </Stack>
    </PaneListItem>
  );
};

export default LeadSelectionButton;
