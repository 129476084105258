/* eslint-disable no-nested-ternary */
import React, { CSSProperties } from 'react';

import { TFunction } from 'react-i18next';

export interface IMapBackgroundProps {
  data: MapBackgroundNodeData;
}

export interface MapBackgroundNodeData {
  element: CSSProperties;
  onFocus?: () => void;
  t?: TFunction;
}

const nodeSizeBase = 500;

const backgroundStyles: CSSProperties = {
  minWidth: `${nodeSizeBase * 6}px`,
  maxWidth: `${nodeSizeBase * 6}px`,
  minHeight: `${nodeSizeBase * 6}px`,
  maxHeight: `${nodeSizeBase * 6}px`,
};

/**
 * Evidence Map React-Flow card component.
 */
const MapActivity: React.FC<IMapBackgroundProps> = ({ data }) => {
  const backgroundStyle = { ...backgroundStyles, ...data.element };
  return (
    <>
      <div style={backgroundStyle}>
        {'\u200B'}
      </div>
    </>
  );
};

export default MapActivity;
