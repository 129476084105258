/**
 * i18next NS: `Episode02.Task14`
 */
 export default {
  common: {
    errorTitle: "Try Again",
    next: "Next",
    submit: "Submit",
    start: "Start",
    skipAhead: "Skip Ahead",
    ok: "OK",
    done: "Done",
  },
  A020: {
    error: {
    1: {
      title: "Try again.",
      body: "No, that's not the right place.",
    },
    2: {
      title: "Try again.",
      body: "That's not right either.",
    },
    3: {
      title: "Go Here!",
      body: "Microsoft Compliance Center.",
    },
    },
  },
  A030: {
    body: "Here's the Microsoft Compliance Center. Where do you want to go next?",
    labels: {
      alerts: "Alerts",
      contentSearch: "Content Search",
      informationProtection: "Information Protection",
    },
    errors: {
      alerts: {
        title: "There are no relevant Alerts",
        body: "No Alerts have been triggered by this event.",
      },
      informationProtection: {
        title: "No, not Information Protection.",
        body: "You want to run a search, not Apply a label.",
      },
    },
    },
  A040: {
    body: "What's your next step?",
    labels: {
      export: "Export",
      newSearch: "New Search",
      downloadList: "Download List",
    },
    errors: {
      export: {
        title: "There are no results to export.",
        body: "You need to configure the search first.",
      },
      downloadList: {
        title: "Not Download List.",
        body: "We aren't using a list today",
      },
    },
  },
  A060: {
    title: "Use these search conditions.",
    body: 'Remember, we want to see if we can find any messages that mention the app registration. We’ll search Exchange Mailboxes for references to "vacation tool" between 11/1 and 11/9.',
  },
  A080: {
    title: "Here are the search results.",
    body: "Do any of these messages explain when or why Frey created the app registration?  Select a result to view the message.",
    labels: {
      message1: {
        title: "alex.gagnon@bestforyouorganics.onmicrosoft.com",
        subtitle: "Monday 11/1/2021 1:16 PM",
      },
      message2: {
        title: "alex.gagnon@bestforyouorganics.onmicrosoft.com",
        subtitle: "Friday 11/5/2021 11:54 AM",
      },
      message3: {
        title: "alex.gagnon@bestforyorganics.onmicrosoft.com",
        subtitle: "Monday 11/8/2021 9:42 AM",
      },
      message4: {
        title: "alex.gagnon@bestforyouorganics.onmicrosoft.com",
        subtitle: "Monday 11/8/2021 10:17 AM",
      },
      message5: {
        title: "alex.gagnon@bestforyouorganics.onmicrosoft.com",
        subtitle: "Monday 11/8/2021 3:30 PM",
      },
    },
  },
  A090: {
    title: "Nothing here.",
    body: `alex.gagnon@bestforyouorganics.onmicrosoft.com<br/>
    Monday 11/1/2021 1:16 PM<br/><br/>

    Best for Your Organics is launching a new Vacation Request Tool!<br/><br/>

    Starting on November 8, all employees must use the Vacation Request Tool to request personal time off (PTO). Your direct supervisor will use this tool to approve / deny all leave requests. <br/><br/>

    We hope that this tool will make it faster and easier for everyone to manage their time off in the new year.`,
  },
  A092: {
    title: "Nothing here.",
    body: `alex.gagnon@bestforyouorganics.onmicrosoft.com <br/>
    Friday 11/5/2021 11:54 AM<br/><br/>

    A friendly reminder: The new Vacation Request Tool launches on Monday afternoon! Take a look at your calendar. Make sure you that you are ready to enter your leave requests for next year.`,
  },
  A094: {
    title: "You found something!",
    body: `alex.gagnon@bestforyorganics.onmicrosoft.com <br/>
    Monday 11/8/2021 9:42 AM <br/><br/>

    Frey, Can you create an app registration for "BFYOVacationTool"? We're launching our new vacation tool today so we need it done ASAP. Thanks!`,
  },
  A096: {
    title: "Nothing here.",
    body: `alex.gagnon@bestforyouorganics.onmicrosoft.com <br/>
    Monday 11/8/2021 10:17 AM<br/><br/>

    Our new vacation request tool is scheduled to be launched later today. Please see the attached document for our updated leave policy. It includes instructions, FAQs, and where to find support.`,
  },
  A098: {
    title: "Nothing here.",
    body: `alex.gagnon@bestforyouorganics.onmicrosoft.com <br/>
    Monday 11/8/2021 3:30 PM<br/><br/>

    The Vacation Request Tool is now live! You may submit vacation requests for Jan 1 - Dec 31 of next year. Remember, vacation requests are granted on a first-come, first-served basis. `,
  },
  A100: {
    title: "You found the app registration request!",
    body: "Select the critical evidence to add it to your Journal, then select Done.",
    feedback: {
      title: "You didn't get all the critical information.",
      body: "Add this evidence to your Journal.",
    },
    labels: {
      evidenceOne: "alex.gagnon@bestforyorganics.onmicrosoft.com Monday 11/8/2021 9:42 AM",
      evidenceTwo: `Frey, Can you create an app registration for "BFYOVacationTool"? We're launching our new vacation tool today so we need it done ASAP. Thanks!`,
    },
    evidenceAdded: {
      title: "All set!",
      body: "Evidence added to Journal",
    },
  },
  A110: {
    title: "Lead complete!",
    body: "You found mentions of the vacation tool. Before you move on, take a minute to check in with Frey.",
    button: "Apply evidence",
  },
};
