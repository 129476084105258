import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Text } from '@fluentui/react';
import { useDispatch } from 'react-redux';
import { ReactFlowProvider } from 'react-flow-renderer';
import Button from '../../Global/Button/Button';
import Modal from '../Modal/Modal';
import PhaseReviewDataModal from './PhaseReviewDataModal';
import MapContent from '../EvidenceMap/MapContent';
import { useGameService } from '../../../state/GlobalStateProvider';
import getFontVariant, { TextComponent } from '../../Global/Text/getFontVariant';
import { useSteps } from '../../../lib/hooks/useSteps';
import { useAppSelector, useAppStore } from '../../../store';
import { appliedDataSelectors } from '../../../store/multiplayer-slice/applied-data';

import type { AppliedDataEntity, ConsensusUpdatePayload } from '../../../lib/multiplayer/schemas';
import Dialog from '../../Global/Dialog/Dialog';
import {
  emitConsensusUpdate,
  getConsensusKeyPredefined,
  handleNewConsensus,
  selectAllUsersConsensus,
  selectCurrentPhase,
  validateUserVote,
} from '../../../store/multiplayer-slice';
import WaitingForTeamates from '../WaitingForTeammates/WaitingForTeammates';
import { selectAllUsers } from '../../../store/user-slice';

const baseNS = ['game01Common', 'common'];
const evidenceMapNS = ['game01Common', 'entries'];

const PhaseReview: React.FC = () => {
  const { t } = useTranslation(baseNS);
  const { t: tEvidenceMap } = useTranslation(evidenceMapNS);
  const { send: gameSend } = useGameService();
  const appStore = useAppStore();
  const dispatch = useDispatch();
  const { currentStep, gotoNextStep, gotoStep } = useSteps([
    'reviewWrapUp',
    'reviewIntro',
    'reviewCards',
    'reviewClues',
    'reviewMapModal',
    'reviewMap',
  ]);

  const appliedData = useAppSelector((state) => appliedDataSelectors.selectAll(state));
  const currentPhase = useAppSelector(selectCurrentPhase);

  const phaseReviewConsensusKey = useAppSelector((state) => getConsensusKeyPredefined(state, 'end_phase_review'));
  const { localUserConsensuses } = useAppSelector(selectAllUsersConsensus);
  const userVote = localUserConsensuses.find((consensus) => consensus.key === phaseReviewConsensusKey);
  const userEndedPhase = validateUserVote(userVote);
  const { localUser } = useAppSelector(selectAllUsers);

  const appliedPhaseDataPerPlayer = React.useMemo(() => {
    return appliedData.reduce<Record<string, AppliedDataEntity[]>>((accumulator, data) => {
      if (data.user && data.phase === currentPhase?.id) {
        accumulator[data.user] = accumulator[data.user] ?? [];
        accumulator[data.user].push(data);
      }
      return accumulator;
    }, {});
  }, [appliedData, currentPhase]);

  const endPhaseCallback = React.useCallback(() => {
    gameSend('DONE.REVIEW');
  }, [gameSend]);

  const handleEndPhase = () => {
    const endPhasePayload: ConsensusUpdatePayload = {
      key: phaseReviewConsensusKey,
      singleContext: { participantId: localUser.username, answer: 'end' },
      correctAnswer: 'end',
      action: endPhaseCallback,
    };

    if (userVote) {
      // update if already voted
      handleNewConsensus(appStore, endPhasePayload);
    } else {
      // emit if not voted yet
      dispatch(emitConsensusUpdate({ data: endPhasePayload }));
    }
  };

  if (currentStep === 'reviewWrapUp' && currentPhase?.id === 'Game01.Episode02.Phase04') {
    return (
      <Dialog
        title={t('phaseReview.wrapUpHeading')}
        body={t('phaseReview.wrapUpBody')}
        onClick={gotoNextStep}
        useDark
        character="andrea"
        background="office"
      />
    );
  }

  if (currentStep === 'reviewWrapUp' && currentPhase?.id !== 'Game01.Episode02.Phase04') {
    gotoStep(1);
  }

  if (currentStep === 'reviewIntro') {
    return (
      <Dialog
        title={t('phaseReview.introModalHeading')}
        body={t('phaseReview.introModalBody')}
        onClick={gotoNextStep}
        useDark
        character="andrea"
        background="office"
      />
    );
  }

  if (userEndedPhase && userVote?.status !== 'completed') {
    return <WaitingForTeamates consensusKey={phaseReviewConsensusKey} />;
  }
  return (
    <>
      <PhaseReviewDataModal
        isOpen={currentStep === 'reviewCards'}
        heading={t('phaseReview.cardModalHeading')}
        description={t('phaseReview.cardModalDescription')}
        buttonLabel={t('buttons.next')}
        playerData={appliedPhaseDataPerPlayer}
        entityType="card"
        onClick={gotoNextStep}
      />
      <PhaseReviewDataModal
        isOpen={currentStep === 'reviewClues'}
        heading={t('phaseReview.clueModalHeading')}
        description={t('phaseReview.clueModalDescription')}
        buttonLabel={t('buttons.next')}
        playerData={appliedPhaseDataPerPlayer}
        entityType="clue"
        onClick={gotoNextStep}
      />
      <Modal
        isOpen={currentStep === 'reviewMapModal'}
        hideDismissButton
        sidebar="character"
        title={t('phaseReview.reviewMapModalHeading')}
        footer={<Button isPrimary label={t('buttons.next')} onClick={gotoNextStep} />}
      >
        <Text variant={getFontVariant(TextComponent.enum.Body, 1)}>{t('phaseReview.reviewMapModalBody')}</Text>
      </Modal>
      <Stack style={{ position: 'absolute', inset: 'auto 274px 16px auto', zIndex: 5 }}>
        <Stack.Item>
          <Button
            isPrimary
            label={t('phaseReview.questionsButtonLabel')}
            disabled={currentStep !== 'reviewMap'}
            onClick={handleEndPhase}
          />
        </Stack.Item>
      </Stack>
      <ReactFlowProvider>
        <MapContent t={tEvidenceMap} fitOnLoad />
      </ReactFlowProvider>
    </>
  );
};

export default PhaseReview;
