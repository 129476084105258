import type { LocaleContent, CardOrEntryLocaleContent } from "../../types";

/**
 * i18Next `entries:Episode01.Task11` lead text content
 */
const Task11Lead: LocaleContent = {
  header: "Investigate Angel's Sign-In Logs",
  role: "Identity Administrator",
  time: "10m",
  brief: "Review login information around the time of those chat messages from Angel to Amari. Let me know what you find. <br><br>-Andrea",
};

/**
 * i18Next `entries:Episode01.Task11` entry content
 */
const Task11Entries: CardOrEntryLocaleContent = {
  User2Secure: {
    heading: "User2 Secure",
    default: "You investigated the security of Angel's account.",
    NoEvidence: "There is no evidence that Angel's account was breached by a third party.",
  },
};

export default {
  ...Task11Lead,
  ...Task11Entries,
};
