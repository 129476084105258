import { IStackItemStyles, IStackStyles, IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../Global/Button/Button';
import Panel from '../../Global/Panel/Panel';
import { Heading, P } from '../../Global/Text';
import Checkbox from '../../Global/Interactions/Checkbox/Checkbox';
// import type { AddArgument } from '../../../lib/types';
import { addEntriesWithUnlocks } from '../../../store/journal-slice';
import { JournalEntryUpdate } from '../../../lib/journal/types';
import useActivityFocus from '../../../lib/hooks/useActivityFocus';

// Re-export for easier use when composing contents
export { Checkbox };

/**
 * Very basic interface for Evidence.
 */
interface Evidence {
  id: string;
  entry: JournalEntryUpdate;
  isCritical?: boolean;
}

export interface IAddEvidenceProps {
  title: string;
  description: string;
  // onComplete?: IButtonProps['onClick'];
  // onSubmit?: AddArgument<NonNullable<IButtonProps['onClick']>, Evidence[]>;
  onSubmit: () => void;
  useDark?: boolean;
  nextLabel?: string;
}

const evidenceWrapper: IStackStyles = {
  root: {
    background: 'white',
    borderRadius: 6,
    overflow: 'hidden',
  },
};

const evidenceHeader: IStackItemStyles = {
  root: {
    background: 'black',
  },
};

const evidenceHeaderTokens: IStackTokens = {
  padding: '3rem 3rem 2rem',
};

const optionWrapper: IStackStyles = {
  root: {
    marginBottom: 60,
  },
};

const optionWrapperTokens: IStackTokens = {
  padding: '0 2rem',
};

const selectStyles: IStackItemStyles = {
  root: {
    border: 'thin solid #ddd',
    margin: '1rem 0',
    borderRadius: 6,
  },
};

const selectTokens: IStackTokens = {
  padding: '2rem',
};

// const questionWrapper: IStackStyles = {
//   root: {
//     background: gradients.primaryLightReversed,
//     borderRadius: 6,
//   },
// };

export function useAddEvidence(evidence: Evidence[]) {
  const dispatch = useDispatch();
  const [savedEvidence, setSavedEvidence] = React.useState(
    evidence.reduce((accumulator, { id }) => {
      accumulator[id] = false;
      return accumulator;
    }, {} as Record<string, boolean>),
  );

  const evidenceDictionary = React.useMemo(() => {
    return evidence.reduce((accumulator, entry) => {
      accumulator[entry.id] = entry;
      return accumulator;
    }, {} as { [key: string]: Evidence });
  }, [evidence]);

  const criticalEvidence = React.useMemo(() => {
    return evidence.reduce((accumulator, element) => {
      if (element.isCritical && element.id) {
        accumulator.push(element.id);
      }
      return accumulator;
    }, [] as string[]);
  }, [evidence]);

  const saveEvidence = () => {
    Object.keys(savedEvidence).forEach((evidenceKey: string) => {
      if (savedEvidence[evidenceKey] || criticalEvidence.includes(evidenceKey)) {
        dispatch(addEntriesWithUnlocks(evidenceDictionary[evidenceKey].entry as JournalEntryUpdate));
      }
    });
  };

  const handleToggleEvidence = (id: string, checked?: boolean) =>
    setSavedEvidence({ ...savedEvidence, [id]: checked ?? false });

  const isComplete = criticalEvidence.map((id) => savedEvidence[id]).every((val) => val);

  return { isComplete, handleToggleEvidence, saveEvidence };
}

export default function AddEvidence({
  title,
  description,
  onSubmit,
  useDark = false,
  children,
  nextLabel = 'Done',
}: React.PropsWithChildren<IAddEvidenceProps>) {
  const hasSingleChild = React.Children.count(children) === 1;
  const { focusRef } = useActivityFocus();

  return (
    <Panel useDark={useDark} fullscreen>
      <Stack.Item align="stretch">
        <Stack verticalAlign="stretch" wrap styles={evidenceWrapper} tokens={{ childrenGap: 40 }}>
          <Stack.Item
            align="stretch"
            styles={evidenceHeader}
            tokens={evidenceHeaderTokens}
            aria-live="polite" // So screenreader knows to read swapped text on feedback
            aria-relevant="text"
          >
            <span ref={focusRef} tabIndex={-1}>
              <Heading level={1} block useDark noMargin>
                {title}
              </Heading>
            </span>
            <P level={1} useDark block>
              {description}
            </P>
          </Stack.Item>
          <Stack styles={optionWrapper} tokens={optionWrapperTokens}>
            {hasSingleChild ? (
              <Stack.Item align="start">{children}</Stack.Item>
            ) : (
              React.Children.map(children, (child) => (
                <Stack.Item styles={selectStyles} tokens={selectTokens}>
                  {child}
                </Stack.Item>
              ))
            )}
            <Stack.Item grow>
              <Button isPrimary label={nextLabel} onClick={onSubmit} />
            </Stack.Item>
          </Stack>
        </Stack>
      </Stack.Item>
    </Panel>
  );
}
