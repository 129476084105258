/* eslint-disable @typescript-eslint/no-use-before-define */
import { differenceInMinutes } from 'date-fns';
import React from 'react';
import { useLocation, useParams } from 'react-router';
import { APIManager } from '../../../lib/api/APIManager';

import { initAPIAndTryReload } from '../../../lib/api/init';
import { MPManager } from '../../../lib/socket';
import { hasDebugFlag, isDev, isStaging } from '../../../lib/util';
import { useAppStore } from '../../../store';
import { kickEarlyUserAndClearSession } from '../../../store/local-session';
import { selectUserIsRegistered } from '../../../store/user-slice';
import LoadGate, { LoadGateOnLoadCallback } from './LoadGate';

const EventLoadGate: React.FC = ({ children }: React.PropsWithChildren<{}>) => {
  const location = useLocation();
  const routeParams: any = useParams();
  const searchParams = new URLSearchParams(location.search);
  const user_id = searchParams.get('user_id');
  const store = useAppStore();

  const socketCallback = React.useCallback<LoadGateOnLoadCallback>(
    ({ done, setMessages }) => {
      const { eventInfo } = store.getState().game;
      const rootState = store.getState();

      if (eventInfo == null) {
        setMessages(['Could not login the player to the event.', 'The event does not exist.']);
        return;
      }

      if (!hasDebugFlag('loadgate')) {
        if (eventInfo.start_date == null || eventInfo.end_date == null) {
          setMessages(['Could not login the player to the event.', 'The start and end dates were not set for the event.']);
          return;
        }

        if (differenceInMinutes(eventInfo.start_date, Date.now()) > 30 && !isStaging && !isDev) {
          kickEarlyUserAndClearSession({ full: true });

          setMessages(['Could not login the player to the event.', 'It is too early to attempt entry into the game. Please reload this page or try again within 30 minutes of the scehduled date/time.']);
          return;
        }

        if (differenceInMinutes(Date.now(), eventInfo.end_date) > 0) {
          setMessages(['Could not login the player to the event.', 'This event has already reached its scheduled end date/time.']);
          return;
        }

        if (eventInfo.status === 'started' && !selectUserIsRegistered(rootState)) {
          setMessages(['Could not login the player to the event.', 'The game for this event has already been started by facilitators.']);
          return;
        }
      }
      done();
  }, [store]);

  const setupSocketAndConnect = React.useCallback<LoadGateOnLoadCallback>(
    ({ done, setMessages }) => {
    const { key, username, event_slug } = APIManager.getConfig();
    MPManager.instance.setupAndConnectSocket(process.env.REACT_APP_SOCKET_URL,
      {
        auth: {
          key,
          username,
          slug: event_slug,
        },
      },
      (error?: string) => {
        if (error) {
          setMessages(['Could not login the player to the event.', error]);
          return;
        }
        socketCallback({ done, setMessages });
      },
    );
  }, [socketCallback]);

  const { event_slug } = routeParams;
  const loadHandler = React.useCallback<LoadGateOnLoadCallback>(
    ({ done, setMessages }) => {
      initAPIAndTryReload({
        done,
        setMessages,
        onLoadParams: { event_slug, username: user_id === null ? undefined : user_id },
        onLoadCallbacks: { socketCallback: setupSocketAndConnect },
      });
    }, [event_slug, user_id, setupSocketAndConnect],
  );

  return (
    <LoadGate onLoad={loadHandler}>
      {children}
    </LoadGate>
  );
};

export default EventLoadGate;
