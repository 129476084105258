/**
 * i18next NS: `Episode01.Phase02Review`
 */
export default {
  dialog: {
    intro: {
      title: "I need an update.",
      body: "Take a look at the evidence and answer the following question.",
    },
    final: {
      title: "Continue your investigation.",
      body: "Hey! The lockdown is over so if you're looking for me, I’ll be in my office. I want you to know no matter what happens, I’ll do my best to keep them from replacing you. I believe in you!",
      nextBtn: "Go to Phase 3 set of leads",
    },
  },
  question: {
    title: "Were any other files exfiltrated?",
    body: "Discuss this with your team, then select your answer.",
    optionOne: "Yes",
    optionTwo: "No",
    feedbackTitle: "No, check your evidence map.",
    feedbackBody: "Several other files were exfiltrated.",
    feedbackNextLabel: "Next",
  },
  proof: {
    title: "What other files were exfiltrated?",
    body: "Discuss this with your team, then select the file names.",
    optionOne: "P and L Summary.xlsx",
    optionTwo: "InventoryList.xlsx",
    optionThree: "Mark 8 Parts and Specs List.xlsx",
    optionFour: "EU Launch Workback Plan.xlsx",
    optionFive: "Customer Survey.docx",
    optionSix: "Sales Results Overview.xlsx",
    optionSeven: "Employee Information.docx",
    optionEight: "UI UX Guidelines.docx",
    feedbackTitle: "No, check your evidence map.",
    feedbackBody: "This is not the correct list of exfiltrated files.",
    feedbackNextLabel: "Next",
  },
};
