/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';
import { Route, useHistory, useLocation, useParams } from 'react-router-dom';
import { IStackItemStyles, IStackStyles, Stack } from '@fluentui/react';
import { useDispatch } from 'react-redux';
import { MPManager } from '../../../lib/socket';
import { APIManager } from '../../../lib/api/APIManager';
import { IOSocket } from '../../../lib/socket/types';
import { ClientListenEventsMap } from '../../../lib/socket/typed-events';
import UserListPanel from './comps/UserListPanel';
import { EventData } from '../../../lib/api/types';
import FacilitatorNotificationPanel from './comps/FacilitatorNotificationPanel';
import FacilitatorSidebar from './comps/FacilitatorSidebar';
import EventInfoPanel from './comps/EventInfoPanel';
import SessionListPanel from './comps/SessionListPanel';
import './comps/Toast/Toast.css';
import { SessionGroup } from './schemas';
import { setSessionGroups } from '../../../store/facilitator-slice';
import { getCurrentGameTheme } from '../../../theme';

const theme = getCurrentGameTheme('Global');

const outerStyles: IStackStyles = {
  root: {
    backgroundColor: theme.palette.white,
    color: theme.palette.themePrimary,
    height: '100vh',
    maxWidth: '100%',
  },
};

const columnStyles: IStackItemStyles = {
  root: {
    marginRight: 24,
  },
};

const baseEventState: EventData = {
  status: '',
  catalog_code: null,
  primary_language: null,
  start_date: null,
  end_date: null,
  date_started: null,
  date_ended: null,
  total_users: 0,
  facilitators: [],
  users: [],
  sessions: [],
};

const setupSocketAndConnect = (socket: IOSocket, onConnectCallback: Function) => {
  if (!socket.connected) {
    // setup sockect event handlers and connect
    socket.on('connect', () => {
      onConnectCallback();
      socket.emit('login:facilitator', {
        username: APIManager.getConfig().username,
        key: APIManager.getConfig().key,
        slug: APIManager.getConfig().event_slug,
      });
    });
    socket.connect();
  }
};

const addEventListener = (socket: IOSocket, key: keyof ClientListenEventsMap, callback: Function) => {
  socket.on(key, (data?: any) => {
    callback(data);
  });
};

const Facilitator: React.FC = () => {
  const [isEventStarted, setEventStarted] = useState(false);
  const [isEventStartable, setEventStartable] = useState(false);
  const [isEventEnded, setEventEnded] = useState(false);
  const [isMPConnected, setMPConnected] = useState(false);
  const [event, setEvent] = useState(baseEventState);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const routeParams: any = useParams();
  const { facilitator_slug } = routeParams;

  useEffect(() => {
    const socket = MPManager.instance.setupSocket(process.env.REACT_APP_SOCKET_URL);
    setupSocketAndConnect(socket, () => {
      setMPConnected(true);
      addEventListener(socket, 'data:event', (data: any) => {
        if (data.status === 'scheduled') {
          setEventStartable(true);
        } else {
          setEventStartable(false);
        }
        if (data.status === 'started') {
          setEventStarted(true);
        } else if (data.status === 'completed') {
          setEventEnded(true);
          history.push(`/facilitator/${facilitator_slug}`);
        }
        setEvent(data);
      });

      addEventListener(socket, 'data:sessions', (data: SessionGroup[]) => {
        dispatch(setSessionGroups(data));
      });
    });
  }, [dispatch, facilitator_slug, history]);

  const startEvent = () => {
    const { socket } = MPManager.instance;
    socket.emit('start:event', {
      slug: APIManager.getConfig().event_slug,
    }, (response) => {
      if (response?.status === 'success') {
        setEventStartable(false);
        setEventStarted(true);
        history.push(`/facilitator/${facilitator_slug}/sessions`);
      }
    });
  };

  const endEvent = () => {
    const { socket } = MPManager.instance;
    socket.emit('end:event', {
      slug: APIManager.getConfig().event_slug,
    }, (response) => {
      if (response?.status === 'success') {
        setEventEnded(true);
        setEventStarted(false);
      }
    });
  };

  const disconnectUser = (username: string) => {
    const { socket } = MPManager.instance;
    socket.emit('abandonAll:update:game', {
      slug: APIManager.getConfig().event_slug,
      username,
    });
  };

  const logoutUser = (username: string) => {
    const { socket } = MPManager.instance;
    socket.emit('kick:user', {
      slug: APIManager.getConfig().event_slug,
      username,
    });
  };

  const removeUser = (username: string) => {
    const { socket } = MPManager.instance;
    socket.emit('abandonAll:update:game', {
      slug: APIManager.getConfig().event_slug,
      username,
    }, (response) => {
      if (response?.status === 'success') {
        socket.emit('kick:user', {
          slug: APIManager.getConfig().event_slug,
          username,
          disable: true,
        });
      }
    });
  };

  return (
    <Stack horizontal styles={outerStyles}>
      <FacilitatorSidebar
        event={event}
        onStartEvent={startEvent}
        onEndEvent={endEvent}
        isEventStartable={isEventStartable}
        isMPConnected={isMPConnected}
        isEventStarted={isEventStarted}
        isEventEnded={isEventEnded}
      />
      <Stack horizontal grow styles={{ root: { padding: 24, height: '100%', overflowY: 'auto' } }}>
        {!location.pathname.includes('sessions') && (
          <Stack.Item styles={columnStyles}>
            <Stack>
              <Stack.Item>
                <EventInfoPanel
                  event={event}
                  onStartEvent={startEvent}
                  onEndEvent={endEvent}
                  isEventStartable={isEventStartable}
                  isMPConnected={isMPConnected}
                  isEventStarted={isEventStarted}
                  isEventEnded={isEventEnded}
                />
              </Stack.Item>
              <Stack.Item>
                <FacilitatorNotificationPanel users={event.users} />
              </Stack.Item>
            </Stack>
          </Stack.Item>
        )}
        <Stack.Item grow>
          <Route exact path="/facilitator/:facilitator_slug">
            <UserListPanel
              users={event.users}
              showDisconnect={event.status === 'started'}
              showLogout={event.status === 'scheduled'}
              onDisconnectUser={disconnectUser}
              onLogoutUser={logoutUser}
              onRemoveUser={removeUser}
            />
          </Route>
          <Route exact path="/facilitator/:facilitator_slug/sessions">
            <SessionListPanel
              users={event.users}
              eventStatus={event.status}
              showDisconnect={event.status === 'started'}
              onDisconnectUser={disconnectUser}
              onRemoveUser={removeUser}
            />
          </Route>
        </Stack.Item>
      </Stack>
    </Stack>
  );
};

export default Facilitator;
