import { createMachine, assign, send } from 'xstate';
import { TaskConfig, TaskOptions, InteractionTaskContext, TaskEvent } from '../../../task-types';
import { addScore, getDefaultOptions } from '../../../task-options';
import { store as reduxStore, getCurrentLeadId } from '../../../../store';

const initialState: InteractionTaskContext = {
  // One tracker for all attempts; will get reset. Consider changing this
  attempts: 0,
  walkthroughStep: 0,
  maxAttempts: 3,
  store: reduxStore,
};

const POINTS_VALUE = 50;
const TASK_ID = 'Game01.Episode01.Task09';

export const Task09Config: TaskConfig<InteractionTaskContext, TaskEvent> = {
  id: 'Task09',
  context: initialState,
  initial: 'A010',
  states: {
    A010: {
      // Desktop Select - 3 chances to click correctly
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          always: {
            target: 'hint',
            cond: (ctx) => ctx.attempts === 3,
          },
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        hint: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          // send fucntion will fire immediately after addScore advancing to the next state
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: { target: '#Task09.A020' },
          },
        },
      },
    },
    A020: {
      // Multi-choice single answer 3 buttons
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        hint: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: {
              target: '#Task09.A030',
            },
          },
        },
      },
    },
    A030: {
      // Interstitial
      entry: 'resetContext',
      on: {
        NEXT: {
          target: '#Task09.A040',
        },
      },
    },
    A040: {
      // Interstitial
      entry: 'resetContext',
      on: {
        NEXT: {
          target: '#Task09.A050',
        },
      },
    },
    A050: {
      // Multiple Choice/Multiple Answer
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.SUBMIT_INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          always: {
            target: 'feedback',
            cond: (ctx) => ctx.attempts >= 2,
          },
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        feedback: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        success: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          // send fucntion will fire immediately after addScore advancing to the next state
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: {
              target: '#Task09.A075',
            },
          },
        },
      },
    },
    A075: {
      // Insterstitial
      entry: 'resetContext',
      on: {
        NEXT: {
          target: '#Task09.A080',
        },
      },
    },
    A080: {
      // Switches
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.SUBMIT_INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'success',
            },
            'CLICK.FEEDBACK': {
              target: 'feedback',
            },
          },
        },
        incorrect: {
          always: {
            target: 'walkthrough',
            cond: (ctx) => ctx.attempts >= 3,
          },
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        success: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        walkthrough: {
          always: {
            target: 'success',
            cond: (ctx) => ctx.walkthroughStep === 12,
          },
          on: {
            NEXT: {
              actions: 'incrementWalkthroughStep',
              target: 'walkthrough',
            },
          },
        },
        feedback: {
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        final: {
          // send fucntion will fire immediately after addScore advancing to the next state
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: { target: '#Task09.B010' },
          },
        },
      },
    },
    B010: {
      // Multi-choice single answer 3 buttons
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        hint: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: {
              target: '#Task09.B020',
            },
          },
        },
      },
    },
    B020: {
      // Interstitial with confirmation
      initial: 'interstitial',
      entry: 'resetContext',
      states: {
        interstitial: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          entry: 'resetContext',
          always: {
            target: '#Task09.B030',
          },
        },
      },
    },
    B030: {
      // Interstitial
      entry: 'resetContext',
      on: {
        NEXT: {
          target: 'A010',
        },
      },
    },
  },
};

/**
 * Additional machine options.
 */
export const Task09Options: TaskOptions<InteractionTaskContext, TaskEvent> = {
  actions: {
    resetContext: assign({ attempts: () => 0, walkthroughStep: () => 0 }),
    incrementAttempts: assign({ attempts: (context) => context.attempts + 1 }),
    incrementWalkthroughStep: assign({ walkthroughStep: (context) => context.walkthroughStep + 1 }),
    setMaxAttemptsToTwo: assign({
      attempts: (context) => context.attempts,
      walkthroughStep: (context) => context.walkthroughStep,
      maxAttempts: (context) => {
        if (context.maxAttempts) {
          context.maxAttempts -= 1;
        }
        return 2;
      },
    }),
  },
  guards: {},
};

/**
 * Machine constructor.
 */
export function createTask09() {
  return createMachine<InteractionTaskContext, TaskEvent>(Task09Config, getDefaultOptions()).withConfig(Task09Options);
}
