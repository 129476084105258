import { IStackStyles, IStackTokens, Stack } from '@fluentui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useGameService } from '../../../state/GlobalStateProvider';
import Button from '../../Global/Button/Button';
import Modal from '../Modal/Modal';
import { Heading, P } from '../../Global/Text';
import EvidenceItem from './EvidenceItem';

import type { JournalEntryComponentProps } from '../../../lib/entries';
import { getCurrentGameTheme } from '../../../theme';

interface Props {
  evidence: JournalEntryComponentProps[];
  canComplete: boolean;
  completionStatus: { [key: string]: CompletionState };
  applyEvidence: (evidenceId: string, facts: string[]) => void;
}

// Todo: This interface is duplicated in Apply Evidence
interface CompletionState {
  isComplete: boolean;
  isCritical: boolean;
}

const theme = getCurrentGameTheme('Game01');

const outerWrapper: IStackStyles = {
  root: {
    backgroundColor: 'rgba(255,255,255,.1)',
    borderRadius: theme.effects.roundedCorner6,
    borderLeft: '1px solid rgba(255,255,255,.1)',
    borderRight: '1px solid rgba(255,255,255,.1)',
    borderBottom: '2px solid rgba(255,255,255,.3)',
    width: 554,
  },
};

const contentStackStyles: IStackStyles = {
  root: {
    height: '100%',
    maxHeight: 488,
    overflowY: 'auto',
    overflowX: 'hidden',
    margin: '0px 10px 0 10px',
    backgroundColor: 'rgba(0,0,0,.2)',
    borderRadius: theme.effects.roundedCorner6,
  },
};

const headerStackTokens: IStackTokens = {
  padding: '12px 24px 12px 24px',
};

const contentStackTokens: IStackTokens = {
  padding: '4px',
  childrenGap: '8px',
};

const footerStackStyles: IStackStyles = {
  root: {
    padding: 24,
    childrenGap: '8px',
  },
};

export default function EvidencePanel({ evidence, canComplete, completionStatus, applyEvidence }: Props): JSX.Element {
  const { send } = useGameService();

  const { t } = useTranslation(['entries', 'common', 'game01Common']);
  const [completionState, setCompletionState] = React.useState<'COMPLETE' | 'ERROR' | 'IN-PROGRESS'>('IN-PROGRESS');

  const handleFinish = () => {
    if (canComplete) {
      send('DONE.EVIDENCE');
    } else {
      setCompletionState('ERROR');
    }
  };

  const appliedEvidenceCount = evidence.reduce((acc, { entryData }) => {
    if (completionStatus[entryData.id].isComplete && completionStatus[entryData.id].isCritical) {
      return acc + 1;
    }
    return acc;
  }, 0);

  const totalCritical = evidence.reduce((acc, { entryData }) => {
    if (entryData.importance === 'critical') {
      return acc + 1;
    }
    return acc;
  }, 0);

  return (
    <Stack styles={outerWrapper}>
      <Stack tokens={headerStackTokens} as="header">
        <Stack.Item>
          <Heading level={2} useDark block noMargin>
            {t('applyEvidence.log.title')}
          </Heading>
          {totalCritical > 0 && (
            <P level={1} useDark>
              {t('applyEvidence.cardProgressLabel', { applied: appliedEvidenceCount, total: totalCritical })}
            </P>
          )}
        </Stack.Item>
      </Stack>
      <Stack styles={contentStackStyles} tokens={contentStackTokens}>
        <ul style={{ margin: 0, padding: 0 }}>
          {evidence.map((data, index) => {
            return (
              <li key={data.entryData.id} style={{ marginBottom: index === evidence.length - 1 ? 0 : 4 }}>
                <EvidenceItem
                  key={data.entryData.id}
                  id={data.entryData.id}
                  onApply={applyEvidence}
                  isComplete={completionStatus[data.entryData.id].isComplete}
                  content={data.content}
                  facts={data.entity.states}
                  type={data.derivedType}
                />
              </li>
            );
          })}
        </ul>
      </Stack>
      <Stack styles={footerStackStyles} as="footer" verticalAlign="end">
        <Button isPrimary onClick={handleFinish} label={t('labels.finish')} />
      </Stack>
      <Modal
        sidebar="error"
        title={t('applyEvidence.notAppliedError.title')}
        isOpen={completionState === 'ERROR'}
        hideDismissButton
        onDismiss={() => setCompletionState('IN-PROGRESS')}
        footer={
          <Button
            isPrimary
            label={t('buttons.again')}
            onClick={() => {
              setCompletionState('IN-PROGRESS');
            }}
          />
        }
      />
    </Stack>
  );
}
