import React from 'react';
import { mergeStyles } from '@fluentui/react';
import { IKeyframes, keyframes } from '@fluentui/merge-styles';
// import gsap from 'gsap';
// import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { ElementDataWire } from '../../../lib/game-data/element-data';
import { useAppStore } from '../../../store';
import { selectCircuitStatus, selectWireEnoughPower } from '../../../store/multiplayer-slice/applied-data';
import { imageList } from '../../../static/images';
import { ImageComponentSingle } from '../../../static/images/image-types';

export interface IMapEdgeProps {
  data: MapEdgeData;
}

export interface MapEdgeData {
  element: ElementDataWire;
}

export type EdgeStatusType = 'deactivated' | 'activated';

const dashAnimation: IKeyframes = {
  to: {
    strokeDashoffset: 1000,
  },
};

const wireAnimationClassname = mergeStyles(
  {
    'g g path:nth-of-type(2)': {
      strokeDasharray: 80,
      animation: `${keyframes(dashAnimation)} 4s linear infinite`,
    },
  },
);
/**
 * Evidence Map React-Flow card component.
 */
const MapEdge: React.FC<IMapEdgeProps> = ({
  data: { element },
}) => {
  const state = useAppStore().getState();

  const nodeSizeBase = 21;

  const mapEdgeStyles = {
    minWidth: `${nodeSizeBase * 10}px`,
    maxWidth: `${nodeSizeBase * 10}px`,
    minHeight: `${nodeSizeBase * 10}px`,
    maxHeight: `${nodeSizeBase * 10}px`,
    zIndex: 0,
  };
  const activatedCondition = React.useMemo(
    () => {
      // eslint-disable-next-line no-nested-ternary
      return element.discipline === 'multiple' ?
        element.getLocation()?.getLocation()?.type === 'node' ?
          element.getLinks()?.find((link) => selectCircuitStatus(state, link.id) === 'completed') :
          selectCircuitStatus(state, element.getLocation()?.getLocation()?.id) === 'completed' :
        selectWireEnoughPower(state, element);
    }, [element, state]);

  const status: EdgeStatusType = activatedCondition ? 'activated' : 'deactivated';
  const nodeComponent = imageList.find((image) => image.name === `${element.id}.${status}`) as ImageComponentSingle;
  if (!nodeComponent) return (<></>);

  // if (status === 'activated' && element.getLocation()?.getLocation()?.type !== 'start') {
  //   React.Children.map(nodeComponent.Component.defaultProps?.children, (child) => {
  //     child.clas
  //   });
  // }

  return (
    <>
      <div style={mapEdgeStyles}>
        <nodeComponent.Component
          className={status === 'activated' && element.getLocation()?.getLocation()?.type !== 'start' ? `${wireAnimationClassname}` : ''}
          id={`Edge-${element.shortId}`}
          width="100%"
          height="100%"
        />
      </div>
    </>
  );
};

export default MapEdge;

/* moving a pin on the wire
  gsap.registerPlugin(MotionPathPlugin);
  gsap.defaults({
    ease: 'power1.inOut',
    duration: 5,
  });

  const tl = gsap.timeline({ repeat: -1, repeatDelay: 1 });

  tl.to(`#Edge-${element.shortId} .marker`, {
    motionPath: {
      path: `.cls-1-${element.shortId}`,
      align: `.cls-1-${element.shortId}`,
      alignOrigin: [0, 0.5],
      autoRotate: true,
    },
  });

  tl.progress(1).progress(0);
  gsap.set(`#Edge-${element.shortId}`, { autoAlpha: 1 });
*/
