/* eslint-disable no-continue */
import React, { ReactElement, ReactNode } from 'react';

import type { EventObject, State, Typestate } from 'xstate';

import { store } from '../../store';
import { coerceArray, unwrapSingle } from '../util';

import type { RootState } from '../../store';
import type { ArrayOrSingle } from '../types';

/**
 * Definition object for state matching.
 */
export type StateMatcher<
  TContext,
  TEvent extends EventObject = EventObject,
  TTypestate extends Typestate<TContext> = { value: any; context: TContext },
  TState extends State<TContext, TEvent, any, TTypestate> = State<TContext, TEvent, any, TTypestate>,
> = {
  /**
   * One or more values to evaluate against `state.matches()`. Test will pass if _any_ succeed.
   */
  states?: ArrayOrSingle<string>;
  /**
   * A predicate function to evaluate against the machine `state` object and/or Redux store state.
   */
  test?: (machineState: TState, storeState: RootState) => boolean;
  /**
   * Whether to add to ('additive') or replace ('exclusive') any existing matches,
   * or to only show if nothing else matches. Default is 'exclusive'.
   */
  type?: 'additive' | 'exclusive' | 'fallback';
  /**
   * Whether to stop matching if this match succeeds.
   */
  final?: boolean;
  /**
   * The content to display if the match succeeds.
   */
  content: ArrayOrSingle<ReactElement | null> | ((state: TState) => ArrayOrSingle<ReactElement | null>);
};

export type StateMatcherOptions = {
  contentWrapper?: ReactElement<{ children?: ReactNode | undefined }>;
  resultsWrapper?: ReactElement<{ children?: ReactNode | undefined }>;
};

/**
 * Run StateMatchers against an Xstate state and return results.
 */
export function getMatcherResults<
  TContext,
  TEvent extends EventObject = EventObject,
  TTypestate extends Typestate<TContext> = { value: any; context: TContext },
>(
  state: State<TContext, TEvent, any, TTypestate>,
  matchers: StateMatcher<TContext, TEvent, TTypestate>[],
  options: StateMatcherOptions = {},
): ArrayOrSingle<ReactElement | null> {
  const { contentWrapper, resultsWrapper } = options;
  const matchedElements: ReactElement[] = [];
  const matchedFallbackElements: ReactElement[] = [];

  // Get current state of Redux store for test predicates
  const storeState = store.getState();

  for (let index = 0; index < matchers.length; index++) {
    const { states, test, type, final, content } = matchers[index];

    // Skip if states defined but don't match.
    if (states != null && states.length > 0 && !coerceArray(states).some(state.matches)) {
      continue;
    }

    // Skip if predicate exists and fails.
    if (test != null && !test(state, storeState)) {
      continue;
    }

    // Evaluate content if it is a function.
    const evaluatedContent = typeof content === 'function' ? content(state) : content;

    // Wrap content
    const wrappedContent =
      contentWrapper == null ? (
        <React.Fragment key={index}>{evaluatedContent}</React.Fragment>
      ) : (
        React.cloneElement(contentWrapper, { key: index }, evaluatedContent)
      );

    switch (type) {
      // 'additive' matches are all wrapped and displayed.
      case 'additive':
        matchedElements.push(wrappedContent);
        break;
      // 'Fallback' matches are collected for if nothing else matches.
      case 'fallback':
        matchedFallbackElements.push(wrappedContent);
        break;
      // (Default) 'Exclusive' matches replace all prior matches.
      case 'exclusive':
      default:
        matchedElements.splice(0, matchedElements.length, wrappedContent);
        break;
    }

    // If the match is 'final', stop immediately after it.
    if (final) {
      break;
    }
  }

  // Append matched fallbacks if nothing matched.
  if (matchedElements.length === 0) {
    matchedElements.push(...matchedFallbackElements);
  }

  // Results wrapper will return with 0+ results.
  if (resultsWrapper != null) {
    return React.cloneElement(resultsWrapper, undefined, matchedElements);
  }

  // Empty results without a wrapper returns nothing.
  if (matchedElements.length === 0) {
    return null;
  }

  // Return single result or array of results.
  return unwrapSingle(matchedElements);
}
