import React from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Stack, DefaultEffects } from '@fluentui/react';
import { mergeStyles } from '@fluentui/merge-styles';
import countBy from 'lodash/countBy';

import NavBarButton, { NavBarButtonProps } from '../NavBarButton/NavBarButton';
import PlayerPersonaLive from '../PlayerPersona/PlayerPersonaLive';
import { NavBarButtonConfigElement, navBarButtonConfig } from '../../../static/nav-bar-buttons';
import { selectCurrentLead } from '../../../store/game-slice';
import {
  selectAllJournalEntities,
  selectAllJournalEntryPropsGrouped,
  setEntriesUnread as JournalSetEntries,
} from '../../../store/journal-slice';
import { useAppSelector } from '../../../store';
import { hasDebugFlag, isDev, noOp } from '../../../lib/util';
import { actions as sessionActions } from '../../../store/session-slice';
import { getCurrentGameTheme } from '../../../theme';
import GetLocaleRouter from '../LocaleRouter/LocaleRouter';
import { selectAllNotebookEntities, setEntriesUnread as NotebookSetEntries } from '../../../store/notebook-slice';
import { selectCurrentGame } from '../../../store/multiplayer-slice';

interface NavBarButtons {
  top: NavBarButtonProps[];
  bottom: NavBarButtonProps[];
}

const NavBar: React.FC = () => {
  const currentGameLocale = GetLocaleRouter();
  const { t } = useTranslation(currentGameLocale, { keyPrefix: 'navBar' });
  const currentLead = useAppSelector(selectCurrentLead);
  const currentGame = useAppSelector(selectCurrentGame);
  // Journal Entries used to calculate unread count on button.
  const journalEntities = useAppSelector(({ journal }) => {
    // Journal debug can see all journal entries, so use all of them.
    if (isDev && hasDebugFlag('journal')) {
      return selectAllJournalEntities(journal.entries);
    }

    // Normally the user can only see current lead entries, so only use those.
    if (currentLead != null) {
      return (
        selectAllJournalEntryPropsGrouped(journal.entries)
          .get(currentLead)
          ?.map(({ entity }) => entity) ?? []
      );
    }

    return [];
  }, shallowEqual);

  const notebookEntities = useAppSelector(({ notebook }) => {
    // Journal debug can see all journal entries, so use all of them.
    if (isDev && hasDebugFlag('notebook')) {
      return selectAllNotebookEntities(notebook.entries);
    }
    return [];
  }, shallowEqual);

  const socketStatus = useAppSelector((state) => state.multiplayer.status.status);
  const unreadMessages = useAppSelector((state) => state.session.unread);
  const sessionFacilitators = useAppSelector((state) => state.session.facilitators);
  const requestingHelp = useAppSelector((state) => state.user.requesting_help);
  const journalEntriesSeen = useAppSelector(({ journal }) => journal.entriesSeen);
  const notebookEntriesSeen = useAppSelector(({ notebook }) => notebook.entriesSeen);
  const dispatch = useDispatch();

  const journalUpdateEntriesUnread = React.useCallback(() => {
    if (journalEntriesSeen.length > 0) {
      dispatch(JournalSetEntries({ ids: journalEntriesSeen, unread: false }));
    }
  }, [dispatch, journalEntriesSeen]);

  const notebookUpdateEntriesUnread = React.useCallback(() => {
    if (notebookEntriesSeen.length > 0) {
      dispatch(NotebookSetEntries({ ids: notebookEntriesSeen, unread: false }));
    }
  }, [dispatch, notebookEntriesSeen]);

  const generateNavButtonProps = React.useCallback(
    (el: NavBarButtonConfigElement): NavBarButtonProps => {
      const { type, icon, activeIcon } = el;
      let disabled = false;
      let unread = -1;
      let unreadCallout = true;
      let onClickCallback = noOp;
      let secondaryIcon = '';
      switch (type) {
        case 'journal':
          unread = countBy(journalEntities, (e) => e?.unread).true || -1;
          onClickCallback = () => {
            journalUpdateEntriesUnread();
          };
          break;
        case 'notebook':
          unread = countBy(notebookEntities, (e) => e?.unread).true || -1;
          onClickCallback = () => {
            notebookUpdateEntriesUnread();
          };
          break;
        case 'chat':
          unread = unreadMessages > 0 ? unreadMessages : -1;
          unreadCallout = false;
          onClickCallback = () => {
            dispatch(sessionActions.resetUnread());
          };
          disabled = socketStatus === null && !hasDebugFlag('chat');
          if (requestingHelp) {
            secondaryIcon = 'hand';

            if (sessionFacilitators && sessionFacilitators.length) {
              secondaryIcon = 'facilitator';
            }
          }
          break;
        default:
          break;
      }
      return {
        title: t(`buttons.${type}.title`),
        label: t(`buttons.${type}.label`),
        panel: type,
        icon,
        unread,
        disabled,
        unreadCallout,
        onClickCallback,
        secondaryIcon,
        ...(activeIcon && { activeIcon }),
      };
    },
    [
      dispatch,
      journalEntities,
      journalUpdateEntriesUnread,
      notebookEntities,
      notebookUpdateEntriesUnread,
      requestingHelp,
      sessionFacilitators,
      socketStatus,
      t,
      unreadMessages,
    ],
  );

  const navBarButtons: NavBarButtons = React.useMemo(
    () => ({
      top: navBarButtonConfig(currentGame?.shortId).top.map((el) => generateNavButtonProps(el)),
      bottom: navBarButtonConfig(currentGame?.shortId).bottom.map((el) => generateNavButtonProps(el)),
    }),
    [currentGame?.shortId, generateNavButtonProps],
  );

  const theme = getCurrentGameTheme(currentGame?.shortId);

  const navBarStyles = mergeStyles({
    'position': 'relative',
    'background': theme.semanticColors.navbarBackground,
    'zIndex': 2,
    'boxShadow': DefaultEffects.elevation16,
    'height': '100%',
    'overflow': 'auto',
    // Accessibility: gradient bg becomes transparent in Windows' high contrast themes; need something opaque.
    '@media screen and (-ms-high-contrast: active), (forced-colors: active)': {
      background: 'Canvas',
    },
  });

  return (
    <Stack
      className={navBarStyles}
      role="complementary"
      aria-label={t('accessibility.regionLabel')}
      verticalAlign="space-between"
    >
      <Stack>
        <Stack.Item align="center" tokens={{ padding: '12px' }}>
          <PlayerPersonaLive size="large" hideName />
        </Stack.Item>
        {navBarButtons.top.map((buttonProps, i) => (
          <Stack.Item key={`top_nav_${i}`}>
            <NavBarButton {...buttonProps} />
          </Stack.Item>
        ))}
      </Stack>
      <Stack>
        {navBarButtons.bottom.map((buttonProps, i) => (
          <Stack.Item key={`bottom_nav_${i}`}>
            <NavBarButton {...buttonProps} />
          </Stack.Item>
        ))}
      </Stack>
    </Stack>
  );
};

export default NavBar;
