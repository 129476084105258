import React from 'react';
import { Handle, Position } from 'react-flow-renderer';
import { Stack, IStackItemStyles } from '@fluentui/react';

import { TFunction } from 'react-i18next';

import { ElementDataWire } from '../../../lib/game-data/element-data';
import { getPlayerColor } from '../../../static/player-avatars';
import { hasDebugFlag, isDev } from '../../../lib/util';

export interface IMapLinkNodeProps {
  data: MapLinkNodeData;
}

export interface MapLinkNodeData {
  element: ElementDataWire;
  onFocus?: () => void;
  t: TFunction;
}
const mapDebug: boolean = isDev && hasDebugFlag('map');
const nodeSize: IStackItemStyles = {
  root: {
    width: mapDebug ? '40px' : '1px',
    height: mapDebug ? '40px' : '1px',
    opacity: 1,
  },
};
const handleStyles = {
  'east': { opacity: mapDebug ? 1 : 0, right: '41%' },
  'south-east': { opacity: mapDebug ? 1 : 0, right: '41%' },
  'south': { opacity: mapDebug ? 1 : 0, right: '41%' },
  'south-west': { opacity: mapDebug ? 1 : 0, right: '41%' },
  'west': { opacity: mapDebug ? 1 : 0, right: '41%' },
  'north-west': { opacity: mapDebug ? 1 : 0, right: '41%' },
  'north': { opacity: mapDebug ? 1 : 0, right: '41%' },
  'north-east': { opacity: mapDebug ? 1 : 0, right: '41%' },
};
/**
 * Evidence Map React-Flow card component.
 */
const MapLinkNode: React.FC<IMapLinkNodeProps> = () => {
  const colorData = getPlayerColor('gray');

  return (
    <>
      <Stack
        styles={nodeSize}
      >
        <Stack.Item
          style={{ strokeLinecap: 'round', backgroundColor: colorData.background }}
          styles={nodeSize}
        >
          {'\u200B'}
        </Stack.Item>
        <Handle type="source" id="east" style={handleStyles.east} position={Position.Right} isConnectable={false} />
        <Handle type="source" id="south-east" style={handleStyles['south-east']} position={Position.Right} isConnectable={false} />
        <Handle type="source" id="south" style={handleStyles.south} position={Position.Right} isConnectable={false} />
        <Handle type="source" id="south-west" style={handleStyles['south-west']} position={Position.Right} isConnectable={false} />
        <Handle type="source" id="west" style={handleStyles.west} position={Position.Right} isConnectable={false} />
        <Handle type="source" id="north-west" style={handleStyles['north-west']} position={Position.Right} isConnectable={false} />
        <Handle type="source" id="north" style={handleStyles.north} position={Position.Right} isConnectable={false} />
        <Handle type="source" id="north-east" style={handleStyles['north-east']} position={Position.Right} isConnectable={false} />
      </Stack>
    </>
  );
};

export default MapLinkNode;
