import { DirectionalHint } from '@fluentui/react';
import { ITeachingBubbleStyles, TeachingBubble } from '@fluentui/react/lib/TeachingBubble';
import * as React from 'react';
import hintImage from '../../../static/images/Game01/Episode01/characters/andrea/hintImage.png';

interface Props {
  target: string;
  onDismiss?: () => void;
  direction?: DirectionalHint;
}

const hintStyles: Partial<ITeachingBubbleStyles> = {
  imageContent: {
    backgroundSize: 'cover',
  },
  content: {
    background: 'white',
    maxHeight: 88,
  },
  bodyContent: {
    padding: 10,
  },
  subText: {
    color: 'black',
    fontSize: 16,
  },
  subComponentStyles: {
    callout: {
      beak: [{ display: 'none' }],
    },
  },
};

export default function Hint({
  children,
  target,
  onDismiss,
  direction = DirectionalHint.topCenter,
}: React.PropsWithChildren<Props>): JSX.Element {
  return (
    <TeachingBubble
      styles={hintStyles}
      illustrationImage={{ src: hintImage, alt: '' }}
      calloutProps={{ directionalHint: direction }}
      target={target}
      onDismiss={onDismiss}
      isWide
      focusTrapZoneProps={{ disabled: true }}
    >
      {children}
    </TeachingBubble>
  );
}
