import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './static/locales/en/index';
import ja from './static/locales/ja/index';
import jaPartial from './static/locales/jaPartial/index';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en, ja, jaPartial,
};
i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en',
    fallbackNS: ['common'],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false, // react already safes from xss
      skipOnVariables: false, // default change to true in v21+
    },
    react: {
      // Must be set to enable multiple namespace fallbacks (i.e. `useTranslation(['roles', 'registerDemo'])`)
      // Otherwise, only the first namespace will ever be loaded into `t()`.
      nsMode: 'fallback',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['b', 'i', 'em', 'p', 'strong', 'br', 'code', 'pre', 'ul', 'li', 'ol'],
    },
  });

export default i18n;
