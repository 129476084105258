import tasks from "./tasks";
import entries from "./entries";
import tutorial from "./tutorial";
import common from "./common";

/**
 * Top-level i18next namespaces.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const en: any = {
  ...common,
  ...entries,
  ...tasks,
  ...tutorial,
};
export default en;
