/**
 * i18next NS: `Episode01.Conclusion`
 */
 export default {
  accuse: {
    title: "It's time to solve the case",
    body: "Who masterminded the attack?",
  },
  confirm: {
    title: "Accuse {{name}}",
    body: "I know I don't need to remind you how important this is. Are you absolutely sure you want to accuse {{name}}?",
    nextLabel: "Yes",
    backLabel: "No",
  },
  confirmHacker: {
    title: "Accuse External Hacker",
    body: "I know I don't need to remind you how important this is. Are you absolutely sure you want to accuse an external hacker?",
  },
  proof: {
    title: "How was the attack carried out?",
    body: "Discuss this with your team, then select all that apply.",
    options: {
      1: {
        label: "Quinn intentionally misconfigured the managed identity to allow the external hackers to get in.",
      },
      2: {
        label: "Frey configured the app registration in response to a spear-phishing email.",
      },
      3: {
        label: "Wenny granted access to BFYOVacationTool via a spear-phishing email.",
      },
      4: {
        label: "Alex sent an email on behalf of the hackers, asking Frey to configure the app registration.",
      },
    },
  },
  incorrect: {
    title: "You failed to find the culprit.",
    body: "CIO Andrea Divkovic entrusted you with this assignment but you didn't come through. Now she’s second guessing her decision to hire you at all! You'll need to work extra hard to gain back her trust.",
  },
  correct: {
    title: "Congratulations! You have successfully closed the ticket.",
    body: "The threat has been identified and the case closed before the hackers did any damage. CIO Andrea Divkovic is impressed with your sleuthing skills and is giving you your own office!",
  },
  partialCorrect: {
    title: "Good work, but questions remain…",
    body: "You managed to clear the internal suspects, but you missed crucials details needed to find the actual culprit. CIO Andrea Divkovic is satisfied with your performance but has advised you to be more rigorous in your investigations moving forward.",
  },
};
