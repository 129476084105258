import React from 'react';
import { DefaultButton, Icon, IStackItemStyles, IStackStyles, mergeStyles, Stack } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { P } from '../../Text';
import { UserInfo } from '../schemas';
import { noOp } from '../../../../lib/util';
import { getCurrentGameTheme } from '../../../../theme';

export interface IFacilitatorUserListItemProps {
  user: UserInfo;
  sessionName?: string;
  showDisconnect?: boolean;
  showLogout?: boolean;
  onDisconnect?: Function;
  onRelease?: Function;
  onLogout?: Function;
  onRemove?: Function;
  onResolveHelp: Function;
}

const theme = getCurrentGameTheme('Global');

function notificationStyles(status: any): IStackItemStyles {
  return {
    root: {
      backgroundColor: status === 'disconnected' || status === 'disabled' ? '#DADADA' : '#E6E6E6',
      padding: 8,
      borderBottom: '2px solid #d2d2d2',
      p: {
        opacity: status === 'disconnected' || status === 'disabled' ? 0.6 : 1,
      },
    },
  };
}
const color = '#243a5e';

const iconClass = mergeStyles({
  margin: '4px',
});

function requestingHelpStackStyles(requesting_help: boolean | undefined): IStackStyles {
  return {
    root: {
      opacity: requesting_help ? '1' : '0.2',
      pointerEvents: requesting_help ? 'initial' : 'none',
      color: requesting_help ? '#28a7e9' : 'inherit',
    },
  };
}

const FacilitatorUserListItem: React.FC<IFacilitatorUserListItemProps> = (
  {
    user,
    sessionName,
    onResolveHelp,
    onDisconnect = noOp,
    onRelease = noOp,
    onLogout = noOp,
    onRemove = noOp,
    showDisconnect = false,
    showLogout = false,
  },
) => {
  const { t } = useTranslation('common');
  const name = user.display_name ? user.display_name : user.username;
  return (
    <>
      <Stack.Item styles={notificationStyles(user.status)}>
        <Stack horizontal grow>
          <Stack.Item>
            <Stack tokens={{ padding: '1px 4px' }} styles={{ root: { minWidth: 30 } }}>
              <Stack.Item>
                <Stack horizontal styles={requestingHelpStackStyles(user.requesting_help)}>
                  <Stack.Item>
                    <Icon iconName="faHandPaper" className={iconClass} />
                  </Stack.Item>
                </Stack>
              </Stack.Item>
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <Stack tokens={{ padding: '4px 6px', childrenGap: 4 }}>
              <Stack.Item styles={{ root: { height: 28 } }}>
                <P level={3} color={color}><b>{name}</b></P>
              </Stack.Item>
              <Stack.Item>
                {sessionName && (<P level={3} color={color}><b>{sessionName}</b> | </P>)}
                {user.status === 'disabled' && (
                  <P level={3} color={color}>{t('facilitator.disabled')}</P>
                )}
                {user.status === 'disconnected' && (
                  <P level={3} color={color}>{t('facilitator.disconnected')}</P>
                )}
                {user.status === 'active' && (
                  <P level={3} color={color}>{user.location || t('facilitator.locationUnknown')}</P>
                )}
              </Stack.Item>
            </Stack>
          </Stack.Item>
          <Stack.Item grow>
            <Stack horizontal horizontalAlign="end" verticalAlign="start" tokens={{ padding: 4, childrenGap: 4 }} style={{ height: '100%' }}>
              {user.requesting_help && (
                <DefaultButton text={t('facilitator.resolve')} onClick={() => onResolveHelp(user)} style={{ padding: 5, borderWidth: 1, fontSize: 14 }} />
              )}
              {showDisconnect && user.status === 'active' && (
                <DefaultButton text={t('facilitator.disconnect')} onClick={() => onDisconnect()} style={{ padding: 5, borderWidth: 1, fontSize: 14 }} />
              )}
              {showDisconnect && user.status === 'disconnected' && (
                <DefaultButton text={t('facilitator.release')} onClick={() => onRelease()} style={{ padding: 5, borderWidth: 1, fontSize: 14 }} />
              )}
              {showLogout && (
                <DefaultButton text={t('facilitator.logout')} onClick={() => onLogout()} style={{ padding: 5, borderWidth: 1, fontSize: 14 }} />
              )}
              {showDisconnect && user.status !== 'disabled' && (
                <DefaultButton
                  text={t('facilitator.remove')}
                  style={{
                    padding: 5,
                    borderWidth: 1,
                    fontSize: 14,
                    color: theme.palette.red,
                    borderColor: theme.palette.red,
                  }}
                  onClick={() => onRemove()}
                />
              )}
            </Stack>
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </>
  );
};
export default FacilitatorUserListItem;
