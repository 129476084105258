import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { mergeStyles } from '@fluentui/merge-styles';
import { Layer } from '@fluentui/react';

import type { IButtonProps } from '@fluentui/react';
import type { TFunction } from 'react-i18next';

import { useGameServiceSelector } from '../../../state/GlobalStateProvider';

export interface IMapActivatorProps {
  t: TFunction;
  layerHostId?: string;
  onClick?: IButtonProps['onClick'];
}

const buttonStyles = mergeStyles(
  {
    pointerEvents: 'auto',
    position: 'fixed',
    left: '16px',
    bottom: '16px',
    zIndex: 5,
    appearance: 'none',
    cursor: 'pointer',
    padding: '8px',
    color: 'rgba(255, 255, 255, 0.8)',
    fontSize: '36px',
    border: 'none',
    backgroundColor: 'transparent',
    svg: {
      filter: 'drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.6))',
    },
  },
  {
    ':hover': {
      svg: {
        filter: 'drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.8))',
      },
    },
  },
  {
    '*': {
      pointerEvents: 'none',
    },
  },
);

/**
 * The activator button for the Evidence Map.
 */
const MapActivator: React.FC<IMapActivatorProps> = ({ layerHostId, onClick, t }) => {
  const isHidden = useGameServiceSelector((state) => state.matches('investigationPhase.review'));
  return (
    <Layer hostId={layerHostId} insertFirst>
      <button
        hidden={isHidden}
        aria-label={t('evidenceMap.toggleButton')}
        className={`map-activator ${buttonStyles}`}
        type="button"
        title={t('evidenceMap.toggleButton')}
        onClick={onClick}
      >
        <FontAwesomeIcon icon={faSearch} />
      </button>
    </Layer>
  );
};

export default MapActivator;
