import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/index';
import { gameData } from '../../static/game-data';
import { EvidenceDataCard, EvidenceDataClue } from '../game-data/evidence-data';
import { applyData, selectCurrentEpisode } from '../../store/multiplayer-slice';
import { AppliedCardEntity, AppliedClueEntity } from '../multiplayer/schemas';

export default function useApplyTutorialCards(forceApply = false, devCurrentEpisode?: string, forceReset?: boolean) {
  const dispatch = useAppDispatch();
  const didApplyCards = React.useRef(false);
  const currentEpisode = useAppSelector((state) => selectCurrentEpisode(state, devCurrentEpisode));
  // console.log('here is the episode', currentEpisode?.shortId);

  React.useEffect(() => {
    if (!currentEpisode) {
      return;
    }
    if (didApplyCards.current && !forceApply) {
      return;
    }
    const allEvidence = new Set<EvidenceDataCard | EvidenceDataClue>();

    [...currentEpisode.defaultcardsIDs, ...currentEpisode.defaultclueIDs].forEach((evidenceId) => {
      const evidence = gameData.find(evidenceId, 'evidence');

      if (evidence) {
        allEvidence.add(evidence);
      }
    });

    const entitiesToApply: (AppliedCardEntity | AppliedClueEntity)[] = [...allEvidence].map((evidence) => {
      const entity = {
        id: evidence.id,
        type: evidence.type,
        user: null,
        date: Date.now(),
        phase: null,
      };

      return evidence.isClue() ? { ...entity, facts: evidence.facts.map((fact) => fact.shortId) } : entity;
    });
    // reset Evidence map on dev test - remove for staging & prod
    // if (forceReset) {
    //   dispatch(resetApplyData());
    // }
    dispatch(applyData({ data: entitiesToApply, options: { isInitial: true } }));

    didApplyCards.current = true;
  }, [currentEpisode, dispatch, forceApply, forceReset]);
}
