import * as React from 'react';
import Screen from '../../../../../components/Global/Screen/Screen';
import background from '../images/04C.020.png';
import { ComponentProps } from '../../../../task-types';
import Interstitial from '../../../../../components/Global/Interactions/Interstitial/Interstitial';

export default function C020({ t, send }: ComponentProps): JSX.Element {
  return (
    <Screen>
      <Interstitial
        title={t('C020.title')}
        body={t('C020.body')}
        onClick={() => send('NEXT')}
        nextLabel={t('common.done')}
        backgroundImage={background}
      />
    </Screen>
  );
}
