import React from 'react';
import { useAppSelector } from '../../../store';
import { selectCurrentEpisode, selectCurrentGame } from '../../../store/multiplayer-slice';
import ConclusionG1E1 from '../../Game01/Conclusion/Episode01/Conclusion';
import ConclusionG1E2 from '../../Game01/Conclusion/Episode02/Conclusion';

export default function ConclusionRouter() {
  const currentGameId = useAppSelector((selectCurrentGame));
  const currentEpisodeId = useAppSelector((selectCurrentEpisode));
  return (
    <>
      {`${currentGameId?.shortId}${currentEpisodeId?.shortId}` === 'Game01Episode01' && <ConclusionG1E1 />}
      {`${currentGameId?.shortId}${currentEpisodeId?.shortId}` === 'Game01Episode02' && <ConclusionG1E2 />}
    </>
  );
}
