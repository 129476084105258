import React from 'react';

import { concatStyleSetsWithProps } from '@fluentui/merge-styles';

import type { IStyleFunctionOrObject, IStyleSet } from '@fluentui/merge-styles';

export const useCombinedStylesWithProps = <TStylesProps, TStyleSet extends IStyleSet<TStyleSet>>(
  ...styles: (IStyleFunctionOrObject<TStylesProps, TStyleSet> | undefined)[]
) =>
  React.useMemo(() => {
    if (styles.length > 1) {
      return (styleProps: TStylesProps) => concatStyleSetsWithProps(styleProps, ...styles);
    }
    return styles.find((value) => value != null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...styles]);
