import _pick from 'lodash/pick';
import { differenceInHours } from 'date-fns';

import { APIManager } from './APIManager';
import { checkEnvVar, devLog, getSubdomain, hasDebugFlag } from '../util';
import { clearLocalSession, setLocalSession, tryGetLocalSession } from '../../store/local-session';
import { persistor, store } from '../../store';
import { setSessionStartTime } from '../../store/game-slice';

import { InitAPIResponse, LocalSessionData, LocalSessionDataSchema, LoginResponse } from './types';
import type { LoadGateOnLoadCallback } from '../../components/Global/LoadGate/LoadGate';
import { setUserValues } from '../../store/user-slice';

/**
 * Initialize API config (with env vars and overrides).
 * Throws user-friendly error messages on failure.
 */
export const initAPIConfig = async () => {
  const url = process.env.REACT_APP_API_URL;
  if (!url) {
    throw new Error('API URL Missing');
  }

  const forceSubdomain = checkEnvVar(process.env.REACT_APP_FORCE_DOMAIN);
  const domain = forceSubdomain ? process.env.REACT_APP_FORCE_DOMAIN : getSubdomain();
  try {
    const apiConfig = await APIManager.config({
      url: process.env.REACT_APP_API_URL,
      domain,
    });
    return apiConfig;
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.error(e);
    throw new Error('Error initializing API.');
  }
};

/**
 * Register a new player/session, save to local storage, and start persistence.
 */
export async function registerNewSession() {
  clearLocalSession({ persist: true });

  const { username, key, event_slug } = APIManager.getConfig();

  try {
    const sessionData: LocalSessionData = LocalSessionDataSchema.parse({
      username,
      key,
      event_slug,
      sessionStartTime: Date.now(),
    });

    setLocalSession(sessionData);
    persistor.persist();
    return sessionData;
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.error(e);
    throw new Error('Valid player or session data could not be parsed from responses.');
  }
}

/**
 * Initialize API and get game data
 */
export const initAPIAndGetData = async (event_slug?: string, username?: string, isDevLoadGate?: boolean) => {
  const response: InitAPIResponse = {
    status: 'pending',
    messages: [],
    session: null,
    sessionIsValid: false,
    apiConfig: null,
    event_slug: null,
  };

  // Initial API configuration (no session)
  try {
    response.apiConfig = await initAPIConfig();
  } catch (e: any) {
    response.messages.push(String(e));
    response.status = 'error';
    return response;
  }

  // check if we have the event_slug and username necessary to proceed
  const missingParams = (!event_slug || !username) && !isDevLoadGate;
  if (missingParams) {
    response.messages.push('Error: URL missing required params.');
    response.status = 'error';
    return response;
  }

  // Check if local session exists and is under 24 hours old.
  const localSession = tryGetLocalSession();
  devLog('the local session is : ', localSession);
  if (localSession) {
    devLog(
      differenceInHours(Date.now(), localSession.sessionStartTime) >= 24,
      localSession.event_slug !== event_slug,
      localSession.username !== username,
      isDevLoadGate);
  }

  if (localSession == null ||
    differenceInHours(Date.now(), localSession.sessionStartTime) >= 24 ||
    localSession.event_slug !== event_slug ||
    localSession.username !== username ||
    isDevLoadGate) {
    try {
      if (!isDevLoadGate) {
        const { data } = await APIManager.loginUserToEvent({ event_slug: event_slug || '', user_id: username || '' });

        const loginResponse = LoginResponse.safeParse(data);
        if (!loginResponse.success) {
          throw new Error('Error in Login response.');
        }

        // check if the user already logged-in
        const { allowed } = loginResponse.data;
        if (!allowed && !hasDebugFlag('loadgate')) {
          throw new Error('The user is already logged in.');
        }
      }
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error(e);
      response.messages.push('Could not login the player to the event.');
      response.messages.push(String(e));
      response.status = 'error';
      return response;
    }

    // configuring APIManager values since login was successful
    APIManager.config({ event_slug, username: isDevLoadGate ? 'devLoadGate' : username }, true);
    if (!isDevLoadGate) {
      // save the user key in the localSession
      response.session = await registerNewSession();
    }
    response.sessionIsValid = false;
    response.status = 'done';
    return response;
  }

  try {
    // Load local session info into APIManager
    response.session = localSession;
    response.apiConfig = await APIManager.config(_pick(response.session, 'username', 'key', 'event_slug'));
    response.sessionIsValid = true;

    response.status = 'done';
    return response;
  } catch (e: any) {
    response.messages.push(String(e));
    response.status = 'error';
    return response;
  }
};

/**
 * Initialize API, get game data, and try to validate/reload an existing session.
 * Designed to be passed into a `LoadGate.onLoad` prop.
 */
export const initAPIAndTryReload: LoadGateOnLoadCallback<
  { event_slug?: string, username?: string, isDevLoadGate?: boolean },
  { socketCallback: Function }> = async (
  { done, setMessages, onLoadParams = {}, onLoadCallbacks = {} }) => {
  const { event_slug, username, isDevLoadGate } = onLoadParams;
  const { socketCallback } = onLoadCallbacks;
  // Initialize API, load game and attempt to validate session.
  const data = await initAPIAndGetData(event_slug, username, isDevLoadGate);

  // Update messages in LoadGate, if there are any (like errors).
  if (data.messages.length > 0) {
    setMessages(data.messages);
  }

  // Bail out on any fatal error.
  if (data.status === 'error') {
    return;
  }

  // Runs after loading is done, triggers transition effect.
  const postLoad = () => {
    devLog('postLoad');
    store.dispatch(setUserValues({ username: isDevLoadGate ? 'devLoadGate' : username }));
    if (socketCallback !== undefined) {
      socketCallback({ done, setMessages });
    } else {
      done();
    }
  };

  // IF saved session is VALID:
  //   - Start persistor.
  //   - Refresh certain data in Redux store when persistor is loaded.
  //   - Call postLoad().
  if (data.sessionIsValid && data.session != null) {
    devLog('initAPIAndTryReload - reloading session');
    const { sessionStartTime } = data.session;
    // Handle persistor load event.
    const handlePersistor = () => {
      if (persistor.getState().bootstrapped) {
        // Run only once.
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        unsubscribe();

        // Reset sessionStartTime and session_slug in redux based on saved session.
        if (sessionStartTime != null) {
          store.dispatch(setSessionStartTime(sessionStartTime));
        }

        devLog('initAPIAndTryReload - session reloaded');
        // Finish loading when persistor is set up.
        postLoad();
      }
    };
    const unsubscribe = persistor.subscribe(handlePersistor);
    // Start persistor and check immediately.
    persistor.persist();
    handlePersistor();

    // End execution. State should be restored.
    return;
  }

  // IF saved session is INVALID:
  //   - Ensure persistor is paused.
  //   - Clear saved session and data.
  //   - Call postLoad().
  // (On registration, persistence will be re-enabled.)
  if (isDevLoadGate) {
    // clearing the session for non dev enviroments happens in the registerNewSession function
    clearLocalSession({ persist: true });
  }
  devLog('initAPIAndTryReload - new session');
  if (isDevLoadGate) {
    clearLocalSession({ persist: true });
  }
  postLoad();
};

/**
 * Get facilitator data from API (with env vars and overrides)
 * Throws user-friendly error messages on failure.
 */
 const registerFacilitator = async (facilitator_slug: string) => {
  try {
    const res = await APIManager.getFacilitator(facilitator_slug);
    return res.data;
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.error(e);
    throw new Error('Could not get facilitator data.');
  }
};
/**
 * Initialize API and get game data
 */
export const initAPIAndGetFacilitator = async (facilitator_slug: string | undefined) => {
  const response: InitAPIResponse = {
    status: 'pending',
    messages: [],
    session: null,
    sessionIsValid: false,
    apiConfig: null,
    event_slug: null,
  };

  // check if we have the facilitator_slug necessary to proceed
  const missingParams = !facilitator_slug;
  if (missingParams) {
    response.messages.push('Error: URL missing required params.');
    response.status = 'error';
    return response;
  }

  // Initial API configuration (no session)
  try {
    response.apiConfig = await initAPIConfig();
  } catch (e: any) {
    response.messages.push(String(e));
    response.status = 'error';
    return response;
  }

  // Initial load game data.
  try {
    await registerFacilitator(facilitator_slug);
  } catch (e: any) {
    response.messages.push(String(e));
    response.status = 'error';
    return response;
  }

  response.status = 'done';
    return response;
};

/**
 * Initialize facilitator API, get game data, and try to validate/reload an existing session.
 * Designed to be passed into a `LoadGate.onLoad` prop.
 */
export const initFacilitatorAPI: LoadGateOnLoadCallback<{ facilitator_slug: string }>
  = async ({ done, setMessages, onLoadParams = {} }) => {
    const { facilitator_slug } = onLoadParams;

    // Initialize API, load game and attempt to validate session.
    const data = await initAPIAndGetFacilitator(facilitator_slug);

    // Update messages in LoadGate, if there are any (like errors).
    if (data.messages.length > 0) {
      setMessages(data.messages);
    }

    // Bail out on any fatal error.
    if (data.status === 'error') {
      return;
    }

    // Runs after loading is done, triggers transition effect.
    const postLoad = () => {
      devLog('postLoad');
      done();
    };

    postLoad();
  };
