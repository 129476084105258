import type { LocaleContent, CardOrEntryLocaleContent } from "../../types";

/**
 * i18Next `entries:Episode02.Task01` lead text content
 */
const Task01Lead: LocaleContent = {
  header: "Review Alert in Microsoft Defender for Cloud",
  role: "Security Operations Analyst",
  time: "5m",
  brief: "We need to learn as much as we can about this incident. Go into Microsoft Defender for Cloud and look at the alert that just occurred. What information can you discover?<br><br>-Andrea",
};

/**
 * i18Next `entries:Episode02.Task01` entry content
 */
const Task01Entries: CardOrEntryLocaleContent = {
  SecurityAlertSummaryDetails: {
    heading: "Security Alert Summary Details",
    Severity: "Severity: Medium",
    Title: "Title: Suspicious download using Certutil detected",
    AffectedResource: "Affected Resource: ProjectD-BFYO",
    Time: "Time: 11/21/21, 03:23 PM",
    Tactics: "Tactics: Execution",
    Status: "Status: Active",
  },
  SecurityAlertFullDetails: {
    heading: "Security Alert Full Details",
    CompromisedHost: "Compromised Host: ProjectD-BFYO",
    UserName: "User Name: IIS APPPOOL\\ProjectD-BFYO",
    AccountSessionID: "Account Session ID: 0x37fd365",
    SuspiciousProcess: "Suspicious Process: c:\\windows\\syswow64\\certutil.exe",
    Commandline: "Suspicious Command Line: certutil -urlcache -split -f, https://download.sysinternals.com/files/PSTools.zip pstools.zip",
    ParentProcess: "Parent Process: c:\\windows\\syswow64\\cmd.exe",
    ProcessID: "Suspicious Process ID: 0x3cc",
    DetectedBy: "Detected By: Microsoft",
    File1: "File: cmd.exe",
    File2: "File: certutil.exe",
    Process1ID: "Process ID: 0x255c",
    Process2ID: "Process ID: 0x3cc",
  },
  RecommendedActions: {
    heading: "Recommended Actions",
    Recommendation1: "Recommendation: FTPS should be required in web apps",
    Recommendation2: "Recommendation: TLS should be updated to the latest version for web apps",
    Recommendation3: "Recommendation: Web Application should only be accessible over HTTPS",
    Recommendation4: "Recommendation: Managed identity should be used in web apps",
    Recommendation5: "Recommendation: Diagnostic logs in App Service should be enabled",
    Recommendation6: "Recommendation: Web apps should request an SSL certificate for all incoming requests",
    Recommendation7: "Recommendation: CORS should not allow every resource to access Web Applications",
    Recommendation8: "Recommendation: Remote debugging should be turned off for Web Applications",
  },
};

export default {
  ...Task01Lead,
  ...Task01Entries,
};
