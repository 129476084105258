import React from 'react';
import ReactIs from 'react-is';

import Game01Episode01Phase01 from './Game01Episode01Phase01';
import Game01Episode01Phase02 from './Game01Episode01Phase02';
import Game01Episode01Phase03 from './Game01Episode01Phase03';
import Game01Episode02Phase01 from './Game01Episode02Phase01';
import Game01Episode02Phase02 from './Game01Episode02Phase02';
import Game01Episode02Phase03 from './Game01Episode02Phase03';
import Game01Episode02Phase04 from './Game01Episode02Phase04';
import Game01DevEvidence from './Game01DevEvidence';
import Game01Episode01Conclusion from './Game01Episode01Conclusion';
import Game01Episode02Conclusion from './Game01Episode02Conclusion';
import Game01Episode01Tutorial from './Game01Episode01Tutorial';
import Game01Episode02Tutorial from './Game01Episode02Tutorial';
import Game01Summary from './Game01Summary';
import Game02NarrativeBreak from './Game02NarrativeBreak';

import type { INavLinkWithComponent } from '../lib/types';
import InsightEngineTest from './InsightEngineTest';
import Game02DevEvidenceTest from './Game02DevEvidence copy';

// Compile object of test components, keyed by name.
const tests: Record<string, React.FC> = {
  Game01Episode01Phase01,
  Game01Episode01Phase02,
  Game01Episode01Phase03,
  Game01Episode02Phase01,
  Game01Episode02Phase02,
  Game01Episode02Phase03,
  Game01Episode02Phase04,
  Game01DevEvidence,
  Game01Episode01Conclusion,
  Game01Episode02Conclusion,
  Game01Episode01Tutorial,
  Game01Episode02Tutorial,
  InsightEngineTest,
  Game01Summary,
  Game02NarrativeBreak,
  Game02DevEvidenceTest,
};

export const testLinks: INavLinkWithComponent[] = [];

// Iterate over all tests
Object.entries(tests).forEach(([componentName, Component]) => {
  if (Component == null || !ReactIs.isValidElementType(Component)) {
    return;
  }
  // Generate Route and navigation link entries.
  const path = `/tests/${componentName}`;
  testLinks.push({
    name: componentName,
    url: path,
    Component,
  });
});

// Freeze exports.
Object.freeze(testLinks);
