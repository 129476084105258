import { io } from 'socket.io-client';
import * as socketIOParser from 'socket.io-parser';
import { batch } from 'react-redux';

import type { ManagerOptions, SocketOptions } from 'socket.io-client';
import { EventNames, EventParams } from '@socket.io/component-emitter';

import {
  processEventArgs,
  ClientEventRoomStatus,
  ClientEventUserStatus,
  ClientEventStatus,
  ClientEventGameUpdate,
  ClientEventUserAuth,
  SessionGroupInfo,
  ClientChatMessage,
  UserInfo,
  ClientErrorEvent,
  ClientEventStartLead,
  ClientEventAbandonLead,
  ClientEventAbandonAll,
  ClientEventUpdateScores,
  ClientEventUpdateConsensus,
  ClientEventCompletedLead,
} from './schemas';
import {
  actions as rdxMPActions,
  emitGetGameUpdate,
  handleNewConsensus,
  handleRemoveUserFromConsensus,
} from '../../store/multiplayer-slice';
import { isDev } from '../util';

import type { CoerceNever, Last, SafeReturnType } from '../types';
import type { IOSocket } from './types';
import type { AppDispatch, StoreType } from '../../store';
import type { ClientEmitEventsMap } from './typed-events';
import type { LocalSessionType } from '../../store/local-session';
import {
  AppliedDataEntity,
} from '../multiplayer/schemas';
import { actions } from '../../store/user-slice';
import { setEventInfo } from '../../store/game-slice';
import { actions as sessionActions } from '../../store/session-slice';
import { errorCodes } from '../../static/error-codes';
import { APIManager } from '../api/APIManager';

type IOOptions = Partial<ManagerOptions & SocketOptions>;

/**
 * Multiplayer manager singleton.
 *
 * Handles socket.io connections and dispatching events to Redux.
 */
/* eslint-disable class-methods-use-this, no-use-before-define */
export class MPManager {
  static get instance(): MPManager {
    if (MPManager.#instance == null) {
      MPManager.#instance = new MPManager();
    }
    return MPManager.#instance;
  }

  /** Keep the single instance inside a private field */
  static #instance: MPManager;

  /** Forced socket options */
  readonly #forcedSocketOptions: IOOptions = Object.freeze({
    transports: ['websocket'],
  });

  /**
   * Default socket options.
   *
   * Unless otherwise noted, these are the normal Socket.IO defaults.
   */
  readonly #defaultSocketOptions: IOOptions = Object.freeze({
    forceNew: false,
    multiplex: true,
    transports: ['polling', 'websockets'], // Overridden by #forceSocketOptions
    upgrade: true,
    rememberUpgrade: false,
    path: '/socket.io/',
    query: {},
    extraHeaders: {},
    withCredentials: false,
    forceBase64: false,
    timestampRequests: true,
    timestampParam: 't',
    closeOnBeforeunload: false,
    reconnection: true,
    reconnectionAttempts: Infinity,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    randomizationFactor: 0.5,
    timeout: 20000,
    autoConnect: false, // Changed. Normally `true`.
    parser: socketIOParser,
    auth: {},
  });

  /** Constant dummy socket Identifier & URL */
  readonly #dummySocketID = 'invalid';

  /** Current socket instance */
  get socket() {
    return this.#socket;
  }

  get authCallback() {
    return this.#authenticateCallback;
  }

  /** Current socket `emit()` method. */
  get emit() {
    // Need to use bound private variable or it doesn't work.
    return this.#socketEmit;
  }

  /** Current socket ID */
  get socketID() {
    return this.#socket.id;
  }

  /** Store dispatch() method. */
  get dispatch() {
    return this.#storeDispatch;
  }

  /** Store getState() method. */
  get getState() {
    return this.#storeGetState;
  }

  /** XState changeState() method. */
  get gameState() {
    return this.#gameStateChange;
  }

  /** XState send() method. */
  get gameStateSend() {
    return this.#gameStateSend;
  }

  /** (Private) URI passed in to the socket instance */
  #socketURI: string = this.#dummySocketID;

  /** (Private) Socket instance. */
  #socket: IOSocket = this.#createDummySocket();

  /** (Private) Socket instance bound `emit()`. */
  #socketEmit = this.#socket.emit.bind(this.#socket);

  /** (Private) Redux store intance. */
  #store: StoreType | null = null;

  /** (Private) Store instance bound `dispatch()`. */
  #storeDispatch = this.#dummyDispatch as AppDispatch;

  /** (Private) Store instance bound `getState()`. */
  #storeGetState = this.#dummyGetState as StoreType['getState'];

  /** (Private) game state service intance. */
  #gameStateService: any | null = null;

  /** (Private) game state change method. */
  #gameStateChange = this.#dummyGameSateChange as any;

  /** (Private) game state change method. */
  #gameStateSend = this.#dummyGameSateSend as any;

  /** (Private) Callback after auth event   */
  #authenticateCallback = this.#dummyAuthCallback as Function;

  /** (Private) local session actions  */
  #localSession: LocalSessionType | null = null;

  #clearLocalSession = this.#dummyClearLocalSession as LocalSessionType['clearLocalSession'];

  /** Singleton is instantiated by `MPManager.instance` getter */
  private constructor() {
    if (
      process.env.REACT_APP_SOCKET_URL != null &&
      process.env.REACT_APP_FORCE_SLUG != null &&
      process.env.REACT_APP_SOCKET_AUTH_USERNAME != null &&
      process.env.REACT_APP_SOCKET_AUTH_KEY != null
    ) {
      this.#socket = this.setupSocket(process.env.REACT_APP_SOCKET_URL, {
        auth: {
          slug: process.env.REACT_APP_FORCE_SLUG,
          username: process.env.REACT_APP_SOCKET_AUTH_USERNAME,
          key: process.env.REACT_APP_SOCKET_AUTH_KEY,
        },
      });
      this.#socket.connect();
    }
  }

  /**
   * Promisified socket `emit()` that wraps event callbacks.
   */
  emitPromise<
    Ev extends EventNames<ClientEmitEventsMap>,
    Output extends CoerceNever<SafeReturnType<Last<EventParams<ClientEmitEventsMap, Ev>>>, void>,
  >(ev: Ev, ...args: EventParams<ClientEmitEventsMap, Ev>): Promise<Output> {
    const socket = this.#socket;
    return new Promise((resolve, reject) => {
      // If the last event parameter is a callback, replace it with a wrapped version
      // that resolves or rejects when it completes
      const callback = args[args.length - 1];
      if (typeof callback === 'function') {
        const newArgs: EventParams<ClientEmitEventsMap, Ev> = [...args];
        const wrappedCallback = async (...cbArgs: any) => {
          try {
            // It's possible that the callback is async or returns a promise.
            const result: Output = await callback(...cbArgs);
            resolve(result);
          } catch (e: any) {
            reject(e);
          }
        };
        newArgs[newArgs.length - 1] = wrappedCallback;
        socket.emit(ev, ...newArgs);
        return;
      }

      // Otherwise, just emit and resolve unless any errors occure.
      try {
        socket.emit(ev, ...args);
        resolve(undefined as Output);
      } catch (e: any) {
        reject(e);
      }
    });
  }

  /**
   * Inject a Redux store into the MP Manager.
   */
  injectStore(store: StoreType) {
    this.#store = store;
    this.#storeDispatch = this.#store.dispatch.bind(this.#store);
    this.#storeGetState = this.#store.getState.bind(this.#store);
    return this.#store;
  }

  /**
   * Inject the gameState service into the MP Manager.
   */
  injectGameStateService(gameStateService: any) {
    this.#gameStateService = gameStateService;
    this.#gameStateChange = this.#gameStateService.changeState.bind(this.#gameStateService);
    this.#gameStateSend = this.#gameStateService.send.bind(this.#gameStateService);
    return this.#gameStateService;
  }

  /**
   * Inject the local-session into the MP Manager
   */
  injectLocalSession(localSession: LocalSessionType) {
    this.#localSession = localSession;
    this.#clearLocalSession = this.#localSession.clearLocalSession.bind(this.#localSession);
    return this.#localSession;
  }

  /**
   * Set up a new socket
   */
  setupSocket(uri: string = this.#socketURI, options: Partial<ManagerOptions & SocketOptions> = {}) {
    // Return the existing socket if the URI is the same.
    if (uri === this.#socketURI) {
      return this.#socket;
    }

    // Otherwise, shut down the socket and remove all listeners.
    // NOTE: We skip generating the new dummy socket here because we'll immediately replace it
    this.teardownSocket(false);

    this.#devLog('Creating socket to', uri);
    this.#socket = io(uri, {
      ...this.#defaultSocketOptions,
      ...options,
      ...this.#forcedSocketOptions,
    });
    this.#socketEmit = this.#socket.emit.bind(this.#socket);
    this.#socket.id = ''; // Initial value is `undefined` despite TS type.
    this.#socketURI = uri;
    return this.#socket;
  }

  setupAndConnectSocket(
    uri: string = this.#socketURI,
    options: Partial<ManagerOptions & SocketOptions> = {},
    onConnectCallback: Function,
  ) {
    this.setupSocket(uri, options);
    this.#socket.connect();
    this.#registerSocketHandlers();
    this.#onceConnect(onConnectCallback);
  }

  /**
   * Tear down the existing socket instance.
   * Optionally replace it with a new dummy instance.
   */
  teardownSocket(generateNewDummySocket = true) {
    if (this.#socket.connected) {
      this.#socket.disconnect();
    }
    this.#socket.removeAllListeners();
    this.#socket.id = undefined as any;
    this.#socketURI = '';

    // Optionally replace the old socket instance with a dummy.
    if (generateNewDummySocket) {
      const dummySocket = this.#createDummySocket();
      this.#socket = dummySocket;
      this.#socketEmit = this.#socket.emit.bind(this.#socket);
    }
    return this.#socket;
  }

  #onceConnect(authenticateCallback: Function) {
    const socket = this.#socket;
    this.#authenticateCallback = authenticateCallback;
    socket.once('connect', () => {});
  }

  #registerSocketHandlers() {
    const socket = this.#socket;

    // Register Socket reserved handlers
    socket.on('connect', () => {
      this.#devLog('Socket connected.');
      this.dispatch(rdxMPActions.updateUserStatus({ status: 'connected' }));
    });

    socket.io.on('reconnect', () => {
      this.#devLog('Socket re-connected.');
      const { location } = this.getState().user;
      this.dispatch(rdxMPActions.emitUserUpdate({ location }));
    });

    socket.on('connect_error', (error) => {
      this.#devLog('Socket connection error:', error.name);
      this.dispatch(rdxMPActions.updateUserStatus({ status: null }));
    });

    // handling errors coming from server. instead of using connect_error use error event which has data regarding the error
    socket.on('error', (...eventArgs) => {
      const [, args] = processEventArgs(eventArgs, ClientErrorEvent);
      if (!args.success) {
        this.#errLog(`ClientErrorEvent validation error: ${args.error.message}`);
        return;
      }

      const { code, message } = args.data;

      switch (code) {
        case errorCodes.user['invalid-key']: // invalid key error
          this.#clearLocalSession({ full: true });
          this.authCallback(message);
          break;
        default:
          break;
      }
      this.teardownSocket(true);
    });

    socket.on('disconnect', (reason: string) => {
      this.#devLog('Socket disconnected:', reason);
      this.dispatch(rdxMPActions.updateUserStatus({ status: null }));
    });

    // authentication result after handshake
    socket.on('auth:user', (...eventArgs) => {
      const [, args] = processEventArgs(eventArgs, ClientEventUserAuth);
      if (!args.success) {
        this.#errLog(`ClientEventUserAuth validation error: ${args.error.message}`);
        return;
      }

      const { status } = args.data.event;
      const { session_slug, need_for_update } = args.data.user;
      batch(() => {
        this.gameState(status);
        this.dispatch(setEventInfo(args.data.event));
        if (session_slug) {
          this.dispatch(actions.setUserValues({ session_slug }));
        }
        if (need_for_update) {
          this.dispatch(emitGetGameUpdate());
        }
      });
      this.authCallback();
    });

    // Register socket listen event handlers
    socket.on('status:room', (...eventArgs) => {
      const [, args] = processEventArgs(eventArgs, ClientEventRoomStatus);
      if (!args.success) {
        this.#errLog('ClientEventRoomStatus Validation error:', args.error.message);
        return;
      }
      this.#devLog('Room status update:', args.data);
    });

    socket.on('status:user', (...eventArgs) => {
      const [, args] = processEventArgs(eventArgs, ClientEventUserStatus);
      if (!args.success) {
        this.#errLog('ClientEventUserStatus Validation error:', args.error.message);
        return;
      }
      this.dispatch(rdxMPActions.updateUserStatus(args.data));
    });

    socket.on('status:event', (...eventArgs) => {
      const [, args] = processEventArgs(eventArgs, ClientEventStatus);
      if (!args.success) {
        this.#errLog('ClientEventStatus Validation error:', args.error.message);
        return;
      }

      this.#devLog('Event status update:', args.data);

      const { action, room, need_for_update } = args.data;
      batch(() => {
        this.gameState(action);
        this.dispatch(actions.setUserValues({ session_slug: room || undefined }));
        this.dispatch(rdxMPActions.emitUserUpdate({
          ...(action === 'start' && { start_time: new Date() }),
          ...(action === 'end' && { end_time: new Date() }),
        }));
        if (action === 'start' && need_for_update) {
          this.dispatch(emitGetGameUpdate());
        }
      });
    });

    socket.on('data:user', (...eventArgs) => {
      const [, args] = processEventArgs(eventArgs, UserInfo);
      if (!args.success) {
        this.#errLog('User data Validation error:', args.error.message);
        return;
      }

      this.#devLog('User data update:', args.data);

      this.dispatch(actions.setUserValues(args.data));
    });

    socket.on('data:session', (...eventArgs) => {
      const [, args] = processEventArgs(eventArgs, SessionGroupInfo);
      if (!args.success) {
        this.#errLog('SessionGroupInfo Validation error:', args.error.message);
        return;
      }

      this.#devLog('Session data update:', args.data);

      this.dispatch(sessionActions.setSession(args.data));
    });

    socket.on('chat', (...eventArgs) => {
      const [, args] = processEventArgs(eventArgs, ClientChatMessage);
      if (!args.success) {
        this.#errLog('ClientChatMessage Validation error:', args.error.message);
        return;
      }

      this.#devLog('Chat message received:', args.data);

      this.dispatch(sessionActions.addMessages(args.data));

      if (this.getState().game.activePanel === 'chat') {
        this.dispatch(sessionActions.resetUnread());
      }
    });

    socket.on('update:game', (...eventArgs) => {
      const [, args] = processEventArgs(eventArgs, ClientEventGameUpdate);
      if (!args.success) {
        this.#errLog('ClientEventGameUpdate Validation error:', args.error.message);
        return;
      }
      const entities = args.data.applied_entities;
      this.dispatch(rdxMPActions.applyData({
        data: entities as AppliedDataEntity[],
        options: { fromSocket: true },
      }));
    });

    socket.on('start:update:game', (...eventArgs) => {
      const [, args] = processEventArgs(eventArgs, ClientEventStartLead);
      if (!args.success) {
        this.#errLog('ClientEventStartLead Validation error:', args.error.message);
        return;
      }
      const leadInfo = args.data;
      if (leadInfo) this.dispatch(rdxMPActions.updateLeadsInfo(leadInfo));
    });

    socket.on('abandon:update:game', (...eventArgs) => {
      const [, args] = processEventArgs(eventArgs, ClientEventAbandonLead);
      if (!args.success) {
        this.#errLog('ClientEventStartLead Validation error:', args.error.message);
        return;
      }

      if (args.data.ownerId) {
        this.dispatch(rdxMPActions.abandonMultipleLeads(args.data.ownerId));
      }
    });

    socket.on('complete:update:game', (...eventArgs) => {
      const [, args] = processEventArgs(eventArgs, ClientEventCompletedLead);
      if (!args.success) {
        this.#errLog('ClientEventStartLead Validation error:', args.error.message);
        return;
      }
      const { leadId } = args.data;
      if (leadId) this.dispatch(rdxMPActions.completeLead(leadId));
    });

    socket.on('abandonAll:update:game', (...eventArgs) => {
      const [, args] = processEventArgs(eventArgs, ClientEventAbandonAll);
      if (!args.success) {
        this.#errLog('ClientEventStartLead Validation error:', args.error.message);
        return;
      }

      const { username } = APIManager.getConfig();

      if (args.data.username) {
        this.dispatch(rdxMPActions.abandonMultipleLeads(args.data.username));
      }

      // abandon all the consensuses that this user is part of
      handleRemoveUserFromConsensus(this, args.data.username);

      if (args.data.username === username) {
        this.gameStateSend({ type: 'DONE.SUSPENSION' });
      }
    });

    socket.on('consensus:update:game', (...eventArgs) => {
      const [, args] = processEventArgs(eventArgs, ClientEventUpdateConsensus);
      if (!args.success) {
        this.#errLog('ClientEventStartLead Validation error:', args.error.message);
        return;
      }
      const { data } = args;
      handleNewConsensus(this, data.vote, data.activeUsers);
    });

    socket.on('kick:user', () => {
      this.#clearLocalSession({ full: true });
      this.gameStateSend({ type: 'DONE.SUSPENSION' });
    });

    // listen for score update
    socket.on('score:update:game', (...eventArgs) => {
      const [, args] = processEventArgs(eventArgs, ClientEventUpdateScores);
      if (!args.success) {
        this.#errLog('ClientEventStartLead Validation error:', args.error.message);
        return;
      }

      this.dispatch(rdxMPActions.setScore(args.data));
    });

    // Register catch-all debug listener
    if (isDev) {
      socket.onAny((eventName: string, ...args: any[]) => {
        this.#devLog(`Socket event received ('${eventName}')`, ...args);
      });
    }

    return socket;
  }

  #devLog(...args: any[]) {
    if (!isDev) {
      return;
    }
    // eslint-disable-next-line no-console
    console.log(`[MPManager][#${this.socket.id ?? ''}]`, ...args, { context: this });
  }

  #errLog(...args: any[]) {
    if (!isDev) {
      return;
    }
    // eslint-disable-next-line no-console
    console.error(`[MPManager][#${this.socket.id ?? ''}]`, ...args, { context: this });
  }

  /** Return a dummy socket */
  #createDummySocket(): IOSocket {
    const newSocket = io('dummy.invalid', { autoConnect: false, ...this.#forcedSocketOptions });
    newSocket.id = this.#dummySocketID;
    return newSocket;
  }

  /** Dummy `dispatch()` function for when the store is unset */
  #dummyDispatch() {
    throw new Error('Tried to `dispatch()` with undefined store.');
  }

  /** Dummy `getState()` function for when the store is unset */
  #dummyGetState() {
    throw new Error('Tried to `getState()` with undefined store.');
  }

  /** Dummy `change()` function for when the game state is unset */
  #dummyGameSateChange() {
    throw new Error('Tried to `change()` with undefined gameState.');
  }

  /** Dummy `send()` function for when the game state is unset */
  #dummyGameSateSend() {
    throw new Error('Tried to `send()` with undefined gameState.');
  }

  /** Dummy `send()` function for when the game state is unset */
  #dummyAuthCallback() {
    throw new Error('Tried to `authenticate` with a null callback.');
  }

  /** Dummy `send()` function for when the game state is unset */
  #dummyClearLocalSession() {
    throw new Error('Tried to `authenticate` with a null callback.');
  }
}
