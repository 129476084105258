import type { LocaleContent } from "./types";

const commonEntryContent: LocaleContent = {
  activityType: {
    solo: "Solo Activity",
    group: "Group Activity",
    bonus: "Bonus Activity",
  },
  leadPreview: {
    role: "Role:",
    expectedTime: "Expected Time:",
    brief: "Brief:",
  },
  start: {
    investigation: "Begin Investigation",
    protection: "Begin Protection",
  },
  ProtectionNode: {
    header: "Protection",
    description: "Proactively detect and prevent incidents from happening again.",
  },
};

export default commonEntryContent;
