import type { LocaleContent, CardOrEntryLocaleContent } from "../../types";

/**
 * i18Next `entries:Episode01.Task01` lead text content
 */
const Task01Lead: LocaleContent = {
  header: "Search for Leaked File",
  role: "Compliance Administrator",
  time: "5-10m",
  brief: "In order to determine who leaked the file and how, we'll need to find it on our network. I’d start by running a compliance search of our SharePoint sites. See if you can figure out where Amari saved the file after he authored it. Good luck!<br><br>-Andrea",
};

/**
 * i18Next `entries:Episode01.Task01` entry content
 */
const Task01Entries: CardOrEntryLocaleContent = {
  LeakedFileSearchResults: {
    heading: "Leaked File Search Results",
    default: "A list of files related to the leaked file.",
    AmariNotes: "Target Path: SharePoint\\amari_rivera_bestforyouorganic_onmicrosoft_com\\Documents\\Technology\\Purchasing Data Q1 Notes.docx",
    PurchasingDataQ1XLSX: "Target Path: SharePoint\\Amari Rivera.zip\\amari_rivera_bestforyouorganic_onmicrosoft_com\\Documents\\Excel data files\\BFYO Purchasing Data - Q1.xlsx",
    SharedNotes: "Target Path: SharePoint\\sites\\Technology\\Shared Documents\\Purchasing Data Q1 Notes.docx",
    DataRequest: "Target Path: SharePoint\\amari_rivera_bestforyouorganic_onmicrosoft_com\\Attachments\\BFYO Q1 Purchasing Data Request.docx",
  },
};

export default {
  ...Task01Lead,
  ...Task01Entries,
};
