import * as React from 'react';
import { ComponentProps } from '../../../../task-types';
import Switch from '../../../../../components/Game01/Interactions/Switch/Switch';
import SwitchInput from '../../../../../components/Game01/Interactions/Switch/SwitchInput';
import { useMultipleSelect } from '../../../../../components/Global/Interactions/MultipleSelect/MultipleSelect';
import Modal from '../../../../../components/Game01/Modal/Modal';
import Button from '../../../../../components/Global/Button/Button';

export default function A090({ t, send, match, state: { walkthroughStep, attempts } }: ComponentProps) {
  const options = React.useMemo(
    () => [
      {
        label: t('A090.switch.1.label'),
        correctState: false,
      },
      {
        label: t('A090.switch.2.label'),
        correctState: false,
      },
      {
        label: t('A090.switch.3.label'),
        correctState: false,
      },
      {
        label: t('A090.switch.4.label'),
        correctState: false,
      },
      {
        label: t('A090.switch.5.label'),
        correctState: true,
      },
      {
        label: t('A090.switch.6.label'),
        correctState: true,
      },
      {
        label: t('A090.switch.7.label'),
        correctState: false,
      },
      {
        label: t('A090.switch.8.label'),
        correctState: false,
      },
      {
        label: t('A090.switch.9.label'),
        correctState: false,
      },
      {
        label: t('A090.switch.10.label'),
        correctState: false,
      },
    ],
    [t],
  );

  const numberCorrect = React.useMemo(() => {
    return options.reduce((acc: number, option) => {
      const newAcc = option.correctState ? acc + 1 : acc;
      return newAcc;
    }, 0);
  }, [options]);

  const { isCorrect, handleError, handleCorrect, noValuesChecked } = useMultipleSelect(numberCorrect);

  const isWalkthrough = match('walkthrough');

  const handleSubmit = () => {
    if (isCorrect || isWalkthrough) {
      send('NEXT');
    } else {
      send('CLICK.SUBMIT_INCORRECT');
    }
  };

  // Todo: Extract the effects/refs below to a custom hook. Also:
  // - Look into the intersection of these hooks with other custom activity hooks (like the milti-select one used about)
  // - Determine if these can all be combined into one, or each generalized to a point where we can compose multiple easier
  // - look into typing for components that use `useImperativeHandle`
  const [lockRefs] = React.useState<any>(options.map(() => React.createRef()));

  React.useEffect(() => {
    if (isWalkthrough) {
      // check the state of the switch matching our walkthrough step
      if (walkthroughStep < 10 && !lockRefs[walkthroughStep].current.getIsCorrect()) {
        lockRefs[walkthroughStep].current.displayHint();
      } else {
        send('NEXT');
      }
    }
  }, [lockRefs, isWalkthrough, walkthroughStep, send]);

  return (
    <>
      <Switch
        title={match('success') ? t('A090.success.title') : t('A090.title')}
        body={match('success') ? t('A090.success.body') : ' '}
        nextLabel={match('success') ? t('A090.success.nextLabel') : t('common.submit')}
        onSubmit={handleSubmit}
        onHelp={() => send('CLICK.FEEDBACK')}
        disableAll={isWalkthrough}
        disableSubmit={noValuesChecked}
      >
        {options.map((option, index) => {
          return (
            <SwitchInput
              key={index}
              id={index}
              ref={lockRefs[index]}
              label={option.label}
              onChange={(event, checked) => {
                if (option.correctState) {
                  handleCorrect(event, checked);
                } else {
                  handleError(event, checked);
                }
                if (isWalkthrough && walkthroughStep === index) {
                  lockRefs[walkthroughStep].current.disable();
                  send('NEXT');
                }
              }}
              disabled={(isWalkthrough && walkthroughStep !== index) || match('success')}
              hint={t(`A090.switch.${index + 1}.hint`)}
              correctState={option.correctState}
            />
          );
        })}
      </Switch>
      <Modal
        sidebar="character"
        isOpen={match('incorrect') && attempts === 1}
        footer={<Button isPrimary label={t('common.errorTitle')} onClick={() => send('NEXT')} />}
        onDismiss={() => send('NEXT')}
        title={t('A090.error.1.title')}
        body={t('A090.error.1.body')}
        hideDismissButton
      />

      <Modal
        sidebar="character"
        isOpen={match('incorrect') && attempts === 2}
        footer={<Button isPrimary label={t('common.errorTitle')} onClick={() => send('NEXT')} />}
        onDismiss={() => send('NEXT')}
        title={t('A090.error.2.title')}
        hideDismissButton
      />

      <Modal
        sidebar="character"
        isOpen={match('feedback')}
        footer={<Button isPrimary label={t('A090.feedback.nextLabel')} onClick={() => send('NEXT')} />}
        onDismiss={() => send('NEXT')}
        title={t('A090.feedback.title')}
        body={t('A090.feedback.body')}
        hideDismissButton
      />
    </>
  );
}
