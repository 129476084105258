import * as React from 'react';
import Screen from '../../../../../components/Global/Screen/Screen';
import Modal from '../../../../../components/Game01/Modal/Modal';
import { ComponentProps } from '../../../../task-types';
import { useMultipleChoice } from '../../../../../components/Global/Interactions/MultipleChoice/MultipleChoice';
import Button from '../../../../../components/Global/Button/Button';
import Dialog from '../../../../../components/Global/Dialog/Dialog';

export default function Q07({ t, send, match }: ComponentProps): JSX.Element {
  const { errorKey, handleIncorrect } = useMultipleChoice(() => send('CLICK.INCORRECT'));
  return (
    <Screen>
      <Dialog title={t('Q07.title')} body={t('Q07.body')} onClick={() => {}} character="andrea" background="office" hideNext>
        <Button
          isPrimary
          onClick={() => handleIncorrect(1)}
          label={t('Q07.options.1.label')}
          icon={match('final') ? 'check' : undefined}
        />
        <Button
          isPrimary
          label={t('Q07.options.2.label')}
          onClick={() => handleIncorrect(2)}
          disabled={match('final')}
        />
        <Button
          isPrimary
          label={t('Q07.options.3.label')}
          onClick={() => handleIncorrect(3)}
          disabled={match('final')}
        />
        <Button
          isPrimary
          label={t('Q07.options.4.label')}
          onClick={() => send('NEXT')}
          disabled={match('final')}
        />
      </Dialog>
      <Modal
        sidebar="character"
        isOpen={match('incorrect')}
        footer={<Button isPrimary label={errorKey === 1 ? t('buttons.again') : t('buttons.ok')} onClick={() => send('NEXT')} />}
        onDismiss={() => send('NEXT')}
        title={t(`Q07.options.${errorKey}.error.title`)}
        body={t(`Q07.options.${errorKey}.error.body`)}
        hideDismissButton
      />
      <Modal
        sidebar="character"
        isOpen={match('feedback')}
        footer={<Button isPrimary label={t('buttons.ok')} onClick={() => send('NEXT')} />}
        onDismiss={() => send('NEXT')}
        title={t('Q07.feedback.title')}
        body={t('Q07.feedback.body')}
        hideDismissButton
      />
    </Screen>
  );
}
