import { EpisodeDefinition } from '../../../../lib/game-data/types';
import { Phase01Review } from './Phase01Review';
import { Phase02Review } from './Phase02Review';
import { Phase03Review } from './Phase03Review';
import { Phase04Review } from './Phase04Review';

export const Episode02: EpisodeDefinition = {
  id: 'Episode02',
  defaultcardsIDs: [
    'Game01.Episode02.Player1Card',
    'Game01.Episode02.Player2Card',
    'Game01.Episode02.Player3Card',
    'Game01.Episode02.BestForYouOrganics',
    'Game01.Episode02.AndreaDivkovic',
    'Game01.Episode02.AbigailJackson',
    'Game01.Episode02.OliviaJackson',
    'Game01.Episode02.MontyDubey',
    'Game01.Episode02.AlexGagnon',
    'Game01.Episode02.GaelTorres',
    'Game01.Episode02.QuinnAnderson',
  ],
  defaultclueIDs: [
    'Game01.Episode02.AboutBFYO',
    'Game01.Episode02.AboutAndrea',
    'Game01.Episode02.AboutAbigail',
    'Game01.Episode02.AboutOlivia',
    'Game01.Episode02.AboutMonty',
    'Game01.Episode02.AboutAlex',
    'Game01.Episode02.AboutGael',
    'Game01.Episode02.AboutQuinn',
    'Game01.Episode02.AboutWenny',
    'Game01.Episode02.AboutFrey',
  ],
  phases: [
    {
      id: 'Phase01',
      leadIds: ['../Task01', '../Task02', '../Task03'],
      review: Phase01Review,
      reviewBasePointValue: 84,
    },
    {
      id: 'Phase02',
      leadIds: ['../Task04', '../Task05', '../Task06', '../Task07'],
      review: Phase02Review,
      reviewBasePointValue: 125,
    },
    {
      id: 'Phase03',
      leadIds: ['../Task08', '../Task09', '../Task10', '../Task11'],
      review: Phase03Review,
      reviewBasePointValue: 84,
    },
    {
      id: 'Phase04',
      leadIds: ['../Task12', '../Task13', '../Task14'],
      review: Phase04Review,
      // not being used
      reviewBasePointValue: 125,
    },
  ],
  evidence: {
    //
    // Cards & Related Non-Lead Clues for Intro
    //
    // Cards images needs to be updated for EP02
    //
    Player1Card: {
      type: 'card',
      importance: 'critical',
      linkIds: ['../AndreaDivkovic'],
      image: 'wh_ep1_evidence_player1card',
      detailImage: 'wh_ep1_evidence_back_player1card',
    },
    Player2Card: {
      type: 'card',
      importance: 'critical',
      linkIds: ['../AndreaDivkovic'],
      image: 'wh_ep1_evidence_player2card',
      detailImage: 'wh_ep1_evidence_back_player2card',
    },
    Player3Card: {
      type: 'card',
      importance: 'critical',
      linkIds: ['../AndreaDivkovic'],
      image: 'wh_ep1_evidence_player3card',
      detailImage: 'wh_ep1_evidence_back_player3card',
    },
    BestForYouOrganics: {
      type: 'card',
      importance: 'critical',
      image: 'wh_ep1_evidence_bestforyouorganics',
      detailImage: 'wh_ep1_evidence_back_bestforyouorganics',
    },
    AboutBFYO: {
      type: 'clue',
      importance: 'critical',
      locationId: '../BestForYouOrganics',
      facts: {
        BFYOFact1: {
          importance: 'critical',
          linkIds: ['../../AbigailJackson', '../../OliviaJackson'],
        },
        BFYOFact2: { importance: 'critical' },
      },
    },
    AndreaDivkovic: {
      type: 'card',
      importance: 'critical',
      image: 'wh_ep1_evidence_andreadivkovic',
      detailImage: 'wh_ep1_evidence_back_andreadivkovic',
    },
    AboutAndrea: {
      type: 'clue',
      importance: 'critical',
      locationId: '../AndreaDivkovic',
      facts: {
        AndreaFact1: {
          importance: 'critical',
          linkIds: ['../../AbigailJackson'],
        },
        AndreaFact2: {
          importance: 'critical',
        },
        AndreaFact3: {
          importance: 'critical',
          linkIds: ['../../Player1Card', '../../Player2Card', '../../Player3Card'],
        },
      },
    },
    AbigailJackson: {
      type: 'card',
      importance: 'critical',
      image: 'wh_ep1_evidence_abigailjackson',
      detailImage: 'wh_ep1_evidence_back_abigailjackson',
    },
    AboutAbigail: {
      type: 'clue',
      importance: 'critical',
      locationId: '../AbigailJackson',
      facts: {
        AbigailFact1: {
          importance: 'critical',
          linkIds: ['../../BestForYouOrganics'],
        },
        AbigailFact2: {
          importance: 'critical',
          linkIds: ['../../OliviaJackson'],
        },
        AbigailFact3: { importance: 'critical' },
      },
    },
    OliviaJackson: {
      type: 'card',
      importance: 'critical',
      image: 'wh_ep1_evidence_oliviajackson',
      detailImage: 'wh_ep1_evidence_back_oliviajackson',
    },
    AboutOlivia: {
      type: 'clue',
      importance: 'critical',
      locationId: '../OliviaJackson',
      facts: {
        OliviaFact1: { importance: 'critical' },
        OliviaFact2: { importance: 'critical' },
        OliviaFact3: { importance: 'critical' },
      },
    },
    MontyDubey: {
      type: 'card',
      importance: 'critical',
      image: 'wh_ep1_evidence_montydubey',
      detailImage: 'wh_ep1_evidence_back_montydubey',
    },
    AboutMonty: {
      type: 'clue',
      importance: 'critical',
      locationId: '../MontyDubey',
      facts: {
        MontyFact1: {
          importance: 'critical',
          linkIds: ['../../AbigailJackson'],
        },
        MontyFact2: { importance: 'critical' },
      },
    },
    AlexGagnon: {
      type: 'card',
      importance: 'critical',
      image: 'wh_ep1_evidence_alexgagnon',
      detailImage: 'wh_ep1_evidence_back_alexgagnon',
    },
    AboutAlex: {
      type: 'clue',
      importance: 'critical',
      locationId: '../AlexGagnon',
    },
    GaelTorres: {
      type: 'card',
      importance: 'critical',
      image: 'wh_ep1_evidence_gaeltorres',
      detailImage: 'wh_ep1_evidence_back_gaeltorres',
    },
    AboutGael: {
      type: 'clue',
      importance: 'critical',
      locationId: '../GaelTorres',
      facts: {
        GaelReport: {
          importance: 'critical',
          linkIds: ['../../AbigailJackson'],
        },
      },
    },
    QuinnAnderson: {
      type: 'card',
      importance: 'bonus',
      image: 'wh_ep1_evidence__quinnanderson',
      detailImage: 'wh_ep1_evidence_back__quinnanderson',
    },
    AboutQuinn: {
      type: 'clue',
      importance: 'bonus',
      locationId: '../QuinnAnderson',
    },
    //
    // Cards & Related Non-Lead Clues for Phase 2+
    //
    SecurityAlert: {
      type: 'card',
      importance: 'critical',
      image: 'wh_ep2_evidence_front_security_alert',
      detailImage: 'wh_ep2_evidence_back_security_alert',
    },
    PhishingEmail: {
      type: 'card',
      importance: 'critical',
      image: 'wh_ep2_evidence_front_phishing_email',
      detailImage: 'wh_ep2_evidence_back_phishing_email',
    },
    BFYOAdmin: {
      type: 'card',
      importance: 'critical',
      image: 'wh_ep2_evidence_front_best_for_you_organiscs_admin',
      detailImage: 'wh_ep2_evidence_back_best_for_you_organiscs_admin',
    },
    GitHubRepository: {
      type: 'card',
      importance: 'critical',
      image: 'wh_ep2_evidence_front_github_repository',
      detailImage: 'wh_ep2_evidence_back_github_repository',
    },
    ManagedIdentity: {
      type: 'card',
      importance: 'critical',
      image: 'wh_ep2_evidence_front_managed_identity',
      detailImage: 'wh_ep2_evidence_back_managed_identity',
    },
    AppRegistration: {
      type: 'card',
      importance: 'critical',
      image: 'wh_ep2_evidence_front_app_registration',
      detailImage: 'wh_ep2_evidence_back_app_registration',
      linkIds: ['../eCommerceApp'],
    },
    PolicySettings: {
      type: 'card',
      importance: 'critical',
      image: 'wh_ep2_evidence_front_policy_settings',
      detailImage: 'wh_ep2_evidence_back_policy_settings',
    },
    eCommerceApp: {
      type: 'card',
      importance: 'critical',
      image: 'wh_ep2_evidence_front_ecommerce_app',
      detailImage: 'wh_ep2_evidence_back_ecommerce_app',
      linkIds: ['../BestForYouOrganics'],
    },
    WennyGultom: {
      type: 'card',
      importance: 'critical',
      unlockIds: ['../AboutWenny'],
      image: 'wh_ep2_evidence_front_winny',
      detailImage: 'wh_ep2_evidence_back_winny',
    },
    AboutWenny: {
      type: 'clue',
      importance: 'bonus',
      locationId: '../WennyGultom',
    },
    OAuthApp: {
      type: 'card',
      importance: 'critical',
      image: 'wh_ep2_evidence_front_oath_app',
      detailImage: 'wh_ep2_evidence_back_oath_app',
    },
    FreyMunch: {
      type: 'card',
      importance: 'critical',
      unlockIds: ['../AboutFrey'],
      image: 'wh_ep2_evidence_front_Frey',
      detailImage: 'wh_ep2_evidence_back_Frey',
    },
    AboutFrey: {
      type: 'clue',
      importance: 'bonus',
      locationId: '../FreyMunch',
    },
  },
  // leads
  leads: {
    Task01: {
      flags: ['simulation'],
      baseScore: 18,
      evidence: {
        SecurityAlertSummaryDetails: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../SecurityAlert',
          unlockIds: ['../../SecurityAlert'],
          facts: {
            Severity: { importance: 'critical' },
            Title: { importance: 'critical' },
            AffectedResource: {
              importance: 'critical',
              linkIds: ['../../../eCommerceApp'],
              unlockIds: ['../../../eCommerceApp'],
            },
            Time: { importance: 'critical' },
            Tactics: { importance: 'critical' },
            Status: { importance: 'critical' },
          },
        },
        SecurityAlertFullDetails: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../SecurityAlert',
          unlockIds: ['../../SecurityAlert'],
          facts: {
            CompromisedHost: {
              importance: 'critical',
              linkIds: ['../../../eCommerceApp'],
              unlockIds: ['../../../eCommerceApp'],
            },
            UserName: { importance: 'irrelevant' },
            AccountSessionID: { importance: 'irrelevant' },
            SuspiciousProcess: { importance: 'critical' },
            Commandline: { importance: 'critical' },
            ParentProcess: { importance: 'irrelevant' },
            ProcessID: { importance: 'irrelevant' },
            DetectedBy: { importance: 'irrelevant' },
            File1: { importance: 'irrelevant' },
            File2: { importance: 'irrelevant' },
            Process1ID: { importance: 'irrelevant' },
            Process2ID: { importance: 'irrelevant' },
          },
        },
        RecommendedActions: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../SecurityAlert',
          unlockIds: ['../../SecurityAlert'],
          facts: {
            Recommendation1: { importance: 'critical' },
            Recommendation2: { importance: 'irrelevant' },
            Recommendation3: { importance: 'critical' },
            Recommendation4: { importance: 'irrelevant' },
            Recommendation5: { importance: 'critical' },
            Recommendation6: { importance: 'critical' },
            Recommendation7: { importance: 'irrelevant' },
            Recommendation8: { importance: 'irrelevant' },
          },
        },
      },
    },
    Task02: {
      // not using basescore for non-sim
      baseScore: 56,
      evidence: {
        eCommerceAppCode: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../eCommerceApp',
          unlockIds: ['../../eCommerceApp', '../../WennyGultom'],
          linkIds: ['../../GitHubRepository'],
          facts: {
            CodeOwner: {
              importance: 'critical',
              linkIds: ['../../../WennyGultom'],
            },
          },
        },
        DefaultASPXCSStatus: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../GitHubRepository',
          unlockIds: ['../../GitHubRepository'],
          linkIds: ['../../eCommerceApp'],
          facts: {
            MaliciousShellcode: {
              importance: 'critical',
            },
            LastUpdate: {
              importance: 'critical',
              linkIds: ['../../../WennyGultom'],
            },
          },
        },
      },
    },
    Task03: {
      // not using basescore for non-sim
      baseScore: 42,
      evidence: {
        eCommerceAppIdentities: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../eCommerceApp',
          unlockIds: ['../../eCommerceApp'],
          facts: {
            ManagedIdentityUse: {
              importance: 'critical',
              linkIds: ['../../../ManagedIdentity'],
            },
            eShopIdentity: {
              importance: 'critical',
              linkIds: ['../../../ManagedIdentity'],
            },
          },
        },
      },
    },
    Task04: {
      baseScore: 36,
    },
    Task05: {
      flags: ['simulation', 'narrative', 'useCompleteSim'],
      hybridCounters: ['sim', 'narrative'],
      baseScore: 11,
      evidence: {
        AddDelegatedPermissionGrant: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../OAuthApp',
          unlockIds: ['../../OAuthApp'],
          hybridLeadType: 'sim',
          facts: {
            ActivityDateTimeAddDelegated: { importance: 'critical' },
            AADOperationTypeAddDelegated: { importance: 'critical' },
            AdditionalDetailsItem0KeyAddDelegated: { importance: 'irrelevant' },
            AdditionalDetailsItem0valueAddDelegated: { importance: 'irrelevant' },
            AdditionalDetailsItem1keyAddDelegated: { importance: 'irrelevant' },
            AdditionalDetailsItem1valueAddDelegated: { importance: 'irrelevant' },
            displayNameAddDelegated: { importance: 'irrelevant' },
            idAddDelegated: { importance: 'irrelevant' },
            ipAddressAddDelegated: { importance: 'critical' },
            rolesAddDelegated: { importance: 'irrelevant' },
            UserPrincipalNameAddDelegated: {
              importance: 'critical',
              linkIds: ['../../../WennyGultom'],
              unlockIds: ['../../../WennyGultom'],
            },
            TargetResourcesItem0displayNameAddDelegated: { importance: 'irrelevant' },
            TargetResourcesItem0modifiedPropertiesAddDelegated: { importance: 'critical' },
            TargetResourcesitem0typeAddDelegated: { importance: 'irrelevant' },
            TargetResourcesItem1AdministrativeUnitsAddDelegated: { importance: 'irrelevant' },
            TargetResourcesItem1TypeAddDelegated: { importance: 'irrelevant' },
          },
        },
        ConsentToApplication: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../OAuthApp',
          unlockIds: ['../../OAuthApp'],
          hybridLeadType: 'sim',
          facts: {
            ActivityDateTimeConsent: { importance: 'critical' },
            AADOperationTypeConsent: { importance: 'critical' },
            AdditionalDetailsItem0keyConsent: { importance: 'irrelevant' },
            AdditionalDetailsItem0valueConsent: { importance: 'irrelevant' },
            AdditionalDetailsItem1keyConsent: { importance: 'irrelevant' },
            AdditionalDetailsItem1valueConsent: { importance: 'irrelevant' },
            displayNameConsent: { importance: 'irrelevant' },
            idConsent: { importance: 'irrelevant' },
            ipAddressConsent: { importance: 'critical' },
            rolesConsent: { importance: 'irrelevant' },
            UserPrincipalNameConsent: {
              importance: 'critical',
              linkIds: ['../../../WennyGultom'],
              unlockIds: ['../../../WennyGultom'],
            },
            TargetResourceDisplayNameConsent: { importance: 'critical' },
            ModifiedProperties0Consent: { importance: 'critical' },
            ModifiedProperties1Consent: { importance: 'critical' },
            ModifiedProperties2Consent: { importance: 'critical' },
            ModifiedProperties3Consent: { importance: 'critical' },
            ModifiedProperties4Consent: { importance: 'irrelevant' },
            ModifiedProperties5Consent: { importance: 'irrelevant' },
          },
        },
        AddAppRoleAssignment: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../OAuthApp',
          unlockIds: ['../../OAuthApp'],
          hybridLeadType: 'sim',
          facts: {
            OperationNameAddApp: { importance: 'critical' },
            ActivityDateTimeAddApp: { importance: 'critical' },
            AADOperationTypeAddApp: { importance: 'critical' },
            AdditionalDetailsItem0keyAddApp: { importance: 'irrelevant' },
            AdditionalDetailsItem0valueAddApp: { importance: 'irrelevant' },
            AdditionalDetailsItem1keyAddApp: { importance: 'irrelevant' },
            AdditionalDetailsItem1valueAddApp: { importance: 'irrelevant' },
            displayNameAddApp: { importance: 'irrelevant' },
            idAddApp: { importance: 'irrelevant' },
            ipAddressAddApp: { importance: 'critical' },
            rolesAddApp: { importance: 'irrelevant' },
            UserPrincipalNameAddApp: {
              importance: 'critical',
              linkIds: ['../../../WennyGultom'],
              unlockIds: ['../../../WennyGultom'],
            },
            TargetResourceDisplayNameAddApp: { importance: 'critical' },
          },
        },
        SpearPhished: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../PhishingEmail',
          unlockIds: ['../../PhishingEmail'],
          hybridLeadType: 'narrative',
          facts: {
            WennyAccess: {
              importance: 'critical',
              linkIds: ['../../../OAuthApp', '../../../WennyGultom'],
              unlockIds: ['../../../OAuthApp', '../../../WennyGultom'],
            },
          },
        },
      },
    },
    Task06: {
      flags: ['simulation'],
      baseScore: 28,
      evidence: {
        GitHubConnectedApp: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../GitHubRepository',
          unlockIds: ['../../GitHubRepository'],
          facts: {
            GitHubConnectedAppName: { importance: 'critical' },
            GitHubConnectedAppStatus: { importance: 'critical' },
          },
        },
        GitHubPolicyEnableForking: {
          type: 'clue',
          importance: 'irrelevant',
          locationId: '../../GitHubRepository',
          unlockIds: ['../../GitHubRepository'],
          facts: {
            GitHubPolicyEnableForkingName: { importance: 'irrelevant' },
            GitHubPolicyEnableForkingAlertEmail: { importance: 'irrelevant' },
          },
        },
        GitHubPolicyOAuth: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../GitHubRepository',
          unlockIds: ['../../GitHubRepository'],
          facts: {
            GitHubPolicyOAuthName: { importance: 'critical' },
            GitHubPolicyOAuthAlertEmail: {
              importance: 'critical',
              linkIds: ['../../../BFYOAdmin'],
              unlockIds: ['../../../BFYOAdmin'],
            },
          },
        },
        GitHubPolicyAccessLevel: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../GitHubRepository',
          unlockIds: ['../../GitHubRepository'],
          facts: {
            GitHubPolicyAccessLevelName: { importance: 'critical' },
            GitHubPolicyAccessLevelAlertEmail: {
              importance: 'critical',
              linkIds: ['../../../BFYOAdmin'],
              unlockIds: ['../../../BFYOAdmin'],
            },
          },
        },
        GitHubPolicyDetectDeletion: {
          type: 'clue',
          importance: 'irrelevant',
          locationId: '../../GitHubRepository',
          unlockIds: ['../../GitHubRepository'],
          facts: {
            GitHubPolicyDetectDeletionName: { importance: 'irrelevant' },
            GitHubPolicyDetectDeletionAlertEmail: {
              importance: 'irrelevant',
              linkIds: ['../../../BFYOAdmin'],
              unlockIds: ['../../../BFYOAdmin'],
            },
          },
        },
      },
    },
    Task07: {
      flags: ['simulation'],
      baseScore: 12.5,
      evidence: {
        ManagedIdentityDetails: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../ManagedIdentity',
          unlockIds: ['../../ManagedIdentity'],
          facts: {
            Name: { importance: 'critical' },
            ResourceGroup: { importance: 'critical' },
            Location: { importance: 'critical' },
            SubId: { importance: 'irrelevant' },
            ClientId: { importance: 'irrelevant' },
            ObjectId: { importance: 'critical' },
          },
        },
        ManagedIdentityPermissions: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../ManagedIdentity',
          unlockIds: ['../../ManagedIdentity'],
          facts: {
            Role1Role: { importance: 'critical' },
            Role1Resource: { importance: 'critical' },
            Role1Type: { importance: 'critical' },
            Role1AssignedTo: { importance: 'irrelevant' },
            Role2Role: { importance: 'critical' },
            Role2Resource: { importance: 'critical' },
            Role2Type: { importance: 'critical' },
            Role2AssignedTo: { importance: 'irrelevant' },
            Role3Role: { importance: 'critical' },
            Role3Resource: { importance: 'critical' },
            Role3Type: { importance: 'critical' },
            Role3AssignedTo: { importance: 'irrelevant' },
            Role4Role: { importance: 'critical' },
            Role4Resource: { importance: 'critical' },
            Role4Type: { importance: 'critical' },
            Role4AssignedTo: { importance: 'irrelevant' },
            Role5Role: { importance: 'critical' },
            Role5Resource: { importance: 'critical' },
            Role5Type: { importance: 'critical' },
            Role5AssignedTo: { importance: 'irrelevant' },
          },
        },
      },
    },
    Task08: {
      flags: ['simulation'],
      baseScore: 11,
      evidence: {
        ManagedIdentityCreation: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../ManagedIdentity',
          unlockIds: ['../../ManagedIdentity'],
          facts: {
            CreationCaller: {
              importance: 'critical',
              linkIds: ['../../../QuinnAnderson'],
            },
            CreationTime: {
              importance: 'critical',
            },
          },
        },
        ManagedIdentityPermissionsAssignment: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../ManagedIdentity',
          unlockIds: ['../../ManagedIdentity'],
          facts: {
            Perm1Time: {
              importance: 'critical',
            },
            Perm1Caller: {
              importance: 'critical',
              linkIds: ['../../../QuinnAnderson'],
            },
            Perm1ResourceGroup: {
              importance: 'critical',
            },
            Perm1ResourceProviderValue: {
              importance: 'critical',
            },
            Perm1RoleDefinitionId: {
              importance: 'irrelevant',
            },
            Perm2Time: {
              importance: 'critical',
            },
            Perm2Caller: {
              importance: 'critical',
              linkIds: ['../../../QuinnAnderson'],
            },
            Perm2ResourceGroup: {
              importance: 'critical',
            },
            Perm2ResourceProviderValue: {
              importance: 'critical',
            },
            Perm2RoleDefinitionId: {
              importance: 'irrelevant',
            },
            Perm3Time: {
              importance: 'critical',
            },
            Perm3Caller: {
              importance: 'critical',
              linkIds: ['../../../QuinnAnderson'],
            },
            Perm3ResourceGroup: {
              importance: 'critical',
            },
            Perm3ResourceProviderValue: {
              importance: 'critical',
            },
            Perm3RoleDefinitionId: {
              importance: 'irrelevant',
            },
            Perm4Time: {
              importance: 'critical',
            },
            Perm4Caller: {
              importance: 'critical',
              linkIds: ['../../../QuinnAnderson'],
            },
            Perm4ResourceGroup: {
              importance: 'critical',
            },
            Perm4ResourceProviderValue: {
              importance: 'critical',
            },
            Perm4RoleDefinitionId: {
              importance: 'irrelevant',
            },
            Perm5Time: {
              importance: 'critical',
            },
            Perm5Caller: {
              importance: 'critical',
              linkIds: ['../../../QuinnAnderson'],
            },
            Perm5ResourceGroup: {
              importance: 'critical',
            },
            Perm5ResourceProviderValue: {
              importance: 'critical',
            },
            Perm5RoleDefinitionId: {
              importance: 'irrelevant',
            },
          },
        },
      },
    },
    Task09: {
      // not using basescore for non-sim
      baseScore: 28,
      evidence: {
        RequestedPermissions: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../WennyGultom',
          unlockIds: ['../../WennyGultom'],
          linkIds: ['../../QuinnAnderson', '../../ManagedIdentity'],
          facts: {
            Perm6Asked: {
              importance: 'irrelevant',
            },
            Perm6Confirmed: {
              importance: 'irrelevant',
            },
          },
        },
      },
    },
    Task10: {
      flags: ['simulation'],
      baseScore: 50,
      evidence: {
        UserConsentSettings: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../AppRegistration',
          unlockIds: ['../../AppRegistration'],
          facts: {
            UserConsentSettingsUsers: {
              importance: 'critical',
            },
            UserConsentSettingsGroupOwner: {
              importance: 'critical',
            },
          },
        },
        AdminConsentWorkflow: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../AppRegistration',
          unlockIds: ['../../AppRegistration'],
          facts: {
            AdminConsentWorkflowGroup: {
              importance: 'critical',
            },
            AdminConsentWorkflowUsersCanRequest: {
              importance: 'critical',
            },
          },
        },
      },
    },
    Task11: {
      // not using basescore for non-sim
      baseScore: 36,
      evidence: {
        AppRegistration: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../FreyMunch',
          linkIds: ['../../OAuthApp'],
          unlockIds: ['../../FreyMunch'],
          facts: {
            AppRegIPaddress: {
              importance: 'irrelevant',
              linkIds: ['../../../OAuthApp'],
            },
            AppRegID: {
              importance: 'irrelevant',
            },
            AppRegTime: {
              importance: 'bonus',
              linkIds: ['../../../OAuthApp'],
            },
          },
        },
      },
    },
    Task12: {
      flags: ['simulation'],
      baseScore: 25,
      hybridCounters: ['sim'],
      evidence: {
        NewCustomRoleSetup: {
          type: 'clue',
          hybridLeadType: 'sim',
          importance: 'critical',
          locationId: '../../AppRegistration',
          unlockIds: ['../../AppRegistration'],
          facts: {
            CustomRolenamed: {
              importance: 'irrelevant',
            },
            CustomRoleDescribed: {
              importance: 'irrelevant',
            },
            CreatePermissionSelected: {
              importance: 'critical',
            },
            CreateAsOwnerPermissionSelected: {
              importance: 'critical',
            },
          },
        },
        NewCustomRoleCreated: {
          type: 'clue',
          importance: 'critical',
          hybridLeadType: 'sim',
          locationId: '../../AppRegistration',
          unlockIds: ['../../AppRegistration'],
          facts: {
            NewRoleCreatedSuccessfully: {
              importance: 'critical',
            },
          },
        },
      },
    },
    Task13: {
      flags: ['simulation'],
      baseScore: 56,
      evidence: {
        ConditionalAccessPolicySetup: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../PolicySettings',
          unlockIds: ['../../PolicySettings'],
          facts: {
            AllusersIncluded: { importance: 'critical' },
            BreakGlassAccountSet: { importance: 'critical', linkIds: ['../../../BFYOAdmin'] },
            SelectedAppSet: { importance: 'critical' },
            RequireMFASelected: { importance: 'critical' },
            SignInFrequency: { importance: 'critical' },
          },
        },
        ConditionalAccessPolicyCreated: {
          type: 'clue',
          importance: 'bonus',
          locationId: '../../PolicySettings',
          facts: {
            SuccessfullyCreatedPolicy: { importance: 'bonus' },
          },
        },
      },
    },
    Task14: {
      baseScore: 50,
      evidence: {
        AppRegistrationRequest: {
          type: 'clue',
          importance: 'critical',
          locationId: '../../PhishingEmail',
          unlockIds: ['../../PhishingEmail'],
          facts: {
            RequestSender: {
              importance: 'critical',
            },
            RequestSubject: {
              importance: 'critical',
              linkIds: ['../../../FreyMunch', '../../../OAuthApp'],
            },
          },
        },
      },
    },
  },

  mapPositions: {
    ProtectionNode: {
      x: -720,
      y: 208,
    },
    Player1Card: {
      x: -63.999999999999986,
      y: 80,
    },
    Player2Card: {
      x: -64,
      y: 368,
    },
    Player3Card: {
      x: -64,
      y: 624,
    },
    BestForYouOrganics: {
      x: 1344,
      y: 0,
    },
    AndreaDivkovic: {
      x: 432,
      y: 368,
    },
    AbigailJackson: {
      x: 768,
      y: 0,
    },
    OliviaJackson: {
      x: 1120,
      y: 368,
    },
    MontyDubey: {
      x: 672,
      y: 368,
    },
    GaelTorres: {
      x: 888.0000000000001,
      y: 368,
    },
    AlexGagnon: {
      x: 784,
      y: 640,
    },
    QuinnAnderson: {
      x: 1040,
      y: 640,
    },
    GitHubRepository: {
      x: 770,
      y: 1216,
    },
    eCommerceApp: {
      x: 1340,
      y: 1216,
    },
    WennyGultom: {
      x: 770,
      y: 928,
    },
    FreyMunch: {
      x: 258,
      y: 928,
    },
    OAuthApp: {
      x: 528,
      y: 928,
    },
    PhishingEmail: {
      x: 528,
      y: 640,
    },
    ManagedIdentity: {
      x: 1168,
      y: 928,
    },
    BFYOAdmin: {
      x: 496,
      y: 1216,
    },
    SecurityAlert: {
      x: 1632,
      y: 1216,
    },
    PolicySettings: {
      x: 496,
      y: 1472,
    },
    AppRegistration: {
      x: 1340,
      y: 1472,
    },
  },
};
