import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSimVariableToAddEntryMap } from '../../../../lib/hooks/useSimVariableEntryMap';
import { devLog } from '../../../../lib/util';
import type { LabFrameOnMessage } from '../../../../components/Global/LabFrame/LabFrame';
import type { TSGSEvent } from '../../../types-tsgs';
import { useTaskMachine } from '../../../../lib/hooks/useTaskMachine';
import A020 from './components/A020';
import A025 from './components/A025';
import A030 from './components/A030';
import A050 from './components/A050';
import A060 from './components/A060';
import A065 from './components/A065';
import A070 from './components/A070';
import A075 from './components/A075';
import A080 from './components/A080';
import A090 from './components/A090';
import A100 from './components/A100';
import A110 from './components/A110';
import B020 from './components/B020';
import { createTask12 } from './machine';
import LabFrame from '../../../../components/Global/LabFrame/LabFrame';

const baseNS = 'Game01.Episode02.Task12';
const taskKey = 'Game01.Episode02.Task12';

const labURI = 'https://ctsdemos.azureedge.net/microsoft/SCI_Ep2Task13/index.html';

function createTaskMatch(parent: string, state: any) {
  return (matchState: string) => state.matches(`${parent}.${matchState}`);
}

const Task12: React.FC = () => {
  const [t] = useTranslation(baseNS);
  const dispatch = useDispatch();

  const labVariableEntries = useSimVariableToAddEntryMap(taskKey);
  devLog(labVariableEntries);

  // Setup task machine
  const [state, send] = useTaskMachine(createTask12, {
    taskKey,
  });

  const labMessageHandler = React.useCallback<LabFrameOnMessage<TSGSEvent>>(
    (message, frameWindow) => {
      const { data } = message;
      devLog('labMessageHandler', data, frameWindow);
      if (data.action === 'tsgs.VariableChanged') {
        labVariableEntries[data.key]?.forEach(dispatch);
      }
    },
    [dispatch, labVariableEntries],
  );

  return (
    <>
      {state.matches('A020') && <A020 t={t} send={send} state={state.context} match={createTaskMatch('A020', state)} />}
      {state.matches('A025') && <A025 t={t} send={send} state={state.context} match={createTaskMatch('A025', state)} />}
      {state.matches('A030') && <A030 t={t} send={send} state={state.context} match={createTaskMatch('A030', state)} />}
      {state.matches('A050') && <A050 t={t} send={send} state={state.context} match={createTaskMatch('A050', state)} />}
      {state.matches('A060') && <A060 t={t} send={send} state={state.context} match={createTaskMatch('A060', state)} />}
      {state.matches('A065') && <A065 t={t} send={send} state={state.context} match={createTaskMatch('A065', state)} />}
      {state.matches('A070') && <A070 t={t} send={send} state={state.context} match={createTaskMatch('A070', state)} />}
      {state.matches('A075') && <A075 t={t} send={send} state={state.context} match={createTaskMatch('A075', state)} />}
      {state.matches('A080') && <A080 t={t} send={send} state={state.context} match={createTaskMatch('A080', state)} />}
      {state.matches('A090') && <A090 t={t} send={send} state={state.context} match={createTaskMatch('A090', state)} />}
      {state.matches('A100') && <A100 t={t} send={send} state={state.context} match={createTaskMatch('A100', state)} />}
      {state.matches('A110') && <A110 t={t} send={send} state={state.context} match={createTaskMatch('A110', state)} />}
      {state.matches('B020') && <B020 t={t} send={send} state={state.context} match={createTaskMatch('B020', state)} />}
      {state.matches('B030') && <LabFrame src={labURI} title={t('title')} onMessage={labMessageHandler} />}
    </>
  );
  return <></>;
};

export default Task12;
