import * as React from 'react';
import { Trans } from 'react-i18next';
import Screen from '../../../../../components/Global/Screen/Screen';
import { ComponentProps } from '../../../../task-types';
import Dialog from '../../../../../components/Global/Dialog/Dialog';

export default function A075({ t, send }: ComponentProps): JSX.Element {
  return (
    <Screen>
      <Dialog
        title={t('A075.title')}
        onClick={() => send('NEXT')}
        nextLabel={t('common.next')}
        character="andrea"
        background="office"
        body={<Trans t={t} i18nKey="A075.body" components={[<strong />]} />}
      />
    </Screen>
  );
}
