import * as React from 'react';
import Screen from '../../../../../components/Global/Screen/Screen';
import Modal from '../../../../../components/Game01/Modal/Modal';
import VerticalUnlock, {
  TaskState,
  useVerticalUnlock,
} from '../../../../../components/Game01/Interactions/VerticalRollerUnlock/VerticalRollerUnlock';
import RollerLock from '../../../../../components/Game01/Interactions/VerticalRollerUnlock/RollerLock';
import Button from '../../../../../components/Global/Button/Button';
import { ComponentProps } from '../../../../task-types';
import { getCurrentGameTheme } from '../../../../../theme';

const initialTaskState: TaskState = {
  1: {
    order: 0,
    label: 'B010.locks.1.label',
    data: [
      { key: 1, text: 'B010.locks.1.options.1', disabled: false },
      { key: 2, text: 'B010.locks.1.options.2', disabled: false, isCorrect: true },
      { key: 3, text: 'B010.locks.1.options.3', disabled: false },
      { key: 4, text: 'B010.locks.1.options.4', disabled: false },
      { key: 5, text: 'B010.locks.1.options.5', disabled: false },
    ],
  },
  2: {
    order: 1,
    label: 'B010.locks.2.label',
    data: [
      { key: 6, text: 'B010.locks.2.options.1', disabled: false },
      { key: 7, text: 'B010.locks.2.options.2', disabled: false, isCorrect: true },
      { key: 8, text: 'B010.locks.2.options.3', disabled: false },
      { key: 9, text: 'B010.locks.2.options.4', disabled: false },
      { key: 10, text: 'B010.locks.2.options.5', disabled: false },
    ],
  },
  3: {
    order: 2,
    label: 'B010.locks.3.label',
    data: [
      { key: 11, text: 'B010.locks.3.options.1', disabled: false, isCorrect: true },
      { key: 12, text: 'B010.locks.3.options.2', disabled: false },
    ],
  },
};

export default function B010({ t, state, send, match }: ComponentProps): JSX.Element {
  const { lockRefs, handleCombinationChange, taskState, resetOptions, isUnlocked } = useVerticalUnlock(
    initialTaskState,
    [2, 7, 11],
    match('walkthrough'),
    state.walkthroughStep,
  );
  const theme = getCurrentGameTheme();
  const lockLabelColor = theme.palette.white;

  const renderLocks = React.useMemo(() => {
    return Object.keys(taskState).map((key) => {
      return (
        <RollerLock
          t={t}
          ref={lockRefs[taskState[key].order]}
          key={key}
          label={t(taskState[key].label)}
          labelColor={lockLabelColor}
          optionKey={key}
          options={taskState[key].data}
          hint={t(`B010.locks.${key}.hint`)}
          onChange={handleCombinationChange}
          showWalkthrough={match('walkthrough')}
          walkthroughStep={state.walkthroughStep}
          order={taskState[key].order}
          walkthroughCallback={() => send('NEXT')}
        />
      );
    });
  }, [taskState, t, lockRefs, lockLabelColor, handleCombinationChange, match, state.walkthroughStep, send]);

  return (
    <Screen>
      <VerticalUnlock
        title={t('B010.title')}
        description={t('B010.body')}
        hint={t('B010.subhead')}
        maxAttempts={3}
        attemptsMade={state.attempts}
        showWalkthrough={match('walkthrough')}
        onSuccess={() => send('NEXT')}
        onError={() => {
          resetOptions();
          send('CLICK.SUBMIT_INCORRECT');
        }}
        isUnlocked={isUnlocked}
        useDark
      >
        {renderLocks}
      </VerticalUnlock>
      <Modal
        sidebar="error"
        isOpen={match('incorrect') && state.attempts < 2}
        footer={<Button isPrimary label={t('B010.error.nextLabel')} onClick={() => send('NEXT')} />}
        onDismiss={() => send('NEXT')}
        title={t('B010.error.title')}
        body={t('B010.error.body')}
        hideDismissButton
      />
      <Modal
        sidebar="character"
        isOpen={match('incorrect') && state.attempts === 2}
        footer={<Button isPrimary label={t('B010.walkthrough.nextLabel')} onClick={() => send('CLICK.WALKTHROUGH')} />}
        onDismiss={() => send('CLICK.WALKTHROUGH')}
        title={t('B010.walkthrough.title')}
        body={t('B010.walkthrough.body')}
        hideDismissButton
      />
      {/* <Modal
        isOpen={match('unlocked')}
        sidebar="character"
        footer={
          <Button
            isPrimary
            label="next"
            onClick={() => {
              resetOptions();
              send('NEXT');
            }}
          />
        }
        onDismiss={() => {
          resetOptions();
          send('NEXT');
        }}
        hideDismissButton
      >
        <Heading level={1} block>
          {t('B010.unlocked')}
        </Heading>
      </Modal> */}
    </Screen>
  );
}
