/**
 * i18next NS: `Episode01.Task07`
 */
 export default {
  common: {
    errorTitle: "Try Again",
    next: "Next",
    submit: "Submit",
    start: "Start",
    skipAhead: "Skip Ahead",
    ok: "OK",
    done: "Done",
  },
  Q01: {
    title: "What type of visual do you recommend?",
    subtitle: "",
    body: "I need to be able to show the key metrics and compare the solutions.",
    options: {
      1: {
        label: "Comparison Table",
        error: {
          title: "Won’t that be hard to read on a slide?",
          body: "We can’t expect the executive team to quickly interpret a table. We need some sort of data visualization.",
        },
      },
      2: {
        label: "Pie Chart",
        error: {
          title: "I don’t think a pie chart will work.",
          body: "There are too many slices of the pie. It won’t show anything meaningful.",
        },
      },
      3: {
        label: "Bar Chart",
      },
      4: {
        label: "Decomposition Tree",
        error: {
          title: "No, I need to show more than one metric.",
          body: "A decomposition tree might be useful later, but not right now.",
        },
      },
    },
  },
  Q02: {
    title: "Can you use bright colors, like a traffic light palette?",
    subtitle: "",
    body: "I want this bar chart to really stand out and make an impact.",
    options: {
      1: {
        label: "Sure, let me show you an example.",
        error: {
          title: "Never mind!",
          body: "Now that I see it, I don’t think I want to use those colors.",
        },
      },
      2: {
        label: "I recommend using consistent, meaningful colors.",
        error: {
          title: "Consistency won’t be boring, will it?",
          body: "This chart is really important and I don’t want it to get overlooked!",
        },
      },
      3: {
        label: "No, those colors aren’t accessible.",
        error: {
          title: "You’re right, it should be accessible.",
          body: "I forgot—red and green are difficult for some people to see. We need to make sure everyone can understand this chart.",
        },
      },
    },
  },
  Q03: {
    title: "Maybe there’s another way to capture their attention.",
    subtitle: "",
    body: "How about a 3D chart? I think that will look more appealing on the slide.",
    options: {
      1: {
        label: "3D visuals are a great way to show the data.",
        error: {
          title: "Never mind, this is hard to understand.",
          body: "I can’t really wrap my mind around the data when it’s visualized this way.",
        },
      },
      2: {
        label: "3D visuals are more difficult to understand.",
      },
    },
    feedback: {
      title: "I see what you mean.",
      body: "Just make sure it's visually interesting. This is for the executive team and I want to make a good impression.",
    },
  },
  Q04: {
    title: "I want to tell the full story.",
    subtitle: "",
    body: "Can you create an overview of all the metrics in the dataset?",
    options: {
      1: {
        label: "Yes, I can show all the solutions by metric.",
        error: {
          title: "Now that I see it, I changed my mind.",
          body: "This is way too much information. We need to make it easier for the team to make a decision.",
        },
      },
      2: {
        label: "No, we should focus on the key metrics only.",
        error: {
          title: "That’s a good point.",
          body: "Focusing only on the key metrics will make it easier to understand the data.",
        },
      },
    },
  },
  Q05: {
    title: "Remind me, what are the three key business metrics?",
    subtitle: "Choose the key business metrics, then select Done. ",
    body: "",
    options: {
      1: {
        label: "Meals diverted",
      },
      2: {
        label: "Jobs created",
      },
      3: {
        label: "CO2 reduction",
      },
      4: {
        label: "Water savings",
      },
      5: {
        label: "Cost",
      },
      6: {
        label: "Profit margin",
      },
    },
    error: {
      title: "Are you sure? ",
      body: "I think you missed something. Check the requirements, then give it another try.",
    },
    feedback: {
      title: "These three priorities are most important to Trey Research.",
      subtitle: "",
      body: "Mr. Trentini wants the solution to be impactful (a large amount of meals diverted), profitable (costs are a low percentage of gross revenue), and scalable (have a high gross revenue value and create jobs).",
    },
  },
  Q06: {
    title: "I think profitability is very important to the executive team.",
    body: "What is the best way to create a profit margin calculation?",
    subtitle: "Choose the method, then select Done.",
    options: {
      1: {
        label: "Create a custom column in Power Query.",
      },
      2: {
        label: "Create a DAX column.",
      },
      3: {
        label: "Create a DAX measure.",
      },
    },
    error: {
      title: "Will that meet the requirements?",
      body: "Make sure your solution adheres to our company policies.",
    },
    feedback: {
      title: "Remember, we need to minimize the size of the model.",
      body: "Adding a column will increase the size of the data model. You should create a DAX measure instead.",
      subtitle: "",
    },
  },
  Q07: {
    title: "I want to show the “top 10” most profitable solutions.",
    body: "I also want to show the net financial benefit for those solutions. What is the most impactful way to show this? ",
    subtitle: "",
    options: {
      1: {
        label: "Option 1",
        error: {
          title: "No, this shows too much detail. ",
          body: "Didn’t we decide not to use tables in the pitch deck? I need something more visual, or people won’t know what they are looking at.",
        },
      },
      2: {
        label: "Option 2",
        error: {
          title: "No, this isn’t helpful.",
          body: "I can only look at one solution at a time—I can’t compare anything.",
        },
      },
      3: {
        label: "Option 3",
        error: {
          title: "That’s close, but not quite right.",
          body: "It’s only showing part of what I want to focus on—it’s missing some key information.",
        },
      },
      4: {
        label: "Option 4",
      },
    },
    feedback: {
      title: "This works for me!",
      body: "It shows both the net financial benefit and the profit margin for each of the top solutions.",
    },
  },
  Q08: {
    title: "I’m going to start pulling this pitch deck together now.",
    body: "Send me those visuals as soon as they’re ready!",
  },
};
