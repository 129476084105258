/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState } from 'react';
import { PrimaryButton } from '@fluentui/react';
import { getCurrentGameTheme } from '../../../theme';

interface ClipboardCopyProps {
  buttonText: string;
  copiedText: string;
  copyText: string;
}

const theme = getCurrentGameTheme();
const buttonStyles = {
  root: {
    fontSize: 16,
    padding: 16,
    borderRadius: 0,
    minWidth: 275,
  },
  rootHovered: {
    padding: 16,
  },
  rootPressed: {
    borderWidth: 3,
  },
  rootDisabled: {
    backgroundColor: `${theme.palette.green}`,
    borderColor: `${theme.palette.green}`,
    color: `${theme.palette.white}`,
  },
};

const ClipboardCopyButton: React.FC<ClipboardCopyProps> = ({ copyText, buttonText, copiedText }) => {
  const [isCopied, setIsCopied] = useState(false);

  async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(text);
    }
    return document.execCommand('copy', true, text);
  }

  const handleCopyClick = () => {
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1000);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        setIsCopied(false);
      });
  };

  return (
    <PrimaryButton
      styles={buttonStyles}
      onClick={handleCopyClick}
      disabled={isCopied}
    >
      {isCopied ? copiedText : buttonText}
    </PrimaryButton>
  );
};

export default ClipboardCopyButton;
