import * as React from 'react';
import background from '../images/09A.30.png';
import { ComponentProps } from '../../../../task-types';
import Interstitial from '../../../../../components/Global/Interactions/Interstitial/Interstitial';
import RTrans from '../../../../../components/Global/RTrans/RTrans';

export default function A030({ t, send }: ComponentProps): JSX.Element {
  return (
    <Interstitial
      title={t('A030.title')}
      body={<RTrans t={t} i18nKey="A030.body" />}
      onClick={() => send('NEXT')}
      nextLabel={t('common.next')}
      backgroundImage={background}
    />
  );
}
