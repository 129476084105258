import { z } from 'zod';
import { DateString } from '../socket/schemas';

// Basic types for JSON requests.
type JsonPrimitive = string | number | boolean | null;
type JsonValue = JsonPrimitive | JsonObject | JsonArray;
type JsonArray = JsonValue[];
type JsonObject = { [key: string]: JsonValue };

export interface InitAPIResponse {
  status: string;
  messages: string[];
  session: LocalSessionData | null;
  sessionIsValid: boolean;
  apiConfig: APIManagerConfig | null;
  event_slug: string | null;
}

export const LocalSessionDataSchema = z.object({
  username: z.string(),
  key: z.string(),
  event_slug: z.string(),
  sessionStartTime: z.number(),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type LocalSessionData = z.infer<typeof LocalSessionDataSchema>;

export const LoginResponse = z.object({
  allowed: z.boolean(),
  key: z.string(),
  event: z.object({
    start_date: DateString,
    end_date: DateString,
    slug: z.string(),
    status: z.string(),
    time_zone: z.string(),
  }),
  user_id: z.number(),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type LoginResponse = z.infer<typeof LoginResponse>;

///
/// APIManager Types
///

export type APIManagerConfig = {
  url: string | undefined;
  domain: string | undefined;
  id: number | undefined;
  key: string | undefined;
  gameData: GameData | undefined;
  event_slug: string | undefined;
  username: string | undefined;
};

export type GetGameParams = (
  | {
      game_id: number;
      slug?: string;
    }
  | {
      game_id?: number;
      slug: string;
    }
) & {
  domain?: string;
  token?: string;
  language_id?: number;
};

export type GameData = {
  game_id: number;
  language_id: number;
  session_group_id: number;
  slug: string;
};

export type EventData = {
  status: string,
  catalog_code: string | null,
  primary_language: string | null,
  start_date: string | null,
  end_date: string | null,
  date_started: string | null,
  date_ended: string | null,
  total_users: number,
  facilitators: [],
  users: [],
  sessions: [],
};

export type RegisterFacilitatorResult = {
  username: string;
  key: string;
  event_slug: string;
};

// Generic type for parameters that will fall back to using GameData. Makes
// any parameter matching a key of GameData optional.
export type GameDataParams<T extends Record<string, JsonValue>> = Partial<Pick<T, keyof GameData>> &
  Omit<T, keyof GameData>;

export type LoginUserToEventParams = {
  event_slug: string;
  user_id: string;
};
