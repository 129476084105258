import * as React from 'react';
import { Trans } from 'react-i18next';
import { ComponentProps } from '../../../../task-types';
import Dialog from '../../../../../components/Global/Dialog/Dialog';
import Screen from '../../../../../components/Global/Screen/Screen';

export default function A035({ t, send }: ComponentProps): JSX.Element {
  return (
    <Screen>
      <Dialog
        title={t('A035.title')}
        body={<Trans t={t} i18nKey="A035.body" components={[<strong />]} />}
        onClick={() => { send('NEXT'); }}
        nextLabel={t('common.next')}
        useDark
        character="andrea"
        background="office"
      />
    </Screen>
  );
}
