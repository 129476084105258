import Lock01A from './Lock01A';
import Lock01B from './Lock01B';
import Lock02A from './Lock02A';
import Lock03A from './Lock03A';
import Lock04A from './Lock04A';
import Lock05A from './Lock05A';
import Lock06A from './Lock06A';
import Lock07A from './Lock07A';
import Lock08A from './Lock08A';
import Lock08B from './Lock08B';

/**
 * Episode01: All task components in a single namespace.
 */

export default {
  Lock01A,
  Lock01B,
  Lock02A,
  Lock03A,
  Lock04A,
  Lock05A,
  Lock06A,
  Lock07A,
  Lock08A,
  Lock08B,
};
