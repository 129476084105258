import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import './i18n';
import './state/init';
import { mergeStyles } from '@fluentui/merge-styles';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';
import { store } from './store';
import App from './components/Global/App/App';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import ThemeProvider from './theme/ThemeProvider';
import CustomIconProvider from './components/Global/CustomIconProvider/CustomIconProvider';
import { GlobalStateProvider } from './state/GlobalStateProvider';

initializeIcons();
initializeFileTypeIcons();

// Inject some global styles
mergeStyles({
  ':global(body,html,#root)': {
    margin: 0,
    padding: 0,
  },
});

ReactDOM.render(
  <Router>
    <ReduxProvider store={store}>
      <GlobalStateProvider>
        <ThemeProvider>
          <CustomIconProvider>
            <App />
          </CustomIconProvider>
        </ThemeProvider>
      </GlobalStateProvider>
    </ReduxProvider>
  </Router>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
