/**
 * i18next NS: `Episode01.Task09`
 */
export default {
  common: {
    errorTitle: "Try Again",
    next: "Next",
    submit: "Submit",
    type: "Type: ",
    expectedTime: "Expected Time: ",
    brief: "Brief: ",
    start: "Start",
    skipAhead: "Skip Ahead",
    ok: "OK",
    done: "Done",
  },
  A010: {
    error: {
      1: {
        title: "Try again.",
        body: "That's not the right place.",
      },
      2: {
        title: "Try again.",
        body: "That's not right either. Are you sure you're ready for this?",
      },
      3: {
        title: "Go Here!",
        body: "I need you to go to the Microsoft Endpoint Manager.",
      },
    },
  },
  A020: {
    body: "Here's the Microsoft Endpoint Manager Admin Center. Where do you go to configure a Windows Security Baseline?",
    options: {
      1: {
        label: "Devices",
        error: {
          title: "No, not that one.",
          body: "",
        },
      },
      2: {
        label: "Endpoint security",
      },
      3: {
        label: "Reports",
        error: {
          title: "Try something else.",
          body: "",
        },
      },
    },
  },
  A030: {
    title: "Security Baseline",
    body: "An easy way to configure your device is to harden the endpoint. Because Edge is browser-specific and Defender for Endpoint is a subset of what's inside Security Baseline, let's create ours in <b>Security Baseline for Windows 10 and later.</b>",
  },
  A040: {
    title: "Every new profile should have a name.",
    body: "We'll call this profile <b>Windows Security Baseline 1</b>",
  },
  A050: {
    title: "",
    body: "How do you reduce vulnerabilities, or attack surfaces, in your applications with intelligent rules that help stop malware?",
    options: {
      1: {
        label: "Enable attack surface reduction rules",
      },
      2: {
        label: "Enable hardware-based protection",
      },
      3: {
        label: "Enable network control",
      },
      4: {
        label: "Enable web folder access",
      },
    },
    error: {
      title: "No, not that one.",
      nextLabel: "Try Again",
    },
    feedback: {
      title: "",
      body: "Reduce vulnerabilities, or attack surfaces, in your applications with these intelligent rules that stop malware.",
    },
  },
  A075: {
    title: "Now, let's use a scenario to practice how we would configure ASR rules for Microsoft Defender.",
    body: "There is a common attack scenario in your industry - a phishing campaign - that uses a Word document that runs Powershell and communicates back to a command and control center. It avoids antivirus by obfuscating and calling Win32 APIs directly.",
  },
  A080: {
    title: "Select the configuration settings you would choose to protect against this phishing scenario.",
    switch: {
      1: {
        label: "Block Office communication apps from creating child processes",
        hint: "This setting should be activated to reduce the attack surface.",
      },
      2: {
        label: "Block Office applications from injecting code into other processes",
        hint: "This setting is not relevant to the scenario.",
      },
      3: {
        label: "Block all Office applications from creating child processes",
        hint: "This setting should be activated to reduce the attack surface.",
      },
      4: {
        label: "Block Win32 API calls from Office macro",
        hint: "This setting should be activated to reduce the attack surface.",
      },
      5: {
        label: "Scan removable drives during full scan",
        hint: "This setting is not relevant to the scenario.",
      },
      6: {
        label: "Block JavaScript or VBScript from launching downloaded executable content",
        hint: "This setting is not relevant to the scenario.",
      },
      7: {
        label: "Block executable content download from email and webmail clients",
        hint: "This setting is not relevant to the scenario.",
      },
      8: {
        label: "Block credential stealing from the Windows local security authority subsystem (lsass.exe)",
        hint: "This setting is not relevant to the scenario.",
      },
      9: {
        label: "Block execution of potentially obfuscated scripts (js/vbs/ps)",
        hint: "This setting should be activated to reduce the attack surface.",
      },
      10: {
        label: "Defender potentially unwanted app action",
        hint: "This setting is not relevant to the scenario.",
      },
      11: {
        label: "Block untrusted and unsigned processes that run from USB",
        hint: "This setting is not relevant to the scenario.",
      },
      12: {
        label: "Enable network protection",
        hint: "This setting is not relevant to the scenario.",
      },
    },
    success: {
      title: "Nice work!",
      body: "Nice work! You selected the key settings that are relevant to protect against a phishing campaign. Remember, to fully protect your device, it’s best practice to activate all these security configuration settings in Microsoft Defender.",
      nextLabel: "Next",
    },
    error: {
      1: {
        title: "No, try again.",
        body: "Hint: There are four settings that should be configured to protect for this phishing scenario.",
      },
      2: {
        title: "Not quite. Fix the incorrect settings.",
      },
    },
    feedback: {
      label: "Revisit the scenario",
      title: "Common attack scenario.",
      body: "There is a common attack scenario in your industry - a phishing campaign - that uses a Word document that runs Powershell and communicates back to a command and control center. It avoids antivirus by obfuscating and calling Win32 APIs directly.",
      nextLabel: "Ok",
    },
  },
  B010: {
    title: "After configuring settings you'll add users to the security baseline.<br>Select the users you want to add to the policy.",
    options: {
      1: {
        label: "Azure ATP bestforyouorganic Administrators",
        error: {
          title: "No, not that one.",
          body: "",
        },
      },
      2: {
        label: "Azure ATP bestforyouorganic Users",
        error: {
          title: "Try something else.",
          body: "",
        },
      },
      3: {
        label: "All users",
      },
    },
  },
  B020: {
    title: "Review your settings, then select CREATE.",
    body: "",
    nextLabel: "Create",
  },
  B030: {
    title: "Nice work!",
    body: "You finished configuring Windows Security Baseline. Remember, you can review your Protection tasks on the Evidence Map.",
  },
};
