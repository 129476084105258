import * as React from 'react';
import { Trans } from 'react-i18next';
import background from '../images/09A.40.png';
import { ComponentProps } from '../../../../task-types';
import Interstitial from '../../../../../components/Global/Interactions/Interstitial/Interstitial';

export default function A040({ t, send }: ComponentProps): JSX.Element {
  return (
    <Interstitial
      title={t('A040.title')}
      onClick={() => send('NEXT')}
      nextLabel={t('common.next')}
      body={<Trans t={t} i18nKey="A040.body" components={[<strong />]} />}
      backgroundImage={background}
    />
  );
}
