import React from 'react';
import { motion } from 'framer-motion';
import { EntryUnreadNotificationProps } from '../../../lib/entries';
import { NOTIFICATION_TRANSITION_TIME } from '../../../lib/anim-timings';
import { getCurrentGameTheme } from '../../../theme';

const variants = {
  read: { opacity: 0, scale: 0 },
  unread: { opacity: 1, scale: 1 },
};

const EntryUnread: React.FC<EntryUnreadNotificationProps> = ({ unread }) => {
  const theme = getCurrentGameTheme();
  return (
    <motion.div
      variants={variants}
      initial={{ opacity: 0, scale: 0 }}
      animate={unread ? 'unread' : 'read'}
      style={{
        top: '17px',
        left: '8px',
        width: '12px',
        height: '12px',
        position: 'absolute',
        backgroundColor: theme.palette.themeTertiary,
        borderRadius: '50%',
      }}
      transition={{ duration: NOTIFICATION_TRANSITION_TIME / 1000 }}
    />
  );
};
export default EntryUnread;
