/**
 * i18next NS: `Episode02.Task12`
 */

 export default {
    common: {
      errorTitle: "Try Again",
      next: "Next",
      submit: "Submit",
      start: "Start",
      skipAhead: "Skip Ahead",
      ok: "OK",
      done: "Done",
    },
    A020: {
      error: {
        1: {
          title: "Try again.",
          body: "No, that's not the right place.",
        },
        2: {
          title: "Try again.",
          body: "That's not right either.",
        },
        3: {
          title: "Go Here!",
          body: "You'll use Azure Active Directory to set up a custom role.",
        },
        4: {
          title: "You're in the right area.",
          body: "Let's skip ahead and go straight to Azure Active Directory.",
        },
      },
    },
    A025: {
      header: "Hang on a sec.",
      body: "The execs are concerned that there's another breach so soon after the last attack. We need to make sure we get this right or we're all going to get fired! Let's go over a few questions before you start.",
    },
    A030: {
      header: "Let's review the goal.",
      body: "We want app creators to be able to create and modify app registrations—nothing else.  We reviewed the current roles and all of them provide too much permission. That’s why we need to create a custom role.",
    },
    A050: {
      header: "What principle should you abide by when creating this custom role definition?",
      most: "Most Privilege",
      elevated: "Elevated Privilege",
      least: "Least Privilege",
    },
    A060: {
      header: "That's not secure enough.",
      body: "Too much pemission is what got us in trouble in the first place!",
    },
    A065: {
      header: "We need to control this situation.",
      body: "We should take a Zero Trust approach.",
    },
    A070: {
      header: "Yes, we need to limit access.",
      body: "Remember these Zero Trust principles:<br /><ul><li>Verify explicitly</li><li>Use least privileged access</li><li>Assume breach</li></ul>",
    },
    A075: {
      header: "The custom role needs to include a role definition. What is that?",
      body: "Choose your answer, then select DONE.",
      radios: {
        one: "An identity that is assigned permissions",
        two: "A collection of permissions",
        three: "Create new types of permissions for Azure resources.",
      },
      error: {
        1: {
          title: "Not that's not it.",
          body: `An identity that is assigned permissions is known as a "security principal." It can be a user, group, or a service principal.`,
        },
        3: {
          title: "That's not a role definition.",
          body: `A constraint on where permissions are available is the "scope" of a role assignment. You can constrain permissions to a subscription, a resource group, or a single resource.`,
        },
      },
    },
    A080: {
      header: "Which subtype do you want to use for this custom app registration role?",
      body: "Choose your answer, then select DONE.",
      feedback: {
        header: `For this custom role, use the "applications" subtype.`,
        body: "Remember, you want to grant permission to manage applications.",
      },
      radios: {
        one: "microsoft.directory/groups",
        two: "microsoft.directory/applications/",
        three: "microsoft.directory/applicationPolicies/",
        four: "microsoft.directory/permissionGrantPolicies/",
      },
      error: {
        title: "That's not the right subtype.",
        body: "Consider what resources you are trying to manage. What permissions are you defining for this custom role?",
      },
    },
    A090: {
      title: "Select the suitable permissions for this custom app registration role.",
      switch: {
        1: {
          label: "microsoft.directory/applications/allProperties/read",
          hint: "Not this. This is an enterprise app permission.",
        },
        2: {
          label: "microsoft.directory/applications/audience/update",
          hint: "Not this. This assigns permission to update the supported account type property.",
        },
        3: {
          label: "microsoft.directory/applications/authentication/update",
          hint: "Not this. This grants access to fields on the application registration authentication page.",
        },
        4: {
          label: "microsoft.directory/applications/basic/update",
          hint: "Not this. This grants access to all fields on the application registration branding page.",
        },
        5: {
          label: "microsoft.directory/applications/create",
          hint: "Assign this permission! It grants the ability to create application registrations.",
        },
        6: {
          label: "microsoft.directory/applications/createAsOwner",
          hint: "Assign this permission! It grants the ability to create application registrations. The creator will be added as the first owner and can modify it later.",
        },
        7: {
          label: "microsoft.directory/applications/credentials/update",
          hint: "Not this. This grants access to all fields on the application registration certificates & secrets page.",
        },
        8: {
          label: "microsoft.directory/applications/delete",
          hint: "Not this. This grants the ability to delete app registrations.",
        },
        9: {
          label: "microsoft.directory/applications/permissions/update",
          hint: "Not this. This grants access to all fields on the application registration API permissions and Expose an API page.",
        },
        10: {
          label: "microsoft.directory/applications/standard/read",
          hint: "Not this. This grants access to read standard application registration properties.",
        },
      },
      success: {
        title: "Nice work!",
        body: "I think you know what you need to do. Go ahead and set up the custom role for creating app registrations.",
        nextLabel: "Next",
      },
      error: {
        1: {
          title: "No, try again.",
          body: "There are two correct answers.",
        },
        2: {
          title: "Not quite. Fix the incorrect settings.",
        },
      },
      feedback: {
        title: "Try again. Fix the incorrect settings to continue.",
      },
    },
    A100: {
      header: "Will this custom role impact other apps?",
      options: {
        1: "Yes, it will impact pre-existing apps.",
        2: "Yes, it will impact all future apps.",
        3: "No, it will only impact one app.",
      },
      error: {
        1: {
          title: "No, it won't impact pre-existing apps.",
          body: "But it's a good idea to complete an audit of pre-existing applications knowing that you will be gatekeeping other apps through this role.",
        },
        3: {
          title: "No, it has a bigger impact than that.",
          body: "Remember the Brief. We will be gatekeeping other apps through this role.",
        },
      },
    },
    A110: {
      title: "Nice work.",
      body: "I think you know what you need to do. Go ahead and set up the custom role for creating app registrations.",
    },
    B020: {
      error: {
        1: {
          title: "Try again.",
          body: "No, that's not the right place.",
        },
        2: {
          title: "Try again.",
          body: "That's not right either.",
        },
        3: {
          title: "Go Here!",
          body: "I need you to go to Azure Active DIrectory.",
        },
      },
    },
  };
