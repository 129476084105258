import type { LocaleContent, CardOrEntryLocaleContent } from "../../types";

/**
 * i18Next `entries:Episode02.Task06` lead text content
 */
const Task06Lead: LocaleContent = {
  header: "Protect GitHub with Microsoft Defender for Cloud Apps",
  role: "Security Operations Analyst",
  time: "3-5m",
  brief: "Set up Microsoft Defender for Cloud Apps to protect our intellectual property (IP) in GitHub. <br><br>-Andrea",
};

/**
 * i18Next `entries:Episode01.Task02` entry content
 */
const Task06Entries: CardOrEntryLocaleContent = {
  GitHubConnectedApp: {
    heading: "GitHub Connected App",
    GitHubConnectedAppName: "Connected App configuration completed for GitHub called GitHub - Enterprise",
    GitHubConnectedAppStatus: "GitHub - Enterprise connected app status:  Connected",
  },
  GitHubPolicyEnableForking: {
    heading: "GitHub Policy - Enable Forking",
    GitHubPolicyEnableForkingName: "Policy created from template:  Enablement of private repository forking (GitHub)",
    GitHubPolicyEnableForkingAlertEmail: "Alert email: bfyoadmin@bfyorganic.onmicrosoft.com",
  },
  GitHubPolicyOAuth: {
    heading: "GitHub Policy - OAuth app creation",
    GitHubPolicyOAuthName: "Policy created from template:  OAuth app creation (GitHub)",
    GitHubPolicyOAuthAlertEmail: "Alert email:  bfyoadmin@bfyorganic.onmicrosoft.com",
  },
  GitHubPolicyAccessLevel: {
    heading: "GitHub Policy - Access Level",
    GitHubPolicyAccessLevelName: "Policy created from template:  Repository access level becomes public (GitHub)",
    GitHubPolicyAccessLevelAlertEmail: "Alert email:  bfyoadmin@bfyorganic.onmicrosoft.com",
  },
  GitHubPolicyDetectDeletion: {
    heading: "GitHub Policy - Detect Deletion",
    GitHubPolicyDetectDeletionName: "Policy created from template:  Detects when a user allows the deletion of a repository (GitHub)",
    GitHubPolicyDetectDeletionAlertEmail: "Alert email:  bfyoadmin@bfyorganic.onmicrosoft.com",
  },
};

export default {
  ...Task06Lead,
  ...Task06Entries,
};
