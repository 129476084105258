import type { LocaleContent, CardOrEntryLocaleContent } from "../../types";

/**
 * i18Next `entries:Episode02.Task11` lead text content
 */
const Task11Lead: LocaleContent = {
  header: "Investigate Creator of the App Registration",
  role: "Identity Administrator",
  time: "5m",
  brief: "Now we need to find out who created the app registration used in the OAuth attack and when they created it—will you do that next? I'm interested in speaking with this person, whoever it is.<br><br>-Andrea",
};

/**
 * i18Next `entries:Episode02.Task11` entry content
 */
const Task11Entries: CardOrEntryLocaleContent = {
  AppRegistration: {
    heading: "App Registration",
    default: "freymunch@bestforyouorganic.onmicrosoft.com initiated the app registration used in the OAuth attack.",
    AppRegIPaddress: "IP address: 40.126.26.160",
    AppRegID: "id: cd29a54a-d7eb-47c2-903c-efae483a298a",
    AppRegTime: "Time Generated: 2021-11-08T23:19:30.335Z",
  },
};

export default {
  ...Task11Lead,
  ...Task11Entries,
};
