import { EventObject } from 'xstate';
import _isMatch from 'lodash/isMatch';

import { gameData } from '../static/game-data';
import { store } from '../store';
import { addPlayerScore, ScoreUpdate } from '../store/game-slice';
import { actions as JournalRdxActions, queries as JournalRdxQueries } from '../store/journal-slice';

import type { GuardMetaExt, InteractionTaskContext, TaskEvent, TaskOptions } from './task-types';
import type { TSGSQueryOptions } from './types-tsgs';
import type { EntryID, JournalEntryQueryOptions, StateList } from '../lib/journal/types';
import { ScoreVariables } from './task-common';
// import { actions as multiplayerAction } from '../store/multiplayer-slice';
/**
 * XState Action creator for 'addEntry' action.
 */
export const addEntry = (id: EntryID, states?: StateList) => {
  if (!gameData.has(id)) {
    // eslint-disable-next-line no-console
    console.warn('addEntry could not find entry id: ', id);
  }

  return {
    type: 'addEntry',
    id,
    states,
  };
};

/**
 * XState Action creator for 'removeEntry' action.
 */
export const removeEntry = (id: EntryID, states?: StateList) => {
  if (!gameData.has(id)) {
    // eslint-disable-next-line no-console
    console.warn('removeEntry could not find entry id: ', id);
  }

  return {
    type: 'removeEntry',
    id,
    states,
  };
};

/**
 * XState Action creator for 'unlockAssessment' action.
 */
export const unlockAssessment = (id: string) => ({
  type: 'unlockAssessment',
  id,
});

/**
 * XState Action creator for 'unlockTask' action.
 */
export const unlockTask = (id: string) => ({
  type: 'unlockTask',
  id,
});

/**
 * XState Action creator for 'addScore' action.
 */
export const addScore = (payload: ScoreUpdate) => {
  return {
    type: 'addScore',
    payload,
  };
};

/**
 * Grouped export for XState action creators
 */
export const actions = Object.freeze({
  addEntry,
  removeEntry,
  unlockAssessment,
  unlockTask,
  addScore,
});

/**
 * XState Guard creator for 'hasEntry' condition
 */
export const hasEntry = (id: string, options: JournalEntryQueryOptions) => {
  if (!gameData.has(id)) {
    // eslint-disable-next-line no-console
    console.warn('hasEntry could not find entry id: ', id);
  }

  return {
    type: 'hasJEntry',
    id,
    ...options,
  };
};

/**
 * XState Guard create for 'matchesTSGS' condition
 */
export const matchesTSGS = (options: TSGSQueryOptions) => ({
  type: 'matchesTSGS',
  ...options,
});

/**
 * Grouped export for XState Guard creators
 */
export const guards = Object.freeze({
  hasEntry,
  matchesTSGS,
});

/**
 * Default MachineOptions for Tasks.
 */
export const getDefaultOptions = <
  TContext extends InteractionTaskContext = InteractionTaskContext,
  TEvent extends EventObject = TaskEvent,
>(): TaskOptions<TContext, TEvent> => ({
  // Action implementations
  actions: {
    '': () => false, // no-op
    'addEntry': (context, event, meta) => {
      const action = meta.action as ReturnType<typeof addEntry>;
      const entries = store.getState().journal.entries;
      if (!JournalRdxQueries.hasEntry(entries, { id: action.id, every: action.states })) {
        const reduxAddAction = JournalRdxActions.addEntry({ id: action.id, states: action.states });
        store.dispatch(reduxAddAction);
        // eslint-disable-next-line no-console
        console.log('action-addEntry', context, event, meta);
      }
    },
    'removeEntry': (context, event, meta) => {
      const action = meta.action as ReturnType<typeof removeEntry>;
      const entries = store.getState().journal.entries;
      if (JournalRdxQueries.hasEntry(entries, { id: action.id })) {
        const reduxAddAction = JournalRdxActions.removeEntry({ id: action.id, states: action.states });
        store.dispatch(reduxAddAction);
        // eslint-disable-next-line no-console
        console.log('action-removeEntry', context, event, meta);
      }
    },
    'unlockAssessment': (context, event, meta) => {
      // eslint-disable-next-line no-console
      console.log('unlockAssessment', context, event, meta);
    },
    'unlockTask': (context, event, meta) => {
      // eslint-disable-next-line no-console
      console.log('unlockTask', context, event, meta);
    },
    'addScore': (context, event, meta) => {
      const action = meta.action as ReturnType<typeof addScore>;
      const amount = action.payload.amount;
      let score = 0;

      if (context.attempts === 0 && context.maxAttempts === 3) {
        score = amount * ScoreVariables.fourX;
      } else if (context.attempts === 0 && context.maxAttempts === 2) {
        score = amount * ScoreVariables.twoX;
      } else if (context.attempts === 1 && context.maxAttempts === 3) {
        score = amount * ScoreVariables.twoX;
      } else if (context.attempts === 1 && context.maxAttempts === 2) {
        score = amount * 0;
      } else {
        score = amount * 0;
      }

      const reduxAddScoreAction = addPlayerScore({
        amount: score,
        id: action.payload.id,
        userId: store.getState().user.username,
      });

      // console.log('Score -taskoptions: ', {
      //   amount: score,
      //   id: action.payload.id,
      //   userId: store.getState().user.username,
      // });

      store.dispatch(reduxAddScoreAction);
    },
  },
  // Guard implementations
  guards: {
    '': () => false, // null guard; won't pass.
    'hasEntry': (context, event, meta: GuardMetaExt<typeof context, typeof event>) => {
      const cond = meta.cond as ReturnType<typeof hasEntry>;
      // eslint-disable-next-line no-console
      console.log('guard-hasEntry', context, event, meta);
      const entries = store.getState().journal.entries;
      return JournalRdxQueries.hasEntry(entries, cond);
    },
    'matchesTSGS': (context, event, meta: GuardMetaExt<typeof context, typeof event>) => {
      const cond = meta.cond as ReturnType<typeof matchesTSGS>;
      const { type, ...query } = cond;
      // eslint-disable-next-line no-console
      console.log('guard-matchesTSGS', context, event, meta);
      return _isMatch(event, query);
    },
  },
});
