import type { LocaleContent, CardOrEntryLocaleContent } from "../../types";

/**
 * i18Next `entries:Episode01.Task12` lead text content
 */
const Task13Lead: LocaleContent = {
  header: "Communication Compliance Search",
  role: "Compliance Administrator",
  time: "8-10m",
  brief: "You previously did a content search and found a malicious communication. Now take a deeper look at Angel's messages. Are there any other suspicious actions?<br><br>-Andrea",
};

/**
 * i18Next `entries:Episode01.Task12` entry content
 */
const Task13Entries: CardOrEntryLocaleContent = {
  QuinnGatheringInvite: {
    heading: "Quinn's Gathering Invitation",
    default: "A birthday gathering in honor of all-star kickball shortstop Alex.",
    QuinnAGatheringInviteAttendees: "Gathering attendees: kickball squad distribution list",
    QuinnAGatheringInviteMtgDate: "Gathering meeting date: Friday, October 29",
    QuinnAGatheringInviteMtgTime: "Gathering meeting time: 1:00 PM-2:00 PM",
    QuinnAGatheringInviteWhere: "Gathering location: Floor 2 Breakroom",
  },
  AngelBrownGatheringAcceptance: {
    heading: "Angel Brown's Gathering Acceptance",
    default: "Angel Brown Accepted: Gathering for Alex's birthday",
    AngelGatheringAcceptance: "Angel accepted Quinn's meeting: True",
  },
};

export default {
  ...Task13Lead,
  ...Task13Entries,
};
