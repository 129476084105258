import Task01 from "./Task01";
import Task02 from "./Task02";
import Task03 from "./Task03";
import Task04 from "./Task04";
import Task05 from "./Task05";
import Task06 from "./Task06";
import Task07 from "./Task07";
import Task08 from "./Task08";
import Task09 from "./Task09";
import Task10 from "./Task10";
import Task11 from "./Task11";
import Task12 from "./Task12";
import Task13 from "./Task13";
import Task14 from "./Task14";
import { Episode02CardAndEntries, Episode02Lead } from "./Episode02";

/**
 * i18Next `entries:Episode1.*` collection
 */
export default {
  ...Episode02Lead,
  ...Episode02CardAndEntries,
  Task01,
  Task02,
  Task03,
  Task04,
  Task05,
  Task06,
  Task07,
  Task08,
  Task09,
  Task10,
  Task11,
  Task12,
  Task13,
  Task14,
};
