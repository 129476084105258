import { Memoize } from 'typescript-memoize';

import { GameDataBase } from './game-data-base';
import { GameDataInstance, GameDataInstanceOf, GameDataKind, PolicyDefinition } from './types';

import type { EpisodeData } from './episode-data';
import type { LeadData } from './lead-data';

/**
 * Policy Data
 */
export class PolicyData extends GameDataBase {
  readonly kind = 'policy';

  declare readonly parent: EpisodeData | LeadData | undefined;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(data: PolicyDefinition, parent?: EpisodeData | LeadData) {
    super(data, parent);
  }

  getChildren(): readonly PolicyData[];
  getChildren<TKind extends GameDataKind | undefined>(filter?: TKind): readonly GameDataInstanceOf<TKind>[];
  @Memoize()
  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  getChildren(filter?: GameDataKind): readonly GameDataInstance[] {
    return Object.freeze([]);
  }
}
