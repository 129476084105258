import * as React from 'react';
import { Trans } from 'react-i18next';
import { ComponentProps } from '../../../../task-types';
import { useCompleteHybridActivity } from '../../../../../lib/hooks/useCompleteHybridActivity';
import Dialog from '../../../../../components/Global/Dialog/Dialog';
import Screen from '../../../../../components/Global/Screen/Screen';

export default function A080({ t }: ComponentProps): JSX.Element {
  const { completeActivity, completeLabel } = useCompleteHybridActivity();

  return (
    <Screen>
      <Dialog
        title={t('A080.title')}
        body={<Trans t={t} i18nKey="A080.body" components={[<strong />]} />}
        onClick={() => completeActivity()}
        nextLabel={completeLabel}
        useDark
        character="andrea"
        background="office"
      />
    </Screen>
  );
}
