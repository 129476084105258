/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import pick from 'lodash/pick';

import type {
  APIManagerConfig,
  GameData,
  GetGameParams,
  LoginUserToEventParams,
  RegisterFacilitatorResult,
} from './types';
import { validateSchema } from './validation';

/**
 * APIManager is a singleton intended to manage the connection between
 * The Training Arcade backend and a game.
 */

class APIManager {
  _config: APIManagerConfig;

  private _axios: AxiosInstance;

  constructor() {
    this._config = this.resetConfig();
    this._axios = axios.create();
    this._axios.interceptors.request.use((config: any) => {
      const c = config;
      if (this._config.key) {
        c.headers['X-TGA-KEY'] = this._config.key;
      }
      if (this._config.domain) {
        c.headers['X-DOMAIN'] = this._config.domain;
      }
      return c;
    });
  }

  /**
   * Set configuration of the TTAMManager
   * Should non-destructively merge any valid params
   * @param {Object} p  params allowed are listed in the _config object/schema
   * @param {Boolean=} skipValidation skip the config validation process (use at your own risk!)
   */
  async config(params: Partial<typeof this._config> = {}, skipValidation = false) {
    if (!skipValidation) {
      await validateSchema(params, 'config');
    }
    const newConfig = {
      ...this._config,
      ...params,
    };
    this._config = newConfig;
    return this._config;
  }

  /**
   * Provide the config object
   */
  getConfig() {
    return this._config;
  }

  /**
   * Resets the config object, setting everything to null
   */
  resetConfig() {
    this._config = {
      domain: undefined,
      url: undefined,
      id: undefined,
      key: undefined,
      gameData: undefined,
      event_slug: undefined,
      username: undefined,
    };
    return this._config;
  }

  /**
   * Helper function to validate that proper config params are set
   * @param {Array} props array of config property keys
   */
  validateConfig(props: string[]) {
    const c = pick(this._config, props);
    validateSchema(c, 'config', `config props not properly set: ${JSON.stringify(c)}`);
    return c;
  }

  /**
   * Get game information. Note: domain should be configured prior and is not required
   * https://api.thetrainingarcade.com/documentation/#api-Game-GetGame
   * @param {Object} params
   * @param {string=} params.domain
   * @param {Number=} params.game_id
   * @param {string=} params.slug
   * @param {string=} params.token
   */
  async getGame(params: GetGameParams) {
    if (params.game_id == null && params.slug == null) {
      throw new Error('need either a game_id or slug');
    }
    const config = await this.validateConfig(['url', 'domain']);
    const result: AxiosResponse<GameData> = await this._axios.request({
      method: 'GET',
      url: `${config.url}/game`,
      params: {
        domain: config.domain,
        ...params,
      },
    });
    this.config({ gameData: result.data });
    return result;
  }

  /**
   * Get facilitator information. Note: domain should be configured prior and is not required
   * https://api.thetrainingarcade.com/documentation/#api-Facilitator-GetFacilitator
   * @param {Object} params
   * @param {string=} params.domain
   * @param {Number=} params.game_id
   * @param {string=} params.slug
   * @param {string=} params.token
   */
  async getFacilitator(slug: any) {
    if (slug == null) {
      throw new Error('need facilitator slug');
    }
    const config = await this.validateConfig(['url', 'domain']);
    const result: AxiosResponse<RegisterFacilitatorResult> = await this._axios.request({
      method: 'GET',
      url: `${config.url}/facilitator/${slug}`,
      params: {
        domain: config.domain,
      },
    });
    if (result.status === 200) {
      this.config(pick(result.data, 'key', 'username', 'event_slug'), true);
    }
    return result;
  }

  /**
   * Attempt to log the player into an event
   * https://api.thetrainingarcade.com/documentation/#api-Player-LoginPlayer
   * @param {Object} data
   * @param {string=} data.event_slug
   * @param {string=} data.user_id
   */
  async loginUserToEvent(data: LoginUserToEventParams) {
    const config = await this.validateConfig(['url', 'domain']);
    const result = await this._axios.request({
      method: 'POST',
      url: `${config.url}/player/login`,
      data,
    });
    if (result.status === 200) {
      this.config(pick(result.data, 'key'), true);
    }
    return result;
  }
}

const APIManagerSingleton = new APIManager();

export { APIManagerSingleton as APIManager };
