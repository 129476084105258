import type { LocaleContent, CardOrEntryLocaleContent } from "../../types";

/**
 * i18Next `entries:Episode01.Task03` lead text content
 */
const Task03Lead: LocaleContent = {
  header: "Investigate Amari in Azure AD Identity Protection",
  role: "Identity Administrator",
  time: "10-15m",
  brief: "Amari's user identity might be compromised! Use Azure Active Directory (AD) Identity Protection to investigate. Let me know if you see any anomalies. <br><br>-Andrea",
};

/**
 * i18Next `entries:Episode01.Task03` entry content
 */
const Task03Entries: CardOrEntryLocaleContent = {
  RiskyUserDetailsNestor: {
    heading: "Risky User Details - Nestor",
    RiskyUserDetailsUsernameNestor: "Username: nestor.wilke@bestforyouorganic.onmicrosoft.com",
    RiskyUserDetailsRisklevelNestor: "Risk level: High",
    RiskyUserDetailsRisklastupdatedNestor: "Risk last updated: 9/1/2021 5:24:23 AM",
  },
  RiskyUserDetailsAmari: {
    heading: "Risky User Details - Amari",
    RiskyUserDetailsUsernameAmari: "Username: amari.rivera@bestforyouorganic.onmicrosoft.com",
    RiskyUserDetailsRisklevelAmari: "Risk level: High",
    RiskyUserDetailsRisklastupdatedAmari: "Risk last updated: 10/28/2021 6:49:17 AM",
  },
  RiskyUserDetailsEmily: {
    heading: "Risky User Details - Emily",
    RiskyUserDetailsUsernameEmily: "Username: emily.braun@bestforyouorganic.onmicrosoft.com",
    RiskyUserDetailsRisklevelEmily: "Risk level: High",
    RiskyUserDetailsRisklastupdatedEmily: "Risk last updated: 8/28/2021 8:11:43 AM",
  },
  RiskInfoNestor: {
    heading: "Risk Info - Nestor",
    RiskInfoRiskLevelNestor: "Risk level: High",
    RiskInfoSigninTimeNestor: "Sign-in time: 8/31/2021, 12:31:01 PM",
    RiskInfoIPaddressNestor: "IP address: 178.17.174.14",
    RiskInfoSigninLocationNestor: "Sign-in Location: Chisinau, Chisinau, MD",
  },
  RiskInfoEmily: {
    heading: "Risk Info - Emily",
    RiskInfoRiskLevelEmily: "Risk level: High",
    RiskInfoSigninTimeEmily: "Sign-in time: 8/27/2021, 3:47:05 PM",
    RiskInfoIPaddressEmily: "IP address: 185.100.87.250",
    RiskInfoSigninLocationEmily: "Sign-in Location: Barcelona, Barcelona, ES",
  },
  RiskDetectionsAmari: {
    heading: "Risk Detections - Amari",
    RiskDetectionAmariDetectionType: "Detection type: Password spray",
    RiskDetectionsAmariRisklevel: "Risk level: High",
    RiskDetectionsAmariDetectiontiming: "Detection timing: Offline",
    RiskDetectionsAmariSigninTime: "Sign-in time: 10/27/2021, 2:49 AM",
    RiskDetectionsAmariIPaddress: "IP address: 199.249.230.167",
    RiskDetectionsAmariSigninLocation: "Sign-in location: San Angelo, Texas, US",
    RiskDetectionsAmariSigninRequestID: "Sign-in request id: 9c21b43f-f9c7-4507-b4a4-768d1fbb9b01",
  },
  AmariCompromiseConfirmed: {
    heading: "Amari Compromise Confirmed",
    AmariCompromiseconfirmed: "Compromise confirmed: True",
  },
  AmariTemporaryPassword: {
    heading: "Amari's Temporary Password",
    default: "Record Amari's Password Reset",
    AmariTemporaryPassword: "Amari's Temporary password: Wuga9037",
  },
};

export default {
  ...Task03Lead,
  ...Task03Entries,
};
