import ReactIs from 'react-is';

import Game02 from './Game02';
import { ImagaeComponent, SVGImageType } from './image-types';

export const imageList: ImagaeComponent[] = [];

// Iterate over all task namespaces

Object.entries({
  Game02,
}).forEach(([gameNamespaceKey, gameNamespace]) => {
  Object.entries(gameNamespace).forEach(([episodeNamespaceKey, imageNamespace]) => {
    Object.entries(imageNamespace).forEach(([imageNamespaceKey, components]) => {
      imageList.push({
        name: `${gameNamespaceKey}.${episodeNamespaceKey}.${imageNamespaceKey}`,
        Component: components as Record<string, SVGImageType>,
      });

      Object.entries(components).forEach(([componentName, Component]) => {
        if (Component == null || !ReactIs.isValidElementType(Component)) {
          return;
        }

        imageList.push({
          name: `${gameNamespaceKey}.${episodeNamespaceKey}.${imageNamespaceKey}.${componentName}`,
          Component: Component as SVGImageType,
        });
      });
    });
  });
});

// Freeze exports.
Object.freeze(imageList);
