import { createMachine, assign, send } from 'xstate';
import { TaskConfig, TaskOptions, InteractionTaskContext, TaskEvent } from '../../../task-types';
import { addScore, getDefaultOptions } from '../../../task-options';
import { store as reduxStore, getCurrentLeadId } from '../../../../store';

const initialState: InteractionTaskContext = {
  // One tracker for all attempts; will get reset. Consider changing this
  attempts: 0,
  walkthroughStep: 0,
  maxAttempts: 3,
  store: reduxStore,
};
// initialzing macro definitions
const POINTS_VALUE = 50;
const TASK_ID = 'Game01.Episode01.Task01';

export const Task01Config: TaskConfig<InteractionTaskContext, TaskEvent> = {
  id: 'Task01',
  context: initialState,
  initial: 'A005',
  states: {
    A005: {
      // Desktop Select - 3 chances to click correctly
      initial: 'decision',
      // reseting context on entry of every state
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          always: {
            target: 'hint',
            cond: (ctx) => ctx.attempts === 3,
          },
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        hint: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          // send fucntion will fire immediately after addScore advancing to the next state
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: { target: '#Task01.A010' },
          },
        },
      },
    },
    A010: {
      // Multi-choice single answer 3 buttons
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        hint: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: {
              target: '#Task01.B010',
            },
          },
        },
      },
    },
    B010: {
      // Combo unlock
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.SUBMIT_INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          on: {
            'NEXT': {
              target: 'decision',
            },
            'CLICK.WALKTHROUGH': {
              target: 'walkthrough',
            },
          },
        },
        walkthrough: {
          always: {
            target: 'final',
            cond: (ctx) => ctx.walkthroughStep === 3,
          },
          on: {
            NEXT: {
              actions: 'incrementWalkthroughStep',
              target: 'walkthrough',
            },
          },
        },
        final: {
          // calling addscore on entry in the final state
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: { target: '#Task01.B050' },
          },
        },
      },
    },
    B050: {
      // Interstitial
      entry: 'resetContext',
      on: {
        NEXT: {
          target: '#Task01.B060',
        },
      },
    },

    B060: {
      // Narrative Break Menu
      // FOR NOW This will just be an interstitial to move on
      initial: 'B070',
      entry: 'resetContext',
      states: {
        B070: {
          // Skip Conversation and continue
          on: {
            NEXT: {
              target: '#Task01.C010',
            },
          },
        },
        //  Todo: Narrative Break branch 2 -- conversation
        // 'B090': {
        //   on: {
        //     NEXT: {
        //       target: '#Task01.C010',
        //     },
        //   },
        // },
      },
    },

    C010: {
      // Multi-choice single answer 3 buttons
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        final: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: {
              target: '#Task01.C040a',
            },
          },
        },
      },
    },
    C040a: {
      // Evidence collection
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.SUBMIT_INCORRECT': {
              target: 'feedback',
            },
            'NEXT': {
              target: 'success',
            },
          },
        },
        feedback: {
          on: {
            NEXT: {
              target: 'feedbackComplete',
            },
          },
        },
        feedbackComplete: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        success: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          // calling addscore on entry in the final state
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: { target: '#Task01.C050' },
          },
        },
      },
    },
    C050: {
      // Interstitial
      entry: 'resetContext',
    },
  },
};

/**
 * Additional machine options.
 */
export const Task01Options: TaskOptions<InteractionTaskContext, TaskEvent> = {
  actions: {
    resetContext: assign({ attempts: () => 0, walkthroughStep: () => 0 }),
    incrementAttempts: assign({ attempts: (context) => context.attempts + 1 }),
    incrementWalkthroughStep: assign({ walkthroughStep: (context) => context.walkthroughStep + 1 }),
    setMaxAttemptsToTwo: assign({
      attempts: (context) => context.attempts,
      walkthroughStep: (context) => context.walkthroughStep,
      maxAttempts: (context) => {
        if (context.maxAttempts) {
          context.maxAttempts -= 1;
        }
        return 2;
      },
    }),
  },

  guards: {},
};

/**
 * Machine constructor.
 */
export function createTask01() {
  return createMachine<InteractionTaskContext, TaskEvent>(Task01Config, getDefaultOptions()).withConfig(Task01Options);
}
