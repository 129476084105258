import React from 'react';

import PlayerPersonaBase, { IPlayerPersonaBaseProps } from './PlayerPersonaBase';
import { useAppSelector } from '../../../store';
import { selectAllUsers, selectUserIsRegistered } from '../../../store/user-slice';

export interface IPlayerPersonaLiveProps extends Omit<IPlayerPersonaBaseProps, 'user' | 'color'> {
  username?: string;
}

/**
 * PlayerPersona component with Redux integration.
 */
const PlayerPersonaLive: React.FC<IPlayerPersonaLiveProps> = ({ username: usernameProp, ...props }) => {
  const { localUser, allUsers } = useAppSelector(selectAllUsers);
  const isRegistered = useAppSelector(selectUserIsRegistered);

  const username = usernameProp ?? localUser.username;
  const isLocalUser = localUser.username === username;

  const userIndex = allUsers.findIndex((user) => username === user.username);
  const user = allUsers[userIndex];
  const userColor = !isRegistered && isLocalUser ? undefined : userIndex;

  return <PlayerPersonaBase {...props} user={user ?? username} color={userColor} />;
};

export default PlayerPersonaLive;
