import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@fluentui/react';
import NavPanel from '../../Global/NavPanel/NavPanel';
import Entry from '../Entry/Entry';
import { useAppDispatch, useAppSelector } from '../../../store';
import { selectAllPolicyEntryProps, setEntriesUnread } from '../../../store/journal-slice';

const hostId = 'policies-content';
const onOpen = () => {
  const scrollableContent = document.querySelector<HTMLElement>(`#${hostId} .ms-Panel-scrollableContent`);
  if (scrollableContent) {
    scrollableContent.scrollTop = scrollableContent.scrollHeight;
  }
};

const Policies: React.FC = () => {
  const { t } = useTranslation('game01Common');

  const dispatch = useAppDispatch();
  const entriesSeen = useAppSelector(({ journal }) => journal.entriesSeen);
  const policyEntries = useAppSelector(({ journal }) => selectAllPolicyEntryProps(journal.entries));

  const updateEntriesUnread = React.useCallback(() => {
    if (entriesSeen.length > 0) {
      dispatch(setEntriesUnread({ ids: entriesSeen, unread: false }));
    }
  }, [dispatch, entriesSeen]);

  return (
    <NavPanel
      activePanel="policies"
      onOpen={onOpen}
      onDismiss={updateEntriesUnread}
      hostId={hostId}
      headerText={t('policies.header')}
    >
      <Stack verticalAlign="start" tokens={{ padding: '8px', childrenGap: '8px' }}>
        {policyEntries.map((eProps) => (
          <Entry key={eProps.entryData.id} {...eProps} />
        ))}
      </Stack>
    </NavPanel>
  );
};
export default Policies;
