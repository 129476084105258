import { mergeStyles } from '@fluentui/react';
import { IStackProps, IStackStyles, IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import * as React from 'react';
import { useAppSelector } from '../../../store';
import { selectCurrentGame } from '../../../store/multiplayer-slice';
import { getCurrentGameTheme } from '../../../theme';
import { gradients as Game01Gradient } from '../../../theme/Game01/colors';
import { gradients as Game02Gradient } from '../../../theme/Game02/colors';
import { IGradients } from '../../../theme/Global/gradients';

interface Props {
  useDark?: boolean;
  fullscreen?: boolean;
  justify?: boolean;
  gap?: number;
  centerIt?: boolean;
}

const stackInner: IStackStyles = {
  root: {
    margin: '0 auto',
    width: '100%',
    maxWidth: 1920,
  },
};

export default function Panel({
  children,
  fullscreen,
  centerIt,
  useDark,
  justify,
  gap = 32,
}: React.PropsWithChildren<Props>) {
  const theme = getCurrentGameTheme();
  const currentGame = useAppSelector(selectCurrentGame);
  const currentGameId = currentGame?.shortId;
  let currentGradient: Partial<IGradients>;

  if (currentGameId === 'Game01') {
    currentGradient = Game01Gradient;
  } else {
    currentGradient = Game02Gradient;
  }

  const stackWrapper: IStackStyles = {
    root: [
      {
        minHeight: '100%',
        height: 'auto',
        background: useDark ? currentGradient.primaryDark : currentGradient.primaryLight,
        width: '100%',
        boxShadow: theme.effects.elevation64,
        justifyContent: justify ? 'center' : 'none',
      },
    ],
  };

  const stackInnerTokens: IStackTokens = {
    childrenGap: gap,
  };

  const stackInnerProps: IStackProps = {
    horizontalAlign: centerIt ? 'center' : 'start',
  };

  const innerStyles = mergeStyles({
    padding: fullscreen ? 60 : '2rem 2rem 2rem 4rem',
  });

  return (
    <Stack styles={stackWrapper} className="interaction-panel" verticalAlign="start">
      <Stack.Item>
        <Stack
          styles={stackInner}
          className={`${innerStyles} interaction-panel__inner`}
          {...stackInnerProps}
          tokens={stackInnerTokens}
        >
          {children}
        </Stack>
      </Stack.Item>
    </Stack>
  );
}
