import { semanticColors } from './colors';
import effects from './effects';

const baseStyles = {
  paddingTop: 27,
  paddingRight: 32,
  paddingBottom: 27,
  paddingLeft: 32,
  borderWidth: 3,
  borderRadius: 50,
  border: '2px solid white',
  backgroundColor: semanticColors.button,
};

const overrideWHCMStyles = {
  // Gives focus ring clear separation in Windows High Contrast Mode (overrides a Fluent UI WHCM bug)
  '@media screen and (-ms-high-contrast: active), (forced-colors: active)': {
    ':focus::after': {
      border: 'unset',
      inset: '-4px',
      outlineWidth: '2px',
    },
  },
};

const buttons = {
  PrimaryButton: {
    styles: {
      root: {
        ...baseStyles,
        color: semanticColors.notebookText,
        transitionDuration: '.09s',
        transitionProperty: 'all',
        transitionTimingFunction: 'linear',
      },
      rootHovered: {
        ...baseStyles,
      },
      rootFocused: {
        ...overrideWHCMStyles,
      },
    },
  },
  DefaultButton: {
    styles: {
      root: {
        ...baseStyles,
        color: semanticColors.notebookText,
        paddingRight: 24,
        paddingLeft: 24,

        transitionDuration: '.2s',
        transitionProperty: 'all',
        transitionTimingFunction: 'linear',
      },
      rootHovered: {
        paddingRight: 24,
        paddingLeft: 24,
        boxShadow: effects.elevation16,
      },
      rootPressed: {
        borderWidth: 3,
      },
      rootFocused: {
        ...overrideWHCMStyles,
      },
    },
  },
};

export default buttons;
