import type { LocaleContent, CardOrEntryLocaleContent } from "../../types";

/**
 * i18Next `entries:Episode02.Task08` lead text content
 */
const Task09Lead: LocaleContent = {
  header: "Compliance Search for Managed Identity",
  role: "Compliance Administrator",
  time: "6m",
  brief: "We need to know more about the user-assigned managed identity used by the eCommerce app. Try to find out who requested it and what permissions they asked for.<br><br>-Andrea",
};

/**
 * i18Next `entries:Episode02.Task08` entry content
 */
const Task09Entries: CardOrEntryLocaleContent = {
  RequestedPermissions: {
    heading: "Requested Permissions",
    default: "Wenny told Quinn that eShopIdentity needed contributor permissions on the rgStore resource group",
    Perm6Asked: "Quinn asked Wenny about the eShopIdentity permissions",
    Perm6Confirmed: "Quinn confirmed Wenny's request",
  },
};

export default {
  ...Task09Lead,
  ...Task09Entries,
};
