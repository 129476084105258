import { selectCurrentGame } from '../store/multiplayer-slice';
import Game01Theme from './Game01/theme';
import Game02Theme from './Game02/theme';
import GlobalTheme from './Global/theme';

import { store } from '../store';

export const getCurrentGameTheme = (override?: string) => {
  const reduxState = store.getState();
  const gameThemeOverride = reduxState.game.gameThemeOverride;
  // console.log(override, gameThemeOverride, selectCurrentGame(reduxState)?.shortId);

  // forced override accepted, then game level override, then checks for current game info
  const currentGameId = override || gameThemeOverride || selectCurrentGame(reduxState)?.shortId;
  switch (currentGameId) {
    case 'Game01':
      return Game01Theme;
    case 'Game02':
      return Game02Theme;
    case 'Global':
      return GlobalTheme;
    default:
      return Game01Theme;
  }
};
