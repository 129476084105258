import { getTheme, IconButton, IStackItemStyles, mergeStyles, Modal as FluentModal, Stack } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import * as React from 'react';
import { Heading, P } from '../../Global/Text';
import characterImage from '../../../static/images/Game01/Episode01/characters/andrea/andrea_full_large.png';
import { gradients } from '../../../theme/Game01/colors';

const theme = getTheme();

interface Props {
  title?: string | React.ReactNode;
  body?: string | React.ReactNode;
  footer?: React.ReactNode | React.ReactNode[];
  isOpen?: boolean;
  sidebar?: 'error' | 'character';
  onDismiss?: () => void;
  onDismissed?: () => void;
  hideDismissButton?: boolean;
  useDark?: boolean;
}

const sidebarStyles: IStackItemStyles = {
  root: [
    {
      background: gradients.primaryDark,
    },
  ],
};

function contentWrapper(sidebar?: string): IStackItemStyles {
  return {
    root: [
      {
        paddingLeft: 40,
        paddingRight: 40,
        paddingTop: 0,
        paddingBottom: 20,
        ...(sidebar && { minHeight: 420 }),
      },
    ],
  };
}

const getSidebarStyles = (sidebar: string) => {
  let background = characterImage;
  switch (sidebar) {
    case 'character':
      background = characterImage;
      break;
    default:
      break;
  }

  return mergeStyles({
    width: 563,
    background: `url(${background}) no-repeat ${theme.palette.themeTertiary}`,
    backgroundSize: 'auto',
    backgroundPosition: '-157px 0px',
  });
};

export default function Modal({
  children,
  title,
  body,
  footer,
  isOpen,
  onDismiss,
  onDismissed,
  sidebar,
  useDark = false,
  hideDismissButton = false,
}: React.PropsWithChildren<Props>) {
  const titleId = useId('modalTitle');
  const subtitleId = useId('modalBody');

  return (
    <FluentModal
      titleAriaId={titleId}
      subtitleAriaId={subtitleId}
      isOpen={isOpen}
      onDismiss={onDismiss}
      onDismissed={onDismissed}
      isBlocking
      styles={() => {
        return {
          main: [
            {
              background: useDark ? gradients.primaryDark : theme.palette.white,
              maxWidth: '1040px',
            },
          ],
        };
      }}
    >
      <Stack horizontalAlign="stretch" horizontal={!!sidebar}>
        {/* {sidebar && <Stack.Item styles={sidebarStyles}>{renderSidebar(sidebar)}</Stack.Item>} */}
        {sidebar && (
          <Stack.Item styles={sidebarStyles} className={getSidebarStyles(sidebar)}>
            &nbsp;
          </Stack.Item>
        )}
        <Stack.Item styles={{ root: [{ width: '100%' }] }}>
          <Stack styles={contentWrapper(sidebar)}>
            {onDismiss && !hideDismissButton && (
              <Stack horizontalAlign="end">
                <IconButton onClick={onDismiss} iconProps={{ iconName: 'Cancel' }} />
              </Stack>
            )}
            <Stack as="header" id={titleId}>
              {title && (
                <Heading level={2} useDark={useDark}>
                  {title}
                </Heading>
              )}
            </Stack>
            <Stack as="section" id={subtitleId}>
              {body && (
                <P level={1} useDark={useDark}>
                  {body}
                </P>
              )}
            </Stack>
            <Stack as="section" styles={{ root: { paddingBottom: 24 } }} grow={3} verticalAlign="stretch">
              {children}
            </Stack>
            {footer && (
              <Stack horizontalAlign="end" verticalAlign="stretch">
                {footer}
              </Stack>
            )}
          </Stack>
        </Stack.Item>
      </Stack>
    </FluentModal>
  );
}
