import * as React from 'react';
import { TFunction, Trans } from 'react-i18next';
import { isEqual } from 'lodash';
import Screen from '../../../../../components/Global/Screen/Screen';
import Modal from '../../../../../components/Game01/Modal/Modal';
import { ComponentProps } from '../../../../task-types';
import Unlock, { TaskState, useUnlock } from '../../../../../components/Game01/Interactions/Unlock/Unlock';
import Lock from '../../../../../components/Game01/Interactions/Unlock/Lock';
import Button from '../../../../../components/Global/Button/Button';

const initialTaskState = (t: TFunction) : TaskState => ({
  1: {
    order: 0,
    label: t('K040.locks.1.label'),
    data: [
      { key: 1, text: t('K040.locks.1.options.1'), disabled: false },
      { key: 2, text: t('K040.locks.1.options.2'), disabled: false },
      { key: 3, text: t('K040.locks.1.options.3'), disabled: false },
      { key: 4, text: t('K040.locks.1.options.4'), disabled: false },
    ],
  },
  2: {
    order: 1,
    label: t('K040.locks.2.label'),
    data: [
      { key: 5, text: t('K040.locks.2.options.1'), disabled: false },
      { key: 6, text: t('K040.locks.2.options.2'), disabled: false },
      { key: 7, text: t('K040.locks.2.options.3'), disabled: false },
      { key: 8, text: t('K040.locks.2.options.4'), disabled: false },
    ],
  },
  3: {
    order: 2,
    label: t('K040.locks.3.label'),
    data: [
      { key: 9, text: t('K040.locks.3.options.1'), disabled: false },
      { key: 10, text: t('K040.locks.3.options.2'), disabled: false },
      { key: 11, text: t('K040.locks.3.options.3'), disabled: false },
      { key: 12, text: t('K040.locks.3.options.4'), disabled: false },
    ],
  },
});

export default function K040({ t, state, send, match }: ComponentProps): JSX.Element {
  const { lockRefs, handleCombinationChange, taskState, resetOptions, combinationState } = useUnlock(
    initialTaskState(t),
    [1, 8, 11],
    match('walkthrough'),
    state.walkthroughStep,
  );

  const renderLocks = React.useMemo(() => {
    return Object.keys(taskState).map((key, optionsIndex) => {
      return (
        <Lock
          ref={lockRefs[taskState[key].order]}
          key={key}
          label={taskState[key].label}
          optionKey={key}
          options={taskState[key].data}
          hint={t(`K040.locks.${key}.hint`)}
          onChange={(optionKey: number | string) => handleCombinationChange(optionsIndex, optionKey)}
          answerKey={combinationState.combination[optionsIndex]}
          walkthroughCallback={() => send('NEXT')}
        />
      );
    });
  }, [handleCombinationChange, combinationState.combination, lockRefs, send, t, taskState]);

  return (
    <Screen>
      <Unlock
        title={t('K040.title')}
        hint={<Trans t={t} i18nKey="K040.subhead" components={[<strong />]} />}
        description={t('K040.body')}
        maxAttempts={3}
        attemptsMade={state.attempts}
        showWalkthrough={match('walkthrough')}
        onSuccess={() => send('CLICK.CORRECT')}
        onError={() => {
          resetOptions();
          send('CLICK.SUBMIT_INCORRECT');
        }}
        isUnlocked={isEqual(combinationState.combination, combinationState.currentCombination)}
      >
        {renderLocks}
      </Unlock>
      <Modal
        sidebar="error"
        isOpen={match('incorrect') && state.attempts < 2}
        footer={<Button isPrimary label={t('common.errorTitle')} onClick={() => send('NEXT')} />}
        title={t('K040.error.title')}
        body={t('K040.error.body')}
        onDismiss={() => send('NEXT')}
        hideDismissButton
      />
      <Modal
        sidebar="character"
        isOpen={match('incorrect') && state.attempts === 2}
        footer={<Button isPrimary label={t('K040.walkthrough.nextLabel')} onClick={() => send('CLICK.WALKTHROUGH')} />}
        title={t('K040.walkthrough.title')}
        body={t('K040.walkthrough.body')}
        onDismiss={() => send('CLICK.WALKTHROUGH')}
        hideDismissButton
      />
      <Modal
        sidebar="character"
        isOpen={match('correct')}
        footer={<Button isPrimary label={t('K040.success.nextLabel')} onClick={() => send('NEXT')} />}
        title={t('K040.success.title')}
        body={t('K040.success.body')}
        onDismiss={() => send('NEXT')}
        hideDismissButton
      />
    </Screen>
  );
}
