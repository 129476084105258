import React from 'react';
import { ITextStyles, mergeStyleSets, Stack, Text, Theme } from '@fluentui/react';

import type { IStackItemStyles, IStackTokens } from '@fluentui/react';

import { getUserDisplayName } from '../../../lib/users';
import getFontVariant, { TextComponent } from '../Text/getFontVariant';
import { getPlayerColor } from '../../../static/player-avatars';

import type { UserInfo } from '../../../lib/socket/schemas';
import type { PlayerColors } from '../../../static/player-avatars';
import { getCurrentGameTheme } from '../../../theme';

export interface IPlayerPersonaBaseProps {
  useDark?: boolean;
  user: UserInfo | string;
  size?: 'small' | 'medium' | 'large';
  color?: keyof PlayerColors | number;
  hideName?: boolean;
  vertical?: boolean;
}

const stackTokens: IStackTokens = {
  childrenGap: '4px',
};

const avatarColumnBaseStyles: IStackItemStyles = {
  root: {
    boxSizing: 'border-box',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: '50%',
    borderWidth: '4px',
    borderStyle: 'solid',
  },
};

const avatarColumnStyles: Record<NonNullable<IPlayerPersonaBaseProps['size']>, IStackItemStyles> = {
  small: mergeStyleSets(avatarColumnBaseStyles, {
    root: {
      width: '36px',
      height: '36px',
    },
  }),
  medium: mergeStyleSets(avatarColumnBaseStyles, {
    root: {
      width: '48px',
      height: '48px',
    },
  }),
  large: mergeStyleSets(avatarColumnBaseStyles, {
    root: {
      width: '56px',
      height: '56px',
    },
  }),
};

const baseLabelStyle = (theme:Theme): ITextStyles => ({
  root: {
    color: theme.palette.black,
    ...theme.fonts[getFontVariant(TextComponent.enum.Label, 2)],
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
});

const darkLabelStyle = (theme:Theme): ITextStyles => mergeStyleSets(baseLabelStyle, {
  root: {
    color: theme.palette.white,
    textShadow: '2px 2px 5px #222',
  },
});

/**
 * Basic PlayerPersona presentation component.
 * (For Redux integration, see `PlayerPersonaLive`.)
 */
const PlayerPersonaBase: React.FC<IPlayerPersonaBaseProps> = ({
  useDark = false,
  user,
  size = 'small',
  color = 'gray',
  hideName = false,
  vertical = false,
}) => {
  const theme = getCurrentGameTheme();
  const displayName = getUserDisplayName(user);
  const colorData = getPlayerColor(color);

  return (
    <Stack
      horizontal={!vertical}
      tokens={stackTokens}
      horizontalAlign={vertical ? 'center' : 'start'}
      verticalAlign={vertical ? 'start' : 'center'}
    >
      <Stack.Item
        disableShrink
        styles={avatarColumnStyles[size]}
        style={{ borderColor: colorData.border, backgroundColor: colorData.background }}
        title={displayName}
        aria-label={hideName ? displayName : undefined}
      >
        {'\u200B'}
      </Stack.Item>
      {!hideName && (
        <Stack.Item styles={useDark ? darkLabelStyle(theme) : baseLabelStyle(theme)}>
          <Text styles={useDark ? darkLabelStyle(theme) : baseLabelStyle(theme)}>{displayName}</Text>
        </Stack.Item>
      )}
    </Stack>
  );
};

export default PlayerPersonaBase;
