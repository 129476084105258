/**
 * i18next NS: `Episode01.Task08`
 */
 export default {
  common: {
    errorTitle: "Try Again",
    next: "Next",
    submit: "Submit",
    start: "Start",
    skipAhead: "Skip Ahead",
    ok: "OK",
    done: "Done",
  },
  A020: {
    error: {
      1: {
        title: "Try again.",
        body: "That's not the right place.",
      },
      2: {
        title: "Try again.",
        body: "That's not right either.",
      },
      3: {
        title: "Go Here!",
        body: "I need you to go to Azure Active Directory.",
      },
      4: {
        title: "You are in the right area.",
        body: "Let's skip ahead and go straight to Azure Active Directory.",
      },
    },
  },
};
