/**
 * i18next NS: `Episode02.Task09`
 */
export default {
  common: {
    errorTitle: "Try Again",
    next: "Next",
    submit: "Submit",
    start: "Start",
    skipAhead: "Skip Ahead",
    ok: "OK",
    done: "Done",
  },
  I020: {
    error: {
      1: {
        title: "Try again.",
        body: "No, that's not the right place.",
      },
      2: {
        title: "Try again.",
        body: "That's not right either.",
      },
      3: {
        title: "Go Here!",
        body: "I need you to go to the Microsoft 365 Compliance Center.",
      },
    },
  },
  I030: {
    body: "You've launched Microsoft 365 Compliance Center. Where will you go next?",
    labels: {
      Catalog: "Catalog",
      Contentsearch: "Content search",
      eDiscovery: "eDiscovery",
    },
    errors: {
      Catalog: {
        title: "Catalog isn't the right place.",
        body: "For this part of the investigation, you need to be able to examine the contents of different types of messages.",
      },
      eDiscovery: {
        title: "No, not eDiscovery.",
        body: "You're thinking along the right lines, but there's a better option for your current needs.",
      },
    },
  },
  I040: {
    body: "What's your next step?",
    labels: {
      TheExporttab: "The \"Export\" tab",
      TheNewsearchbutton: "The \"New search\" button",
      TheSearchinputfield: "The \"Search\" input field",
    },
    errors: {
      TheExporttab: {
        title: "That's not it.",
        body: "You haven't configured your search yet, so there won't be anything meaningful to export.",
      },
      TheSearchinputfield: {
        title: "No, not the Search field.",
        body: "Searching in this field will return the names of existing search configurations. For this task, assume that no useful pre-existing search configurations exist.",
      },
    },
  },
  I045: {
    title: "It'll only take a few steps to configure your content search—the name and description, locations, and conditions. Let's name your new search simply \"Managed identity search.\"",
    body: "",
  },
  I050: {
    title: "Locations",
    body: "Choose all the locations where you want to look for results, then select DONE.",
    options: {
      1: {
        label: "Exchange mailboxes",
      },
      2: {
        label: "SharePoint sites",
      },
      3: {
        label: "Exchange public folders",
      },
    },
    error: {
      title: "Those aren't the right parameters.",
      body: "Hint: Best for Your Organics doesn't use Exchange public folders.",
    },
    feedback: {
      title: "Search Exchange mailboxes and SharePoint sites.",
      body: "The more places you search, the more likely you are to find something. Best for Your Organics doesn't use Exchange public folders, so you can skip over that selection for now.",
    },
  },
  I055: {
    body: "Now that you've selected locations for your search, what users, groups, teams, and sites should you include in your parameters?",
    labels: {
      Specificusersorgroups: "Specific users or groups",
      OnlyTeamsmailboxesandsites: "Only Teams mailboxes and sites",
      Allofthem: "All of them",
    },
    errors: {
      Specificusersorgroups: {
        title: "Good idea, but that won't work here.",
        body: "Remember the Brief—you are trying to find out who requested the managed identity and when. You don't have enough information to narrow it down to a specific person or group yet.",
      },
      OnlyTeamsmailboxesandsites: {
        title: "Not quite.",
        body: "Searching only within Teams mailboxes and sites might not be enough.",
      },
    },
  },
  I060: {
    body: "Let's finish configuring your content search. Which search term will you try?",
    labels: {
      eShopIdentity: "eShopIdentity",
      eCommerce: "eCommerce",
      onlineshopping: "online shopping",
      ProjectD: "ProjectD",
      permissions: "permissions",
    },
    errors: {
      eCommerce: {
        title: "Too many results!",
        body: "Your search for \"eCommerce\" returned a long list of results. You should use a more specific search term.",
      },
      onlineshopping: {
        title: "Too many results!",
        body: "Your search for \"online shopping\" returned hundreds of results. You should use a more specific search term.",
      },
      permissions: {
        title: "Too many results!",
        body: "Your search for \"permissions\" returned hundreds of results. You should use a more specific search term.",
      },
    },
    correct: {
      eShopIdentity: {
        title: "You found something!",
        body: "Your search for \"eShopIdentity\" returned an interesting result. It's a message from Quinn to Wenny: <br><br> QUINN: Wenny, can you let me know what permissions you need the eShopIdentity to have for the ProjectD eCommerce app?",
      },
      ProjectD: {
        title: "You found something!",
        body: "Your search for \"ProjectD\" returned an interesting result. It's a message from Quinn to Wenny: <br><br> QUINN: Wenny, can you let me know what permissions you need the eShopIdentity to have for the ProjectD eCommerce app?",
      },
    },
  },
  I080: {
    title: "Quinn's message to Wenny seemed to be part of a longer conversation. So why did the search return only the one message?",
    body: "",
    options: {
      1: {
        label: "Microsoft Teams treats each message as a single object.",
      },
      2: {
        label: "Wenny must not have replied to Quinn's message.",
      },
      3: {
        label: "The other messages in the exchange didn't include the search term.",
      },
      4: {
        label: "Wenny might have replied through email instead of Microsoft Teams.",
      },
    },
    error: {
      title: "These selections aren't correct.",
      body: "It might help to think about how you configured your content search.",
    },
    feedback: {
      title: "Here's what you need to know.",
      body: "If Wenny's reply to Quinn's message didn't match the conditions of your content search (eShopIdentity or ProjectD) then her message won't show up in the search results. This is because Microsoft Teams treats each message as a single object.",
    },
  },
  I090: {
    body: "Next, try to locate the content of the whole exchange between Quinn and Wenny. Choose a strategy:",
    labels: {
      Searchforadditionalkeywords: "Search for additional keywords",
      SearchwithParticipantsandDateconditions: "Search with Participants and Date conditions",
      SearchwithSubjectTitleconditions: "Search with Subject/Title conditions",
    },
    errors: {
      Searchforadditionalkeywords: {
        title: "Irrelevant results.",
        body: "You did some additional content searches using other keywords, but none of the results you found were relevant to your investigation.",
      },
      SearchwithSubjectTitleconditions: {
        title: "Too many results!",
        body: "You did another content search using Subject/Title conditions, but your search returned too many results to be helpful.",
      },
    },
    correct: {
      SearchwithParticipantsandDateconditions: {
        title: "You found something!",
        body: "You ran another content search using the Participants and Date conditions. It seems Wenny replied to Quinn's initial message. <br><br> Let's read the full exchange.",
      },
    },
  },
  I100: {
    title: "Here is the exchange between Quinn and Wenny.",
    body: "Select the critical evidence to add it to your Journal, then select DONE.",
    evidenceAdded: {
      title: "All set!",
      body: "Evidence added to journal.",
      nextLabel: "OK",
    },
    feedback: {
      title: "You didn't get all the critical information.",
      body: "Add this evidence to your journal.",
    },
    evidence: {
      one: {
        title: "QUINN: Wenny, can you let me know what permissions you need the eShopIdentity to have for the ProjectD eCommerce app?",
        subtitle: "Quinn asked Wenny about the eShopIdentity permissions",
      },
      two: {
        title: "WENNY: Just contributor on the rgStore resource group. I don't think the architecture of the app calls for anything more. Thanks",
        subtitle: "Wenny told Quinn that eShopIdentity needed contributor permissions on the rgStore resource group",
      },
      three: {
        title: "QUINN: You got it!",
        subtitle: "Quinn confirmed Wenny's request",
      },
    },
  },
  I110: {
    title: "Lead complete!",
    body: "You searched for messages about the user-assigned managed identity. Before you move on, check in with Quinn. See if you can learn anything else about eShopIdentity.",
    nextLabel: "Interview Quinn",
  },
};
