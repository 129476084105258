import React from 'react';
import { useAppDispatch, useAppSelector, useAppStore } from '../../store';
import {
  emitAnswerHistory,
  emitConsensusUpdate,
  handleNewConsensus,
  selectAllUsersConsensus,
  selectConsensus,
  validateUserVote,
} from '../../store/multiplayer-slice';
import { selectAllUsers, selectUserIsRegistered } from '../../store/user-slice';
import { Consensus, ConsensusAnswer, ConsensusStatus, ConsensusUpdatePayload, NILAnswer, NoAnswer } from '../multiplayer/schemas';
import { ArrayOrSingle } from '../types';
import { devLog } from '../util';

export function useConsensus(
  key: string,
  callback?: (correctCount: number, isCorrect?: boolean | null) => void,
  alwaysUpdate: boolean = false,
  triggerImmediately?: boolean,
  allowRepeatedCallbacks?: boolean,
) {
  const appStore = useAppStore();
  const dispatch = useAppDispatch();
  const { localUser } = useAppSelector(selectAllUsers);
  const [userVote, setUserVote] = React.useState<ArrayOrSingle<string | number>>('');
  const didCallCallback = React.useRef(false);
  const isUserRegistered = selectUserIsRegistered(appStore.getState());
  const [actionCallback, setActionCallback] =
    React.useState<
      { answerCorrectCount?: number, answerIsCorrect?: boolean | null, callAction: boolean, hookCurrentKey: string }>(
      { answerCorrectCount: 1, answerIsCorrect: undefined, callAction: false, hookCurrentKey: '' },
    );

  React.useEffect(() => {
    didCallCallback.current = false;
    // reset the userVote state
    setUserVote('');
  }, [key]);

  React.useEffect(() => {
  if (actionCallback.callAction && (actionCallback.hookCurrentKey === key || !isUserRegistered)) {
      callback?.call(undefined, actionCallback.answerCorrectCount || 1, actionCallback.answerIsCorrect);
      setActionCallback({ ...actionCallback, callAction: false });
    }
  }, [actionCallback, callback, isUserRegistered, key]);

  const currentConsensus: Consensus | undefined = useAppSelector(
    (state) => selectConsensus(state, key));

  const { usersWithNoVoteInConsensuses, localUserConsensuses } = useAppSelector(selectAllUsersConsensus);
  const userVoteContext = localUserConsensuses.find((consensus) => consensus.key === key);
  if (userVoteContext && (!userVote || userVoteContext?.singleContext.answer !== userVote)) {
    // sets the user answer if the user already voted (happens once)
    setUserVote(userVoteContext.singleContext.answer);
  }

  const waitingFor = usersWithNoVoteInConsensuses.find((consensus) => consensus.key === key)?.users;
  const allVotes = currentConsensus?.context.filter((context) =>
    currentConsensus.condition.participantIds.includes(context.participantId),
  ); // all votes belong to the participants that are participating in the consensus
  const finalAnswer = currentConsensus?.finalAnswer;

  const hasUserVoted = !!userVoteContext;
  const hasUserConfirmed = validateUserVote(userVoteContext);
  const tryAgainConsensus = currentConsensus?.tryAgain;
  const userTriedAgain = userVoteContext?.singleContext.triedAgain && tryAgainConsensus;

  const handleConsensus = React.useCallback(
      (
        answer: ArrayOrSingle<string | number>,
        isCorrect?: boolean,
        correctCount?: number,
        historyKey?: string,
        correctAnswer?: ConsensusAnswer,
      ) => {
      if (Array.isArray(answer) && (isCorrect === undefined || correctCount === undefined)) {
        devLog(
          `handleConsensus() passed an answer array but either 'isCorrect' (got: ${isCorrect}, expected boolean) or 'correctCount' (got: ${correctCount}, expected number) are undefined.`,
        );
      }

      const endPhasePayload: ConsensusUpdatePayload = {
        key,
        singleContext: {
          participantId: localUser.username,
          answer,
          triedAgain: userVoteContext ? userVoteContext.singleContext.triedAgain : true,
        },
        correctAnswer,
        action: (currentKey) => {
          if ((!didCallCallback.current || allowRepeatedCallbacks) && callback != null) {
            setActionCallback({
              answerCorrectCount: correctCount,
              answerIsCorrect: isCorrect,
              callAction: true,
              hookCurrentKey: currentKey as string,
            });
            // If optional params `correctCount` or `isCorrect` are omitted, set count to 1 and `isCorrect` to true
            didCallCallback.current = true;
          }
        },
      };
      if (hasUserVoted && !alwaysUpdate && answer !== NILAnswer && userVote !== NILAnswer) {
        handleNewConsensus(appStore, endPhasePayload);
      } else {
        dispatch(emitConsensusUpdate({ data: endPhasePayload }));
        if (historyKey) dispatch(emitAnswerHistory({ data: historyKey }));
      }
      setUserVote(answer);
    }, [
      allowRepeatedCallbacks,
      alwaysUpdate,
      appStore,
      callback,
      dispatch,
      hasUserVoted,
      key,
      localUser.username,
      userVote,
      userVoteContext,
    ]);

  if (triggerImmediately) {
    // Todo: I'm going to leave this check, although I suspect triggering a consensus creation early won't give desired results, so I'll leave it unimplemented for now - joel
  }

  if (!isUserRegistered) {
    return {
      handleConsensus,
      key,
      tryAgainConsensus: true,
      userTriedAgain: false,
      userVote,
      allVotes,
      finalAnswer: userVote,
      waitingFor: [],
      status: (userVote !== '' && userVote !== NoAnswer ? 'completed' : 'waiting') as ConsensusStatus,
      hasUserVoted: userVote !== '' && userVote !== NoAnswer,
      hasUserConfirmed: userVote !== '' && userVote !== NoAnswer,
    };
  }

  return {
    handleConsensus,
    key,
    tryAgainConsensus,
    userTriedAgain,
    userVote,
    allVotes,
    finalAnswer,
    waitingFor: waitingFor || [],
    // Todo: fix the hardcoded string here
    status: currentConsensus?.status,
    hasUserVoted,
    hasUserConfirmed,
  };
}
