import type { LocaleContent, CardOrEntryLocaleContent } from "../../types";

/**
 * i18Next `entries:Episode02.Task12` lead text content
 */
const Task12Lead: LocaleContent = {
  header: "Implement Custom Role for App Registration ",
  role: "Identity Administrator",
  time: "5-10m",
  brief: "Based on what happened with the OAuth attack, we should implement privileged identity management for app registration tasks. Could you set up a custom role for creating app registrations?<br><br>-Andrea",
};

/**
 * i18Next `entries:Episode02.Task12` entry content
 */
const Task12Entries: CardOrEntryLocaleContent = {
  NewCustomRoleSetup: {
    heading: "New Custom Role Setup",
    CustomRolenamed: "Custom role named: True",
    CustomRoleDescribed: "Custom role described: True",
    CreatePermissionSelected: "Create Permission selected: microsoft.directory/applications/create",
    CreateAsOwnerPermissionSelected: "Create Permission selected: microsoft.directory/applications/createAsOwner",
  },
  NewCustomRoleCreated: {
    heading: "New Custom Role Created",
    NewRoleCreatedSuccessfully: "New Custom Role Created: Successful",
  },
};

export default {
  ...Task12Lead,
  ...Task12Entries,
};
