import type { LocaleContent, CardOrEntryLocaleContent } from "../../types";

/**
 * i18Next `entries:Episode01.Task09` lead text content
 */
const Task09Lead: LocaleContent = {
  header: "Configure Windows Security Baseline",
  role: "Security Operations Analyst",
  time: "5m",
  brief: "You noticed protection weaknesses when you investigated Amari. We don't know what's going on yet, so let's make sure we set up a security baseline to prevent against future phishing attacks.",
};

/**
 * i18Next `entries:Episode01.Task09` entry content
 */
const Task09Entries: CardOrEntryLocaleContent = {};

export default {
  ...Task09Lead,
  ...Task09Entries,
};
