import { Stack, mergeStyles } from '@fluentui/react';
import * as React from 'react';
import { ScreenProps } from '../../Screen/Screen';
import { Heading, P } from '../../Text';
import Panel from '../../Panel/Panel';
import useActivityFocus from '../../../../lib/hooks/useActivityFocus';
import InteractionScreenContent from '../InteractionScreenContent/InteractionScreenContent';

interface Props extends ScreenProps {
  questionText: string | React.ReactElement;
  subtitle?: string | React.ReactElement;
  useDark?: boolean;
  horizontal?: boolean;
  didComplete?: boolean;
  onComplete: () => void;
}

export function useMultipleChoice(callback: () => {}): {
  errorKey: number | string | null;
  handleIncorrect: (key: number | string) => void;
} {
  const [errorKey, setErrorKey] = React.useState<number | string | null>(null);

  const handleIncorrect = (key: number | string) => {
    setErrorKey(key);
    callback();
  };

  return { errorKey, handleIncorrect };
}

export default function MultipleChoice({
  children,
  questionText,
  useDark = false,
  didComplete = false,
  horizontal = true,
  onComplete,
  backgroundColor = 'transparent',
  backgroundImage,
  subtitle,
}: React.PropsWithChildren<Props>) {
  React.useEffect(() => {
    if (didComplete) {
      setTimeout(() => {
        onComplete();
      }, 500);
    }
  }, [didComplete, onComplete]);

  const { focusRef } = useActivityFocus();
  const headerStyles = mergeStyles({
    margin: '0px 4em',
  });

  return (
    <Panel useDark={useDark} gap={16}>
      <Stack className={headerStyles} verticalAlign="center" tokens={{ childrenGap: 16 }}>
        <Stack.Item align="start">
          <span ref={focusRef} tabIndex={-1}>
            <Heading level={4} useDark={useDark}>
              {questionText}
            </Heading>
          </span>
          <Stack.Item align="stretch" grow>
            {subtitle && <P level={1}>{subtitle}</P>}
          </Stack.Item>
        </Stack.Item>
        <Stack.Item align="start">
          <Stack horizontal={horizontal} wrap tokens={{ childrenGap: 24 }}>
            {children}
          </Stack>
        </Stack.Item>
      </Stack>
      {backgroundImage && (
        <InteractionScreenContent backgroundColor={backgroundColor} backgroundImage={backgroundImage} />
      )}
    </Panel>
  );
}
