import type { LocaleContent, CardOrEntryLocaleContent } from "../../types";

/**
 * i18Next `entries:Episode01.Task08` lead text content
 */
const Task08Lead: LocaleContent = {
  header: "Investigate IP Address in Sentinel",
  role: "Security Operations Analyst",
  time: "5-8m",
  brief: "We need to find out how far this has spread and contain this threat. Look for other accounts or devices connected with that bad IP address. Then set up an Analytics rule to notify us if it shows up again.<br><br>-Andrea",
};

/**
 * i18Next `entries:Episode01.Task08` entry content
 */
const Task08Entries: CardOrEntryLocaleContent = {
  SentinelLogOtherConnectionsToIP: {
    heading: "Sentinel Log Other Connections To IP",
    default: "Did any other PCs connect to the bad IP address?",
    SentinelLogNoEvidenceOfOtherConnections: "Device Name: pc105",
  },
  NewAnalyticsRule: {
    heading: "New Analytics Rule",
    default: "Record details about this unfamiliar sign-in",
    NewAnalyticsRuleName: "Rule for 20.108.242.184",
    NewAnalyticsRuleQuery: "DeviceNetworkEvents | where RemotIP == '20.108.242.184'",
  },
};

export default {
  ...Task08Lead,
  ...Task08Entries,
};
