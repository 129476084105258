import type { CardOrEntryLocaleContent, LocaleContent } from "../../types";

/**
 * i18Next `entries:Episode01` Cards and  Entry content
 */
export const Episode01CardAndEntries: CardOrEntryLocaleContent = {
  //
  // Cards & Related Non-Lead Clues for Phase 1+
  //
  Player1Card: {
    heading: "Player 1",
    default: "Player 1 is one of the newest members of the Best For You Organics family. They're working directly with our CIO Andrea Divkovic to protect the company.",
  },
  Player2Card: {
    heading: "Player 2",
    default: "Player 2 is one of the newest members of the Best For You Organics family. They're working directly with our CIO Andrea Divkovic to protect the company.",
  },
  Player3Card: {
    heading: "Player 3",
    default: "Player 3 is one of the newest members of the Best For You Organics family. They're working directly with our CIO Andrea Divkovic to protect the company.",
  },
  BestForYouOrganics: {
    heading: "Best For You Organics",
    default: "Best For You Organics is one of the largest organic e-retailers in the U.S.",
  },
  AboutBFYO: {
    heading: "About Best For You Organics",
    default: "A selection of facts about Best For You Organics for new employees.",
    BFYOFact1: "Founded by Abigail and Olivia Jackson in 2009.",
    BFYOFact2: "Has over 10 million subscribers in the U.S.",
    BFYOFact3: "Scheduled to launch in the EU TODAY!",
  },
  EULaunch: {
    heading: "EU Launch",
    default: "Best For You Organics' European launch represents a critical inflection point for the company.",
  },
  AndreaDivkovic: {
    heading: "Andrea Divkovic",
    default: "Andrea Divkovic (she/her) is the CIO of Best For You Organics.",
  },
  AboutAndrea: {
    heading: "About Andrea",
    default: "Age: 51, Alma Mater: University of Illinois at Urbana-Champaign, Hometown: Terre Haute, Indiana, Favorite food: coffee, Pets: a Philodendron named Bunny",
    AndreaFact1: "Reports directly to CEO Abigail Jackson.",
    AndreaFact2: "Joined Best For You Organics 8 months ago to shephard the IT department through the company's EU expansion.",
    AndreaFact3: "Personally chose the three newest employees of the IT team after an extensive search.",
  },
  AbigailJackson: {
    heading: "Abigail Jackson",
    default: "Abigail Jackson (she/her) is co-founder and CEO of Best For You Organics.",
  },
  AboutAbigail: {
    heading: "About Abigail",
    default: "Age: 50, Alma Mater: Stanford, Hometown: Richland, WA, Favorite food: Sushi, Pets: none (no time)",
    AbigailFact1: "Began her first start up while still in college in the Bay Area.",
    AbigailFact2: "Talked her older sister, Olivia, into turning the family farm's CSA into an e-retailer while home for the holidays in 2009.",
    AbigailFact3: "Abigail is determined to make Best For You Organics the largest organics e-commerce site in the world.",
  },
  OliviaJackson: {
    heading: "Olivia Jackson",
    default: "Olivia Jackson (she/her) is co-founder, VP of Quality, and Brand Ambassador of Best For You Organics.",
  },
  AboutOlivia: {
    heading: "About Olivia",
    default: "Age: 52, Alma Mater: Oregon State University, Hometown: Richland, WA, Favorite food: roasted brussel sprouts, Pets: none, but she maintains a 30 beehive apiary in her yard.",
    OliviaFact1: "Got her BS in agricultural sciences and turned the family farm organic for her thesis project.",
    OliviaFact2: "Chosen as the face of the company because she's an actual farmer and the co-founder of the company.",
    OliviaFact3: 'Olivia cares most that Best For You Organics lives up to its motto: "Best For the Planet, Best For You."',
  },
  MontyDubey: {
    heading: "Monty Dubey",
    default: "Monty Dubey (he/him) is the CPO of Best For You Organics",
  },
  AboutMonty: {
    heading: "About Monty",
    default: "Age: 50, Alma Mater: Brown University, Hometown: Delhi, India, Favorite food: his mother's aloo gosht, Pets: tortoisehell tomcat named Leonardo.",
    MontyFact1: "Reports directly to CEO Abigail Jackson.",
    MontyFact2: "Was one of the first employees Abigail and Olivia hired at the start of the company.",
    MontyFact3: "Receives the blackmail notice directly from the ransomer first thing on the morning Best For You Organics intends to launch in the EU.",
  },
  TomoTakanashi: {
    heading: "Tomo Takanashi",
    default: "Tomo Takanashi (she/her) is the CHRO of Best For You Organics.",
  },
  AboutTomo: {
    heading: "About Tomo",
    default: "Age: 46, Alma Mater: UCLA, Hometown: Pasadena, CA, Favorite food: pizza, Pets: Jack Russell terrier named Maximus",
    TomoFact1: "Reports directly to CEO Abigail Jackson.",
    TomoFact2: "Started in the HR department in 2015 as a recruiter.",
    TomoFact3: "The IT department draws straws to decide who has to help her each time.",
  },
  TheLeakedFile: {
    heading: "The Leaked File",
    default: "A company file was leaked to a malicious actor.",
  },
  TheLeakedFileProperties: {
    heading: "The Leaked File - Properties",
    default: "Recorded properties of the Leaked File are as follows.",
    LeakedFileTitle: "Title: BFYO Purchasing Data - Q1.xlsx",
    LeakedFileAuthor: "File Author: Amari Rivera",
    LeakedFileProgram: "Program: Microsoft Excel",
    LeakedFileCreated: "Content Created: Wednesday, October 27, 2021, 1:23:15 PM",
    LeakedFileSaved: "Date Last Saved: Thursday, October 28, 2021, 3:33:36 PM",
  },
  TheRansomer: {
    heading: "The Ransomer",
    default: "A Ransomer claims to have hacked Best for You Organics and is demanding payment.",
  },
  AboutRansomer: {
    heading: "About the Ransomer",
    default: "The Ransomer is threatening to break news of the breach if they aren't paid on time.",
    RansomerGoal: "The Ransomer is trying to extort Best For You Organics.",
    RansomerFile: "The Ransomer is in possession of the Leaked File.",
  },
  AmariRivera: {
    heading: "Amari Rivera",
    default: "Amari Rivera (he/him) is a member of the e-Commerce team and the de facto party planner for the company.",
  },
  AboutAmari: {
    heading: "About Amari",
    default: "Age: 35, Alma Mater: Fordham University, Hometown: Throggs Neck, NY, Favorite food: vegetarian tasting menu at Le Bernadin, Pets: Golden Retriever named Goldie Hawn.",
    AmariReport: "He reports to App Platform Manager Parker McLean.",
  },
  pc105: {
    heading: "pc105",
    default: "The device registered as pc105 has been involved in multiple suspicious incidents.",
  },
  BFYOAdmin: {
    heading: "Best For You Organics Admin",
    shortHeading: "BFYO Admin",
    default: "This account has administrator level access to Best For You Organics' systems and databases.",
  },
  PatchEXE: {
    heading: "patch.exe",
    default: "A suspicious file discovered on pc105.",
  },
  SuspiciousIP: {
    heading: "Suspicious IP",
    default: "This IP 20.108.242.184 is linked to suspicious events.",
  },
  NestorWilke: {
    heading: "Nestor Wilke",
    default: "Nestor Wilke (he/him) is a marketing assistant at Best For You Organics.",
  },
  AboutNestor: {
    heading: "About Nestor",
    default: "Age: 23, Alma Mater: Texas A&M, Hometown: Chicago, IL, Favorite food: BBQ, Pets: mixed breed mutt named Reveille",
    NestorReport: "He answers to CMO Gael Torres.",
  },
  EmilyBraun: {
    heading: "Emily Braun",
    default: "Emily Braun (she/her) is a digital brand manager at Best For You Organics.",
  },
  AboutEmily: {
    heading: "About Emily",
    default: "Age: 27, Alma Mater: Villanova, Hometown: Denver, CO, Favorite food: chili fries, Pets: a black labrador named Izzy",
    EmilyReport: "She answers to CMO Gael Torres.",
  },
  //
  // Cards & Related Non-Lead Clues for Phase 2+
  //
  ShoppingListZIP: {
    heading: "ShoppingList.ZIP",
    default: "A malware-generated ZIP file used to bundle several sensitive documents for exfiltration.",
  },
  AngelBrown: {
    heading: "Angel Brown",
    default: "Angel Brown (she/her) is an Identity Protection Manager for Best For You Organics.",
  },
  AboutAngel: {
    heading: "About Angel",
    default: "Age: 32, Alma Mater: CUNY City College, Hometown: Burlington, VT, Favorite food: grilled veggie burger, Pets: tropical fish (various)",
    AngelReport: "She reports to IT Project Manager Robin Becker.",
  },
  //
  // Cards & Related Non-Lead Clues for Phase 3+
  //
  PolicySettings: {
    heading: "Policy Settings",
    default: "A list of changes that the team has made to your Policy Settings.",
  },
  pc067: {
    heading: "pc067",
    default: "The device registered as pc067 is being investigated as part of the ongoing security breach.",
  },
  RDPConnection: {
    heading: "RDP Connection",
    default: "An RDP Connection made from device pc034.",
  },
  pc034: {
    heading: "pc034",
    default: "The device registered as pc034 is being investigated as part of the ongoing security breach.",
  },
  AlexsBirthdayParty: {
    heading: "Alex's Birthday Party",
    default: "A birthday gathering in honor of the company kickball team's all-star shortstop, Alex Gagnon.",
  },
  AlexGagnon: {
    heading: "Alex Gagnon",
    default: "Alex Gagnon (she/they) is an HR Administrator with Best for You Organics.",
  },
  AboutAlex: {
    heading: "About Alex",
    default: "Age: 27, Alma Mater: Villanova, Hometown: Denver, CO, Favorite food: chili fries, Pets: Golden Labrador (Izzy)",
  },
  QuinnAnderson: {
    heading: "Quinn Anderson",
    default: "Quinn Anderson (he/him) is an ID admin for Managed Identities with Best for You Organics.",
  },
  AboutQuinn: {
    heading: "About Quinn",
    default: "Age: 52, Alma Mater: Columbia College, Hometown: Philadelphia, PA, Favorite food: lasagna, Pets: tropical fish (various)",
  },
  GaelTorres: {
    heading: "Gael Torres",
    default: "Gael Torres (he/him) is the CMO for Best For You Organics.",
  },
  AboutGael: {
    heading: "About Gael",
    default: "Age: 57, Alma Mater: Missouri State University, Hometown: St. Louis, MO, Favorite food: breakfast burrito, Pets: a hedgehog named Julian",
    GaelReport: "He answers directly to CEO Abigail Jackson.",
  },
};

export const Episode01Lead: LocaleContent = {
  header: "Episode 01 Non-Lead Content",
};
