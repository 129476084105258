import { createMachine, assign, send } from 'xstate';
import { TaskConfig, TaskOptions, InteractionTaskContext, TaskEvent } from '../../../task-types';
import { addScore, getDefaultOptions } from '../../../task-options';
import { gameService } from '../../../../state/game-service';
import { store as reduxStore, getCurrentLeadId } from '../../../../store';

const initialState: InteractionTaskContext = {
  // One tracker for all attempts; will get reset. Consider changing this
  attempts: 0,
  walkthroughStep: 0,
  maxAttempts: 3,
  store: reduxStore,
};
const POINTS_VALUE = 11;
const TASK_ID = 'Game01.Episode02.Task05';

export const Task05Config: TaskConfig<InteractionTaskContext, TaskEvent> = {
  id: 'Task05',
  context: initialState,
  initial: 'A020',
  states: {
    A020: {
      // Desktop Select - 3 chances to click correctly - 2 correct answers
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
            'CLICK.PARTIALCORRECT': {
              target: 'partialcorrect',
            },
          },
        },
        incorrect: {
          always: {
            target: 'hint',
            cond: (ctx) => ctx.attempts === 3,
          },
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        hint: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        partialcorrect: {
          always: {
            target: 'hint',
            actions: 'setAttempts',
          },
        },
        final: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: '#Task05.A030',
          },
        },
      },
    },
    A030: {
      entry: ['resetContext', 'sendSimStart'],
      on: {
        'CHOOSE.SKIP': {
          target: '#Task05.A031',
        },
      },
    },
    // this will be the interstitial
    A031: {
      entry: ['resetContext', 'sendSimEnd'],
      on: {
        NEXT: {
          target: '#Task05.A035',
        },
      },
    },
    A035: {
      entry: 'resetContext',
      on: {
        NEXT: {
          target: '#Task05.A040',
        },
      },
    },
    A040: {
      entry: 'resetContext',
      on: {
        'CLICK.A050': {
          target: '#Task05.A050',
        },
        'CLICK.A060': {
          target: '#Task05.A060',
        },
      },
    },
    A050: {
      entry: 'resetContext',
      on: {
        NEXT: {
          target: '#Task05.A070',
        },
      },
    },
    A060: {
      entry: 'resetContext',
      on: {
        NEXT: {
          target: '#Task05.A070',
        },
      },
    },
    A070: {
      entry: 'resetContext',
      on: {
        NEXT: {
          target: '#Task05.A080',
        },
      },
    },
    A080: {
      // Lead Complete
      entry: [
        'resetContext',
        addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
      ],
      on: {
        NEXT: {
          target: 'A020',
        },
      },
    },
  },
};

/**
 * Additional machine options.
 */
export const Task05Options: TaskOptions<InteractionTaskContext, TaskEvent> = {
  actions: {
    resetContext: assign({ attempts: () => 0, walkthroughStep: () => 0 }),
    incrementAttempts: assign({ attempts: (context) => context.attempts + 1 }),
    incrementWalkthroughStep: assign({ walkthroughStep: (context) => context.walkthroughStep + 1 }),
    setAttempts: (context) => {
      context.attempts = 4;
    },
    sendSimStart: () => gameService.send('START.SIMULATION'),
    sendSimEnd: () => gameService.send('DONE.SIMULATION'),
    setMaxAttemptsToTwo: assign({
      attempts: (context) => context.attempts,
      walkthroughStep: (context) => context.walkthroughStep,
      maxAttempts: (context) => {
        if (context.maxAttempts) {
          context.maxAttempts -= 1;
        }
        return 2;
      },
    }),
  },

  guards: {},
};

/**
 * Machine constructor.
 */
export function createTask05() {
  return createMachine<InteractionTaskContext, TaskEvent>(Task05Config, getDefaultOptions()).withConfig(Task05Options);
}
