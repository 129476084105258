import * as React from 'react';
import background from '../images/02.09.060.png';
import { ComponentProps } from '../../../../task-types';
import MultipleChoice, { useMultipleChoice } from '../../../../../components/Global/Interactions/MultipleChoice/MultipleChoice';
import Button from '../../../../../components/Global/Button/Button';
import Modal from '../../../../../components/Game01/Modal/Modal';
import RTrans from '../../../../../components/Global/RTrans/RTrans';

export default function I060({ t, send, match }: ComponentProps): JSX.Element {
  const { errorKey, handleIncorrect } = useMultipleChoice(() => send('CLICK.INCORRECT'));

  return (
    <>
      <MultipleChoice
        questionText={t('I060.body')}
        didComplete={match('final')}
        onComplete={() => send('NEXT')}
        backgroundImage={background}
      >
        <Button
          isPrimary
          onClick={() => !match('eShopIdentity') && send('CLICK.eShopIdentity')}
          label={t('I060.labels.eShopIdentity')}
          icon={match('eShopIdentity') ? 'check' : undefined}
        />
        <Button
          isPrimary
          onClick={() => handleIncorrect('eCommerce')}
          label={t('I060.labels.eCommerce')}
          disabled={match('final')}
        />
        <Button
          isPrimary
          onClick={() => handleIncorrect('onlineshopping')}
          label={t('I060.labels.onlineshopping')}
          disabled={match('final')}
        />
        <Button
          isPrimary
          onClick={() => !match('ProjectD') && send('CLICK.ProjectD')}
          label={t('I060.labels.ProjectD')}
          icon={match('ProjectD') ? 'check' : undefined}
        />
        <Button
          isPrimary
          onClick={() => handleIncorrect('permissions')}
          label={t('I060.labels.permissions')}
          disabled={match('final')}
        />
      </MultipleChoice>
      <Modal
        sidebar="character"
        title={t(`I060.errors.${errorKey}.title`)}
        body={t(`I060.errors.${errorKey}.body`)}
        isOpen={match('incorrect')}
        footer={<Button isPrimary label={t('common.errorTitle')} onClick={() => send('NEXT')} />}
        onDismiss={() => send('NEXT')}
        hideDismissButton
      />
      <Modal
        sidebar="character"
        title={t('I060.correct.eShopIdentity.title')}
        body={<RTrans t={t} i18nKey="I060.correct.eShopIdentity.body" />}
        isOpen={match('eShopIdentity')}
        footer={<Button isPrimary label={t('common.next')} onClick={() => send('NEXT')} />}
        onDismiss={() => send('NEXT')}
        hideDismissButton
      />
      <Modal
        sidebar="character"
        title={t('I060.correct.ProjectD.title')}
        body={<RTrans t={t} i18nKey="I060.correct.ProjectD.body" />}
        isOpen={match('ProjectD')}
        footer={<Button isPrimary label={t('common.next')} onClick={() => send('NEXT')} />}
        onDismiss={() => send('NEXT')}
        hideDismissButton
      />
    </>
  );
}
