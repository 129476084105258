import React from 'react';
import { Stack } from '@fluentui/react';
import _isEqual from 'lodash/isEqual';
import { useAppSelector } from '../../../store';
import { getLeadScores } from '../../../store/game-slice';
import { Heading, P } from '../Text';

const DebugHelpContent: React.FC = () => {
  const leadScores = useAppSelector((state) => state.game.leadScores);
  const totalScore = useAppSelector((state) => getLeadScores(state.game.leadScores));
  const phaseScores = useAppSelector((state) => state.game.phaseScores, _isEqual);

  return (
    <Stack tokens={{ childrenGap: '16px', padding: '24px' }}>
      <Stack.Item>
        <Heading level={2} noMargin useDark>
          Debug Dev Help Content
        </Heading>
      </Stack.Item>
      <Stack.Item>
        <Heading level={3} block noMargin useDark>
          Score
        </Heading>
        {Object.entries(leadScores).map(([leadId, leadScoreData]) => {
          return (
            <P key={leadId} level={3} block noMargin useDark>
              <strong>{leadId}</strong>
              {': '}
              {leadScoreData && leadScoreData.score}
            </P>
          );
        })}
        {Object.entries(phaseScores).map(([phaseId, amount]) => {
          return (
            <P key={phaseId} level={3} block noMargin useDark>
              <strong>{phaseId}</strong>
              {': '}
              {amount}
            </P>
          );
        })}
        <Stack.Item>
          {Object.keys(totalScore).map((key) => (
            <P key={`${key}`} level={1} block useDark>
              {key} : {totalScore[key]}
            </P>
          ))}
        </Stack.Item>
      </Stack.Item>
    </Stack>
  );
};
export default DebugHelpContent;
