import * as React from 'react';
import background from '../images/02.03.030.png';
import { ComponentProps } from '../../../../task-types';
import MultipleChoice, { useMultipleChoice } from '../../../../../components/Global/Interactions/MultipleChoice/MultipleChoice';
import Button from '../../../../../components/Global/Button/Button';
import Modal from '../../../../../components/Game01/Modal/Modal';

export default function C040({ t, send, match }: ComponentProps): JSX.Element {
  const { errorKey, handleIncorrect } = useMultipleChoice(() => send('CLICK.INCORRECT'));

  return (
    <>
      <MultipleChoice
        questionText={t('C040.body')}
        didComplete={match('final')}
        onComplete={() => send('NEXT')}
        backgroundImage={background}
      >
        <Button
          isPrimary
          onClick={() => handleIncorrect('WebAppDeploymentSlots')}
          label={t('C040.labels.WebAppDeploymentSlots')}
          disabled={match('final')}
        />
        <Button
          isPrimary
          onClick={() => !match('correct') && send('CLICK.CORRECT')}
          label={t('C040.labels.UserAssignedManagedIdentity')}
          icon={match('correct') ? 'check' : undefined}
        />
        <Button
          isPrimary
          onClick={() => handleIncorrect('AzureStorage')}
          label={t('C040.labels.AzureStorage')}
          disabled={match('final')}
        />
        <Button
          isPrimary
          onClick={() => handleIncorrect('SQLDatabase')}
          label={t('C040.labels.SQLDatabase')}
          disabled={match('final')}
        />
        <Button
          isPrimary
          onClick={() => handleIncorrect('AzureKeyVault')}
          label={t('C040.labels.AzureKeyVault')}
          disabled={match('final')}
        />
      </MultipleChoice>
      <Modal
        sidebar="character"
        title={t(`C040.errors.${errorKey}.title`)}
        body={t(`C040.errors.${errorKey}.body`)}
        isOpen={match('incorrect')}
        footer={<Button isPrimary label={t('common.errorTitle')} onClick={() => send('NEXT')} />}
        onDismiss={() => send('NEXT')}
        hideDismissButton
      />
      <Modal
        sidebar="character"
        title={t('C040.correct.UserAssignedManagedIdentity.title')}
        body={t('C040.correct.UserAssignedManagedIdentity.body')}
        isOpen={match('correct')}
        footer={<Button isPrimary label={t('common.next')} onClick={() => send('NEXT')} />}
        onDismiss={() => send('NEXT')}
        hideDismissButton
      />
    </>
  );
}
