import * as React from 'react';
import { IStackStyles, IStackTokens, mergeStyles, Stack } from '@fluentui/react';
import { getCurrentGameTheme } from '../../../theme';

interface Props {
  isSelected?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  button?: boolean;
}

const theme = getCurrentGameTheme('Game01');

const contentStackStyles: IStackStyles = {
  root: {
    // Note: the below color needs to be fixed in the theme
    borderRadius: theme.effects.roundedCorner6,
    border: '1px solid rgba(255,255,255,.2)',
    selectors: {
      ':hover': {
        border: '1px solid white',
      },
    },
  },
};

const contentStackTokens: IStackTokens = {
  padding: '.5rem 1rem 1rem 1rem',
};

export default function PaneListItem({
  children,
  isSelected = false,
  onClick,
  disabled = false,
  button = false,
}: React.PropsWithChildren<Props>) {
  const selectedStyles = mergeStyles({
    backgroundColor: isSelected && !disabled ? 'white' : '#334F7E',
    cursor: onClick ? 'pointer' : 'inherit',
    opacity: disabled ? 0.5 : 1,
  });

  return (
    <Stack
      as={button ? 'button' : 'div'}
      horizontal
      verticalAlign="center"
      styles={contentStackStyles}
      className={selectedStyles}
      tokens={contentStackTokens}
      onClick={() => !disabled && onClick && onClick()}
    >
      <Stack.Item grow>
        <Stack tokens={{ childrenGap: 0 }}>{children}</Stack>
      </Stack.Item>
    </Stack>
  );
}
