import * as React from 'react';
import { mergeStyles, Stack } from '@fluentui/react';
import { TFunction, useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle } from '@fortawesome/pro-solid-svg-icons';
import Button from '../../Global/Button/Button';
import { P, Heading } from '../../Global/Text';
import IconButton from '../../Global/IconButton/IconButton';
import PaneListItem from '../Pane/PaneListItem';

interface Props {
  id: string;
  isSelected?: boolean;
  isComplete?: boolean;
  onApply?: (evidenceId: string, facts: string[]) => void;
  content?: string[];
  facts?: string[];
  type: string;
}

const listItemStyles = mergeStyles({
  // color: 'white',
  padding: 0,
  margin: 0,
});

const listStyles = mergeStyles({
  color: 'white',
  paddingLeft: '2rem',
  margin: 0,
  maxHeight: 0,
  opacity: 0,
  transition: 'all .2s ease',
  overflow: 'hidden',
});

const DialogIconStyles = mergeStyles({
  fontSize: '48px',
});

function checkContentEmpty(content: string[], t: TFunction) {
  let str = '';
  content.forEach((c) => {
    str += t(c);
  });
  return str === '';
}

export default function EvidenceItem({
  id,
  isSelected = false,
  isComplete = false,
  onApply,
  content = [],
  facts = [],
  type,
}: Props) {
  const { t } = useTranslation(['entries', 'common', 'game01Common']);
  const [isExpanded, setIsExpanded] = React.useState(false);
  const isCard = type === 'card';
  const emptyContent = content.length === 0 || checkContentEmpty(content, t);
  return (
    <PaneListItem>
      <Stack tokens={{ childrenGap: 0 }}>
        <Stack.Item align="end" style={{ height: 23 }}>
          {!emptyContent && (
            <IconButton
              iconName={isExpanded ? 'minus' : 'plus'}
              onClick={() => setIsExpanded((val) => !val)}
              label={t(isExpanded ? 'buttons.collapse' : 'buttons.expand')}
            />
          )}
        </Stack.Item>
        <Stack.Item style={{ paddingBottom: 16 }}>
          <Heading level={4} useDark={!isSelected} block noMargin>
            {t(`${id}.heading`)}
          </Heading>
          {!emptyContent && (
            <ul
              className={listStyles}
              style={isExpanded ? { maxHeight: 500, opacity: 1 } : { maxHeight: 0, opacity: 0 }}
            >
              {content.map((contentKey) => (
                <li key={contentKey} className={listItemStyles}>
                  <P level={3} useDark={!isSelected} block noMargin>
                    {t(contentKey)}
                  </P>
                </li>
              ))}
            </ul>
          )}
        </Stack.Item>
        <Stack.Item>
          {isComplete ? (
            <div className={`fa-layers fa-fw ${DialogIconStyles}`}>
              <FontAwesomeIcon icon={faCircle} color="white" transform="grow-2" />
              <FontAwesomeIcon icon={faCheckCircle} color="green" />
            </div>
          ) : (
            <Button
              label={t(`labels.${isCard ? 'addCard' : 'apply'}`)}
              isPrimary={!isCard}
              onClick={() => onApply?.call(undefined, id, facts)}
            />
          )}
        </Stack.Item>
      </Stack>
    </PaneListItem>
  );
}
