import { createMachine, assign } from 'xstate';
import { TaskConfig, TaskOptions, TaskEvent, BranchingTaskContext } from '../../../task-types';
import { getDefaultOptions } from '../../../task-options';

const initialState: BranchingTaskContext = {
  attempts: 0,
  walkthroughStep: 0,
  tKey: 'N01',
};
// initialzing macro definitions
// const POINTS_VALUE = 50;
// const TASK_ID = 'Game02.Episode01.Task01';

export const Task01Config: TaskConfig<BranchingTaskContext, TaskEvent> = {
  id: 'Task01',
  context: initialState,
  initial: 'N01',
  states: {
    N01: {
      initial: 'final',
      entry: assign<BranchingTaskContext, TaskEvent>({ tKey: 'N01' }),
      states: {
        final: {
          on: {
            'CLICK.BRANCH1': { target: '#Task01.N02' },
          },
        },
      },
    },
    N02: {
      initial: 'final',
      entry: assign<BranchingTaskContext, TaskEvent>({ tKey: 'N02' }),
      states: {
        final: {
          on: {
            'CLICK.BRANCH1': { target: '#Task01.N03' },
          },
        },
      },
    },
    N03: {
      initial: 'decision',
      entry: assign<BranchingTaskContext, TaskEvent>({ tKey: 'N03' }),
      states: {
        decision: {
          on: {
            'CLICK.BRANCH1': {
              target: '#Task01.N04',
            },
            'CLICK.BRANCH2': {
              target: '#Task01.N05',
            },
            'CLICK.BRANCH3': {
              target: '#Task01.N06',
            },
            'CLICK.BRANCH4': {
              target: '#Task01.N07',
            },
            'CLICK.BRANCH5': {
              target: '#Task01.N08',
            },
          },
        },
      },
    },
    N04: {
      initial: 'decision',
      entry: assign<BranchingTaskContext, TaskEvent>({ tKey: 'N04' }),
      states: {
        decision: {
          on: {
            'CLICK.BRANCH1': {
              target: '#Task01.N09',
            },
            'CLICK.BRANCH2': {
              target: '#Task01.N10',
            },
          },
        },
      },
    },
    N05: {
      initial: 'decision',
      entry: assign<BranchingTaskContext, TaskEvent>({ tKey: 'N05' }),
      states: {
        decision: {
          on: {
            'CLICK.BRANCH1': {
              target: '#Task01.N11',
            },
            'CLICK.BRANCH2': {
              target: '#Task01.N12',
            },
            'CLICK.BRANCH3': {
              target: '#Task01.N03',
            },
          },
        },
      },
    },
    N06: {
      initial: 'decision',
      entry: assign<BranchingTaskContext, TaskEvent>({ tKey: 'N06' }),
      states: {
        decision: {
          on: {
            'CLICK.BRANCH1': {
              target: '#Task01.N13',
            },
            'CLICK.BRANCH2': {
              target: '#Task01.N14',
            },
          },
        },
      },
    },
    N07: {
      initial: 'decision',
      entry: assign<BranchingTaskContext, TaskEvent>({ tKey: 'N07' }),
      states: {
        decision: {
          on: {
            'CLICK.BRANCH1': {
              target: '#Task01.N03',
            },
            'CLICK.BRANCH2': {
              target: '#Task01.Completion',
            },
          },
        },
      },
    },
    N08: {
      initial: 'decision',
      entry: assign<BranchingTaskContext, TaskEvent>({ tKey: 'N08' }),
      states: {
        decision: {
          on: {
            'CLICK.BRANCH1': {
              target: '#Task01.N03',
            },
            'CLICK.BRANCH2': {
              target: '#Task01.Completion',
            },
          },
        },
      },
    },
    N09: {
      initial: 'decision',
      entry: assign<BranchingTaskContext, TaskEvent>({ tKey: 'N09' }),
      states: {
        decision: {
          on: {
            'CLICK.BRANCH1': {
              target: '#Task01.N15',
            },
            'CLICK.BRANCH2': {
              target: '#Task01.N03',
            },
          },
        },
      },
    },
    N10: {
      initial: 'decision',
      entry: assign<BranchingTaskContext, TaskEvent>({ tKey: 'N10' }),
      states: {
        decision: {
          on: {
            'CLICK.BRANCH1': {
              target: '#Task01.N03',
            },
            'CLICK.BRANCH2': {
              target: '#Task01.Completion',
            },
          },
        },
      },
    },
    N11: {
      initial: 'decision',
      entry: assign<BranchingTaskContext, TaskEvent>({ tKey: 'N11' }),
      states: {
        decision: {
          on: {
            'CLICK.BRANCH1': {
              target: '#Task01.N16',
            },
            'CLICK.BRANCH2': {
              target: '#Task01.N03',
            },
          },
        },
      },
    },
    N12: {
      initial: 'final',
      entry: assign<BranchingTaskContext, TaskEvent>({ tKey: 'N12' }),
      states: {
        final: {
          on: {
            'CLICK.BRANCH1': { target: '#Task01.N17' },
          },
        },
      },
    },
    N13: {
      initial: 'decision',
      entry: assign<BranchingTaskContext, TaskEvent>({ tKey: 'N13' }),
      states: {
        decision: {
          on: {
            'CLICK.BRANCH1': {
              target: '#Task01.N03',
            },
            'CLICK.BRANCH2': {
              target: '#Task01.Completion',
            },
          },
        },
      },
    },
    N14: {
      initial: 'decision',
      entry: assign<BranchingTaskContext, TaskEvent>({ tKey: 'N14' }),
      states: {
        decision: {
          on: {
            'CLICK.BRANCH1': {
              target: '#Task01.N18',
            },
          },
        },
      },
    },
    N15: {
      initial: 'decision',
      entry: assign<BranchingTaskContext, TaskEvent>({ tKey: 'N15' }),
      states: {
        decision: {
          on: {
            'CLICK.BRANCH1': {
              target: '#Task01.N03',
            },
            'CLICK.BRANCH2': {
              target: '#Task01.Completion',
            },
          },
        },
      },
    },
    N16: {
      initial: 'decision',
      entry: assign<BranchingTaskContext, TaskEvent>({ tKey: 'N16' }),
      states: {
        decision: {
          on: {
            'CLICK.BRANCH1': {
              target: '#Task01.N19',
            },
          },
        },
      },
    },
    N17: {
      initial: 'decision',
      entry: assign<BranchingTaskContext, TaskEvent>({ tKey: 'N17' }),
      states: {
        decision: {
          on: {
            'CLICK.BRANCH1': {
              target: '#Task01.N03',
            },
            'CLICK.BRANCH2': {
              target: '#Task01.Completion',
            },
          },
        },
      },
    },
    N18: {
      initial: 'decision',
      entry: assign<BranchingTaskContext, TaskEvent>({ tKey: 'N18' }),
      states: {
        decision: {
          on: {
            'CLICK.BRANCH1': {
              target: '#Task01.N03',
            },
            'CLICK.BRANCH2': {
              target: '#Task01.Completion',
            },
          },
        },
      },
    },
    N19: {
      initial: 'decision',
      entry: assign<BranchingTaskContext, TaskEvent>({ tKey: 'N19' }),
      states: {
        decision: {
          on: {
            'CLICK.BRANCH1': {
              target: '#Task01.N03',
            },
            'CLICK.BRANCH2': {
              target: '#Task01.Completion',
            },
          },
        },
      },
    },
    Completion: {
      initial: 'final',
      entry: assign<BranchingTaskContext, TaskEvent>({ tKey: 'N03' }),
      states: {
        final: {
          on: {
            'CLICK.BRANCH1': {
              target: '#Task01.N04',
            },
            'CLICK.BRANCH2': {
              target: '#Task01.N05',
            },
            'CLICK.BRANCH3': {
              target: '#Task01.N06',
            },
            'CLICK.BRANCH4': {
              target: '#Task01.N07',
            },
            'CLICK.BRANCH5': {
              target: '#Task01.N08',
            },
          },
        },
      },
    },
  },
};

/**
 * Additional machine options.
 */
export const Task01Options: TaskOptions<BranchingTaskContext, TaskEvent> = {
  actions: {},
  guards: {},
};

/**
 * Machine constructor.
 */
export function createTask01() {
  return createMachine<BranchingTaskContext, TaskEvent>(Task01Config, getDefaultOptions()).withConfig(Task01Options);
}
