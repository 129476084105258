import * as React from 'react';
import { Trans } from 'react-i18next';
import Screen from '../../../../../components/Global/Screen/Screen';

import Modal from '../../../../../components/Game01/Modal/Modal';
import { ComponentProps } from '../../../../task-types';
import { useMultipleChoice } from '../../../../../components/Global/Interactions/MultipleChoice/MultipleChoice';
import Button from '../../../../../components/Global/Button/Button';
import Dialog from '../../../../../components/Global/Dialog/Dialog';

export default function A080({ t, send, match }: ComponentProps): JSX.Element {
  const { errorKey, handleIncorrect } = useMultipleChoice(() => send('CLICK.INCORRECT'));

  const contentKey = match('final') ? 'A094' : errorKey;
  return (
    <Screen>
      <Dialog
        title={t('A080.title')}
        body={t('A080.body')}
        onClick={() => {}}
        hideNext
        character="andrea"
        background="office"
      >
        <Button isPrimary onClick={() => handleIncorrect('A090')} label="" disabled={match('final')}>
          <span style={{ textAlign: 'left' }}>
            {t('A080.labels.message1.title')}
            <br />
            {t('A080.labels.message1.subtitle')}
          </span>
        </Button>
        <Button isPrimary onClick={() => handleIncorrect('A092')} label="" disabled={match('final')}>
          <span style={{ textAlign: 'left' }}>
            {t('A080.labels.message2.title')}
            <br />
            {t('A080.labels.message2.subtitle')}
          </span>
        </Button>
        <Button isPrimary onClick={() => send('NEXT')} label="">
          <span style={{ textAlign: 'left' }}>
            {t('A080.labels.message3.title')}
            <br />
            {t('A080.labels.message3.subtitle')}
          </span>
        </Button>
        <Button isPrimary onClick={() => handleIncorrect('A096')} label="" disabled={match('final')}>
          <span style={{ textAlign: 'left' }}>
            {t('A080.labels.message4.title')}
            <br />
            {t('A080.labels.message4.subtitle')}
          </span>
        </Button>
        <Button isPrimary onClick={() => handleIncorrect('A098')} label="" disabled={match('final')}>
          <span style={{ textAlign: 'left' }}>
            {t('A080.labels.message5.title')}
            <br />
            {t('A080.labels.message5.subtitle')}
          </span>
        </Button>
      </Dialog>
      <Modal
        title={t(`${contentKey}.title`)}
        body={<Trans t={t} i18nKey={`${contentKey}.body`} />}
        isOpen={match('incorrect') || match('final')}
        footer={
          <Button
            isPrimary
            label={match('final') ? t('common.next') : t('common.errorTitle')}
            onClick={() => send('NEXT')}
          />
        }
        onDismiss={() => send('NEXT')}
        hideDismissButton
      />
    </Screen>
  );
}
