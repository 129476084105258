/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { useParams } from 'react-router';
import { initAPIAndTryReload } from '../../../lib/api/init';
import LoadGate, { LoadGateOnLoadCallback } from './LoadGate';

const DevGameLoadGate: React.FC = ({ children }: React.PropsWithChildren<{}>) => {
  const routeParams: any = useParams();

  const { game_session_slug } = routeParams;
  const loadHandler = React.useCallback<LoadGateOnLoadCallback>(
    ({ done, setMessages }) => {
      initAPIAndTryReload({
        done,
        setMessages,
        onLoadParams: { event_slug: game_session_slug, isDevLoadGate: true },
      });
    }, [game_session_slug],
  );
  return (
    <LoadGate onLoad={loadHandler}>
      {children}
    </LoadGate>
  );
};

export default DevGameLoadGate;
