import React from 'react';
import { Stack } from '@fluentui/react';
import type { IStackStyles, IStackTokens } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import Screen from '../../Global/Screen/Screen';
import Panel from '../../Global/Panel/Panel';
import { P, Heading } from '../../Global/Text';
import type { LeadData } from '../../../lib/game-data/lead-data';
import LeadPreviewPane from './LeadPreviewPane';
import LeadSelectionPane from './LeadSelectionPane';
import LeadSelectionUserList from './LeadSelectionUserList';
import { selectAllAppliedLeadIds } from '../../../store/multiplayer-slice/applied-data';
import { useAppSelector } from '../../../store';
import { useWindowSize } from '../../../lib/hooks/useWindowSize';
import {
  getConsensusKeyPredefined,
  selectAllUsersConsensus,
  selectCurrentPhase,
  validateUserVote,
} from '../../../store/multiplayer-slice';
import WaitingForTeamates from '../WaitingForTeammates/WaitingForTeammates';
import { devLog } from '../../../lib/util';

export interface ILeadSelectionProps {
  phaseId?: string;
}

const contentStackStyles: IStackStyles = {
  root: {},
};
const contentStackTokens: IStackTokens = {
  childrenGap: '8px',
};

const LeadSelection: React.FC<ILeadSelectionProps> = () => {
  const { t } = useTranslation('game01Common', { keyPrefix: 'leadSelection' });
  const [leadPreview, setLeadPreview] = React.useState<LeadData | undefined>(undefined);
  const completedLeads = useAppSelector(selectAllAppliedLeadIds);
  const phaseData = useAppSelector(selectCurrentPhase);
  devLog('phase data is : ', phaseData);
  const isComplete = phaseData?.isComplete(completedLeads) || false;

  const endPhaseConsensusKey = useAppSelector((state) => getConsensusKeyPredefined(state, 'end_investigation'));
  const { localUserConsensuses } = useAppSelector(selectAllUsersConsensus);
  const userVote = localUserConsensuses.find((consensus) => consensus.key === endPhaseConsensusKey);
  const userEndedPhase = validateUserVote(userVote);

  const selectHandler = React.useCallback(
    (lead?: LeadData) => {
      if (leadPreview === lead) {
        setLeadPreview(undefined);
      } else {
        setLeadPreview(lead);
      }
    },
    [leadPreview, setLeadPreview],
  );
  const { width } = useWindowSize();

  if (userEndedPhase && userVote?.status !== 'completed') {
    return <WaitingForTeamates consensusKey={endPhaseConsensusKey} />;
  }

  return (
    <Screen>
      <Panel fullscreen useDark justify={userEndedPhase && userVote?.status !== 'completed'}>
        <Stack horizontal horizontalAlign="center" verticalAlign="stretch">
          <Stack styles={contentStackStyles} tokens={contentStackTokens}>
            <Stack horizontal horizontalAlign="space-between" wrap>
              <Stack.Item>
                <Heading level={1} block useDark noMargin>
                  {t('title')}
                </Heading>
                <P level={2} block noMargin useDark>
                  {t('body')}
                </P>
                <LeadSelectionUserList />
              </Stack.Item>
            </Stack>
            <Stack
              grow={1}
              horizontal
              tokens={{ padding: '24px 0 0 0 ', childrenGap: '28px' }}
              verticalAlign="stretch"
              wrap={width ? width < 1100 : false}
            >
              <LeadSelectionPane
                selectedLead={leadPreview}
                phase={phaseData}
                onSelect={selectHandler}
                isComplete={isComplete}
              />
              <LeadPreviewPane lead={leadPreview} isComplete={isComplete} phaseId={phaseData?.id} />
            </Stack>
          </Stack>
        </Stack>
      </Panel>
    </Screen>
  );
};

export default LeadSelection;
