import type { LocaleContent, CardOrEntryLocaleContent } from "../../types";

/**
 * i18Next `entries:Episode01.Task10` lead text content
 */
const Task10Lead: LocaleContent = {
  header: "Configure Azure AD Identity Protection",
  role: "Identity Administrator",
  time: "10-15m",
  brief: "In response to what's going on here, I need you to configure user risk and sign-in policies to automate the response to risk detections in our environment and allow users to confirm their identity when risk is detected.<br><br>-Andrea",
};

/**
 * i18Next `entries:Episode01.Task10` entry content
 */
const Task10Entries: CardOrEntryLocaleContent = {
  UserRiskPolicySettings: {
    heading: "User risk policy settings",
    default: "You updated the User Risk Policy Settings.",
    IdentityProtectionUserriskpolicyAssignmentsUsers: "Users: All users",
    IdentityProtectionUserriskpolicyAssignmentsUserrisk: "User risk: High",
    IdentityProtectionUserriskpolicyControlsAccess: "Access: Require password change",
    IdentityProtectionUserriskpolicyEnforcepolicy: "Enforce police: On",
  },
  IPSigninRiskPolicy: {
    heading: "IP Signin Risk Policy",
    default: "You updated your Sign-in risk policy.",
    IPSigninRiskPolicyAssignmentUsers: "Users: All users",
    IPSigninRiskPolicyAssignmentSignInRiskMedium: "User risk: Medium and above",
    IPSigninRiskPolicyAssignmentSignInRiskHigh: "User risk: High",
    IPSigninRiskPolicyControlsRequireMFA: "Access: Require Multi-factor authentication",
    IPSigninRiskPolicyEnforcePolicyOn: "Enforce police: On",
  },
};

export default {
  ...Task10Lead,
  ...Task10Entries,
};
