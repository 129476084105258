/**
 * i18next NS: `Episode01.Phase01Review`
 */
export default {
  dialog: {
    intro: {
      title: "I have a question for you.",
      body: "Take a look at the evidence and give me your opinion.",
    },
    final: {
      title: "Continue your investigation.",
      body: "Thanks for weighing in. Since we're all locked in the C-suite, you're our eyes and ears right now! We're relying on you to get to the bottom of this mystery ASAP. The fate of the company is in your hands!",
      nextBtn: "Go to Phase 2 set of leads",
    },
  },
  question: {
    title: "How did the file get released?",
    body: "Discuss this with your team, then select your answer.",
    optionOne: "Amari leaked it maliciously",
    optionTwo: "Amari's machine was compromised by malware",
    optionThree: "Amari's Sharepoint account was compromised",
    feedbackTitle: "No, check your evidence map.",
    feedbackBody: "Amari's machine was compromised by malware.",
    feedbackNextLabel: "Next",
  },
  proof: {
    title: "How do we know that Amari's machine was compromised?",
    body: "Discuss this with your team, then select the evidence that provides proof.",
    optionOne: "Event: patch.exe created file ShoppingList.zip",
    optionTwo: "Event: patch.exe read file curl.exe",
    optionThree: "Event: patch.exe established a connection with 20.108.242.184:443",
    feedbackTitle: "No, check your evidence map.",
    feedbackBody: "This is the relevant Event: patch.exe established a connection with 20.108.242.184:443",
    feedbackNextLabel: "Next",
  },
};
