import React from 'react';

import { useAppDispatch } from '../../store';
import { setCurrentPhase } from '../../store/multiplayer-slice';
import InsightEngine from '../../components/Game02/InsightEngine';

const InsightEngineTest: React.FC = () => {
  const dispatch = useAppDispatch();

  // Jump to Choose Lead in Episode01.Phase01
  dispatch(setCurrentPhase('Game02.Episode01.Phase01'));
  return <InsightEngine />;
};

export default InsightEngineTest;
