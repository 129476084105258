import React from 'react';

import { TFunction } from 'react-i18next';

import { ElementDataNode } from '../../../lib/game-data/element-data';
import { imageList } from '../../../static/images';
import { ImageComponentSingle } from '../../../static/images/image-types';

export interface IMapStartNodeProps {
  data: MapStartNodeData;
}

export interface MapStartNodeData {
  element: ElementDataNode;
  onFocus?: () => void;
  t: TFunction;
}

export type NodeStatusType = 'deactivated' | 'activated' | 'completed';

const nodeSizeBase = 18;

const startNodStyles = {
  minWidth: `${nodeSizeBase * 8}px`,
  maxWidth: `${nodeSizeBase * 8}px`,
  minHeight: `${nodeSizeBase * 6}px`,
  maxHeight: `${nodeSizeBase * 6}px`,
};

/**
 * Evidence Map React-Flow card component.
 */
const MapStartNode: React.FC<IMapStartNodeProps> = ({ data: { element } }) => {
  const nodeComponent = imageList.find((image) => image.name === `${element.id}.activated`) as ImageComponentSingle;
  if (!nodeComponent) return (<></>);

  return (
    <>
      <div style={startNodStyles}>
        <nodeComponent.Component width="100%" height="100%" />
      </div>
    </>
  );
};

export default MapStartNode;
