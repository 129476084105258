import React, { CSSProperties } from 'react';
import { Icon, IIconStyles, IStackItemStyles, PrimaryButton, Stack } from '@fluentui/react';
import { NavLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useBoolean } from '@fluentui/react-hooks';
import { Heading } from '../../Text';
import { EventData } from '../../../../lib/api/types';
import FacilitatorConfirmDialog from './FacilitatorConfirmDialog';
import { getCurrentGameTheme } from '../../../../theme';

const theme = getCurrentGameTheme('Global');

const sidebarStyles: IStackItemStyles = {
  root: {
    minWidth: 260,
    overflowY: 'auto',
    boxShadow: theme.effects.elevation8,
  },
};

const sidebarSection: IStackItemStyles = {
  root: {
    padding: 24,
    fontSize: 14,
    fontWeight: 'normal',
    borderBottom: 'solid 1px #eee',
    h5: {
      marginTop: 0,
    },
  },
};

function linkStyles(disabled: boolean): CSSProperties {
  return {
    marginBottom: 10,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.themePrimary,
    opacity: disabled ? 0.5 : 1,
    cursor: disabled ? 'default' : 'pointer',
  };
}

const iconStyles: IIconStyles = {
  root: {
    fontSize: 16,
    marginRight: 10,
  },
};

export interface IFacilitatorSidebar {
  event: EventData;
  onStartEvent: Function;
  onEndEvent: Function;
  isEventStarted: boolean;
  isEventStartable: boolean;
  isEventEnded: boolean;
  isMPConnected: boolean;
}

const showConfirm = (toggle: Function) => {
  toggle();
};

const FacilitatorSidebar: React.FC<IFacilitatorSidebar> = ({
  event,
  onStartEvent,
  onEndEvent,
  isEventStartable,
  isMPConnected,
  isEventStarted,
  isEventEnded,
}) => {
  const { t } = useTranslation('common');
  const color = theme.palette.themePrimary;
  const routeParams: any = useParams();
  const { facilitator_slug } = routeParams;
  const [showStartDialog, { toggle: toggleStartDialog }] = useBoolean(false);
  const [showEndDialog, { toggle: toggleEndDialog }] = useBoolean(false);

  const checkStart = (e: any) => {
    if (!isEventStarted) {
      e.preventDefault();
    }
  };

  return (
    <>
      <Stack.Item styles={sidebarStyles}>
        <Stack styles={sidebarSection}>
          <NavLink
            style={linkStyles(false)}
            activeStyle={{ fontWeight: 'bold' }}
            exact
            to={`/facilitator/${facilitator_slug}`}
          >
            <Icon iconName="ViewDashboard" styles={iconStyles} /> Dashboard
          </NavLink>
          <NavLink
            style={linkStyles(!isEventStarted)}
            activeStyle={{ fontWeight: 'bold' }}
            exact
            to={`/facilitator/${facilitator_slug}/sessions`}
            onClick={checkStart}
          >
            <Icon iconName="Clock" styles={iconStyles} /> Sessions
          </NavLink>
        </Stack>
        <Stack styles={sidebarSection}>
          <Heading level={5} color={color}>
            {t('facilitator.eventInfo')}
          </Heading>
          <p>
            {isEventStartable && (
              <Icon iconName="CircleFill" style={{ marginRight: 5, verticalAlign: 'top', color: '#ddd' }} />
            )}
            {isEventStarted && !isEventEnded && (
              <Icon iconName="CircleFill" style={{ marginRight: 5, verticalAlign: 'top', color: '#45b847' }} />
            )}
            {isEventEnded && (
              <Icon iconName="CircleFill" style={{ marginRight: 5, verticalAlign: 'top', color: '#ee3a3a' }} />
            )}
            {`Status: ${event.status && t(event.status)}`}
          </p>
          <p>
            Start Date:
            <br />
            {`${event.start_date && format(new Date(event.start_date), 'MMMM dd, yyyy @ h:mmaaa')}`}
          </p>
          <p>
            End Date:
            <br />
            {`${event.end_date && format(new Date(event.end_date), 'MMMM dd, yyyy @ h:mmaaa')}`}
          </p>

          <Stack style={{ padding: '0 20px' }}>
            <PrimaryButton
              onClick={() => showConfirm(toggleStartDialog)}
              disabled={!isEventStartable || !isMPConnected}
              text={t('facilitator.startEvent')}
              style={{ marginBottom: 10 }}
            />
            <PrimaryButton
              onClick={() => showConfirm(toggleEndDialog)}
              disabled={isEventEnded || !isEventStarted || !isMPConnected}
              text={t('facilitator.endEvent')}
            />
          </Stack>
        </Stack>
      </Stack.Item>
      {showStartDialog && (
        <FacilitatorConfirmDialog
          title={t('facilitator.startEvent')}
          subText={t('facilitator.startEventDialogText')}
          matchText="start event"
          showDialog={showStartDialog}
          onDismiss={() => toggleStartDialog()}
          onConfirm={() => {
            onStartEvent();
            toggleStartDialog();
          }}
        />
      )}
      {showEndDialog && (
        <FacilitatorConfirmDialog
          title={t('facilitator.endEvent')}
          subText={t('facilitator.endEventDialogText')}
          matchText="end event"
          showDialog={showEndDialog}
          onDismiss={() => toggleEndDialog()}
          onConfirm={() => {
            onEndEvent();
            toggleEndDialog();
          }}
        />
      )}
    </>
  );
};

export default FacilitatorSidebar;
