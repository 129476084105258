import { UserInfo } from './socket/schemas';

export function getUserDisplayName(user: UserInfo | string) {
  if (typeof user === 'string') {
    return user;
  }
  if (user.display_name != null && user.display_name.length > 0) {
    return user.display_name;
  }
  return user.username;
}

export function getUserInitials(user: UserInfo | string) {
  const displayName = typeof user === 'string' ? user : getUserDisplayName(user);
  return displayName
    .split(/[\s-]+/)
    .map((s) => s[0])
    .slice(0, 4)
    .join('')
    .toLocaleUpperCase();
}
