import { createMachine, assign, send } from 'xstate';
import { TaskConfig, TaskOptions, InteractionTaskContext, TaskEvent } from '../../../task-types';
import { addScore, getDefaultOptions } from '../../../task-options';
import { gameService } from '../../../../state/game-service';
import { store as reduxStore, getCurrentLeadId } from '../../../../store';

const initialState: InteractionTaskContext = {
  // One tracker for all attempts; will get reset. Consider changing this
  attempts: 0,
  walkthroughStep: 0,
  maxAttempts: 3,
  store: reduxStore,
};
const POINTS_VALUE = 4.5;
const TASK_ID = 'Game01.Episode01.Task06';

export const Task06Config: TaskConfig<InteractionTaskContext, TaskEvent> = {
  id: 'Task06',
  context: initialState,
  entry: 'resetContext',
  initial: 'A015',
  states: {
    A015: {
      // Desktop Select - 3 chances to click correctly
      initial: 'decision',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          always: {
            target: 'hint',
            cond: (ctx) => ctx.attempts === 3,
          },
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        hint: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: '#Task06.A020',
          },
        },
      },
    },
    A020: {
      initial: 'simulation',
      entry: ['resetContext', 'sendSimStart'],
      states: {
        simulation: {
        },
      },
    },
  },
};

/**
 * Additional machine options.
 */
export const Task06Options: TaskOptions<InteractionTaskContext, TaskEvent> = {
  actions: {
    resetContext: assign({ attempts: () => 0, walkthroughStep: () => 0 }),
    incrementAttempts: assign({ attempts: (context) => context.attempts + 1 }),
    incrementWalkthroughStep: assign({ walkthroughStep: (context) => context.walkthroughStep + 1 }),
    sendSimStart: () => gameService.send('START.SIMULATION'),
    setMaxAttemptsToTwo: assign({
      attempts: (context) => context.attempts,
      walkthroughStep: (context) => context.walkthroughStep,
      maxAttempts: (context) => {
        if (context.maxAttempts) {
          context.maxAttempts -= 1;
        }
        return 2;
      },
    }),
  },
  guards: {},
};

/**
 * Machine constructor.
 */
export function createTask06() {
  return createMachine<InteractionTaskContext, TaskEvent>(Task06Config, getDefaultOptions()).withConfig(Task06Options);
}
