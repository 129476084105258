/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { useDispatch } from 'react-redux';
import { IconButton, IStackStyles, Stack } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { Heading } from '../../../Text';
import { SessionGroup, UserInfo } from '../../schemas';
import { toggleActiveSession } from '../../../../../store/facilitator-slice';
import { MPManager } from '../../../../../lib/socket';
import SessionChatDisplay from './SessionChatDisplay';
import SessionChatSubmit from './SessionChatSubmit';
import { getCurrentGameTheme } from '../../../../../theme';

const theme = getCurrentGameTheme('Global');

interface ISessionChatProps {
  users: UserInfo[];
  session: SessionGroup;
}

const panelStyles: IStackStyles = {
  root: {
    marginLeft: '24px !important',
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    borderRadius: 5,
    boxShadow: theme.effects.elevation8,
    minWidth: 400,
    width: '30%',
    h4: {
      marginTop: 0,
      marginBottom: 12,
    },
  },
};

const headerStyles: IStackStyles = {
  root: {
    padding: '12px 16px',
    boxShadow: '0 6px 3px -3px rgba(0,0,0,0.1)',
    position: 'relative',
  },
};

const SessionChat: React.FC<ISessionChatProps> = ({ users = [], session }) => {
  const { t } = useTranslation('common');
  const color = theme.palette.themePrimary;
  const dispatch = useDispatch();

  const socket = MPManager.instance.setupSocket(process.env.REACT_APP_SOCKET_URL);

  const onCloseChat = () => {
    dispatch(toggleActiveSession(session));
  };

  return (
    <Stack grow styles={panelStyles}>
      <Stack.Item styles={headerStyles}>
        <Stack horizontal style={{ justifyContent: 'space-between' }}>
          <Stack.Item>
            <Heading level={4} color={color}>
              {session.name} {t('facilitator.chat')}
            </Heading>
          </Stack.Item>
          <Stack.Item>
            <IconButton iconProps={{ iconName: 'Cancel' }} onClick={() => onCloseChat()} />
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <SessionChatDisplay users={users} socket={socket} slug={session.slug} />
      <SessionChatSubmit socket={socket} slug={session.slug} />
    </Stack>
  );
};

export default SessionChat;
