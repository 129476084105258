/**
 * i18next NS: `Episode02.Phase01Review`
 */
export default {
  dialog: {
    intro: {
      title: "I have a question for you.",
      body: "Take a look at the evidence and let me know what you think. Make sure you have the proof to back it up!",
    },
    final: {
      title: "Continue your investigation.",
      body: "Abigail is taking entire C-suite out for lunch with the journalist now. What that means for you is you won't have anyone snooping around for a couple of hours. But I'm expecting some real progress in your investigation by the time we get back! The pressure is on to put this to bed by the end of the day. Don't let me down!",
      nextBtn: "Go to Phase 2 set of leads",
    },
  },
  question1: {
    title: "Who instigated the attack?",
    body: "Discuss this with your team, then select your answer.",
    optionOne: "Wenny Gultom",
    optionTwo: "The creator of eShopIdentity",
    optionThree: "We don't know yet",
    feedbackTitle: "No, check your evidence map.",
    feedbackBody: "We don't have enough information to solve this mystery yet.",
    feedbackNextLabel: "Next",
  },
  proof: {
    title: "What GitHub file was compromised?",
    body: "Discuss this with your team, then select your answer.",
    optionOne: "Web.config",
    optionTwo: "Default.aspx",
    optionThree: "Default.aspx.cs",
    optionFour: "ProjectD.csproj",
    feedbackTitle: "No, check your evidence map.",
    feedbackBody: "Default.aspx.cs contained malicious shell code.",
    feedbackNextLabel: "Next",
  },
  question2: {
    title: "Which resource was compromised?",
    body: "Discuss this with your team, then select your answer.",
    optionOne: "ProjectD-BFYO",
    optionTwo: "ProjectDstore",
    optionThree: "BFYOecommerce",
    optionFour: "BFYO-finance",
    feedbackTitle: "No, check your evidence map.",
    feedbackBody: "The compromised resource is ProjectD-BFYO.",
    feedbackNextLabel: "Next",
  },
};
