import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';
import { StaticContext } from 'react-router';
import HomeScreen from '../HomeScreen/HomeScreen';
import DebugScreen from '../DebugScreen/DebugScreen';
import { isDev, isStaging } from '../../../lib/util';
import { taskLinks } from '../../../tasks';
import { testLinks } from '../../../tests';

/**
 * TODO: Currently this component is doing a lot for debug and dev purposes (creating routes etc)
 * I've sectioned it off so that we can build a version of the game component that ties into the Event component
 */

function generateRoute({
  path,
  component,
  exact = false,
}: {
  path: string;
  component?: React.ComponentType<any> | React.ComponentType<RouteComponentProps<any, StaticContext, unknown>>;
  exact: boolean;
}) {
  return <Route exact={exact} path={path} component={component} key={path} />;
}

const DevGameRoutes: React.FC = () => {
  /**
   * Set the root / route path based on isDev
   */
  const rootPath = () => {
    if (isDev || isStaging) {
      return <HomeScreen />;
    }
    return null;
  };

  return (
    <Switch>
      {generateRoute({
        exact: true,
        path: '/debug',
        component: DebugScreen,
      })}
      {generateRoute({
        exact: true,
        path: '/home',
        component: HomeScreen,
      })}

      {taskLinks.map((link) =>
        generateRoute({
          path: link.url,
          exact: true,
          component: link.Component,
        }),
      )}
      {testLinks.map((link) =>
        generateRoute({
          path: link.url,
          exact: true,
          component: link.Component,
        }),
      )}
      <Route path="/">{rootPath}</Route>
    </Switch>
  );
};

export default DevGameRoutes;
