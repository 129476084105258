import Circuit from './Circuit';
import Storage from './Storage';
import Model from './Model';
import Dashboard from './Dashboard';

/**
 * Episode01: All task components in a single namespace.
 */

export default {
  ...Circuit,
  ...Storage,
  ...Model,
  ...Dashboard,
};
