import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { useAppDispatch } from '../../store';
import { useGameService } from '../../state/GlobalStateProvider';
import { GameContext, GameEvent } from '../../state/game-service';
import { getMatcherResults, StateMatcher } from '../../lib/xstate/matchers';
import ConclusionRouter from '../../components/Global/ConclusionRouter/ConclusionRouter';
import { setCurrentPhase } from '../../store/multiplayer-slice';
import Summary from '../../components/Game01/Summary/Summary';

const Game01Episode01Conclusion: React.FC = () => {
  const dispatch = useAppDispatch();
  const { state, setState } = useGameService();

  // Jump to Choose Lead in Episode01.Phase01
  React.useEffect(() => {
    dispatch(setCurrentPhase('Game01.Episode01.Phase01'));
    setState('conclusion', {});
  }, [dispatch, setState]);

  // Generate the list of content to match on once.
  // (Add dependencies for regeneration if necessary later)
  const stateMatchers = React.useMemo<StateMatcher<GameContext, GameEvent>[]>(
    () => [
      {
        states: 'conclusion',
        content: <ConclusionRouter />,
      },
      {
        states: 'summary',
        final: true,
        content: <Summary />,
      },
    ],
    [],
  );

  // Generate matched content to display every time the state or matchers change.
  const matchedContent = React.useMemo(
    () =>
      getMatcherResults(state, stateMatchers, {
        contentWrapper: (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            // Note: The inline style below ensures activity backgrounds fill the entire viewport and panels connect to the bottom of the screen
            style={{ height: '100%' }}
          />
        ),
        resultsWrapper: <AnimatePresence exitBeforeEnter />,
      }),
    [state, stateMatchers],
  );

  return <>{matchedContent}</>;
};

export default Game01Episode01Conclusion;
