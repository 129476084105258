import * as React from 'react';
import Modal from '../../../../../components/Game01/Modal/Modal';
// import background from '../images/05A.015.png';
import Button from '../../../../../components/Global/Button/Button';
import Screen from '../../../../../components/Global/Screen/Screen';
import { ComponentProps } from '../../../../task-types';
import SingleSelect from '../../../../../components/Global/Interactions/SingleSelect/SingleSelect';

export default function A040({ t, send, match }: ComponentProps): JSX.Element {
  const isFeedback = match('feedback');

  const handleSubmit = (isCorrect: boolean) => {
    if (isCorrect || isFeedback) {
      send('NEXT');
    } else {
      send('CLICK.SUBMIT_INCORRECT');
    }
  };

  const selectOptions = [
    {
      key: 1,
      text: t('A040.options.1.label'),
    },
    {
      key: 2,
      text: t('A040.options.2.label'),
      isCorrect: true,
    },
    {
      key: 3,
      text: t('A040.options.3.label'),
    },
    {
      key: 4,
      text: t('A040.options.4.label'),
    },
  ];

  return (
    <Screen>
      <SingleSelect
        title={isFeedback ? t('A040.feedback.title') : t('A040.title')}
        subtitle={isFeedback ? t('A040.feedback.subtitle') : t('A040.subtitle')}
        body={isFeedback ? t('A040.feedback.body') : t('A040.body')}
        onSubmit={handleSubmit}
        nextLabel={isFeedback ? t('common.ok') : t('common.done')}
        options={selectOptions}
        showAnswer={isFeedback}
        useDark
      />
      <Modal
        sidebar="error"
        isOpen={match('incorrect')}
        footer={<Button isPrimary label={t('common.errorTitle')} onClick={() => send('NEXT')} />}
        title={t('A040.error.title')}
        body={t('A040.error.body')}
      />
    </Screen>
  );
}
