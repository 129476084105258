/**
 * i18next NS: `Episode02.Phase04Review`
 */
export default {
  dialog: {
    intro: {
      title: "I have a question for you.",
      body: "Take a look at the evidence and let me know what you think. Make sure you have the proof to back it up!",
    },
  },
};
