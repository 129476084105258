import { DefaultButton, IStackItemStyles, IStackStyles, ITheme, Stack, useTheme } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { MPManager } from '../../../lib/socket';
import { Heading, P } from '../Text';
import Background from '../../../static/images/Game01/Episode01/backgrounds/screen-background-dark.jpg';
import Andrea from '../../../static/images/Game01/Episode01/characters/andrea/Episode_1_Andrea.png';
import { APIManager } from '../../../lib/api/APIManager';
import { EventData } from '../../../lib/api/types';
import { useGameService } from '../../../state/GlobalStateProvider';
import { coerceArray } from '../../../lib/util';
import Div from '../Text/Div';

function outerStyles(theme: ITheme): IStackStyles {
  return {
    root: {
      backgroundColor: theme.palette.blueMid,
      backgroundImage: `url(${Background})`,
      backgroundSize: 'cover',
      color: theme.palette.themePrimary,
      height: '100vh',
      maxWidth: '100%',
      overflowY: 'auto',
    },
  };
}

function innerStyles(): IStackStyles {
  return {
    root: {
      width: '100%',
      height: '100%',
      margin: '0 auto',
      alignItems: 'start',
      justifyContent: 'space-evenly',
      boxSizing: 'border-box',
    },
  };
}

function leftStyles(theme: ITheme): IStackItemStyles {
  return {
    root: {
      backgroundColor: theme.palette.white,
      padding: 30,
      borderRadius: theme.effects.roundedCorner4,
      width: '40%',
      marginTop: 100,
      marginLeft: '10%',
      boxSizing: 'border-box',
    },
  };
}

function rightStyles(): IStackItemStyles {
  return {
    root: {
      height: '100%',
      minHeight: 500,
      width: '50%',
      overflow: 'hidden',
      marginLeft: '-50%',
      img: {
        display: 'block',
        height: '100%',
      },
    },
  };
}

const baseEventState: EventData = {
  status: '',
  catalog_code: null,
  primary_language: null,
  start_date: null,
  end_date: null,
  date_started: null,
  date_ended: null,
  total_users: 0,
  facilitators: [],
  users: [],
  sessions: [],
};

const EventLobby: React.FC = () => {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const socket = MPManager.instance.setupSocket(process.env.REACT_APP_SOCKET_URL);
  const [event, setEvent] = useState(baseEventState);
  const [playersJoined, setPlayersJoined] = useState(1);
  const [enterEvent, setEnterEvent] = useState(false);
  const { send } = useGameService();
  const { username, event_slug } = APIManager.getConfig();

  useEffect(() => {
    // Get event data
    socket.emit('data:event', { username, slug: event_slug });
    const onDataEvent = (data: any) => {
      setEvent(data);
      if (data.status === 'started') {
        setEnterEvent(true);
      }
    };
    socket.on('data:event', onDataEvent);

    // Get initial room status
    socket.emit('status:room', { room: `event:${event_slug}` }, (room: any) => {
      if (room.status === 'success') {
        setPlayersJoined((prevTotal) => room.data.users.length || prevTotal);
      }
    });
    // Get total joined whenever another player joins or leaves room
    const onStatusRoom = (data: any) => {
      if (data.action === 'joined' || data.action === 'left') {
        socket.emit('status:room', { room: `event:${event_slug}` }, (room: any) => {
          if (room.status === 'success') {
            setPlayersJoined((prevTotal) => room.data.users.length || prevTotal);
          }
        });
      }
    };
    socket.on('status:room', onStatusRoom);

    // Listen for event start
    const onStatusEvent = (data: any) => {
      if (data.action === 'start') {
        setEnterEvent(true);
      }
    };
    socket.on('status:event', onStatusEvent);

    return () => {
      socket.off('data:event', onDataEvent);
      socket.off('status:room', onStatusRoom);
      socket.off('status:event', onStatusEvent);
    };
  }, [event_slug, socket, username]);

  return (
    <Stack styles={outerStyles(theme)}>
      <Stack horizontal styles={innerStyles()}>
        {!enterEvent && (
          <Stack.Item styles={leftStyles(theme)}>
            <Heading level={2} as={1} block noMargin>
              {t('event.lobby')}
            </Heading>
            <P level={3} block>
              <Trans t={t} i18nKey="event.pleaseWait" />
            </P>
            <P level={3} block>
              <Trans t={t} i18nKey="event.pleaseWait2" />
            </P>
            <Div level={3} block>
              <ol>
                {coerceArray<string>(t('event.prep.items', { returnObjects: true })).map((item, i) => (
                  <li key={`lobby_item_li_${i}`} style={{ padding: '0 0 12px' }}>
                    {item}
                  </li>
                ))}
              </ol>
            </Div>
            <P level={3} block>
              <Trans t={t} i18nKey="event.lobbyThankYou" />
            </P>
            <P level={3} block>
              <em>{t('event.startTime')}</em>{' '}
              {`${event.start_date && format(new Date(event.start_date), 'MMMM dd, yyyy @ h:mmaaa')}`}
            </P>
            <P level={3} block>
              <em>{t('event.playersJoined')}</em> {`${playersJoined}`} of {`${event.total_users}`}
            </P>
          </Stack.Item>
        )}
        {enterEvent && (
          <Stack.Item styles={leftStyles(theme)}>
            <Heading level={2} as={1} block noMargin>
              {t('event.enterEvent')}
            </Heading>
            <P level={3} block>
              {t('event.eventStarted')}
            </P>
            <DefaultButton
              onClick={() => send({ type: 'START.SESSION' })}
              text={t('event.enterEvent')}
              style={{ float: 'right' }}
            />
          </Stack.Item>
        )}
        <Stack.Item styles={rightStyles()}>
          <img src={Andrea} alt="" />
        </Stack.Item>
      </Stack>
    </Stack>
  );
};

export default EventLobby;
