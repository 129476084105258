import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTaskMachine } from '../../../../lib/hooks/useTaskMachine';
import I020 from './components/I020';
import I030 from './components/I030';
import I040 from './components/I040';
import I045 from './components/I045';
import I050 from './components/I050';
import I055 from './components/I055';
import I060 from './components/I060';
import I080 from './components/I080';
import I090 from './components/I090';
import I100 from './components/I100';
import I110 from './components/I110';
import { createTask09 } from './machine';

const baseNS = 'Game01.Episode02.Task09';
const taskKey = 'Game01.Episode02.Task09';

function createTaskMatch(parent: string, state: any) {
  return (matchState: string) => state.matches(`${parent}.${matchState}`);
}

const Task09: React.FC = () => {
  const { t } = useTranslation([baseNS, 'common']);

  // Setup task machine
  const [state, send] = useTaskMachine(createTask09, {
    taskKey,
  });

  return (
    <>
      {state.matches('I020') && <I020 t={t} send={send} state={state.context} match={createTaskMatch('I020', state)} />}
      {state.matches('I030') && <I030 t={t} send={send} state={state.context} match={createTaskMatch('I030', state)} />}
      {state.matches('I040') && <I040 t={t} send={send} state={state.context} match={createTaskMatch('I040', state)} />}
      {state.matches('I045') && <I045 t={t} send={send} state={state.context} match={createTaskMatch('I045', state)} />}
      {state.matches('I050') && <I050 t={t} send={send} state={state.context} match={createTaskMatch('I050', state)} />}
      {state.matches('I055') && <I055 t={t} send={send} state={state.context} match={createTaskMatch('I055', state)} />}
      {state.matches('I060') && <I060 t={t} send={send} state={state.context} match={createTaskMatch('I060', state)} />}
      {state.matches('I080') && <I080 t={t} send={send} state={state.context} match={createTaskMatch('I080', state)} />}
      {state.matches('I090') && <I090 t={t} send={send} state={state.context} match={createTaskMatch('I090', state)} />}
      {state.matches('I100') && <I100 t={t} send={send} state={state.context} match={createTaskMatch('I100', state)} />}
      {state.matches('I110') && <I110 t={t} send={send} state={state.context} match={createTaskMatch('I110', state)} />}
    </>
  );
};

export default Task09;
