import Task01 from "./Task01";
import Task02 from "./Task02";
import Task05 from "./Task05";
import Task07 from "./Task07";
// import Phase01Review from "./Phase01Review";
import Phase02Review from "./Phase02Review";

export default {
  "Game02.Episode01.Task01": Task01,
  "Game02.Episode01.Task02": Task02,
  "Game02.Episode01.Task05": Task05,
  "Game02.Episode01.Task07": Task07,
  // "Game02.Episode01.Phase01Review": Phase01Review,
  "Game02.Episode01.Phase02Review": Phase02Review,
};
