import React from 'react';
import { format } from 'date-fns';
import { IStackItemStyles, Stack } from '@fluentui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faCheckCircle, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { LinkItUrl } from 'react-linkify-it';
import { P } from '../Text';
import { ClientChatMessage, UserInfo } from '../../../lib/socket/schemas';
import { getCurrentGameTheme } from '../../../theme';

export interface IChatMessageProps {
  user?: UserInfo;
  message: ClientChatMessage;
}

const theme = getCurrentGameTheme();

function generateIcon(info_type: any) {
  switch (info_type) {
    case 'info':
      return <FontAwesomeIcon icon={faInfoCircle} color={theme.palette.themeSecondary} />;
    case 'success':
      return <FontAwesomeIcon icon={faCheckCircle} color={theme.palette.teal} />;
    case 'error':
      return <FontAwesomeIcon icon={faTimesCircle} color={theme.palette.red} />;
    default:
      return <FontAwesomeIcon icon={faInfoCircle} color={theme.palette.themeSecondary} />;
  }
}

function messageStyles(user_type: any, message_type: any): IStackItemStyles {
  let backgroundColor = 'inherit';
  if (user_type === 'facilitator') {
    if (message_type === 'message') {
      backgroundColor = theme.palette.themeTertiary;
    }
    if (message_type === 'broadcast') {
      backgroundColor = theme.palette.orangeLighter;
    }
  }
  return (
    {
      root: {
        padding: '8px 12px 10px',
        marginBottom: 10,
        borderRadius: 6,
        backgroundColor,
        overflowWrap: 'anywhere',
      },
    }
  );
}

const ChatMessage: React.FC<IChatMessageProps> = ({ user, message }) => {
  const { t } = useTranslation('common');
  let name = user?.display_name || user?.username;
  if (message.user_type === 'facilitator') {
    name = 'Facilitator';
  }
  return (
    <Stack styles={messageStyles(message.user_type, message.message_type)}>
      {(message.message_type === 'info') && (
        <P level={5} noMargin color="#444" center>
          {generateIcon(message.info_type)} <b>{name}</b> {message.message}
        </P>
      )}
      {(message.message_type !== 'info') && (
        <>
          {(message.message_type === 'broadcast') && (
            <P level={5} noMargin color="#333">
              {generateIcon(message.info_type)} {t('chat.broadcastInfo')}
            </P>
          )}
          <Stack horizontal>
            <Stack.Item>
              <P level={4} noMargin color="#444">
                <b>{name}</b>
              </P>
            </Stack.Item>
            <Stack.Item style={{ marginLeft: 10 }}>
              <P level={4} noMargin color="#444">
                {message.time && format(new Date(message.time), 'MMM dd, h:mmaaa')}
              </P>
            </Stack.Item>
          </Stack>
          <Stack.Item>
            <P level={3} noMargin>
              <LinkItUrl>
                {message.message}
              </LinkItUrl>
            </P>
          </Stack.Item>
        </>
      )}
    </Stack>
  );
};
export default ChatMessage;
