import React from 'react';
import { Stack } from '@fluentui/react';
import Help from '../Help/Help';
import Policies from '../../Game01/Policies/Policies';
import Settings from '../Settings/Settings';
import Chat from '../Chat/Chat';
import { useGameServiceSelector } from '../../../state/GlobalStateProvider';
// import LeadJournal from '../../Game01/LeadJournal/LeadJournal';
import NavBar from '../NavBar/NavBar';
// import Notebook from '../../Game02/Notebook/Notebook';
import JournalRouter from '../JournalRouter/JournalRouter';

const GameSidebar: React.FC = () => {
  const hideSidebar = useGameServiceSelector((state) => {
    return state.matches('tutorial') || state.matches('summary') || state.matches('lobby') || state.matches('devHome');
  });
  return hideSidebar ? null : (
    <Stack.Item grow={0} disableShrink>
      <NavBar />
      <JournalRouter />
      <Help />
      <Policies />
      <Chat />
      <Settings />
    </Stack.Item>
  );
};

export default GameSidebar;
