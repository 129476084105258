import { Memoize } from 'typescript-memoize';
import { devLogWarn } from '../util';
import type { GameDataStore } from './game-data-store';
import type { GameDataDefinitionBase, GameDataInstance, GameDataInstanceOf, GameDataKind } from './types';

/**
 * Abstract base class for static game data items.
 */
export abstract class GameDataBase {
  /**
   * Unique kind for the given class.
   *
   * NOTE: This can be used to discriminate unions of different GameDataBase types.
   */
  abstract readonly kind: GameDataKind;

  /** The fully-qualified ID of the data instance. */
  readonly id: string;

  /** The short ID of the data instance (without any parent path). */
  readonly shortId: string;

  /** Full identity path of the data instance. */
  readonly path: readonly string[];

  /** The parent of the data instance */
  readonly parent: GameDataInstance | undefined;

  /** The store that the instance is loaded into. */
  readonly store: GameDataStore | undefined;

  /** Get all child data instances of this instance. */
  abstract getChildren(): readonly GameDataInstance[];
  abstract getChildren<TKind extends GameDataKind | undefined>(filter?: TKind): readonly GameDataInstanceOf<TKind>[];

  constructor(data: GameDataDefinitionBase, parent?: GameDataInstance) {
    const id = GameDataBase.sanitizeId(data.id);
    this.parent = parent;

    if (this.parent != null) {
      this.path = Object.freeze([...this.parent.path, id]);
    } else {
      this.path = Object.freeze([id]);
    }

    this.shortId = this.path[this.path.length - 1];
    this.id = this.path.join('.');
  }

  /** Check the specific GameDataInstance type by `kind` value (TS type predicate). */
  isKind<TKind extends GameDataKind>(kind: TKind): this is GameDataInstanceOf<TKind> {
    return this.kind === kind;
  }

  /**
   * Search for a data instance.
   *   - Accepts queries as paths or strings.
   *   - Supports relative paths by leading the query with 1 or more `../` or ['..'] segments.
   *   - Supports forcing root-level lookup by prefixing first segment with `#`.
   *   - Supports filtering result by data kind.
   *
   * @todo This is a bit bloated and needs a clean-up pass at some point.
   */
  find<TKind extends GameDataKind | undefined>(
    query: string | readonly string[] | null | undefined,
    filter?: TKind,
  ): GameDataInstanceOf<TKind> | undefined;
  /** Implementation signature. */
  find(input?: string | readonly string[] | null | undefined, filter?: GameDataKind): GameDataInstance | undefined {
    // Coerce query into path and get any relative path up amount.
    const [queryPath, relDepth] = GameDataBase.resolveQueryPath(input);
    // devLog('GameDataBase.find()', { queryPath, relDepth, filter, context: this });

    //
    // Handle absolute search
    //
    if (relDepth === -Infinity) {
      return this.getRoot().find(queryPath, filter);
    }

    //
    // Handle relative search
    //
    if (relDepth < 0) {
      let newBase: GameDataInstance = this as GameDataInstance;
      let relDepthRemaining = relDepth;
      // Try to move up as many times as '..' segments.
      while (relDepthRemaining < 0) {
        if (newBase.parent != null) {
          newBase = newBase.parent;
          relDepthRemaining += 1;
        } else {
          // Can't go above root, so break here.
          break;
        }
      }

      // If we fully resolved the relative path...
      if (relDepthRemaining === 0) {
        // Recursively complete the search
        if (queryPath.length > 0) {
          return newBase.find(queryPath, filter);
        }

        // Return the base if it matches the filter.
        return filter == null || filter === newBase.kind ? newBase : undefined;
      }

      // If we have one more step to go, a store, and a queryPath, search the store.
      if (relDepthRemaining === -1 && newBase.store != null && queryPath.length > 0) {
        return newBase.store.find(queryPath, filter);
      }

      // All avenues have been exhaused, so return undefined.
      return undefined;
    }

    // Validate queryPath and split into head/tail.
    if (!GameDataBase.validatePath(queryPath)) {
      return undefined;
    }
    const [queryHead, ...queryTail] = queryPath;

    //
    // Search in children.
    //
    const children = this.getChildren();
    for (let i = 0; i < children.length; i++) {
      const currentChild = children[i];

      // Skip loop if we're on the wrong track.
      if (queryHead !== currentChild.shortId) {
        // eslint-disable-next-line no-continue
        continue;
      }

      // If at end of query, return result if filter matches.
      if (queryTail.length === 0) {
        return filter == null || filter === currentChild.kind ? currentChild : undefined;
      }

      // (Filter) Recurse search.
      return currentChild.find(queryTail, filter);
    }

    return undefined;
  }

  /** Get root-most parent (or associated GameDataStore) */
  getRoot() {
    if (this.store != null) {
      return this.store;
    }

    let root = this as GameDataInstance;
    while (root.parent != null) {
      root = root.parent;
    }
    return root;
  }

  /**
   * Recursively get all children of this instance, with optional filter.
   */
  getAllChildren(): readonly GameDataInstance[];
  getAllChildren<TKind extends GameDataKind | undefined>(filter?: TKind): readonly GameDataInstanceOf<TKind>[];
  @Memoize()
  getAllChildren(filter?: GameDataKind): readonly GameDataInstance[] {
    // Check if this instance has any children, and bail out quickly if it doesn't.
    const localChildren = this.getChildren();
    if (localChildren.length === 0) {
      return Object.freeze([]);
    }

    // Create the result array and search queue (initially containing direct children).
    const allChildren: GameDataInstance[] = [];
    const childrenToSearch: GameDataInstance[] = [...localChildren];

    // Process the queue
    while (childrenToSearch.length > 0) {
      const currentChild = childrenToSearch.shift()!;

      // Add this child to the output if it matches the filter.
      if (filter == null || currentChild.kind === filter) {
        allChildren.push(currentChild);
      }

      // Recursively add all children to the queue (without filtering).
      childrenToSearch.push(...currentChild.getAllChildren());
    }

    return Object.freeze(allChildren);
  }

  /**
   * This RegExp splits on:
   *   - `/` which is not at the end of the string
   *   - `.` which is _not_ not followed by a `.` or `/` or end of string.
   * This is to facilitate relative paths using `../`, allow root prefix matching on `#`, etc.
   *
   * Examples of path strings that work here:
   *   - '../Sibling/Child.Childer.Childest' -> ['..', 'Sibling', 'Child', 'Childer', 'Childest']
   *   - '#RootChild/Child.Childer.Childest' -> ['#RootChild', 'Child', 'Childer', 'Childest']
   */
  static readonly fancyPathSplitRegExp = /(?:[/]|[.](?![./]))(?!$)/;

  /**
   * Match relative path-style segments such as `.`, `..`, `./`, and `../`
   * Capture `.` for testing type of path.
   */
  static readonly relativePathSegmentRegexp = /^([.]+)\/?$/;

  /**
   * Sanitize a string to use as an instance ID
   */
  static sanitizeId(id: string) {
    const newId = id
      .replace(/[\s]+/g, '') // Remove whitespace
      .replace(/^[#./]+/, '') // Remove all leading `#`, `.` and `/`
      .replace(/[#,./:;'"\\]+/g, '_'); // Replace remaining invalid characters with underscores

    return newId.length > 0 ? newId : '_EMPTY_ID_';
  }

  /**
   * Validate an instance ID
   *
   * Note: This will reject valid query characters, like "../"
   */
  static validateId(id: string) {
    // Valid IDs will:
    // * Not be empty
    // * Not contain any of `#./`, or whitespace
    return !(id.length === 0 || /[#./\s]+/.test(id));
  }

  /** Validate a path. */
  static validatePath(path: readonly string[]) {
    if (path.length === 0) {
      devLogWarn('GameDataBase.validatePath() Empty path');
      return false;
    }

    for (let i = 0; i < path.length; i++) {
      const segment = path[i];
      if (!GameDataBase.validateId(segment)) {
        devLogWarn(
          'GameDataBase.validatePath() Invalid segment ',
          `${JSON.stringify(segment)} at index ${i} of path ${JSON.stringify(path)}`,
        );
        return false;
      }
    }
    return true;
  }

  static resolveQueryPath(input?: string | readonly string[] | null): readonly [readonly string[], number] {
    // Coerce to array.
    const path = Array.isArray(input) ? input : (input ?? '').split(GameDataBase.fancyPathSplitRegExp);

    // We don't need to process empty paths.
    if (path.length === 0) {
      return [Object.freeze(path), 0] as const;
    }

    // Absolute paths can be returned clean with depth set to -Infinity.
    if (path[0].startsWith('#')) {
      const newPath = [...path];
      newPath[0] = newPath[0].replace(/^[#./]+/, '');
      return [Object.freeze(newPath), -Infinity] as const;
    }

    const newPath: string[] = [];
    let checkingBase = true;
    let depth = 0;

    path.forEach((segment) => {
      const segmentMatch = segment.match(GameDataBase.relativePathSegmentRegexp);

      // Count leading relative segments.
      if (checkingBase) {
        // Consume all relative segment matches
        if (segmentMatch != null) {
          // Only drop depth if match is exactly '..', otherwise discard.
          if (segmentMatch[1] === '..') {
            depth -= 1;
          }
        } else {
          checkingBase = false;
          newPath.push(segment);
        }
        return;
      }

      // Process non-leading segments.
      if (segmentMatch != null) {
        // Consume all relative segment matches,
        // But only remove previous segment if match is exactly '..', otherwise discard.
        if (segmentMatch[1] === '..') {
          newPath.pop();
        }
      } else {
        newPath.push(segment);
      }
    });

    return [Object.freeze(newPath), depth] as const;
  }
}
