import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTaskMachine } from '../../../../lib/hooks/useTaskMachine';
import A010 from './components/A010';
import A020 from './components/A020';
import A030 from './components/A030';
import A065 from './components/A065';
import A070 from './components/A070';
import A080 from './components/A080';
import { createTask11 } from './machine';

const baseNS = 'Game01.Episode01.Task11';
const taskKey = 'Game01.Episode01.Task11';

// Todo: Refactor this so it's less cumbersome to get a scoped match function
function createTaskMatch(parent: string, state: any) {
  return (matchState: string) => state.matches(`${parent}.${matchState}`);
}

const Task11: React.FC = () => {
  const { t } = useTranslation([baseNS, 'common']);

  // Setup task machine
  const [state, send] = useTaskMachine(createTask11, {
    taskKey,
  });

  return (
    <>
      {state.matches('A010') && <A010 t={t} send={send} state={state.context} match={createTaskMatch('A010', state)} />}
      {state.matches('A020') && <A020 t={t} send={send} state={state.context} match={createTaskMatch('A020', state)} />}
      {state.matches('A030') && <A030 t={t} send={send} state={state.context} match={createTaskMatch('A030', state)} />}
      {state.matches('A065') && <A065 t={t} send={send} state={state.context} match={createTaskMatch('A065', state)} />}
      {state.matches('A070') && <A070 t={t} send={send} state={state.context} match={createTaskMatch('A070', state)} />}
      {state.matches('A080') && <A080 t={t} send={send} state={state.context} match={createTaskMatch('A080', state)} />}
    </>
  );
};

export default Task11;
