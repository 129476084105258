import { Memoize } from 'typescript-memoize';
import { GameDataBase } from './game-data-base';

import type { GameDataInstance, GameDefinition } from './types';
import { EpisodeData } from './episode-data';

/**
 * Episode Data
 */
export class GameData extends GameDataBase {
  readonly kind = 'game';

  readonly episodes: EpisodeData[] = [];

  constructor(data: GameDefinition, parent?: GameDataInstance) {
    super(data, parent);

    const { episodes } = data;
    // Parse lead definitions
    if (episodes != null) {
      this.episodes = episodes.map((def) => new EpisodeData(def, this));
    }
    Object.freeze(this.episodes);
  }

  getChildren(): readonly (EpisodeData)[];
  @Memoize()
  getChildren(): readonly GameDataInstance[] {
    return this.episodes;
  }
}
