import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IStackStyles, IStackTokens, mergeStyles, Stack } from '@fluentui/react';
import { Label } from '../../Global/Text';
import { useAppSelector } from '../../../store';
import { selectAppliedDataForCardId } from '../../../store/multiplayer-slice/applied-data';
import { selectCurrentEpisode, selectCurrentGame } from '../../../store/multiplayer-slice';
import { getCurrentGameTheme } from '../../../theme';

interface Props {
  title: string;
  isSelected?: boolean;
  onClick: () => void;
}

const theme = getCurrentGameTheme('Game01');

const contentStackStyles: IStackStyles = {
  root: {
    height: 110,
    width: 110,
    cursor: 'pointer',
    borderRadius: theme.effects.roundedCorner4,
    border: '2px solid transparent',
    transition: 'border .1s linear',
    selectors: {
      ':hover': {
        borderColor: '#666',
      },
      ':active': {
        backgroundColor: '#aaa',
        color: 'white',
      },
    },
  },
};

const cardImageStyles: IStackStyles = {
  root: {
    width: 100,
    height: 60,
    borderRadius: theme.effects.roundedCorner4,
  },
};

const contentStackTokens: IStackTokens = {
  padding: 5,
};

export default function CardCarouselCard({ title, isSelected = false, onClick }: Props) {
  const { t, i18n } = useTranslation('entries');
  const cardStyles = mergeStyles([
    isSelected && {
      backgroundColor: '#eee',
      color: 'white',
    },
  ]);
  const appliedDataForCard = useAppSelector((state) => selectAppliedDataForCardId(state, title ?? ''));
  const hasShortHeading = i18n.exists(`entries:${title}.shortHeading`);
  const label = t(`${title}.${hasShortHeading ? 'shortHeading' : 'heading'}`);
  const image = appliedDataForCard?.cardData.image;
  const currentGameId = useAppSelector((selectCurrentGame));
  const currentEpisodeId = useAppSelector((selectCurrentEpisode));
  const CARD_IMG_PATH = `./images/${currentGameId?.shortId}/cards/${currentEpisodeId?.shortId}/front/`;
  const imgSrc = image ? `${CARD_IMG_PATH}${image}.jpg` : undefined;
  return (
    <Stack
      className={cardStyles}
      onClick={onClick}
      horizontalAlign="center"
      verticalAlign="stretch"
      styles={contentStackStyles}
      tokens={contentStackTokens}
      role="button"
      tabIndex={0}
      aria-label={title}
    >
      <Stack styles={cardImageStyles}>{imgSrc && <img src={imgSrc} style={{ width: 100 }} alt={label} />}</Stack>
      <Label level={3} center>
        {label}
      </Label>
    </Stack>
  );
}
