import type { LocaleContent, CardOrEntryLocaleContent } from "../../types";

/**
 * i18Next `entries:Episode02.Task08` lead text content
 */
const Task08Lead: LocaleContent = {
  header: "Investigate Source of Managed Identity",
  role: "Identity Administrator",
  time: "5-10m",
  brief: "We should find out who created the user-assigned managed identity for the eCommerce app and when they created it. Take a closer look in Microsoft Sentinel and check out the permissions too.<br><br>-Andrea",
};

/**
 * i18Next `entries:Episode02.Task08` entry content
 */
const Task08Entries: CardOrEntryLocaleContent = {
  ManagedIdentityCreation: {
    heading: "Managed Identity Creation",
    CreationCaller: "Caller: quinn.anderson@bestforyouorganic.onmicrosoft.com",
    CreationTime: "Time: 2021-11-10T21:43:05.483Z",
  },
  ManagedIdentityPermissionsAssignment: {
    heading: "Managed Identity Permissions Assignment",
    Perm1Time: "Permission 1 Time: 11/11/2021, 7:52:48.073 PM UTC",
    Perm1Caller: "Permission 1 Caller: quinn.anderson@bestforyouorganic.onmicrosoft.com",
    Perm1ResourceGroup: "Permission 1 Resource Group: RGFINANCE",
    Perm1ResourceProviderValue: "Permission 1 Resource Provider Value: MICROSOFT.KEYVAULT",
    Perm1RoleDefinitionId: "Permission 1 Role Definition Id: 00482a5a-887f-4fb3-b363-3b7fe8e74483",
    Perm2Time: "Permission 2 Time: 11/11/2021, 7:53:47.017 PM UTC",
    Perm2Caller: "Permission 2 Caller: quinn.anderson@bestforyouorganic.onmicrosoft.com",
    Perm2ResourceGroup: "Permission 2 Resource Group: RGFINANCE",
    Perm2ResourceProviderValue: "Permission 2 Resource Provider Value: MICROSOFT.STORAGE",
    Perm2RoleDefinitionId: "Permission 2 Role Definition Id: 8e3af657-a8ff-443c-a75c-2fe8c4bcb635",
    Perm3Time: "Permission 3 Time: 11/11/2021, 8:02:12.681 PM UTC",
    Perm3Caller: "Permission 3 Caller: quinn.anderson@bestforyouorganic.onmicrosoft.com",
    Perm3ResourceGroup: "Permission 3 Resource Group: RGFINANCE",
    Perm3ResourceProviderValue: "Permission 3 Resource Provider Value: MICROSOFT.SQL",
    Perm3RoleDefinitionId: "Permission 3 Role Definition Id: b24988ac-6180-42a0-ab88-20f7382dd24c",
    Perm4Time: "Permission 4 Time: 11/11/2021, 7:51:49.814 PM UTC",
    Perm4Caller: "Permission 4 Caller: quinn.anderson@bestforyouorganic.onmicrosoft.com",
    Perm4ResourceGroup: "Permission 4 Resource Group: RGFINANCE",
    Perm4ResourceProviderValue: "Permission 4 Resource Provider Value: MICROSOFT.DOCUMENTDB",
    Perm4RoleDefinitionId: "Permission 4 Role Definition Id: acdd72a7-3385-48ef-bd42-f606fba81ae7",
    Perm5Time: "Permission 5 Time: 11/11/2021, 11:06:14.374 PM UTC",
    Perm5Caller: "Permission 5 Caller: quinn.anderson@bestforyouorganic.onmicrosoft.com",
    Perm5ResourceGroup: "Permission 5 Resource Group: RGSTORE",
    Perm5ResourceProviderValue: "Permission 5 Resource Provider Value: MICROSOFT.AUTHORIZATION",
    Perm5RoleDefinitionId: "Permission 5 Role Definition Id: 8e3af657-a8ff-443c-a75c-2fe8c4bcb635",
  },
};

export default {
  ...Task08Lead,
  ...Task08Entries,
};
