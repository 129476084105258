import type { LocaleContent, CardOrEntryLocaleContent } from "../../types";

/**
 * i18Next `entries:Episode01.Task12` lead text content
 */
const Task12Lead: LocaleContent = {
  header: "Investigate Angel in Sentinel & Defender",
  role: "Security Operations Analyst",
  time: "10-15m",
  brief: "See what you can find out about Angel. Start in Microsoft Sentinel. Search for indicators of attack to her account and her device.<br><br>-Andrea",
};

/**
 * i18Next `entries:Episode01.Task12` entry content
 */
const Task12Entries: CardOrEntryLocaleContent = {
  SecurityAlertsAngelBrown: {
    heading: "Security Alerts for Angel Brown",
    default: "No Security Alerts for Angel Brown",
    AngelHasNoSecurityAlerts: "Angel Brown Security Alerts: 0",
  },
  DeviceNameAngelBrown: {
    heading: "Device Name of Angel Brown",
    default: "Angel Brown's device name found",
    AngelDeviceispc067: "Angel Brown Device Name: pc067",
  },
  SecurityAlertspc067: {
    heading: "Security Alerts for pc067",
    default: "No Security Alerts for pc067",
    pc067HasNoSecurityAlerts: "pc067 Security Alerts: 0",
  },
  AlertInfopc067: {
    heading: "Alert Info for pc067",
    default: "No Alert Info for pc067",
    pc067NoAlertInfo: "pc067 Alert Info: 0",
  },
  AlertEvidencepc067: {
    heading: "Alert Evidence for pc067",
    default: "No Alert Evidence for pc067",
    pc067NoAlertEvidence: "pc067 Alert Evidence: 0",
  },
  pc067DeviceSummary: {
    heading: "pc067 Device Summary ",
    default: "No Anomalies Detected on pc067",
    pc067ZeroOpenIncidents: "pc067 Open Incidents: 0",
    pc067ZeroActiveAlerts: "pc067 Active Alerts: 0",
    pc067NoRiskLevel: "pc067 Risk Level: No risk",
    pc067IPaddress: "pc067 IP address: 10.1.0.6",
  },
  SourceIPRDPConnection: {
    heading: "Source IP of the RDP Connection",
    default: "RDP Connection to pc067",
    SourceIPaddressofRDPConnection: "Source IP Address of the RDP Connection: 13.68.237.243",
  },
  pc067RecordInspection: {
    heading: "pc067 Record Inspection",
    default: "Source of the RDP Connection to pc067",
    DeviceNamepc034: "Device Name: pc034",
    pc034PublicIP: "pc034 Public IP: 13.68.237.243",
    pc034OwnerTomotakanashi: "pc034 Owner: Tomo Takanashi",
  },
  pc034ExposureLevel: {
    heading: "pc034 Exposure level",
    default: "Medium Exposure Level for pc034",
    pc034Exposurelevel: "pc034 Exposure level: Medium",
  },
};

export default {
  ...Task12Lead,
  ...Task12Entries,
};
