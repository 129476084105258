/**
 * i18next NS: `Episode01.Task04`
 */
export default {
  common: {
    errorTitle: "Try Again",
    next: "Next",
    submit: "Submit",
    type: "Type: ",
    expectedTime: "Expected Time: ",
    brief: "Brief: ",
    start: "Start",
    skipAhead: "Skip Ahead",
    ok: "OK",
    done: "Done",
  },
  A005: {
    intro: {
      title: "Set up Insider Risk Policy",
      subtitle: "Solo Activity",
      type: "Compliance Administrator",
      time: "5-10m",
      body: "Let's keep an eye on Amari. Set up a insider risk policy so that we can quickly identify, triage, and act on any risky activity on his eCommerce app team. - Andrea",
    },
    error: {
      1: {
        title: "Try again.",
        body: "That's not the right place.",
      },
      2: {
        title: "Try again.",
        body: "That's not right either.",
      },
      3: {
        title: "Go Here!",
        body: "I need you to go to Microsoft 365 Compliance Center.",
      },
    },
  },
  A010: {
    body: "Here's the Microsoft 365 Compliance Center. What tool do you want to use?",
    options: {
      1: {
        label: "Information governance",
        error: {
          title: "No, not that one.",
          body: "Information governance covers compliance and data requirements for the entire organization.",
        },
      },
      2: {
        label: "Information protection",
        error: {
          title: "Try something else.",
          body: "Information protection helps you discover, classify, and protect sensitive information -- it's not specific to insider risk.",
        },
      },
      3: {
        label: "Insider risk management",
      },
    },
  },
  A020: {
    body: "Where to next?",
    options: {
      1: {
        label: "Alerts",
        error: {
          title: "You can't create an alert now.",
          body: "But this is where you will be alerted if there is a hit on one of the rules you set up.",
        },
      },
      2: {
        label: "Cases",
        error: {
          title: "You can't create a case.",
          body: "But later, if there is a hit on one of the rules you set up, it will generate an alert and a case you can investigate.",
        },
      },
      3: {
        label: "Policies",
      },
    },
  },
  A030: {
    title: "Create Policy",
    body: "What policy template do you want to use?",
    options: {
      1: {
        label: "Data theft by departing users",
        error: {
          title: "Prerequisite not met.",
          body: "HR data connector must be configured to track job level changes and performance activity.",
        },
      },
      2: {
        label: "General data leaks",
      },
      3: {
        label: "Data leaks by disgruntled users",
        error: {
          title: "Prerequisite not met.",
          body: "HR data connector must be configured to track job level changes and performance activity.",
        },
      },
    },
  },
  A040: {
    title: "Choose users and groups",
    subtitle: "Who will this policy apply to? Note that risk policies show groups by email address. Choose the group, then select DONE.",
    body: "",
    options: {
      1: {
        label: "AllCompany@bestforyouorganic.onmicrosoft.com",
      },
      2: {
        label: "ECommerceApp@bestforyouorganic.onmicrosoft.com",
      },
      3: {
        label: "Operations@bestforyouorganic.onmicrosoft.com",
      },
      4: {
        label: "SalesAndMarketing@bestforyouorganic.onmicrosoft.com",
      },
    },
    error: {
      title: "Remember your lead.",
      body: "Protect the team that Amari works with most.",
    },
    feedback: {
      title: "Amari Rivera is on the eCommerce app team.",
      subtitle: "That's the group we need to protect here.",
      body: "",
    },
  },
  A060: {
    title: "Specify content to prioritize (optional)",
    body: "If you want to include priority content in this insider risk policy, choose it and then select DONE.",
    options: {
      1: {
        label: "SharePoint sites",
      },
      2: {
        label: "Sensitive info types",
      },
      3: {
        label: "Sensitivity labels",
      },
      4: {
        label: "None - do not specify priority content ",
      },
    },
    error: {
      title: "Remember your lead.",
      body: "You want to protect places where people have saved sensitive credit card information.",
    },
    feedback: {
      title: "Specify content to prioritize (optional)",
      body: "You haven't configured sensitivity labels to apply to this policy. But you should prioritize SharePoint sites and sensitive information.",
    },
  },
  B010: {
    title: "Set Up Insider Risk Policy",
    subhead: "Choose the priority content and triggering event for this policy.",
    attempts: "Attempt % of 3",
    body: "Note: Best For You Organics uses the default Policy Indicators recommended by Microsoft.",
    placeholder: "Select the correct answer",
    unlocked: "Priority content and triggering event set!",
    locks: {
      1: {
        label: "Priority Content: SharePoint sites",
        options: {
          1: "https://bestforyouorganic.sharepoint.com/sites/Admin",
          2: "https://bestforyouorganic.sharepoint.com/ECommerceApp",
          3: "https://bestforyouorganic.sharepoint.com/sites/EULaunch",
          4: "https://bestforyouorganic.sharepoint.com/Operations",
          5: "https://bestforyouorganic.sharepoint.com/sites/Sales",
        },
        hint: "First, select the SharePoint site for Amari's team.",
      },
      2: {
        label: "Priority Content: Sensitive info types",
        options: {
          1: "Azure storage account key",
          2: "Credit card number",
          3: "EU Tax identification number",
          4: "IP address",
          5: "U.S. bank account number",
        },
        hint: "Remember, the leaked file contained credit card data.",
      },
      3: {
        label: "Triggering Event",
        options: {
          1: "User performs an exfiltration activity",
          2: "User matches a data loss prevention (DLP) policy",
        },
        hint: "We didn't set up a DLP policy for this, so the triggering event should be the default - an exfiltration activity.",
      },
    },
    error: {
      title: "Check the Brief.",
      body: "You can always find the Brief in your Journal. It includes the key information you need for this part of the investigation.",
      nextLabel: "Try Again",
    },
    walkthrough: {
      title: "Remember your lead.",
      body: "It looks like you're having some trouble. Let's walk through this.",
      nextLabel: "Guide Me",
    },
  },
  B050: {
    title: "Policy indicators",
    subtitle: "",
    body: "Choose the types of indicators to include in this insider risk policy, then select DONE.",
    options: {
      1: {
        label: "Sharing files, folders, or sites",
      },
      2: {
        label: "Downloading content",
      },
      3: {
        label: "Downgrading or removing sensitivity labels",
      },
      4: {
        label: "Sending email with attachments to recipients outside organization",
      },
    },
    error: {
      title: "That's not the best choice in this situation.",
      body: "Remember, policy indicators cover SharePoint sites, Microsoft Teams, and email messaging. Think about how the information was released and what types of risk you are trying to manage.",
    },
    feedback: {
      title: "Policy indicators",
      subtitle: "",
      body: "You should activate all of these. You are not far enough into the investigation to know what happened, so you want the full context of what these users are doing. You would only need to limit this if you get too many false positives.",
    },
  },
  C010: {
    title: "Insider risk policy set!",
    body: "Review your settings, then select SUBMIT to create the policy.",
  },
  C020: {
    title: "Your insider risk policy is set up for future detections.",
    body: "Select DONE to continue monitoring the eCommerce App team.",
  },
  C030: {
    title: "Nice work!",
    body: "You set up an insider risk policy. Remember, you can review your Protection tasks on the Evidence Map.",
    nextLabel: "Back to evidence map",
  },
};
