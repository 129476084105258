/* eslint-disable no-underscore-dangle */
import React from 'react';
import { mergeStyles, Modal, Stack, Text } from '@fluentui/react';
import ReactFlow, {
  Background,
  ConnectionMode,
  Controls,
  getConnectedEdges,
  isNode,
  MiniMap,
  ReactFlowProps,
  useZoomPanHelper,
  XYPosition,
  useStore,
} from 'react-flow-renderer';
import { useBoolean } from '@fluentui/react-hooks';

import type { TFunction } from 'react-i18next';
import type {
  Elements as FlowElements,
  Edge as FlowEdge,
  Node as FlowNode,
  NodeTypesType,
  OnLoadParams as FlowInstance,
} from 'react-flow-renderer';

import _ from 'lodash';

import CardPanel from '../CardPanel/CardPanel';
import MapCard from './MapCard';
import MapCardProtection, { MapCardProtectionNodeData } from './MapCardProtection';
import getFontVariant, { TextComponent } from '../../Global/Text/getFontVariant';
import { useAppSelector } from '../../../store';
import { selectCurrentPhase } from '../../../store/multiplayer-slice';
import {
  selectAllAppliedCards,
  selectAllAppliedCluesMap,
  selectAllAppliedLeadsMap,
} from '../../../store/multiplayer-slice/applied-data';
import { gameData } from '../../../static/game-data';

import imgEvidenceMapBg from '../../../static/images/Game01/shared/evidence-map-bg.jpg';

import { devLog, hasDebugFlag, isDev } from '../../../lib/util';

import type { MapCardNodeData } from './MapCard';
import type { AppliedCardEntity, AppliedClueEntity, AppliedLeadEntity } from '../../../lib/multiplayer/schemas';
import type { SafeDictionary } from '../../../lib/types';
import type { PhaseData } from '../../../lib/game-data/phase-data';
import type { EvidenceData } from '../../../lib/game-data/evidence-data';
import type { FactData } from '../../../lib/game-data/fact-data';

export interface IMapContentProps {
  t: TFunction;
  fitOnLoad?: boolean;
}

const nodeTypes: NodeTypesType = {
  card: MapCard,
  protection: MapCardProtection,
};

export type MapNodeDataTypes = MapCardProtectionNodeData | MapCardNodeData;
export type MapEdgeDataTypes = undefined;
export type MapElementsDataTypes = MapNodeDataTypes | MapEdgeDataTypes;
export type MapElement = FlowNode<MapNodeDataTypes> | FlowEdge<MapEdgeDataTypes>;

/** Normalize an angle in degrees to 0-359. */
function normalizeAngle(angle: number) {
  let result = angle;
  while (result < 0) {
    result += 360;
  }
  while (result >= 360) {
    result -= 360;
  }
  return result;
}

/** Get handle side from angle. */
function getHandleFromAngle(angle: number) {
  const normalizedAngle = normalizeAngle(angle);
  if (normalizedAngle > 315) {
    return 'east';
  }
  if (normalizedAngle > 225) {
    return 'north';
  }
  if (normalizedAngle > 135) {
    return 'west';
  }
  if (normalizedAngle > 45) {
    return 'south';
  }
  return 'east';
}

/** Get closest handles between two nodes. */
function getClosestHandles(sourceNode: FlowNode<MapCardNodeData>, targetNode: FlowNode<MapCardNodeData>) {
  const angle =
    (Math.atan2(targetNode.position.y - sourceNode.position.y, targetNode.position.x - sourceNode.position.x) * 180) /
    Math.PI;
  return [getHandleFromAngle(angle), getHandleFromAngle(angle + 180)] as const;
}

/** Get current position of all nodes in a React Flow instance. */
function getCurrentNodePositions<T = any>(instance?: FlowInstance<T>) {
  return (instance?.getElements() ?? []).reduce<SafeDictionary<XYPosition>>((accumulator, element) => {
    if (isNode(element)) {
      accumulator[element.id] = element.position;
    }
    return accumulator;
  }, {});
}

/** Generate special Evidence Map nodes */
function generateSpecialNodes(
  currentPhase: PhaseData | undefined,
  appliedLeadsMap: SafeDictionary<AppliedLeadEntity>,
  t: TFunction,
  currentNodePositions: SafeDictionary<XYPosition> = {},
) {
  const currentEpisode = currentPhase?.parent;
  const specialNodes: FlowNode<MapNodeDataTypes>[] = [];
  if (currentPhase == null || currentEpisode == null) {
    return specialNodes;
  }

  const phaseIndex = currentEpisode.phases.indexOf(currentPhase);
  const visibleProtectionLeads = currentEpisode.phases
    .slice(0, phaseIndex + 1)
    .flatMap((phase) => phase.getLeads())
    .filter((lead) => lead.flags.has('protection'));

  if (visibleProtectionLeads.length > 0) {
    const protectionNodeId = 'ProtectionNode';
    const protectionNode: FlowNode<MapCardProtectionNodeData> = {
      id: protectionNodeId,
      position: currentNodePositions[protectionNodeId] ?? currentEpisode.getMapPosition(protectionNodeId),
      data: {
        leads: visibleProtectionLeads.map((lead) => ({ lead, completed: appliedLeadsMap.hasOwnProperty(lead.id) })),
        t,
      },
      type: 'protection',
    };
    specialNodes.push(protectionNode);
  }

  return specialNodes;
}

/** Generate Evidence Map card nodes */
function generateCardNodes(
  appliedCards: AppliedCardEntity[],
  appliedCluesMap: SafeDictionary<AppliedClueEntity>,
  t: TFunction,
  currentNodePositions: SafeDictionary<XYPosition> = {},
  onFocus: (id: string) => void,
) {
  return appliedCards.reduce<Record<string, FlowNode<MapCardNodeData>>>((accumulator, cardEntity) => {
    const cardInstance = gameData.get(cardEntity?.id, 'evidence')?.getLocation();
    if (cardInstance != null) {
      const criticalDependencies = cardInstance.getDependencies('critical');
      const criticalDependenciesCollected = criticalDependencies.filter((dependency) =>
        appliedCluesMap.hasOwnProperty(dependency.id),
      );

      // Add new Card Node.
      accumulator[cardInstance.id] = {
        id: cardInstance.id,
        position: currentNodePositions[cardInstance.id] ?? cardInstance.getMapPosition(),
        data: {
          evidence: cardInstance,
          entity: cardEntity,
          progress: [criticalDependenciesCollected.length, criticalDependencies.length],
          onFocus: () => {
            onFocus(cardInstance.id);
          },
          t,
        },
        type: 'card',
      };
    }
    return accumulator;
  }, {});
}

/** Generate Evidence Map link edges. */
function generateLinks(
  cardNodes: Record<string, FlowNode<MapCardNodeData>>,
  appliedCluesMap: SafeDictionary<AppliedClueEntity>,
) {
  // Array of applied data instances to generate links for.
  const allInstances: (EvidenceData | FactData)[] = [];

  // Collect data instances for applied cards with links
  Object.values(cardNodes).reduce((accumulator, cardNode) => {
    const dataInstance = cardNode.data?.evidence ?? gameData.get(cardNode?.id, 'evidence');
    if (dataInstance != null && dataInstance.linkIds.length > 0) {
      accumulator.push(dataInstance);
    }
    return accumulator;
  }, allInstances);

  Object.values(appliedCluesMap).reduce((accumulator, clueEntity) => {
    if (clueEntity == null) {
      return accumulator;
    }

    const clueInstance = gameData.get(clueEntity.id, 'evidence');
    if (clueInstance == null || clueInstance.type !== 'clue') {
      return accumulator;
    }

    // Add clue instance if it has any links.
    if (clueInstance.linkIds.length > 0) {
      accumulator.push(clueInstance);
    }

    // Add applied facts with links for the given clue.
    accumulator.push(
      ...clueInstance
        .getAllChildren('fact')
        .filter(({ shortId, linkIds }) => clueEntity.facts?.includes(shortId) && linkIds.length > 0),
    );

    return accumulator;
  }, allInstances);

  // Generate links for cards, clues, and facts.
  return allInstances.reduce<FlowEdge<MapEdgeDataTypes>[]>((accumulator, dataInstance) => {
    dataInstance.getLinks().forEach((link) => {
      const ids = [dataInstance.getLocation()?.getLocation()?.id, link.getLocation()?.id].filter(
        (id): id is string => id != null,
      );
      if (ids.length !== 2) {
        return;
      }
      const sourceNode = cardNodes[ids[0]];
      const targetNode = cardNodes[ids[1]];
      if (sourceNode == null || targetNode == null) {
        return;
      }
      const [sourceHandle, targetHandle] = getClosestHandles(sourceNode, targetNode);

      accumulator.push({
        id: `[${dataInstance.id}]: ${ids.join('<->')}`,
        source: sourceNode.id,
        target: targetNode.id,
        sourceHandle,
        targetHandle,
        type: 'straight',
        style: { stroke: 'black', strokeWidth: 5 },
      });
    });

    return accumulator;
  }, []);
}

const mapContentClassName = mergeStyles(
  {
    backgroundImage: `url(${imgEvidenceMapBg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
  },
  {
    '.react-flow__zoompane': {
      'cursor': 'grab',
      ':active': {
        cursor: 'grabbing',
      },
    },
  },
);

const miniMapClassName = mergeStyles(
  { left: 'auto', bottom: '16px', right: '16px' },
  {
    '.react-flow__minimap-node': { fill: 'rgb(102, 102, 102)' },
    '.react-flow__minimap-mask': {
      stroke: 'rgb(102, 102, 102)',
      strokeWidth: '10px',
      fill: 'rgb(211, 211, 211, 0.25)',
    },
  },
);

/**
 * The Evidence Map Content.
 */
const MapContent: React.FC<IMapContentProps> = ({ t, fitOnLoad = false }) => {
  // Current phase selector
  const currentPhase = useAppSelector(selectCurrentPhase);

  // Helper
  const { setCenter } = useZoomPanHelper();
  const store = useStore();

  // Applied data selectors.
  const appliedCards = useAppSelector(selectAllAppliedCards);
  const appliedCluesMap = useAppSelector(selectAllAppliedCluesMap);
  const appliedLeadsMap = useAppSelector(selectAllAppliedLeadsMap);

  // Flow instance and elements state.
  const flowInstance = React.useRef<FlowInstance<MapElementsDataTypes> | undefined>(undefined);
  const [flowElements, setFlowElements] = React.useState<FlowElements<MapElementsDataTypes>>([]);

  const [cardPanelIsOpen, { setTrue: showCardPanel, setFalse: hideCardPanel }] = useBoolean(false);
  const [cardFocusPrevented, { setTrue: preventCardFocus, setFalse: allowCardFocus }] = useBoolean(false);
  const [selectedCardId, setSelectedCardId] = React.useState(appliedCards[0]?.id);

  const handleCardPanelClosed = React.useCallback(() => {
    preventCardFocus();
    setTimeout(() => { allowCardFocus(); }, 0.1);
  }, [allowCardFocus, preventCardFocus]);

  /** Handler for when you tab onto a node */

  /** Callback to rebuild the Evidence Map cards and links. */
  const rebuildMap = React.useCallback(() => {
    devLog('Rebuilding evidence map.');
    const handleFocus = (id: string) => {
      if (cardFocusPrevented) return;
      flowInstance.current?.fitView();
      const { nodes } = store.getState();

      if (nodes.length) {
        const node = nodes.find((e) => e.id === id);

        if (node) {
          const x = node.__rf.position.x + node.__rf.width / 2;
          const y = node.__rf.position.y + node.__rf.height / 2;
          const zoom = 0.7;

          setCenter(x, y, zoom);
        }
      }
    };
    const currentNodePositions = getCurrentNodePositions(flowInstance.current);
    const specialNodes = generateSpecialNodes(currentPhase, appliedLeadsMap, t, currentNodePositions);
    const cardNodes = generateCardNodes(appliedCards, appliedCluesMap, t, currentNodePositions, handleFocus);
    const links = generateLinks(cardNodes, appliedCluesMap);

    /* We're sorting them by position for tab indexing */
    const sortedCardNodes = _.orderBy(Object.values(cardNodes), ['position.y', 'position.x']);
    setFlowElements([...specialNodes, ...sortedCardNodes, ...links]);
  }, [
    t,
    currentPhase,
    appliedCards,
    appliedCluesMap,
    appliedLeadsMap,
    flowInstance,
    setFlowElements,
    setCenter,
    store,
    cardFocusPrevented,
  ]);

  /** Trigger rebuildMap() whenever it changes (i.e. when its dependencies do) */
  React.useEffect(rebuildMap, [rebuildMap]);

  /** Connect instance reference on first load. */
  const onFlowLoad = React.useCallback(
    (params: FlowInstance<MapElementsDataTypes>) => {
      flowInstance.current = params;
      if (fitOnLoad) {
        flowInstance.current.fitView();
      }
    },
    [flowInstance, fitOnLoad],
  );

  type OnElementClickCallback = NonNullable<ReactFlowProps['onElementClick']>;
  const onElementClick = React.useCallback<OnElementClickCallback>(
    (event, element: MapElement) => {
      if (!isNode(element) || element.type !== 'card') {
        return;
      }
      // Need to figure out how getConnectedEdges works. It wants a list of... edges? Every edge?
      devLog('Clicked on Card:', element.id, element);
      devLog('Flow instance:', flowInstance.current);
      devLog('Connected edges:', getConnectedEdges([element], []));

      setSelectedCardId(element.id);
      showCardPanel();
    },
    [setSelectedCardId, showCardPanel],
  );

  /** Check if we should show the grid and allow node dragging. */
  const mapDebug: boolean = isDev && hasDebugFlag('map');

  /** MapDebug only: Print out stringified node positions on click. */
  const onFlowPaneClick = React.useCallback(() => {
    if (!mapDebug || flowInstance.current == null) {
      return;
    }

    // Generate list of node positions with the `Episode##.` stripped off.
    const shortNameNodePositions = Object.entries(getCurrentNodePositions(flowInstance.current)).reduce<
      Record<string, XYPosition>
    >((accumulator, [key, position]) => {
      if (position != null) {
        accumulator[key.slice(key.indexOf('.') + 1)] = position;
      }
      return accumulator;
    }, {});

    devLog('Flow Nodes:', {
      elements: flowInstance.current.getElements(),
      positionsJSON: JSON.stringify(shortNameNodePositions, null, 2),
    });
  }, [flowInstance, mapDebug]);

  return (
    <ReactFlow
      // Basic
      elements={flowElements}
      className={`evidence-map-content ${mapContentClassName}`}
      // Flow view
      minZoom={0.333}
      maxZoom={3}
      defaultPosition={[496, 96]}
      snapToGrid
      snapGrid={[16, 16]}
      // onlyRenderVisibleElements
      translateExtent={[
        [-1000, -96],
        [2600, 2400],
      ]}
      nodeExtent={[
        [-1000, 0],
        [2000, 2000],
      ]}
      preventScrolling
      // Event handlers
      onElementClick={onElementClick}
      onNodeDragStop={rebuildMap}
      onLoad={onFlowLoad}
      onPaneClick={onFlowPaneClick}
      // Interaction
      nodesDraggable={mapDebug}
      zoomOnScroll={false}
      zoomOnPinch={false}
      zoomOnDoubleClick={false}
      // paneMoveable={false}
      selectNodesOnDrag={false}
      connectionMode={ConnectionMode.Loose}
      // Element customization
      nodeTypes={nodeTypes}
      // Connection line options
      // Keys
      selectionKeyCode={mapDebug ? 'Shift' : ''}
    >
      <Background gap={16} size={1} color={mapDebug ? '#000000' : 'transparent'} />
      <Stack
        styles={{ root: { position: 'relative', zIndex: 5, pointerEvents: 'none' } }}
        tokens={{ childrenGap: '16px', padding: '32px 64px' }}
      >
        <Stack.Item>
          <Text
            as="h1"
            styles={{ root: { color: '#2f2f2f', pointerEvents: 'auto' } }}
            variant={getFontVariant(TextComponent.enum.Heading, 1)}
          >
            {t('evidenceMap.title')}
          </Text>
        </Stack.Item>
      </Stack>
      <Modal
        isOpen={cardPanelIsOpen}
        onDismiss={hideCardPanel}
        onDismissed={handleCardPanelClosed}
        styles={{ main: { backgroundColor: 'transparent', maxWidth: 'auto' } }}
      >
        <CardPanel selectedCardId={selectedCardId} selectCard={setSelectedCardId} />
      </Modal>
      <Controls showInteractive={false} style={{ left: 'auto', bottom: '16px', right: '232px' }} />
      <MiniMap className={miniMapClassName} />
    </ReactFlow>
  );
};

export default MapContent;
