/**
 * i18next NS: `Episode01.Conclusion`
 */
 export default {
  accuse: {
    title: "It's time to solve the case",
    body: "Who was behind the breach?",
  },
  confirm: {
    title: "Accuse {{name}}",
    body: "I know I don't need to remind you how important this is. Are you absolutely sure you want to accuse {{name}}?",
    nextLabel: "Yes",
    backLabel: "No",
  },
  proof: {
    title: "How did {{name}} carry out the attack?",
    body: "Discuss this with your team, then select the method.",
    options: {
      1: {
        label: "Used Amari's device to download file",
      },
      2: {
        label: "Sent attack command via RDP session",
      },
      3: {
        label: "Stole admin credentials to access SharePoint",
      },
    },
  },
  incorrect: {
    title: "You failed to find the culprit.",
    body: "CIO Andrea Divkovic went to bat for you and you didn't come through. Now she’s second guessing her decision to hire you at all! You'll need to work extra hard to gain back her trust.",
  },
  correct: {
    title: "Congratulations! Angel has confessed.",
    body: "Victorious in your first-day trial by fire, you are now a valued and trusted member of the team. CIO Andrea Divkovic has assured you that, once your probationary period is over, you'll receive a promotion and a raise.",
  },
  partialCorrect: {
    title: "Lucky for you, Angel confessed...",
    body: "...because the evidence you found wouldn't hold up in court! Day-one crisis averted by the skin of your teeth, CIO Andrea Divkovic is satisfied with your performance but has advised you to be more rigorous in your investigations moving forward.",
  },
};
