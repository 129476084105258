import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Text } from '@fluentui/react';

import type { IPanelHeaderRenderer } from '@fluentui/react';

import LeadEntry from '../LeadEntry/LeadEntry';
import NavPanel from '../../Global/NavPanel/NavPanel';
import { selectAllJournalEntryPropsGrouped, setEntriesUnread } from '../../../store/journal-slice';
import { selectCurrentLead } from '../../../store/game-slice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { getCurrentGameTheme } from '../../../theme';
import { hasDebugFlag, isDev } from '../../../lib/util';
import getFontVariant, { TextComponent } from '../../Global/Text/getFontVariant';
import NavPanelHeader from '../../Global/NavPanel/NavPanelHeader';

const headerRenderer: IPanelHeaderRenderer = (props, defaultRender, headerTextId?: string) => (
  <NavPanelHeader headerTextId={headerTextId} headerText={props?.headerText} />
);

const LeadJournal: React.FC = () => {
  const { t } = useTranslation('game01Common');
  const theme = getCurrentGameTheme();

  const dispatch = useAppDispatch();
  const entriesSeen = useAppSelector(({ journal }) => journal.entriesSeen);
  const journalEntriesGrouped = useAppSelector(({ journal }) => selectAllJournalEntryPropsGrouped(journal.entries));

  const currentLead = useAppSelector(selectCurrentLead);

  const updateEntriesUnread = React.useCallback(() => {
    if (entriesSeen.length > 0) {
      dispatch(setEntriesUnread({ ids: entriesSeen, unread: false }));
    }
  }, [dispatch, entriesSeen]);

  const currentLeadEntries = React.useMemo(() => {
    if (currentLead == null) {
      return [];
    }
    return journalEntriesGrouped.get(currentLead) ?? [];
  }, [currentLead, journalEntriesGrouped]);

  /** Check if we should show all leads. */
  const journalDebug: boolean = isDev && hasDebugFlag('journal');

  /** Generate content for LeadJournal */
  const journalContent = React.useMemo(() => {
    const output: JSX.Element[] = [];
    if (currentLead != null) {
      output.push(<LeadEntry key={currentLead.id} isCurrentLead lead={currentLead} entries={currentLeadEntries} />);
    }

    if (journalDebug) {
      journalEntriesGrouped.forEach((entries, lead) => {
        if (lead !== currentLead) {
          output.push(<LeadEntry key={lead.id} lead={lead} entries={entries} />);
        }
      });
    }

    if (output.length === 0) {
      output.push(
        <Stack.Item key="noLeadSelected" align="center" tokens={{ padding: '16px' }}>
          <Text
            styles={{ root: { color: theme.palette.white } }}
            variant={getFontVariant(TextComponent.enum.Heading, 5)}
            block
          >
            {t('journal.noLeadSelected')}
          </Text>
        </Stack.Item>,
      );
    }

    return output;
  }, [t, currentLead, currentLeadEntries, journalEntriesGrouped, journalDebug, theme]);

  return (
    <NavPanel
      activePanel="journal"
      onDismiss={updateEntriesUnread}
      headerText={t('journal.header')}
      styles={{
        navigation: {
          position: 'sticky',
          inset: '0 0 auto',
          height: '108px', // Static height because LeadEntry headers stick below it.
        },
      }}
      closeButtonAriaLabel={t('journal.closeButton')}
      hasCloseButton
      onRenderHeader={headerRenderer}
    >
      <Stack verticalAlign="start" tokens={{ childrenGap: '16px' }}>
        {journalContent}
      </Stack>
    </NavPanel>
  );
};
export default LeadJournal;
