import { IFontStyles } from '@fluentui/react';

const fonts: Partial<IFontStyles> = {
  // mega: {},
  superLarge: {
    // h1 ('Header' in style guide)
    fontSize: 42,
    lineHeight: 48,
    fontWeight: 700,
  },
  xxLargePlus: {
    // h2 (h1 in style guide)
    fontSize: 32,
    lineHeight: 48,
    fontWeight: 600,
  },
  xxLarge: {
    // h3 (h2 in style guide)
    fontSize: 28,
    lineHeight: 38,
    fontWeight: 600,
  },
  xLargePlus: {
    // h4 (h3 in style guide)
    fontSize: 24,
    lineHeight: 32,
    fontWeight: 600,
  },
  xLarge: {
    // p1
    fontSize: 24,
    lineHeight: 32,
    fontWeight: 400,
  },
  large: {
    // p2
    fontSize: 20,
    lineHeight: 26,
    fontWeight: 400,
  },
  mediumPlus: {
    // Button label
    fontSize: 18,
    lineHeight: 24,
    fontWeight: 700,
    margin: '1em',
  },
  medium: {
    // h5 (h4 in style guide)
    fontSize: 18,
    lineHeight: 24,
    fontWeight: 700,
  },
  smallPlus: {
    // p3
    fontSize: 16,
    lineHeight: 24,
    fontWeight: 400,
  },
  small: {
    // Label
    fontSize: 16,
    lineHeight: 22,
    fontWeight: 400,
  },
  xSmall: {
    // Small label
    fontSize: 14,
    lineHeight: 18,
    fontWeight: 400,
  },
  // tiny: {},
};

export default fonts;
