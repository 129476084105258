/**
 * i18next NS: `Episode02.Phase03Review`
 */
export default {
  dialog: {
    intro: {
      title: "I have a question for you.",
      body: "Take a look at the evidence and let me know what you think. Make sure you have the proof to back it up!",
    },
    final: {
      title: "Continue your investigation.",
      body: "Sounds like Quinn got sloppy and granted too much access to the managed identity, but why did Frey create the identity in the first place? And are we even sure that it was Frey who created it? I'm getting pressure from Abigail for updates on this investigation so wrap it up ASAP!",
      nextBtn: "Go to Phase 4 set of leads",
    },
  },
  question1: {
    title: "Out of the events you've discovered so far leading up to the attack, what happened first?",
    body: "Discuss this with your team, then select your answer.",
    optionOne: "Quinn configured eShopIdentity",
    optionTwo: "Frey initiated the app registration",
    optionThree: "Wenny granted access to BFYOVacationTool",
    feedbackTitle: "No, check your evidence map.",
    feedbackBody: "The first event was when Frey initiated the app registration.",
    feedbackNextLabel: "Next",
  },
  proof: {
    title: "When did Frey initiate the app registration?",
    body: "Discuss this with your team, then select the evidence that provides proof.",
    optionOne: "2021-11-06 T11:17:30.45Z",
    optionTwo: "2021-11-08 T23:19:30.335Z",
    optionThree: "2021-11-10 T21:43:05.483Z",
    optionFour: "2021-11-13 T23:17:30.51Z",
    feedbackTitle: "No, check your evidence map.",
    feedbackBody: "The app registration was initiated 2021-11-08 T23:19:30.335Z.",
    feedbackNextLabel: "Next",
  },
  question2: {
    title: "Does eShopIdentity have the correct permissions?",
    body: "Discuss this with your team, then select your answer.",
    optionOne: "Yes",
    optionTwo: "No",
    feedbackTitle: "No, check your evidence map.",
    feedbackBody: "The eShopIdentity (the user-assigned managed identity) was granted permissions that exceeded that initial request.",
    feedbackNextLabel: "Next",
  },
};
