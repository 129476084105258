import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { Trans, useTranslation } from 'react-i18next';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { EntryContentProps } from '../../../lib/entries';
import getFontVariant, { TextComponent } from '../../Global/Text/getFontVariant';
import { toHTMLSafeId } from '../../../lib/util';
import { getCurrentGameTheme } from '../../../theme';

const theme = getCurrentGameTheme('Game01');

const EntryContent: React.FC<EntryContentProps> = ({ heading, content, entry_id }) => {
  const { t } = useTranslation('entries');
  const startOpen = false;

  const [expanded, { toggle: toggleExpanded }] = useBoolean(startOpen);

  const toggleExpandedOnKeyUp = React.useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.code === 'Enter' || event.code === 'Space') {
        toggleExpanded();
      }
    },
    [toggleExpanded],
  );
  return (
    <Stack
      tokens={{ childrenGap: '8px' }}
      styles={{ root: { overflowWrap: 'anywhere', width: '100%' } }}
      verticalFill
      horizontalAlign="space-between"
    >
      <Stack tokens={{ childrenGap: '8px' }} horizontal horizontalAlign="space-between">
        <Stack.Item>
          <Text
            id={toHTMLSafeId(entry_id, 'headerText', undefined)}
            role="heading"
            aria-level={3}
            variant={getFontVariant(TextComponent.enum.Heading, 5)}
            styles={{ root: { color: theme.semanticColors.notebookText, fontWeight: 400 } }}
            block
          >
            {t(heading)}
          </Text>
        </Stack.Item>
        <Stack.Item
          onClick={toggleExpanded}
          onKeyUp={toggleExpandedOnKeyUp}
          styles={{ root: { cursor: 'pointer' } }}
          role="button"
          tabIndex={0}
          title={expanded ? t('buttons.collapse') : t('buttons.expand')}
          aria-label={expanded ? t('buttons.collapse') : t('buttons.expand')}
          aria-expanded={expanded ? 'true' : 'false'}
        >
          <FontAwesomeIcon
            icon={expanded ? faCaretUp : faCaretDown}
            style={{
              color: theme.semanticColors.caret,
              fontSize: '20px',
              alignContent: 'end',
              alignSelf: 'end',
              alignItems: 'end',
            }}
          />
        </Stack.Item>
      </Stack>
      <Stack
        styles={{
          root: !expanded && {
            maxHeight: '0px',
          },
        }}
        tokens={{ childrenGap: '2px' }}
      >
        {content.reverse().map((c, i) => (
          <Stack.Item key={`${entry_id}_content_${i}`} styles={{ root: { overflow: 'hidden' } }} grow={1}>
            <Text
              variant={getFontVariant(TextComponent.enum.Body, 3)}
              styles={{
                root: [
                  {
                    color: theme.semanticColors.notebookText,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                  !expanded && {
                    maxHeight: '0px',
                    whiteSpace: 'wrap',
                    margin: '0px',
                    padding: '0px',
                  },
                ],
              }}
              block
            >
              <Trans t={t} i18nKey={c} />
            </Text>
          </Stack.Item>
        ))}
      </Stack>
    </Stack>
  );
};
export default EntryContent;
