/* eslint-disable no-underscore-dangle */
import React, { CSSProperties } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import { mergeStyles, Modal } from '@fluentui/react';
import ReactFlow, {
  Background,
  ConnectionMode,
  isNode,
  ReactFlowProps,
  useZoomPanHelper,
  XYPosition,
  useStore,
  EdgeTypesType,
} from 'react-flow-renderer';

import type { TFunction } from 'react-i18next';
import type {
  Elements as FlowElements,
  Edge as FlowEdge,
  Node as FlowNode,
  NodeTypesType,
  OnLoadParams as FlowInstance,
} from 'react-flow-renderer';

import _ from 'lodash';

import MapActivity from './MapActivity';
import MapLinkNode, { MapLinkNodeData } from './MapLinkNode';
import MapCircuitLock, { MapCircuitLockNodeData } from './MapCircuitLock';
import MapStartNode, { MapStartNodeData } from './MapStartNode';
import MapBattery from './MapBattery';

import { useAppSelector, useAppStore } from '../../../store';

import { devLog, hasDebugFlag, isDev } from '../../../lib/util';

import { selectCurrentEpisode, selectCurrentPhase } from '../../../store/multiplayer-slice';

import type { MapActivityNodeData } from './MapActivity';
import type { SafeDictionary } from '../../../lib/types';
import type { EpisodeData } from '../../../lib/game-data/episode-data';
import { ElementDataNode, ElementDataWire } from '../../../lib/game-data/element-data';
import MapEdge, { MapEdgeData } from './MapEdge';

import ActivityPreviewPane from '../ActivityPreview/ActivityPreviewPane';
import { ActivityData } from '../../../lib/game-data/activity-data';
import { selectActivityStatus, selectAllCompeletedActivities } from '../../../store/multiplayer-slice/applied-data';
import MapBackground, { MapBackgroundNodeData } from './MapBackground';
import imgInsightEngineMapBg from '../../../static/images/Game02/Episode01/insight-engine/04_background.png';

export interface IMapContentProps {
  t: TFunction;
  fitOnLoad?: boolean;
}

const nodeTypes: NodeTypesType = {
  node: MapActivity,
  nodeLink: MapLinkNode,
  lock: MapCircuitLock,
  battery: MapBattery,
  background: MapBackground,
  link: MapEdge,
  start: MapStartNode,
};

const edgeTypes: EdgeTypesType = {
};

export type MapNodeDataTypes =
  MapActivityNodeData |
  MapLinkNodeData |
  MapCircuitLockNodeData |
  MapStartNodeData |
  MapEdgeData;

export type MapEdgeDataTypes = undefined;
export type MapElementsDataTypes =
  MapNodeDataTypes |
  MapEdgeDataTypes |
  MapBackgroundNodeData;

export type MapElement =
  FlowNode<MapNodeDataTypes> |
  FlowEdge<MapEdgeDataTypes>;

/** Get current position of all nodes in a React Flow instance. */
function getCurrentNodePositions<T = any>(instance?: FlowInstance<T>) {
  return (instance?.getElements() ?? []).reduce<SafeDictionary<XYPosition>>((accumulator, element) => {
    if (isNode(element)) {
      accumulator[element.id] = element.position;
    }
    return accumulator;
  }, {});
}

/** Generate insight engine start nodes */
function generateStartNodes(
  currentEpisode: EpisodeData,
  t: TFunction,
  currentNodePositions: SafeDictionary<XYPosition> = {},
  onFocus: (id: string) => void,
) {
  // Applied data selectors.
  const nodeElements = currentEpisode?.getChildren('element').filter((element) =>
    element.type === 'start',
  ) as ElementDataNode[];

  return nodeElements.reduce<Record<string, FlowNode<MapStartNodeData>>>((accumulator, nodeElement) => {
    // Add new Card Node.
    if (nodeElement.discipline !== 'storage') return accumulator; // creating only the storage nodes
    accumulator[nodeElement.id] = {
      id: nodeElement.id,
      position: currentNodePositions[nodeElement.id] ?? nodeElement.getMapPosition(),
      data: {
        element: nodeElement,
        onFocus: () => {
          onFocus(nodeElement.id);
        },
        t,
      },
      type: nodeElement.type,
    };
    return accumulator;
  }, {});
}

/** Generate insight engine nodes */
function generateNodes(
  currentEpisode: EpisodeData,
  t: TFunction,
  currentNodePositions: SafeDictionary<XYPosition> = {},
  onFocus: (id: string) => void,
) {
  // Applied data selectors.
  const nodeElements = currentEpisode?.getChildren('element').filter((element) =>
    element.type === 'node' ||
    element.type === 'lock' ||
    element.type === 'battery',
  ) as ElementDataNode[];

  return nodeElements.reduce<Record<string, FlowNode<MapActivityNodeData>>>((accumulator, nodeElement) => {
    // Add new Card Node.
    if (nodeElement.discipline !== 'storage' && nodeElement.discipline !== 'multiple' || nodeElement.type === 'battery') return accumulator; // creating only the storage nodes
    accumulator[nodeElement.id] = {
      id: nodeElement.id,
      position: currentNodePositions[nodeElement.id] ?? nodeElement.getMapPosition(),
      data: {
        element: nodeElement,
        onFocus: () => {
          onFocus(nodeElement.id);
        },
        t,
      },
      type: nodeElement.type,
    };
    return accumulator;
  }, {});
}

/** Generate hidden nodes for wires */
function generateLinkNodes(
  currentEpisode: EpisodeData,
  t: TFunction,
  currentNodePositions: SafeDictionary<XYPosition> = {},
) {
  // Applied data selectors.
  const wireElements = currentEpisode?.getChildren('element').filter((element) => element.type === 'wire') as ElementDataWire[];
  const allLinkNodesWithId: { id: string, position: XYPosition, element: ElementDataWire }[] = [];

  wireElements.forEach((wireElement) => {
    const wirePositions = wireElement.getWirePosition();
    allLinkNodesWithId.push(
      ...wirePositions.map((wirePosition, index) => {
        return {
          id: `${wireElement.id}.point${index}`,
          element: wireElement,
          position: wirePosition,
        };
      }),
    );
  });

  return allLinkNodesWithId.reduce<Record<string, FlowNode<MapLinkNodeData>>>((accumulator, nodeLinkElement) => {
    // Add new Card Node.
    if (nodeLinkElement.element.discipline !== 'storage') return accumulator; // creating only the storage edges
    accumulator[nodeLinkElement.id] = {
      id: nodeLinkElement.id,
      position: currentNodePositions[nodeLinkElement.id] ?? nodeLinkElement.position,
      data: {
        element: nodeLinkElement.element,
        t,
      },
      type: 'nodeLink',
    };
    return accumulator;
  }, {});
}

/** Generate insight engine link edges. */
function generateLinks(
  currentEpisode: EpisodeData,
  nodes: Record<string, FlowNode<MapActivityNodeData>>,
  linkNodes: Record<string, FlowNode<MapLinkNodeData>>,
  startNodes: Record<string, FlowNode<MapStartNodeData>>,
  currentNodePositions: SafeDictionary<XYPosition>,
) {
  // Applied data selectors.
  const wires = currentEpisode?.getChildren('element').filter((element) => element.type === 'wire') as ElementDataWire[];
  // Generate links for cards, clues, and facts.
  return wires.reduce<FlowNode<MapEdgeData>[]>((accumulator, dataInstance) => {
    dataInstance.getLinks().forEach((link) => {
      const wirePositions = [{ x: 0, y: 0 }, ...dataInstance.getWirePosition(), { x: 0, y: 0 }];
      wirePositions.forEach((wirePosition, index) => {
        if (index + 1 >= wirePositions.length) {
          return;
        }
        const ids = [
          index <= 0 ? dataInstance.getLocation()?.getLocation()?.id : `${dataInstance.id}.point${index - 1}`,
          index + 2 >= wirePositions.length ? link.getLocation()?.id : `${dataInstance.id}.point${index}`,
        ].filter(
          (id): id is string => id != null,
          );
          if (ids.length !== 2) {
            return;
          }
          const sourceNode = nodes[ids[0]] || linkNodes[ids[0]] || startNodes[ids[0]];
          const targetNode = nodes[ids[1]] || linkNodes[ids[1]] || startNodes[ids[1]];
          if (sourceNode == null || targetNode == null) {
            return;
          }
          // const [sourceHandle, targetHandle] = getClosestHandles(sourceNode, targetNode);
          if (dataInstance.discipline !== 'storage') return; // creating only the storage nodes
          accumulator.push({
            id: `[${dataInstance.id}]: ${ids.join('<->')}`,
            position: currentNodePositions[dataInstance.id] ?? dataInstance.getMapPosition(),
            type: 'link',
            style: {
              zIndex: 1,
            },
            data: {
              element: dataInstance,
            },
          });
        });
      });

    return accumulator;
  }, []);
}

const mapContentClassName = mergeStyles(
  {
  },
  {
    '.react-flow__zoompane': {
      'cursor': 'grab',
      ':active': {
        cursor: 'grabbing',
      },
    },
  },
);

const nodeSizeBase = 200;
const backgroundProperties: CSSProperties = {
  backgroundImage: `url(${imgInsightEngineMapBg})`,
  position: 'absolute',
  minWidth: `${nodeSizeBase * 10}px`,
  maxWidth: `${nodeSizeBase * 10}px`,
  minHeight: `${nodeSizeBase * 10}px`,
  maxHeight: `${nodeSizeBase * 10}px`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  transformOrigin: '0 0',
};

/**
 * The Evidence Map Content.
 */
const MapContent: React.FC<IMapContentProps> = ({ t, fitOnLoad = false }) => {
  // Helper
  const { setCenter } = useZoomPanHelper();
  const rootState = useAppStore().getState();
  const store = useStore();
  const compeletedActivities = useAppSelector(selectAllCompeletedActivities);
  const currentEpisode = useAppSelector(selectCurrentEpisode);
  const phaseData = useAppSelector(selectCurrentPhase);
  // const currentActivities = phaseData?.getActivities().map((activity) => activity.id);
  const isComplete = phaseData?.isComplete(compeletedActivities) || false;
  // Flow instance and elements state.
  const flowInstance = React.useRef<FlowInstance<MapElementsDataTypes> | undefined>(undefined);
  const [flowElements, setFlowElements] = React.useState<FlowElements<MapElementsDataTypes>>([]);
  const [backgroundStyle, setBackgroundStyle] = React.useState<CSSProperties>({});
  const [previewPanelIsOpen, { setTrue: showPreviewPanel, setFalse: hidePreviewPanel }] = useBoolean(false);
  const [previewFocusPrevented, { setTrue: preventPreviewFocus, setFalse: allowPreviewFocus }] = useBoolean(false);
  const [selectedActivity, setSelectedActivity] = React.useState<ActivityData | undefined>(undefined);

  const handleCardPanelClosed = React.useCallback(() => {
    preventPreviewFocus();
    setTimeout(() => { allowPreviewFocus(); }, 0.1);
  }, [allowPreviewFocus, preventPreviewFocus]);
  /** Handler for when you tab onto a node */

  /** Callback to rebuild the Evidence Map cards and links. */
  const rebuildMap = React.useCallback(() => {
    devLog('Rebuilding evidence map.');
    const handleFocus = (id: string) => {
      if (previewFocusPrevented) return;
      flowInstance.current?.fitView();
      const { nodes } = store.getState();

      if (nodes.length) {
        const node = nodes.find((e) => e.id === id);

        if (node) {
          const x = node.__rf.position.x + node.__rf.width / 2;
          const y = node.__rf.position.y + node.__rf.height / 2;
          const zoom = 0.7;

          setCenter(x, y, zoom);
        }
      }
    };
    const currentNodePositions = getCurrentNodePositions(flowInstance.current);
    const nodes = generateNodes(currentEpisode, t, currentNodePositions, handleFocus);
    const linkNodes = generateLinkNodes(currentEpisode, t, currentNodePositions);
    const startNodes = generateStartNodes(currentEpisode, t, currentNodePositions, handleFocus);
    const links = generateLinks(currentEpisode, nodes, linkNodes, startNodes, currentNodePositions);

    const mapBackground: FlowNode<MapBackgroundNodeData>[] = []; // getBackground(currentNodePositions); adds the background as a node

    /* We're sorting them by position for tab indexing */
    const sortedNodes = _.orderBy(Object.values(nodes), ['position.y', 'position.x']);
    const sortedStartNodes = _.orderBy(Object.values(startNodes), ['position.y', 'position.x']);
    const sortedLinkNodes = _.orderBy(Object.values(linkNodes), ['position.y', 'position.x']);

    setFlowElements([...mapBackground, ...links, ...sortedNodes, ...sortedStartNodes, ...sortedLinkNodes]);
  }, [currentEpisode, t, previewFocusPrevented, store, setCenter]);

  /** Trigger rebuildMap() whenever it changes (i.e. when its dependencies do) */
  React.useEffect(rebuildMap, [rebuildMap]);

  const getPanPostion = () => {
    const panPostion = {
      transform: `translate(0,-25%) ${document
        .getElementsByClassName('react-flow__nodes')[0]
        ?.getAttribute('style')
        ?.split('transform:')[1]
        ?.split(';')[0]}`,
      transformOrigin: '0 25%',
    };
    return panPostion;
  };
  /** Connect instance reference on first load. */
  const onFlowLoad = React.useCallback(
    (params: FlowInstance<MapElementsDataTypes>) => {
      flowInstance.current = params;
      if (fitOnLoad) {
        flowInstance.current.fitView();
      }
      setBackgroundStyle({ ...backgroundProperties, ...getPanPostion() });
    },
    [flowInstance, fitOnLoad],
  );

  const onMapMove = React.useCallback(() => {
    setBackgroundStyle({ ...backgroundProperties, ...getPanPostion() });
  }, []);

  type OnElementClickCallback = NonNullable<ReactFlowProps['onElementClick']>;
  const onElementClick = React.useCallback<OnElementClickCallback>(
    (event, element: MapElement) => {
      if (!isNode(element) || element.type !== 'node') {
        return;
      }
      const currentTask = element.data?.element.getTask();
      if (!currentTask) {
        return;
      }

      const activityStatus = selectActivityStatus(rootState, currentTask.id);

      if (activityStatus !== 'deactivated') { // && currentActivities?.includes(currentTask.id) && !isComplete) {
        setSelectedActivity(currentTask);
        showPreviewPanel();
      }
    },
    [rootState, showPreviewPanel],
  );

  /** Check if we should show the grid and allow node dragging. */
  const mapDebug: boolean = isDev && hasDebugFlag('map');

  /** MapDebug only: Print out stringified node positions on click. */
  const onFlowPaneClick = React.useCallback(() => {
    if (!mapDebug || flowInstance.current == null) {
      return;
    }

    // Generate list of node positions with the `Episode##.` stripped off.
    const shortNameNodePositions =
      (flowInstance.current?.getElements() ?? [])
        .reduce<{
          mapPositions: Record<string, XYPosition>,
          wirePositions: Record<string, XYPosition[]>,
          background: XYPosition,
        }>(
          (accumulator, element) => {
            if (isNode(element)) {
              if (element.type === 'background') {
                accumulator.background = element.position;
              } else {
                const data = element.data as MapNodeDataTypes;
                const key = data.element.shortId || element.id;
                if (element.type === 'nodeLink') {
                  if (!accumulator.wirePositions[key]) accumulator.wirePositions[key] = [];
                  const [, index] = element.id.split('point');
                  accumulator.wirePositions[key][parseInt(index, 10)] = element.position;
                } else {
                  accumulator.mapPositions[key] = element.position;
                }
              }
            }
            return accumulator;
          }, { mapPositions: {}, wirePositions: {}, background: { x: 0, y: 0 } });
    devLog('Flow Nodes:', {
      elements: flowInstance.current.getElements(),
      positionsJSON: JSON.stringify(shortNameNodePositions, null, 2),
    });
  }, [flowInstance, mapDebug]);

  return (
    <ReactFlow
      // Basic
      elements={flowElements}
      className={`evidence-map-content ${mapContentClassName}`}
      // Flow view
      defaultZoom={1.3}
      minZoom={1.3}
      maxZoom={3}
      defaultPosition={[0, 0]}
      snapToGrid
      snapGrid={[10, 10]}
      // onlyRenderVisibleElements
      translateExtent={[
        [0, 0],
        [2000, 1040],
      ]}
      nodeExtent={[
        [0, 0],
        [2000, 1600],
      ]}
      preventScrolling
      // Event handlers
      onElementClick={onElementClick}
      onNodeDragStop={rebuildMap}
      onLoad={onFlowLoad}
      onPaneClick={onFlowPaneClick}
      onMove={onMapMove}
      onPaneScroll={onMapMove}
      // Interaction
      nodesDraggable={mapDebug}
      zoomOnScroll={false}
      zoomOnPinch={false}
      zoomOnDoubleClick={false}
      // paneMoveable={false}
      selectNodesOnDrag={false}
      connectionMode={ConnectionMode.Loose}
      // Element customization
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      // Connection line options
      // Keys
      selectionKeyCode={mapDebug ? 'Shift' : ''}
    >
      <div style={backgroundStyle}>
        {'\u200B'}
      </div>
      <Background
        // className={`evidence-map-content ${mapContentClassName}`} // adds the background to the background component
        gap={10}
        size={1}
        color={mapDebug ? '#000000' : 'transparent'}
      />
      <Modal
        isOpen={previewPanelIsOpen}
        onDismiss={hidePreviewPanel}
        onDismissed={handleCardPanelClosed}
        styles={{ main: { backgroundColor: 'transparent', maxWidth: 'auto' } }}
      >
        <ActivityPreviewPane isComplete={isComplete} activity={selectedActivity} handlePreview={hidePreviewPanel} />
      </Modal>
    </ReactFlow>
  );
};

export default MapContent;
