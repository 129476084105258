import { IStackStyles, Stack } from '@fluentui/react/lib/Stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../Global/Button/Button';
import Panel from '../../../Global/Panel/Panel';
import { Heading, P } from '../../../Global/Text';

export interface LockItem {
  key: number | string;
  text: string;
  disabled: boolean;
  isCorrect?: boolean;
}

interface combinationState {
  currentError: string | null;
  combination: (string | number)[];
  currentCombination: (string | number)[];
}
interface Props {
  title: string;
  description: string;
  hint: string;
  attemptsMade: number;
  maxAttempts: number;
  showWalkthrough: boolean;
  onSuccess: () => void;
  onError: () => void;
  isUnlocked: boolean;
  useDark?: boolean;
}

export interface TaskState {
  [key: string]: {
    order: number;
    label: string;
    data: LockItem[];
  };
}

export function useVerticalUnlock(
  initialTaskState: TaskState,
  combination: number[],
  isWalkthrough: boolean = false,
  walkthroughStep: number,
) {
  const taskLength = React.useMemo(() => Object.keys(initialTaskState).length, [initialTaskState]);
  const [taskState, setTaskState] = React.useState<any>(initialTaskState);

  const [combinationState, setCombinationState] = React.useState<combinationState>({
    currentError: null,
    combination,
    currentCombination: new Array(taskLength).fill(-1),
  });

  const [isUnlocked, setIsUnlocked] = React.useState(false);

  // Create a set of ref to assign to our dropdowns
  const [lockRefs] = React.useState<any>([React.createRef(), React.createRef(), React.createRef()]);

  const resetOptions = () => {
    if (!lockRefs || lockRefs?.length === 0) {
      return;
    }

    setCombinationState((val) => {
      return {
        ...val,
        currentCombination: new Array(taskLength).fill(-1),
      };
    });

    lockRefs.forEach((lockRef: any) => lockRef.current.reset());
  };

  React.useEffect(() => {
    if (isWalkthrough) {
      setTaskState((currentTaskState: any) => {
        // disabled wrong answers in dropdowns
        const newTaskState = { ...currentTaskState };
        Object.keys(currentTaskState).forEach((optionsKey, optionsIndex) => {
          newTaskState[optionsKey].data = currentTaskState[optionsKey].data.map((option: LockItem) => {
            if (combinationState.combination[optionsIndex] === option.key) {
              return { ...option };
            }
            return { ...option, disabled: true };
          });
        });
        return newTaskState;
      });
    }
  }, [isWalkthrough, combinationState.combination]);

  // set focus on the current walkthrough step dropdown
  React.useEffect(() => {
    if (isWalkthrough && walkthroughStep <= taskLength) {
      lockRefs.forEach((lockRef: any) => lockRef.current.disable());
      lockRefs[walkthroughStep].current.displayHint();
    }
  }, [walkthroughStep, isWalkthrough, lockRefs, taskLength]);

  const handleCombinationChange = () => {
    const currentLockState = lockRefs
      .map((lockRef: any) => lockRef.current?.getIsCorrect())
      .every((val: boolean | undefined) => val);
    setIsUnlocked(currentLockState);
  };

  return { lockRefs, handleCombinationChange, resetOptions, isUnlocked, taskState };
}

const locksInnerWrapper: IStackStyles = {
  root: {
    width: '100%',
    position: 'relative',
    marginTop: 30,
  },
};

export default function VerticalUnlock({
  children,
  title,
  description,
  hint,
  attemptsMade,
  maxAttempts,
  showWalkthrough,
  onSuccess,
  onError,
  isUnlocked = false,
  useDark = false,
}: React.PropsWithChildren<Props>): JSX.Element {
  const { t } = useTranslation(['common', 'game01Common']);
  const hasSingleChild = React.Children.count(children) === 1;

  return (
    <Panel fullscreen useDark={useDark}>
      <Stack verticalFill>
        <Stack.Item align="stretch" className="unlock__header" grow={1}>
          <Heading level={1} block center useDark={useDark}>
            {title}
          </Heading>
          <P level={1} block noMargin center useDark={useDark}>
            {hint} {description}
          </P>
          <Heading level={2} block center useDark={useDark}>
            {/* eslint-disable react/jsx-one-expression-per-line */}
            Attempt {attemptsMade + 1 < maxAttempts ? attemptsMade + 1 : maxAttempts} of {maxAttempts}
          </Heading>
        </Stack.Item>
        <Stack.Item align="center" grow={1}>
          <P level={1} block noMargin center useDark={useDark}>
            {t('activities.unlock.instructions')}
          </P>
        </Stack.Item>
        <Stack.Item className="unlock__locks" grow={3}>
          <Stack horizontal verticalAlign="start" horizontalAlign="center" verticalFill>
            <Stack styles={locksInnerWrapper} horizontal wrap verticalAlign="center" horizontalAlign="space-evenly">
              {hasSingleChild ? (
                <Stack.Item>{children}</Stack.Item>
              ) : (
                React.Children.map(children, (child) => <Stack.Item>{child}</Stack.Item>)
              )}
            </Stack>
          </Stack>
        </Stack.Item>
        <Stack.Item align="end">
          <Button
            isPrimary
            label={t('buttons.submit')}
            onClick={isUnlocked ? onSuccess : onError}
            disabled={showWalkthrough}
          />
        </Stack.Item>
      </Stack>
    </Panel>
  );
}
