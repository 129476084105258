import { IStackItemStyles, IStackItemTokens, IStackTokens, mergeStyles, Stack } from '@fluentui/react';
import * as React from 'react';

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    margin: '0 auto',
    width: 'calc(100% - 142px)',
  },
};

const stackTokens: IStackTokens | IStackItemTokens = {};

interface Props {
  backgroundColor?: string;
  backgroundImage: string;
  altText?: string;
}

export default function InteractionScreenContent({
  backgroundColor = 'transparent',
  backgroundImage,
  altText = 'need alt text',
}: React.PropsWithChildren<Props>) {
  const backgroundImageStyles = mergeStyles({
    backgroundColor,
    width: '100%',
  });

  return (
    <Stack horizontal className="interaction-screen__content" verticalFill>
      <Stack.Item styles={stackItemStyles} tokens={stackTokens}>
        <img className={`${backgroundImageStyles}`} src={backgroundImage} alt={altText} />
      </Stack.Item>
    </Stack>
  );
}
