import { NIL as NIL_UUID } from 'uuid';

import type { EvidenceData } from './evidence-data';
import type { AppliedDataEntity, AppliedDataEntityBase } from '../multiplayer/schemas';
import type { JournalEntryUpdate } from '../journal/types';

export function compileDataFromEvidence(
  input: readonly EvidenceData[],
  options?: {
    memo?: Map<EvidenceData, Set<string>>;
    missProbability?: number;
  },
) {
  const allEvidence = options?.memo ?? new Map<EvidenceData, Set<string>>();
  const missProbability = options?.missProbability ?? 0;

  input.forEach((evidence) => {
    // Chance to _not_ collect non-critical clues/cards.
    if (evidence.importance !== 'critical' && missProbability > Math.random()) {
      return;
    }

    const location = evidence.getLocation();
    if (location != null && location !== evidence) {
      // Add location
      allEvidence.set(location, allEvidence.get(location) ?? new Set());
    }

    // Add base Evidence
    const evidenceFactIds = allEvidence.get(evidence) ?? new Set();
    allEvidence.set(evidence, evidenceFactIds);

    // Process direct Facts of base Evidence.
    evidence.facts.forEach((fact) => {
      // Chance to _not_ collect non-critical facts.
      if (evidence.importance !== 'critical' && missProbability > Math.random()) {
        return;
      }
      // Add fact shortId to list for base Evidence.
      evidenceFactIds.add(fact.shortId);
      // Process direct unlocks of Fact.
      fact.getUnlocks().forEach((unlock) => {
        if (unlock.kind === 'evidence') {
          allEvidence.set(unlock, allEvidence.get(unlock) ?? new Set());
        }
        if (unlock.kind === 'fact') {
          const factLocation = unlock.getLocation();
          if (factLocation != null) {
            allEvidence.set(factLocation, (allEvidence.get(factLocation) ?? new Set()).add(unlock.shortId));
          }
        }
      });
    });

    // Process direct unlocks of base Evidence.
    evidence.getUnlocks().forEach((unlock) => {
      if (unlock.kind === 'evidence') {
        allEvidence.set(unlock, allEvidence.get(unlock) ?? new Set());
      }
      if (unlock.kind === 'fact') {
        const factLocation = unlock.getLocation();
        if (factLocation != null) {
          allEvidence.set(factLocation, (allEvidence.get(factLocation) ?? new Set()).add(unlock.shortId));
        }
      }
    });
  });

  return allEvidence;
}

export function generateJournalEntriesFromCompiledData(
  compiledData: ReturnType<typeof compileDataFromEvidence>,
  options?: {
    memo?: JournalEntryUpdate[];
    entryDate?: number;
  },
) {
  const journalEntriesToAdd: JournalEntryUpdate[] = options?.memo ?? [];
  const entryDate = options?.entryDate ?? Date.now();

  compiledData.forEach((facts, evidence) => {
    if (evidence.isCard()) {
      const leadId = evidence.getDependencies().find((dep) => dep.parent?.isKind('lead'))?.parent?.id;
      if (leadId != null) {
        journalEntriesToAdd.push({
          id: `${leadId}::${evidence.id}`,
          date: entryDate,
        });
      }
    } else if (evidence.isClue()) {
      journalEntriesToAdd.push({
        id: evidence.id,
        date: entryDate,
        states: Array.from(facts).sort(),
      });
    }
  });

  return journalEntriesToAdd;
}

/** Generate lists of entities to apply and journal entries to add. */
export function generateAppliedDataFromCompiledData(
  compiledData: ReturnType<typeof compileDataFromEvidence>,
  options?: {
    memo?: AppliedDataEntity[];
    entryUsername?: string;
    entryDate?: number;
    entryPhase?: string | null;
  },
) {
  const entitiesToApply: Record<string, AppliedDataEntity> = {};
  options?.memo?.forEach((entity) => {
    entitiesToApply[entity.id] = entity;
  });
  const entryUsername = options?.entryUsername ?? NIL_UUID;
  const entryDate = options?.entryDate ?? Date.now();

  compiledData.forEach((facts, evidence) => {
    let entryPhase = options?.entryPhase;
    if (entryPhase === undefined) {
      const evidencePhase =
        evidence.getPhase() ??
        evidence
          .getDependencies()
          .find((dep) => dep.getPhase() != null)
          ?.getPhase();
      entryPhase = evidencePhase?.id;
    }
    // Base applied data entity
    const payload: AppliedDataEntityBase = {
      id: evidence.id,
      user: entryUsername,
      date: entryDate,
      phase: entryPhase,
    };

    if (evidence.isCard()) {
      entitiesToApply[evidence.id] = { type: 'card', ...payload };
    } else if (evidence.isClue()) {
      entitiesToApply[evidence.id] = { type: 'clue', ...payload, facts: Array.from(facts).sort() };
    }
  });

  return Object.values(entitiesToApply);
}
