import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import ApplyEvidence from '../components/Game01/ApplyEvidence/ApplyEvidence';
import LeadSelection from '../components/Game01/LeadSelection/LeadSelection';
import { taskList } from '../tasks';
import PhaseReview from '../components/Game01/PhaseReview/PhaseReview';
import ReviewQuestions from '../components/Game01/ReviewQuestions/ReviewQuestions';
import ConclusionRouter from '../components/Global/ConclusionRouter/ConclusionRouter';
import Tutorial from '../components/Game01/Tutorial/Tutorial';
import Summary from '../components/Game01/Summary/Summary';

import type { StateMatcher, StateMatcherOptions } from '../lib/xstate/matchers';
import type { GameContext, GameEvent } from './game-service';

import InsightEngine from '../components/Game02/InsightEngine';
import NarrativeBreak from '../components/Game02/NarrativeBreak/NarrativeBreak';

/**
 * Returns the task selection component for each game
 */
const getTaskSelectionComponent = (gameId?: string) => {
  switch (gameId) {
    case 'Game01':
      return <LeadSelection />;
    case 'Game02':
      return <InsightEngine />;
    default:
      return <LeadSelection />;
  }
};

const getReviewQuestionsComponent = (gameId?: string) => {
  switch (gameId) {
    case 'Game01':
      return <ReviewQuestions />;
    case 'Game02':
      return <NarrativeBreak />;
    default:
      return <ReviewQuestions />;
  }
};

/**
 * Default state matchers for game states.
 * (Used by `GameContent` component and full-game type tests)
 */
export const getDefaultStateMatchers = (gameId?: string): StateMatcher<GameContext, GameEvent>[] => [
  {
    states: 'tutorial',
    final: true,
    content: <Tutorial />,
  },
  {
    states: 'investigationPhase.choose',
    final: true,
    content: getTaskSelectionComponent(gameId),
  },
  {
    states: 'investigationPhase.addEvidence',
    final: true,
    content: <ApplyEvidence />,
  },
  ...taskList.map(
    (task): StateMatcher<GameContext, GameEvent> => ({
      states: 'investigationPhase.play',
      test: (_, { game }) => game.currentLeadId === task.name,
      final: true,
      content: <task.Component />,
    }),
  ),
  {
    states: 'investigationPhase.review',
    final: true,
    content: <PhaseReview />,
  },
  {
    states: 'investigationPhase.reviewQuestions',
    final: true,
    content: getReviewQuestionsComponent(gameId),
  },
  {
    states: 'conclusion',
    final: true,
    // content: <Conclusion />,
    content: <ConclusionRouter />,
  },
  {
    states: 'summary',
    final: true,
    content: <Summary />,
  },
];

/**
 * Default state matcher options for game states.
 * (Used by `GameContent` component and full-game type tests)
 */
export const getDefaultStateMatcherOptions = (): StateMatcherOptions => ({
  contentWrapper: (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      // Note: The inline style below ensures activity backgrounds fill the entire viewport and panels connect to the bottom of the screen
      style={{ height: '100%' }}
    />
  ),
  resultsWrapper: <AnimatePresence exitBeforeEnter />,
});
