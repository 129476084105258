import * as React from 'react';
import Desktop, { generateDesktopIcons } from '../../../../../components/Game01/Episode1Desktop/Episode1Desktop';
import { ComponentProps } from '../../../../task-types';
import background from '../../../../../static/images/Game01/shared/b4y0_desktop.jpeg';

export default function A010({ t, state, send, match }: ComponentProps) {
  const desktopIcons = generateDesktopIcons(
    () => send('CLICK.INCORRECT'),
    [
      {
        icon: 'microsoftEndpointManager',
        onClick: () => send('NEXT'),
        isCorrect: true,
      },
    ],
  );
  return (
    <Desktop
      icons={desktopIcons}
      desktopBackground={background}
      showCorrect={match('hint')}
      showHint={match('incorrect')}
      title={t(`A010.error.${state.attempts}.title`)}
      hint={t(`A010.error.${state.attempts}.body`)}
      onDismiss={() => { send('NEXT'); }}
    />
  );
}
