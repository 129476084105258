import type { LocaleContent, CardOrEntryLocaleContent } from "../../types";

/**
 * i18Next `entries:Episode02.Task08` lead text content
 */
const Task10Lead: LocaleContent = {
  header: "Manage Organization-Wide App Consent",
  role: "Identity Administrator",
  time: "5-10m",
  brief: "Can you go into Azure Active Directory and configure the settings for granting app permissions? Make sure to limit how users consent to applications and enable the workflow for admins to consent to app registrations.<br><br>-Andrea",
};

/**
 * i18Next `entries:Episode02.Task08` entry content
 */
const Task10Entries: CardOrEntryLocaleContent = {
  UserConsentSettings: {
    heading: "User Consent Settings",
    UserConsentSettingsUsers: "User Consent Settings User Consent=Do not allow user consent",
    UserConsentSettingsGroupOwner: "User Consent Settings Group Owner Consent=Do not allow group owner consent",
  },
  AdminConsentWorkflow: {
    heading: "Admin Consent Workflow",
    AdminConsentWorkflowGroup: "Admin Consent Request group=Application Administrators",
    AdminConsentWorkflowUsersCanRequest: "Users can request admin consent to apps they are unable to consent to=Yes",
  },
};

export default {
  ...Task10Lead,
  ...Task10Entries,
};
