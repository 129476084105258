import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Label, IIconProps, IButtonStyles, IconButton, FontWeights } from '@fluentui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPaper, faUserHeadset } from '@fortawesome/pro-solid-svg-icons';
import { AnimatePresence, motion } from 'framer-motion';
import { setActivePanel } from '../../../store/game-slice';
import { RootState } from '../../../store';
import NavBarUnread from './NavBarUnread';
import NavBarCallout from './NavBarCallout';
import { NOTIFICATION_TRANSITION_TIME } from '../../../lib/anim-timings';
import { getCurrentGameTheme } from '../../../theme';
import { IGradients } from '../../../theme/Global/gradients';

export interface NavBarButtonProps {
  panel: string;
  icon: string;
  activeIcon?: string;
  disabled: boolean;
  unread: number;
  unreadCallout: boolean;
  title: string;
  label: string;
  onClickCallback: Function;
  secondaryIcon: string;
}

const theme = getCurrentGameTheme();

function createButtonStyles(isActive: boolean): IButtonStyles {
  const background = isActive ? theme.semanticColors.navbarButtonBackground : 'transparent';
  const color = isActive ? theme.semanticColors.navbarButtonActive : theme.semanticColors.navbarButtonInactive;
  const hoverBackground = isActive
    ? theme.semanticColors.navbarButtonBackgroundHoverActive
    : theme.semanticColors.navbarButtonBackgroundHoverActive;
  const transitionTiming = '0.3s';
  return {
    root: {
      'transition': `background ${transitionTiming}, color ${transitionTiming}`,
      'height': 'auto',
      'width': 111,
      'minHeight': 92,
      'paddingTop': 12,
      'pointer': 'cursor',
      'borderRadius': 0,
      color,
      background,
      'label': {
        marginTop: 20,
        pointerEvents: 'none',
        color,
        transition: `color ${transitionTiming}`,
      },
      // Prevent button child elements from being the target of a click event.
      '> *': {
        pointerEvents: 'none',
      },
    },
    rootFocused: {
      ':focus::after': {
        outline: `2px solid ${theme.palette as Partial<IGradients>} `,
      },
      // Accessibility: Prevent Fluent's high contrast media queries from breaking focus outline
      '@media screen and (-ms-high-contrast: active), (forced-colors: active)': {
        ':focus::after': {
          inset: '2px',
        },
      },
    },
    rootHovered: {
      background: hoverBackground,
      color: theme.semanticColors.navButtonFocused,
      label: {
        color: theme.semanticColors.navButtonFocused,
      },
    },
    rootPressed: {
      height: 'auto',
      background: theme.semanticColors.navbarButtonBackground,
      color: 'white',
      label: {
        color: 'white',
      },
    },
    rootDisabled: {
      background: 'transparent',
      color: '#B3B5B5',
      label: {
        color: '#B3B5B5',
      },
    },
    flexContainer: {
      flexDirection: 'column',
    },
  };
}

function createIconProps(fontSize: number, iconName: string): IIconProps {
  return {
    styles: {
      root: {
        fontSize,
        display: 'block',
      },
    },
    iconName,
  };
}

const variants = {
  show: { opacity: 1, scale: 1 },
  hide: { opacity: 0, scale: 0 },
};

const NavBarButton: React.FC<NavBarButtonProps> = ({
  panel,
  icon,
  activeIcon,
  title,
  label,
  unread,
  disabled,
  unreadCallout,
  onClickCallback,
  secondaryIcon,
}) => {
  const activeCallout = useSelector((state: RootState) => state.game.activeCallout.indexOf(panel) !== -1);
  const activePanel = useSelector((state: RootState) => state.game.activePanel);
  const dispatch = useDispatch();
  function onClick() {
    dispatch(setActivePanel(panel));
    onClickCallback();
  }
  const isActive = activePanel === panel;

  return (
    <IconButton
      iconProps={createIconProps(36, isActive ? activeIcon || icon : icon)}
      className="navBarButton"
      id={`navBarButton_${panel}`}
      title={title}
      ariaLabel={title}
      toggle
      disabled={disabled}
      styles={createButtonStyles(isActive)}
      onClick={() => {
        onClick();
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        <NavBarCallout active={activeCallout} />
        <NavBarUnread
          isActive={isActive}
          target={`navBarButton_${panel}`}
          unread={unread}
          onClick={() => onClick()}
          unreadCallout={unreadCallout}
        />
        <Label>
          <span style={{ fontSize: 14, fontWeight: FontWeights.regular } as React.CSSProperties}>{label}</span>
        </Label>
        <AnimatePresence>
          {secondaryIcon && (
            <motion.div
              variants={variants}
              initial={{ opacity: 0, scale: 0 }}
              animate={secondaryIcon ? 'show' : 'hide'}
              exit={secondaryIcon ? 'hide' : 'show'}
              style={{
                width: 22,
                height: 22,
                right: 24,
                top: -20,
                transformOrigin: 'center',
                position: 'absolute',
                backgroundColor: secondaryIcon === 'hand' ? '#2eaa4b' : '#0078d4',
                border: '1px solid rgba(0,0,0,0.4)',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: 14,
              }}
              transition={{ duration: NOTIFICATION_TRANSITION_TIME / 1000 }}
            >
              {secondaryIcon === 'hand' && (
                <FontAwesomeIcon icon={faHandPaper} style={{ marginLeft: -1, marginTop: -1 }} />
              )}
              {secondaryIcon === 'facilitator' && <FontAwesomeIcon icon={faUserHeadset} style={{ marginTop: -1 }} />}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </IconButton>
  );
};

export default NavBarButton;
