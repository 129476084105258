import { createMachine, assign, send } from 'xstate';
import { TaskConfig, TaskOptions, InteractionTaskContext, TaskEvent } from '../../../task-types';
import { addScore, getDefaultOptions } from '../../../task-options';
import { gameService } from '../../../../state/game-service';
import { store as reduxStore, getCurrentLeadId } from '../../../../store';

const initialState: InteractionTaskContext = {
  // One tracker for all attempts; will get reset. Consider changing this
  attempts: 0,
  walkthroughStep: 0,
  maxAttempts: 3,
  store: reduxStore,
};
const POINTS_VALUE = 25;
const TASK_ID = 'Game01.Episode02.Task12';

export const Task12Config: TaskConfig<InteractionTaskContext, TaskEvent> = {
  id: 'Task12',
  context: initialState,
  initial: 'A020',
  states: {
    A020: {
      // Desktop Select - 3 chances to click correctly
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          always: {
            target: 'hint',
            cond: (ctx) => ctx.attempts === 3,
          },
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        hint: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: '#Task12.A025',
          },
        },
      },
    },
    A025: {
      initial: 'final',
      states: {
        final: {
          on: {
            NEXT: '#Task12.A030',
          },
        },
      },
    },
    A030: {
      initial: 'final',
      entry: 'resetContext',
      states: {
        final: {
          on: {
            NEXT: '#Task12.A050',
          },
        },
      },
    },
    A050: {
      initial: 'decision',
      states: {
        decision: {
          on: {
            'CLICK.MOST': {
              target: '#Task12.A060',
              actions: 'incrementAttempts',
            },
            'CLICK.ELEVATED': {
              target: '#Task12.A065',
              actions: 'incrementAttempts',
            },
            'NEXT': {
              target: '#Task12.A070',
            },
          },
        },
      },
    },
    A060: {
      initial: 'final',
      states: {
        final: {
          on: {
            NEXT: '#Task12.A050',
          },
        },
      },
    },
    A065: {
      initial: 'final',
      states: {
        final: {
          on: {
            NEXT: '#Task12.A050',
          },
        },
      },
    },
    A070: {
      initial: 'final',
      entry: [
        addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
        send('NEXT'),
      ],
      states: {
        final: {
          on: {
            NEXT: '#Task12.A075',
          },
        },
      },
    },
    A075: {
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        correct: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        hint: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: {
              target: '#Task12.A080',
            },
          },
        },
      },
    },
    A080: {
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'CLICK.CORRECT': {
              target: 'correct',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        correct: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        hint: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: {
              target: '#Task12.A090',
            },
          },
        },
      },
    },
    A090: {
      // Switches
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.SUBMIT_INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'success',
            },
            'CLICK.FEEDBACK': {
              target: 'feedback',
            },
          },
        },
        incorrect: {
          always: {
            target: 'walkthrough',
            cond: (ctx) => ctx.attempts >= 3,
          },
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        success: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        walkthrough: {
          always: {
            target: 'success',
            cond: (ctx) => ctx.walkthroughStep === 12,
          },
          on: {
            NEXT: {
              actions: 'incrementWalkthroughStep',
              target: 'walkthrough',
            },
          },
        },
        feedback: {
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        final: {
          // send fucntion will fire immediately after addScore advancing to the next state
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: { target: '#Task12.A100' },
          },
        },
      },
    },
    A100: {
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        final: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: {
              target: '#Task12.A110',
            },
          },
        },
      },
    },
    A110: {
      // Interstitial
      entry: 'resetContext',
      on: {
        NEXT: {
          target: '#Task12.B020',
        },
      },
    },
    B020: {
      // Desktop Select - 3 chances to click correctly
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          always: {
            target: 'hint',
            cond: (ctx) => ctx.attempts === 3,
          },
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        hint: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: '#Task12.B030',
          },
        },
      },
    },
    B030: {
      initial: 'simulation',
      entry: ['resetContext', 'sendSimStart'],
      states: {
        simulation: {
        },
      },
    },
  },
};

/**
 * Additional machine options.
 */
export const Task12Options: TaskOptions<InteractionTaskContext, TaskEvent> = {
  actions: {
    resetContext: assign({ attempts: () => 0, walkthroughStep: () => 0 }),
    incrementAttempts: assign({ attempts: (context) => context.attempts + 1 }),
    incrementWalkthroughStep: assign({ walkthroughStep: (context) => context.walkthroughStep + 1 }),
    sendSimStart: () => gameService.send('START.SIMULATION'),
    setMaxAttemptsToTwo: assign({
      attempts: (context) => context.attempts,
      walkthroughStep: (context) => context.walkthroughStep,
      maxAttempts: (context) => {
        if (context.maxAttempts) {
          context.maxAttempts -= 1;
        }
        return 2;
      },
    }),
  },
  guards: {},
};

/**
 * Machine constructor.
 */
export function createTask12() {
  return createMachine<InteractionTaskContext, TaskEvent>(Task12Config, getDefaultOptions()).withConfig(Task12Options);
}
