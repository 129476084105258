import * as React from 'react';
import { z } from 'zod';
import thisPc from '../../../../static/images/Game01/shared/desktop_icons/thisPc.png';
import securityCenter from '../../../../static/images/Game01/shared/desktop_icons/securityCenter.png';
import policy from '../../../../static/images/Game01/shared/desktop_icons/policy.png';
import microsoftComplianceCenter from '../../../../static/images/Game01/shared/desktop_icons/microsoftComplianceCenter.png';
import logAnalyticsWorkspace from '../../../../static/images/Game01/shared/desktop_icons/logAnalyticsWorkspace.png';
import azureSentinel from '../../../../static/images/Game01/shared/desktop_icons/azureSentinel.png';
import azureAdRoles from '../../../../static/images/Game01/shared/desktop_icons/azureAdRoles.png';
import azureAdPrivileges from '../../../../static/images/Game01/shared/desktop_icons/azureAdPrivileges.png';
import azureAd from '../../../../static/images/Game01/shared/desktop_icons/azureAd.png';
import defenderForEndpoint from '../../../../static/images/Game01/shared/desktop_icons/defenderForEndpoint.png';
import microsoftEndpointManager from '../../../../static/images/Game01/shared/desktop_icons/microsoftEndpointManager.png';

export const ICON_NAMES = {
  thisPc: 'thisPc',
  securityCenter: 'securityCenter',
  policy: 'policy',
  microsoftComplianceCenter: 'microsoftComplianceCenter',
  microsoftEndpointManager: 'microsoftEndpointManager',
  logAnalyticsWorkspace: 'logAnalyticsWorkspace',
  azureSentinel: 'azureSentinel',
  azureAdRoles: 'azureAdRoles',
  azureAdPrivileges: 'azureAdPrivileges',
  azureAd: 'azureAd',
  defenderForEndpoint: 'defenderForEndpoint',
} as const;

const IconEnum = z.nativeEnum(ICON_NAMES);
export type IconNames = z.infer<typeof IconEnum>;

interface IconProps {
  iconName: IconNames;
  width?: number;
}

export default function Icon({ iconName, width = 50 }: IconProps) {
  switch (iconName) {
    case ICON_NAMES.thisPc:
      return <img src={thisPc} alt="" width={width} />;
    case ICON_NAMES.securityCenter:
      return <img src={securityCenter} alt="" width={width} />;
    case ICON_NAMES.policy:
      return <img src={policy} alt="" width={width} />;
    case ICON_NAMES.microsoftComplianceCenter:
      return (
        <img src={microsoftComplianceCenter} alt="" width={width} />
      );
    case ICON_NAMES.logAnalyticsWorkspace:
      return <img src={logAnalyticsWorkspace} alt="" width={width} />;
    case ICON_NAMES.azureSentinel:
      return <img src={azureSentinel} alt="" width={width} />;
    case ICON_NAMES.azureAdRoles:
      return <img src={azureAdRoles} alt="" width={width} />;
    case ICON_NAMES.azureAdPrivileges:
      return <img src={azureAdPrivileges} alt="" width={width} />;
    case ICON_NAMES.azureAd:
      return <img src={azureAd} alt="" width={width} />;
    case ICON_NAMES.defenderForEndpoint:
      return <img src={defenderForEndpoint} alt="" width={width} />;
    case ICON_NAMES.microsoftEndpointManager:
      return <img src={microsoftEndpointManager} alt="" width={width} />;
    default:
      return <img src={thisPc} alt="" width={width} />;
  }
}
