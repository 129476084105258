import i18n from '../../../../i18n';
import { ReviewItemDefinition } from '../../../../lib/game-data/types';

export const Phase04Review: ReviewItemDefinition[] = [
  {
    type: 'Dialog',
    title: i18n.t('Game01.Episode02.Phase04Review:dialog.intro.title'),
    body: i18n.t('Game01.Episode02.Phase04Review:dialog.intro.body'),
    nextLabel: i18n.t('common:buttons.letsGo'),
    character: 'andrea',
  },
];
