import * as React from 'react';
import Modal from '../../../../../components/Game01/Modal/Modal';
import background from '../images/02.04.140.png';
import { ComponentProps } from '../../../../task-types';
import MultipleChoice, { useMultipleChoice } from '../../../../../components/Global/Interactions/MultipleChoice/MultipleChoice';
import Button from '../../../../../components/Global/Button/Button';

export default function A140({ t, send, match }: ComponentProps): JSX.Element {
  const { errorKey, handleIncorrect } = useMultipleChoice(() => send('CLICK.INCORRECT'));

  return (
    <>
      <MultipleChoice
        questionText={t('A140.body')}
        didComplete={match('final')}
        onComplete={() => send('NEXT')}
        backgroundImage={background}
      >
        <Button
          isPrimary
          onClick={() => handleIncorrect('frequently')}
          label={t('A140.labels.frequently')}
          disabled={match('final')}
        />
        <Button
          isPrimary
          onClick={() => !match('final') && send('NEXT')}
          label={t('A140.labels.outside')}
          icon={match('final') ? 'check' : undefined}
        />
      </MultipleChoice>
      <Modal
        sidebar="character"
        title={t(`A140.errors.${errorKey}.title`)}
        body={t(`A140.errors.${errorKey}.body`)}
        isOpen={match('incorrect')}
        footer={<Button isPrimary label={t('common.errorTitle')} onClick={() => send('NEXT')} />}
        onDismiss={() => send('NEXT')}
        hideDismissButton
      />
    </>
  );
}
