import type { LocaleContent, CardOrEntryLocaleContent } from "../../types";

/**
 * i18Next `entries:Episode02.Task14` lead text content
 */
const Task14Lead: LocaleContent = {
  header: "Compliance Search for App Registration",
  role: "Compliance Administrator",
  time: "5m",
  brief: "Why did Frey create the app registration? Search for content using the Microsoft Compliance Center. See if you can find any messages that mention the app used in the OAuth attack.<br><br>-Andrea",
};

/**
 * i18Next `entries:Episode02.Task14` entry content
 */
const Task14Entries: CardOrEntryLocaleContent = {
  AppRegistrationRequest: {
    heading: "App Registration Request",
    RequestSender: "alex.gagnon@bestforyorganics.onmicrosoft.com Monday 11/8/2021 9:42 AM",
    RequestSubject: "Requested that Frey create an app registration for BFYOVacationTool",
    SearchForVacationTool: `Searched for references to "vacation tool".`,
    SearchByDate: "Searched for messages between 11/1 and 11/9.",
  },
};

export default {
  ...Task14Lead,
  ...Task14Entries,
};
