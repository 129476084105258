import * as React from 'react';
import { ComponentProps } from '../../../../task-types';
import Interstitial from '../../../../../components/Global/Interactions/Interstitial/Interstitial';
import background from '../images/02.04.060.png';

export default function A130({ t, send }: ComponentProps): JSX.Element {
  return (
    <Interstitial title={t('A130.body')} onClick={() => send('NEXT')} nextLabel={t('common.next')} backgroundImage={background} />
  );
}
