import * as React from 'react';
import { IStackItemStyles, IStackStyles, IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { mergeStyles } from '@fluentui/react';
import useActivityFocus from '../../../../../lib/hooks/useActivityFocus';
import Screen from '../../../../../components/Global/Screen/Screen';
import Panel from '../../../../../components/Global/Panel/Panel';
import { Heading, P } from '../../../../../components/Global/Text';
import useCharacter, { Characters } from '../../../../../lib/hooks/useCharacter';
import useBackground, { Backgrounds } from '../../../../../lib/hooks/useBackground';

interface Props {
  title: string;
  body?: string | JSX.Element;
  useDark?: boolean;
  characterLeft?: Characters;
  character?: Characters;
  background?: Backgrounds;
}

const interstitialWrapper: IStackStyles = {
  root: {
    'top': 500,
    'backgroundColor': 'white',
    'borderRadius': 6,
    'position': 'absolute',
    'zIndex': 2,
    '@media(min-width: 1040px)': {
      top: 50,
      maxWidth: 500,
    },
    '@media(min-width: 1240px)': {
      top: 150,
      maxWidth: 667,
    },
    '@media(min-width: 1650px)': {
      top: 150,
      maxWidth: 840,
    },
  },
};

const interstitialTokens: IStackTokens = {
  padding: 48,
  childrenGap: 32,
};

const itemStyles: IStackItemStyles = {
  root: {},
};

const characterWrapper: IStackStyles = {
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1,
  },
};

const characterImageStyles = mergeStyles({
  'width': 900,
  'marginRight': -100,
  'backgroundSize': 'cover',
  'transform': 'matrix(-1, 0, 0, 1, 0, 0)',
  '@media(min-width: 1020px)': {
    width: 900,
    marginRight: -100,
  },
  '@media(min-width: 1440px)': {
    width: 1000,
    marginRight: -30,
  },
  '@media(min-width: 1650px)': {
    width: 1000,
    marginRight: 0,
  },
  '@media(min-width: 1920px)': {
    width: 1200,
  },
});

export default function BranchingDialog({
  title,
  body,
  useDark = false,
  characterLeft,
  character,
  background,
  children,
}: React.PropsWithChildren<Props>): JSX.Element {
  const { focusRef } = useActivityFocus();

  const characterImageLeft = useCharacter(characterLeft);
  const characterImage = useCharacter(character);
  const backgroundImage = useBackground(background);

  return (
    <Screen backgroundImage={backgroundImage} cover={!!backgroundImage}>
      <Panel useDark={useDark} fullscreen>
        {!!characterImageLeft && (
          <Stack styles={characterWrapper} horizontalAlign="start">
            <img src={characterImageLeft} alt="CharacterLeft" className={characterImageStyles} />
          </Stack>
        )}
        {!!characterImage && (
          <Stack styles={characterWrapper} horizontalAlign="end">
            <img src={characterImage} alt="Character" className={characterImageStyles} />
          </Stack>
        )}

        <Stack styles={interstitialWrapper} tokens={interstitialTokens}>
          <Stack.Item styles={itemStyles}>
            {/* eslint-disable jsx-a11y/no-noninteractive-tabindex */}
            <span ref={focusRef} tabIndex={-1}>
              <Heading level={1} block noMargin>
                {title}
              </Heading>
            </span>
          </Stack.Item>
          <Stack.Item styles={itemStyles}>
            {body && (
              <P level={2} block>
                {body}
              </P>
            )}
          </Stack.Item>

          {children && (
            <Stack.Item styles={itemStyles}>
              <Stack horizontal={false} tokens={{ childrenGap: 10 }}>
                {children}
              </Stack>
            </Stack.Item>
          )}
        </Stack>
      </Panel>
    </Screen>
  );
}
