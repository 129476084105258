import { Memoize } from 'typescript-memoize';

import { GameDataBase } from './game-data-base';

import type { EpisodeData } from './episode-data';
import type { LeadData } from './lead-data';
import type {
  GameDataInstance,
  GameDataInstanceOf,
  GameDataKind,
  PhaseDefinition,
  ReviewItemDefinition,
} from './types';
import type { ActivityData } from './activity-data';

/**
 * Phase Data
 */
export class PhaseData extends GameDataBase {
  readonly kind = 'phase';

  declare readonly parent: EpisodeData | undefined;

  readonly leadIds: readonly string[] = [];

  readonly activityIds: readonly string[] = [];

  readonly review: { readonly baseScore: number; readonly data: ReviewItemDefinition[] } = { baseScore: 0, data: [] };

  constructor(data: PhaseDefinition, parent?: EpisodeData) {
    super(data, parent);

    const { leadIds, activityIds, review } = data;

    this.leadIds = leadIds ? Object.freeze([...leadIds]) : [];
    this.activityIds = activityIds ? Object.freeze([...activityIds]) : [];
    this.review = Object.freeze({ baseScore: data.reviewBasePointValue, data: [...review] });
  }

  getChildren(): readonly PhaseData[];
  getChildren<TKind extends GameDataKind | undefined>(filter?: TKind): readonly GameDataInstanceOf<TKind>[];
  @Memoize()
  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  getChildren(filter?: GameDataKind): readonly GameDataInstance[] {
    return Object.freeze([]);
  }

  @Memoize()
  getLeads() {
    const leads = this.leadIds
      .map((leadId) => this.find(leadId, 'lead'))
      .filter((lead): lead is LeadData => lead != null);
    return Object.freeze(leads);
  }

  @Memoize()
  getActivities() {
    const activities = this.activityIds
      .map((activityId) => this.find(activityId, 'activity'))
      .filter((activity): activity is ActivityData => activity != null);
    return Object.freeze(activities);
  }

  isComplete(completedTaskIds: string[]) {
    const phaseTaskIds = [];
    if (this.leadIds.length > 0) {
      phaseTaskIds.push(...this.getLeads().map((lead) => lead.id));
    }

    if (this.activityIds.length > 0) {
      phaseTaskIds.push(...this.getActivities().map((activity) => activity.id));
    }
    // check that all phase lead ids appear in completedTaskIds
    return phaseTaskIds.every((phaseLeadId) => completedTaskIds.includes(phaseLeadId));
  }
}
