import { ReactComponent as Deactivated } from './deactivated.svg';

/**
 * Episode01: All task components in a single namespace.
 */

export default {
  completed: Deactivated,
  deactivated: Deactivated,
  activated: Deactivated,
};
