import React from 'react';
import { Stack } from '@fluentui/react';

import { EntryComponentProps } from '../../../lib/entries';
import EntryUnread from './EntryUnread';
import EntryContent from './EntryContent';
// import EntryIconCol from './EntryIconCol';
import { toHTMLSafeId } from '../../../lib/util';
import { getCurrentGameTheme } from '../../../theme';
import { useAppDispatch, useAppSelector } from '../../../store';
import { setEntriesSeen } from '../../../store/journal-slice';

const theme = getCurrentGameTheme('Game01');

const Entry: React.FC<EntryComponentProps> = ({ entryData, entity, date, heading, content, unread }) => {
  const activePanel = useAppSelector((state) => state.game.activePanel);
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    if (
      entity.unread &&
      ((activePanel === 'journal' && entryData.kind === 'evidence') ||
        (activePanel === 'policies' && entryData.kind === 'policy'))
    ) {
      dispatch(setEntriesSeen(entity.id));
    }
  }, [activePanel, entryData, entity, dispatch]);

  return (
    <Stack
      horizontalAlign="stretch"
      as="article"
      aria-labelledby={toHTMLSafeId(entity.id, 'headerText')}
      horizontal
      tokens={{ childrenGap: '10px', padding: '10px 30px 12px 35px' }}
      styles={{
        root: {
          position: 'relative',
          backgroundColor: theme.palette.white,
          minHeight: '10px',
          borderBottom: `3px solid ${theme.semanticColors.notebookEntryBorder}`,
          overflow: 'auto',
          scrollbarColor: theme.semanticColors.notebookEntryBorder,
        },
      }}
    >
      <Stack.Item styles={{ root: { width: '100%' } }}>
        <EntryUnread unread={unread} />
        <EntryContent date={date} heading={heading} content={content} entry_id={entity.id} />
      </Stack.Item>
    </Stack>
  );
};
export default Entry;
