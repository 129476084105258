import tutorial001 from '../../static/images/Game01/tutorial/wh_ep1_tutorial_001.jpg';
import tutorial002 from '../../static/images/Game01/tutorial/wh_ep1_tutorial_002.jpg';
import tutorial003 from '../../static/images/Game01/tutorial/wh_ep1_tutorial_003.jpg';
import tutorial004 from '../../static/images/Game01/tutorial/wh_ep1_tutorial_004.jpg';
import tutorial005 from '../../static/images/Game01/tutorial/wh_ep1_tutorial_005.jpg';
import tutorial006 from '../../static/images/Game01/tutorial/wh_ep1_tutorial_006.jpg';
import tutorial007 from '../../static/images/Game01/tutorial/wh_ep1_tutorial_007.jpg';
import tutorial008 from '../../static/images/Game01/tutorial/wh_ep1_tutorial_008.jpg';

export type TutorialScreens =
  | 'none'
  | 'pickDisplayName'
  | 'tutorial001'
  | 'tutorial002'
  | 'tutorial003'
  | 'tutorial004'
  | 'tutorial005'
  | 'tutorial006'
  | 'tutorial007'
  | 'tutorial008';

const tutorialScreens: { [key: string]: string } = {
  tutorial001,
  tutorial002,
  tutorial003,
  tutorial004,
  tutorial005,
  tutorial006,
  tutorial007,
  tutorial008,
};

export default function useTutorialScreens(tutorialScreen?: string): string | undefined {
  if (!tutorialScreen || tutorialScreen === 'none') {
    return undefined;
  }

  return tutorialScreens[tutorialScreen];
}
