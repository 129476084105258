/**
 * Data for player avatar presentation (colors, styles, icons)
 */

/** Player color definitions */
export const PlayerColors = Object.freeze({
  pink: Object.freeze({
    border: '#E02CAD',
    background: '#808080',
  }),
  orange: Object.freeze({
    border: '#FF8E3C',
    background: '#808080',
  }),
  blue: Object.freeze({
    border: '#28A7E9',
    background: '#808080',
  }),
  gray: Object.freeze({
    border: '#CCCCCC',
    background: '#808080',
  }),
});
/* eslint-disable-next-line @typescript-eslint/no-redeclare */
export type PlayerColors = typeof PlayerColors;

const playerColorsByIndex = Object.freeze([PlayerColors.pink, PlayerColors.orange, PlayerColors.blue]);

/**
 * Get color definition from player color data by index or name
 * (with graceful fallbacks.)
 */
export function getPlayerColor(lookup?: number | keyof PlayerColors) {
  if (typeof lookup === 'number') {
    return playerColorsByIndex[lookup] ?? PlayerColors.gray;
  }

  return PlayerColors[lookup ?? 'gray'];
}
