import * as React from 'react';
import { Trans } from 'react-i18next';
import Screen from '../../../../../components/Global/Screen/Screen';
import background from '../images/05B.100.png';
import { ComponentProps } from '../../../../task-types';
import Interstitial from '../../../../../components/Global/Interactions/Interstitial/Interstitial';
import Modal from '../../../../../components/Game01/Modal/Modal';
import Button from '../../../../../components/Global/Button/Button';
import { useCompleteActivity } from '../../../../../lib/hooks/useCompleteLead';

export default function B100({ t, send, match }: ComponentProps): JSX.Element {
  const { completeActivity, completeLabel } = useCompleteActivity();

  return (
    <Screen>
      <Interstitial
        title={t('B100.title')}
        body={t('B100.body')}
        onClick={() => send('NEXT')}
        nextLabel={t('common.done')}
        backgroundImage={background}
      />
      <Modal
        sidebar="character"
        isOpen={match('final')}
        title={t('B100.feedback.title')}
        footer={<Button isPrimary label={completeLabel} onClick={() => completeActivity()} />}
        onDismiss={completeActivity}
        body={<Trans t={t} i18nKey="B100.feedback.body" components={[<strong />]} />}
        hideDismissButton
      />
    </Screen>
  );
}
