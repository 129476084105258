import type { LocaleContent, CardOrEntryLocaleContent } from "../../types";

/**
 * i18Next `entries:Episode01.Task05` lead text content
 */
const Task05Lead: LocaleContent = {
  header: "Set Up Compliance Policies",
  role: "Compliance Administrator",
  time: "10m",
  brief: "The information in the leaked file is confidential and should have been protected. The legal and executive team want us to set up a sensitivity label for the eCommerce app team. It should encrypt files and emails that contain credit card information. Use an auto-labeling policy to apply it.<br><br>-Andrea",
};

/**
 * i18Next `entries:Episode01.Task05` entry content
 */
const Task05Entries: CardOrEntryLocaleContent = {};

export default {
  ...Task05Lead,
  ...Task05Entries,
};
