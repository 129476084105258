import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { useGameService } from '../../state/GlobalStateProvider';
import { GameContext, GameEvent } from '../../state/game-service';
import { getMatcherResults, StateMatcher } from '../../lib/xstate/matchers';
import Tutorial from '../../components/Game01/Tutorial/Tutorial';
import { setCurrentPhase } from '../../store/multiplayer-slice';
import { useAppDispatch } from '../../store';

const Game01Episode02TutorialTest: React.FC = () => {
  const dispatch = useAppDispatch();
  const { state, setState } = useGameService();

  // Jump to Choose Lead in Episode02.Phase01
  React.useEffect(() => {
    dispatch(setCurrentPhase('Game01.Episode02.Phase01'));
    setState('tutorial', {});
  }, [dispatch, setState]);

  // Generate the list of content to match on once.
  // (Add dependencies for regeneration if necessary later)
  const stateMatchers = React.useMemo<StateMatcher<GameContext, GameEvent>[]>(
    () => [
      {
        states: 'tutorial',
        content: <Tutorial />,
      },
    ],
    [],
  );

  // Generate matched content to display every time the state or matchers change.
  const matchedContent = React.useMemo(
    () =>
      getMatcherResults(state, stateMatchers, {
        contentWrapper: (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            // Note: The inline style below ensures activity backgrounds fill the entire viewport and panels connect to the bottom of the screen
            style={{ height: '100%' }}
          />
        ),
        resultsWrapper: <AnimatePresence exitBeforeEnter />,
      }),
    [state, stateMatchers],
  );

  return <>{matchedContent}</>;
};

export default Game01Episode02TutorialTest;
