import { Memoize } from 'typescript-memoize';

import type { XYPosition } from 'react-flow-renderer';

import { GameDataBase } from './game-data-base';
import { LeadData } from './lead-data';
import { PhaseData } from './phase-data';
import { EvidenceData } from './evidence-data';
import { PolicyData } from './policy-data';

import type { EpisodeDefinition, GameDataInstance, GameDataInstanceOf, GameDataKind } from './types';
import { SafeDictionary } from '../types';
import type { GameData } from './game-data';
import { ElementData, ElementDataNode } from './element-data';
import { ActivityData } from './activity-data';

/**
 * Episode Data
 */
export class EpisodeData extends GameDataBase {
  readonly kind = 'episode';

  declare readonly parent: GameData | undefined;

  readonly defaultcardsIDs: string[] = [];

  readonly defaultclueIDs: string[] = [];

  readonly phases: readonly PhaseData[] = [];

  readonly leads: readonly LeadData[] = [];

  readonly activities: readonly ActivityData[] = [];

  readonly evidence: readonly EvidenceData[] = [];

  readonly elements: readonly ElementData[] = [];

  readonly policies: readonly PolicyData[] = [];

  readonly mapPositions: Readonly<SafeDictionary<XYPosition>> = {};

  readonly wirePositions: Readonly<SafeDictionary<XYPosition[]>> = {};

  constructor(data: EpisodeDefinition, parent?: GameData) {
    super(data, parent);

    const {
      phases,
      defaultcardsIDs,
      defaultclueIDs,
      leads,
      activities,
      evidence,
      elements,
      policies,
      mapPositions,
      wirePositions,
    } = data;
    // Parse lead definitions
    if (phases != null) {
      this.phases = phases.map((def) => new PhaseData(def, this));
    }
    Object.freeze(this.phases);

    // Parse lead definitions
    if (Array.isArray(leads)) {
      this.leads = leads.map((def) => new LeadData(def, this));
    } else if (leads != null) {
      this.leads = Object.entries(leads).map(([key, def]) => new LeadData({ id: key, ...def }, this));
    }
    Object.freeze(this.leads);

    // Parse activity definitions
    if (Array.isArray(activities)) {
      this.activities = activities.map((def) => new ActivityData(def, this));
    } else if (activities != null) {
      this.activities = Object.entries(activities).map(([key, def]) => new ActivityData({ id: key, ...def }, this));
    }
    Object.freeze(this.leads);

    // Parse evidence definitions
    if (Array.isArray(evidence)) {
      this.evidence = evidence.map((def) => new EvidenceData(def, this));
    } else if (evidence != null) {
      this.evidence = Object.entries(evidence).map(([key, def]) => new EvidenceData({ id: key, ...def }, this));
    }
    Object.freeze(this.evidence);

    // Parse elements definitions
    if (Array.isArray(elements)) {
      this.elements = elements.map((def) => new ElementData(def, this));
    } else if (elements != null) {
      this.elements = Object.entries(elements).map(([key, def]) => new ElementData({ id: key, ...def }, this));
    }
    Object.freeze(this.elements);

    // Parse policy definitions
    if (Array.isArray(policies)) {
      this.policies = policies.map((def) => new PolicyData(def, this));
    } else if (policies != null) {
      this.policies = Object.entries(policies).map(([key, def]) => new PolicyData({ id: key, ...def }, this));
    }
    Object.freeze(this.policies);

    if (Array.isArray(defaultcardsIDs)) {
      this.defaultcardsIDs = defaultcardsIDs;
    }
    Object.freeze(this.defaultcardsIDs);

    if (Array.isArray(defaultclueIDs)) {
      this.defaultclueIDs = defaultclueIDs;
    }
    Object.freeze(this.defaultclueIDs);

    // Parse evidence map positions
    if (mapPositions != null) {
      this.mapPositions = Object.entries(mapPositions).reduce((accumulator, [key, position]) => {
        accumulator[key] = Object.freeze({ ...position });
        return accumulator;
      }, {} as SafeDictionary<XYPosition>);
    }
    Object.freeze(this.mapPositions);

    // Parse evidence map positions
    if (wirePositions != null) {
      this.wirePositions = Object.entries(wirePositions).reduce((accumulator, [key, positions]) => {
        if (!accumulator[key]) accumulator[key] = [];
        positions.forEach((position) => accumulator[key]?.push(Object.freeze({ ...position })));
        return accumulator;
      }, {} as SafeDictionary<XYPosition[]>);
    }
    Object.freeze(this.wirePositions);
  }

  getChildren(): readonly (PhaseData | LeadData | EvidenceData | PolicyData)[];
  getChildren<TKind extends GameDataKind | undefined>(filter?: TKind): readonly GameDataInstanceOf<TKind>[];
  @Memoize()
  getChildren(filter?: GameDataKind): readonly GameDataInstance[] {
    switch (filter) {
      case undefined:
        return Object.freeze([
          ...this.phases,
          ...this.leads,
          ...this.evidence,
          ...this.policies,
          ...this.elements,
          ...this.activities,
        ]);
      case 'phase':
        return this.phases;
      case 'lead':
        return this.leads;
      case 'activity':
        return this.activities;
      case 'evidence':
        return this.evidence;
      case 'element':
          return this.elements;
      case 'policy':
        return this.policies;
      default:
        return Object.freeze([]);
    }
  }

  @Memoize()
  getUnlockedElements(): readonly ElementDataNode[] {
    return this.elements.filter((element) => element.type === 'node' && element.unlocked) as ElementDataNode[];
  }

  @Memoize()
  getActivityConnectedNodes(activityId?: string): readonly ElementDataNode[] {
    return this.getChildren('element').reduce<ElementDataNode[]>(
      (accumulator, element) => {
        if (!activityId ||
          element.type !== 'wire' ||
          !element.getLinks().find((link) => link.getTask()?.id === activityId)) {
            return accumulator;
        }

        const connectedNode = element.getLocation()?.getLocation();
        if (!connectedNode || accumulator.find((node) => node.id === connectedNode.id)) {
          return accumulator;
        }

        accumulator.push(connectedNode);
        return accumulator;
      }, [],
    );
  }

  /**
   * Get the evidence map position for a given ID.
   */
  getMapPosition(key: string, defaultValue: XYPosition = { x: 0, y: 0 }): XYPosition {
    // If `key` doesn't exist, try appending the episode ID (e.g. `Player1Card` -> `Episode01.Player1Card`)
    const value = this.mapPositions[key] ?? this.mapPositions[`${this.id}.${key}`];
    return value != null ? { ...value } : defaultValue;
  }

  /**
   * Get the evidence map position for a given wire ID.
   */
   getWirePositions(key: string, defaultValue: XYPosition[] = []): XYPosition[] {
    // If `key` doesn't exist, try appending the episode ID (e.g. `Player1Card` -> `Episode01.Player1Card`)
    const value = this.wirePositions[key] ?? this.wirePositions[`${this.parent?.id}.${this.id}.${key}`];
    return value != null ? value : defaultValue;
  }
}
