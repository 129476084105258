import { IStackItemStyles, IStackStyles, ITheme, Stack, useTheme } from '@fluentui/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LinkItUrl } from 'react-linkify-it';
import { Heading, P } from '../Text';
import Background from '../../../static/images/Game01/Episode01/backgrounds/screen-background-dark.jpg';
import EndImage from '../../../static/images/Game01/Episode01/backgrounds/Episode_1_End.png';
import { MPManager } from '../../../lib/socket';
import { APIManager } from '../../../lib/api/APIManager';

function outerStyles(theme: ITheme): IStackStyles {
  return {
    root: {
      backgroundColor: theme.palette.blueMid,
      backgroundImage: `url(${Background})`,
      backgroundSize: 'cover',
      color: theme.palette.themePrimary,
      height: '100vh',
      maxWidth: '100%',
      overflowY: 'auto',
    },
  };
}

function innerStyles(): IStackStyles {
  return {
    root: {
      width: '100%',
      height: '100%',
      margin: '0 auto',
      alignItems: 'flex-start',
      justifyContent: 'space-evenly',
      boxSizing: 'border-box',
      selectors: {
        '@media screen and (max-width: 1024px)': {
          alignItems: 'stretch',
        },
      },
    },
  };
}

function leftStyles(theme: ITheme): IStackItemStyles {
  return {
    root: {
      backgroundColor: theme.palette.white,
      padding: 30,
      borderRadius: theme.effects.roundedCorner4,
      width: '50%',
      marginTop: '5%',
      marginLeft: '10%',
      boxSizing: 'border-box',
      selectors: {
        '@media screen and (max-width: 1024px)': {
          width: 'auto',
          margin: '30px 30px 0',
        },
      },
    },
  };
}

function rightStyles(): IStackItemStyles {
  return {
    root: {
      width: '100%',
      overflow: 'hidden',
      flex: 1,
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
      img: {
        display: 'block',
        maxHeight: '90%',
        maxWidth: '100%',
      },
    },
  };
}

const EventEnded: React.FC = () => {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const socket = MPManager.instance.setupSocket(process.env.REACT_APP_SOCKET_URL);
  const { event_slug, username, key } = APIManager.getConfig();

  useEffect(() => {
    socket.emit('update:user', {
      username,
      key,
      slug: event_slug,
      end_time: new Date(),
      location: 'Event Ended',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack styles={outerStyles(theme)}>
      <Stack styles={innerStyles()}>
        <Stack.Item styles={leftStyles(theme)}>
          <Heading level={2} block noMargin>
            {t('event.thankYou')}
          </Heading>
          <P level={3} block>
            {t('event.eventIsOver')}
          </P>
          <LinkItUrl>
            <P level={3} block>
              {t('event.surveyLink')}
            </P>
          </LinkItUrl>
        </Stack.Item>
        <Stack.Item styles={rightStyles()}>
          <img src={EndImage} alt="" />
        </Stack.Item>
      </Stack>
    </Stack>
  );
};

export default EventEnded;
