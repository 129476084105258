import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { DefaultButton, Icon, IconButton, IIconProps, IIconStyles, IStackItemStyles, IStackStyles, Stack } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useBoolean } from '@fluentui/react-hooks';
import { P } from '../../Text';
import { SessionGroup, UserInfo } from '../schemas';
import FacilitatorUserListItem from './FacilitatorUserListItem';
import { toggleActiveSession } from '../../../../store/facilitator-slice';
import FacilitatorResolveHelpDialog from './FacilitatorResolveHelpDialog';
import FacilitatorConfirmDialog from './FacilitatorConfirmDialog';
import { getCurrentGameTheme } from '../../../../theme';

export interface ISessionListItemProps {
  session: SessionGroup;
  users: UserInfo[];
  showDisconnect: boolean;
  onDisconnectUser: Function;
  onRemoveUser: Function;
  active: boolean;
}

const theme = getCurrentGameTheme('Global');

const wrapperStyles: IStackItemStyles = {
  root: {
    backgroundColor: '#e6e6e6',
    marginBottom: 20,
  },
};
const headerStyles: IStackStyles = {
  root: {
    backgroundColor: '#d2d2d2',
    padding: '12px 16px',
    borderBottom: 'solid 2px #bbb',
    alignItems: 'center',
  },
};

function requestingHelpStackStyles(users: UserInfo[] | undefined): IIconStyles {
  const requesting_help = users?.find((user) => {
    return user.requesting_help === true;
  });

  return {
    root: {
      opacity: requesting_help ? '1' : '0.2',
      color: requesting_help ? '#28a7e9' : 'inherit',
    },
  };
}

function facilitatorHelping(isHelping: boolean | undefined): IIconStyles {
  return {
    root: {
      opacity: isHelping ? '1' : '0.2',
      color: isHelping ? theme.palette.themeLight : 'inherit',
    },
  };
}

const setUserIntoStateAndToggle = (user: UserInfo | null, set: Function, toggle: Function) => {
  set(user);
  toggle();
};

const SessionListItem: React.FC<ISessionListItemProps> = (
  { session, active, users, showDisconnect = false, onDisconnectUser, onRemoveUser },
) => {
  const { t } = useTranslation('common');
  const color = theme.palette.themePrimary;
  const dispatch = useDispatch();
  const toggleIcon: IIconProps = active ? { iconName: 'ChevronUpSmall' } : { iconName: 'ChevronDownSmall' };
  const [hideResolveHelpDialog, { toggle: toggleHelpDialog }] = useBoolean(true);
  const [userToResolve, setUserToResolve] = useState<UserInfo | null>(null);
  const [showDisconnectDialog, { toggle: toggleDisconnectDialog }] = useBoolean(false);
  const [showReleaseDialog, { toggle: toggleReleaseDialog }] = useBoolean(false);
  const [showRemoveDialog, { toggle: toggleRemoveDialog }] = useBoolean(false);
  const onClickToggle = () => {
    dispatch(toggleActiveSession(session));
  };
  return (
    <Stack.Item styles={wrapperStyles}>
      <Stack horizontal styles={headerStyles}>
        <Stack.Item>
          <Stack horizontal>
            <Stack.Item>
              <Icon iconName="faHandPaper" styles={requestingHelpStackStyles(users)} />
            </Stack.Item>
            <Stack.Item style={{ marginLeft: 6 }}>
              <Icon iconName="faUserHeadset" styles={facilitatorHelping(session.facilitators && session.facilitators.length > 0)} />
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item style={{ marginLeft: 15, marginRight: 20 }}>
          <P level={2} color={color}><strong>{session.name}</strong></P>
        </Stack.Item>
        <Stack.Item grow>
          <DefaultButton
            iconProps={{ iconName: 'SkypeMessage' }}
            text="Chat"
            style={{ padding: 5, borderWidth: 1, fontSize: 14 }}
            onClick={() => onClickToggle()}
          />
        </Stack.Item>
        <Stack.Item>
          <IconButton iconProps={toggleIcon} onClick={() => onClickToggle()} />
        </Stack.Item>
      </Stack>
      {active === true && (
      <Stack>
        {(users.map((u: any) => (
          <FacilitatorUserListItem
            key={`sfuli_${u.username}`}
            user={u}
            showDisconnect={showDisconnect}
            onDisconnect={() => {
              setUserIntoStateAndToggle(u, setUserToResolve, toggleDisconnectDialog);
            }}
            onRelease={() => {
              setUserIntoStateAndToggle(u, setUserToResolve, toggleReleaseDialog);
            }}
            onRemove={() => {
              setUserIntoStateAndToggle(u, setUserToResolve, toggleRemoveDialog);
            }}
            onResolveHelp={() => {
              setUserIntoStateAndToggle(u, setUserToResolve, toggleHelpDialog);
            }}
          />
        )))}
      </Stack>
      )}
      {userToResolve !== null && !hideResolveHelpDialog && (
        <FacilitatorResolveHelpDialog
          user={userToResolve}
          hideResolveHelpDialog={hideResolveHelpDialog}
          toggleResolveHelpDialog={
            () => {
              setUserIntoStateAndToggle(null, setUserToResolve, toggleHelpDialog);
            }
          }
        />
      )}
      {showDisconnectDialog && (
        <FacilitatorConfirmDialog
          title={t('facilitator.disconnectUser')}
          subText={t('facilitator.confirmDisconnectDialogText', { name: userToResolve?.display_name ? userToResolve?.display_name : userToResolve?.username })}
          showDialog={showDisconnectDialog}
          onDismiss={() => toggleDisconnectDialog()}
          onConfirm={
            () => {
              onDisconnectUser(userToResolve?.username);
              toggleDisconnectDialog();
            }
          }
        />
      )}
      {showReleaseDialog && (
        <FacilitatorConfirmDialog
          title={t('facilitator.releaseUser')}
          subText={t('facilitator.confirmReleaseDialogText', { name: userToResolve?.display_name ? userToResolve?.display_name : userToResolve?.username })}
          showDialog={showReleaseDialog}
          onDismiss={() => toggleReleaseDialog()}
          onConfirm={
            () => {
              onDisconnectUser(userToResolve?.username);
              toggleReleaseDialog();
            }
          }
        />
      )}
      {showRemoveDialog && (
        <FacilitatorConfirmDialog
          title={t('facilitator.removeUser')}
          subText={t('facilitator.confirmRemoveDialogText', { name: userToResolve?.display_name ? userToResolve?.display_name : userToResolve?.username })}
          showDialog={showRemoveDialog}
          onDismiss={() => toggleRemoveDialog()}
          onConfirm={
            () => {
              onRemoveUser(userToResolve?.username);
              toggleRemoveDialog();
            }
          }
        />
      )}
    </Stack.Item>
  );
};
export default SessionListItem;
