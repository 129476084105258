import React from 'react';
import { IStackItemStyles, ITextStyles, Stack, Text } from '@fluentui/react';

import _clamp from 'lodash/clamp';

export interface IMapCardProgressProps {
  count?: number;
  total?: number;
}

const cardProgressTextStyles: ITextStyles = {
  root: {
    color: '#000000',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
  },
};

const cardProgressOuterBarStyles: IStackItemStyles = {
  root: {
    marginTop: '4px',
    width: '100%',
    height: '9px',
    background: '#999999',
    borderRadius: '80px',
    overflow: 'hidden',
  },
};

const cardProgressInnerBarStyles: IStackItemStyles = {
  root: {
    backgroundColor: '#2f2f2f',
    borderRadius: '90px',
    height: '100%',
  },
};

function getPercentage(count: number, total: number) {
  const percentage = count / total;
  if (Number.isNaN(percentage)) {
    return 100;
  }
  return _clamp(percentage * 100, 0, 100);
}

/**
 * Evidence Map React-Flow card progress bar component.
 */
const MapCardProgress: React.FC<IMapCardProgressProps> = ({ count = 0, total = 0 }) => {
  return (
    <Stack.Item disableShrink>
      <Text styles={cardProgressTextStyles} block>
        {count}/{total}
      </Text>
      <Stack.Item disableShrink styles={cardProgressOuterBarStyles}>
        <Stack.Item styles={cardProgressInnerBarStyles} style={{ width: `${getPercentage(count, total)}%` }}>
          {'\u200b'}
        </Stack.Item>
      </Stack.Item>
    </Stack.Item>
  );
};

export default MapCardProgress;
