import * as React from 'react';
import { registerIcons } from '@fluentui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBookOpen,
  faComment,
  faQuestion,
  faCog,
  faFileAlt,
  faMapMarked,
  faCheck,
  faHandPaper,
  faCheckCircle,
  faPlus,
  faMinus,
  faSearch,
  faTimes,
  faCommentDots,
} from '@fortawesome/free-solid-svg-icons';
import {
  faHandPaper as FaHandPaperOutline,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faUserHeadset,
} from '@fortawesome/pro-solid-svg-icons';
import { z } from 'zod';

export const Icons = z.enum([
  'navBarJournal',
  'navBarChat',
  'navBarEvidenceMap',
  'navBarHelp',
  'navBarPolicy',
  'navBarSettings',
  'check',
  'faHandPaper',
  'FaHandPaperOutline',
  'faCheckCircle',
  'plus',
  'minus',
  'faSearch',
  'times',
  'faCommentDots',
  'faUserHeadset',
]);

export type IconEnum = z.infer<typeof Icons>;

// component for registering FontAwesome Icons with the FluentUI framework
export default function CustomIconProvider({ children }: React.PropsWithChildren<{}>) {
  registerIcons({
    icons: {
      [Icons.enum.navBarJournal]: <FontAwesomeIcon icon={faBookOpen} />,
      [Icons.enum.navBarChat]: <FontAwesomeIcon icon={faComment} />,
      [Icons.enum.navBarEvidenceMap]: <FontAwesomeIcon icon={faMapMarked} />,
      [Icons.enum.navBarHelp]: <FontAwesomeIcon icon={faQuestion} />,
      [Icons.enum.navBarPolicy]: <FontAwesomeIcon icon={faFileAlt} />,
      [Icons.enum.navBarSettings]: <FontAwesomeIcon icon={faCog} />,
      [Icons.enum.check]: <FontAwesomeIcon icon={faCheck} />,
      [Icons.enum.faHandPaper]: <FontAwesomeIcon icon={faHandPaper} />,
      [Icons.enum.FaHandPaperOutline]: <FontAwesomeIcon icon={FaHandPaperOutline} />,
      [Icons.enum.faCheckCircle]: <FontAwesomeIcon icon={faCheckCircle} />,
      [Icons.enum.plus]: <FontAwesomeIcon icon={faPlus} />,
      [Icons.enum.minus]: <FontAwesomeIcon icon={faMinus} />,
      [Icons.enum.faSearch]: <FontAwesomeIcon icon={faSearch} />,
      [Icons.enum.times]: <FontAwesomeIcon icon={faTimes} />,
      [Icons.enum.faCommentDots]: <FontAwesomeIcon icon={faCommentDots} />,
      [Icons.enum.faUserHeadset]: <FontAwesomeIcon icon={faUserHeadset} />,
    },
  });
  return <>{children}</>;
}
