import * as React from 'react';
import background from '../images/wh_ep2_lead_2.2_02.02.060.png';
import { ComponentProps } from '../../../../task-types';
import MultipleChoice from '../../../../../components/Global/Interactions/MultipleChoice/MultipleChoice';
import Button from '../../../../../components/Global/Button/Button';
import RTrans from '../../../../../components/Global/RTrans/RTrans';

export default function A080({ t, send, match }: ComponentProps): JSX.Element {
  return (
    <>
      <MultipleChoice
        questionText={<RTrans t={t} i18nKey="A060.body" />}
        didComplete={match('Defaultaspx')}
        onComplete={() => send('NEXT')}
        backgroundImage={background}
      >
        <Button
          isPrimary
          onClick={() => !match('Defaultaspx') && send('CLICK.Defaultaspx')}
          label={t('A060.labels.Defaultaspx')}
          icon={match('Defaultaspx') ? 'check' : undefined}
        />
        <Button
          isPrimary
          onClick={() => !match('Defaultaspxcs') && send('CLICK.Defaultaspxcs')}
          label={t('A060.labels.Defaultaspxcs')}
          icon={match('Defaultaspxcs') ? 'check' : undefined}
        />
        <Button
          isPrimary
          onClick={() => !match('ProjectDcsproj') && send('CLICK.ProjectDcsproj')}
          label={t('A060.labels.ProjectDcsproj')}
          icon={match('ProjectDcsproj') ? 'check' : undefined}
        />
        <Button
          isPrimary
          onClick={() => !match('Webconfig') && send('CLICK.Webconfig')}
          label={t('A060.labels.Webconfig')}
          icon={match('Webconfig') ? 'check' : undefined}
        />
      </MultipleChoice>
    </>
  );
}
