import { GameDataBase } from './game-data-base';
import { devLogErr } from '../util';

import type { ArrayOrSingle } from '../types';
import type { GameDataInstance, GameDataInstanceOf, GameDataKind } from './types';

/**
 * Store for collecting/querying game data instances.
 */
export class GameDataStore implements Iterable<GameDataInstance> {
  readonly kind = 'store';

  readonly data: Readonly<Record<string, GameDataInstance>>;

  readonly keys: readonly string[];

  readonly values: readonly GameDataInstance[];

  readonly size: number;

  constructor(data: GameDataInstance | GameDataInstance[]) {
    this.data = Object.freeze(
      (Array.isArray(data) ? data : [data]).reduce(
        (accumulator, instance) => GameDataStore.#extractFlatData(instance, accumulator),
        {},
      ),
    );
    this.keys = Object.freeze(Object.keys(this.data));
    this.values = Object.freeze(Object.values(this.data));
    this.size = this.keys.length;

    this.values.forEach((instance) => {
      const newChild = instance as any;
      newChild.store = this;
    });

    // Lock down properties.
    Object.defineProperties(this, {
      data: {
        configurable: false,
        enumerable: true,
        writable: false,
      },
      keys: {
        configurable: false,
        enumerable: true,
        writable: false,
      },
      values: {
        configurable: false,
        enumerable: true,
        writable: false,
      },
      size: {
        configurable: false,
        enumerable: false,
        writable: false,
      },
    });
  }

  /**
   * Check if an entry exists in the store by ID.
   */
  has(id: string) {
    return this.data.hasOwnProperty(id);
  }

  /**
   * Get data instance by ID, optionally filtered by `kind`.
   */
  get<TKind extends GameDataKind | undefined>(
    id: string | null | undefined,
    filter?: TKind,
  ): GameDataInstanceOf<TKind> | undefined;
  /**
   * Get data instances by IDs, filtered by `kind`.
   */
  get<TKind extends GameDataKind | undefined>(
    ids: (string | null | undefined)[],
    filter?: TKind,
  ): GameDataInstanceOf<TKind>[];
  /** Implementation signature */
  get(
    input?: ArrayOrSingle<string | null | undefined>,
    filter?: GameDataKind,
  ): GameDataInstance | GameDataInstance[] | undefined {
    // Handle array[] overload
    if (Array.isArray(input)) {
      return input.reduce((accumulator, id) => {
        if (id != null && this.has(id)) {
          const result = this.data[id];
          if (filter == null || result.kind === filter) {
            accumulator.push(result);
          }
        }
        return accumulator;
      }, [] as GameDataInstance[]);
    }

    // Handle string overload
    if (input != null && this.has(input)) {
      const result = this.data[input];
      if (filter == null || result.kind === filter) {
        return result;
      }
    }
    return undefined;
  }

  /**
   * Method compatible with `GameDataBase.find()` interface that proxies to
   * `GameDataStore.get()`
   * NOTE: This will ignore relative path and root prefixes.
   *
   * @see {GameDataBase.find} for more details
   *
   * @todo Clean this up/optimizie along with GameDataBase.find()
   */
  find<TKind extends GameDataKind | undefined>(
    id: string | readonly string[] | null | undefined,
    filter?: TKind,
  ): GameDataInstanceOf<TKind> | undefined;
  /** Implementation signature */
  find(input?: string | readonly string[] | null | undefined, filter?: GameDataKind): GameDataInstance | undefined {
    // Coerce query into path.
    const [queryPath] = GameDataBase.resolveQueryPath(input);
    // Validate path
    if (!GameDataBase.validatePath(queryPath)) {
      return undefined;
    }

    // Return results.
    const targetId = queryPath.join('.');
    return this.get(targetId, filter);
  }

  [Symbol.iterator]() {
    return this.values[Symbol.iterator]();
  }

  static #extractFlatData(instance: GameDataInstance, accumulator: Record<string, GameDataInstance> = {}) {
    const id = instance.id;
    if (accumulator.hasOwnProperty(id)) {
      devLogErr(`Collision: ${id}!`, { old: accumulator[id], new: instance });
    }
    accumulator[id] = instance;

    Object.values(instance).forEach((value) => {
      if (!Array.isArray(value) || !(value[0] instanceof GameDataBase)) {
        return;
      }
      value.forEach((childInstance) => GameDataStore.#extractFlatData(childInstance, accumulator));
    });

    return accumulator;
  }
}
