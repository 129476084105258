import { ReactComponent as BaseBackground } from './base-background.svg';
import { ReactComponent as BaseForeground } from './base-foreground.svg';
import { ReactComponent as MidBackground } from './mid-background.svg';
import { ReactComponent as MideForeground } from './mid-foreground.svg';
import { ReactComponent as TopSingle } from './top-single.svg';
import { ReactComponent as TopDouble } from './top-double.svg';

/**
 * Episode01: All task components in a single namespace.
 */

export default {
  baseBack: BaseBackground,
  baseFore: BaseForeground,
  midBack: MidBackground,
  midFore: MideForeground,
  topDouble: TopDouble,
  topSingle: TopSingle,
};
