import React from 'react';
import { InterpreterStatus } from 'xstate';
import { useSelector as useXSelector } from '@xstate/react';
import { gameService } from './game-service';
import { useReactEffectActions } from '../lib/hooks/xstate/useReactEffectActions';
import { useServiceSettable } from '../lib/hooks/xstate/useServiceSettable';

import type { GameInterpreter, GameState } from './game-service';
import { executeGlobalActions } from './GlobalActionListener';
// import { devLog } from '../lib/util';

export const GlobalStateContext = React.createContext({ gameService });

export const GlobalStateProvider: React.FC = ({ children }) => {
  React.useEffect(() => {
    if (gameService.status !== InterpreterStatus.Running) {
      gameService.start();
    }

    const subscribtion = gameService.subscribe(executeGlobalActions);

    return () => {
      gameService.stop();
      subscribtion.unsubscribe();
    };
  }, []);
  useReactEffectActions(gameService);

  return <GlobalStateContext.Provider value={{ gameService }}>{children}</GlobalStateContext.Provider>;
};

/** Hook to directly provide gameService as actor with settable state */
export function useGameService() {
  const globalServices = React.useContext(GlobalStateContext);
  return useServiceSettable(globalServices.gameService);
}

/**
 * Hook to directly and efficiently provide XState's useSelector() from the game service.
 * (Using `useSelector()` on `service` from `useGameService()` will nest 2 `useActor()` hooks.)
 */
export function useGameServiceSelector<T>(
  selector: (emitted: GameState) => T,
  compare?: (a: T, b: T) => boolean,
  getSnapshot?: (a: GameInterpreter) => GameState,
) {
  const globalServices = React.useContext(GlobalStateContext);
  return useXSelector<GameInterpreter, T, GameState>(globalServices.gameService, selector, compare, getSnapshot);
}
