import * as React from 'react';
import { Text as FluentText } from '@fluentui/react/lib/Text';
import getFontVariant, { TextComponent } from './getFontVariant';
import TextProps from './interface';
import { getCurrentGameTheme } from '../../../theme';

type HeadingLevels = 1 | 2 | 3 | 4 | 5;
interface Props extends TextProps {
  level: HeadingLevels;
  as?: HeadingLevels;
  color?: string;
}

const headings: { [key: number]: React.ElementType<React.HTMLAttributes<HTMLElement>> } = {
  1: 'h1',
  2: 'h2',
  3: 'h3',
  4: 'h4',
  5: 'h5',
};

export default function Heading({
  children,
  level,
  as,
  block = false,
  nowrap = false,
  noMargin = false,
  useDark = false,
  center = false,
  color,
  ariaLive,
}: React.PropsWithChildren<Props>): JSX.Element {
  const theme = getCurrentGameTheme();
  const headingType = headings[as || level];
  return (
    <FluentText
      as={headingType}
      block={block}
      nowrap={nowrap}
      variant={getFontVariant(TextComponent.enum.Heading, level)}
      style={{
        ...(noMargin && { margin: 0 }),
        ...(useDark && { color: theme.palette.white }),
        ...(center && { textAlign: 'center' }),
        ...(color && { color }),
      }}
      aria-live={ariaLive}
    >
      {children}
    </FluentText>
  );
}
