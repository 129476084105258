import { motion, AnimatePresence } from 'framer-motion';
import React from 'react';
import { getMatcherResults, StateMatcher } from '../../../lib/xstate/matchers';
import { GameContext, GameEvent } from '../../../state/game-service';
import { useGameService } from '../../../state/GlobalStateProvider';
import EventEnded from '../EventEnded/EventEnded';
import EventLobby from '../EventLobby/EventLobby';
import Game from '../Game/Game';
import Suspension from '../Suspension/Suspension';
import Tutorial from '../../Game01/Tutorial/Tutorial';

/**
 * TODO: Currently this component only contains the EventLobby.
 * In the future this should contain a means of loading either the
 * lobby component, the game component (once an event is started or if
 * the player is rejoining a game that they have a saved state for),
 * or the event ended component (if the event is ended).
 * */

const Event: React.FC = () => {
  const { state } = useGameService();

  const stateMatchers = React.useMemo<StateMatcher<GameContext, GameEvent>[]>(
    () => [
      {
        states: 'lobby',
        content: <EventLobby />,
      },
      {
        states: 'tutorial',
        content: <Tutorial />,
      },
      {
        states: ['investigationPhase', 'reviewQuestions', 'conclusion', 'summary'],
        content: <Game useDevRoutes={false} />,
      },
      {
        states: 'eventEnded',
        content: <EventEnded />,
      },
      {
        states: 'userSuspended',
        content: <Suspension />,
      },
    ],
    [],
  );

  const matchedContent = React.useMemo(
    () =>
      getMatcherResults(state, stateMatchers, {
        contentWrapper: (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            // Note: The inline style below ensures activity backgrounds fill the entire viewport and panels connect to the bottom of the screen
            style={{ height: '100%' }}
          />
        ),
        resultsWrapper: <AnimatePresence exitBeforeEnter />,
      }),
    [state, stateMatchers],
  );

  return <>{matchedContent}</>;
};

export default Event;
