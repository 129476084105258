import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import LabFrame from '../../../../components/Global/LabFrame/LabFrame';
import { useSimVariableToAddEntryMap } from '../../../../lib/hooks/useSimVariableEntryMap';
import { devLog } from '../../../../lib/util';

import type { LabFrameOnMessage } from '../../../../components/Global/LabFrame/LabFrame';
import type { TSGSEvent } from '../../../types-tsgs';
import { useTaskMachine } from '../../../../lib/hooks/useTaskMachine';
import A015 from './components/A015';
import { createTask10 } from './machine';

const baseNS = 'Game01.Episode01.Task10';
const taskKey = 'Game01.Episode01.Task10';

const labURI = 'https://ctsdemos.azureedge.net/microsoft/SCI_Ep1Task10/index.html';

/**
 * This allows the player to get the same 3 "Sign-In Risk Policy" facts
 * regardless of whether they choose "Medium" or "High" Sign-In Risk.
 *
 * The FactData IDs match the variable IDs triggered in the "Medium" path,
 * so this copies that data to the equivalent "High" variable IDs.
 */
const labVariablesToCopy = [
  {
    sourceVar: 'IPSigninRiskPolicyAssignmentUsers',
    targetVar: 'IPSigninRiskHighAssignmentUsers',
  },
  {
    sourceVar: 'IPSigninRiskPolicyControlsRequireMFA',
    targetVar: 'IPSigninRiskHighControlsRequireMFA',
  },
  {
    sourceVar: 'IPSigninRiskPolicyEnforcePolicyOn',
    targetVar: 'IPSigninRiskHighEnforcePolicyOn',
  },
];

function createTaskMatch(parent: string, state: any) {
  return (matchState: string) => state.matches(`${parent}.${matchState}`);
}

const Task10: React.FC = () => {
  const [t] = useTranslation(baseNS);
  const dispatch = useDispatch();

  const baseLabVariableEntries = useSimVariableToAddEntryMap(taskKey);
  const labVariableEntries = React.useMemo(() => {
    // Make a shallow copy of the variable entries.
    const output = { ...baseLabVariableEntries };
    labVariablesToCopy.forEach(({ sourceVar, targetVar }) => {
      const sourceData = output[sourceVar] ?? [];
      const currentTargetData = output[targetVar] ?? [];
      // Merge data from sourceVar with current data for targetVar
      output[targetVar] = [...currentTargetData, ...sourceData];
    });
    return output;
  }, [baseLabVariableEntries]);
  devLog(labVariableEntries);

  // Setup task machine
  const [state, send] = useTaskMachine(createTask10, {
    taskKey,
  });

  const labMessageHandler = React.useCallback<LabFrameOnMessage<TSGSEvent>>(
    (message, frameWindow) => {
      const { data } = message;
      devLog('labMessageHandler', data, frameWindow);
      if (data.action === 'tsgs.VariableChanged') {
        labVariableEntries[data.key]?.forEach(dispatch);
      }
    },
    [dispatch, labVariableEntries],
  );

  return (
    <>
      {state.matches('A015') && <A015 t={t} send={send} state={state.context} match={createTaskMatch('A015', state)} />}
      {state.matches('A020') && <LabFrame src={labURI} title={t('title')} onMessage={labMessageHandler} />}
    </>
  );
  return <></>;
};

export default Task10;
