import React from 'react';
import { Stack, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { AnimatePresence, motion } from 'framer-motion';
import { Trans, useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faSignOut } from '@fortawesome/pro-regular-svg-icons';
import Entry from '../Entry/Entry';
import Button from '../../Global/Button/Button';
import { EntryComponentProps } from '../../../lib/entries';
import getFontVariant, { TextComponent } from '../../Global/Text/getFontVariant';
import { toHTMLSafeId, isDev, isStaging } from '../../../lib/util';
import { useGameService } from '../../../state/GlobalStateProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import { closePanel, resetPlayerScore } from '../../../store/game-slice';
import { setEntriesUnread } from '../../../store/notebook-slice';
import { selectAllAppliedActivityIds } from '../../../store/multiplayer-slice/applied-data';
import { useCompleteActivity } from '../../../lib/hooks/useCompleteLead';
import { emitAbandonUpdate } from '../../../store/multiplayer-slice';
import { getCurrentGameTheme } from '../../../theme';
import { ActivityData } from '../../../lib/game-data/activity-data';

export interface ActivityEntryComponentProps {
  isCurrentActivity?: boolean;
  activity: ActivityData;
  entries: EntryComponentProps[];
  startOpen?: boolean;
}
const theme = getCurrentGameTheme();

const ActivityEntry: React.FC<ActivityEntryComponentProps> = ({
  isCurrentActivity = false,
  activity,
  entries,
  startOpen = false,
}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const { t } = useTranslation(['entries', 'game02Common', 'common']);
  const { state: gameState } = useGameService();
  const dispatch = useAppDispatch();
  const { send } = useGameService();
  const [isAbandonButtonShown, { setFalse: hideAbandonButton, toggle: toggleAbandonButton }] = useBoolean(false);
  const [isCompleteConfirmShown, { setFalse: hideCompleteConfirm, toggle: toggleCompleteConfirm }] = useBoolean(false);
  const [isSkipConfirmShown, { setFalse: hideSkipConfirm, toggle: toggleSkipConfirm }] = useBoolean(false);
  const [expanded, { toggle: toggleExpanded }] = useBoolean(startOpen);
  // const userId = useAppSelector((state) => {
  //   return state.user.username;
  // });

  const toggleAbandonButtonOnKeyUp = React.useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.code === 'Enter' || event.code === 'Space') {
        toggleAbandonButton();
      }
    },
    [toggleAbandonButton],
  );

  const toggleExpandedOnKeyUp = React.useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.code === 'Enter' || event.code === 'Space') {
        toggleExpanded();
      }
    },
    [toggleExpanded],
  );

  const headerTextHTMLId = toHTMLSafeId(activity.id, 'headerText');
  const briefTextHTMLId = toHTMLSafeId(activity.id, 'briefText');

  const isCompleted = useAppSelector((state) => selectAllAppliedActivityIds(state).includes(activity.id));

  const { collectionStatus, targetEvent, completeLabel, skipLabel } = useCompleteActivity();

  const allActivitiesCompletable = urlParams.get('allActivitiesCompletable') != null && (isDev || isStaging);
  const inSim = gameState.matches({ investigationPhase: { play: 'inSim' } });
  const isCompletable =
    !isAbandonButtonShown &&
    isCurrentActivity &&
    !isCompleted &&
    activity.kind === 'activity' &&
    ((inSim && activity.flags.has('simulation') && !activity.flags.has('useCompleteSim')) ||
      allActivitiesCompletable) && // FIXME: Only support completing Simulation leads via the Journal.
    gameState.can(targetEvent);
  // Automatically hide completion confirmation if it is irrelevant.
  if (!isCompletable && isCompleteConfirmShown) {
    hideCompleteConfirm();
  }

  // Complete Simulation button for leads that have content following a simulation (so do not use Complete Lead)
  const isSkippable =
    !isAbandonButtonShown &&
    isCurrentActivity &&
    !isCompleted &&
    activity.kind === 'activity' &&
    inSim &&
    activity.flags.has('simulation') &&
    activity.flags.has('useCompleteSim') &&
    gameState.can(targetEvent);
  if (!isSkippable && isSkipConfirmShown) {
    hideSkipConfirm();
  }

  const completeHandler = React.useCallback(() => {
    // Close the sidebar
    dispatch(closePanel());
    // Mark any lead entries as seen
    dispatch(setEntriesUnread({ ids: entries.map((entry) => entry.entity.id), unread: false }));
  }, [entries, dispatch]);

  const skipHandler = React.useCallback(() => {
    // do an window event called 'TASK:GOTO.NEXT'
    window.postMessage({ action: 'TASK:SKIP.SIMULATION' });
    dispatch(closePanel());
    dispatch(setEntriesUnread({ ids: entries.map((entry) => entry.entity.id), unread: false }));
  }, [dispatch, entries]);

  const isAbandonable =
    isCurrentActivity && activity.kind === 'activity' && !isCompleted && gameState.can('ABANDON.LEAD');
  // Automatically hide abandon confirmation if it is irrelevant.
  if (!isAbandonable && isAbandonButtonShown) {
    hideAbandonButton();
  }

  const abandonHandler = React.useCallback(() => {
    dispatch(emitAbandonUpdate({ data: activity.id }));
    send({ type: 'ABANDON.LEAD' });
    // Close the sidebar
    dispatch(closePanel());
    // reset player score
    dispatch(resetPlayerScore(activity.id));
    // Remove all associated Journal entries.
    // dispatch(removeEntries(entries.map(({ entity }) => entity)));
  }, [dispatch, activity.id, send]);

  const entryElements = React.useMemo(() => {
    if (entries.length === 0) {
      return (
        <Stack.Item align="center" tokens={{ padding: '16px' }}>
          <Text
            styles={{ root: { color: theme.semanticColors.notebookText } }}
            variant={getFontVariant(TextComponent.enum.Heading, 5)}
            block
          >
            {t('notebook.noEntriesFoundText')}
          </Text>
        </Stack.Item>
      );
    }
    return entries.map((entryProps) => <Entry key={entryProps.entryData.id} {...entryProps} />);
  }, [t, entries]);

  const isEntriesExists = entries.length;

  // Set of what separations a hybrid lead has, in what order, 'sim', 'narrative'
  // const hybridCounters = (activity as ActivityData).hybridCounters;
  // const hasHybridCounters = hybridCounters && hybridCounters.size > 0;

  return (
    <article aria-labelledby={headerTextHTMLId}>
      <Stack
        styles={{
          root: {
            'backgroundColor': 'transparent',
            'boxShadow': '0px 4px 4px rgba(0, 0, 0, 0)',
            'position': 'relative',
            '@media(min-height: 720px)': {
              inset: '0px 0 auto', // Top measurement should be height of LeadJournal panel sticky header.
            },
            'zIndex': 1,
          },
        }}
        tokens={{ childrenGap: '16px', padding: '16px 16px 16px 32px' }}
      >
        <Stack horizontal>
          <Stack.Item grow={1} verticalFill>
            <Text
              id={headerTextHTMLId}
              role="heading"
              aria-level={2}
              variant={getFontVariant(TextComponent.enum.Heading, 4)}
              styles={{ root: { color: theme.semanticColors.notebookText, fontWeight: 400 } }}
              block
            >
              {t(`${activity.id}.header`)}
            </Text>
          </Stack.Item>

          {isAbandonable && (
            <Stack.Item
              align="start"
              onClick={toggleAbandonButton}
              onKeyUp={toggleAbandonButtonOnKeyUp}
              styles={{ root: { cursor: 'pointer' } }}
              tokens={{ padding: '4px 0 0 0' }}
              verticalFill
              role="button"
              tabIndex={0}
              title={isAbandonButtonShown ? t('buttons.cancel') : t('notebook.abandonLeadIconButtonLabel')}
              aria-label={isAbandonButtonShown ? t('buttons.cancel') : t('notebook.abandonLeadIconButtonLabel')}
            >
              <FontAwesomeIcon icon={faSignOut} fixedWidth style={{ color: theme.palette.black, fontSize: '24px' }} />
            </Stack.Item>
          )}
        </Stack>
        <AnimatePresence exitBeforeEnter initial={false}>
          {isAbandonButtonShown && (
            <motion.div
              key="abandonConfirmation"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Stack horizontalAlign="center" tokens={{ childrenGap: '16px' }}>
                <Stack.Item>
                  <Text
                    styles={{ root: { color: theme.semanticColors.notebookText } }}
                    variant={getFontVariant(TextComponent.enum.Body, 2)}
                    block
                  >
                    {t('notebook.abandonLeadConfirmationText')}
                  </Text>
                </Stack.Item>
                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '16px' }}>
                  <Stack.Item>
                    <Button isPrimary label={t('notebook.abandonLeadButtonLabel')} onClick={abandonHandler} />
                  </Stack.Item>
                  <Stack.Item>
                    <Button label={t('buttons.cancel')} onClick={hideAbandonButton} />
                  </Stack.Item>
                </Stack>
              </Stack>
            </motion.div>
          )}
          {!isAbandonButtonShown && (isCompleteConfirmShown || isSkipConfirmShown) && (
            <motion.div
              key="completeConfirmation"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Stack horizontalAlign="center" tokens={{ childrenGap: '16px' }}>
                <Stack.Item>
                  {isCompletable && (
                    <Text
                      styles={{ root: { color: theme.semanticColors.notebookText } }}
                      variant={getFontVariant(TextComponent.enum.Body, 2)}
                      block
                    >
                      {collectionStatus?.completable
                        ? t('notebook.completeLeadConfirmationText')
                        : t('notebook.completeLeadConfirmationTextMissing')}
                    </Text>
                  )}
                  {isSkippable && (
                    <Text
                      styles={{ root: { color: theme.semanticColors.notebookText } }}
                      variant={getFontVariant(TextComponent.enum.Body, 2)}
                      block
                    >
                      {collectionStatus?.completable
                        ? t('notebook.skipSimulationConfirmationText')
                        : t('notebook.skipSimulationConfirmationTextMissing')}
                    </Text>
                  )}
                </Stack.Item>
                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '16px' }}>
                  <Stack.Item>
                    <Button
                      isPrimary
                      label={isCompletable ? completeLabel : skipLabel}
                      onClick={isCompletable ? completeHandler : skipHandler}
                    />
                  </Stack.Item>
                  <Stack.Item>
                    <Button
                      label={t('buttons.cancel')}
                      onClick={isCompletable ? hideCompleteConfirm : hideSkipConfirm}
                    />
                  </Stack.Item>
                </Stack>
              </Stack>
            </motion.div>
          )}
          {!isAbandonButtonShown && !isCompleteConfirmShown && !isSkipConfirmShown && (
            <motion.div key="brief" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <Stack horizontal>
                <Stack.Item id={briefTextHTMLId} styles={{ root: { overflow: 'hidden' } }} grow={1} verticalFill>
                  <Text
                    variant={getFontVariant(TextComponent.enum.Body, 3)}
                    styles={{
                      root: {
                        color: theme.semanticColors.notebookText,
                        fontWeight: 600,
                      },
                    }}
                    block
                  >
                    {t('commonEntry.leadPreview.brief')}
                  </Text>
                  <Text
                    variant={getFontVariant(TextComponent.enum.Body, 3)}
                    styles={{
                      root: [
                        {
                          color: theme.semanticColors.notebookText,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        },
                        !expanded && {
                          maxHeight: '24px',
                          whiteSpace: 'nowrap',
                        },
                      ],
                    }}
                    block
                  >
                    <Trans t={t} i18nKey={`${activity.id}.brief`} />
                  </Text>
                </Stack.Item>
                <Stack.Item
                  align="start"
                  onClick={toggleExpanded}
                  onKeyUp={toggleExpandedOnKeyUp}
                  styles={{ root: { cursor: 'pointer' } }}
                  tokens={{ padding: '4px 0 0 0' }}
                  verticalFill
                  role="button"
                  tabIndex={0}
                  title={expanded ? t('buttons.collapse') : t('buttons.expand')}
                  aria-label={expanded ? t('buttons.collapse') : t('buttons.expand')}
                  aria-expanded={expanded ? 'true' : 'false'}
                  aria-controls={briefTextHTMLId}
                >
                  <FontAwesomeIcon
                    icon={expanded ? faMinus : faPlus}
                    fixedWidth
                    style={{ color: theme.semanticColors.notebookText, fontSize: '24px' }}
                  />
                </Stack.Item>
              </Stack>
              {(isCompletable || isSkippable) && (
                <Stack horizontalAlign="center" tokens={{ padding: '16px 0 0 0' }}>
                  <Stack.Item>
                    <Button
                      isPrimary
                      label={
                        isCompletable ? t('notebook.completeLeadButtonLabel') : t('notebook.skipSimulationButtonLabel')
                      }
                      onClick={isCompletable ? toggleCompleteConfirm : toggleSkipConfirm}
                    />
                  </Stack.Item>
                </Stack>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </Stack>
      <Stack.Item>
        {isEntriesExists > 0 && (
          <Text
            role="heading"
            aria-level={3}
            styles={{
              root: { color: theme.semanticColors.notebookText, fontWeight: 400, backgroundColor: theme.semanticColors.completeActivity, padding: '7px 35px' },
            }}
            block
          >
            {t('notebook.completedActivities')}
          </Text>
        )}
      </Stack.Item>
      <Stack tokens={{ padding: '0', childrenGap: '0px' }}>{entryElements}</Stack>
    </article>
  );
};

export default ActivityEntry;
