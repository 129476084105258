/* eslint-disable max-len */
import React from 'react';
import { Text, Stack, Icon } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

const PanelUnavailable: React.FC = () => {
  const textStyles = {
    root: {
      margin: '24px 0',
    },
  };
  const { t } = useTranslation('game01Common');
  return (
    <Stack
      verticalFill
      verticalAlign="center"
      styles={{
        root: {
          textAlign: 'center',
        },
      }}
    >
      <Stack.Item>
        <Text variant="xxLarge" styles={textStyles} block>
          {t('unavailable.header')}
        </Text>
        <Icon
          iconName="Repair"
          styles={{
            root: {
              fontSize: 64,
            },
          }}
        />
        <Text variant="mediumPlus" styles={textStyles} block>
          {t('unavailable.body')}
        </Text>
      </Stack.Item>
    </Stack>
  );
};
export default PanelUnavailable;
