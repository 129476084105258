import { mergeStyles, Stack } from '@fluentui/react';
import * as React from 'react';
import Button from '../../../Global/Button/Button';
import Panel from '../../../Global/Panel/Panel';
import Screen from '../../../Global/Screen/Screen';
import { Heading, P } from '../../../Global/Text';

interface Props {
  title: string;
  body: string;
  nextLabel: string;
  helpLabel?: string;
  onSubmit: () => void;
  onHelp: () => void;
  disableSubmit?: boolean;
  disableAll?: boolean;
}

const switchClass = mergeStyles({
  'display': 'grid',
  'width': '100%',
  '@media(min-width: 1200px)': {
    gridTemplateColumns: '1fr 1fr',
  },
});

export default function Switch({
  children,
  title,
  body,
  nextLabel,
  helpLabel,
  onSubmit,
  onHelp,
  disableAll = false,
  disableSubmit = false,
}: React.PropsWithChildren<Props>): JSX.Element {
  return (
    <Screen>
      <Panel fullscreen>
        <Heading level={2}>{title}</Heading>
        {body && (
          <P level={3}>{body}</P>
        )}
        <div className={switchClass}>{children}</div>
        <Stack wrap>
          <Stack.Item align="end" grow>
            <Stack horizontal tokens={{ childrenGap: 24 }}>
              {helpLabel && (
                <Button isPrimary label={helpLabel} onClick={onHelp} disabled={disableAll} />
              )}
              <Button isPrimary label={nextLabel} onClick={onSubmit} disabled={disableAll || disableSubmit} />
            </Stack>
          </Stack.Item>
        </Stack>
      </Panel>
    </Screen>
  );
}
