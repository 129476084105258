import React from 'react';
import { TextField, ITextFieldProps, Dropdown, IDropdownProps } from '@fluentui/react';
import { Controller } from 'react-hook-form';
import { HookFormProps } from '../../../lib/hook-form';

export const ControlledTextField: React.FC<HookFormProps & ITextFieldProps> = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || ''}
      render={({ field: { onChange, onBlur, name: fieldName, value }, fieldState: { error } }) => (
        <TextField
          {...props}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
          name={fieldName}
          errorMessage={error && error.message}
          defaultValue={undefined}
        />
      )}
    />
  );
};

export const ControlledDropdown: React.FC<HookFormProps & IDropdownProps> = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || ''}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
        <Dropdown
          {...props}
          selectedKey={value}
          onChange={(_e, option) => {
            onChange(option?.key);
          }}
          onBlur={onBlur}
          errorMessage={error && error.message}
          defaultValue={undefined}
        />
      )}
    />
  );
};
