export const ScoreVariables: { [key: string]: number } = {
  attemptLimit: 3,
  fourX: 4,
  twoX: 2,
  leadLimit: 1000,
};
/**
 * Score point value enum.
 */
export enum PointValue {
  Critical = 4,
  Bonus = 1,
  Irrelevant = 0,
  NoPoints = 0,
  SoftIncorrect = -2,
  Incorrect = -4,
}
