import Game01 from "./Game01";
import Game02 from "./Game02";
import commonEntry from "./commonEntry";

/**
 * i18Next NS: `'entries`
 */
export default {
  entries: {
    commonEntry,
    Game01,
    Game02,
  },
};
