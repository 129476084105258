/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontWeights, Stack } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { Heading, P } from '../Text';
import { MPManager } from '../../../lib/socket';
import ClipboardCopyButton from './ClipboardCopyButton';
import { getCurrentGameTheme } from '../../../theme';

interface TestState {
  canConnect: boolean;
  complete: boolean;
  errorClipboardText: string;
  errorName: string;
  errorMessage: string;
}

const baseTestState: TestState = {
  canConnect: false,
  complete: false,
  errorClipboardText: '',
  errorName: '',
  errorMessage: '',
};

const theme = getCurrentGameTheme('Global');

let TESTS_HAVE_RUN = false;

const ConnectionTest: React.FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'connectionTest' });

  const [apiTestState, setAPITestState] = useState(JSON.parse(JSON.stringify(baseTestState)));
  const [mpTestState, setMPTestState] = useState(JSON.parse(JSON.stringify(baseTestState)));

  useEffect(() => {
    async function runAPITest() {
      const newAPIState = JSON.parse(JSON.stringify(apiTestState));
      try {
        const res = await axios(`${process.env.REACT_APP_API_URL}/support/connectiontest`);
        newAPIState.canConnect = res.status === 200;
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          if (!err.response) {
            const { name, message, stack } = err;
            newAPIState.errorName = name;
            newAPIState.errorMessage = message;
            newAPIState.errorClipboardText = JSON.stringify({ name, message, stack });
          } else {
            const data: any = err.response.data;
            newAPIState.errorName = err.response.status.toString();
            newAPIState.errorMessage = data.message || err.response.statusText;
            newAPIState.errorClipboardText = JSON.stringify({
              status: err.response.status,
              statusText: err.response.statusText,
              data: err.response.data,
            });
          }
        }
        newAPIState.canConnect = false;
      }
      newAPIState.complete = true;
      setAPITestState({
        ...apiTestState,
        ...newAPIState,
      });
    }

    function runMPTest() {
      const mpManager = MPManager.instance.setupSocket(process.env.REACT_APP_SOCKET_URL);
      mpManager.on('connect', () => {
        setMPTestState({
          ...mpTestState,
          canConnect: true,
          complete: true,
        });
        MPManager.instance.teardownSocket();
      });
      mpManager.on('connect_error', (err: any) => {
        const { type, message, stack } = err;
        setMPTestState({
          canConnect: false,
          errorClipboardText: JSON.stringify({ type, message, stack }),
          errorName: type,
          errorMessage: message,
          complete: true,
        });
        MPManager.instance.teardownSocket();
      });
      mpManager.connect();
    }

    if (!TESTS_HAVE_RUN) {
      TESTS_HAVE_RUN = true;
      runAPITest();
      runMPTest();
    }
  }, [apiTestState, mpTestState]);

  const apiHasError = apiTestState.complete && !apiTestState.canConnect;
  const mpHasError = mpTestState.complete && !mpTestState.canConnect;
  const errorText = JSON.stringify({
    ...(apiHasError && { apiErrors: JSON.parse(apiTestState.errorClipboardText) }),
    ...(mpHasError && { mpErrors: JSON.parse(mpTestState.errorClipboardText) }),
  });
  return (
    <Stack style={{ backgroundColor: theme.palette.white }}>
      <Stack.Item>
        <Stack tokens={{ childrenGap: 24 }}>
          <Stack.Item>
            <Heading level={2}>{t('title')}</Heading>
          </Stack.Item>
          <Stack.Item>
            <Stack tokens={{ childrenGap: 16 }}>
              <Stack.Item>
                <P level={1}>{`${t('apiStatus')} `}</P>
                {generateStatusP(apiTestState, t('success'), t('failure'), t('loading'))}
              </Stack.Item>
              {apiHasError && apiTestState.errorName !== '' && generateErrorDetails(apiTestState)}
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <Stack tokens={{ childrenGap: 16 }}>
              <Stack.Item>
                <P level={1}>{`${t('mpStatus')} `}</P>
                {generateStatusP(mpTestState, t('success'), t('failure'), t('loading'))}
              </Stack.Item>
              {mpHasError && mpTestState.errorName !== '' && generateErrorDetails(mpTestState)}
            </Stack>
          </Stack.Item>
          {(apiHasError || mpHasError) && (
            <Stack.Item>
              <ClipboardCopyButton
                copyText={errorText}
                buttonText={t('errorButtonText')}
                copiedText={t('errorCopiedText')}
              />
            </Stack.Item>
          )}
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

function generateStatusP(state: TestState, successMsg: string, failureMsg: string, loadingMsg: string) {
  const { canConnect, complete } = state;
  let msg = loadingMsg;
  const style = {
    ...(complete && { color: canConnect ? theme.palette.green : theme.palette.red }),
    fontWeight: FontWeights.bold,
  } as React.CSSProperties;
  if (complete) {
    msg = canConnect ? successMsg : failureMsg;
  }
  return (
    <P level={1}>
      <span style={style}>{msg}</span>
    </P>
  );
}

const generateErrorDetails = (state: TestState) => {
  return (
    <Stack.Item>
      <P level={1}>Error Message:</P>
      <P level={1}>
        <span style={{ fontWeight: FontWeights.bold, color: theme.palette.red } as React.CSSProperties}>
          {` ${state.errorName} : ${state.errorMessage}`}
        </span>
      </P>
    </Stack.Item>
  );
};

export default ConnectionTest;
