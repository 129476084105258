import * as React from 'react';
import { Checkbox as FluentCheckbox, ICheckboxStyles, mergeStyles, Theme } from '@fluentui/react';
import { greyScale } from '../../../../theme/Global/colors';
import { getCurrentGameTheme } from '../../../../theme';

interface Props {
  label: string;
  onChange: (ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined) => void;
  showAnswer?: boolean;
  isCorrect?: boolean;
  forceWrap?: boolean;
  checked?: boolean;
  disabled?: boolean;
}

const checkboxStyles = (theme: Theme) : ICheckboxStyles => ({
  root: {
    selectors: {
      ':hover .ms-Checkbox-checkbox, :focus .ms-Checkbox-checkbox': {
        borderColor: theme.palette.white,
        backgroundColor: theme.palette.themeSecondary,
      },
    },
    margin: '10px 0',
  },
  label: {
    alignItems: 'center',
  },
  checkbox: {
    'borderWidth': 2,
    'height': 40,
    'width': 40,
    'borderRadius': 6,
    'borderColor': greyScale[7],
    'color': theme.palette.black,
    'backgroundColor': 'transparent',
    'marginRight': 10,
    ':hover .ms-Checkbox-checkmark, :focus .ms-Checkbox-checkmark': {
      color: theme.palette.white,
    },
  },
  text: {
    fontSize: 20,
    fontWeight: 400,
  },
  checkmark: {
    color: theme.palette.green,
    fontSize: 35,
    fontWeight: 900,
  },
});

export default function Checkbox({
  label,
  onChange,
  showAnswer,
  isCorrect,
  forceWrap = false,
  checked,
  disabled = false,
}: Props) {
  const styles: any = {
    overflowWrap: forceWrap ? 'anywhere' : 'normal',
  };

  const theme = getCurrentGameTheme();
  // To ensure we can force the checked state of the otherwise uncontrolled fluent checkbox, render a separate checkbox instance when showing the answer
  if (showAnswer) {
    const RevealedCheckbox = (props: { label: string; checked: boolean }) => (
      <FluentCheckbox
        className={mergeStyles([
          styles,
          {
            opacity: props.checked ? 1 : 0.25,
          },
        ])}
        label={props.label}
        styles={checkboxStyles(theme)}
        disabled
        checked={props.checked}
      />
    );
    return <RevealedCheckbox label={label} checked={!!(showAnswer && isCorrect)} />;
  }

  if (checked === undefined) {
    return (
      <FluentCheckbox
        className={styles}
        label={label}
        onChange={onChange}
        styles={checkboxStyles(theme)}
        disabled={disabled}
      />
    );
  }
  return (
    <FluentCheckbox
      className={styles}
      label={label}
      onChange={onChange}
      styles={checkboxStyles(theme)}
      checked={checked}
      disabled={disabled}
    />
  );
}
