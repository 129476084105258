import * as React from 'react';
import { z } from 'zod';
import thisPc from '../../../../static/images/Game01/shared/desktop_icons/thisPc.png';
import microsoftComplianceCenter from '../../../../static/images/Game01/shared/desktop_icons/microsoftComplianceCenter.png';
import azureSentinel from '../../../../static/images/Game01/shared/desktop_icons/azureSentinel.png';
import azureAd from '../../../../static/images/Game01/shared/desktop_icons/azureAd.png';
import defenderForEndpoint from '../../../../static/images/Game01/shared/desktop_icons/defenderForEndpoint.png';
import microsoftEndpointManager from '../../../../static/images/Game01/shared/desktop_icons/microsoftEndpointManager.png';
// ep02 specific
import ecommerce from '../../../../static/images/Game01/shared/desktop_icons/ecommerce_appservice_azureresources.docx.png';
import gitHub from '../../../../static/images/Game01/shared/desktop_icons/GitHub.png';
import azurePortal from '../../../../static/images/Game01/shared/desktop_icons/Azure-Portal.png';
import azureAppService from '../../../../static/images/Game01/shared/desktop_icons/Azure App Service.png';
import azureMonitor from '../../../../static/images/Game01/shared/desktop_icons/Azure-Monitor.png';
import defenderForCloud from '../../../../static/images/Game01/shared/desktop_icons/Defender-for-Cloud.png';
import defenderForCloudApps from '../../../../static/images/Game01/shared/desktop_icons/Defender-for-Cloud-Apps.png';

export const ICON_NAMES = {
  thisPc: 'thisPc',
  microsoftComplianceCenter: 'microsoftComplianceCenter',
  microsoftEndpointManager: 'microsoftEndpointManager',
  azureSentinel: 'azureSentinel',
  azureAd: 'azureAd',
  defenderForEndpoint: 'defenderForEndpoint',
  // Episode 2 Specific
  ecommerce: 'ecommerce',
  gitHub: 'gitHub',
  azurePortal: 'azurePortal',
  azureAppService: 'azureAppService',
  azureMonitor: 'azureMonitor',
  defenderForCloud: 'defenderForCloud',
  defenderForCloudApps: 'defenderForCloudApps',
} as const;

const IconEnum = z.nativeEnum(ICON_NAMES);
export type IconNames = z.infer<typeof IconEnum>;

interface IconProps {
  iconName: IconNames;
  width?: number;
}

export default function Icon({ iconName, width = 50 }: IconProps) {
  switch (iconName) {
    case ICON_NAMES.thisPc:
      return <img src={thisPc} alt="" width={width} />;
    case ICON_NAMES.microsoftComplianceCenter:
      return (
        <img src={microsoftComplianceCenter} alt="" width={width} />
      );
    case ICON_NAMES.azureSentinel:
      return <img src={azureSentinel} alt="" width={width} />;
    case ICON_NAMES.azureAd:
      return <img src={azureAd} alt="" width={width} />;
    case ICON_NAMES.defenderForEndpoint:
      return <img src={defenderForEndpoint} alt="" width={width} />;
    case ICON_NAMES.microsoftEndpointManager:
      return <img src={microsoftEndpointManager} alt="" width={width} />;
    // TODO: Update with real images
    case ICON_NAMES.ecommerce:
      return <img src={ecommerce} alt="" width={width} />;
    case ICON_NAMES.gitHub:
      return <img src={gitHub} alt="" width={width} />;
    case ICON_NAMES.azurePortal:
      return <img src={azurePortal} alt="" width={width} />;
    case ICON_NAMES.azureAppService:
      return <img src={azureAppService} alt="" width={width} />;
    case ICON_NAMES.azureMonitor:
      return <img src={azureMonitor} alt="" width={width} />;
    case ICON_NAMES.defenderForCloud:
      return <img src={defenderForCloud} alt="" width={width} />;
    case ICON_NAMES.defenderForCloudApps:
      return <img src={defenderForCloudApps} alt="" width={width} />;
    default:
      return <img src={thisPc} alt="" width={width} />;
  }
}
