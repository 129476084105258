/**
 * i18next NS: `Episode01.Task03`
 */
 export default {
  common: {
    errorTitle: "Try Again",
    next: "Next",
    submit: "Submit",
    start: "Start",
    skipAhead: "Skip Ahead",
    ok: "OK",
    done: "Done",
  },
  A015: {
    error: {
      1: {
        title: "Try again.",
        body: "That's not the right place.",
      },
      2: {
        title: "Try again.",
        body: "That's not right either.",
      },
      3: {
        title: "Go Here!",
        body: "I need you to go to Azure Active Directory.",
      },
    },
  },
};
