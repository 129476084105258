/**
 * i18next NS: `Episode01.Task05`
 */
export default {
  common: {
    errorTitle: "Try Again",
    next: "Next",
    submit: "Submit",
    type: "Type: ",
    expectedTime: "Expected Time: ",
    brief: "Brief: ",
    start: "Start",
    skipAhead: "Skip Ahead",
    ok: "OK",
    done: "Done",
  },
  A010: {
    error: {
      1: {
        title: "Try again.",
        body: "That's not the right place.",
      },
      2: {
        title: "Try again.",
        body: "That's not right either.",
      },
      3: {
        title: "Go Here!",
        body: "I need you to go to Microsoft 365 Compliance Center.",
      },
    },
  },
  A015: {
    body: "Here's the Microsoft 365 Compliance Center. What tool do you want to use?",
    options: {
      1: {
        label: "Information governance",
        error: {
          title: "That's more than you need right now.",
          body: "Information governance covers organizational compliance and data requirements.",
        },
      },
      2: {
        label: "Information protection",
      },
      3: {
        label: "Insider risk management",
        error: {
          title: "Try something else.",
          body: "For this task, focus on protecting documents - not managing risky user activity.",
        },
      },
    },
  },
  A020: {
    body: "Where should you go first?",
    options: {
      1: {
        label: "Labels",
      },
      2: {
        label: "Label policies",
        error: {
          title: "Try something else.",
          body: "First, you need to create and configure the sensitivity label.",
        },
      },
      3: {
        label: "Auto-labeling",
        error: {
          title: "Wait!",
          body: "You haven't configured a sensitivity label yet, so you don't have anything to auto-apply.",
        },
      },
    },
  },
  A030: {
    title: "Now what?",
    options: {
      1: {
        label: "Create a label",
      },
      2: {
        label: "Publish label",
        error: {
          title: "Not yet.",
          body: "You aren't ready to publish yet. First, create a new sensitivity label.",
        },
      },
    },
  },
  A040: {
    title: "New sensitivity label",
    subhead: "Protect our sensitive eCommerce App data from unauthorized access.",
    attempts: "Attempt % of 3",
    body: "Label name: Confidential eCommerce App Team",
    placeholder: "Select the correct answer",
    success: {
      title: "Initial parameters set.",
      body: "Now you have a few more settings to complete.",
    },
    locks: {
      1: {
        label: "Scope",
        options: {
          1: "Files & Emails",
          2: "Groups & Sites",
          3: "Schematized data assets ",
        },
        hint: "We want to protect highly confidential files and emails no matter where they live.",
      },
      2: {
        label: "Protection settings",
        options: {
          1: "Encrypt",
          2: "Mark the content",
        },
        hint: "The label should live in the metadata for the file so it is always protected.",
      },
      3: {
        label: "Permissions",
        options: {
          1: "Assign now",
          2: "Let users assign",
        },
        hint: "Assign now, so you can determine exactly which users get permission to view highly confidential content.",
      },
    },
    error: {
      title: "Check the Brief.",
      body: "It explains what settings you need to use here.",
    },
    walkthrough: {
      title: "How's that label coming along?",
      body: "Let me walk you through this.",
      nextLabel: "Guide Me",
    },
  },
  A080: {
    title: "Assign permissions now",
    subhead: "Per the legal team, authentication is required. But valid users should always have access to the file.",
    body: "Choose the encryption settings and permissions for email and Office files, then select DONE.",
    selection: {
      1: {
        label: "User access to content expires:",
        options: {
          1: "6 months after label is applied",
          2: "1 year after label is applied",
          3: "7 years after label is applied",
          4: "Never",
        },
        hint: 'Choose "Never" so you don\'t block authorized users.',
      },
      2: {
        label: "Allow offline access:",
        options: {
          1: "Always",
          2: "1 day after label is applied",
          3: "1 week after label is applied",
          4: "Never",
        },
        hint: 'Remember Zero Trust. Choose "Never."',
      },
      3: {
        label: "Assign permissions to specific users and groups:",
        options: {
          1: "Operations team",
          2: "eCommerce app team",
          3: "IT team",
          4: "Sales and marketing team",
        },
        hint: "You are protecting Amari's eCommerce app team.",
      },
    },
    error: {
      title: "Those aren't the right settings.",
      body: "Remember, you want to protect sensitive information but still allow authorized users to do their jobs. Zero Trust is important here, too.",
    },
  },
  A090: {
    title: "New sensitivity label created!",
    body: "Review your settings. Then move on to create the auto-labeling policy.",
  },
  B010: {
    body: "Now create an auto-labeling policy for your sensitivity label. Where should you go to set that up?",
    options: {
      1: {
        label: "Labels",
        error: {
          title: "Try something else.",
          body: "You already created the label. Now  you need to apply the auto-labeling policy.",
        },
      },
      2: {
        label: "Label policies",
        error: {
          title: "We won't use that option today.",
          body: "There is another way to do what you need to do.",
        },
      },
      3: {
        label: "Auto-labeling",
      },
    },
  },
  B020: {
    // title: "New sensitivity label created!",
    body: "This auto-labeling policy will automatically apply your sensitivity label to files and emails that contain credit card information. Let's get started.",
    label: "Create auto-labeling policy",
  },
  B030: {
    body: "Do you want to use one of the default policy templates or create your own?",
    options: {
      1: {
        label: "Use a default policy template",
      },
      2: {
        label: "Create a custom policy ",
        error: {
          title: "You don't need to create a custom template.",
          body: "Microsoft already has a default policy template that you can use in this situation.",
        },
      },
    },
  },
  B040: {
    title: "",
    body: "What type of information do you want to apply this label to?",
    options: {
      1: {
        label: "Financial",
      },
      2: {
        label: "Medical and health",
        error: {
          title: "No, not medical and health.",
          body: "Remember, you're creating an auto-labeling policy for credit card information.",
        },
      },
      3: {
        label: "Privacy",
        error: {
          title: "You won't find credit card templates there.",
          body: "I understand your thinking, but privacy is not the right category.",
        },
      },
    },
    success: {
      title: "Yes, we'll use a Financial policy template.",
      body: "The <0>PCI Data Security Standard (PCI DSS) policy template</0> helps detect the presence of information subject to that standard, including information like credit cards or debit card numbers.",
    },
  },
  B050: {
    title: "Policy Name: eCommerce PCI DSS auto-labeling policy",
    body: "Locations: Choose locations where you want to apply this auto-labeling policy, then select DONE.",
    options: {
      1: {
        label: "Exchange",
      },
      2: {
        label: "SharePoint sites",
      },
      3: {
        label: "OneDrive accounts",
      },
    },
    error: {
      title: "Start with what you know.",
      body: "Where was the leaked file found? What information are you trying to protect?",
      nextLabel: "Try Again",
    },
    feedback: {
      title: "Policy Name: eCommerce PCI DSS auto-labeling policy",
      subtitle: "",
      body: "Locations: You should apply the auto-labeling policy in all of these locations.",
      nextLabel: "OK",
    },
  },
  B060: {
    title: "Common rules were preloaded based on your template.",
    body: "Your label will be auto-applied to content that matches these rules and conditions. You don't have to choose any other Policy Rules here.",
    nextLabel: "OK",
  },
  B070: {
    title: "Choose a label to auto-apply",
    subtitle: "",
    body: "Choose the label you want to auto-apply, then select DONE.",
    options: {
      1: {
        label: "Public",
      },
      2: {
        label: "Confidential Credit Card Info",
      },
      3: {
        label: "Confidential eCommerce App Team",
      },
      4: {
        label: "Highly Confidential Internal",
      },
    },
    error: {
      title: "That isn't right.",
      body: "Remember, you want to protect Amari's team with the label you just created.",
    },
    feedback: {
      title: "Choose a label to auto-apply",
      subtitle: "",
      body: "You just created the Confidential eCommerce App Team label, so that is what we will apply here.",
    },
  },
  B080: {
    title: "Test the policy",
    subtitle: "",
    body: "We need to run this policy in Simulation mode before we turn it on. What will this allow you to do?",
    options: {
      1: {
        label: "Refine policy rules for accuracy",
      },
      2: {
        label: "Gradually increase policy scope",
      },
      3: {
        label: "Estimate time needed to apply the label",
      },
      4: {
        label: "Speed up deployment of the policy",
      },
    },
    error: {
      title: "You didn't get all the right reasons.",
    },
    success: {
      title: "You got it.",
      body: "Simulation mode lets you preview the results. So you can make sure that you are applying the label to the correct items before you turn on the policy and physically change the files.",
    },
    feedback: {
      title: "Test the policy",
      subtitle: "",
      body: "Simulation mode lets you preview the results. So you can make sure that you are applying the label to the correct items before you turn on the policy and physically change the files.",
    },
  },
  B100: {
    title: "Review and finish",
    body: "Simulation mode is running. You will be notified when it is complete. After you review the simulation results and refine the policy (if needed), select the Turn on policy option. Your auto-labeling policy will run continuously until it is deleted. ",
    feedback: {
      title: "Nice work!",
      body: "You set up the requested compliance policies. Remember, you can review your Protection tasks on the Evidence Map.",
      nextLabel: "Back to evidence map",
    },
  },
};
