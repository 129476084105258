import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import LabFrame from '../../../../components/Global/LabFrame/LabFrame';
import { useSimVariableToAddEntryMap } from '../../../../lib/hooks/useSimVariableEntryMap';
import { devLog } from '../../../../lib/util';

import type { LabFrameOnMessage } from '../../../../components/Global/LabFrame/LabFrame';
import type { TSGSEvent } from '../../../types-tsgs';
import { useTaskMachine } from '../../../../lib/hooks/useTaskMachine';
import A020 from './components/A020';
import A031 from './components/A031';
import A035 from './components/A035';
import A040 from './components/A040';
import A050 from './components/A050';
import A060 from './components/A060';
import A070 from './components/A070';
import A080 from './components/A080';
import { createTask05 } from './machine';

const baseNS = 'Game01.Episode02.Task05';
const taskKey = 'Game01.Episode02.Task05';

const labURI = 'https://ctsdemos.azureedge.net/microsoft/SCI_Ep2Task5/index.html';

function createTaskMatch(parent: string, state: any) {
  return (matchState: string) => state.matches(`${parent}.${matchState}`);
}

const Task05: React.FC = () => {
  const [t] = useTranslation(baseNS);
  const dispatch = useDispatch();

  const labVariableEntries = useSimVariableToAddEntryMap(taskKey);
  devLog(labVariableEntries);

  // Setup task machine
  const [state, send] = useTaskMachine(createTask05, {
    taskKey,
  });

  const labMessageHandler = React.useCallback<LabFrameOnMessage<TSGSEvent>>(
    (message, frameWindow) => {
      const { data } = message;
      devLog('labMessageHandler', data, frameWindow);
      if (data.action === 'tsgs.VariableChanged') {
        labVariableEntries[data.key]?.forEach(dispatch);
      }
    },
    [dispatch, labVariableEntries],
  );

  return (
    <>
      {state.matches('A020') && <A020 t={t} send={send} state={state.context} match={createTaskMatch('A020', state)} />}
      {state.matches('A030') && <LabFrame src={labURI} title={t('title')} onMessage={labMessageHandler} />}
      {state.matches('A031') && <A031 t={t} send={send} state={state.context} match={createTaskMatch('A031', state)} />}
      {state.matches('A035') && <A035 t={t} send={send} state={state.context} match={createTaskMatch('A035', state)} />}
      {state.matches('A040') && <A040 t={t} send={send} state={state.context} match={createTaskMatch('A040', state)} />}
      {state.matches('A050') && <A050 t={t} send={send} state={state.context} match={createTaskMatch('A050', state)} />}
      {state.matches('A060') && <A060 t={t} send={send} state={state.context} match={createTaskMatch('A060', state)} />}
      {state.matches('A070') && <A070 t={t} send={send} state={state.context} match={createTaskMatch('A070', state)} />}
      {state.matches('A080') && <A080 t={t} send={send} state={state.context} match={createTaskMatch('A080', state)} />}
    </>
  );
};

export default Task05;
