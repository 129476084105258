import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClientChatMessage, UserInfo } from '../lib/socket/schemas';
import type { RootState } from '.';

export interface SessionStateInterface {
  slug: string;
  name: string;
  event_slug: string;
  users: UserInfo[];
  facilitators: string[];
  messages: ClientChatMessage[];
  unread: number;
}

// the initial state
const initialState: SessionStateInterface = {
  slug: '',
  name: '',
  event_slug: '',
  users: [],
  facilitators: [],
  messages: [],
  unread: 0,
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSession: (state, { payload }: PayloadAction<Partial<SessionStateInterface>>) => Object.assign(state, payload),
    addMessages: (state, { payload }: PayloadAction<ClientChatMessage | ClientChatMessage[]>) => {
      state.messages = state.messages.concat(payload);
      state.unread += Array.isArray(payload) ? payload.length : 1;
    },
    resetMessages: (state) => {
      state.messages = [];
      state.unread = 0;
    },
    resetUnread: (state) => {
      state.unread = 0;
    },
  },
});

export const getUsernamesFromIds = ({ session }: RootState, userIds: string[]) => {
  return userIds.map((userId) => {
    return session.users.find((user) => user.username === userId);
  });
};

/**
 * Grouped export for sessionSlice actions.
 */
export const actions = Object.freeze({
  ...sessionSlice.actions,
});

/**
 * Individual exports for sessionSlice actions.
 */
export const { setSession } = actions;
