import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Text } from '@fluentui/react';
import getFontVariant, { TextComponent } from '../../Global/Text/getFontVariant';
import { useCompleteActivity } from '../../../lib/hooks/useCompleteLead';

const LeadReviewUnCollectedEvidence: React.FC = () => {
  const { t } = useTranslation('entries');

  const { collectionStatus } = useCompleteActivity();
  const criticalTotalEntries = collectionStatus?.critical.total || [];

  const filteredData: string[] = [];
  criticalTotalEntries.forEach((element) => {
    if (collectionStatus) {
      const collectedItems = collectionStatus.critical.completed.find((collected) => collected.id === element.id);
      if (!collectedItems && element.leadType === 'sim') {
        filteredData.push(element.id);
      }
    }
  });

  return (
    <Stack grow={1} tokens={{ childrenGap: '8px' }}>
      {filteredData.map((entity) => (
        <Stack.Item key={entity}>
          <Text variant={getFontVariant(TextComponent.Enum.Body, 2)} block>
            • {t(`${entity}.heading`)}
          </Text>
        </Stack.Item>
      ))}
    </Stack>
  );
};

export default LeadReviewUnCollectedEvidence;
