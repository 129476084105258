import { Icon, IStackStyles, Stack } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { EventData } from '../../../../lib/api/types';
import { Heading } from '../../Text';
import { getCurrentGameTheme } from '../../../../theme';

const theme = getCurrentGameTheme('Global');

const eventPanelStyles: IStackStyles = {
  root: {
    minWidth: 400,
    marginBottom: 24,
    backgroundColor: '#F2F2F2',
    boxShadow: theme.effects.elevation8,
    p: {
      fontSize: 14,
      fontWeight: 'normal',
      margin: '8px 0',
    },
  },
};

export interface IEventInfoProps {
  event: EventData;
  onStartEvent: Function;
  onEndEvent: Function;
  isEventStarted: boolean;
  isEventStartable: boolean;
  isEventEnded: boolean;
  isMPConnected: boolean;
}

const EventInfoPanel: React.FC<IEventInfoProps> = (
  { event, isEventStartable, isEventStarted, isEventEnded },
) => {
  const { t } = useTranslation('common');
  const color = theme.palette.themePrimary;

  return (
    <Stack styles={eventPanelStyles} tokens={{ padding: 24 }}>
      <Stack.Item>
        <Heading level={4} color={color}>{t('facilitator.event')}</Heading>
      </Stack.Item>
      <Stack.Item>
        <p>
          {(isEventStartable) && (
            <Icon iconName="CircleFill" style={{ marginRight: 5, verticalAlign: 'top', color: '#ddd' }} />
          )}
          {(isEventStarted && !isEventEnded) && (
            <Icon iconName="CircleFill" style={{ marginRight: 5, verticalAlign: 'top', color: '#45b847' }} />
          )}
          {isEventEnded && (
            <Icon iconName="CircleFill" style={{ marginRight: 5, verticalAlign: 'top', color: '#ee3a3a' }} />
          )}
          {`Status: ${event.status && t(event.status)}`}
        </p>
        <p><strong>Start Date:</strong> {`${event.start_date && format(new Date(event.start_date), 'MMMM dd, yyyy @ h:mmaaa')}`}</p>
        <p><strong>End Date:</strong> {`${event.end_date && format(new Date(event.end_date), 'MMMM dd, yyyy @ h:mmaaa')}`}</p>
        <p><strong>Registered Players:</strong> {`${event.total_users}`}</p>
        <p><strong>Joined Players:</strong> {`${event.users.length}`}</p>
        <p><strong>Catalog ID:</strong> {`${event.catalog_code}`}</p>
        <p><strong>Primary Language:</strong> {`${event.primary_language}`}</p>
      </Stack.Item>
    </Stack>
  );
};
export default EventInfoPanel;
