import Task01 from "./Task01";
import Task02 from "./Task02";
import Task03 from "./Task03";
import Task04 from "./Task04";
import Task05 from "./Task05";
import Task06 from "./Task06";
import Task07 from "./Task07";
import Task08 from "./Task08";
import Task09 from "./Task09";
import Task10 from "./Task10";
import Task11 from "./Task11";
import Task12 from "./Task12";
import Task13 from "./Task13";
import Task14 from "./Task14";
import Phase01Review from "./Phase01Review";
import Phase02Review from "./Phase02Review";
import Conclusion from "./Conclusion";

export default {
  "Game01.Episode01.Task01": Task01,
  "Game01.Episode01.Task02": Task02,
  "Game01.Episode01.Task03": Task03,
  "Game01.Episode01.Task04": Task04,
  "Game01.Episode01.Task05": Task05,
  "Game01.Episode01.Task06": Task06,
  "Game01.Episode01.Task07": Task07,
  "Game01.Episode01.Task08": Task08,
  "Game01.Episode01.Task09": Task09,
  "Game01.Episode01.Task10": Task10,
  "Game01.Episode01.Task11": Task11,
  "Game01.Episode01.Task12": Task12,
  "Game01.Episode01.Task13": Task13,
  "Game01.Episode01.Task14": Task14,
  "Game01.Episode01.Phase01Review": Phase01Review,
  "Game01.Episode01.Phase02Review": Phase02Review,
  "Game01.Episode01.Conclusion": Conclusion,
};
