export default {
  usernameField: {
    title: "Welcome to Best for You Organics",
    body: "Type the name you would like the system to use",
  },
  pickDisplayName: {
    title: "Please type your display name.",
    body: "Let your teammates know what to call you.",
    placeholder: "Enter Display Name...",
  },
  continueLabel: "Continue",
  nextLabel: "Next",
  backLabel: "Previous",
  Episode01: {
    phases: {
      video1: {
        title: "",
        body: "",
        nextLabel: "Continue",
      },
      video2: {
        title: "",
        body: "",
        nextLabel: "Continue",
      },
      tutorial001: {
        title: "Welcome to the Best For You Organics family!",
        body: "",
      },
      tutorial002: {
        title: "You’ll be working directly with our CIO Andrea Divkovic to identify and reduce security risks.",
        body: "",
      },
      tutorial003: {
        title: "I hope you’re ready to hit the ground running!",
        body: "We have a bit of an, uh... unusual situation right now. There’s been a data breach and customer information has been stolen by someone who is holding it for ransom. We need to make sure news of this breach doesn’t spread. Work with your team to find out which data was exfiltrated, who did it, and how. And do it fast because the executive team is locked in the C-Suite until this is solved!",
      },
      tutorial004: {
        title: "You’ll follow leads, collect evidence, and create an Evidence Map.",
        body: "The Evidence Map shows you how all of the information links together. To help you get started, I’ve already added Evidence Cards for Best For You Organics, the Leaked File, and our C-Suite executives.",
      },
      tutorial005: {
        title: "As you collect critical evidence, it’s added to your Journal.",
        body: "I completed the first lead for you—I reviewed the properties of the leaked file and added the critical information to your Journal. Some leads are a game activity like the one featured below. Others take place in simulated software environments. Once in the game, you will be able to access an interactive tutorial for the software environments in the Help menu (located in the purple right-side panel). Tip: Choose evidence wisely! Some information is critical, some information is irrelevant, and other information earns bonus points.",
      },
      tutorial006: {
        title: "To build the Evidence Map, you’ll apply the evidence in your Journal to the Evidence Cards.",
        body: "Remember, you can always discuss this with your teammates if you have any questions.",
      },
      tutorial007: {
        title: "As you uncover more information, you’ll add more Evidence Cards to the Evidence Map.",
        body: "Based on what I uncovered, I updated the Leaked File Card. I also added a new Evidence Card for Amari Rivera (the file author). ",
      },
      tutorial008: {
        title: "Once you get started, the Evidence Map is always available for you to review.",
        body: "Select the magnifying glass icon to open the Evidence Map. Select an Evidence Card to read the information on the back.",
      },
    },
  },
  Episode02: {
    phases: {
      video1: {
        title: "",
        body: "",
        nextLabel: "Continue",
      },
      video2: {
        title: "",
        body: "",
        nextLabel: "Continue",
      },
      tutorial001: {
        title: "Welcome to the Best For You Organics family!",
        body: "",
      },
      tutorial002: {
        title: "You’ll be working directly with our CIO Andrea Divkovic to identify and reduce security risks.",
        body: "",
      },
      tutorial003: {
        title: "I hope you’re ready to hit the ground running!",
        body: "We’ve got a busy day ahead of us. A film crew is on site filming a documentary…and we’ve got a new alert in Defender for Cloud that you and your team will need to take care of immediately. Find the root user and determine whether or not the user was malicious but let’s do all we can to keep it off the record.",
      },
      tutorial004: {
        title: "You’ll follow leads, collect evidence, and create an Evidence Map.",
        body: "The Evidence Map shows you how all of the information links together. I’ve added some Evidence Cards to help you get started.",
      },
      tutorial005: {
        title: "As you collect critical evidence, it’s added to your Journal.",
        body: "Some leads are a game activity like the one featured below. Others take place in simulated software environments. Once in the game, you will be able to access an interactive tutorial for the software environments in the Help menu (located in the purple right-side panel). Collecting evidence works just the same as before. Review the information and save evidence to your journal. Tip: Choose evidence wisely! Some information is critical, some information is irrelevant, and other information earns bonus points.",
      },
      tutorial006: {
        title: "To build the Evidence Map, you’ll apply the evidence in your Journal to the Evidence Cards.",
        body: "Remember, you can always discuss this with your teammates if you have any questions.",
      },
      tutorial007: {
        title: "As you uncover more information, you’ll add more Evidence Cards to the Evidence Map.",
        body: "You may recall the the Leaked File card from our security breach two weeks ago.",
      },
      tutorial008: {
        title: "Once you get started, the Evidence Map is always available for you to review.",
        body: "Select the magnifying glass icon to open the Evidence Map. Select an Evidence Card to read the information on the back.",
      },
    },
  },
};
