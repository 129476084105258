import StorageA from './StorageA';
import StorageB from './StorageB';
import StorageC from './StorageC';
import StorageD from './StorageD';
import StorageE from './StorageE';
import StorageF from './StorageF';
import StorageG from './StorageG';
import StorageH from './StorageH';
import Start from './Start';
/**
 * Episode01: All task components in a single namespace.
 */

export default {
  StorageA,
  StorageB,
  StorageC,
  StorageD,
  StorageE,
  StorageF,
  StorageG,
  StorageH,
  Storage: Start,
};
