import DashboardA from './DashboardA';
import DashboardB from './DashboardB';
import DashboardC from './DashboardC';
import DashboardD from './DashboardD';
import DashboardE from './DashboardE';
import DashboardF from './DashboardF';
import Start from './Start';
/**
 * Episode01: All task components in a single namespace.
 */

export default {
  DashboardA,
  DashboardB,
  DashboardC,
  DashboardD,
  DashboardE,
  DashboardF,
  Dashboard: Start,
};
