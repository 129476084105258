import * as React from 'react';
import background from '../images/04C.010.png';
import { ComponentProps } from '../../../../task-types';
import Interstitial from '../../../../../components/Global/Interactions/Interstitial/Interstitial';

export default function C010({ t, send }: ComponentProps): JSX.Element {
  return (
    <Interstitial
      title={t('C010.title')}
      body={t('C010.body')}
      onClick={() => send('NEXT')}
      nextLabel={t('common.submit')}
      backgroundImage={background}
    />
  );
}
