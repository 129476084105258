import type { LocaleContent, CardOrEntryLocaleContent } from "../../types";

/**
 * i18Next `entries:Episode02.Task07` lead text content
 */
const Task07Lead: LocaleContent = {
  header: "Investigate Managed Identity Permissions",
  role: "Identity Administrator",
  time: "4m",
  brief: "If the eCommerce app has been compromised, we need to understand what the attacker may have access to. What permissions and roles have been granted to the user-assigned managed identity? <br><br>-Andrea",
};

/**
 * i18Next `entries:Episode01.Task02` entry content
 */
const Task07Entries: CardOrEntryLocaleContent = {
  ManagedIdentityDetails: {
    heading: "Managed Identity Details",
    Name: "Name: eShopIdentity",
    ResourceGroup: "Resource Group: rgStore",
    Location: "Location: East US",
    SubId: "Subscription ID: 3bbfbc94-652f-48d1-92c6-f20bc21ae333",
    ClientId: "Client ID: 61b2a910-7379-4746-ba1d-d3f34a06e0ab",
    ObjectId: "Object (principal) ID: eccf5041-955b-43df-b2b7-53bfb583bf1e",
  },
  ManagedIdentityPermissions: {
    heading: "Managed Identity Permissions",
    Role1Role: "Role 1 Role: Owner",
    Role1Resource: "Role 1 Resource Name: rgStore",
    Role1Type: "Role 1 Resource Type: Resource Group",
    Role1AssignedTo: "Role 1 Assigned To: eShopIdentity",
    Role2Role: "Role 2 Role: Contributor",
    Role2Resource: "Role 2 Resource Name: bfyosql",
    Role2Type: "Role 2 Resource Type: SQL server",
    Role2AssignedTo: "Role 2 Assigned To: eShopIdentity",
    Role3Role: "Role 3 Role: Reader",
    Role3Resource: "Role 3 Resource Name: bfyofinancecosmos",
    Role3Type: "Role 3 Resource Type: Azure Cosmos DB account",
    Role3AssignedTo: "Role 3 Assigned To: eShopIdentity",
    Role4Role: "Role 4 Role: Owner",
    Role4Resource: "Role 4 Resource Name: bfyostorage",
    Role4Type: "Role 4 Resource Type: Storage account",
    Role4AssignedTo: "Role 4 Assigned To: eShopIdentity",
    Role5Role: "Role 5 Role: Key Vault Administrator",
    Role5Resource: "Role 5 Resource Name: bfyofinancekeyvault",
    Role5Type: "Role 5 Resource Type: Key Vault",
    Role5AssignedTo: "Role 5 Assigned To: eShopIdentity",
  },
};

export default {
  ...Task07Lead,
  ...Task07Entries,
};
