import game01Common from "./Game01";
import game02Common from "./Game02";

/**
 * i18Next NS: `'entries`
 */
export default {
  common: {
    buttons: {
      again: "Try Again",
      begin: "Begin",
      cancel: "Cancel",
      collapse: "Collapse",
      done: "Done",
      expand: "Expand",
      letsGo: "Let's Go",
      next: "Next",
      submit: "Submit",
      ok: "OK",
    },
    chat: {
      header: "Chat",
      closeButton: "Close Chat panel",
      submitField: "Type to chat...",
      requestHelp: "Select the hand icon for facilitator help.",
      requestHelpButton: "Request help",
      cancelHelpButton: "Cancel help request",
      sendChatMessageButton: "Send chat message",
      broadcastInfo: "This message was broadcasted to the entire event.",
    },
    connectionTest: {
      title: "Project Squire Environment Test",
      loading: "Checking...",
      apiStatus: "API Server Connection Test:",
      mpStatus: "Multiplayer Server Connection Test:",
      success: "Success",
      failure: "Failure",
      errorButtonText: "Copy Error Data to Clipboard",
      errorCopiedText: "Data Copied!",
    },
    event: {
      lobby: "Lobby",
      disconnected: "Disconnected Session",
      enterEvent: "Enter Event",
      pleaseWait: "Why are you here waiting in the lobby?<br>You are here, intentionally, waiting for all players to assemble.",
      pleaseWait2: "While you are here, take a moment to shake off the rest of the world…",
      prep: {
        items: ["Prepare to play a game.", "Prepare to make mistakes, to be frustrated. Just like any new experience, you won’t know everything up front.", "Prepare to explore, read, think through challenges, and make guesses.", "Prepare to have fun."],
      },
      lobbyThankYou: "<b>Thank you</b> for participating in our pilot. We welcome your feedback to help us improve the “Who Hacked?!” experience.",
      userDisconnected: "Facilitator has disconnected you from your session.",
      pleaseRefresh: "Refresh your browser or select the “Refresh” button to rejoin the session.",
      refresh: "Refresh",
      eventStarted: "The facilitator has started the event.",
      startTime: "Event Start Time:",
      playersJoined: "Players Joined:",
      thankYou: "Thank you for playing!",
      eventIsOver: "The event has concluded. You may close this browser to exit the game.",
      surveyLink: "If you would like to provide additional feedback about your experience, please complete this form: https://aka.ms/SecurityCloudGamesSurvey",
    },
    facilitator: {
      event: "Event",
      eventInfo: "Event Information",
      startEvent: "Start Event",
      endEvent: "End Event",
      scheduled: "Scheduled",
      started: "Started",
      completed: "Completed",
      canceled: "Canceled",
      disabled: "Disabled",
      disconnect: "Disconnect",
      disconnected: "Disconnected",
      disconnectUser: "Disconnect User",
      logout: "Logout",
      logoutUser: "Logout User",
      release: "Release",
      releaseUser: "Release User Resources",
      remove: "Remove",
      removeUser: "Remove User",
      resolve: "Resolve",
      view: "View",
      users: "Users",
      sessions: "Sessions",
      chat: "Chat",
      sendChatMessageButton: "Send chat message",
      notification: "Notifications",
      noNotifications: "All is quiet",
      locationUnknown: "Location Unknown",
      resolveHelpDialogTitle: "Resolve Issue",
      resolveHelpDialogText: "Please confirm you would like to resolve <b>{{name}}'s</b> issue.",
      typeConfirm: "Type confirmation message here",
      startEventDialogText: "Before starting the event please make sure that all players have joined. Once the event starts, no new players can join.<br /><br />To start the event, type in <b>START EVENT</b> in the text field below and select the <em>Confirm</em> button.",
      endEventDialogText: "To end the event, type in <b>END EVENT</b> in the text field below and select the <em>Confirm</em> button.",
      confirmDisconnectDialogText: "Please confirm you would like to disconnect <b>{{name}}</b> from the event.<br /><br />The user will be able to refresh their browser and rejoin the session.",
      confirmReleaseDialogText: "Please confirm you would like to release <b>{{name}}</b>'s resources.<br /><br />This may be necessary if the user disconnected from the event and has locked resources which prevent their teammates from moving forward.",
      confirmLogoutDialogText: "Please confirm you would like to logout <b>{{name}}</b> from the event.<br /><br />This will allow the user to login from a new browser or device.",
      confirmRemoveDialogText: "Please confirm you would like to permanently remove <b>{{name}}</b> from the event.<br /><br />This user will not be able to enter the event again.",
      cancel: "Cancel",
      confirm: "Confirm",
      close: "Close",
      broadcastMessage: "Broadcast Message",
      enterMessage: "Enter message...",
      confirmBroadcastText: "Enter message below and select <em>Send</em> to broadcast to all group chats.",
    },
  },
  game01Common,
  game02Common,
};
