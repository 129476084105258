import { mergeStyles, Stack } from '@fluentui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { store } from '../../../store';
import { emitUserUpdate } from '../../../store/multiplayer-slice';
import Button from '../../Global/Button/Button';
import { Heading, P } from '../../Global/Text';
import logo from '../../../static/images/Game01/tutorial/BFYO_Logo 1.png';

export interface PickDisplayNameProps {
  title: string,
  body: string,
  placeholder: string,
  onSubmit: () => void,
}

const wrapperStyles = mergeStyles({
  position: 'absolute',
  width: '100%',
  padding: '18% 24% 0 24%',
  overflow: 'hidden',
});

const inputStyles = mergeStyles({
  height: '68px',
  border: '3px solid #96AB5E',
  boxShadow: 'inset 4px 4px 4px rgba(0, 0, 0, 0.25)',
  padding: '0 10px',
  fontSize: 24,
});

export default function PickDisplayName({
  title,
  body,
  placeholder,
  onSubmit,
}: PickDisplayNameProps): JSX.Element {
  const { t } = useTranslation('common');
  const [displayName, setDisplayName] = React.useState('');

  const submitName = () => {
    if (/\p{L}/u.test(displayName)) {
      store.dispatch(emitUserUpdate({ display_name: displayName }));
      onSubmit();
    }
  };

  const handleKeyDown = (key: string) => {
    if (key === 'Enter') submitName();
  };

  return (
    <Stack verticalAlign="center" className={wrapperStyles}>
      <Stack tokens={{ childrenGap: 18 }}>
        <Stack horizontal horizontalAlign="space-between">
          <Stack tokens={{ childrenGap: 3 }} verticalAlign="end">
            <Stack.Item>
              <Heading level={1}>
                {title}
              </Heading>
            </Stack.Item>
            <Stack.Item>
              <P level={2}>
                {body}
              </P>
            </Stack.Item>
          </Stack>
          <Stack.Item>
            <img src={logo} alt="BFYO Logo" />
          </Stack.Item>
        </Stack>
        <Stack tokens={{ childrenGap: 53 }}>
          <Stack>
            <input
              className={inputStyles}
              type="text"
              placeholder={placeholder}
              value={displayName}
              aria-label={title} // Title/heading provides instructions
              onChange={(e) => setDisplayName(e.target.value)}
              onKeyDown={(e) => handleKeyDown(e.key)}
              maxLength={32}
            />
          </Stack>
          <Stack horizontalAlign="end">
            <Button
              isPrimary
              label={t('buttons.submit')}
              onClick={submitName}
              disabled={displayName === ''}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
