import { EpisodeDefinition } from '../../../../lib/game-data/types';
import { Phase01Review } from './Phase01Review';
import { Phase02Review } from './Phase02Review';

export const Episode01: EpisodeDefinition = {
  id: 'Episode01',
  phases: [
    {
      id: 'Phase01',
      activityIds: ['../Task01', '../Task02', '../Task03', '../Task04'],
      review: Phase01Review,
      reviewBasePointValue: 0,
      // reviewTotalValue: 1000
    },
    {
      id: 'Phase02',
      activityIds: ['../Task05', '../Task06', '../Task07', '../Task08', '../Task09'],
      review: Phase02Review,
      reviewBasePointValue: 0,
    },
    {
      id: 'Phase03',
      activityIds: ['../Task10', '../Task11', '../Task12', '../Task13'],
      review: [], // No review for phase03
      reviewBasePointValue: 0,
    },
  ],
  elements: {
    //
    // Cards & Related Non-Lead Clues for Intro
    //
    'Battery': {
      type: 'battery',
      capacity: 1100,
      discipline: 'multiple',
    },
    'Lock01': {
      type: 'lock',
      linkIds: ['../Battery01'],
      discipline: 'multiple',
    },
    'Battery01': {
      type: 'wire',
      locationId: '../Lock01',
      linkIds: ['../Battery'],
      discipline: 'multiple',
    },
    'Lock02': {
      type: 'lock',
      linkIds: ['../Battery02'],
      discipline: 'multiple',
    },
    'Battery02': {
      type: 'wire',
      locationId: '../Lock02',
      linkIds: ['../Battery'],
      discipline: 'multiple',
    },
    'Lock03': {
      type: 'lock',
      linkIds: ['../Battery03'],
      discipline: 'multiple',
    },
    'Battery03': {
      type: 'wire',
      locationId: '../Lock03',
      linkIds: ['../Battery'],
      discipline: 'multiple',
    },
    'Lock04': {
      type: 'lock',
      linkIds: ['../Battery04'],
      discipline: 'multiple',
    },
    'Battery04': {
      type: 'wire',
      locationId: '../Lock04',
      linkIds: ['../Battery'],
      discipline: 'multiple',
    },
    'Lock05': {
      type: 'lock',
      linkIds: ['../Battery05'],
      discipline: 'multiple',
    },
    'Battery05': {
      type: 'wire',
      locationId: '../Lock05',
      linkIds: ['../Battery'],
      discipline: 'multiple',
    },
    'Lock06': {
      type: 'lock',
      linkIds: ['../Battery06'],
      discipline: 'multiple',
    },
    'Battery06': {
      type: 'wire',
      locationId: '../Lock06',
      linkIds: ['../Battery'],
      discipline: 'multiple',
    },
    'Lock07': {
      type: 'lock',
      linkIds: ['../Battery07'],
      discipline: 'multiple',
    },
    'Battery07': {
      type: 'wire',
      locationId: '../Lock07',
      linkIds: ['../Battery'],
      discipline: 'multiple',
    },
    'Lock08': {
      type: 'lock',
      linkIds: ['../Battery08'],
      discipline: 'multiple',
    },
    'Battery08': {
      type: 'wire',
      locationId: '../Lock08',
      linkIds: ['../Battery'],
      discipline: 'multiple',
    },
    'StartA': {
      type: 'start',
      linkIds: ['../Storage'],
      discipline: 'storage',
      unlocked: true,
    },
    'Storage': {
      type: 'wire',
      locationId: '../StartA',
      linkIds: ['../010100A'],
      discipline: 'storage',
      powerRange: [0, 100],
    },
    '010100A': {
      type: 'node',
      taskId: '../Task01',
      linkIds: ['../StorageA', '../StorageB'],
      discipline: 'storage',
      unlocked: true,
    },
    'StorageA': {
      type: 'wire',
      locationId: '../010100A',
      linkIds: ['../010102'],
      discipline: 'storage',
      powerRange: [75, 100],
    },
    'StorageB': {
      type: 'wire',
      locationId: '../010100A',
      linkIds: ['../010102'],
      discipline: 'storage',
      powerRange: [40, 75],
    },
    '010100B': {
      type: 'node',
      taskId: '../Task02',
      linkIds: ['../ModelA', '../ModelB'],
      discipline: 'model',
      unlocked: true,
    },
    'ModelA': {
      type: 'wire',
      locationId: '../010100B',
      linkIds: ['../010104'],
      discipline: 'model',
      powerRange: [75, 100],
    },
    'ModelB': {
      type: 'wire',
      locationId: '../010100B',
      linkIds: ['../010104'],
      discipline: 'model',
      powerRange: [40, 75],
    },
    '010100C': {
      type: 'node',
      taskId: '../Task03',
      linkIds: ['../DashboardA', '../DashboardB'],
      discipline: 'dashboard',
      unlocked: true,
    },
    'DashboardA': {
      type: 'wire',
      locationId: '../010100C',
      linkIds: ['../010105'],
      discipline: 'dashboard',
      powerRange: [75, 100],
    },
    'DashboardB': {
      type: 'wire',
      locationId: '../010100C',
      linkIds: ['../010105'],
      discipline: 'dashboard',
      powerRange: [40, 75],
    },
    '010102': {
      type: 'node',
      taskId: '../Task04',
      linkIds: ['../StorageC', '../StorageD', 'Lock01A', 'Lock01B', 'Lock03A', 'Lock04A', 'Lock06A'],
      discipline: 'storage',
      hybridActivityType: 'hybrid',
    },
    'StorageC': {
      type: 'wire',
      locationId: '../010102',
      linkIds: ['../010202'],
      discipline: 'storage',
      powerRange: [75, 100],
    },
    'StorageD': {
      type: 'wire',
      locationId: '../010102',
      linkIds: ['../010202'],
      discipline: 'storage',
      powerRange: [40, 75],
    },
    'Lock01A': {
      type: 'wire',
      locationId: '../010102',
      linkIds: ['../Lock01'],
      discipline: 'multiple',
    },
    'Lock01B': {
      type: 'wire',
      locationId: '../010102',
      linkIds: ['../Lock01'],
      discipline: 'multiple',
    },
    'Lock03A': {
      type: 'wire',
      locationId: '../010102',
      linkIds: ['../Lock03'],
      discipline: 'multiple',
    },
    'Lock04A': {
      type: 'wire',
      locationId: '../010102',
      linkIds: ['../Lock04'],
      discipline: 'multiple',
    },
    'Lock06A': {
      type: 'wire',
      locationId: '../010102',
      linkIds: ['../Lock06'],
      discipline: 'multiple',
    },
    '010104': {
      type: 'node',
      taskId: '../Task06',
      linkIds: ['../ModelC', '../ModelD'],
      discipline: 'model',
    },
    'ModelC': {
      type: 'wire',
      locationId: '../010104',
      linkIds: ['../010201'],
      discipline: 'model',
      powerRange: [75, 100],
    },
    'ModelD': {
      type: 'wire',
      locationId: '../010104',
      linkIds: ['../010201'],
      discipline: 'model',
      powerRange: [40, 75],
    },
    '010105': {
      type: 'node',
      taskId: '../Task07',
      linkIds: ['../DashboardA', '../DashboardB', 'Lock07A', 'Lock08B'],
      discipline: 'dashboard',
    },
    'DashboardC': {
      type: 'wire',
      locationId: '../010105',
      linkIds: ['../010203'],
      discipline: 'dashboard',
      powerRange: [75, 100],
    },
    'DashboardD': {
      type: 'wire',
      locationId: '../010105',
      linkIds: ['../010203'],
      discipline: 'dashboard',
      powerRange: [40, 75],
    },
    'Lock07A': {
      type: 'wire',
      locationId: '../010105',
      linkIds: ['../Lock07'],
      discipline: 'multiple',
    },
    'Lock08B': {
      type: 'wire',
      locationId: '../010105',
      linkIds: ['../Lock08'],
      discipline: 'multiple',
    },
    '010202': {
      type: 'node',
      taskId: '../Task08',
      linkIds: ['../StorageG', '../StorageH', 'Lock05A'],
      discipline: 'storage',
    },
    'StorageG': {
      type: 'wire',
      locationId: '../010202',
      linkIds: ['../010401'],
      discipline: 'storage',
      powerRange: [75, 100],
    },
    'StorageH': {
      type: 'wire',
      locationId: '../010202',
      linkIds: ['../010401'],
      discipline: 'storage',
      powerRange: [40, 75],
    },
    'Lock05A': {
      type: 'wire',
      locationId: '../010202',
      linkIds: ['../Lock05'],
      discipline: 'multiple',
    },
    '010201': {
      type: 'node',
      taskId: '../Task09',
      linkIds: ['../ModelE', '../ModelF', 'Lock02A', 'Lock08A'],
      discipline: 'model',
    },
    'ModelE': {
      type: 'wire',
      locationId: '../010201',
      linkIds: ['../010401'],
      discipline: 'model',
      powerRange: [75, 100],
    },
    'ModelF': {
      type: 'wire',
      locationId: '../010201',
      linkIds: ['../010401'],
      discipline: 'model',
      powerRange: [40, 75],
    },
    'Lock02A': {
      type: 'wire',
      locationId: '../010201',
      linkIds: ['../Lock02'],
      discipline: 'multiple',
    },
    'Lock08A': {
      type: 'wire',
      locationId: '../010201',
      linkIds: ['../Lock08'],
      discipline: 'multiple',
    },
    '010203': {
      type: 'node',
      taskId: '../Task010',
      linkIds: ['../DashboardE', '../DashboardF'],
      discipline: 'dashboard',
    },
    'DashboardE': {
      type: 'wire',
      locationId: '../010203',
      linkIds: ['../010401'],
      discipline: 'dashboard',
      powerRange: [75, 100],
    },
    'DashboardF': {
      type: 'wire',
      locationId: '../010203',
      linkIds: ['../010401'],
      discipline: 'dashboard',
      powerRange: [40, 75],
    },
    '010401': {
      type: 'node',
      linkIds: ['../Battery'],
      discipline: 'storage',
      taskId: '../Task11',
    },
  },
  activities: {
    Task01: {
      baseScore: 100,
      elements: {
        ChangeLogA: {
          type: 'log',
          discipline: 'storage',
          output: 10,
          locationId: '../../010100A',
        },
        ChangeLogB: {
          type: 'log',
          discipline: 'storage',
          output: 5,
          locationId: '../../010100A',
        },
        ChangeLogC: {
          type: 'log',
          discipline: 'storage',
          output: 5,
          locationId: '../../010100A',
        },
        ChangeLogD: {
          type: 'log',
          discipline: 'storage',
          output: 5,
          locationId: '../../010100A',
        },
      },
    },
    Task02: {
      baseScore: 100,
      elements: {
        ChangeLogE: {
          type: 'log',
          discipline: 'storage',
          output: 25,
          locationId: '../../010100B',
        },
      },
    },
    Task03: {
      baseScore: 100,
      elements: {
        ChangeLogF: {
          type: 'log',
          discipline: 'storage',
          output: 25,
          locationId: '../../010100C',
        },
      },
    },
    Task04: {
      baseScore: 100,
      elements: {
        ChangeLogG: {
          type: 'log',
          discipline: 'storage',
          output: 25,
          locationId: '../../010102',
          unlockIds: ['../../Lock01', '../../Lock03', '../../Lock06'],
        },
      },
    },
    // Task05: {
    //   baseScore: 100,
    //   elements: {
    //     ChangeLogH: {
    //       type: 'log',
    //       discipline: 'storage',
    //       output: 25,
    //       locationId: '../../010103',
    //       unlockIds: ['../../Lock01', '../../Lock04'],
    //     },
    //   },
    // },
    Task06: {
      baseScore: 100,
      elements: {
        ChangeLogI: {
          type: 'log',
          discipline: 'storage',
          output: 25,
          locationId: '../../010104',
        },
      },
    },
    Task07: {
      baseScore: 100,
      elements: {
        ChangeLogJ: {
          type: 'log',
          discipline: 'storage',
          output: 25,
          locationId: '../../010105',
          unlockIds: ['../../Lock07', '../../Lock08'],
        },
      },
    },
    Task08: {
      baseScore: 100,
      elements: {
        ChangeLogK: {
          type: 'log',
          discipline: 'storage',
          output: 25,
          locationId: '../../010202',
          unlockIds: ['../../Lock05'],
        },
      },
    },
    Task09: {
      baseScore: 100,
      elements: {
        ChangeLogL: {
          type: 'log',
          discipline: 'storage',
          output: 25,
          locationId: '../../010201',
          unlockIds: ['../../Lock02', '../../Lock08'],
        },
      },
    },
    Task010: {
      baseScore: 100,
      elements: {
        ChangeLogM: {
          type: 'log',
          discipline: 'storage',
          output: 25,
          locationId: '../../010203',
        },
      },
    },
    Task11: {
      baseScore: 100,
      elements: {
        ChangeLogN: {
          type: 'log',
          discipline: 'storage',
          output: 0,
          locationId: '../../010401',
        },
      },
    },
  },
  mapPositions: {
    'Storage': {
      x: 258,
      y: 664,
    },
    'StorageA': {
      x: 414,
      y: 444,
    },
    'StorageB': {
      x: 420,
      y: 470,
    },
    'StorageC': {
      x: 654,
      y: 444,
    },
    'StorageD': {
      x: 660,
      y: 466,
    },
    'StorageG': {
      x: 884.0000000000001,
      y: 396,
    },
    'StorageH': {
      x: 883.9999999999999,
      y: 412,
    },
    'Lock01': {
      x: 820,
      y: 100,
    },
    'Lock02': {
      x: 870,
      y: 130,
    },
    'Lock03': {
      x: 920,
      y: 160,
    },
    'Lock04': {
      x: 970.0000000000001,
      y: 190,
    },
    '010401': {
      x: 1056,
      y: 407.9999999999999,
    },
    '010202': {
      x: 816,
      y: 408,
    },
    'Lock05': {
      x: 1390,
      y: 430,
    },
    '010102': {
      x: 594,
      y: 434,
    },
    'Lock06': {
      x: 1440,
      y: 460,
    },
    'Lock07': {
      x: 1490,
      y: 490,
    },
    'Lock08': {
      x: 1540,
      y: 520,
    },
    '010100A': {
      x: 382,
      y: 628,
    },
    'StartA': {
      x: 234.00000000000006,
      y: 770,
    },
  },
  wirePositions: {},
};
