import Task05 from './Task05/Task05';
import Task07 from './Task07/Task07';
import Task01 from './Task01/Task01';
import Task02 from './Task02/Task02';

/**
 * Game01 Episode01: All task components in a single namespace.
 */

export default {
  Task01,
  Task02,
  Task05,
  Task07,
};
