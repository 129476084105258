import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GameData } from '../lib/api/types';
import { EventInfo } from '../lib/socket/schemas';
import { SafeDictionary } from '../lib/types';
import { ScoreVariables } from '../tasks/task-common';

import { gameData } from '../static/game-data';
import type { RootState } from '.';
import type { LeadData } from '../lib/game-data/lead-data';

export interface GameStateInterface {
  lastActivePanel: string;
  activePanel: string;
  activeCallout: string[];
  gameData: GameData | null;
  eventInfo: EventInfo | null;
  sessionStartTime: number;
  currentLeadId: string | null;
  currentActivityId: string | null;
  leadScores: SafeDictionary<UserLeadScore>;
  phaseScores: SafeDictionary<number>;
  skipTutorial: boolean;
  gameThemeOverride: string | null;
}

export interface UserLeadScore {
  userId: string | null;
  score: number;
}

export interface ScoreUpdate {
  amount: number;
  id: string;
  force?: boolean;
  attempt?: number;
}

export interface ScoreUpdateWithId extends ScoreUpdate {
  userId: string;
}

// the initial game state
const initialState: GameStateInterface = {
  lastActivePanel: '',
  activePanel: '',
  activeCallout: [],
  gameData: null,
  eventInfo: null,
  sessionStartTime: 0,
  currentLeadId: null,
  currentActivityId: null,
  leadScores: {},
  phaseScores: {},
  skipTutorial: false,
  gameThemeOverride: null,
};

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setActivePanel: (state, { payload }: PayloadAction<string>) => {
      const isOpen = state.activePanel === payload;
      state.lastActivePanel = state.activePanel;
      state.activePanel = isOpen ? '' : payload;
    },
    closePanel: (state) => {
      if (state.activePanel) {
        state.lastActivePanel = state.activePanel;
        state.activePanel = '';
      }
    },
    setActiveCallout: (state, { payload }) => {
      state.activeCallout = payload;
    },
    setGameData: (state, { payload }) => {
      state.gameData = payload;
    },
    setSessionStartTime: (state, { payload }) => {
      state.sessionStartTime = payload;
    },
    setCurrentLead: (state, { payload }: PayloadAction<string | LeadData | null | undefined>) => {
      if (typeof payload === 'string') {
        state.currentLeadId = gameData.get(payload, 'lead')?.id ?? null;
      } else if (payload != null) {
        state.currentLeadId = payload.id;
      } else {
        state.currentLeadId = null;
      }
    },
    addPlayerScore: (state, { payload }: PayloadAction<ScoreUpdateWithId>) => {
      let totalScore = payload.amount;
      const previousScore = state.leadScores[payload.id]?.score || 0;
      totalScore += previousScore;

      if (totalScore >= ScoreVariables.leadLimit) {
        totalScore = ScoreVariables.leadLimit;
      } else if (totalScore < 0) {
        totalScore = 0;
      }
      return {
        ...state,
        leadScores: { ...state.leadScores, [payload.id]: { score: totalScore, userId: payload.userId } },
      };
    },
    addPhaseScore: (state, { payload }: PayloadAction<ScoreUpdate>) => {
      let newScore = payload.amount;
      const previousScore = state.phaseScores[payload.id] || 0;
      newScore += previousScore;
      return {
        ...state,
        phaseScores: {
          ...state.phaseScores,
          [payload.id]: newScore > ScoreVariables.leadLimit ? ScoreVariables.leadLimit : newScore,
        },
      };
    },
    resetPlayerScore: (state, { payload }: PayloadAction<string>) => {
      type leadScoreType = typeof state.leadScores;
      const newLeadScores: leadScoreType = {};
      Object.keys(state.leadScores).forEach((key) => {
        if (key !== payload) {
          newLeadScores[key] = state.leadScores[key];
        }
      });
      return {
        ...state,
        leadScores: newLeadScores,
      };
    },
    setEventInfo: (state, { payload }: PayloadAction<EventInfo>) => {
      state.eventInfo = payload;
    },
    setSkipTutorial: (state, { payload }: PayloadAction<boolean>) => {
      state.skipTutorial = payload;
      return state;
    },
    setGameThemeOverride: (state, { payload }: PayloadAction<string | null>) => {
      state.gameThemeOverride = payload;
    },
  },
});

export const selectCurrentLead = (state: RootState | GameStateInterface) => {
  const { currentLeadId } = 'game' in state ? state.game : state;
  return gameData.get(currentLeadId, 'lead');
};

export const selectCurrentActivity = (state: RootState | GameStateInterface) => {
  const { currentActivityId } = 'game' in state ? state.game : state;
  return gameData.get(currentActivityId, 'activity');
};

export const getLeadScores = (scores: SafeDictionary<UserLeadScore>) => {
  const totalPlayerScore = Object.values(scores).reduce<SafeDictionary<number>>((accumulator, value) => {
    if (value == null || value.score == null || value.userId == null) {
      return accumulator;
    }
    if (!accumulator[value.userId]) {
      accumulator[value.userId] = value.score;
    } else {
      const currentScore = accumulator[value.userId] || 0;
      accumulator[value.userId] = value.score + currentScore;
    }
    return accumulator;
  }, {});

  return totalPlayerScore;
};

export const getLeadCount = (count: SafeDictionary<UserLeadScore>) => {
  const leadCount = Object.values(count).reduce<SafeDictionary<number>>((accumulator, value) => {
    if (value == null || value.score == null || value.userId == null) {
      return accumulator;
    }
    if (!accumulator[value.userId]) {
      accumulator[value.userId] = 1;
    } else {
      const currentScore = accumulator[value.userId] || 0;
      accumulator[value.userId] = 1 + currentScore;
    }
    return accumulator;
  }, {});

  return leadCount;
};

export const getTeamScore = (scores: SafeDictionary<number>) => {
  let teamScore = 0;

  teamScore += Object.values(scores).reduce<number>((accumulator, value) => {
    if (value != null) {
      return accumulator + value;
    }
    return accumulator;
  }, 0);

  return teamScore;
};
/**
 * Grouped export for gameSlice actions.
 */
export const actions = Object.freeze({
  ...gameSlice.actions,
});

/**
 * Individual exports for gameSlice actions.
 */
export const {
  setActivePanel,
  closePanel,
  setActiveCallout,
  setGameData,
  setSessionStartTime,
  setCurrentLead,
  setEventInfo,
  addPlayerScore,
  addPhaseScore,
  resetPlayerScore,
  setSkipTutorial,
  setGameThemeOverride,
} = actions;
