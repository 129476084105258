import * as React from 'react';
import { Text as FluentText } from '@fluentui/react/lib/Text';
import { mergeStyles } from '@fluentui/react';
import getFontVariant, { TextComponent } from './getFontVariant';
import TextProps from './interface';
import { getCurrentGameTheme } from '../../../theme';

interface Props extends TextProps {
  level: 1 | 2 | 3;
  color?: string;
}

export default function Label({
  children,
  level,
  block = false,
  nowrap = false,
  noMargin = false,
  useDark = false,
  center = false,
  color,
}: React.PropsWithChildren<Props>): JSX.Element {
  const theme = getCurrentGameTheme();

  const styles = mergeStyles({
    ...(noMargin && { margin: 0 }),
    // NOTE: Text shadow is being applied to ALL labels using useDark! This might need to be dialed in!
    ...(useDark && { color: theme.palette.white, textShadow: '2px 2px 5px #222' }),
    ...(center && { textAlign: 'center' }),
    ...(color && { color }),
    overflowWrap: 'anywhere',
  });

  return (
    <FluentText
      as="label"
      block={block}
      nowrap={nowrap}
      variant={getFontVariant(TextComponent.enum.Label, level)}
      className={styles}
    >
      {children}
    </FluentText>
  );
}
