import * as React from 'react';
import { IStackStyles, IStackTokens, Stack } from '@fluentui/react';
import { clone } from 'lodash';
import { useTranslation } from 'react-i18next';
import Screen from '../../../Global/Screen/Screen';
import Button from '../../../Global/Button/Button';
import { Heading, P } from '../../../Global/Text';
import Panel from '../../../Global/Panel/Panel';

interface Props {
  heading: string;
  subhead: string;
  body?: string;
  showWalkthrough: boolean;
  onSuccess: () => void;
  onError: () => void;
  isUnlocked: boolean;
}
export interface DropdownItem {
  key: number | string;
  text: string;
  disabled: boolean;
  isCorrect?: boolean;
}
export interface TaskState {
  [key: string]: {
    order: number;
    label: string;
    options: DropdownItem[];
  };
}
interface combinationState {
  currentError: string | null;
  combination: (string | number)[];
  currentCombination: (string | number)[];
}

const activityWrapper: IStackStyles = {
  root: {
    width: '100%',
    // maxWidth: 815,
    // backgroundColor: theme.palette.white,
    // boxShadow: theme.effects.elevation4,
    // borderRadius: theme.effects.roundedCorner4,
    // padding: 40,
  },
};

const activityWrapperTokens: IStackTokens = {
  childrenGap: 20,
};

const optionsWrapper: IStackStyles = {
  root: {},
};

export function useMultipleDropdown(
  initialTaskState: TaskState,
  combination: number[],
  isWalkthrough: boolean = false,
  walkthroughStep: number,
) {
  const taskLength = React.useMemo(() => Object.keys(initialTaskState).length, [initialTaskState]);
  const [taskState, setTaskState] = React.useState<any>(initialTaskState);

  const [combinationState, setCombinationState] = React.useState<combinationState>({
    currentError: null,
    combination,
    currentCombination: new Array(taskLength).fill(-1),
  });
  // Create a set of ref to assign to our dropdowns
  const [dropdownRefs] = React.useState<any>([React.createRef(), React.createRef(), React.createRef()]);

  const resetOptions = () => {
    if (!dropdownRefs || dropdownRefs?.length === 0) {
      return;
    }

    setCombinationState((val) => {
      return {
        ...val,
        currentCombination: new Array(taskLength).fill(-1),
      };
    });

    dropdownRefs.forEach((lockRef: any) => lockRef.current.reset());
  };

  React.useEffect(() => {
    if (isWalkthrough) {
      setTaskState((currentTaskState: any) => {
        // disabled wrong answers in dropdowns
        const newTaskState = { ...currentTaskState };
        Object.keys(currentTaskState).forEach((optionsKey, optionsIndex) => {
          newTaskState[optionsKey].options = currentTaskState[optionsKey].options.map((option: DropdownItem) => {
            if (combinationState.combination[optionsIndex] === option.key) {
              return { ...option };
            }
            return { ...option, disabled: true };
          });
        });
        return newTaskState;
      });
    }
  }, [isWalkthrough, combinationState.combination]);

  // set focus on the current walkthrough step dropdown
  React.useEffect(() => {
    if (isWalkthrough && walkthroughStep <= taskLength) {
      dropdownRefs.forEach((lockRef: any) => {
        lockRef.current.disable();
      });
      dropdownRefs[walkthroughStep].current.displayHint();
    }
  }, [walkthroughStep, isWalkthrough, dropdownRefs, taskLength]);

  const handleCombinationChange = (valueIndex: number, value: number | string) => {
    setCombinationState((val) => {
      const newCombination = clone(val.currentCombination);
      newCombination[valueIndex] = value;

      return {
        ...val,
        currentCombination: newCombination,
      };
    });
  };

  return { dropdownRefs, handleCombinationChange, resetOptions, combinationState, taskState };
}

export default function MultipleDropdown({
  children,
  heading,
  subhead,
  body,
  showWalkthrough,
  onSuccess,
  onError,
  isUnlocked,
}: React.PropsWithChildren<Props>) {
  const { t } = useTranslation('common');
  return (
    <Screen>
      <Panel fullscreen useDark>
        <Heading level={1} useDark noMargin>
          {heading}
        </Heading>
        <P level={2} useDark>
          {subhead}
        </P>
        <Stack verticalAlign="center" horizontalAlign="center">
          <Stack styles={activityWrapper} tokens={activityWrapperTokens}>
            {body && (
              <P level={1} block noMargin useDark>
                {body}
              </P>
            )}
            <Stack styles={optionsWrapper} tokens={{ childrenGap: 20 }}>
              {children}
              <Stack.Item align="end">
                <Button isPrimary label={t('done')} onClick={isUnlocked ? onSuccess : onError} disabled={showWalkthrough} />
              </Stack.Item>
            </Stack>
          </Stack>
        </Stack>
      </Panel>
    </Screen>
  );
}
