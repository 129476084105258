/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { useForm } from 'react-hook-form';
import { IButtonStyles, IconButton, IStackStyles, Stack, Theme } from '@fluentui/react';
import { TFunction } from 'react-i18next';
import { IOSocket } from '../../../lib/socket/types';
import { ControlledTextField } from '../HookFormComponents/HookFormComponents';
import { getCurrentGameTheme } from '../../../theme';

interface ChatSubmitProps {
  session_slug?: string;
  socket: IOSocket;
  t: TFunction;
}

function footerStyles(theme: Theme): IStackStyles {
  return {
    root: {
      padding: 18,
      backgroundColor: theme.palette.themePrimary,
      input: {
        fontWeight: 'normal',
      },
    },
  };
}

const buttonStyles: IButtonStyles = {
  root: {
    marginLeft: -32,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
};

type Form = {
  message: string;
};

const ChatSubmit: React.FC<ChatSubmitProps> = ({ session_slug, socket, t }) => {
  const theme = getCurrentGameTheme();

  const { handleSubmit, control, setValue } = useForm<Form>({
    defaultValues: {
      message: '',
    },
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  const onSend = () => {
    handleSubmit(
      async (data) => {
        try {
          const { message } = data;
          if (!message.length) {
            return;
          }
          socket.emit('chat:send', {
            room: `session:${session_slug}`,
            message,
          });
          setValue('message', '');
        } catch (error: any) {
          throw new Error(error);
        }
      },
      (err) => {
        console.log('ChatError:', err);
      },
    )();
  };

  return (
    <Stack.Item styles={footerStyles(theme)}>
      <form onSubmit={handleSubmit(onSend)}>
        <Stack horizontal verticalAlign="center">
          <Stack.Item grow>
            <ControlledTextField autoComplete="off" placeholder={t('chat.submitField')} control={control} style={{ paddingRight: 32 }} borderless name="message" />
          </Stack.Item>
          <Stack.Item>
            <IconButton
              iconProps={{ iconName: 'Forward' }}
              styles={buttonStyles}
              type="submit"
              aria-label={t('chat.sendChatMessageButton')}
            />
          </Stack.Item>
        </Stack>
      </form>
    </Stack.Item>
  );
};

export default ChatSubmit;
