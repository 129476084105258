import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTaskMachine } from '../../../../lib/hooks/useTaskMachine';
import K020 from './components/K020';
import K030 from './components/K030';
import K035 from './components/K035';
import K040 from './components/K040';
import K050 from './components/K050';
import K060 from './components/K060';
import K070 from './components/K070';
import K080 from './components/K080';
import K090 from './components/K090';
import { createTask11 } from './machine';

const baseNS = 'Game01.Episode02.Task11';
const taskKey = 'Game01.Episode02.Task11';

function createTaskMatch(parent: string, state: any) {
  return (matchState: string) => state.matches(`${parent}.${matchState}`);
}

const Task11: React.FC = () => {
  const { t } = useTranslation([baseNS, 'common']);

  // Setup task machine
  const [state, send] = useTaskMachine(createTask11, {
    taskKey,
  });

  return (
    <>
      {state.matches('K020') && <K020 t={t} send={send} state={state.context} match={createTaskMatch('K020', state)} />}
      {state.matches('K030') && <K030 t={t} send={send} state={state.context} match={createTaskMatch('K030', state)} />}
      {state.matches('K035') && <K035 t={t} send={send} state={state.context} match={createTaskMatch('K035', state)} />}
      {state.matches('K040') && <K040 t={t} send={send} state={state.context} match={createTaskMatch('K040', state)} />}
      {state.matches('K050') && <K050 t={t} send={send} state={state.context} match={createTaskMatch('K050', state)} />}
      {state.matches('K060') && (
        <K060 t={t} send={send} state={state.context} match={createTaskMatch('K060', state)} />
      )}
      {state.matches('K070') && <K070 t={t} send={send} state={state.context} match={createTaskMatch('K070', state)} />}
      {state.matches('K080') && <K080 t={t} send={send} state={state.context} match={createTaskMatch('K080', state)} />}
      {state.matches('K090') && <K090 t={t} send={send} state={state.context} match={createTaskMatch('K090', state)} />}
    </>
  );
};

export default Task11;
