import * as React from 'react';
import RTrans from '../../../../../components/Global/RTrans/RTrans';
import Modal from '../../../../../components/Game01/Modal/Modal';
import background from '../images/02.04.090.png';
import { ComponentProps } from '../../../../task-types';
import MultipleChoice, { useMultipleChoice } from '../../../../../components/Global/Interactions/MultipleChoice/MultipleChoice';
import Button from '../../../../../components/Global/Button/Button';

export default function A100({ t, send, match }: ComponentProps): JSX.Element {
  const { errorKey, handleIncorrect } = useMultipleChoice(() => send('CLICK.INCORRECT'));

  return (
    <>
      <MultipleChoice
        questionText={t('A100.body')}
        didComplete={match('final')}
        onComplete={() => send('NEXT')}
        backgroundImage={background}
      >
        <Button
          isPrimary
          onClick={() => !match('final') && send('NEXT')}
          // label={t('A100.labels.consentFull')}
          label=""
          icon={match('final') ? 'check' : undefined}
          styles={{ height: '95px' }}
        >
          <RTrans t={t} i18nKey="A100.labels.consentFull" components={[<span style={{ color: 'red' }} />]} />
        </Button>
        <Button
          isPrimary
          onClick={() => handleIncorrect('grantConsent')}
          // label={t('A100.labels.grantConsent')}
          label=""
          disabled={match('final')}
          styles={{ height: '95px' }}
        >
          <RTrans t={t} i18nKey="A100.labels.grantConsent" components={[<span style={{ color: 'red' }} />]} />
        </Button>
      </MultipleChoice>
      <Modal
        sidebar="character"
        title={t(`A100.errors.${errorKey}.title`)}
        body={t(`A100.errors.${errorKey}.body`)}
        isOpen={match('incorrect')}
        footer={<Button isPrimary label={t('common.errorTitle')} onClick={() => send('NEXT')} />}
        onDismiss={() => send('NEXT')}
        hideDismissButton
      />
    </>
  );
}
