import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSimVariableToAddEntryMap } from '../../../../lib/hooks/useSimVariableEntryMap';
import { devLog } from '../../../../lib/util';

import { useTaskMachine } from '../../../../lib/hooks/useTaskMachine';
import A020 from './components/A020';
import A030 from './components/A030';
import A040 from './components/A040';
import A050 from './components/A050';
import A060 from './components/A060';
import A070 from './components/A070';
import A080 from './components/A080';
import A090 from './components/A090';
import A100 from './components/A100';
import A110 from './components/A110';
import A120 from './components/A120';
import A130 from './components/A130';
import A140 from './components/A140';
import A160 from './components/A160';
import A170 from './components/A170';
import A180 from './components/A180';
import { createTask04 } from './machine';

const baseNS = 'Game01.Episode02.Task04';
const taskKey = 'Game01.Episode02.Task04';

function createTaskMatch(parent: string, state: any) {
  return (matchState: string) => state.matches(`${parent}.${matchState}`);
}

const Task04: React.FC = () => {
  const [t] = useTranslation(baseNS);

  const labVariableEntries = useSimVariableToAddEntryMap(taskKey);
  devLog(labVariableEntries);

  // Setup task machine
  const [state, send] = useTaskMachine(createTask04, {
    taskKey,
  });

  return (
    <>
      {state.matches('A020') && <A020 t={t} send={send} state={state.context} match={createTaskMatch('A020', state)} />}
      {state.matches('A030') && <A030 t={t} send={send} state={state.context} match={createTaskMatch('A030', state)} />}
      {state.matches('A040') && <A040 t={t} send={send} state={state.context} match={createTaskMatch('A040', state)} />}
      {state.matches('A050') && <A050 t={t} send={send} state={state.context} match={createTaskMatch('A050', state)} />}
      {state.matches('A060') && <A060 t={t} send={send} state={state.context} match={createTaskMatch('A060', state)} />}
      {state.matches('A070') && <A070 t={t} send={send} state={state.context} match={createTaskMatch('A070', state)} />}
      {state.matches('A080') && <A080 t={t} send={send} state={state.context} match={createTaskMatch('A080', state)} />}
      {state.matches('A090') && <A090 t={t} send={send} state={state.context} match={createTaskMatch('A090', state)} />}
      {state.matches('A100') && <A100 t={t} send={send} state={state.context} match={createTaskMatch('A100', state)} />}
      {state.matches('A110') && <A110 t={t} send={send} state={state.context} match={createTaskMatch('A110', state)} />}
      {state.matches('A120') && <A120 t={t} send={send} state={state.context} match={createTaskMatch('A120', state)} />}
      {state.matches('A130') && <A130 t={t} send={send} state={state.context} match={createTaskMatch('A130', state)} />}
      {state.matches('A140') && <A140 t={t} send={send} state={state.context} match={createTaskMatch('A140', state)} />}
      {state.matches('A160') && <A160 t={t} send={send} state={state.context} match={createTaskMatch('A160', state)} />}
      {state.matches('A170') && <A170 t={t} send={send} state={state.context} match={createTaskMatch('A170', state)} />}
      {state.matches('A180') && <A180 t={t} send={send} state={state.context} match={createTaskMatch('A180', state)} />}
    </>
  );
};

export default Task04;
