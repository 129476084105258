import { IStackStyles, Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, P } from '../../Text';
import { SessionGroup, UserInfo } from '../schemas';
import FacilitatorUserListItem from './FacilitatorUserListItem';
import FacilitatorResolveHelpDialog from './FacilitatorResolveHelpDialog';
import { RootState, useAppSelector } from '../../../../store';
import { getCurrentGameTheme } from '../../../../theme';

export interface IFacilitatorNotificationProps {
  users?: UserInfo[];
}

const theme = getCurrentGameTheme('Global');

const notificationPanelStyles: IStackStyles = {
  root: {
    minWidth: 400,
    backgroundColor: '#F2F2F2',
    boxShadow: theme.effects.elevation8,
  },
};

function getUserSession(username: string, sessions: SessionGroup[]): string | undefined {
  const session = sessions.find((s) => {
    return s.users.findIndex((u) => u.username === username) !== -1;
  });
  if (session) {
    return session.name;
  }
  return undefined;
}

const FacilitatorNotificationPanel: React.FC<IFacilitatorNotificationProps> = ({ users = [] }) => {
  const { t } = useTranslation('common');
  const userReqHelp = users.filter((u) => u.requesting_help);
  const sessionGroups = useAppSelector((state: RootState) => state.facilitator.sessionGroups);
  const color = theme.palette.themePrimary;
  const [hideResolveHelpDialog, { toggle: toggleResolveHelpDialog }] = useBoolean(true);
  const [userToResolve, setUserToResolve] = useState<UserInfo | null>(null);

  const showResolveHelpDialog = (user: UserInfo) => {
    setUserToResolve(user);
    toggleResolveHelpDialog();
  };
  return (
    <>
      <Stack styles={notificationPanelStyles} tokens={{ padding: 24 }}>
        <Stack.Item styles={{ root: { marginBottom: 16 } }}>
          <Heading level={4} color={color}>{t('facilitator.notification')}</Heading>
        </Stack.Item>
        <Stack.Item>
          {userReqHelp.length ? (
            <Stack>
              {(userReqHelp.map((u) => (
                <FacilitatorUserListItem
                  key={`fn_${u.username}`}
                  user={u}
                  sessionName={getUserSession(u.username, sessionGroups)}
                  onResolveHelp={showResolveHelpDialog}
                />
              )))}
            </Stack>
          ) : (
            <P level={3} color={color}>All is quiet.</P>
          )}
        </Stack.Item>
      </Stack>
      {userToResolve !== null && (
        <FacilitatorResolveHelpDialog
          user={userToResolve}
          hideResolveHelpDialog={hideResolveHelpDialog}
          toggleResolveHelpDialog={
            () => {
              toggleResolveHelpDialog();
              setUserToResolve(null);
            }
          }
        />
      )}
    </>
  );
};
export default FacilitatorNotificationPanel;
