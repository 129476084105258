import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { shallowEqual } from 'react-redux';
import _pullAll from 'lodash/pullAll';
import _uniq from 'lodash/uniq';
import type { EntityId, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { extractIdsFromEntry, matchesEntry, updateEntry } from '../lib/notebook/notebook-entry';
import { gameData } from '../static/game-data';
import type { ThunkApiConfig } from '.';
import type { ArrayOrSingle } from '../lib/types';
import type {
  NotebookEntry,
  NotebookEntryUpdate,
  NotebookUnreadUpdate,
  NotebookEntryQueryOptions,
  NotebookEntryQuery,
} from '../lib/notebook/types';

import { generateNotebookEntryProps, NotebookEntryComponentProps } from '../lib/entries';
import { ElementData } from '../lib/game-data/element-data';
// import { ActivityData } from '../lib/game-data/activity-data';

// Sort journal entries by date (descending).
export const notebookAdapter = createEntityAdapter<NotebookEntry>({
  sortComparer: (a, b) => b.date - a.date,
});

export interface NotebookStateInterface {
  entriesSeen: string[];
  entries: ReturnType<typeof notebookAdapter.getInitialState>;
}

// the initial game state
const initialState: NotebookStateInterface = {
  entriesSeen: [],
  entries: notebookAdapter.getInitialState(),
};

/**
 * Selectors for journalAdapter.
 */
export const notebookSelectors = notebookAdapter.getSelectors();

/**
 * Selector to return all entries that are `evidence` entries.
 */
export const selectAllNotebookEntities = createSelector(
  notebookSelectors.selectAll,
  (entities) =>
    entities.filter(({ id }) => {
      const { entryId } = extractIdsFromEntry(id);
      // needs to change
      return gameData.get(entryId)?.kind === 'element';
    }),
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
    },
  },
);

/** */
export const selectAllNotebookEntryProps = createSelector(
  selectAllNotebookEntities,
  (notebookEntities) => generateNotebookEntryProps(notebookEntities) as NotebookEntryComponentProps[],
);

/**
 * Generate a NotebookEntryUpdate payload for a given unlock.
 *
 * @param unlock - The GameData instance for the unlock to generate.
 * @param entryData - The GameData instance from which the unlock originated, or
 *                    which any proxy Card entries should use as a base.
 * @param appliedDataIds - An array of applied entity IDs used to prevent
 *                         duplicate proxy Card entries.
 */
function getNoteBookEntryUpdateForUnlock(
  unlock: ElementData,
  entryData: ElementData,
  appliedDataIds: EntityId[],
): NotebookEntryUpdate | undefined {
  /**
   * A Fact unlock will add the associated clue with the fact on it.
   * (If the clue is already added, this will simply add the fact to it.)
   */
  if (unlock.kind === 'element') {
    const factLocation = unlock.getLocation();
    return factLocation == null
      ? undefined
      : {
          id: factLocation.id,
          states: [unlock.shortId],
        };
  }

  /**
   * A Clue unlock will add the associated clue.
   * (This will do nothing if the clue is already added.)
   */
  if (unlock.type === 'log') {
    return {
      id: unlock.id,
      states: [],
    };
  }

  /**
   * A Card unlock will add the associated card, but _only_ if it has not yet
   * been applied to the Evidence Map.
   *
   * Also adds a `baseId` prefix to the Card JournalEntry consisting of either:
   *   a) the baseEntry's parent's ID; or b) its own ID (intended as fallback).
   *
   * Since EntryComponentProps assumes that the `baseData` (for grouping) is
   * equivalent to `entryData.parent` if not specified, this will generally
   * ensure that this Card entry's EntryComponentProps.baseData will match that
   * of the entryData (i.e. the Lead they will both sort under.)
   */
  if (unlock.type === 'lock') {
    const baseId = entryData.parent?.id ?? entryData.id;
    return appliedDataIds.includes(unlock.id)
      ? undefined
      : {
          id: `${baseId}::${unlock.id}`,
        };
  }
  return undefined;
}

/**
 * Action creator for adding multiple Journal Entries and their associated
 * GameData unlocks.
 *
 * This action uses the Thunk Async API because it provides access to the root
 * Redux state, allowing the logic to access `multiplayer.appliedData` for the
 * purpose of deduplicating proxy Card entries for already-applied cards.
 */
export const addEntriesWithUnlocks = createAsyncThunk<void, ArrayOrSingle<NotebookEntryUpdate>, ThunkApiConfig>(
  'notebook/addEntriesWithUnlocks',
  (payload, { dispatch, getState }) => {
    const entriesIn = Array.isArray(payload) ? payload : [payload];
    if (entriesIn.length === 0) {
      return;
    }
    const appliedDataIds = getState().multiplayer.appliedData.ids;
    const entriesOut = entriesIn.reduce<NotebookEntryUpdate[]>((accumulator, entry) => {
      const entryData = gameData.get(entry.id);
      if (entryData == null) {
        return accumulator;
      }

      // // Pass through policy entries (they don't have unlocks).
      // if (entryData.kind === 'policy') {
      //   accumulator.push(entry);
      //   return accumulator;
      // }

      // Bail out if the entry isn't a Card or Clue.
      if (entryData.kind !== 'element') {
        return accumulator;
      }

      // Add entry for input Evidence entry (w/ facts).
      accumulator.push(entry);

      // Add unlocks on input Evidence.
      entryData.getUnlocks().forEach((unlock) => {
        const newEntry = getNoteBookEntryUpdateForUnlock(unlock, entryData, appliedDataIds);
        if (newEntry != null) {
          accumulator.push(newEntry);
        }
      });

      // Add unlocks on directly-added Facts of the input Evidence entry.
      // entryData.facts.forEach((fact) => {
      //   // Skip facts that aren't being added.
      //   if (!entry.states?.includes(fact.shortId)) {
      //     return;
      //   }

      //   fact.getUnlocks().forEach((unlock) => {
      //     // Pass in input Evidence entry as the base, so that any proxy entries sort correctly.
      //     const newEntry = getNoteBookEntryUpdateForUnlock(unlock, entryData, appliedDataIds);
      //     if (newEntry != null) {
      //       accumulator.push(newEntry);
      //     }
      //   });
      // });
      return accumulator;
    }, []);

    /* eslint-disable-next-line @typescript-eslint/no-use-before-define */
    dispatch(addEntries(entriesOut));
  },
);

/**
 * Journal Slice definition
 */
export const notebookSlice = createSlice({
  name: 'notebook',
  initialState,
  reducers: {
    addEntry: ({ entries }, { payload }: PayloadAction<NotebookEntryUpdate>) => {
      const entity = notebookSelectors.selectById(entries, payload.id);
      const newEntity = updateEntry(entity, payload);
      notebookAdapter.upsertOne(entries, newEntity);
    },
    addEntries: ({ entries }, { payload }: PayloadAction<ArrayOrSingle<NotebookEntryUpdate>>) => {
      (Array.isArray(payload) ? payload : [payload]).forEach((singleUpdate) => {
        const entity = notebookSelectors.selectById(entries, singleUpdate.id);
        const newEntity = updateEntry(entity, singleUpdate);
        notebookAdapter.upsertOne(entries, newEntity);
      });
    },
    // removeEntry: ({ entries }, { payload }: PayloadAction<NotebookEntryUpdate>) => {
    //   const entity = notebookSelectors.selectById(entries, payload.id);
    //   const newEntity = removeStates(entity, payload.states);
    //   if (newEntity == null) {
    //     notebookAdapter.removeOne(entries, payload.id);
    //     return;
    //   }
    //   notebookAdapter.upsertOne(entries, newEntity);
    // },
    // removeEntries: ({ entries }, { payload }: PayloadAction<ArrayOrSingle<NotebookEntryUpdate>>) => {
    //   (Array.isArray(payload) ? payload : [payload]).forEach((singleUpdate) => {
    //     const entity = notebookSelectors.selectById(entries, singleUpdate.id);
    //     const newEntity = removeStates(entity, singleUpdate.states);
    //     if (newEntity == null) {
    //       notebookAdapter.removeOne(entries, singleUpdate.id);
    //       return;
    //     }
    //     notebookAdapter.upsertOne(entries, newEntity);
    //   });
    // },
    setEntriesUnread: (state, { payload }: PayloadAction<NotebookUnreadUpdate>) => {
      const ids = Array.isArray(payload.ids) ? payload.ids : [payload.ids];
      const unread = payload.unread;
      const updates = ids.map((id: string) => ({
        id,
        changes: {
          unread,
        },
      }));
      notebookAdapter.updateMany(state.entries, updates);
      if (unread === false) {
        _pullAll(state.entriesSeen, ids);
      }
    },
    setEntriesSeen: (state, { payload }: PayloadAction<string | string[]>) => {
      const entryIds = Array.isArray(payload) ? payload : [payload];
      state.entriesSeen = _uniq([...state.entriesSeen, ...entryIds]);
    },
  },
});

/**
 * Grouped export for journalSlice actions.
 */
export const actions = Object.freeze({
  addEntriesWithUnlocks,
  ...notebookSlice.actions,
});

/**
 * Individual exports for journalSlice actions.
 */
export const { addEntry, addEntries, setEntriesUnread, setEntriesSeen } = actions;

/**
 * Check if an entry exists in the journal, with optional state checks.
 */
export function hasEntry(state: EntityState<NotebookEntry>, id: string, options?: NotebookEntryQueryOptions): boolean;
export function hasEntry(state: EntityState<NotebookEntry>, query: NotebookEntryQuery): boolean;
export function hasEntry(
  state: EntityState<NotebookEntry>,
  idOrQuery: string | NotebookEntryQuery,
  options?: NotebookEntryQueryOptions,
): boolean {
  // Process overload parameters
  let id: string;
  let queryOptions: typeof options;
  if (typeof idOrQuery === 'string') {
    id = idOrQuery;
    queryOptions = options;
  } else {
    id = idOrQuery.id;
    queryOptions = idOrQuery;
  }

  const entity = notebookSelectors.selectById(state, id);
  return matchesEntry(entity, queryOptions);
}

/**
 * Grouped export for journalSlice query functions.
 */
export const queries = Object.freeze({
  hasEntry,
});
