import React from 'react';
import { Stack, Text } from '@fluentui/react';
import { Trans, useTranslation } from 'react-i18next';
import { EntryContentProps } from '../../../lib/entries';
import getFontVariant, { TextComponent } from '../../Global/Text/getFontVariant';
import { toHTMLSafeId } from '../../../lib/util';
import { getCurrentGameTheme } from '../../../theme';

const theme = getCurrentGameTheme('Game01');

const EntryContent: React.FC<EntryContentProps> = ({ heading, content, entry_id }) => {
  const { t } = useTranslation('entries');
  return (
    <Stack tokens={{ childrenGap: '8px' }} styles={{ root: { overflowWrap: 'anywhere' } }}>
      <Stack.Item>
        <Text
          id={toHTMLSafeId(entry_id, 'headerText', undefined)}
          role="heading"
          aria-level={3}
          variant={getFontVariant(TextComponent.enum.Heading, 5)}
          styles={{ root: { color: theme.palette.white } }}
          block
        >
          {t(heading)}
        </Text>
      </Stack.Item>
      <Stack tokens={{ childrenGap: '2px' }}>
        {content.map((c, i) => (
          <Stack.Item key={`${entry_id}_content_${i}`}>
            <Text
              variant={getFontVariant(TextComponent.enum.Body, 3)}
              styles={{ root: { color: theme.palette.white } }}
              block
            >
              <Trans t={t} i18nKey={c} />
            </Text>
          </Stack.Item>
        ))}
      </Stack>
    </Stack>
  );
};
export default EntryContent;
