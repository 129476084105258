import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactFlowProvider } from 'react-flow-renderer';
import MapContent from './MapContent';

const baseNS = ['evidenceMap', 'entries'];

/**
 * The main orchestration component for the Evidence Map.
 */
const InsightEngine: React.FC = () => {
  const { t } = useTranslation(baseNS);

  return (
    <ReactFlowProvider>
      <MapContent t={t} />
    </ReactFlowProvider>
  );
};

export default InsightEngine;
