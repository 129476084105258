import type { LocaleContent, CardOrEntryLocaleContent } from "../../types";

/**
 * i18Next `entries:Episode02.Task02` lead text content
 */
const Task02Lead: LocaleContent = {
  header: "Investigate Code in GitHub",
  role: "Security Operations Analyst",
  time: "5-10m",
  brief: "Check the GitHub repository for the eCommerce app. Have any files changed since our last security validation 30 days ago? Let me know if you find anything suspicious.<br><br>-Andrea",
};

/**
 * i18Next `entries:Episode02.Task02` entry content
 */
const Task02Entries: CardOrEntryLocaleContent = {
  eCommerceAppCode: {
    heading: "eCommerce App Code",
    CodeOwner: "Wenny Gultom owns the eCommerce app code in the repository",
    UpdatedFiles: "5 files were recently updated: About.aspx, Default.aspx, Default.aspx.cs, ProjectD.csproj, Web.config",
  },
  AboutWenny: {
    heading: "About Wenny",
    default: "Age: 35, Alma Mater: University of Technology Bandung, Hometown: Jakarta, Indonesia, Favorite food: nasi goreng, Pets: German Shephard mobility service dog named Max",
  },
  DefaultASPXCSStatus: {
    heading: "Default.aspx.cs Status",
    MaliciousShellcode: "Default.aspx.cs contains malicious shellcode",
    LastUpdate: "Wenny Gultom was the last person to update Default.aspx.cs",
  },
};

export default {
  ...Task02Lead,
  ...Task02Entries,
};
