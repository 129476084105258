import { DefaultButton, Dialog, DialogFooter, DialogType, IDialogContentProps, IDialogStyles, IModalProps, ITextFieldStyles, PrimaryButton, TextField } from '@fluentui/react';
import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { MPManager } from '../../../../lib/socket';
import { getCurrentGameTheme } from '../../../../theme';
import { greyScale } from '../../../../theme/Global/colors';
import RTrans from '../../RTrans/RTrans';
import { P } from '../../Text';

const theme = getCurrentGameTheme('Global');

export interface IFacilitatorConfirmDialogProps {
  title: string;
  subText: string;
  showDialog: boolean;
  onDismiss: () => void;
  onSubmit: (response: any) => void;
}

const modalProps: IModalProps = {
  isBlocking: true,
};

const dialogStyles: IDialogStyles = {
  root: {},
  main: {
    minWidth: '550px !important',
    backgroundColor: greyScale[2],
  },
};

const textFieldStyles: Partial<ITextFieldStyles> = {
  root: {
    marginTop: 20,
  },
  field: {
    fontWeight: 'normal',
  },
};

function generateDialogContentProps(
  t: TFunction, color: string, title: string,
): IDialogContentProps {
  return {
    type: DialogType.normal,
    theme,
    title,
    closeButtonAriaLabel: t('facilitator.close'),
    styles: {
      title: {
        fontSize: 24,
        color,
      },
    },
  };
}

const FacilitatorConfirmDialog: React.FC<IFacilitatorConfirmDialogProps> = (
  { title, subText, showDialog, onDismiss, onSubmit },
) => {
  const { t } = useTranslation('common');
  const color = theme.palette.themePrimary;
  const socket = MPManager.instance.setupSocket(process.env.REACT_APP_SOCKET_URL);
  const [msgFieldValue, setMsgFieldValue] = React.useState('');
  const onChangeMsgFieldValue = React.useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      setMsgFieldValue(newValue || '');
    },
    [],
  );

  const submitMessage = () => {
    try {
      if (!msgFieldValue.length) {
        return;
      }
      socket.emit('chat:broadcast', {
        message: msgFieldValue,
      }, (response) => {
        onSubmit(response?.status);
      });
    } catch (error: any) {
      throw new Error(error);
    }
  };

  return (
    <Dialog
      hidden={!showDialog}
      onDismiss={onDismiss}
      dialogContentProps={generateDialogContentProps(t, color, title)}
      modalProps={modalProps}
      theme={theme}
      styles={dialogStyles}
    >
      <P level={3}><RTrans t={t} i18nKey={subText} components={{ bold: <strong />, subText }} /></P>
      <TextField
        value={msgFieldValue}
        onChange={onChangeMsgFieldValue}
        placeholder={t('facilitator.enterMessage')}
        styles={textFieldStyles}
        multiline
        autoAdjustHeight
      />
      <DialogFooter>
        <DefaultButton onClick={onDismiss} text="Cancel" />
        <PrimaryButton
          disabled={msgFieldValue.length === 0 || msgFieldValue.trim() === ''}
          onClick={submitMessage}
          text="Send"
        />
      </DialogFooter>
    </Dialog>
  );
};
export default FacilitatorConfirmDialog;
