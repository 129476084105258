import * as React from 'react';
import {
  IProgressIndicatorStyles,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  ProgressIndicator,
  Stack,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';

import Swiper from 'swiper';
import { Heading, P, Label } from '../../Global/Text';
import CardCarousel from './CardCarousel';
import { useAppSelector } from '../../../store';
import { selectAllAppliedCardIds, selectAppliedDataForCardId } from '../../../store/multiplayer-slice/applied-data';
import { selectCurrentEpisode, selectCurrentGame } from '../../../store/multiplayer-slice';
import { getCurrentGameTheme } from '../../../theme';

const theme = getCurrentGameTheme('Game01');

interface Props {
  selectedCardId?: string;
  selectCard?: (cardId: string) => void;
  selectedClueId?: string;
  selectClue?: (clueId: string) => void;
  onSwiper?: (s: Swiper) => void;
}

const wrapperStyles: IStackStyles = {
  root: {
    '@media(min-width: 1080px)': {
      width: '100%',
    },
    '@media(min-width: 1200px)': {
      width: 640,
    },
    '@media(min-width: 1920px)': {
      width: 840,
    },
    // 'marginRight': 16,
    'overflow': 'hidden',
  },
};

const contentStackStyles: IStackStyles = {
  root: {
    backgroundColor: '#E8E0D1', // Todo: Add to palette
    border: '3px solid #8D8678',
    borderRadius: theme.effects.roundedCorner6,
  },
};

const contentStackTokens: IStackTokens = {
  padding: 20,
};

const cardInfoWrapper: IStackStyles = {
  root: {
    minHeight: 250,
  },
};

const cardImageWrapper: IStackItemStyles = {
  root: {
    width: 200,
    height: 200,
    marginRight: 16,
    borderRadius: 6,
  },
};

const cardEvidenceListWrapper: IStackStyles = {
  root: {
    height: 250,
    overflowY: 'auto',
    padding: '12px 24px 0 12px',
    backgroundColor: 'white',
    marginTop: '1rem',
    borderRadius: theme.effects.roundedCorner6,
    border: '3px solid #8D8678',
    boxShadow: 'inset 2px 2px 5px rgba(0,0,0,.4)',
  },
};

const progressStyles: Partial<IProgressIndicatorStyles> = {
  root: {
    marginTop: '1rem',
  },
  progressTrack: {
    borderRadius: 6,
    backgroundColor: '#C4C4C4',
  },
  progressBar: {
    backgroundColor: '#28A7E9',
    borderRadius: 6,
  },
};

// Used in useTranslation() and renderedClues()
const baseNS = ['entries', 'game01Common'];

export default function CardPanel({
  selectedCardId,
  selectCard,
  selectedClueId,
  selectClue,
  onSwiper = () => {},
}: Props) {
  const { t, i18n } = useTranslation(baseNS);

  const appliedCardIds = useAppSelector(selectAllAppliedCardIds);
  const appliedDataForCard = useAppSelector((state) => selectAppliedDataForCardId(state, selectedCardId ?? ''));
  const [appliedCriticalEvidenceCount, totalCriticalEvidenceCount] = React.useMemo(() => {
    if (appliedDataForCard == null) {
      return [0, 0] as const;
    }

    const criticalEvidenceForCard = appliedDataForCard.cardData.getDependencies('critical');
    const appliedCriticalEvidenceForCard = appliedDataForCard.appliedClues.filter(
      (appliedClue) => appliedClue.clueData.importance === 'critical',
    );

    return [appliedCriticalEvidenceForCard.length, criticalEvidenceForCard.length];
  }, [appliedDataForCard]);

  const renderedClues = React.useMemo(
    () =>
      appliedDataForCard?.appliedClues?.map((appliedClue) => {
        const clueId: string = appliedClue.clueData.id;

        const clueWrapper: IStackStyles = {
          root: [
            selectClue && {
              border: 'thin solid #ddd',
              cursor: 'pointer',
              padding: '10px 10px 0px 10px',
              borderRadius: theme.effects.roundedCorner6,
            },
            clueId === selectedClueId && {
              backgroundColor: '#ddd',
              color: 'white',
            },
          ],
        };

        return (
          <Stack key={clueId} styles={selectClue ? clueWrapper : {}} onClick={() => selectClue && selectClue(clueId)}>
            <P level={2} block noMargin>
              {t(`${clueId}.heading`)}
            </P>
            <ul>
              {i18n.exists(`${clueId}.default`, { ns: baseNS }) && (
                <li key={`${clueId}.default`}>
                  <P level={3} block noMargin>
                    {t(`${clueId}.default`)}
                  </P>
                </li>
              )}
              {appliedClue.appliedFacts.map((fact) => {
                return (
                  <li key={fact.id}>
                    <P level={3} block noMargin>
                      {t(fact.id)}
                    </P>
                  </li>
                );
              })}
            </ul>
          </Stack>
        );
      }),
    [t, i18n, appliedDataForCard, selectedClueId, selectClue],
  );
  const detailImage = appliedDataForCard?.cardData.detailImage;
  const currentGameId = useAppSelector(selectCurrentGame);
  const currentEpisodeId = useAppSelector(selectCurrentEpisode);
  const CARD_IMG_PATH = `./images/${currentGameId?.shortId}/cards/${currentEpisodeId?.shortId}/back/`;
  const imgSrc = detailImage ? `${CARD_IMG_PATH}${detailImage}.jpg` : '';
  return (
    <Stack horizontalAlign="stretch" grow tokens={{ childrenGap: 8 }} styles={wrapperStyles}>
      <Stack styles={contentStackStyles} tokens={contentStackTokens}>
        <Stack.Item>
          <Stack horizontal styles={cardInfoWrapper}>
            <Stack.Item disableShrink styles={cardImageWrapper}>
              {imgSrc && <img src={imgSrc} style={{ height: 200 }} alt={t(`${selectedCardId}.heading`)} />}
            </Stack.Item>
            <Stack.Item grow>
              <Heading level={2} block noMargin>
                {t(`${selectedCardId}.heading`)}
              </Heading>
              <P level={2} block>
                {t(`${selectedCardId}.default`)}
              </P>
              {totalCriticalEvidenceCount ? (
                <ProgressIndicator
                  label={t('applyEvidence.cardProgressLabel', {
                    applied: appliedCriticalEvidenceCount,
                    total: totalCriticalEvidenceCount,
                  })}
                  percentComplete={appliedCriticalEvidenceCount / totalCriticalEvidenceCount}
                  barHeight={13}
                  styles={progressStyles}
                />
              ) : (
                <Label level={1} block noMargin>
                  {t('applyEvidence.noCriticalEvidence')}
                </Label>
              )}
            </Stack.Item>
          </Stack>
          <Stack styles={cardEvidenceListWrapper}>{renderedClues}</Stack>
        </Stack.Item>
      </Stack>
      <CardCarousel
        cardIds={appliedCardIds}
        onSelectCard={(cardId) => selectCard?.call(undefined, cardId)}
        selectedCardId={selectedCardId}
        onSwiper={onSwiper}
      />
    </Stack>
  );
}
