import Task01 from './Task01/Task01';
import Task02 from './Task02/Task02';
import Task03 from './Task03/Task03';
import Task04 from './Task04/Task04';
import Task05 from './Task05/Task05';
import Task06 from './Task06/Task06';
import Task07 from './Task07/Task07';
import Task08 from './Task08/Task08';
import Task09 from './Task09/Task09';
import Task10 from './Task10/Task10';
import Task11 from './Task11/Task11';
import Task12 from './Task12/Task12';
import Task13 from './Task13/Task13';
import Task14 from './Task14/Task14';

/**
 * Episode01: All task components in a single namespace.
 */

export default {
  Task01,
  Task02,
  Task03,
  Task04,
  Task05,
  Task06,
  Task07,
  Task08,
  Task09,
  Task10,
  Task11,
  Task12,
  Task13,
  Task14,
};
