import React from 'react';
import { Stack, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { AnimatePresence, motion } from 'framer-motion';
import { Trans, useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/pro-regular-svg-icons';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import Entry from '../Entry/Entry';
import Button from '../../Global/Button/Button';
import { EntryComponentProps } from '../../../lib/entries';
import getFontVariant, { TextComponent } from '../../Global/Text/getFontVariant';
import { toHTMLSafeId, isDev, isStaging } from '../../../lib/util';
import { useGameService } from '../../../state/GlobalStateProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import { closePanel, resetPlayerScore } from '../../../store/game-slice';
import { removeEntries, setEntriesUnread } from '../../../store/journal-slice';
import type { EpisodeData } from '../../../lib/game-data/episode-data';
import type { LeadData } from '../../../lib/game-data/lead-data';
import { selectAllAppliedLeadIds } from '../../../store/multiplayer-slice/applied-data';
import { getCurrentGameTheme } from '../../../theme';
import { useCompleteActivity } from '../../../lib/hooks/useCompleteLead';
import { emitAbandonUpdate } from '../../../store/multiplayer-slice';

export interface LeadEntryComponentProps {
  isCurrentLead?: boolean;
  lead: EpisodeData | LeadData;
  entries: EntryComponentProps[];
  startOpen?: boolean;
}

const CRIT_MULTIPLIER = 4;
const BONUS_MULTIPLIER = 2;

const LeadEntry: React.FC<LeadEntryComponentProps> = ({ isCurrentLead = false, lead, entries, startOpen = false }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const theme = getCurrentGameTheme();
  const { t } = useTranslation(['entries', 'game01Common', 'common']);
  const { state: gameState } = useGameService();
  const dispatch = useAppDispatch();
  const { send } = useGameService();
  const [isAbandonButtonShown, { setFalse: hideAbandonButton, toggle: toggleAbandonButton }] = useBoolean(false);
  const [isCompleteConfirmShown, { setFalse: hideCompleteConfirm, toggle: toggleCompleteConfirm }] = useBoolean(false);
  const [isSkipConfirmShown, { setFalse: hideSkipConfirm, toggle: toggleSkipConfirm }] = useBoolean(false);
  const [expanded, { toggle: toggleExpanded }] = useBoolean(startOpen);
  const userId = useAppSelector((state) => {
    return state.user.username;
  });

  const toggleAbandonButtonOnKeyUp = React.useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.code === 'Enter' || event.code === 'Space') {
        toggleAbandonButton();
      }
    },
    [toggleAbandonButton],
  );

  const toggleExpandedOnKeyUp = React.useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.code === 'Enter' || event.code === 'Space') {
        toggleExpanded();
      }
    },
    [toggleExpanded],
  );

  const headerTextHTMLId = toHTMLSafeId(lead.id, 'headerText');
  const briefTextHTMLId = toHTMLSafeId(lead.id, 'briefText');

  const isCompleted = useAppSelector((state) => selectAllAppliedLeadIds(state).includes(lead.id));

  // Get collection status and completion handler from `useCompleteActivity()` hook.
  const { collectionStatus, targetEvent, completeLabel, skipLabel, completeActivity } = useCompleteActivity();

  const allLeadsCompletable = urlParams.get('allLeadsCompletable') != null && (isDev || isStaging);
  const inSim = gameState.matches({ investigationPhase: { play: 'inSim' } });
  const isCompletable =
    !isAbandonButtonShown &&
    isCurrentLead &&
    !isCompleted &&
    lead.kind === 'lead' &&
    ((inSim && lead.flags.has('simulation') && !lead.flags.has('useCompleteSim')) || allLeadsCompletable) && // FIXME: Only support completing Simulation leads via the Journal.
    gameState.can(targetEvent);
  // Automatically hide completion confirmation if it is irrelevant.
  if (!isCompletable && isCompleteConfirmShown) {
    hideCompleteConfirm();
  }

  // Complete Simulation button for leads that have content following a simulation (so do not use Complete Lead)
  const isSkippable =
    !isAbandonButtonShown &&
    isCurrentLead &&
    !isCompleted &&
    lead.kind === 'lead' &&
    inSim &&
    lead.flags.has('simulation') &&
    lead.flags.has('useCompleteSim') &&
    gameState.can(targetEvent);
  if (!isSkippable && isSkipConfirmShown) {
    hideSkipConfirm();
  }
  // sim scoring handler
  const getSimLeadScore = React.useCallback(() => {
    const criticalCompletedEntry = collectionStatus?.critical.completed.length || 0;
    const criticalCollectedEntry = collectionStatus?.critical.collected.length || 0;
    const bonusCompletedEntry = collectionStatus?.bonus.completed.length || 0;

    let criticalCollectedScore = 0;
    let criticalCompletedScore = 0;
    let totalCriticalCollectedScore = 0;
    let totalCriticalCompletedScore = 0;
    let totalScore = 0;
    let bonusScore = 0;
    let totalBonusScore = 0;

    const baseScore = (lead as LeadData).baseScore;

    // calculating critical collected score
    for (let i = 0; i < criticalCollectedEntry; i++) {
      const completedLength = collectionStatus?.critical.collected[i].critical.collected.length ?? 0;

      criticalCollectedScore = completedLength * baseScore * CRIT_MULTIPLIER;
      totalCriticalCollectedScore += criticalCollectedScore;
    }
    // calculating critical completed score
    for (let i = 0; i < criticalCompletedEntry; i++) {
      const completedLength = collectionStatus?.critical.completed[i].critical.collected.length ?? 0;

      criticalCompletedScore = completedLength * baseScore * CRIT_MULTIPLIER;
      totalCriticalCompletedScore += criticalCompletedScore;
    }

    totalScore = totalCriticalCompletedScore + totalCriticalCollectedScore;

    // calculating bonus score
    for (let i = 0; i < bonusCompletedEntry; i++) {
      bonusScore = (collectionStatus?.bonus.completed[i].bonus.collected.length ?? 0) * baseScore * BONUS_MULTIPLIER;
      totalBonusScore += bonusScore;
    }

    return {
      amount: totalScore + totalBonusScore,
      id: lead.id,
      userId,
    };
  }, [collectionStatus, lead, userId]);

  const completeHandler = React.useCallback(() => {
    // call addSimScoreHandler
    const score = getSimLeadScore();
    completeActivity(score);

    // Close the sidebar
    dispatch(closePanel());
    // Mark any lead entries as seen
    dispatch(setEntriesUnread({ ids: entries.map((entry) => entry.entity.id), unread: false }));
  }, [completeActivity, entries, dispatch, getSimLeadScore]);

  const skipHandler = React.useCallback(() => {
    // do an window event called 'TASK:GOTO.NEXT'
    window.postMessage({ action: 'TASK:SKIP.SIMULATION' });
    dispatch(closePanel());
    dispatch(setEntriesUnread({ ids: entries.map((entry) => entry.entity.id), unread: false }));
  }, [dispatch, entries]);

  const isAbandonable = isCurrentLead && lead.kind === 'lead' && !isCompleted && gameState.can('ABANDON.LEAD');
  // Automatically hide abandon confirmation if it is irrelevant.
  if (!isAbandonable && isAbandonButtonShown) {
    hideAbandonButton();
  }

  const abandonHandler = React.useCallback(() => {
    dispatch(emitAbandonUpdate({ data: lead.id }));
    send({ type: 'ABANDON.LEAD' });
    // Close the sidebar
    dispatch(closePanel());
    // reset player score
    dispatch(resetPlayerScore(lead.id));
    // Remove all associated Journal entries.
    dispatch(removeEntries(entries.map(({ entity }) => entity)));
  }, [lead.id, entries, send, dispatch]);

  const entryElements = React.useMemo(() => {
    if (entries.length === 0) {
      return (
        <Stack.Item align="center" tokens={{ padding: '16px' }}>
          <Text
            styles={{ root: { color: theme.palette.white } }}
            variant={getFontVariant(TextComponent.enum.Heading, 5)}
            block
          >
            {t('journal.noEntriesFoundText')}
          </Text>
        </Stack.Item>
      );
    }
    return entries.map((entryProps) => <Entry key={entryProps.entryData.id} {...entryProps} />);
  }, [t, entries, theme]);

  // Set of what separations a hybrid lead has, in what order, 'sim', 'narrative'
  const hybridCounters = (lead as LeadData).hybridCounters;
  const hasHybridCounters = hybridCounters && hybridCounters.size > 0;

  return (
    <article aria-labelledby={headerTextHTMLId}>
      <Stack
        styles={{
          root: {
            'backgroundColor': theme.palette.themePrimary,
            'boxShadow': '0px 4px 4px rgba(0, 0, 0, 0.25)',
            'position': 'relative',
            '@media(min-height: 720px)': {
              position: 'sticky',
              inset: '108px 0 auto', // Top measurement should be height of LeadJournal panel sticky header.
            },
            'zIndex': 1,
          },
        }}
        tokens={{ childrenGap: '16px', padding: '16px 16px 16px 32px' }}
      >
        <Stack horizontal>
          <Stack.Item grow={1} verticalFill>
            <Text
              id={headerTextHTMLId}
              role="heading"
              aria-level={2}
              variant={getFontVariant(TextComponent.enum.Heading, 4)}
              styles={{ root: { color: theme.palette.white } }}
              block
            >
              {t(`${lead.id}.header`)}
            </Text>
          </Stack.Item>

          {isAbandonable && (
            <Stack.Item
              align="start"
              onClick={toggleAbandonButton}
              onKeyUp={toggleAbandonButtonOnKeyUp}
              styles={{ root: { cursor: 'pointer' } }}
              tokens={{ padding: '4px 0 0 0' }}
              verticalFill
              role="button"
              tabIndex={0}
              title={isAbandonButtonShown ? t('buttons.cancel') : t('journal.abandonLeadIconButtonLabel')}
              aria-label={isAbandonButtonShown ? t('buttons.cancel') : t('journal.abandonLeadIconButtonLabel')}
            >
              <FontAwesomeIcon icon={faTrash} fixedWidth style={{ color: theme.palette.white, fontSize: '24px' }} />
            </Stack.Item>
          )}
        </Stack>
        {collectionStatus?.critical.total.length &&
          !hasHybridCounters && ( // Normal critical evidence counter
            <Stack horizontal>
              <Stack.Item grow={1} verticalFill>
                <Text
                  variant={getFontVariant(TextComponent.enum.Heading, 5)}
                  styles={{ root: { color: theme.palette.white } }}
                  block
                >
                  {t('journal.progressText', {
                    count: collectionStatus.critical.completed.length,
                    total: collectionStatus.critical.total.length,
                  })}
                </Text>
              </Stack.Item>
            </Stack>
          )}
        {collectionStatus?.critical.total.length &&
          hasHybridCounters && // Broken out by hybrid lead region
          [...hybridCounters].map((region) => (
            <Stack horizontal key={region}>
              <Stack.Item grow={1} verticalFill>
                <Text
                  variant={getFontVariant(TextComponent.enum.Heading, 5)}
                  styles={{ root: { color: theme.palette.white } }}
                  block
                >
                  {t('journal.progressTextHybrid', {
                    count: collectionStatus.critical.completed.filter((e) => e.leadType === region).length,
                    total: collectionStatus.critical.total.filter((e) => e.leadType === region).length,
                    region: t(`journal.${region}`),
                  })}
                </Text>
              </Stack.Item>
            </Stack>
          ))}
        <AnimatePresence exitBeforeEnter initial={false}>
          {isAbandonButtonShown && (
            <motion.div
              key="abandonConfirmation"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Stack horizontalAlign="center" tokens={{ childrenGap: '16px' }}>
                <Stack.Item>
                  <Text
                    styles={{ root: { color: theme.palette.white } }}
                    variant={getFontVariant(TextComponent.enum.Body, 2)}
                    block
                  >
                    {t('journal.abandonLeadConfirmationText')}
                  </Text>
                </Stack.Item>
                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '16px' }}>
                  <Stack.Item>
                    <Button isPrimary label={t('journal.abandonLeadButtonLabel')} onClick={abandonHandler} />
                  </Stack.Item>
                  <Stack.Item>
                    <Button label={t('buttons.cancel')} onClick={hideAbandonButton} />
                  </Stack.Item>
                </Stack>
              </Stack>
            </motion.div>
          )}
          {!isAbandonButtonShown && (isCompleteConfirmShown || isSkipConfirmShown) && (
            <motion.div
              key="completeConfirmation"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Stack horizontalAlign="center" tokens={{ childrenGap: '16px' }}>
                <Stack.Item>
                  {isCompletable && (
                    <Text
                      styles={{ root: { color: theme.palette.white } }}
                      variant={getFontVariant(TextComponent.enum.Body, 2)}
                      block
                    >
                      {collectionStatus?.completable
                        ? t('journal.completeLeadConfirmationText')
                        : t('journal.completeLeadConfirmationTextMissing')}
                    </Text>
                  )}
                  {isSkippable && (
                    <Text
                      styles={{ root: { color: theme.palette.white } }}
                      variant={getFontVariant(TextComponent.enum.Body, 2)}
                      block
                    >
                      {collectionStatus?.completable
                        ? t('journal.skipSimulationConfirmationText')
                        : t('journal.skipSimulationConfirmationTextMissing')}
                    </Text>
                  )}
                </Stack.Item>
                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '16px' }}>
                  <Stack.Item>
                    <Button
                      isPrimary
                      label={isCompletable ? completeLabel : skipLabel}
                      onClick={isCompletable ? completeHandler : skipHandler}
                    />
                  </Stack.Item>
                  <Stack.Item>
                    <Button
                      label={t('buttons.cancel')}
                      onClick={isCompletable ? hideCompleteConfirm : hideSkipConfirm}
                    />
                  </Stack.Item>
                </Stack>
              </Stack>
            </motion.div>
          )}
          {!isAbandonButtonShown && !isCompleteConfirmShown && !isSkipConfirmShown && (
            <motion.div key="brief" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <Stack horizontal>
                <Stack.Item id={briefTextHTMLId} styles={{ root: { overflow: 'hidden' } }} grow={1} verticalFill>
                  <Text
                    variant={getFontVariant(TextComponent.enum.Body, 3)}
                    styles={{
                      root: {
                        color: theme.palette.white,
                        fontWeight: 600,
                      },
                    }}
                    block
                  >
                    {t('commonEntry.leadPreview.brief')}
                  </Text>
                  <Text
                    variant={getFontVariant(TextComponent.enum.Body, 3)}
                    styles={{
                      root: [
                        {
                          color: theme.palette.white,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        },
                        !expanded && {
                          maxHeight: '24px',
                          whiteSpace: 'nowrap',
                        },
                      ],
                    }}
                    block
                  >
                    <Trans t={t} i18nKey={`${lead.id}.brief`} />
                  </Text>
                </Stack.Item>
                <Stack.Item
                  align="start"
                  onClick={toggleExpanded}
                  onKeyUp={toggleExpandedOnKeyUp}
                  styles={{ root: { cursor: 'pointer' } }}
                  tokens={{ padding: '4px 0 0 0' }}
                  verticalFill
                  role="button"
                  tabIndex={0}
                  title={expanded ? t('buttons.collapse') : t('buttons.expand')}
                  aria-label={expanded ? t('buttons.collapse') : t('buttons.expand')}
                  aria-expanded={expanded ? 'true' : 'false'}
                  aria-controls={briefTextHTMLId}
                >
                  <FontAwesomeIcon
                    icon={expanded ? faMinus : faPlus}
                    fixedWidth
                    style={{ color: theme.palette.white, fontSize: '24px' }}
                  />
                </Stack.Item>
              </Stack>
              {(isCompletable || isSkippable) && (
                <Stack horizontalAlign="center" tokens={{ padding: '16px 0 0 0' }}>
                  <Stack.Item>
                    <Button
                      isPrimary
                      label={
                        isCompletable ? t('journal.completeLeadButtonLabel') : t('journal.skipSimulationButtonLabel')
                      }
                      onClick={isCompletable ? toggleCompleteConfirm : toggleSkipConfirm}
                    />
                  </Stack.Item>
                </Stack>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </Stack>
      <Stack tokens={{ padding: '8px', childrenGap: '8px' }}>{entryElements}</Stack>
    </article>
  );
};

export default LeadEntry;
