import React from 'react';
import { IButtonStyles, IStackStyles, ITextStyles, DefaultButton, Stack, Text } from '@fluentui/react';
import { Handle, Position } from 'react-flow-renderer';

import { TFunction, useTranslation } from 'react-i18next';

import MapCardProgress from './MapCardProgress';

import type { EvidenceDataCard } from '../../../lib/game-data/evidence-data';
import type { AppliedCardEntity } from '../../../lib/multiplayer/schemas';
import { selectCurrentEpisode, selectCurrentGame } from '../../../store/multiplayer-slice';
import { useAppSelector } from '../../../store';

export interface IMapCardProps {
  data: MapCardNodeData;
}

export interface MapCardNodeData {
  evidence: EvidenceDataCard;
  entity?: AppliedCardEntity;
  progress?: [count: number, total: number];
  onFocus?: () => void;
  t: TFunction;
}

const cardSizeBase = 18;

const sharedButtonStackStyles = {
  minWidth: `${cardSizeBase * 10}px`,
  maxWidth: `${cardSizeBase * 10}px`,
  minHeight: `${cardSizeBase * 11}px`,
  maxHeight: `${cardSizeBase * 12}px`,
};

const cardButtonStyles: IButtonStyles = {
  root: {
    ...sharedButtonStackStyles,
    backgroundColor: '#eee9de',
    borderRadius: 0,
    border: 'none',
  },
  rootFocused: {
    'backgroundColor': 'rgb(255, 255, 255)',
    'color': 'rgb(32, 31, 30)',
    ':focus::after': {
      outlineWidth: '6px',
      inset: '-6px',
    },
  },
};

const cardStackStyles: IStackStyles = {
  root: {
    ...sharedButtonStackStyles,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
};

function cardTitleStyles(hasCriticalFacts: number): ITextStyles {
  return {
    root: {
      display: 'block',
      color: '#000000',
      textAlign: hasCriticalFacts ? 'left' : 'center',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '22px',
    },
  };
}

/**
 * Evidence Map React-Flow card component.
 */
const MapCard: React.FC<IMapCardProps> = ({ data: { evidence, progress = [0, 0], t, onFocus } }) => {
  const { i18n } = useTranslation('entries');
  const { image } = evidence;
  const currentGameId = useAppSelector(selectCurrentGame);
  const currentEpisodeId = useAppSelector(selectCurrentEpisode);
  const CARD_IMG_PATH = `./images/${currentGameId?.shortId}/cards/${currentEpisodeId?.shortId}/front/`;
  const imgSrc = image ? `${CARD_IMG_PATH}${image}.jpg` : undefined;
  const hasShortHeading = i18n.exists(`entries:${evidence.id}.shortHeading`);
  const heading = t(`${evidence.id}.${hasShortHeading ? 'shortHeading' : 'heading'}`);
  const [wasClicked, setWasClicked] = React.useState(false);
  return (
    <>
      <DefaultButton
        styles={cardButtonStyles}
        onFocus={!wasClicked ? onFocus : () => {}}
        onMouseDown={() => {
          setWasClicked(true);
          setTimeout(() => {
            setWasClicked(false);
          }, 0.1);
        }}
        onMouseUp={() => {
          setWasClicked(false);
        }}
      >
        <Stack styles={cardStackStyles} tokens={{ padding: '12px', childrenGap: '8px' }}>
          <Stack.Item>
            {imgSrc && <img src={imgSrc} alt={heading} style={{ width: 152, border: '2px solid #828282' }} />}
          </Stack.Item>
          <Stack.Item disableShrink>
            <Text styles={cardTitleStyles(progress[1])} block>
              {heading}
            </Text>
          </Stack.Item>
          {progress[1] && <MapCardProgress count={progress[0]} total={progress[1]} />}
        </Stack>
      </DefaultButton>
      <Handle type="source" id="south" position={Position.Bottom} isConnectable={false} />
      <Handle type="source" id="north" position={Position.Top} isConnectable={false} />
      <Handle type="source" id="east" position={Position.Right} isConnectable={false} />
      <Handle type="source" id="west" position={Position.Left} isConnectable={false} />
    </>
  );
};

export default MapCard;
