/**
 * i18next NS: `Episode02.Task02`
 */
export default {
  common: {
    errorTitle: "Try Again",
    next: "Next",
    submit: "Submit",
    start: "Start",
    skipAhead: "Skip Ahead",
    ok: "OK",
    done: "Done",
  },
  A020: {
    error: {
      1: {
        title: "Try again.",
        body: "No, that's not the right place.",
      },
      2: {
        title: "Try again.",
        body: "That's not right either.",
      },
      3: {
        title: "Go Here!",
        body: "I need you to go to GitHub.",
      },
    },
  },
  A030: {
    body: "Here's the GitHub repository for the eCommerce app. Who is Wenny Gultom?",
    labels: {
      member: "Member",
      CodeOwner: "Code Owner",
      OutsideCollaborator: "Outside Collaborator",
    },
    errors: {
      member: {
        title: "Wenny is not a member.",
        body: "She has a higher level of access than that.",
      },
      OutsideCollaborator: {
        title: "Wenny is not an outside collaborator.",
        body: "Wenny works at Best for You Organics. She isn't an outside collaborator.",
      },
    },
    // check if this is needed here
    evidence: {
      one: {
        title: "Wenny Gultom", // verify if this is the correct text to be displayed in the journal
        subtitle: "Wenny Gultom owns the eCommerce app code in the repository",
      },
    },
  },
  A040: {
    title: "How many files have changed since the last security validation?",
    body: "Scroll to review the full list of files.",
    labels: {
      one: "1 (One)",
      three: "3 (Three)",
      five: "5 (Five)",
    },
    errors: {
      one: {
        title: "Remember to scroll.",
        body: "It looks like you missed a few things. Make sure you review the full list. ",
      },
      three: {
        title: "Remember the Brief.",
        body: "Our last security validation was 30 days ago. Look for files that have changed since then.",
      },
    },
  },
  A050: {
    title: "Here is the first file that changed: About.aspx.",
    body: "Do you see any malicious code?",
    labels: {
      Yes: "Yes",
      No: "No",
      IDontKnow: "I Don't Know",
    },
    errors: {
      Yes: {
        title: "Are you sure you see something malicious?",
        body: "There doesn't seem to be anything out of the ordinary in this source code.",
      },
      IDontKnow: {
        title: "Quickly scan through the source code.",
        body: "Is it straightforward and easy to understand, or does something look strange?",
      },
    },
  },
  A060: {
    body: "Four other files have changed since the last security validation. Which source code do you want to inspect next?",
    labels: {
      Defaultaspx: "Default.aspx",
      Defaultaspxcs: "Default.aspx.cs",
      ProjectDcsproj: "ProjectD.csproj",
      Webconfig: "Web.config",
    },
  },
  A080: {
    title: "Here is Default.aspx",
    body: "Inspect the code. How does it look?",
    labels: {
      Itlooksstrange: "It looks strange",
      Itlooksokay: "It looks okay",
    },
    errors: {
      Itlooksstrange: {
        title: "Take another look.",
        body: "There doesn't appear to be any malicious code here.",
      },
    },
  },
  A081: {
    title: "Here is Default.aspx.cs",
    body: "Inspect the code. How does it look?",
    labels: {
      Itlooksstrange: "It looks strange",
      Itlooksokay: "It looks okay",
    },
    errors: {
      Itlooksokay: {
        title: "Are you sure?",
        body: "Some of this code doesn't look right.",
      },
    },
  },
  A082: {
    title: "Here is ProjectD.csproj",
    body: "Inspect the code. How does it look?",
    labels: {
      Itlooksstrange: "It looks strange",
      Itlooksokay: "It looks okay",
    },
    errors: {
      Itlooksstrange: {
        title: "Take another look.",
        body: "There doesn't appear to be any malicious code here.",
      },
    },
  },
  A083: {
    title: "Here is Web.config",
    body: "Inspect the code. How does it look?",
    labels: {
      Itlooksstrange: "It looks strange",
      Itlooksokay: "It looks okay",
    },
    errors: {
      Itlooksstrange: {
        title: "Take another look.",
        body: "There doesn't appear to be any malicious code here.",
      },
    },
  },
  A090: {
    title: "Nice work! You found the malicious code.",
    body: "Select the key evidence to add it to your Journal, then select DONE.",
    evidenceAdded: {
      title: "All set!",
      body: "Evidence added to journal.",
      nextLabel: "OK",
    },
    feedback: {
      title: "You didn't get all the critical information.",
      body: "Add this evidence to your journal.",
    },
    evidence: {
      one: {
        title: "Code Owner: Wenny Gultom",
        subtitle: "Wenny Gultom owns the eCommerce app code in the repository",
      },
      two: {
        title: "Recently updated files: About.aspx, Default.aspx, Default.aspx.cs, ProjectD.csproj, Web.config",
        subtitle: "5 files were recently updated: About.aspx, Default.aspx, Default.aspx.cs, ProjectD.csproj, Web.config",
      },
      three: {
        title: "File with malicious code: Default.aspx.cs",
        subtitle: "Default.aspx.cs contains malicious shellcode",
      },
      four: {
        title: "Last updated by: Wenny Gultom",
        subtitle: "Wenny Gultom was the last person to update Default.aspx.cs",
      },
    },
  },
  A100: {
    title: "Lead complete!",
    body: "You searched for the leaked file. Now it's time to put the information you found on the Evidence Map.",
    nextLabel: "Back to evidence map",
  },
};
