import { IStackStyles, Stack } from '@fluentui/react/lib/Stack';
import * as React from 'react';
import { motion, useAnimation, useMotionValue } from 'framer-motion';
import { DirectionalHint, FocusTrapZone, mergeStyles } from '@fluentui/react';
import { ITeachingBubbleStyles, TeachingBubble } from '@fluentui/react/lib/TeachingBubble';
import { TFunction, useTranslation } from 'react-i18next';
import { Label, P } from '../../../Global/Text';
import hitImage from '../../../../static/images/Game01/Episode01/characters/andrea/hintImage.png';
import { LockItem } from './VerticalRollerUnlock';
import lockFrame from '../../../../static/images/Game01/interactions/roller_lock/slot_frame.png';
import { getCurrentGameTheme } from '../../../../theme';

const theme = getCurrentGameTheme('Game01');

const LOCK_HEIGHT = 500;
const OPTION_HEIGHT = LOCK_HEIGHT / 3;

interface LockProps {
  t: TFunction<(string | 'common')[]>;
  optionKey: string;
  options: LockItem[];
  label: string;
  labelColor?: string;
  hint: string;
  walkthroughCallback?: () => void;
  onChange: () => void;
  useDark?: boolean;
  showWalkthrough: boolean;
  walkthroughStep: number;
  order: number;
}

const locksOuterWrapper = mergeStyles({
  position: 'relative',
  height: LOCK_HEIGHT,
  background: 'linear-gradient(#aaa, #fff, #fff, #fff, #aaa)',
  backgroundSize: 'cover',
  width: '97%',
  overflow: 'hidden',
  boxShadow: 'inset 5px 5px 12px rgba(0,0,0,.5)',
  margin: 10,
  borderRadius: 8,

  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
});

const frameStyles: IStackStyles = {
  root: {
    height: '100%',
    background: `url(${lockFrame}) transparent`,
    backgroundSize: 'cover',
    width: '100%',
    borderRadius: 8,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
  },
};

const hintStyles: Partial<ITeachingBubbleStyles> = {
  imageContent: [{ backgroundColor: theme.palette.themeDark }],
  content: [
    {
      background: 'white',
    },
  ],
  bodyContent: [
    {
      padding: 10,
    },
  ],
  subText: [
    {
      color: 'black',
    },
  ],
  subComponentStyles: {
    callout: {
      beak: [{ display: 'none' }],
    },
  },
};

const optionsItemStyles = {
  root: [
    {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 272,
      borderTop: '1px solid #999',
      height: OPTION_HEIGHT,
      padding: '0 24px',
      margin: '0 20px',
      backgroundColor: 'transparent',
      label: {
        margin: 0,
        fontSize: 16,
      },
    },
  ],
};

const VerticalLock = React.forwardRef(
  (
    {
      t,
      optionKey,
      options,
      hint,
      label,
      onChange,
      walkthroughCallback,
      walkthroughStep,
      order,
      showWalkthrough,
      useDark = false,
      labelColor,
    }: LockProps,
    ref,
  ) => {
    const controls = useAnimation();
    const yOffset = useMotionValue(0);
    const { t: tActivity } = useTranslation('game01Common', { keyPrefix: 'activities' });

    // Insert a generic "Select an Option" entry to start/reset on
    const optionsWithDefault = React.useMemo(() => {
      return [
        ...options.slice(0, 1),
        { key: 'select', text: tActivity('unlock.optionDefault'), disabled: false },
        ...options.slice(1),
      ];
    }, [options, tActivity]);
    // Create a new array of entries with the first three elements repeated at the end
    const loopedEntries = React.useRef([...optionsWithDefault, ...optionsWithDefault.slice(0, 3)]);

    const buttonRef = React.useRef<HTMLButtonElement | null>(null);
    const isUnlocked = React.useRef(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isDisabled, setIsDisabled] = React.useState(false);
    const [showHint, setShowHint] = React.useState(false);
    const [lastVisibleIndex, setLastVisibleIndex] = React.useState(2);
    const [isCorrect, setIsCorrect] = React.useState(!!loopedEntries.current[lastVisibleIndex - 1].isCorrect);

    React.useEffect(() => {
      if (loopedEntries.current.length === 4) {
        // Pad the end of the loopedEntries array with another copy of the first element, when there's only 2 options
        loopedEntries.current.push(options[0]);
      }
    }, [options]);

    const isNotCurrentWalkthroughFocus = showWalkthrough && !showHint && order !== walkthroughStep;

    const answerStyles = mergeStyles({
      background: isNotCurrentWalkthroughFocus ? 'linear-gradient(rgba(0,0,0,.5), transparent)' : 'transparent',
    });

    React.useImperativeHandle(
      ref,
      () => ({
        reset: () => {
          setLastVisibleIndex(2);
          yOffset.set(0);
          controls.start({ y: yOffset.get(), transition: { duration: 0.2 } });
        },
        disable: () => {
          setIsDisabled(true);
          setShowHint(false);
        },
        displayHint: () => {
          setIsDisabled(false);

          if (buttonRef?.current) {
            buttonRef.current.focus();
          }
          setShowHint(true);
        },
        getIsCorrect: () => {
          return !!loopedEntries.current[lastVisibleIndex - 1].isCorrect;
        },
      }),
      [lastVisibleIndex, controls, yOffset],
    );

    const handleRotate = async () => {
      if (isDisabled) return;
      // Disable the button so we can't interrupt the animation
      setIsDisabled(true);
      setIsCorrect(!!loopedEntries.current[lastVisibleIndex].isCorrect);
      setLastVisibleIndex((val) => val + 1);
      yOffset.set(yOffset.get() - OPTION_HEIGHT);
      await controls.start({ y: yOffset.get(), transition: { duration: 0.2 } });
      onChange();
    };

    const resetLoop = () => {
      if (isCorrect && showHint && walkthroughCallback) {
        isUnlocked.current = true;
        // before we change local state, check that the option we're rolling into is correct and fire the optional walkthrough callback
        walkthroughCallback();
      }
      // Re-enable the button
      if (lastVisibleIndex === loopedEntries.current.length - 1) {
        setLastVisibleIndex(2);
        yOffset.set(0);
        controls.start({ y: 0, transition: { duration: 0 } });
      }
      setIsDisabled(false);
    };

    const currentOptionLabel =
      lastVisibleIndex === loopedEntries.current.length - 1
        ? loopedEntries.current[2].text
        : loopedEntries.current[lastVisibleIndex - 1].text;

    const buttonAriaLabel = lastVisibleIndex === 2 ? // "Select an Option"
      tActivity('unlock.lockLabel.unselected', { lockLabel: label }) :
      tActivity('unlock.lockLabel.selected', { lockLabel: label, selectedOption: t(currentOptionLabel) });

    return (
      <>
        <FocusTrapZone disabled={!showHint}>
          <Stack wrap verticalAlign="start" horizontalAlign="center">
            <P level={3} center useDark={useDark} color={labelColor}>
              {label}
            </P>
          </Stack>
          <button
            type="button"
            className={locksOuterWrapper}
            id={`lock-${optionKey}`}
            onClick={handleRotate}
            aria-label={buttonAriaLabel}
          >
            <Stack styles={frameStyles} />
            <motion.div animate={controls} onAnimationComplete={resetLoop}>
              <Stack className={answerStyles}>
                {loopedEntries.current.map((option, index) => (
                  <Stack.Item key={index} styles={optionsItemStyles} align="center">
                    <Label level={1} block center>
                      {t(option.text)}
                    </Label>
                  </Stack.Item>
                ))}
              </Stack>
            </motion.div>
            {showHint && (
              <TeachingBubble
                styles={hintStyles}
                illustrationImage={{ src: hitImage, alt: '' }}
                calloutProps={{ directionalHint: DirectionalHint.topCenter }}
                target={`#lock-${optionKey}`}
                isWide
                closeButtonAriaLabel="Close"
                focusTrapZoneProps={{ disabled: true }}
              >
                {hint}
              </TeachingBubble>
            )}
          </button>
        </FocusTrapZone>
      </>
    );
  },
);

export default VerticalLock;
