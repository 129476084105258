import { ReactComponent as Deactivated } from './deactivated.svg';
import { ReactComponent as Activated } from './activated.svg';

/**
 * Episode01: All task components in a single namespace.
 */

export default {
  completed: Activated,
  deactivated: Deactivated,
  activated: Activated,
};
