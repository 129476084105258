import React from 'react';
import { useTranslation } from 'react-i18next';
import _difference from 'lodash/difference';
import { useAppDispatch, useAppSelector } from '../../store';
import { addEntriesWithUnlocks, selectAllJournalEntities } from '../../store/journal-slice';
import { selectCurrentLead } from '../../store/game-slice';
import { devLogWarn } from '../util';
import type { JournalEntryUpdate } from '../journal/types';
import { actions as multiplayerAction } from '../../store/multiplayer-slice';
import { LeadData } from '../game-data/lead-data';

export function useCompleteSimActivity() {
  const { t } = useTranslation(['common', 'game01Common']);
  const dispatch = useAppDispatch();
  const currentLead = useAppSelector(selectCurrentLead) as LeadData;
  const journalEntities = useAppSelector(({ journal }) => selectAllJournalEntities(journal.entries));
  const userId = useAppSelector(({ user }) => user.username);

  // Get collection status info and missing critical Journal entries.
  const [collectionStatus, missingEntries] = React.useMemo(() => {
    const status = currentLead?.getCollectionStatus(journalEntities);
    const missing = status?.critical.total.reduce<JournalEntryUpdate[]>((accumulator, evidenceStatus) => {
      if (!evidenceStatus.completed && evidenceStatus.leadType === 'sim') {
        accumulator.push({
          id: evidenceStatus.id,
          states: _difference(evidenceStatus.critical.total, evidenceStatus.critical.collected)
            .map(({ shortId }) => shortId)
            .sort(),
        });
      }
      return accumulator;
    }, []);
    return [status, missing] as const;
  }, [currentLead, journalEntities]);

  const CRIT_MULTIPLIER = 4;
  const BONUS_MULTIPLIER = 2;

  const getSimLeadScore = React.useCallback(() => {
    const criticalCompletedEntry = collectionStatus?.critical.completed.length || 0;
    const criticalCollectedEntry = collectionStatus?.critical.collected.length || 0;
    const bonusCompletedEntry = collectionStatus?.bonus.completed.length || 0;

    let criticalCollectedScore = 0;
    let criticalCompletedScore = 0;
    let totalCriticalCollectedScore = 0;
    let totalCriticalCompletedScore = 0;
    let totalScore = 0;
    let bonusScore = 0;
    let totalBonusScore = 0;

    const baseScore = currentLead?.baseScore || 0;

    // calculating critical collected score
    for (let i = 0; i < criticalCollectedEntry; i++) {
      const completedLength = collectionStatus?.critical.collected[i].critical.collected.length ?? 0;

      criticalCollectedScore = completedLength * baseScore * CRIT_MULTIPLIER;
      totalCriticalCollectedScore += criticalCollectedScore;
    }
    // calculating critical completed score
    for (let i = 0; i < criticalCompletedEntry; i++) {
      const completedLength = collectionStatus?.critical.completed[i].critical.collected.length ?? 0;

      criticalCompletedScore = completedLength * baseScore * CRIT_MULTIPLIER;
      totalCriticalCompletedScore += criticalCompletedScore;
    }

    totalScore = totalCriticalCompletedScore + totalCriticalCollectedScore;

    // calculating bonus score
    for (let i = 0; i < bonusCompletedEntry; i++) {
      bonusScore = (collectionStatus?.bonus.completed[i].bonus.collected.length ?? 0) * baseScore * BONUS_MULTIPLIER;
      totalBonusScore += bonusScore;
    }

    return {
      amount: totalScore + totalBonusScore,
      id: currentLead?.id,
      userId,
    };
  }, [
    collectionStatus?.bonus.completed,
    collectionStatus?.critical.collected,
    collectionStatus?.critical.completed,
    currentLead?.baseScore,
    currentLead?.id,
    userId,
  ]);

  // Check if activity is completable
  const isCompletable = !!collectionStatus?.completable;

  // Handler to actually complete the lead.
  // NOTE: This handler does _not_ check `isCompletable`
  const completeSimActivity = React.useCallback(() => {
    // Add missing critical entries.
    if (missingEntries != null && missingEntries.length > 0) {
      devLogWarn('Adding missing critical entries:', missingEntries);
      dispatch(addEntriesWithUnlocks(missingEntries));
    }

    const scoreUpdate = getSimLeadScore();

    if (scoreUpdate) {
      // this only happens for sim leads
      dispatch(multiplayerAction.emitLeadScoreUpdate(scoreUpdate));
    }
  }, [missingEntries, getSimLeadScore, dispatch]);

  return {
    isCompletable,
    collectionStatus,
    missingEntries,
    skipLabel: t('journal.gameServiceEvents.SKIP.SIMULATION'),
    completeSimActivity,
  } as const;
}
