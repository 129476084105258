import * as React from 'react';
import Screen from '../../../../../components/Global/Screen/Screen';
import background from '../images/14B.070.png';
import { ComponentProps } from '../../../../task-types';
import Interstitial from '../../../../../components/Global/Interactions/Interstitial/Interstitial';

export default function B070({ t, send }: ComponentProps): JSX.Element {
  return (
    <Screen>
      <Interstitial
        title={t('B070.title')}
        body={t('B070.body')}
        onClick={() => send('NEXT')}
        nextLabel={t('common.done')}
        backgroundImage={background}
        useDark
      />
    </Screen>
  );
}
