import { IStackStyles, Stack } from '@fluentui/react/lib/Stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { mergeStyles } from '@fluentui/react';
import { Label, Heading } from '../../../Global/Text';
import lockImage from '../../../../static/images/Game01/interactions/dial_lock/lock_front.png';
import lockDialImage from '../../../../static/images/Game01/interactions/dial_lock/lock_dial.png';
import { LockItem } from './Unlock';
import Hint from '../../Hint/Hint';
import { gradients } from '../../../../theme/Game01/colors';

interface LockProps {
  optionKey: string;
  options: LockItem[];
  label: string;
  hint: string;
  answerKey: number | string;
  walkthroughCallback?: () => void;
  onChange: (optionKey: number | string) => void;
  useDark?: boolean;
}

const labelWrapper: IStackStyles = {
  root: {
    position: 'relative',
  },
};

const outerWrapper: IStackStyles = {
  root: {
    position: 'relative',
    margin: '10px 20px',
    backgroundImage: gradients.primaryLightReversed,
    borderRadius: '50%',
    width: 400,
  },
};

const lockWrapper: IStackStyles = {
  root: {
    width: 400,
    height: 400,
    borderRadius: '50%',
    overflow: 'hidden',
    position: 'relative',
    opacity: 0.75,
  },
};

const getOptionStyles = (rotation: number): IStackStyles => {
  return {
    root: [{ position: 'absolute', top: 0, width: '100%', height: '100%', transform: `rotate(${rotation}deg)` }],
    inner: [{ padding: 10 }],
  };
};

const optionsItemStyles = {
  root: {
    width: 90,
    textAlign: 'center',
    paddingTop: 48,
  },
};

const lockButtonStyles = mergeStyles({
  width: 400,
  height: 400,
  position: 'absolute',
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
});

const Lock = React.forwardRef(
  ({ optionKey, options, hint, label, answerKey, onChange, walkthroughCallback, useDark = false }: LockProps, ref) => {
    const { t } = useTranslation('game01Common', { keyPrefix: 'activities' });
    const buttonRef = React.useRef<HTMLButtonElement | null>(null);
    const isUnlocked = React.useRef(false);
    const [isDisabled, setIsDisabled] = React.useState(false);
    const [position, setPosition] = React.useState(-1);
    const [optionIndex, setOptionIndex] = React.useState({
      current: -1,
      next: 0,
    });
    const [showHint, setShowHint] = React.useState(false);

    const correctOptionIndex: number = React.useMemo(
      () => options.findIndex((option) => option.key === answerKey),
      [answerKey, options],
    );

    const isCurrentOptionCorrect = correctOptionIndex === optionIndex.current;

    const answerStyles = mergeStyles({
      background:
        showHint && !isCurrentOptionCorrect && position !== -1
          ? 'radial-gradient(circle at center, #fff, #999 58%, rgba(255,255,255,.25) 55%, rgba(255,255,255,.6) 100%)'
          : 'transparent',
    });

    React.useImperativeHandle(
      ref,
      () => ({
        reset: () => {
          setPosition(-1);
          setOptionIndex({
            current: -1,
            next: 0,
          });
        },
        disable: () => {
          setIsDisabled(true);
          setShowHint(false);

          // Keep unlocked locks in the correct position
          if (!isUnlocked.current) {
            setPosition(-1);
            setOptionIndex({
              current: -1,
              next: 0,
            });
          }
        },
        displayHint: () => {
          setIsDisabled(false);

          if (buttonRef?.current) {
            buttonRef.current.focus();
          }
          setShowHint(true);
        },
      }),
      [],
    );

    const orientation = position % 2;

    const handleTap = () => {
      setPosition((val) => val + 1);

      const newNextOptionIndex = optionIndex.next + 1;
      onChange(options[optionIndex.next].key);

      setOptionIndex((val) => {
        return {
          current: val.next,
          next: newNextOptionIndex < options.length ? newNextOptionIndex : 0,
        };
      });
    };

    const spring = {
      type: 'spring',
      stiffness: 700,
      damping: 30,
    };

    const divisionSize = 180;

    // NOTE: If rotation is 0, the animation styles will clear. Set to 1 as a quick fix in this case, as it will only happen on the first click
    const rotate = position * -divisionSize || 1;

    const buttonLabel = () => {
      if (position === -1) {
        return t('unlock.lockLabel.unselected', { lockLabel: label });
      }

      return t('unlock.lockLabel.selected', { lockLabel: label, selectedOption: options[optionIndex.current].text });
    };

    return (
      <>
        <Stack styles={labelWrapper} wrap verticalAlign="start" horizontalAlign="center">
          <Stack.Item id={`lock-${optionKey}`}>
            <Heading level={2} center useDark={useDark} block noMargin>
              {label}
            </Heading>
          </Stack.Item>
        </Stack>
        <Stack styles={outerWrapper} className="lock">
          <motion.div
            transition={spring}
            animate={{ rotate }}
            onAnimationComplete={() => {
              if (isCurrentOptionCorrect && showHint && walkthroughCallback) {
                isUnlocked.current = true;
                // before we change local state, check that the option we're rolling into is correct and fire the optional walkthrough callback
                walkthroughCallback();
              }
            }}
          >
            <Stack styles={lockWrapper} className={answerStyles}>
              <Stack wrap verticalAlign="start" horizontalAlign="center" styles={getOptionStyles(0)}>
                <Stack.Item styles={optionsItemStyles}>
                  {position === -1 ? (
                    <Label level={2} center>
                      {t('unlock.optionDefault')}
                    </Label>
                  ) : (
                    <Label level={2}>
                      {orientation ? options[optionIndex.next].text : options[optionIndex.current].text}
                    </Label>
                  )}
                </Stack.Item>
              </Stack>
              <Stack wrap verticalAlign="start" horizontalAlign="center" styles={getOptionStyles(180)}>
                <Stack.Item styles={optionsItemStyles}>
                  {position === -1 ? (
                    <Label level={2}>{t('unlock.optionDefault')}</Label>
                  ) : (
                    <Label level={2}>
                      {!orientation ? options[optionIndex.next].text : options[optionIndex.current].text}
                    </Label>
                  )}
                </Stack.Item>
              </Stack>
            </Stack>
          </motion.div>
          <button
            type="button"
            ref={buttonRef}
            className={lockButtonStyles}
            disabled={isDisabled}
            id={`lock-${optionKey}`}
            onClick={handleTap}
            aria-label={buttonLabel()}
          >
            <img
              src={lockImage}
              style={{ position: 'absolute', top: 0, left: 0, width: 400, height: 400 }}
              alt="lock"
              draggable={false}
            />
            <motion.div transition={spring} animate={{ rotate }}>
              <img src={lockDialImage} style={{ width: '100%', height: '100%' }} alt="lock" draggable={false} />
            </motion.div>
          </button>
          {showHint && <Hint target={`#lock-${optionKey}`}>{hint}</Hint>}
        </Stack>
      </>
    );
  },
);

export default Lock;
