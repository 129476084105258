import * as React from 'react';
import { IStackStyles, Stack, mergeStyles, DirectionalHint } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import Label from '../../Global/Text/Label';
import Screen from '../../Global/Screen/Screen';
import Icon, { IconNames, ICON_NAMES } from './Icon/Icon';
import Hint from '../Hint/Hint';
import { P } from '../../Global/Text';
import { getCurrentGameTheme } from '../../../theme';

const theme = getCurrentGameTheme('Game01');

export type DesktopIconProps = {
  icon: IconNames;
  onClick: () => void;
  isCorrect?: boolean;
  showCorrect?: boolean;
  currentButton?: boolean;
};

interface DesktopProps {
  icons?: DesktopIconProps[];
  desktopColor?: string;
  desktopBackground?: string;
  showCorrect?: boolean;
  onDismiss?: () => void;
  title?: string;
  hint?: string;
  showHint?: boolean;
}

const iconLabelWrapper: IStackStyles = {
  root: [
    {
      width: 120,
      marginTop: 10,
      backgroundColor: 'rgba(256,256,256,0.6)',
      padding: 5,
      borderRadius: 5,
    },
  ],
};

const buttonStyles = {
  'display': 'flex',
  'flexDirection': 'column',
  'alignItems': 'center',
  'justifyContent': 'center',
  'backgroundColor': 'transparent',
  'width': 140,
  'height': 170,
  'padding': 30,
  'marginBottom': 10,
  'borderRadius': 10,
  'border': 'none',
  'transitionDuration': '.09s',
  'transitionProperty': 'all',
  'transitionTimingFunction': 'linear',
  '&:hover': {
    border: `thin solid ${theme.palette.themeSecondary}`,
    backgroundColor: `${theme.palette.themeSecondary}44`,
  },
  '&:active': {
    border: `thin solid ${theme.palette.themeSecondary}`,
    backgroundColor: `${theme.palette.themeSecondary}aa`,
  },
  // Accessibility: Windows high contrast themes need some help with the desktop background
  '@media screen and (-ms-high-contrast: active), (forced-colors: active)': {
    '&:focus-visible': {
      outline: 'medium solid ButtonText',
      backgroundColor: 'ButtonFace',
    },
  },
};

function DesktopIcon({ icon, onClick, isCorrect, showCorrect, currentButton }: DesktopIconProps) {
  const { t } = useTranslation('game01Common', { keyPrefix: 'activities' });
  const targetButton = React.useRef<HTMLButtonElement | null>(null);

  const curtainStyles = mergeStyles({
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,.25)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
  });

  const hintStyles = mergeStyles(buttonStyles, [
    {
      position: 'relative',
      zIndex: showCorrect && isCorrect ? 2 : 0,
    },
  ]);

  // Backdrop behind the button, to allow existing button styles to layer overtop the highlight
  const correctHighlight = mergeStyles({
    position: 'absolute',
    width: 140,
    height: 170,
    borderRadius: 10,
    backgroundColor: 'white',
    zIndex: 2,
    outline: `thick dashed ${theme.palette.themeSecondary}`,
  });

  React.useEffect(() => {
    if ((showCorrect && isCorrect) || currentButton) {
      targetButton.current?.focus();
    }
  });

  return (
    <>
      {showCorrect && isCorrect && <div className={`${curtainStyles} hint-curtain`}>&nbsp;</div>}
      {showCorrect && isCorrect && <div className={correctHighlight} />}
      <button
        id={`icon-${icon}`}
        ref={targetButton}
        type="button"
        onClick={onClick}
        className={hintStyles}
        disabled={showCorrect && !isCorrect}
      >
        <Icon iconName={icon} />
        <Stack styles={iconLabelWrapper}>
          <Label level={3}>
            <P level={3}>{t(`desktop.icons.${icon}.label`)}</P>
          </Label>
        </Stack>
      </button>
    </>
  );
}

const iconWrapper: IStackStyles = {
  root: {
    padding: 30,
    maxHeight: '80%',
    height: '100%',
    width: 300,
    paddingRight: 100,
    position: 'absolute',
    top: 0,
  },
};

export default function Desktop({
  children,
  icons,
  desktopColor = 'transparent',
  desktopBackground,
  showCorrect = false,
  showHint = false,
  onDismiss,
  title,
  hint,
}: React.PropsWithChildren<DesktopProps>): JSX.Element {
  const [buttonId, setButtonId] = React.useState('');
  const handleClick = (id: string) => {
    setButtonId(id);
  };

  const correctId = `#icon-${icons?.find((icon) => icon.isCorrect)?.icon}`;

  return (
    <Screen backgroundColor={desktopColor} backgroundImage={desktopBackground} cover anchor="left">
      <Stack wrap styles={iconWrapper}>
        {icons &&
          icons.map((iconData) => (
            <Stack.Item key={iconData.icon} align="start">
              <DesktopIcon
                icon={iconData.icon}
                onClick={() => {
                  handleClick(`#icon-${iconData.icon}`);
                  iconData.onClick();
                }}
                isCorrect={iconData.isCorrect}
                showCorrect={showCorrect}
                currentButton={buttonId === `#icon-${iconData.icon}`} // Used for maintaining focus
              />
            </Stack.Item>
          ))}
      </Stack>
      {(showHint || showCorrect) && (
        <Hint
          target={showHint ? buttonId : correctId}
          onDismiss={showHint ? onDismiss : () => {}}
          direction={DirectionalHint.rightCenter}
        >
          <span>{title} </span>
          <span>{hint}</span>
        </Hint>
      )}
      {children}
    </Screen>
  );
}

export function generateDesktopIcons(
  defaultOnClick = () => {
    // TODO: Set id of this icon for error popup
  },
  overrides: DesktopIconProps[] = [],
): DesktopIconProps[] {
  const iconKeys = Object.keys(ICON_NAMES);
  const iconList = iconKeys.map((icon) => ({
    icon,
    onClick: () => defaultOnClick(),
  }));
  for (let i = 0; i < iconList.length; i++) {
    const icon = iconList[i];
    const oIndex = overrides.findIndex((o) => o.icon === icon.icon);
    if (oIndex > -1) {
      iconList[i] = {
        ...overrides[oIndex],
      };
    }
  }
  return iconList as DesktopIconProps[];
}
