import * as React from 'react';
import { IStackItemStyles, IStackStyles, IStackTokens, Stack } from '@fluentui/react';
import Button from '../../Button/Button';
import Panel from '../../Panel/Panel';
import { Heading, P } from '../../Text';
import Checkbox from '../Checkbox/Checkbox';
import useActivityFocus from '../../../../lib/hooks/useActivityFocus';
import { gradients, greyScale } from '../../../../theme/Global/colors';

// Re-export for easier use when composing contents
export { Checkbox };

interface MultipleSelectProps {
  title: string;
  subtitle?: string;
  body: string;
  onSubmit: () => void;
  submitDisabled?: boolean;
  useDark?: boolean;
  nextLabel?: string;
}

const optionWrapper: IStackStyles = {
  root: [{ marginBottom: 48, width: '100%' }],
};

const selectStyles: IStackItemStyles = {
  root: [{}],
};

const questionWrapper: IStackStyles = {
  root: {
    background: gradients.primaryLightReversed,
    borderRadius: 6,
  },
};

const questionWrapperTokens: IStackTokens = {
  padding: 48,
};

const instructionWrapper: IStackItemStyles = {
  root: {
    borderBottom: `1px solid ${greyScale[7]}`,
    marginBottom: '2rem',
    paddingBottom: '2rem',
  },
};

export function useMultipleSelect(correctCount: number) {
  const [errorCount, setErrorCount] = React.useState(0);
  const [totalCorrect, setTotalCorrect] = React.useState(0);
  const [totalChecked, setTotalChecked] = React.useState(0);

  const isCorrect = !errorCount && totalCorrect === correctCount;
  const noValuesChecked = totalChecked <= 0;
  const handleError = React.useCallback(
    (event: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean) => {
      setErrorCount((val) => (checked ? val + 1 : val - 1));
      setTotalChecked((val) => (checked ? val + 1 : val - 1));
    },
    [setErrorCount, setTotalChecked],
  );

  const handleCorrect = React.useCallback(
    (event: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean) => {
      setTotalCorrect((val) => (checked ? val + 1 : val - 1));
      setTotalChecked((val) => (checked ? val + 1 : val - 1));
    },
    [setTotalCorrect, setTotalChecked],
  );

  return { isCorrect, handleError, handleCorrect, noValuesChecked };
}

export default function MultiSelect({
  children,
  title,
  subtitle,
  body,
  onSubmit,
  submitDisabled = false,
  useDark = false,
  nextLabel = 'Done',
}: React.PropsWithChildren<MultipleSelectProps>) {
  const hasSingleChild = React.Children.count(children) === 1;
  const { focusRef } = useActivityFocus();

  return (
    <Panel fullscreen useDark={useDark}>
      <div>
        <Stack styles={questionWrapper} tokens={questionWrapperTokens} className="inside">
          <Stack.Item>
            {/* eslint-disable jsx-a11y/no-noninteractive-tabindex */}
            <span ref={focusRef} tabIndex={-1}>
              <Heading level={2} block>
                {title}
              </Heading>
              {subtitle && <P level={1}>{subtitle}</P>}
            </span>
          </Stack.Item>
          <Stack.Item styles={instructionWrapper}>
            <P level={1} block noMargin>
              {body}
            </P>
          </Stack.Item>
          <Stack.Item>
            <Stack verticalAlign="center" horizontalAlign="center" verticalFill>
              <Stack styles={optionWrapper}>
                {hasSingleChild ? (
                  <Stack.Item align="start">{children}</Stack.Item>
                ) : (
                  React.Children.map(children, (child) => <Stack.Item styles={selectStyles}>{child}</Stack.Item>)
                )}
              </Stack>
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <Button isPrimary label={nextLabel} onClick={onSubmit} disabled={submitDisabled} />
          </Stack.Item>
        </Stack>
      </div>
    </Panel>
  );
}
