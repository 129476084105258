import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGameService } from '../../../state/GlobalStateProvider';
import { RootState } from '../../../store';
import { setGameThemeOverride } from '../../../store/game-slice';
import ForceThemeOverride from '../ForceThemeOverride/ForceThemeOverride';

interface Props {
  children?: React.ReactNode;
  gameId?: string;
}

// HOC for running Tasks outside of a phase that will approximate proper game env
const DevTask: React.FC<Props> = ({ children, gameId }) => {
  const { state, setState } = useGameService();
  const gameThemeOverride = useSelector((reduxState: RootState) => reduxState.game.gameThemeOverride);
  const dispatch = useDispatch();

  useEffect(() => {
    // setting to a discrete state in the game machine to disable unwanted functionality
    if (gameId && gameThemeOverride !== gameId) {
      dispatch(setGameThemeOverride(gameId));
    }
    if (!state.matches('devTask')) {
      setState('devTask');
    }
    return function cleanup() {
      if (gameThemeOverride !== null) {
        dispatch(setGameThemeOverride(null));
      }
    };
  });

  return <ForceThemeOverride themeId={gameId}>{children || 'failed to load DevTaskChildren'}</ForceThemeOverride>;
};

export default DevTask;
