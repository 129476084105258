import { ReactComponent as Completed } from './completed.svg';
import { ReactComponent as Deactivated } from './deactivated.svg';

/**
 * Episode01: All task components in a single namespace.
 */

export default {
  completed: Completed,
  deactivated: Deactivated,
  activated: Deactivated,
};
