import * as React from 'react';
import { Trans } from 'react-i18next';
import { ComponentProps } from '../../../../task-types';
import Dialog from '../../../../../components/Global/Dialog/Dialog';
import Screen from '../../../../../components/Global/Screen/Screen';
import Button from '../../../../../components/Global/Button/Button';

export default function A040({ t, send }: ComponentProps): JSX.Element {
  return (
    <Screen>
      <Dialog
        title={t('A040.title')}
        body={<Trans t={t} i18nKey="A040.body" components={[<strong />]} />}
        onClick={() => {}}
        hideNext
        useDark
        character="wennyStare"
        background="desk"
      >
        <Button
          isPrimary
          onClick={() => send('CLICK.A050')}
          label={t('A040.options.1.label')}
        />
        <Button
          isPrimary
          onClick={() => send('CLICK.A060')}
          label={t('A040.options.2.label')}
        />
      </Dialog>
    </Screen>
  );
}
