import React from 'react';
import { Stack } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useGameService } from '../../../state/GlobalStateProvider';
import Dialog from '../../Global/Dialog/Dialog';
import { ReviewItemDefinition, ReviewTypeEnum } from '../../../lib/game-data/types';
import Button from '../../Global/Button/Button';
import { useAppDispatch, useAppSelector } from '../../../store';
import Modal from '../Modal/Modal';
import { Heading, P } from '../../Global/Text';
import Checkbox from '../../Global/Interactions/Checkbox/Checkbox';
import PlayerPersonaLive from '../../Global/PlayerPersona/PlayerPersonaLive';
import { useConsensus } from '../../../lib/hooks/useConsensus';
import { selectCurrentPhase, actions as multiplayerAction } from '../../../store/multiplayer-slice';
import { ArrayOrSingle } from '../../../lib/types';
import { NILAnswer } from '../../../lib/multiplayer/schemas';
import { devLog } from '../../../lib/util';

// Compare local state to consensus answer array to determine initial correctCount value
function getUserCorrectCount(answers: string | string[], review: ReviewItemDefinition | undefined) {
  // Create an string[] of all correct answers identified by their original index
  const correctAnswers = review?.answers
    ?.map((answer, index) => (answer.isCorrect ? `answer_${index}` : null))
    .filter((answer) => answer);
  let correctCount = 0;
  if (!review) return correctCount;

  if (typeof answers === 'string') {
    return correctAnswers?.includes(answers) ? 1 : 0;
  }

  answers.forEach((answer) => {
    if (correctAnswers?.includes(answer)) {
      correctCount += 1;
    }
  });
  return correctCount;
}

function getCorrectAnswer(question: ReviewItemDefinition) {
  if (question.type === ReviewTypeEnum.enum.Question) {
    const correctAnswerIndex = question.answers?.findIndex((answer) => answer.isCorrect);
    return `answer_${correctAnswerIndex}`;
  }

  if (question.type === ReviewTypeEnum.enum.MultipleSelect) {
    const correctAnswers: string[] = [];
    question.answers?.forEach((answer, index) => {
      if (answer.isCorrect) correctAnswers.push(`answer_${index}`);
    });
    return correctAnswers;
  }

  return '';
}

const ReviewQuestions = () => {
  const { t } = useTranslation(['common', 'entries', 'game01Common']);
  const { send } = useGameService();
  const dispatch = useAppDispatch();
  const [showUnanimous, setShowUnanimous] = React.useState(false);
  const [showFeedback, setShowFeedback] = React.useState(false);
  const [currentReviewStep, setCurrentReviewStep] = React.useState(0);

  const currentPhase = useAppSelector(selectCurrentPhase);
  // Save phase-data as a ref so that we don't get a flash of undefined text when transitioning back to lead-selection
  const phaseData = React.useRef(currentPhase);
  const reviewLength = phaseData.current?.review.data.length;
  const nextQuestion = phaseData.current?.review.data[currentReviewStep];

  const correctCount = React.useMemo(() => {
    if (nextQuestion && nextQuestion.answers) {
      return nextQuestion.answers.reduce((acc, answer) => {
        return answer.isCorrect ? acc + 1 : acc;
      }, 0);
    }

    return 0;
  }, [nextQuestion]);

  // Move to the next question/dialog or finish
  const handleNext = React.useCallback(() => {
    // ensure the feedback modal is closed
    setShowFeedback(false);
    if (reviewLength && currentReviewStep < reviewLength - 1) {
      setCurrentReviewStep((val) => {
        return val + 1;
      });
    } else {
      send('DONE.QUESTION');
    }
  }, [currentReviewStep, reviewLength, send]);

  const currentConsensusKey = `${phaseData?.current?.id}.Question0${currentReviewStep + 1}`;
  // Create a question consensus
  const questionConsensus = useConsensus(currentConsensusKey, () => {}, true, false, true);
  const userVote = questionConsensus.userVote as ArrayOrSingle<string>;
  const userCorrectCount = getUserCorrectCount(userVote, nextQuestion);

  // Answer handler final answer submission
  const handleFinalAnswer = React.useCallback(
    (correctAnswerCount: number, isCorrect?: boolean | null) => {
      if (!phaseData.current || !nextQuestion) return; // early return if phaseData isn't set

      if (questionConsensus.status !== 'completed') {
        setShowUnanimous(true);
        return;
      }

      // If `isCorrect` is null, side-step scoring or correct checks and proceed
      if (isCorrect !== null && questionConsensus.userVote) {
        // const scoreUpdate = nextQuestion.scoreMultiplier
        //   ? nextQuestion.scoreMultiplier * phaseData.current.review.baseScore
        //   : phaseData.current.review.baseScore;
        // const singleAnswerScore = isCorrect ? scoreUpdate : 0;

        // Save the score, allowing partial points for multiple choices
        dispatch(
          multiplayerAction.emitPhaseScoreUpdate({
            id: phaseData.current.id,
            // amount: correctAnswerCount > 1 ? scoreUpdate * correctAnswerCount : singleAnswerScore,
            // Set score to either 500 or nothing. For now we'll side-step partial scoring in favour of all-or-nothing
            amount: isCorrect ? 500 : 0,
          }),
        );
      }

      const finalAnswerCorrectCount = getUserCorrectCount(
        questionConsensus.finalAnswer as ArrayOrSingle<string>,
        nextQuestion,
      );

      if (isCorrect || isCorrect == null || finalAnswerCorrectCount === correctCount) {
        handleNext();
      } else {
        setShowFeedback(true);
      }
    },
    [nextQuestion, questionConsensus, correctCount, dispatch, handleNext],
  );

  // Create a consensus for the final submission
  const submitConsensus = useConsensus(`${currentConsensusKey}_final`, handleFinalAnswer, false, false, true);

  React.useEffect(() => {
    if (!nextQuestion) {
      send('DONE.QUESTION');
    }
  }, [send, nextQuestion]);

  if (!nextQuestion) {
    return null;
  }

  const nextQuestionCorrectAnswer = getCorrectAnswer(nextQuestion);
  const renderAnswers = (useCheckbox = false) => {
    return nextQuestion.answers?.map(({ text, isCorrect }, index) => {
      const answerId = `answer_${index}`;

      const userVotes = () =>
        questionConsensus.allVotes &&
        questionConsensus.allVotes.reduce<React.ReactNode[]>((acc, vote) => {
          if (vote.answer === answerId || (vote.answer as string[]).includes(answerId)) {
            devLog('participant voted : ', vote.participantId);
            acc.push(
              <Stack.Item
                key={vote.participantId}
                // styles={{ root: { position: 'absolute', top: 10, right: 20 * (voteIndex + 1) } }}
              >
                <PlayerPersonaLive hideName username={vote.participantId} />
              </Stack.Item>,
            );
          }
          return acc;
        }, []);

      if (useCheckbox) {
        const answerIndex = userVote.indexOf(answerId);

        return (
          <Stack horizontal verticalAlign="center" key={answerId} tokens={{ childrenGap: 20 }}>
            <Checkbox
              label={text}
              checked={answerIndex > -1}
              disabled={
                (submitConsensus.status === 'completed' && questionConsensus.status === 'completed') ||
                (submitConsensus.hasUserVoted && submitConsensus.userVote !== NILAnswer)
              }
              onChange={() => {
                const updatedAnswers =
                  answerIndex === -1
                    ? [...userVote, answerId]
                    : [...userVote.slice(0, answerIndex), ...userVote.slice(answerIndex + 1)];
                // eslint-disable-next-line no-nested-ternary
                const newCorrectCount = isCorrect
                  ? answerIndex === -1
                    ? userCorrectCount + 1
                    : userCorrectCount - 1
                  : userCorrectCount;

                questionConsensus.handleConsensus(
                  Array.from(new Set(updatedAnswers)).sort(),
                  newCorrectCount === correctCount,
                  newCorrectCount,
                  undefined,
                  nextQuestionCorrectAnswer,
                );
              }}
            />
            {userVotes()}
          </Stack>
        );
      }

      return (
        <Button
          key={answerId}
          label={text}
          isPrimary
          onClick={() => {
            questionConsensus.handleConsensus(
              answerId,
              isCorrect,
              userCorrectCount,
              undefined,
              nextQuestionCorrectAnswer,
            );
          }}
          disabled={
            (submitConsensus.status === 'completed' && questionConsensus.status === 'completed') ||
            (submitConsensus.hasUserVoted && submitConsensus.userVote !== NILAnswer)
          }
        >
          <Stack tokens={{ childrenGap: 20 }} horizontal verticalAlign="center">
            <Stack.Item>{`${text}`}</Stack.Item>
            {userVotes()}
          </Stack>
        </Button>
      );
    });
  };

  const renderPlayerConsensusStatus = () => {
    if (questionConsensus.waitingFor.length === 0) {
      return (
        <>
          <P level={3} block>
            {questionConsensus.status === 'completed'
              ? t('phaseReview.playersHaveAgreement')
              : t('phaseReview.consensusNeeded')}
          </P>
        </>
      );
    }

    return (
      <P level={3} block>
        {t('phaseReview.waitingOnAnswer', {
          val: questionConsensus.waitingFor.map((user) => user && user.display_name),
        })}
      </P>
    );
  };

  const renderSubmitButton = () => {
    const userVotes = () =>
      submitConsensus.allVotes &&
      submitConsensus.allVotes.reduce<React.ReactNode[]>((acc, vote) => {
        if (vote.answer === NILAnswer) return acc;
        acc.push(
          <Stack.Item key={vote.participantId}>
            <PlayerPersonaLive hideName username={vote.participantId} />
          </Stack.Item>,
        );
        return acc;
      }, []);
    return (
      <Button
        label={t('buttons.submit')}
        isPrimary
        onClick={() =>
          submitConsensus.handleConsensus(
            'final',
            userCorrectCount === correctCount,
            userCorrectCount,
            questionConsensus.key,
            'final',
          )}
        disabled={
          !(submitConsensus.status === 'completed' && questionConsensus.status === 'completed') &&
          !(
            (questionConsensus.hasUserVoted && !submitConsensus.hasUserConfirmed) ||
            submitConsensus.userVote === NILAnswer
          )
        }
      >
        <Stack tokens={{ childrenGap: 20 }} horizontal verticalAlign="center">
          <Stack.Item>{t('buttons.submit')}</Stack.Item>
          {userVotes()}
        </Stack>
      </Button>
    );
  };

  // Deal with Dialog
  if (nextQuestion.type === ReviewTypeEnum.enum.Dialog) {
    return (
      <Dialog
        title={nextQuestion.title}
        body={nextQuestion.body}
        nextLabel={nextQuestion.nextLabel}
        onClick={handleNext}
        character="andrea"
        background="office"
        useDark
      />
    );
  }

  // Deal with Questions
  if (nextQuestion.type === ReviewTypeEnum.enum.Question) {
    return (
      <Dialog
        title={nextQuestion.title}
        body={nextQuestion.body}
        onClick={() => {}}
        character="andrea"
        background="office"
        disableSubmit
        hideNext
        useDark
      >
        {renderAnswers()}
        {renderPlayerConsensusStatus()}
        {renderSubmitButton()}
        <Modal
          sidebar="character"
          isOpen={showFeedback}
          footer={<Button isPrimary label={nextQuestion.feedbackNextLabel || 'Next'} onClick={handleNext} />}
          onDismiss={handleNext}
          hideDismissButton
        >
          <Heading level={3}>{nextQuestion.feedbackTitle}</Heading>
          <P level={1}>{nextQuestion.feedbackBody}</P>
        </Modal>
        <Modal
          sidebar="character"
          isOpen={showUnanimous}
          hideDismissButton
          title={t('consensus.unanimous.title')}
          body={t('consensus.unanimous.body')}
          footer={
            <Button
              isPrimary
              label={t('buttons.again')}
              onClick={() => {
                setShowUnanimous(false);
                submitConsensus.handleConsensus(NILAnswer);
              }}
            />
          }
        />
      </Dialog>
    );
  }

  if (nextQuestion.type === ReviewTypeEnum.enum.MultipleSelect) {
    return (
      <Dialog
        title={nextQuestion.title}
        body={nextQuestion.body}
        onClick={() =>
          submitConsensus.handleConsensus(
            'final',
            userCorrectCount === correctCount,
            userCorrectCount,
            questionConsensus.key,
            'final',
          )}
        character="andrea"
        background="office"
        disableSubmit
        hideNext
        useDark
      >
        {renderAnswers(true)}
        {renderPlayerConsensusStatus()}
        {renderSubmitButton()}
        <Modal
          sidebar="character"
          isOpen={showFeedback}
          footer={<Button isPrimary label={nextQuestion.feedbackNextLabel || 'Next'} onClick={handleNext} />}
          onDismiss={handleNext}
          hideDismissButton
        >
          <Heading level={3}>{nextQuestion.feedbackTitle}</Heading>
          <P level={1}>{nextQuestion.feedbackBody}</P>
        </Modal>
        <Modal
          sidebar="character"
          isOpen={showUnanimous}
          hideDismissButton
          title={t('consensus.unanimous.title')}
          body={t('consensus.unanimous.body')}
          footer={
            <Button
              isPrimary
              label={t('buttons.again')}
              onClick={() => {
                setShowUnanimous(false);
                submitConsensus.handleConsensus(NILAnswer);
              }}
            />
          }
        />
      </Dialog>
    );
  }

  return null;
};

export default ReviewQuestions;
