import { createMachine, interpret } from 'xstate';

import type { MachineConfig, MachineOptions, StateFrom, InterpreterFrom } from 'xstate';

import './init';
import { isDev } from '../lib/util';
import { store as reduxStore } from '../store';
import { resetPlayerScore, setCurrentLead, setSkipTutorial } from '../store/game-slice';
import { applyData, emitCurrentPhase, selectCurrentPhase, setCurrentPhase } from '../store/multiplayer-slice';
import { MPManager } from '../lib/socket';

import type { PhaseData } from '../lib/game-data/phase-data';

export type GameEvent =
  | { type: 'CHOOSE.LEAD'; leadId: string }
  | { type: 'ADD.EVIDENCE'; leadId?: string }
  | {
      type:
        | 'ABANDON.LEAD'
        | 'COMPLETE.LEAD'
        | 'DONE.CONCLUSION'
        | 'DONE.EVIDENCE'
        | 'DONE.INCIDENTREPORT'
        | 'DONE.INVESTIGATION'
        | 'DONE.LOADING'
        | 'DONE.QUESTION'
        | 'DONE.REVIEW'
        | 'DONE.TUTORIAL'
        | 'START.SESSION'
        | 'DONE.GAMEOVER'
        | 'DONE.SUSPENSION'
        | 'EVENT.STARTED'
        | 'DONE.SUMMARY'
        | 'START.SIMULATION'
        | 'DONE.SIMULATION';
    };

export type GameContext = {
  store: Readonly<typeof reduxStore>;
};

export type GameState = StateFrom<typeof gameMachine>;
export type GameInterpreter = InterpreterFrom<typeof gameMachine>;

export const getGameContext = (extendWith: Partial<GameContext> = {}): GameContext => ({
  store: reduxStore,
  ...extendWith,
});

export const gameMachineConfig: MachineConfig<GameContext, any, GameEvent> = {
  id: 'MPSGame',
  context: getGameContext(),
  initial: 'loading',
  on: {
    'DONE.GAMEOVER': {
      target: '#MPSGame.eventEnded',
    },
    'DONE.SUSPENSION': {
      target: '#MPSGame.userSuspended',
    },
    'DONE.SUMMARY': {
      cond: 'isSummary',
      target: '#MPSGame.summary',
    },
  },
  states: {
    loading: {
      on: {
        'DONE.LOADING': {
          target: 'lobby',
        },
        'EVENT.STARTED': {
          target: 'lobby',
        },
      },
    },
    devHome: {},
    devTask: {},
    lobby: {
      on: {
        'START.SESSION': [
          {
            cond: 'isSummary',
            target: '#MPSGame.summary',
          },
          {
            cond: 'shouldSkipTutorial',
            target: 'investigationPhase',
          },
          {
            target: 'tutorial',
          },
        ],
      },
    },
    tutorial: {
      on: {
        'DONE.TUTORIAL': {
          target: 'investigationPhase',
          actions: 'skipTheTutorial',
        },
      },
    },
    investigationPhase: {
      initial: 'choose',
      states: {
        choose: {
          entry: ['clearCurrentLead'],
          on: {
            'CHOOSE.LEAD': {
              cond: 'canChooseLead',
              target: 'play',
              actions: ({ store }, { leadId }) => {
                store.dispatch(setCurrentLead(leadId));
                store.dispatch(resetPlayerScore(leadId));
              },
            },
            'ADD.EVIDENCE': {
              target: 'addEvidence',
              actions: ({ store }, { leadId }) => {
                // Set current lead to add evidence to (if one was provided).
                if (leadId != null) {
                  store.dispatch(setCurrentLead(leadId));
                }
              },
            },
            'DONE.INVESTIGATION': {
              target: 'review',
            },
          },
        },
        play: {
          initial: 'notInSim',
          states: {
            notInSim: {
              on: {
                'START.SIMULATION': {
                  target: 'inSim',
                },
              },
            },
            inSim: {
              on: {
                'DONE.SIMULATION': {
                  target: 'notInSim',
                },
              },
            },
          },
          on: {
            'ABANDON.LEAD': {
              target: 'choose',
              actions: 'clearCurrentLead',
            },
            'ADD.EVIDENCE': {
              target: 'addEvidence',
              // Note: this cond should probably be different that the complete.lead one
              cond: 'canCompleteCurrentLead',
            },
            'COMPLETE.LEAD': {
              target: 'choose',
              cond: 'canCompleteCurrentLead',
              actions: ['completeLead'],
            },
          },
        },
        addEvidence: {
          on: {
            'DONE.EVIDENCE': {
              target: 'choose',
              actions: ['completeLead'],
            },
          },
        },
        review: {
          on: {
            'DONE.REVIEW': {
              target: 'reviewQuestions',
            },
          },
        },
        reviewQuestions: {
          on: {
            'DONE.QUESTION': [
              {
                cond: 'isLastPhase',
                target: '#MPSGame.conclusion',
              },
              {
                target: 'choose',
                actions: 'gotoNextPhase',
              },
            ],
          },
        },
      },
    },
    conclusion: {
      on: {
        'DONE.CONCLUSION': {
          target: 'summary',
          actions: 'setCurrentPhaseToSummary',
        },
      },
    },
    summary: {},
    eventEnded: {},
    userSuspended: {},
  },
};

export const gameMachineOptions: MachineOptions<GameContext, GameEvent> = {
  actions: {
    completeLead: ({ store }) => {
      const { game, user, multiplayer } = store.getState();
      // Add current lead to "complete" list.
      if (game.currentLeadId != null) {
        store.dispatch(
          applyData({
            id: game.currentLeadId,
            type: 'lead',
            user: user.username,
            date: Date.now(),
            phase: multiplayer.currentPhaseId,
          }),
        );
      }
    },
    gotoNextPhase: ({ store }) => {
      const currentPhase = selectCurrentPhase(store.getState());
      const nextPhase = getNextPhase(currentPhase);
      if (nextPhase != null) {
        store.dispatch(
          emitCurrentPhase({
            data: nextPhase.id,
            cb: (newPhase) => {
              store.dispatch(setCurrentPhase(newPhase));
            },
          }),
        );
      }
    },
    clearCurrentLead: ({ store }) => {
      store.dispatch(setCurrentLead(null));
    },
    skipTheTutorial: ({ store }) => {
      store.dispatch(setSkipTutorial(true));
    },
    setCurrentPhaseToSummary: ({ store }) => {
      store.dispatch(
        emitCurrentPhase({
          data: 'summary',
          cb: (newPhase) => {
            store.dispatch(setCurrentPhase(newPhase));
          },
        }),
      );
    },
  },
  activities: {},
  delays: {},
  guards: {
    shouldSkipTutorial: ({ store }) => {
      return store.getState().game.skipTutorial;
    },
    isSummary: ({ store }) => {
      const { currentPhaseId } = store.getState().multiplayer;
      return currentPhaseId === 'summary';
    },
    isLastPhase: ({ store }) => {
      const currentPhase = selectCurrentPhase(store.getState());
      const currentEpisode = currentPhase?.parent;
      if (currentPhase == null || currentEpisode == null) {
        return true;
      }
      const index = currentEpisode.phases.indexOf(currentPhase);
      return index + 1 >= currentEpisode.phases.length;
    },
    canChooseLead: ({ store }, event) => {
      const currentPhase = selectCurrentPhase(store.getState());

      // Validate event type and parameters.
      if (event.type !== 'CHOOSE.LEAD' || event.leadId == null || event.leadId.length === 0 || currentPhase == null) {
        return false;
      }

      // Return true if lead exists in phase.
      if (currentPhase == null) {
        return false;
      }
      return currentPhase.getLeads().findIndex(({ id }) => id === event.leadId) !== -1;
    },
    canCompleteCurrentLead: ({ store }) => {
      // NOTE: This does not validate collection/completion status, only whether there is a current lead selected.
      return store.getState().game.currentLeadId != null;
    },
  },
  services: {},
};

export const gameMachine = createMachine(gameMachineConfig, gameMachineOptions);

export const gameService = interpret(gameMachine, {
  devTools: isDev,
});

export type GameServiceType = typeof gameService;

export const changeState = (status: string) => {
  const { send, state } = gameService;
  switch (status) {
    case 'started':
      if (state.value === 'loading') {
        send('EVENT.STARTED');
      } else if (state.value === 'lobby') {
        send('START.SESSION');
      }
      break;
    case 'completed':
      send('DONE.GAMEOVER');
      break;
    case 'end':
      send('DONE.GAMEOVER');
      break;
    case 'summary':
      send('DONE.SUMMARY');
      break;
    default:
      break;
  }
};

MPManager.instance.injectGameStateService(Object.assign(gameService, { changeState }));

///
/// Helper functions
///

/** Get the next phase */
export function getNextPhase(phase?: PhaseData | null) {
  const episode = phase?.parent;
  if (phase == null || episode == null) {
    return undefined;
  }
  const index = episode.phases.indexOf(phase);
  return episode.phases[index + 1];
}
