/* eslint-disable react/prop-types */
import React, { useCallback, useEffect } from 'react';
import {
  IconButton,
  IPanelHeaderRenderer,
  IPanelStyleProps,
  IPanelStyles,
  IStyleFunctionOrObject,
  Theme,
} from '@fluentui/react';
import { faComment } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { APIManager } from '../../../lib/api/APIManager';
import { MPManager } from '../../../lib/socket';
import { useAppSelector } from '../../../store';
import NavPanel from '../NavPanel/NavPanel';
import { P } from '../Text';
import ChatDisplay from './ChatDisplay';
import ChatSubmit from './ChatSubmit';
import { actions } from '../../../store/session-slice';
import NavPanelHeader from '../NavPanel/NavPanelHeader';
import { closePanel } from '../../../store/game-slice';
import { hasDebugFlag } from '../../../lib/util';
import { getCurrentGameTheme } from '../../../theme';

const panelStyles = (theme: Theme):IStyleFunctionOrObject<IPanelStyleProps, IPanelStyles> => ({
  main: {
    backgroundColor: theme.palette.white,
  },
  content: {
    // Need this for firefox (also makes the scrollbar look better)
    overflowY: 'auto',
  },
  footer: {
    borderTop: 'none',
  },
  footerInner: {
    padding: 0,
  },
});

const Chat: React.FC = () => {
  const { t } = useTranslation('common');
  const theme = getCurrentGameTheme();

  const socket = MPManager.instance.setupSocket(process.env.REACT_APP_SOCKET_URL);
  const user = useAppSelector((state) => state.user);
  const session = useAppSelector((state) => state.session);
  const { event_slug, username, key } = APIManager.getConfig();
  const dispatch = useDispatch();

  const isActivePanel = useAppSelector(({ game }) => game.activePanel === 'chat');
  const socketStatus = useAppSelector(({ multiplayer }) => multiplayer.status.status);
  // Close chat panel when socket is disconnected
  if (socketStatus === null && isActivePanel && !hasDebugFlag('chat')) {
    dispatch(closePanel());
  }

  const requestHelp = React.useCallback(
    (requesting_help: boolean) => {
      socket.emit('update:user', {
        username,
        key,
        slug: event_slug,
        requesting_help,
      });
    },
    [username, key, event_slug, socket],
  );

  useEffect(() => {
    // Clear persisted messages
    dispatch(actions.resetMessages());

    // Get chat history
    socket.emit('chat:history', { room: `session:${user.session_slug}` }, (history: any) => {
      if (history.data) {
        dispatch(actions.addMessages(history.data));
        dispatch(actions.resetUnread());
      }
    });

    // Get session info
    socket.emit('data:session', { session_slug: `${user.session_slug}`, event_slug: `${event_slug}` });
  }, [dispatch, event_slug, socket, user.session_slug]);

  const headerRenderer = React.useCallback<IPanelHeaderRenderer>(
    (props, defaultRender, headerTextId?: string) => (
      <NavPanelHeader headerTextId={headerTextId} headerText={props?.headerText} icon={faComment}>
        <P level={3} noMargin color={theme.palette.white}>
          {t('chat.requestHelp')}
          <IconButton
            iconProps={{ iconName: 'FaHandPaperOutline' }}
            type="button"
            aria-label={t('chat.requestHelpButton')}
            style={{ marginLeft: 5 }}
            onClick={() => requestHelp(true)}
            disabled={user.requesting_help === true}
          />
          {user.requesting_help && (
            <IconButton
              iconProps={{ iconName: 'times' }}
              type="button"
              aria-label={t('chat.cancelHelpButton')}
              onClick={() => requestHelp(false)}
              style={{ color: theme.palette.red }}
            />
          )}
        </P>
      </NavPanelHeader>
    ),
    [user.requesting_help, requestHelp, t, theme],
  );

  const onRenderFooterContent = useCallback(
    () => <ChatSubmit socket={socket} session_slug={user.session_slug} t={t} />,
    [user.session_slug, socket, t],
  );

  return (
    <NavPanel
      activePanel="chat"
      headerText={t('chat.header')}
      closeButtonAriaLabel={t('chat.closeButton')}
      onRenderHeader={headerRenderer}
      onRenderFooterContent={onRenderFooterContent}
      styles={panelStyles(theme)}
      isFooterAtBottom
    >
      <ChatDisplay users={session.users} messages={session.messages} />
    </NavPanel>
  );
};
export default Chat;
