import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  Panel,
  PanelType,
  IPanelProps,
  IPanelStyles,
  IPanelStyleProps,
  IStyleFunction,
} from '@fluentui/react';
import { useAppSelector } from '../../../store';
import { closePanel } from '../../../store/game-slice';
import { useCombinedStylesWithProps } from '../../../lib/hooks/useCombinedStyles';
import { getCurrentGameTheme } from '../../../theme';

interface NavPanelInterface extends IPanelProps {
  activePanel: string;
  hostId?: string;
}

const theme = getCurrentGameTheme();

/**
 * Forced styles that cannot be overriden by individual NavPanels. (Important sizing/layout)
 */
const forcedStyles: IStyleFunction<IPanelStyleProps, IPanelStyles> = ({ isOpen, isAnimating, isHiddenOnDismiss }) => ({
  main: {
    maxWidth: '100%',
  },
  scrollableContent: {
    height: '100%',
  },
  commands: {
    'position': 'relative',
    'zIndex': 2,
    'marginTop': 0,
    'padding': 0,
    '@media(min-height: 480px)': {
      zIndex: 2,
    },
  },
  navigation: {
    padding: 0,
  },
  content: {
    padding: 0,
    margin: 0,
    zIndex: 0,
  },
  header: {
    padding: '32px',
  },
  // Need to add `opacity: 0` to panel hide state to prevent weird content flash on close.
  hiddenPanel: [
    !isOpen &&
      !isAnimating &&
      isHiddenOnDismiss && {
        visibility: 'hidden',
        opacity: 0,
      },
  ],
  subComponentStyles: {
    closeButton: {
      root: {
        margin: '14px',
        padding: '4px',
      },
    },
  },
});

/**
 * Default styles that _can_ be overriden by individual NavPanels. (Theming)
 */
const defaultStyles: IStyleFunction<IPanelStyleProps, IPanelStyles> = () => ({
  main: {
    boxShadow: theme.effects.elevation16,
    backgroundColor: theme.semanticColors.navPanelBackground,
  },
  commands: {
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  navigation: {
    backgroundColor: theme.semanticColors.navPanelHeaderBackground,
  },
  // NOTE: `headerText` only impacts default text, not custom render results
  headerText: {
    fontSize: '36px',
    lineHeight: '44px',
    fontWeight: 900,
    color: theme.semanticColors.navPanelHeaderText,
  },
  content: {
    color: theme.palette.white,
  },
  subComponentStyles: {
    closeButton: {
      root: {
        'color': theme.semanticColors.navPanelHeaderText,
        // Prevent button child elements from being the target of a click event.
        '> *': {
          pointerEvents: 'none',
        },
      },
      rootFocused: {
        borderColor: 'transparent',
        outline: `1px solid ${theme.palette.white}`,
      },
    },
  },
});

const NavPanel: React.FC<NavPanelInterface> = ({
  activePanel,
  hostId = 'panel-content',
  children,
  onOpen,
  onDismiss,
  styles,
  type = PanelType.custom,
  customWidth = '600px',
  focusTrapZoneProps = { firstFocusableTarget: '.ms-Panel-closeButton' },
  ...panelProps
}) => {
  const dispatch = useDispatch();
  const isOpen = useAppSelector(({ game }) => game.activePanel === activePanel);

  const wrappedOnDismiss = useCallback<NonNullable<IPanelProps['onDismiss']>>(
    (ev) => {
      // Suppress dissmiss when clicking NavBar buttons.
      // Otherwise, clicking the button for the current tab closes & opens it.
      if (ev?.target instanceof Element && ev.target.classList.contains('navBarButton')) {
        return;
      }
      // Call passed-in onDismiss callback, if provided.
      onDismiss?.call(this, ev);
      if (isOpen) {
        dispatch(closePanel());
      }
    },
    [isOpen, onDismiss, dispatch],
  );

  const wrappedOnOpen = useCallback(() => {
    onOpen?.call(this);
  }, [onOpen]);

  /** Combine passed styles with forced styles */
  const combinedStyles = useCombinedStylesWithProps(defaultStyles, styles, forcedStyles);

  return (
    <Panel
      {...panelProps}
      focusTrapZoneProps={focusTrapZoneProps}
      isOpen={isOpen}
      isHiddenOnDismiss
      isLightDismiss
      onDismiss={wrappedOnDismiss}
      onOpen={wrappedOnOpen}
      type={type}
      customWidth={customWidth}
      styles={combinedStyles}
      layerProps={{ hostId }}
    >
      {children}
    </Panel>
  );
};
export default NavPanel;
