import React from 'react';
import { TFunction } from 'react-i18next';

import { ElementDataNode } from '../../../lib/game-data/element-data';
import { imageList } from '../../../static/images';
import { ImageComponentSingle } from '../../../static/images/image-types';
import { useAppSelector } from '../../../store';
import { selectActivityStatus } from '../../../store/multiplayer-slice/applied-data';

export interface IMapActivityProps {
  data: MapActivityNodeData;
}

export interface MapActivityNodeData {
  element: ElementDataNode;
  onFocus?: () => void;
  t: TFunction;
}

export type NodeStatusType = 'deactivated' | 'activated' | 'completed';

const nodeSizeBase = 22;

const nodStyles = {
  minWidth: `${nodeSizeBase * 5}px`,
  maxWidth: `${nodeSizeBase * 5}px`,
  minHeight: `${nodeSizeBase * 5}px`,
  maxHeight: `${nodeSizeBase * 5}px`,
};

/**
 * Evidence Map React-Flow card component.
 */
const MapActivity: React.FC<IMapActivityProps> = ({ data: { element } }) => {
  const status: NodeStatusType = useAppSelector((state) => selectActivityStatus(state, element.getTask()?.id)) || 'deactivated';
  const nodeComponent = imageList.find((image) => image.name === `${element.id}.${status}`) as ImageComponentSingle;
  if (!nodeComponent) return (<></>);

  return (
    <>
      <div style={nodStyles}>
        <nodeComponent.Component width="100%" height="100%" />
      </div>
    </>
  );
};

export default MapActivity;
