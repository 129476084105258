import * as React from 'react';
import { Trans } from 'react-i18next';
import { ComponentProps } from '../../../../task-types';
import { useCompleteActivity } from '../../../../../lib/hooks/useCompleteLead';
import Dialog from '../../../../../components/Global/Dialog/Dialog';
import Screen from '../../../../../components/Global/Screen/Screen';

export default function C080({ t }: ComponentProps): JSX.Element {
  const { completeActivity, completeLabel } = useCompleteActivity();

  return (
    <Screen>
      <Dialog
        title={t('C080.title')}
        body={<Trans t={t} i18nKey="C080.body" components={[<strong />]} />}
        onClick={() => completeActivity()}
        nextLabel={completeLabel}
        useDark
        character="andrea"
        background="office"
      />
    </Screen>
  );
}
