import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTaskMachine } from '../../../../lib/hooks/useTaskMachine';
import A010 from './components/A010';
import A020 from './components/A020';
import A030 from './components/A030';
import A040 from './components/A040';
import A050 from './components/A050';
import A075 from './components/A075';
import A080 from './components/A080';
import B010 from './components/B010';
import B020 from './components/B020';
import B030 from './components/B030';

import { createTask09 } from './machine';

const baseNS = 'Game01.Episode01.Task09';
const taskKey = 'Game01.Episode01.Task09';

// Todo: Refactor this so it's less cumbersome to get a scoped match function
function createTaskMatch(parent: string, state: any) {
  return (matchState: string) => state.matches(`${parent}.${matchState}`);
}

const Task09: React.FC = () => {
  const { t } = useTranslation([baseNS, 'common']);

  // Setup task machine
  const [state, send] = useTaskMachine(createTask09, {
    taskKey,
  });

  return (
    <>
      {state.matches('A010') && <A010 t={t} send={send} state={state.context} match={createTaskMatch('A010', state)} />}
      {state.matches('A020') && <A020 t={t} send={send} state={state.context} match={createTaskMatch('A020', state)} />}
      {state.matches('A030') && <A030 t={t} send={send} state={state.context} match={createTaskMatch('A030', state)} />}
      {state.matches('A040') && <A040 t={t} send={send} state={state.context} match={createTaskMatch('A040', state)} />}
      {state.matches('A050') && <A050 t={t} send={send} state={state.context} match={createTaskMatch('A050', state)} />}
      {state.matches('A075') && <A075 t={t} send={send} state={state.context} match={createTaskMatch('A075', state)} />}
      {state.matches('A080') && <A080 t={t} send={send} state={state.context} match={createTaskMatch('A080', state)} />}
      {state.matches('B010') && <B010 t={t} send={send} state={state.context} match={createTaskMatch('B010', state)} />}
      {state.matches('B020') && <B020 t={t} send={send} state={state.context} match={createTaskMatch('B020', state)} />}
      {state.matches('B030') && <B030 t={t} send={send} state={state.context} match={createTaskMatch('B030', state)} />}
    </>
  );
};

export default Task09;
