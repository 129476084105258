import { createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { shallowEqual } from 'react-redux';

import type { PayloadAction, CaseReducer } from '@reduxjs/toolkit';

import { coerceArray, devLog, devLogWarn } from '../../lib/util';
import { gameData } from '../../static/game-data';

import type { RootState } from '..';
import type { MultiplayerStateInterface } from '.';
import type {
  AppliedCardEntity,
  AppliedClueEntity,
  AppliedDataEntity,
  AppliedDataEntityType,
  ApplyDataPayload,
} from '../../lib/multiplayer/schemas';
import type { SafeDictionary } from '../../lib/types';
import type { EvidenceData } from '../../lib/game-data/evidence-data';
import type { FactData } from '../../lib/game-data/fact-data';
import { ElementData, ElementDataWire } from '../../lib/game-data/element-data';
import type { NodeStatusType } from '../../components/Game02/InsightEngine/MapActivity';
import { EpisodeData } from '../../lib/game-data/episode-data';

/** Applied data entity adapter. */
export const appliedDataAdapter = createEntityAdapter<AppliedDataEntity>({
  // Sort applied data entities by date (ascending).
  sortComparer: (a, b) => a.date - b.date,
});

/**
 * Selectors for appliedDataAdapter.
 * Note: These are pre-rooted for use directly with the Redux store's RootState.
 */
const appliedDataAdapterSelectors = appliedDataAdapter.getSelectors<RootState>(
  (state) => state.multiplayer.appliedData,
);

/**
 * Selector create for applied data type-filter selectors.
 */
function appliedDataByTypeSelectorCreator<TType extends AppliedDataEntityType>(type: TType) {
  // Select all applied data entities matching a certain type.
  const selectAllAppliedByType = createSelector(
    appliedDataAdapterSelectors.selectAll,
    (entities) =>
      entities.filter((entity): entity is Extract<AppliedDataEntity, { type: TType }> => entity.type === type),
    { memoizeOptions: { resultEqualityCheck: shallowEqual } },
  );

  // Generate list of IDs from filtered result of selector above.
  const selectAllAppliedByTypeIds = createSelector(
    selectAllAppliedByType,
    (entities) => entities.map((entity) => entity.id),
    { memoizeOptions: { resultEqualityCheck: shallowEqual } },
  );

  // Generate map of IDs->Entities from filtered result of selector above.
  const selectAllAppliedByTypeMap = createSelector(
    selectAllAppliedByType,
    (entities) =>
      entities.reduce((accumulator, entity) => {
        accumulator[entity.id] = entity;
        return accumulator;
      }, {} as SafeDictionary<Extract<AppliedDataEntity, { type: TType }>>),
    { memoizeOptions: { resultEqualityCheck: shallowEqual } },
  );

  return [selectAllAppliedByType, selectAllAppliedByTypeIds, selectAllAppliedByTypeMap] as const;
}

/**
 * Selectors to return all applied data entities that are of type `card`.
 */
export const [selectAllAppliedCards, selectAllAppliedCardIds, selectAllAppliedCardsMap] =
  appliedDataByTypeSelectorCreator('card');

/**
 * Selectors to return all applied data entities that are of type `clue`.
 */
export const [selectAllAppliedClues, selectAllAppliedClueIds, selectAllAppliedCluesMap] =
  appliedDataByTypeSelectorCreator('clue');

/**
 * Selectors to return all applied data entities that are of type `clue`.
 */
 export const [selectAllAppliedLogs, selectAllAppliedLogIds, selectAllAppliedLogsMap] =
 appliedDataByTypeSelectorCreator('log');

/** Type for `selectAppliedDataForCardId` selector. */
export type AppliedDataForCard = {
  cardData: EvidenceData;
  cardEntity?: AppliedCardEntity;
  appliedClues: {
    clueData: EvidenceData;
    clueEntity: AppliedClueEntity;
    appliedFacts: FactData[];
  }[];
};

/** Complex selector that retrieves all applied data entities and associated game data instances for a given card ID. */
export const selectAppliedDataForCardId = createSelector(
  [(_, cardId: string) => cardId, selectAllAppliedCardsMap, selectAllAppliedClues],
  (cardId, cardEntitiesMap, clueEntities): AppliedDataForCard | undefined => {
    // Retrieve card data instance. If this doesn't exist or is not a card, return undefined object.
    const cardData = gameData.get(cardId, 'evidence');
    if (cardData?.type !== 'card') {
      return undefined;
    }
    return {
      cardData,
      cardEntity: cardEntitiesMap[cardData.id],
      appliedClues: clueEntities.reduce<AppliedDataForCard['appliedClues']>((accumulator, clueEntity) => {
        const clueData = gameData.get(clueEntity.id, 'evidence');
        if (clueData?.type !== 'clue' || clueData.getLocation() !== cardData) {
          return accumulator;
        }
        accumulator.push({
          clueData,
          clueEntity,
          appliedFacts: clueData.facts.filter(({ shortId }) => clueEntity.facts?.includes(shortId)),
        });
        return accumulator;
      }, []),
    };
  },
);

export const selectAppliedDataForLogs = createSelector(
  [selectAllAppliedLogs],
  (logEntities): ElementData[] => {
    return logEntities.reduce<ElementData[]>((accumulator, logEntity) => {
      const elementData = gameData.get(logEntity.id, 'element');
      if (elementData?.type !== 'log') {
        return accumulator;
      }
      accumulator.push(elementData);
      return accumulator;
    }, []);
  },
);

/**
 * Selectors to return all applied data entities that are of type `lead`.
 */
export const [selectAllAppliedLeads, selectAllAppliedLeadIds, selectAllAppliedLeadsMap] =
  appliedDataByTypeSelectorCreator('lead');

  /**
 * Selectors to return all applied data entities that are of type `activity`.
 */
export const [selectAllAppliedActivities, selectAllAppliedActivityIds, selectAllAppliedActivitiesMap] =
  appliedDataByTypeSelectorCreator('activity');

export const selectActivityEnoughPower = createSelector(
  [
    (_, activityId: string | undefined) => activityId,
    selectAppliedDataForLogs,
    selectAllAppliedLogs,
  ],
  (activityId, logData, logEntities): boolean => {
    const activityAppliedLogGeneratedPower = logData.reduce<number>(
      (accumulator, log) => {
        if (log.getTask()?.id !== activityId) {
          return accumulator;
        }

        const numberOftries = logEntities.find((entity) => entity.id === log.id)?.tries ?? 1;
        // eslint-disable-next-line no-param-reassign
        accumulator += (log.output ?? 0) * (4 / numberOftries);

        return accumulator;
      }, 0);

    return !!logData
      .filter((log, index, self) => self.findIndex(({ locationId }) => locationId === log.locationId) === index) // get unique nodes from the logs
      .find((log) => log.getTask()?.id === activityId &&
        log.getOutputWires()?.find((wire) => wire.inPowerRange(activityAppliedLogGeneratedPower)));
  },
);

export const selectWireEnoughPower = createSelector(
  [
    (_, wire: ElementDataWire | undefined) => wire,
    selectAppliedDataForLogs,
    selectAllAppliedLogs,
  ],
  (wire, logData, logEntities): boolean => {
    const activityAppliedLogGeneratedPower = logData.reduce<number>(
      (accumulator, log) => {
        if (!log.getOutputWires()?.find((outWire) => outWire.id === wire?.id)) {
          return accumulator;
        }

        const numberOftries = logEntities.find((entity) => entity.id === log.id)?.tries ?? 1;
        // eslint-disable-next-line no-param-reassign
        accumulator += (log.output ?? 0) * (4 / numberOftries);

        return accumulator;
      }, 0);

    return wire?.inPowerRange(activityAppliedLogGeneratedPower) ?? false;
  },
);

export const selectActivityStatus = createSelector(
  [
    selectActivityEnoughPower,
    (state, activityId) => {
      const { currentPhaseId } = state.multiplayer;
      const currentEpisode = gameData.get(currentPhaseId, 'phase')?.parent as EpisodeData;
      return currentEpisode.getUnlockedElements()
        .find((element) => {
          return element.getTask()?.id === activityId;
        });
    },
    (state, activityId: string | undefined) => {
      const { currentPhaseId } = state.multiplayer;
      const currentEpisode = gameData.get(currentPhaseId, 'phase')?.parent as EpisodeData;
      const connectedNodes = currentEpisode.getActivityConnectedNodes(activityId);
      return connectedNodes.find((node) => !selectActivityEnoughPower(state, node.getTask()?.id));
    },
  ],
  (enoughPower, activityUnlocked, connectedNodeWithLowPower): NodeStatusType | undefined => {
    // activity is compeleted if it has generated enough power
    if (enoughPower) {
      return 'completed';
    }

    // activity is activated if it was unlocked from the game data
    if (activityUnlocked) {
      return 'activated';
    }

    // activity is activated if all the nodes connected to it are generating enough power
    if (!connectedNodeWithLowPower) {
      return 'activated';
    }

    return 'deactivated';
  },
);

export const selectCircuitStatus = createSelector(
  [
    (_, circuitId: string | undefined) => circuitId,
    (state) => {
      const { currentPhaseId } = state.multiplayer;
      return gameData.get(currentPhaseId, 'phase')?.parent as EpisodeData;
    },
    selectAllAppliedLogIds,
  ], (circuitId, currentEpisode, appliedLogIds) => {
  const allLogsUnlocking = currentEpisode.activities.reduce<string[]>((accumulator, activity) => {
    accumulator.push(...activity.getChildren('element')
      .reduce<string[]>((lockAccumulator, element) => {
        if (element.type !== 'log' ||
          !element.getUnlocks().find((lock) => lock.id === circuitId)) {
          return lockAccumulator;
        }

        lockAccumulator.push(element.id);
        return lockAccumulator;
      }, []),
    );
    return accumulator;
  }, []);

  const notFoundedLocks = allLogsUnlocking.find((nodeId) => !appliedLogIds.includes(nodeId));
  return (notFoundedLocks ? 'deactivate' : 'completed') as NodeStatusType;
});

export const selectBatteryPower = createSelector(
  [
    selectAllAppliedLogs,
    selectAppliedDataForLogs,
    (state) => {
      const { currentPhaseId } = state.multiplayer;
      return gameData.get(currentPhaseId, 'phase')?.parent as EpisodeData;
    },
  ], (logEntities, logData, currentEpisode) => {
    const maximumPower = currentEpisode.activities.reduce<number>((accumulator, activity) => {
      return accumulator + activity.elements.reduce<number>((logAccumulator, log) => {
        return logAccumulator + (log.output ?? 0) * 4;
      }, 0);
    }, 0);

    const insightPower = logData.reduce<number>(
      (accumulator, log) => {
        const numberOftries = logEntities.find((entity) => entity.id === log.id)?.tries ?? 1;
        // eslint-disable-next-line no-param-reassign
        accumulator += (log.output ?? 0) * (4 / numberOftries);

        return accumulator;
      }, 0);

    return (insightPower / maximumPower) * 100;
  });

export const selectAllCompeletedActivities = createSelector(
  [
    (state) => state,
    selectAppliedDataForLogs,
  ],
  (state, logData) => {
    const logsWithUniqueNode = logData
      .filter((log, index, self) => self.findIndex(({ locationId }) => locationId === log.locationId) === index);
    return logsWithUniqueNode.reduce<string[]>((accumulator, log) => {
      const activity = log.getTask();
      if (!activity) return accumulator;

      if (selectActivityEnoughPower(state, activity.id)) {
        accumulator.push(activity.id);
      }

      return accumulator;
    }, []);
  },
);
/**
 * Selectors to return all applied data entities that are of type `question`.
 */
export const [selectAllAppliedQuestions, selectAllAppliedQuestionIds, selectAllAppliedQuestionsMap] =
  appliedDataByTypeSelectorCreator('question');

/**
 * Grouped export for all selectors.
 */
export const appliedDataSelectors = Object.freeze({
  ...appliedDataAdapterSelectors,
  selectAllAppliedCards,
  selectAllAppliedCardIds,
  selectAllAppliedCardsMap,
  selectAllAppliedClues,
  selectAllAppliedClueIds,
  selectAllAppliedCluesMap,
  selectAllAppliedLeads,
  selectAllAppliedLeadIds,
  selectAllAppliedLeadsMap,
  selectAllAppliedQuestions,
  selectAllAppliedQuestionIds,
  selectAllAppliedQuestionsMap,
});

/**
 * Case reducer: apply data entity
 */
export const applyDataCR: CaseReducer<MultiplayerStateInterface, PayloadAction<ApplyDataPayload>> = (
  state,
  { payload },
) => {
  const options = 'options' in payload ? payload.options : undefined;
  const entities = coerceArray('data' in payload ? payload.data : payload);

  entities.forEach((newEntity) => {
    // Forced updates skip all validation.
    if (options?.forceAll === true) {
      return appliedDataAdapter.setOne(state.appliedData, newEntity);
    }

    // Validate that entity wasn't already applied earlier
    if (options?.overwriteOlder !== true) {
      const oldEntity = state.appliedData.entities[newEntity.id];
      if (oldEntity != null) {
        // Don't replace an Entity with one with a newer timestamp.
        if (newEntity.date >= oldEntity.date) {
          return devLogWarn('applyData(): Entity already exists with older timestamp.');
        }
        // DO replace an Entity with one with an OLDER timestamp, but warn.
        devLog('applyData(): Entity already exists with newer timestamp. Replacing...');
      }
    }

    // Validate that entity represents valid GameData
    const dataInstance = gameData.get(newEntity.id);
    if (dataInstance == null) {
      return devLogWarn('applyData(): GameData not found for Entity.');
    }

    // Validate that entity matches type of valid GameData
    if (!(newEntity.type === dataInstance.kind || ('type' in dataInstance && newEntity.type === dataInstance.type))) {
      return devLogWarn("applyData(): Entity type doesn't match GameData");
    }

    // Add entity
    return appliedDataAdapter.setOne(state.appliedData, newEntity);
  });
};
