import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTaskMachine } from '../../../../lib/hooks/useTaskMachine';
import N01 from './components/N01';
import N02 from './components/N02';
import N03 from './components/N03';
import N04 from './components/N04';
import N05 from './components/N05';
import { createTask01 } from './machine';

const baseNS = 'Game02.Episode01.Task01';
const taskKey = 'Game02.Episode01.Task01';

function createTaskMatch(parent: string, state: any) {
  return (matchState: string) => state.matches(`${parent}.${matchState}`);
}

const Task01: React.FC = () => {
  const { t } = useTranslation([baseNS, 'common']);

  // Setup task machine
  const [state, send] = useTaskMachine(createTask01, {
    taskKey,
  });

  return (
    <>
      {state.matches('N01') && <N01 t={t} send={send} state={state.context} match={createTaskMatch('N01', state)} />}
      {state.matches('N02') && <N02 t={t} send={send} state={state.context} match={createTaskMatch('N02', state)} />}
      {state.matches('N03') && <N03 t={t} send={send} state={state.context} match={createTaskMatch('N03', state)} />}
      {state.matches('N04') && <N02 t={t} send={send} state={state.context} match={createTaskMatch('N04', state)} />}
      {state.matches('N05') && <N04 t={t} send={send} state={state.context} match={createTaskMatch('N05', state)} />}
      {state.matches('N06') && <N05 t={t} send={send} state={state.context} match={createTaskMatch('N06', state)} />}
      {state.matches('N07') && <N04 t={t} send={send} state={state.context} match={createTaskMatch('N07', state)} />}
      {state.matches('N08') && <N02 t={t} send={send} state={state.context} match={createTaskMatch('N08', state)} />}
      {state.matches('N09') && <N04 t={t} send={send} state={state.context} match={createTaskMatch('N09', state)} />}
      {state.matches('N10') && <N02 t={t} send={send} state={state.context} match={createTaskMatch('N10', state)} />}
      {state.matches('N11') && <N02 t={t} send={send} state={state.context} match={createTaskMatch('N11', state)} />}
      {state.matches('N12') && <N04 t={t} send={send} state={state.context} match={createTaskMatch('N12', state)} />}
      {state.matches('N13') && <N05 t={t} send={send} state={state.context} match={createTaskMatch('N13', state)} />}
      {state.matches('N14') && <N02 t={t} send={send} state={state.context} match={createTaskMatch('N14', state)} />}
      {state.matches('N15') && <N04 t={t} send={send} state={state.context} match={createTaskMatch('N15', state)} />}
      {state.matches('N16') && <N05 t={t} send={send} state={state.context} match={createTaskMatch('N16', state)} />}
      {state.matches('N17') && <N04 t={t} send={send} state={state.context} match={createTaskMatch('N17', state)} />}
      {state.matches('N18') && <N04 t={t} send={send} state={state.context} match={createTaskMatch('N18', state)} />}
      {state.matches('N19') && <N05 t={t} send={send} state={state.context} match={createTaskMatch('N19', state)} />}
      {state.matches('Completion') && <N03 t={t} send={send} state={state.context} match={createTaskMatch('Completion', state)} />}
    </>
  );
};

export default Task01;
