import React from 'react';
import { ITextStyles, mergeStyleSets, Stack } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import type { IStackItemStyles, IStackTokens } from '@fluentui/react';

import { getUserDisplayName } from '../../../lib/users';
import getFontVariant, { TextComponent } from '../Text/getFontVariant';
import { getPlayerColor } from '../../../static/player-avatars';

import type { UserInfo } from '../../../lib/socket/schemas';
import type { PlayerColors } from '../../../static/player-avatars';
import { useAppSelector } from '../../../store';
// import { selectAllUsers } from '../../store/user-slice';
import { Heading } from '../Text';
import { getMPLeadCount, getMPLeadScore } from '../../../store/multiplayer-slice';
import { getCurrentGameTheme } from '../../../theme';

export interface IPlayerPersonaBaseProps {
  useDark?: boolean;
  user: UserInfo;
  size?: 'small' | 'medium' | 'large';
  color?: keyof PlayerColors | number;
  hideName?: boolean;
  vertical?: boolean;
}

const theme = getCurrentGameTheme();

const stackTokens: IStackTokens = {
  childrenGap: '20px',
};

const avatarColumnBaseStyles: IStackItemStyles = {
  root: {
    overflow: 'hidden',
    position: 'relative',
    borderRadius: '50%',
    boxSizing: 'border-box',
    borderWidth: '4px',
    borderStyle: 'solid',
  },
};

const avatarColumnStyles: Record<NonNullable<IPlayerPersonaBaseProps['size']>, IStackItemStyles> = {
  small: mergeStyleSets(avatarColumnBaseStyles, {
    root: {
      width: '60px',
      height: '60px',
    },
  }),
  medium: mergeStyleSets(avatarColumnBaseStyles, {
    root: {
      width: '120px',
      height: '120px',
    },
  }),
  large: mergeStyleSets(avatarColumnBaseStyles, {
    root: {
      width: '100px',
      height: '100px',
    },
  }),
};

const baseLabelStyle: ITextStyles = {
  root: {
    color: theme.palette.black,
    ...theme.fonts[getFontVariant(TextComponent.enum.Label, 2)],
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
};

const darkLabelStyle: ITextStyles = mergeStyleSets(baseLabelStyle, {
  root: {
    color: theme.palette.white,
    textShadow: '2px 2px 5px #222',
  },
});

/**
 * Basic PlayerPersona presentation component.
 * (For Redux integration, see `PlayerPersonaLive`.)
 */
const SummaryPlayerPersonaBase: React.FC<IPlayerPersonaBaseProps> = ({
  useDark = false,
  user,
  size = 'small',
  color = 'gray',
  // hideName = false,
  vertical = false,
}) => {
  const displayName = getUserDisplayName(user);
  const colorData = getPlayerColor(color);
  const leadScores = useAppSelector((state) => getMPLeadScore(state.multiplayer.leadScores));
  const leadCounts = useAppSelector((state) => getMPLeadCount(state.multiplayer.leadScores));
  // const { allUsers } = useAppSelector(selectAllUsers);
  const { t } = useTranslation('game01Common', { keyPrefix: 'summary' });

  return (
    <Stack horizontal={!vertical} tokens={stackTokens} horizontalAlign="start" verticalAlign="start">
      <Stack.Item
        disableShrink
        styles={avatarColumnStyles[size]}
        style={{ borderColor: colorData.border, backgroundColor: colorData.background }}
        // title={user.display_name}
        // aria-label={hideName ? displayName : undefined}
      >
        {'\u200B'}
      </Stack.Item>
      <Stack verticalAlign="center">
        {/* {!hideName &&
          !vertical &&
          allUsers.map((key) => (
            <Stack.Item key={key.username} styles={useDark ? darkLabelStyle : baseLabelStyle}>
              <Heading level={3} as={1} useDark>
                {key.display_name}
              </Heading>
            </Stack.Item>
          ))} */}
        <Stack.Item styles={useDark ? darkLabelStyle : baseLabelStyle}>
          <Heading level={3} as={1} useDark>
            {displayName}
          </Heading>
        </Stack.Item>

        <Heading level={3} useDark noMargin>
          {!leadScores[user.username] ? 0 : leadScores[user.username]}
        </Heading>

        <Heading level={3} useDark noMargin>
          {leadCounts[user.username] ? leadCounts[user.username] : 0}{' '}
          {leadCounts[user.username] === 1 ? t('lead') : t('leads')}
        </Heading>
      </Stack>
    </Stack>
  );
};

export default SummaryPlayerPersonaBase;
