import type { LocaleContent, CardOrEntryLocaleContent } from "../../types";

/**
 * i18Next `entries:Episode02.Task03` lead text content
 */
const Task03Lead: LocaleContent = {
  header: "Review App Deployment Architecture ",
  role: "Identity Administrator",
  time: "5m",
  brief: "It looks like the command was run from our eCommerce App. Take a look at our deployment architecture—it's documented in \"ecommerce_appservice_azureresources.docx.\" How are the Azure resources deployed and managed? Is there anything we should investigate? <br><br>-Andrea",
};

/**
 * i18Next `entries:Episode02.Task03` entry content
 */
const Task03Entries: CardOrEntryLocaleContent = {
  eCommerceAppIdentities: {
    heading: "eCommerce App Identities",
    ManagedIdentityUse: "eCommerce app has a user-assigned managed identity.",
    eShopIdentity: "Name of user-assigned managed identity is eShopIdentity.",
    DeploymentSlotsUse: "eCommerce app has 3 deployment slots.",
    DevTestProd: "Names of deployment slots are dev, test, and production.",
  },
};

export default {
  ...Task03Lead,
  ...Task03Entries,
};
