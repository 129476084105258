import React from 'react';
import { useTranslation } from 'react-i18next';
import _difference from 'lodash/difference';

import { useGameService } from '../../state/GlobalStateProvider';
import { useAppDispatch, useAppSelector } from '../../store';
import { addEntriesWithUnlocks, selectAllJournalEntities } from '../../store/journal-slice';
import { ScoreUpdateWithId, selectCurrentLead } from '../../store/game-slice';
import { devLogWarn } from '../util';
import { actions as multiplayerAction } from '../../store/multiplayer-slice';
import type { JournalEntryUpdate } from '../journal/types';

export function useCompleteActivity() {
  const { t } = useTranslation(['common', 'game01Common']);
  const { state, send } = useGameService();
  const dispatch = useAppDispatch();

  const currentLead = useAppSelector(selectCurrentLead);
  const journalEntities = useAppSelector(({ journal }) => selectAllJournalEntities(journal.entries));
  const leadScore: number = useAppSelector(({ game }) => {
    const update = currentLead ? game.leadScores[currentLead.id]?.score : 0;
    return update || 0;
  });
  const userId = useAppSelector(({ user }) => user.username);

  // Get collection status info and missing critical Journal entries.
  const [collectionStatus, missingEntries] = React.useMemo(() => {
    const status = currentLead?.getCollectionStatus(journalEntities);
    const missing = status?.critical.total.reduce<JournalEntryUpdate[]>((accumulator, evidenceStatus) => {
      if (!evidenceStatus.completed) {
        accumulator.push({
          id: evidenceStatus.id,
          states: _difference(evidenceStatus.critical.total, evidenceStatus.critical.collected)
            .map(({ shortId }) => shortId)
            .sort(),
        });
      }
      return accumulator;
    }, []);
    return [status, missing] as const;
  }, [currentLead, journalEntities]);

  // GameService event to fire, based on whether there are (or will be) entries to apply.
  const targetEvent =
    (collectionStatus?.entities.length ?? 0) === 0 && (missingEntries?.length ?? 0) === 0
      ? 'COMPLETE.LEAD'
      : 'ADD.EVIDENCE';

  // Check if activity is completable
  const isCompletable = !!collectionStatus?.completable && state.can(targetEvent);

  // Handler to actually complete the lead.
  // NOTE: This handler does _not_ check `isCompletable`
  const completeActivity = React.useCallback(
    (scoreUpdate?: ScoreUpdateWithId) => {
      // Add missing critical entries.
      if (missingEntries != null && missingEntries.length > 0) {
        devLogWarn('Adding missing critical entries:', missingEntries);
        dispatch(addEntriesWithUnlocks(missingEntries));
      }

      if (scoreUpdate) {
        // this only happens for sim leads
        dispatch(multiplayerAction.emitLeadScoreUpdate(scoreUpdate));
      } else if (currentLead) {
        // this happens for non-sim leads
        dispatch(
          multiplayerAction.emitLeadScoreUpdate({
            amount: leadScore,
            id: currentLead.id,
            userId,
          }),
        );
      }

      // Trigger transition.
      send(targetEvent);
    },
    [missingEntries, targetEvent, send, dispatch, currentLead, leadScore, userId],
  );

  return {
    isCompletable,
    collectionStatus,
    missingEntries,
    targetEvent,
    completeLabel: t(`gameServiceEvents.${targetEvent}`),
    skipLabel: t('gameServiceEvents.SKIP.SIMULATION'),
    completeActivity,
  } as const;
}
