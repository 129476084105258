/**
 * i18next NS: `Episode01.Task11`
 */
export default {
  common: {
    errorTitle: "Try Again",
    next: "Next",
    submit: "Submit",
    start: "Start",
    skipAhead: "Skip Ahead",
    ok: "OK",
    done: "Done",
  },
  A010: {
    // Desktop Activity
    error: {
      1: {
        title: "Try again.",
        body: "No, that's not the right place.",
      },
      2: {
        title: "Try again.",
        body: "That's not right either.",
      },
      3: {
        title: "Go Here!",
        body: "I need you to go to Azure Active Directory.",
      },
    },
  },
  A020: {
    body: "There are a few ways to access sign-in logs. Today we'll get to them via the Manage menu. What next?",
    options: {
      1: {
        label: "Users",
      },
      2: {
        label: "Groups",
        error: {
          title: "Try something else.",
          body: "Try to narrow down the results a little more.",
        },
      },
      3: {
        label: "External identities",
        error: {
          title: "Not that.",
          body: "External identities is for people outside your organization who have access to your apps and resources.",
        },
      },
    },
  },
  A030: {
    title: "Access Sign-in Logs",
    body: "Set the filters to narrow down the results.",
    attempts: "Attempt % of 3",
    subhead: "",
    placeholder: "Select the correct answer",
    unlocked: "Search Unlocked!",
    success: {
      title: "Sign-in Logs Unlocked",
      body: "",
    },
    locks: {
      1: {
        label: "User",
        options: {
          1: "Andrea Divkovic",
          2: "Amari Rivera",
          3: "Angel Brown",
        },
        hint: "The message was sent from Angel's account.",
      },
      2: {
        label: "Option",
        options: {
          1: "Devices",
          2: "Sign-in logs",
          3: "Audit logs",
        },
        hint: "Remember your lead - look at Angel's sign-in logs.",
      },
      3: {
        label: "Timeframe",
        options: {
          1: "Last 1 month",
          2: "Last 7 days",
          3: "Last 24 hours",
          4: "Custom: 24 hrs +/- Pivot Time",
        },
        hint: "Filter around the Pivot Time (the time of the message). Otherwise there will be too many results - you'll be looking forever.",
      },
    },
    error: {
      title: "Check the Brief.",
      body: "It explains what settings you should use here.",
    },
    walkthrough: {
      title: "Did you take a look at those sign-in logs yet?",
      nextLabel: "No, guide me",
    },
  },
  A065: {
    title: "Here are Angel's sign-in logs.",
    body: "Here are Angel's sign-in logs around the UTC Pivot Time. What do you notice?",
  },
  A070: {
    title: "Your search of Angel's sign-in logs had no results.  What could this indicate?",
    subtitle: "",
    body: "",
    options: {
      1: {
        label: "There was an RDP connection to Angel's machine.",
      },
      2: {
        label: "The chat message was sent from a remote IP address.",
      },
      3: {
        label: "Angel's credentials have been compromised.",
      },
      4: {
        label: "There is no evidence that Angel's account was breached by a third party.",
      },
    },
    error: {
      title: "That isn't right.",
      body: "Remember, sign-in logs show you when a user provides their username and password in the Azure Active Directory sign-in screen.",
    },
    feedback: {
      title: "There is no evidence that Angel's account was breached by a third party.",
      subtitle: "No sign-ins were found within 24 hours of the UTC Pivot time. Note that you will not see RDP sessions listed here - they are Windows events, not Azure Active Directory events.",
      body: "",
    },
    success: {
      title: "Yes, cached credentials must have been used to send the message.",
      body: "No sign-ins were found within 24 hours of the UTC Pivot time, so there is no evidence that Angel's account was breached by a third party. Note that you will not see RDP sessions listed here - they are Windows events, not Azure AD events.",
    },
  },
  A080: {
    title: "Lead complete!",
    body: "You investigated Angel's sign-in logs. Now it's time to put the information you found on the Evidence Map.",
    nextLabel: "Back to evidence map",
  },
};
