export default {
  activities: {
    desktop: {
      icons: {
        thisPc: {
          label: "This PC",
          alt: "This PC",
        },
        securityCenter: {
          label: "Security Center",
          alt: "Security Center",
        },
        policy: {
          label: "Policy",
          alt: "Policy",
        },
        microsoftComplianceCenter: {
          label: "Microsoft 365 Compliance Center",
          alt: "Microsoft 365 Compliance Center",
        },
        microsoftEndpointManager: {
          label: "Microsoft Endpoint Manager",
          alt: "Microsoft Endpoint Manager",
        },
        logAnalyticsWorkspace: {
          label: "Log Analytics Workspace",
          alt: "Log Analytics Workspace",
        },
        azureSentinel: {
          label: "Microsoft Sentinel",
          alt: "Microsoft Sentinel",
        },
        azureAdRoles: {
          label: "Azure AD Roles",
          alt: "Azure AD Roles",
        },
        azureAdPrivileges: {
          label: "Azure AD Privileges",
          alt: "Azure AD Privileges",
        },
        azureAd: {
          label: "Azure Active Directory",
          alt: "Azure Active Directory",
        },
        defenderForEndpoint: {
          label: "Defender For Endpoint",
          alt: "Defender For Endpoint",
        },
        ecommerce: {
          label: "ecommerce_appservice_azureresources.docx",
          alt: "ecommerce_appservice_azureresources.docx",
        },
        gitHub: {
          label: "GitHub",
          alt: "GitHub",
        },
        azurePortal: {
          label: "Azure Portal",
          alt: "Azure Portal",
        },
        azureAppService: {
          label: "Azure App Service",
          alt: "Azure App Service",
        },
        azureMonitor: {
          label: "Azure Monitor",
          alt: "Azure Monitor",
        },
        defenderForCloud: {
          label: "Microsoft Defender for Cloud",
          alt: "Microsoft Defender for Cloud",
        },
        defenderForCloudApps: {
          label: "Microsoft Defender for Cloud Apps",
          alt: "Microsoft Defender for Cloud Apps",
        },
      },
    },
    unlock: {
      instructions: "Move the dials to set the parameters.",
      optionDefault: "Select an option",
      lockLabel: {
        unselected: "{{lockLabel}}: no option selected. Press enter or space to select the first option.",
        selected: "{{lockLabel}}: selected option {{selectedOption}}. Press enter or space to select next available option.",
      },
    },
  },
  applyEvidence: {
    title: "Apply Evidence: ",
    body: "Review your cards and the evidence you collected. Add any new cards first. To add evidence, choose the right card and then select APPLY.",
    protectionTitle: "Protection Complete!",
    protectionBody: "Review what you did, the select Apply to document it.",
    cardProgressLabel: "{{applied}}/{{total}} Pieces",
    noCriticalEvidence: "No Critical Information",
    intro: {
      title: "Now it’s time to add the evidence you found to the Evidence Map.",
      body: "Take a look at your Journal. How does this new evidence connect to what you already know about the case?",
      label: "Begin",
    },
    log: {
      title: "Log",
      progressLabel: "{{applied}}/{{total}}",
    },
    notAppliedError: {
      title: "Critical Evidence Not Applied",
    },
    incorrectSelectionError: {
      title: "That evidence doesn't apply to this card.",
      body: "Take another look at your cards. There is a better match for this information.",
      label: "Try Again",
    },
  },
  consensus: {
    currentUser: "You",
    waitingForTeamates: "Waiting for Teammates to finish",
    voteStatus: "<b>{{number}}</b> chose to end the phase. Still Waiting for <b>{{names}}</b>",
    waitingForFinalAnswer: "Waiting for final answer from: <b>{{names}}</b>",
    unanimous: {
      title: "You all need to agree.",
      body: "This needs to be a unanimous decision. Discuss this with your team. Make sure you all choose the same suspect.",
      nextLabel: "Try Again",
    },
  },
  evidenceMap: {
    toggleButton: "Show/Hide Evidence Map",
    closeButton: "Close Evidence Map",
    title: "Evidence Map",
    subTitle: "Explore your progress, click on card to see specfic evidence that has been added.",
  },
  gameServiceEvents: {
    "ADD.EVIDENCE": "Apply Evidence",
    "COMPLETE.LEAD": "Complete Lead",
    "SKIP.SIMULATION": "Complete Simulation",
  },
  help: {
    header: "Help",
    closeButton: "Close Help panel",
    instructionList: {
      heading: "If you are unsure what action to take next, here are some options:",
      items: [
        "Open your Journal and check the Brief to see if you missed anything.",
        "Learn how to navigate through the simulated Azure environment: https://aka.ms/ESI_Tutorial",
        "Ask your teammates for help via Chat.",
        "If you are still stuck, you may reach out to the Facilitator via Chat.",
      ],
    },
  },
  journal: {
    header: "Journal",
    closeButton: "Close Journal panel",
    noLeadSelected: "Select a lead to continue.",
    completeLeadButtonLabel: "Complete Lead",
    abandonLeadConfirmationText: "Are you sure you want to abandon this lead? You will lose all progress.",
    abandonLeadIconButtonLabel: "Abandon Lead",
    abandonLeadButtonLabel: "Abandon",
    completeLeadConfirmationText: "Are you sure you want to complete this lead?",
    completeLeadConfirmationTextMissing: "Are you sure you want to complete this lead? You will not gain any points for evidence you missed.",
    skipSimulationButtonLabel: "Complete Simulation",
    skipSimulationConfirmationText: "Are you sure you want to complete this simulation?",
    skipSimulationConfirmationTextMissing: "Are you sure you want to complete this simulation? You will not gain any points for evidence you missed.",
    noEntriesFoundText: "No Entries Collected",
    progressText: "{{count, number}}/{{total, number}} Critical information collected",
    progressTextHybrid: "{{count, number}}/{{total, number}} Critical information collected from {{region}}",
    nonSim: "game activity",
    sim: "simulation",
    narrative: "narrative",
  },
  journalEntry: {
    evidence: "Evidence",
    clue: "Clue",
    link: "Link",
    card: "Card",
    question: "Question",
    alert: "Alert",
    policy: "Policy",
  },
  labels: {
    finish: "Finish",
    apply: "Apply",
    addCard: "Add Card",
  },
  leadSelection: {
    title: "Choose a lead to investigate",
    body: "You and your teammates need to divide and conquer. Select a lead to get started.",
    leadSelectionHeading: "Available Leads",
    buttonLabels: {
      startInvestigation: "Begin Investigation",
      startProtection: "Begin Protection",
      end: "End Investigation",
      applyEvidence: "Apply Evidence",
    },
    completedBy: "has completed this lead.",
    startedBy: "has started this lead",
    leadStatus: {
      inProgress: "Waiting for all leads to be completed.",
      complete: "All leads are complete.<br>Select END INVESTIGATION to continue.",
    },
  },
  location: {
    lobby: "Event Lobby",
    tutorial: "Introduction/Tutorial",
    conclusion: "Conclusion",
    summary: "Final Score/Summary",
    eventEnded: "Event Ended",
    userSuspended: "Disconnected",
    review: "Review",
    reviewQuestions: "Review Questions",
  },
  navBar: {
    accessibility: {
      regionLabel: "Side Menu",
    },
    unread: {
      newEntry: "New Entry",
      newEntries: "New Entries",
      clickToView: "Click To View",
    },
    buttons: {
      journal: {
        title: "Journal Nav Button",
        label: "Journal",
      },
      policies: {
        title: "Policies Nav Button",
        label: "Policies",
      },
      chat: {
        title: "Chat Button",
        label: "Chat",
      },
      help: {
        title: "Help Nav Button",
        label: "Help",
      },
      settings: {
        title: "Settings Nav Button",
        label: "Settings",
      },
    },
  },
  phaseReview: {
    wrapUpHeading: "Here's what's going on.",
    wrapUpBody: "Thanks to Defender for App Service's alert, we were able to quickly respond and cut the attack off before any customer data was compromised. Our damage control protocols were successful! Great job, team!",
    introModalHeading: "Check in with your teammates",
    introModalBody: "What did you learn during your investigation? It's time to share what you found with the rest of the team. Remember, you can always review your findings in the Evidence Map.",
    cardModalHeading: "Here are the updated cards",
    cardModalDescription: "Share your findings with your teammates.",
    clueModalHeading: "Here is the evidence you uncovered",
    clueModalDescription: "Share your findings with your teammates.",
    reviewMapModalHeading: "Review the Evidence Map",
    reviewMapModalBody: "Take some time to explore the map and discuss the evidence you found. When you're finished, I'd like to ask you a few questions.",
    questionsButtonLabel: "Questions",
    reviewQuestionInstruction: "Discuss this with your team, then select your answer.",
    waitingOnAnswer: "Waiting for answer from {{val, list}}",
    waitingOnSubmission: "Waiting on submission from {{val, list}}",
    playersHaveAgreement: "All Players Agree.",
    consensusNeeded: "Players must reach a consensus.",
    error: {
      title: "No, check your evidence map.",
      body: "This is the answer {{answer}}",
    },
  },
  policies: {
    header: "Policies",
  },
  settings: {
    header: "Settings",
    confirmationDialog: {
      title: "Are you sure?",
      body: "If you log out, all your progress will be cleared.",
      confirmButton: "Log out",
      cancelButton: "Cancel",
    },
  },
  summary: {
    thankYouMsg: "Thanks for Playing",
    teamScore: "Team Score",
    leads: "Leads",
    lead: "Lead",
  },
  unavailable: {
    header: "Feature Unavailable",
    body: "This feature is not yet available in the current build.",
  },
};
