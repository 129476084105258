import * as React from 'react';
import { Stack } from '@fluentui/react';
import { isEqual } from 'lodash';
import Modal from '../../../../../components/Game01/Modal/Modal';
import { ComponentProps } from '../../../../task-types';
import Button from '../../../../../components/Global/Button/Button';
import MultipleDropdown, {
  useMultipleDropdown,
} from '../../../../../components/Game01/Interactions/MultipleDropdown/MultipleDropdown';
import Dropdown from '../../../../../components/Game01/Interactions/MultipleDropdown/Dropdown';

export default function A080({ t, send, match, state }: ComponentProps): JSX.Element {
  const initialOptions = {
    1: {
      order: 1,
      label: t('A080.selection.1.label'),
      options: [
        { id: 0, key: 0, text: t('A080.selection.1.options.1'), disabled: false },
        { id: 1, key: 1, text: t('A080.selection.1.options.2'), disabled: false },
        { id: 2, key: 2, text: t('A080.selection.1.options.3'), disabled: false },
        { id: 3, key: 3, text: t('A080.selection.1.options.4'), disabled: false, isCorrect: true },
      ],
    },
    2: {
      order: 2,
      label: t('A080.selection.2.label'),
      options: [
        { id: 0, key: 0, text: t('A080.selection.2.options.1'), disabled: false },
        { id: 1, key: 1, text: t('A080.selection.2.options.2'), disabled: false },
        { id: 2, key: 2, text: t('A080.selection.2.options.3'), disabled: false },
        { id: 3, key: 3, text: t('A080.selection.2.options.4'), disabled: false, isCorrect: true },
      ],
    },
    3: {
      order: 3,
      label: t('A080.selection.3.label'),
      options: [
        { id: 0, key: 0, text: t('A080.selection.3.options.1'), disabled: false },
        { id: 1, key: 1, text: t('A080.selection.3.options.2'), disabled: false, isCorrect: true },
        { id: 2, key: 2, text: t('A080.selection.3.options.3'), disabled: false },
        { id: 3, key: 3, text: t('A080.selection.3.options.4'), disabled: false },
      ],
    },
  };

  const { dropdownRefs, handleCombinationChange, resetOptions, taskState, combinationState } = useMultipleDropdown(
    initialOptions,
    [3, 3, 1],
    match('walkthrough'),
    state.walkthroughStep,
  );
  return (
    <>
      <MultipleDropdown
        heading={t('A080.title')}
        subhead={`${t('A080.subhead')} ${t('A080.body')}`}
        showWalkthrough={match('walkthrough')}
        onSuccess={() => send('NEXT')}
        onError={() => {
          resetOptions();
          send('CLICK.SUBMIT_INCORRECT');
        }}
        isUnlocked={isEqual(combinationState.combination, combinationState.currentCombination)}
      >
        {Object.keys(taskState).map((key, optionsIndex) => (
          <Stack.Item key={optionsIndex}>
            <Dropdown
              ref={dropdownRefs[optionsIndex]}
              id={key}
              label={taskState[key].label}
              options={taskState[key].options}
              onChange={(optionKey: number | string) => handleCombinationChange(optionsIndex, optionKey)}
              hint={t(`A080.selection.${key}.hint`)}
              walkthroughCallback={() => send('NEXT')}
            />
          </Stack.Item>
        ))}
      </MultipleDropdown>

      <Modal
        sidebar="character"
        footer={<Button isPrimary label={t('common.errorTitle')} onClick={() => send('NEXT')} />}
        onDismiss={() => send('NEXT')}
        hideDismissButton
        isOpen={match('incorrect') && state.attempts < 2}
        title={t('A080.error.title')}
        body={t('A080.error.body')}
      />
    </>
  );
}
