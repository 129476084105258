import * as React from 'react';
import Screen from '../../../../../components/Global/Screen/Screen';
// import { ComponentProps } from '../interface';
import AddEvidence, { Checkbox, useAddEvidence } from '../../../../../components/Game01/AddEvidence/AddEvidence';
import { ComponentProps } from '../../../../task-types';
import Modal from '../../../../../components/Game01/Modal/Modal';
import Button from '../../../../../components/Global/Button/Button';

export default function C040a({ t, send, match }: ComponentProps): JSX.Element {
  const evidence = [
    {
      id: 'one',
      title: t('C040a.evidence.one.title'),
      subtitle: t('C040a.evidence.one.subtitle'),
      entry: {
        id: 'Episode01.Task01.LeakedFileSearchResults',
        states: ['AmariNotes'],
      },
    },
    {
      id: 'two',
      title: t('C040a.evidence.two.title'),
      subtitle: t('C040a.evidence.two.subtitle'),
      entry: {
        id: 'Episode01.Task01.LeakedFileSearchResults',
        states: ['SharedNotes'],
      },
    },
    {
      id: 'three',
      title: t('C040a.evidence.three.title'),
      subtitle: t('C040a.evidence.three.subtitle'),
      entry: {
        id: 'Episode01.Task01.LeakedFileSearchResults',
        states: ['PurchasingDataQ1XLSX'],
      },
      isCritical: true,
    },
    {
      id: 'four',
      title: t('C040a.evidence.four.title'),
      subtitle: t('C040a.evidence.four.subtitle'),
      entry: {
        id: 'Episode01.Task01.LeakedFileSearchResults',
        states: ['DataRequest'],
      },
    },
  ];

  const { isComplete, handleToggleEvidence, saveEvidence } = useAddEvidence(evidence);

  const isFeedback = match('feedback') || match('feedbackComplete');

  const handleSubmit = () => {
    if (isComplete || isFeedback) {
      send('NEXT');
      saveEvidence();
    } else {
      send('CLICK.SUBMIT_INCORRECT');
    }
  };

  return (
    <Screen>
      <AddEvidence
        title={isFeedback ? t('C040a.feedback.title') : t('C040a.title')}
        description={isFeedback ? t('C040a.feedback.body') : t('C040a.body')}
        onSubmit={handleSubmit}
        useDark
      >
        {evidence.map((item) => (
          <Checkbox
            key={item.id}
            label={`${item.title} ${item.subtitle}`}
            onChange={(_, checked?: boolean) => handleToggleEvidence(item.id, checked)}
            forceWrap
            showAnswer={isFeedback}
            isCorrect={item.isCritical}
          />
        ))}
      </AddEvidence>

      <Modal
        sidebar="character"
        title={t('C040a.evidenceAdded.title')}
        body={t('C040a.evidenceAdded.body')}
        isOpen={match('success') || match('feedbackComplete')}
        footer={<Button isPrimary label={t('C040a.evidenceAdded.nextLabel')} onClick={() => send('NEXT')} />}
        onDismiss={() => send('NEXT')}
        hideDismissButton
      />
    </Screen>
  );
}
