import React from 'react';
import { IStackItemTokens, IStackStyles, IStackTokens, Stack } from '@fluentui/react';
import { mergeStyles } from '@fluentui/merge-styles';

const baseWidth = 1920;
const baseHeight = 1080;
const ratio = baseWidth / baseHeight;
const aspectRatioTopPadding = `${(1 / ratio) * 100}%`;
// To ensure any backgrounds aren't covered by the navbar, adjust wrapper using this offset
const navbarOffset = 111;

const stackTokens: IStackTokens | IStackItemTokens = {};

export interface ScreenProps {
  backgroundImage?: string;
  backgroundColor?: string;
  anchor?: string;
  cover?: boolean;
  ignoreOffset?: boolean;
}

export default function Screen({
  children,
  backgroundColor = 'transparent',
  backgroundImage,
  anchor = 'right',
  cover = false,
  ignoreOffset = false,
}: React.PropsWithChildren<ScreenProps>) {
  const stackStyles: IStackStyles = {
    root: {
      transform: `translateX(-${ignoreOffset ? 0 : navbarOffset}px)`,
    },
  };
  const backgroundImageStyles = mergeStyles({
    backgroundColor,
    background: cover ? `url(${backgroundImage}) no-repeat ${anchor} center` : `url(${backgroundImage})`,
    backgroundSize: cover ? 'cover' : 'contain',
    backgroundRepeat: 'no-repeat',
  });

  const screenWrapper = mergeStyles({
    overflow: 'hidden',
  });

  const aspectRatioDivBaseStyles = mergeStyles({
    position: cover ? 'fixed' : 'relative',
    top: cover ? 0 : 'unset',
    left: cover ? 0 : 'unset',
    width: cover ? '100vw' : 'auto',
    padding: cover ? 0 : `${aspectRatioTopPadding} 0 0 0`,
    height: cover ? '100vh' : 'auto',
    margin: 'auto',
  });

  return (
    <Stack className={`${screenWrapper} interaction-screen__wrapper`} verticalFill>
      {backgroundImage && (
        <Stack horizontal className="interaction-screen__content" verticalFill={cover} styles={stackStyles}>
          <Stack.Item grow={1} tokens={stackTokens}>
            <div className={`${backgroundImageStyles} ${aspectRatioDivBaseStyles} aspect-box`} />
          </Stack.Item>
        </Stack>
      )}
      <Stack className="interaction-screen__panel" verticalFill={!cover}>
        <Stack.Item grow={1} tokens={stackTokens}>
          {children}
        </Stack.Item>
      </Stack>
    </Stack>
  );
}
