import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { useGameService } from '../../state/GlobalStateProvider';
import { GameContext, GameEvent } from '../../state/game-service';
import { getMatcherResults, StateMatcher } from '../../lib/xstate/matchers';
import { setCurrentPhase } from '../../store/multiplayer-slice';
import { useAppDispatch } from '../../store';
import NarrativeBreak from '../../components/Game02/NarrativeBreak/NarrativeBreak';

const Game02NarrativeBreak: React.FC = () => {
  const dispatch = useAppDispatch();
  const { state, setState } = useGameService();

  React.useEffect(() => {
    dispatch(setCurrentPhase('Game02.Episode01.Phase02'));
    setState({ investigationPhase: 'reviewQuestions' }, {});
  }, [dispatch, setState]);

  // Generate the list of content to match on once.
  // (Add dependencies for regeneration if necessary later)
  const stateMatchers = React.useMemo<StateMatcher<GameContext, GameEvent>[]>(
    () => [
      {
        states: 'investigationPhase.reviewQuestions',
        content: <NarrativeBreak />,
      },
    ],
    [],
  );

  // Generate matched content to display every time the state or matchers change.
  const matchedContent = React.useMemo(
    () =>
      getMatcherResults(state, stateMatchers, {
        contentWrapper: (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            // Note: The inline style below ensures activity backgrounds fill the entire viewport and panels connect to the bottom of the screen
            style={{ height: '100%' }}
          />
        ),
        resultsWrapper: <AnimatePresence exitBeforeEnter />,
      }),
    [state, stateMatchers],
  );

  return <>{matchedContent}</>;
};

export default Game02NarrativeBreak;
