import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SessionGroup } from '../components/Global/Facilitator/schemas';

export interface FacilitatorStateInterface {
  sessionGroups: SessionGroup[];
  activeSession: SessionGroup | null;
  sessionStartTime: number;
}

// the initial state
const initialState: FacilitatorStateInterface = {
  sessionGroups: [],
  activeSession: null,
  sessionStartTime: 0,
};

export const facilitatorSlice = createSlice({
  name: 'facilitator',
  initialState,
  reducers: {
    setSessionGroups: (state, { payload }: PayloadAction<SessionGroup[]>) => {
      payload.sort((a, b) => {
        return a.sort_order > b.sort_order ? 1 : -1;
      });
      state.sessionGroups = payload;
    },
    toggleActiveSession: (state, { payload }: PayloadAction<SessionGroup | null>) => {
      const isOpen = state.activeSession?.slug === payload?.slug;
      state.activeSession = isOpen ? null : payload;
    },
    setSessionStartTime: (state, { payload }) => {
      state.sessionStartTime = payload;
    },
  },
});

/**
 * Grouped export for facilitatorSlice actions.
 */
export const actions = Object.freeze({
  ...facilitatorSlice.actions,
});

/**
 * Individual exports for facilitatorSlice actions.
 */
export const { setSessionGroups, toggleActiveSession, setSessionStartTime } = actions;
