import React from 'react';
import ReactIs from 'react-is';

import Game01 from './Game01';
import Game02 from './Game02';
import { TaskComponent } from './task-types';

import type { INavLinkWithComponent } from '../lib/types';
import DevTask from '../components/Global/DevTask/DevTask';

export const taskLinks: INavLinkWithComponent[] = [];
export const taskList: TaskComponent[] = [];

// Iterate over all task namespaces

Object.entries({
  Game01,
  Game02,
}).forEach(([gameNamespaceKey, gameNamespace]) => {
  Object.entries(gameNamespace).forEach(([namespaceKey, taskNamespace]) => {
    Object.entries(taskNamespace).forEach(([componentName, taskComponent]) => {
      if (taskComponent == null || !ReactIs.isValidElementType(taskComponent)) {
        return;
      }
      // inject taskComponent into higher-order DevTask component that sets the game state to devTask
      const Component = () =>
        React.createElement(DevTask, { gameId: gameNamespaceKey }, React.createElement(taskComponent));

      // Generate Route and navigation link entries for dev task routes.
      const path = `/tasks/${gameNamespaceKey}/${namespaceKey}/${componentName}`;
      taskLinks.push({
        name: `${gameNamespaceKey}, ${namespaceKey}, ${componentName}`,
        url: path,
        Component,
      });

      // Generate normal task components
      taskList.push({
        name: `${gameNamespaceKey}.${namespaceKey}.${componentName}`,
        url: path,
        Component: taskComponent,
      });
    });
  });
});

// Freeze exports.
Object.freeze(taskLinks);
