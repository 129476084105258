import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Text } from '@fluentui/react';
import type { IPanelHeaderRenderer } from '@fluentui/react';
import NavPanel from '../../Global/NavPanel/NavPanel';
import { selectAllNotebookEntryProps, setEntriesUnread } from '../../../store/notebook-slice';
import { selectCurrentActivity } from '../../../store/game-slice';
import { useAppDispatch, useAppSelector } from '../../../store';
import getFontVariant, { TextComponent } from '../../Global/Text/getFontVariant';
import NavPanelHeader from '../../Global/NavPanel/NavPanelHeader';
import { getCurrentGameTheme } from '../../../theme';
import ActivityEntry from '../ActivityEntry/AcitivityEntry';
import { hasDebugFlag, isDev } from '../../../lib/util';

const headerRenderer: IPanelHeaderRenderer = (props, defaultRender, headerTextId?: string) => (
  <NavPanelHeader headerTextId={headerTextId} headerText={props?.headerText} />
);

const Notebook: React.FC = () => {
  const { t } = useTranslation('game02Common');
  const theme = getCurrentGameTheme();
  const dispatch = useAppDispatch();
  const entriesSeen = useAppSelector(({ notebook }) => notebook.entriesSeen);
  const notebookEntriesAll = useAppSelector(({ notebook }) => selectAllNotebookEntryProps(notebook.entries));
  const currentActivity = useAppSelector(selectCurrentActivity);

  const updateEntriesUnread = React.useCallback(() => {
    if (entriesSeen.length > 0) {
      dispatch(setEntriesUnread({ ids: entriesSeen, unread: false }));
    }
  }, [dispatch, entriesSeen]);

  const currentActivityEntries = React.useMemo(() => {
    if (currentActivity == null) {
      return [];
    }
    return notebookEntriesAll ?? [];
  }, [currentActivity, notebookEntriesAll]);

  /** Check if we should show all leads. */
  const notebookDebug: boolean = isDev && hasDebugFlag('notebook');

  /** Generate content for Notebook */
  const notebookContent = React.useMemo(() => {
    const output: JSX.Element[] = [];
    if (currentActivity != null) {
      output.push(
        <ActivityEntry
          key={currentActivity.id}
          isCurrentActivity
          activity={currentActivity}
          entries={currentActivityEntries}
        />,
      );
    }

    if (notebookDebug) {
      notebookEntriesAll.forEach(() => {
        if (currentActivity !== undefined) {
          output.push(<ActivityEntry activity={currentActivity} entries={currentActivityEntries} />);
        }
      });
    }

    if (output.length === 0) {
      output.push(
        <Stack.Item key="noLeadSelected" align="center" tokens={{ padding: '16px' }}>
          <Text
            styles={{ root: { color: theme.semanticColors.notebookText, fontWeight: 100 } }}
            variant={getFontVariant(TextComponent.enum.Heading, 5)}
            block
          >
            {t('notebook.noLeadSelected')}
          </Text>
        </Stack.Item>,
      );
    }

    return output;
  }, [
    currentActivity,
    notebookDebug,
    currentActivityEntries,
    notebookEntriesAll,
    theme.semanticColors.notebookText,
    t,
  ]);

  return (
    <NavPanel
      activePanel="notebook"
      onDismiss={updateEntriesUnread}
      headerText={t('notebook.header')}
      styles={{
        navigation: {
          position: 'sticky',
          inset: '0 0 auto',
          height: '108px', // Static height because LeadEntry headers stick below it.
        },
      }}
      closeButtonAriaLabel={t('notebook.closeButton')}
      hasCloseButton
      onRenderHeader={headerRenderer}
    >
      <Stack verticalAlign="start" tokens={{ childrenGap: '16px' }}>
        {notebookContent}
      </Stack>
    </NavPanel>
  );
};
export default Notebook;
