import React from 'react';
import { Stack } from '@fluentui/react';

import { EntryComponentProps } from '../../../lib/entries';
import EntryUnread from './EntryUnread';
import EntryContent from './EntryContent';
import EntryIconCol from './EntryIconCol';
import { toHTMLSafeId } from '../../../lib/util';
import { getCurrentGameTheme } from '../../../theme';
import { useAppDispatch, useAppSelector } from '../../../store';
import { setEntriesSeen } from '../../../store/journal-slice';

const theme = getCurrentGameTheme('Game01');

const Entry: React.FC<EntryComponentProps> = ({ entryData, entity, derivedType, date, heading, content, unread }) => {
  const activePanel = useAppSelector((state) => state.game.activePanel);
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    if (
      entity.unread &&
      ((activePanel === 'journal' && entryData.kind === 'evidence') ||
        (activePanel === 'policies' && entryData.kind === 'policy'))
    ) {
      dispatch(setEntriesSeen(entity.id));
    }
  }, [activePanel, entryData, entity, dispatch]);

  return (
    <Stack
      as="article"
      aria-labelledby={toHTMLSafeId(entity.id, 'headerText')}
      horizontal
      tokens={{ childrenGap: '16px', padding: '24px 60px 24px 24px' }}
      styles={{
        root: {
          position: 'relative',
          backgroundColor: theme.palette.themeLight,
          border: '1px solid rgba(255, 255, 255, 0.1)',
          borderRadius: theme.effects.roundedCorner6,
          minHeight: '142px',
        },
      }}
    >
      <EntryIconCol type={derivedType} />
      <Stack.Item>
        <EntryUnread unread={unread} />
        <EntryContent date={date} heading={heading} content={content} entry_id={entity.id} />
      </Stack.Item>
    </Stack>
  );
};
export default Entry;
