import * as React from 'react';
import { ComponentProps } from '../../../../task-types';
import Dialog from '../../../../../components/Global/Dialog/Dialog';
import { useSteps } from '../../../../../lib/hooks/useSteps';
import LeadReviewModal from '../../../../../components/Game01/LeadReview/LeadReviewModal';
import { useCompleteSimActivity } from '../../../../../lib/hooks/useCompleteSimActivity';

export default function A031({ send, t }: ComponentProps): JSX.Element {
  const { currentStep, gotoNextStep } = useSteps(['reviewIntro', 'reviewCollectedCards', 'reviewUnCollectedCards']);
  const { completeSimActivity, collectionStatus } = useCompleteSimActivity();
  let headingText = '';
  let bodyText = '';

  if (currentStep === 'reviewIntro') {
    return (
      <Dialog
        title={t('A031.introModalHeading')}
        body={t('A031.introModalBody')}
        onClick={gotoNextStep}
        useDark
        character="andrea"
        background="office"
      />
    );
  }

  // dynamically update modal content
  if (collectionStatus?.critical.completed.length && collectionStatus?.critical.completed.length > 0) {
    headingText = t('A031.collectedcardModalHeading');
  } else {
    headingText = t('A031.collectedcardAlternateModalHeading');
  }

  if (collectionStatus?.critical.completed.length && collectionStatus?.critical.completed.length > 0) {
    bodyText = t('A031.collectedcardModalDescription');
  } else {
    bodyText = t('A031.collectedcardAlternateModalDescription');
  }

  const addEvidenceNext = () => {
    completeSimActivity();
    send('NEXT');
  };

  return (
    <>
      <LeadReviewModal
        isOpen={currentStep === 'reviewCollectedCards' || currentStep === 'reviewUnCollectedCards'}
        heading={currentStep === 'reviewCollectedCards' ? headingText : t('A031.uncollectedcardModalHeading')}
        description={currentStep === 'reviewCollectedCards' ? bodyText : t('A031.uncollectedcardDescription')}
        buttonLabel={currentStep === 'reviewCollectedCards' ? t('A031.next') : t('A031.addEvidence')}
        onClick={currentStep === 'reviewCollectedCards' ? gotoNextStep : addEvidenceNext}
        isCollected={currentStep === 'reviewCollectedCards'}
      />
    </>
  );
}
