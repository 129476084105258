import type { LocaleContent, CardOrEntryLocaleContent } from "../../types";

/**
 * i18Next `entries:Episode01.Task14` lead text content
 */
const Task14Lead: LocaleContent = {
  header: "Investigate Tomo’s Device in Sentinel & Defender ",
  role: "Security Operations Analyst",
  time: "10m",
  brief: "We know that Tomo's device was connected to Angel's machine. Now we need to determine if any of Tomo's devices are compromised and more specifically the device used in the RDP Session. Use Microsoft Sentinel to start your investigation. <br><br>-Andrea",
};

/**
 * i18Next `entries:Episode01.Task14` entry content
 */
const Task14Entries: CardOrEntryLocaleContent = {
  TomosSecurityAlerts: {
    heading: "Tomo's Security Alerts",
    default: "A list of security alerts involving CHRO Tomo Takanashi",
    TomoNoAlerts: "Tomo is not involved in any security alerts.",
  },
  TomosDevice: {
    heading: "Tomo's Devices",
    default: "A list of devices used by CHRO Tomo Takanashi.",
    OnlyDevice: "Tomo has only used one device, pc034.",
  },
  pc034sSecurityAlerts: {
    heading: "pc034's Security Alerts",
    default: "A list of security alerts involving the device pc034",
    pc034NoAlerts: "pc034 has no security alerts.",
  },
  pc034sRDPLog: {
    heading: "pc034's RDP Log",
    default: "A list of remote desktop protocol events used to access pc034.",
    pc034RDP: "An RDP event was found on pc034 on 10/29/21 at 1:29:03.120PM",
    PartyTime: "This RDP connection took place during Alex's Birthday Party.",
  },
};

export default {
  ...Task14Lead,
  ...Task14Entries,
};
