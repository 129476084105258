/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useRef, useState } from 'react';
import { IStackStyles, Stack } from '@fluentui/react';
import { MessageInfo, UserInfo } from '../../schemas';
import SessionChatMessage from './SessionChatMessage';
import { IOSocket } from '../../../../../lib/socket/types';

interface ISessionChatDisplayProps {
  users: UserInfo[];
  socket: IOSocket;
  slug: string;
}
function bodyStyles(): IStackStyles {
  return {
    root: {
      padding: '18px 18px 8px',
      overflowY: 'auto',
    },
  };
}

const SessionChatDisplay: React.FC<ISessionChatDisplayProps> = ({ users = [], socket, slug }) => {
  const [messages, setMessages] = useState<MessageInfo[]>([]);
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  function updateMessages(msg: MessageInfo | MessageInfo[]) {
    setMessages((orig) => orig.concat(msg));
    messagesEndRef.current?.scrollIntoView();
  }
  useEffect(() => {
    socket.emit('join:session', { session_slug: slug }, () => {
      setMessages([]); // empty message list
      socket.emit(
        'chat:history',
        {
          room: `session:${slug}`,
        },
        (history: any) => {
          if (history.data) {
            updateMessages(history.data);
          }
        },
      );
    });
    // TODO: Check for race conditions
    // Does this go sideways if they get a chat
    // right before the history emit comes through???
    socket.on('chat', (data: MessageInfo) => {
      if (data.room === `session:${slug}`) {
        updateMessages(data);
      }
    });
    return () => {
      socket.emit('leave:session', {
        session_slug: slug,
      });
      socket.off('chat');
    };
  }, [slug, socket]);
  return (
    <Stack.Item grow styles={bodyStyles()}>
      {messages.map((m, i) => (
        <SessionChatMessage
          key={`cht_m_${m.id}_${i}`}
          user={users.find((u) => u.username === m.username)}
          message={m}
        />
      ))}
      <div ref={messagesEndRef} />
    </Stack.Item>
  );
};

export default SessionChatDisplay;
