import * as React from 'react';
import { Icon as FluentIcon, IIconStyles, mergeStyles } from '@fluentui/react';
import { z } from 'zod';
import { Icons } from '../CustomIconProvider/CustomIconProvider';

interface Props {
  iconName: z.infer<typeof Icons>;
  onClick: () => void;
  label: string;
}

const buttonStyles = mergeStyles({
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
});

const iconStyles: IIconStyles = {
  root: {
    color: 'white',
    backgroundColor: 'transparent',
  },
};

export default function IconButton({ iconName, onClick, label }: Props) {
  return (
    <button type="button" className={buttonStyles} onClick={onClick} aria-label={label}>
      <FluentIcon iconName={iconName} aria-hidden="true" styles={iconStyles} />
    </button>
  );
}
