import React from 'react';
import { useTranslation } from 'react-i18next';
import { mergeStyles, Stack, Text, Theme } from '@fluentui/react';

import type { IPanelHeaderRenderer } from '@fluentui/react';

import { LinkItUrl } from 'react-linkify-it';
import NavPanel from '../NavPanel/NavPanel';
import NavPanelHeader from '../NavPanel/NavPanelHeader';
import { coerceArray, isDev } from '../../../lib/util';
import DebugHelpContent from './DebugHelpContent';
import getFontVariant, { TextComponent } from '../Text/getFontVariant';
import { getCurrentGameTheme } from '../../../theme';

const headerRenderer: IPanelHeaderRenderer = (props, defaultRender, headerTextId?: string) => (
  <NavPanelHeader headerTextId={headerTextId} headerText={props?.headerText} />
);

const p2StyleClass = (theme: Theme) =>
  mergeStyles(
    {
      color: theme.palette.white,
      li: {
        margin: '12px 0',
      },
      a: {
        color: theme.palette.white,
      },
    },
    theme.fonts[getFontVariant(TextComponent.enum.Body, 2)],
  );

const Help: React.FC = () => {
  const { t } = useTranslation('game01Common');
  const theme = getCurrentGameTheme();

  return (
    <NavPanel
      activePanel="help"
      headerText={t('help.header')}
      onRenderHeader={headerRenderer}
      closeButtonAriaLabel={t('help.closeButton')}
    >
      <Stack verticalAlign="start" tokens={{ childrenGap: '8px', padding: '8px' }}>
        <Stack
          tokens={{ childrenGap: '16px', padding: '24px' }}
          styles={{
            root: {
              backgroundColor: theme.palette.themeLight,
              border: '1px solid rgba(255, 255, 255, 0.1)',
              borderRadius: theme.effects.roundedCorner6,
            },
          }}
        >
          <Text className={p2StyleClass(theme)}>{t('help.instructionList.heading')}</Text>
          <ol className={p2StyleClass(theme)}>
            {coerceArray<string>(t('help.instructionList.items', { returnObjects: true })).map((item, i) => (
              <li style={{}} key={`help_item_li_${i}`}>
                <LinkItUrl>{item}</LinkItUrl>
              </li>
            ))}
          </ol>
        </Stack>
        {isDev && <DebugHelpContent />}
      </Stack>
    </NavPanel>
  );
};
export default Help;
