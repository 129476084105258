import { mergeStyles, Stack } from '@fluentui/react';
import * as React from 'react';
import { useVideoJS } from '../../../lib/hooks/useVideoJs';

export interface VideoPlayerProps {
  url: string,
  poster?: string,
  controls?: boolean,
  subtitlesUrl?: string,
  fullscreen?: boolean,
  bigPlayButton?: boolean,
  onEnded?: () => void,
  fullscreenToggle?: boolean,
  progressControl?: boolean,
  pictureInPictureToggle?: boolean,
  fluid?: boolean,
  height?: string,
  width?: string,
  autoplay?: boolean,
  muted?: boolean,
}

export default function VideoPlayer({
  url,
  poster,
  controls,
  subtitlesUrl,
  bigPlayButton = false,
  fullscreenToggle = true,
  progressControl = true,
  pictureInPictureToggle = true,
  fluid = false,
  height = '100vh',
  width = '100%',
  autoplay = false,
  muted = false,
  onEnded,
}: VideoPlayerProps): JSX.Element {
  const { Video } = useVideoJS({
      poster,
      sources: [{ src: url }],
      autoplay,
      controls,
      playbackRates: [0.5, 1, 1.5, 2],
      responsive: true,
      bigPlayButton,
      fluid,
      muted,
      controlBar: {
        fullscreenToggle,
        progressControl,
        pictureInPictureToggle,
      },
  });

  const videoWrapper = mergeStyles({
    width,
    height,
  });

  return (
    <Stack className={videoWrapper}>
      <Video poster={poster} playsInline muted onEnded={onEnded}>
        <track
          src={subtitlesUrl}
          kind="captions"
          srcLang="en"
          label="English"
        />
      </Video>
    </Stack>
  );
}
