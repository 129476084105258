import { ActionFunction, EventObject, State, Typestate } from 'xstate';
import i18n from '../i18n';
import { store } from '../store';
import { selectCurrentLead } from '../store/game-slice';
import { emitUserUpdate, selectCurrentPhase } from '../store/multiplayer-slice';
import { TaskContext, TaskEvent } from '../tasks/task-types';
import { gameService } from './game-service';

const locationsOutsideOfPhase = ['lobby', 'tutorial', 'conclusion', 'summary', 'eventEnded', 'userSuspended'];
const endOfPhase = ['review', 'reviewQuestions'];
/**
 * Default actions in state transitions
 */
export const globalActions = <TContext extends TaskContext = TaskContext, TEvent extends EventObject = TaskEvent>(): {
  type: string;
  exec: ActionFunction<TContext, TEvent>;
}[] => [
  { type: '', exec: () => {} }, // no-op
  {
    type: 'updateLocation',
    exec: (context: any) => {
      // disable the task machine changes since the location in task dose not matter
      if (context.label === 'TaskService') {
        return;
      }
      const storeState = store.getState();
      const currentPhase = selectCurrentPhase(storeState);
      const currentLead = selectCurrentLead(storeState);
      let location = '';
      // chekcing the state of the game for the location
      const stateValue: any = gameService.state.value;
      const leadHeader = currentLead ? i18n.t(`entries:${currentLead.id}.header`) : '';
      if (locationsOutsideOfPhase.findIndex((el) => el === stateValue) > -1) {
        location = i18n.t(`game01Common:location.${stateValue}`);
      } else if (stateValue.hasOwnProperty('investigationPhase') && endOfPhase.findIndex((el) => el === stateValue.investigationPhase) > -1) {
        location = `${currentPhase?.id} : ${i18n.t(`game01Common:location.${stateValue.investigationPhase}`)}`;
      } else if (stateValue.hasOwnProperty('investigationPhase') && stateValue.investigationPhase.hasOwnProperty('play')) {
        // in 'play' state on investigation phase the location is the lead header
        location = `${currentPhase?.id} : ${leadHeader}`;
      } else if (stateValue.hasOwnProperty('investigationPhase') && stateValue.investigationPhase === 'choose') {
        location = `${currentPhase?.id} : Lead Selection`;
      } else if (stateValue.hasOwnProperty('investigationPhase') && !stateValue.investigationPhase.hasOwnProperty('play')) {
        // in other states on invsetigation phase the location is the name of the state (choose, review, etc.)
        location =
          leadHeader !== ''
            ? `${currentPhase?.id} : ${leadHeader} : ${stateValue.investigationPhase}`
            : `${currentPhase?.id} : ${stateValue.investigationPhase}`;
      } else {
        // the location in any other state
        location = `${currentPhase?.id} : ${stateValue}`;
      }
      store.dispatch(emitUserUpdate({ location }));
    },
  },
];

export const executeGlobalActions = <
  TContext extends TaskContext = TaskContext,
  TEvent extends EventObject = TaskEvent,
  TTypestate extends Typestate<TContext> = { value: any; context: TContext },
>(
  state: State<TContext, TEvent, any, TTypestate>,
  label?: Partial<string>,
) => {
  const actions = globalActions<TContext, TEvent>();
  actions.forEach((action) => {
    if (typeof action.exec === 'function') {
      const newState = {
        ...state.context,
        ...gameService.state.context,
        ...state.actions,
        label,
        actionType: action.type,
      };
      action.exec(newState, state.event, state.meta);
    }
  });
};
