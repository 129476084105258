import { mergeStyles, Stack } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../Global/Button/Button';
import { Heading, P } from '../../Global/Text';
import useTutorialScreens from '../../../lib/hooks/useTutorialScreen';
import VideoPlayer from '../../Global/VideoPlayer/VideoPlayer';
import PickDisplayName from './PickDisplayName';
import { useGameService } from '../../../state/GlobalStateProvider';
import useApplyTutorialCards from '../../../lib/hooks/useApplyTutorialCards';
import { selectCurrentEpisode } from '../../../store/multiplayer-slice';
import { useAppSelector } from '../../../lib/hooks/useRedux';

interface ITutorialPhase {
  phase: string;
  noBack?: boolean;
  isVideo?: boolean;
  videoUrl?: string;
  subtitlesUrl?: string;
  backLabel?: string;
  nextLabel?: string;
}

const tutorialPhasesEpisode01: ITutorialPhase[] = [
  {
    phase: 'pickDisplayName',
    noBack: true,
  },
  {
    phase: 'video1',
    isVideo: true,
    videoUrl: 'https://tta-cdn.azureedge.net/videos/who-hacked-ep1-scene1.mp4',
    subtitlesUrl: 'https://tta-cdn.azureedge.net/videos/who-hacked-ep1-scene1-cc.vtt',
    noBack: true,
    nextLabel: 'continueLabel',
  },
  {
    phase: 'tutorial001',
  },
  {
    phase: 'tutorial002',
  },
  {
    phase: 'video2',
    isVideo: true,
    videoUrl: 'https://tta-cdn.azureedge.net/videos/who-hacked-ep1-scene2.mp4',
    subtitlesUrl: 'https://tta-cdn.azureedge.net/videos/who-hacked-ep1-scene2-cc.vtt',
    nextLabel: 'continueLabel',
  },
  {
    phase: 'tutorial003',
  },
  {
    phase: 'tutorial004',
  },
  {
    phase: 'tutorial005',
  },
  {
    phase: 'tutorial006',
  },
  {
    phase: 'tutorial007',
  },
  {
    phase: 'tutorial008',
  },
];
const tutorialPhasesEpisode02: ITutorialPhase[] = [
  {
    phase: 'pickDisplayName',
    noBack: true,
  },
  {
    phase: 'video1',
    isVideo: true,
    videoUrl: 'https://tta-cdn.azureedge.net/videos/who-hacked-ep2.mp4',
    subtitlesUrl: 'https://tta-cdn.azureedge.net/videos/who-hacked-ep2-cc.vtt',
    nextLabel: 'continueLabel',
    noBack: true,
  },
  {
    phase: 'tutorial001',
  },
  {
    phase: 'tutorial002',
  },
  {
    phase: 'tutorial003',
  },
  {
    phase: 'tutorial004',
  },
  {
    phase: 'tutorial005',
  },
  {
    phase: 'tutorial006',
  },
  {
    phase: 'tutorial007',
  },
  {
    phase: 'tutorial008',
  },
];

const headerStyles = mergeStyles({
  height: 'auto',
  padding: '40px 0',
});

const wordWrapper = mergeStyles({
  width: '80%',
});

const imageWrapper = mergeStyles({
  height: '100vh',
});

const skipWrapper = mergeStyles({
  padding: 12,
});

const Tutorial: React.FC = () => {
  const [phaseIndex, setPhaseIndex] = React.useState(0);
  const currentEpisode = useAppSelector(selectCurrentEpisode);
  const tutorialPhases = currentEpisode?.shortId === 'Episode01' ? tutorialPhasesEpisode01 : tutorialPhasesEpisode02;
  const { isVideo, phase } = tutorialPhases[phaseIndex];
  const { t } = useTranslation('tutorial');
  const { send } = useGameService();
  const background = useTutorialScreens(phase);

  const imageStyles = mergeStyles({
    width: '100%',
    height: 'auto',
    overflow: 'hidden',
    right: 0,
    left: 0,
  });

  const pageWrapper = mergeStyles({
    width: '100%',
    background:
      phase === 'pickDisplayName' ? 'linear-gradient(71.98deg, #B3C54D -3.5%, #FCFFE0 58.5%, #F5F7D5 93.6%)' : '',
  });

  const handleNext = () => {
    if (phaseIndex < tutorialPhases.length - 1) setPhaseIndex(phaseIndex + 1);
    else send('DONE.TUTORIAL');
  };

  const handleBack = () => {
    if (phaseIndex > 0) setPhaseIndex(phaseIndex - 1);
  };

  useApplyTutorialCards();

  const phaseProps = tutorialPhases[phaseIndex];
  const phaseLocaleKey = `Game01.${currentEpisode?.shortId}.phases.${phase}`;

  return (
    <Stack className={pageWrapper}>
      {!isVideo && phase !== 'pickDisplayName' && (
        <Stack verticalAlign="stretch" className={headerStyles}>
          <Stack.Item className={wordWrapper} align="center">
            <Stack tokens={{ childrenGap: 16 }}>
              <Stack.Item>
                <Heading level={3}>{t(`${phaseLocaleKey}.title`)}</Heading>
              </Stack.Item>
              <Stack.Item>
                <P level={2}>{t(`${phaseLocaleKey}.body`)}</P>
              </Stack.Item>
              <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: 16 }}>
                {!tutorialPhases[phaseIndex].noBack && (
                  <Stack.Item>
                    <Button isPrimary onClick={handleBack} label={t(`Game01.${phaseProps.backLabel ?? 'backLabel'}`)} />
                  </Stack.Item>
                )}
                <Stack.Item>
                  <Button isPrimary onClick={handleNext} label={t(`Game01.${phaseProps.nextLabel ?? 'nextLabel'}`)} />
                </Stack.Item>
              </Stack>
            </Stack>
          </Stack.Item>
        </Stack>
      )}
      {isVideo && (
        <Stack horizontalAlign="end">
          <VideoPlayer
            url={tutorialPhases[phaseIndex].videoUrl ?? ''}
            subtitlesUrl={tutorialPhases[phaseIndex].subtitlesUrl}
            controls
            height="calc(100vh - 100px)"
            bigPlayButton
          />
          <Stack className={skipWrapper} horizontal tokens={{ childrenGap: 16 }}>
            {!tutorialPhases[phaseIndex].noBack && (
              <Stack.Item>
                <Button isPrimary onClick={handleBack} label={t(`Game01.${phaseProps.backLabel ?? 'backLabel'}`)} />
              </Stack.Item>
            )}
            <Stack.Item>
              <Button isPrimary onClick={handleNext} label={t(`Game01.${phaseProps.nextLabel ?? 'nextLabel'}`)} />
            </Stack.Item>
          </Stack>
        </Stack>
      )}
      {phase === 'pickDisplayName' && (
        <PickDisplayName
          title={t('Game01.pickDisplayName.title')}
          body={t('Game01.pickDisplayName.body')}
          placeholder={t('Game01.pickDisplayName.placeholder')}
          onSubmit={handleNext}
        />
      )}
      {!isVideo && (
        <Stack.Item className={phase === 'pickDisplayName' ? imageWrapper : ''}>
          {!isVideo && phase !== 'pickDisplayName' && <img src={background} alt="Tutorial" className={imageStyles} />}
        </Stack.Item>
      )}
    </Stack>
  );
};

export default Tutorial;
