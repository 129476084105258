import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactFlowProvider } from 'react-flow-renderer';
import MapActivator from './MapActivator';
import MapPanel, { IMapPanel } from './MapPanel';
import MapContent from './MapContent';
import { devLog } from '../../../lib/util';
import { selectCurrentEpisode, selectCurrentGame } from '../../../store/multiplayer-slice';
import { RootState, useAppStore } from '../../../store';
import { useGameServiceSelector } from '../../../state/GlobalStateProvider';

const baseNS = ['game01Common', 'entries'];
const layerHostId = 'evidence-map-content';

function cacheEpisode1EvMapImages(state: RootState) {
  devLog('preloading evidence map images');
  const currentGameId = selectCurrentGame(state);
  const currentEpisodeId = selectCurrentEpisode(state);
  const CARD_IMG_PATH = `./images/${currentGameId?.shortId}/cards/${currentEpisodeId?.shortId}/front/`;
  const CARD_DETAIL_IMG_PATH = `./images/${currentGameId?.shortId}/cards/${currentEpisodeId?.shortId}/back/`;
  const episodeData = selectCurrentEpisode(state);
  const evidenceArray = !episodeData ? [] : episodeData.evidence.filter((e) => e.image || e.detailImage);
  const srcArray: string[] = [];
  evidenceArray.forEach((e) => {
    if (e.image) {
      srcArray.push(`${CARD_IMG_PATH}${e.image}.jpg`);
    }
    if (e.detailImage) {
      srcArray.push(`${CARD_DETAIL_IMG_PATH}${e.detailImage}.jpg`);
    }
  });
  srcArray.forEach((s) => {
    const img = new Image();
    img.src = s;
  });
}

/**
 * The main orchestration component for the Evidence Map.
 */
const EvidenceMap: React.FC = () => {
  const { t } = useTranslation(baseNS);
  const panelRef = React.useRef<IMapPanel>(null);
  const clickHandler = React.useCallback(() => panelRef.current?.toggle(), [panelRef]);
  const hideEvidenceMap = useGameServiceSelector(
    (state) => state.matches('tutorial') || state.matches('summary') || state.matches('lobby') || state.matches('devHome'),
  );
  const state = useAppStore().getState();
  useEffect(() => {
    // should selectively preload based on which episode is being run
    cacheEpisode1EvMapImages(state);
  }, [state]);
  return (
    hideEvidenceMap ? null :
    <div hidden>
      <MapActivator t={t} layerHostId={layerHostId} onClick={clickHandler} />
      <MapPanel t={t} componentRef={panelRef} layerHostId={layerHostId}>
        <ReactFlowProvider>
          <MapContent t={t} />
        </ReactFlowProvider>
      </MapPanel>
    </div>
  );
};

export default EvidenceMap;
