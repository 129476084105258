/**
 * i18next NS: `Episode02.Phase04Review`
 */
 export default {
  dialog: {
    intro: {
      title: "I have a question for you.",
      body: "Take a look at the evidence and let me know what you think. Make sure you have the proof to back it up!",
    },
    final: {
      title: "Continue your investigation.",
      body: "I'm back from lunch and the reporter has moved on to the HR department! I see you discovered who granted access to the malicious app but there's still a lot of questions we need answers to. Keep digging!",
      nextBtn: "Go to Phase 3 set of Leads",
    },
  },
  question1: {
    title: "What actions did Best For You Organics take to ensure that the alert event won't happen again?",
    body: "Discuss this with your team. Select ALL the correct answers, then select Done.",
    optionOne: "Set up insider risk policy",
    optionTwo: "Implemented Microsoft Cloud App Security Broker for GitHub",
    optionThree: "Configured Microsoft Azure Active Directory Identity Protection",
    optionFour: "Enabled app consent analytical rules",
    feedbackTitle: "No, check your Evidence Map.",
    feedbackBody: "Best For You Organics took two protective actions in response to the alert. It implemented Cloud App Security Broker for GitHub and enabled app consent analytical rules in Microsoft Sentinel.",
    feedbackNextLabel: "Next",
  },
  proof: {
    title: "Which resources does eShopIdentity have access to?",
    body: "Discuss this with your team. Select ALL the correct answers, then select Done.",
    optionOne: "bfyosql",
    optionTwo: "bfyofinancevm",
    optionThree: "rgStore",
    optionFour: "bfyofinancecosmos",
    optionFive: "bfyokeyvault",
    feedbackTitle: "No, check your Evidence Map.",
    feedbackBody: "eShopIdentity has access to five resources, three of which are listed here: bfyosql, rgStore, and bfyofinancecosmos.",
    feedbackNextLabel: "Next",
  },
  question2: {
    title: "Next steps.",
    body: "Looking over everything you've found so far, what do you think the next steps should be?",
    optionOne: "Notify our customers their information may have been compromised.",
    optionTwo: "Notify our cyber-insurance provider to start an investigation.",
    optionThree: "We don't know enough yet to notify anyone -- we need to keep investigating.",
  },
  question3: {
    title: "Theory of the crime.",
    body: "Based on the evidence collected so far, what do you think happened?",
    optionOne: "An individual inside the company intentionally attacked.",
    optionTwo: "A group of people inside the company intentionally attacked.",
    optionThree: "An outsider(s) coordinated the attack.",
    optionFour: "No one attacked -- it was a series of mistakes.",
  },
};
