import Wires from './Wires';
import Nodes from './Nodes';

/**
 * Episode01: All task components in a single namespace.
 */

export default {
  ...Wires,
  ...Nodes,
};
