import * as React from 'react';
import Screen from '../../../../../components/Global/Screen/Screen';
import Modal from '../../../../../components/Game01/Modal/Modal';
import { ComponentProps } from '../../../../task-types';
import MultipleSelect, {
  Checkbox,
  useMultipleSelect,
} from '../../../../../components/Global/Interactions/MultipleSelect/MultipleSelect';
import Button from '../../../../../components/Global/Button/Button';

export default function Q05({ t, send, match }: ComponentProps): JSX.Element {
  const { isCorrect, handleCorrect, handleError, noValuesChecked } = useMultipleSelect(3);

  const isFeedback = match('feedback') || match('final');

  const handleSubmit = React.useCallback(() => {
    if (isCorrect || isFeedback) {
      send('NEXT');
    } else {
      send('CLICK.SUBMIT_INCORRECT');
    }
  }, [isCorrect, isFeedback, send]);

  return (
    <Screen>
      <MultipleSelect
        title={isFeedback ? t('Q05.feedback.title') : t('Q05.title')}
        body={isFeedback ? t('Q05.feedback.body') : t('Q05.body')}
        subtitle={isFeedback ? t('Q05.feedback.subtitle') : t('Q05.subtitle')}
        onSubmit={handleSubmit}
        submitDisabled={noValuesChecked}
        useDark
        nextLabel={isFeedback ? t('buttons.ok') : t('buttons.done')}
      >
        <Checkbox isCorrect label={t('Q05.options.1.label')} onChange={handleCorrect} showAnswer={isFeedback} />
        <Checkbox isCorrect label={t('Q05.options.2.label')} onChange={handleCorrect} showAnswer={isFeedback} />
        <Checkbox label={t('Q05.options.3.label')} onChange={handleCorrect} showAnswer={isFeedback} />
        <Checkbox label={t('Q05.options.4.label')} onChange={handleError} showAnswer={isFeedback} />
        <Checkbox label={t('Q05.options.5.label')} onChange={handleError} showAnswer={isFeedback} />
        <Checkbox isCorrect label={t('Q05.options.6.label')} onChange={handleError} showAnswer={isFeedback} />
      </MultipleSelect>
      <Modal
        sidebar="character"
        isOpen={match('incorrect')}
        footer={<Button isPrimary label={t('buttons.again')} onClick={() => send('NEXT')} />}
        onDismiss={() => send('NEXT')}
        title={t('Q05.error.title')}
        body={t('Q05.error.body')}
        hideDismissButton
      />
      {/* <Modal
        isOpen={match('feedback')}
        footer={<Button isPrimary label={t('buttons.ok')} onClick={() => send('NEXT')} />}
        onDismiss={() => send('NEXT')}
        title={t('Q05.feedback.title')}
        body={t('Q05.feedback.body')}
        hideDismissButton
      /> */}
    </Screen>
  );
}
