import { Memoize } from 'typescript-memoize';
import _round from 'lodash/round';

import { GameDataBase } from './game-data-base';
import { EvidenceData } from './evidence-data';
import { PolicyData } from './policy-data';

import type { EpisodeData } from './episode-data';
import {
  EvidenceHybridLeadType,
  EvidenceImportance,
  GameDataInstance,
  GameDataInstanceOf,
  GameDataKind,
  LeadCollectionStatus,
  LeadDefinition,
  LeadFlags,
  LeadType,
} from './types';
import type { JournalEntry } from '../journal/types';
import type { AppliedDataEntity } from '../multiplayer/schemas';

const allImportances: ReadonlyArray<EvidenceImportance> = Object.freeze(['critical', 'bonus', 'irrelevant']);

/**
 * Lead Data
 */
export class LeadData extends GameDataBase {
  readonly kind = 'lead';

  declare readonly parent: EpisodeData | undefined;

  readonly type: LeadType;

  readonly flags: ReadonlySet<LeadFlags>;

  readonly evidence: readonly EvidenceData[] = [];

  readonly policies: readonly PolicyData[] = [];

  readonly baseScore: number;

  readonly hybridCounters: ReadonlySet<EvidenceHybridLeadType>;

  constructor(data: LeadDefinition, parent?: EpisodeData) {
    super(data, parent);

    const { type, flags, evidence, policies, baseScore, hybridCounters } = data;
    this.type = type ?? 'solo';
    this.flags = new Set(flags);
    this.baseScore = baseScore;
    this.hybridCounters = new Set(hybridCounters);

    // Parse evidence definitions
    if (Array.isArray(evidence)) {
      this.evidence = evidence.map((def) => new EvidenceData(def, this));
    } else if (evidence != null) {
      this.evidence = Object.entries(evidence).map(([key, def]) => new EvidenceData({ id: key, ...def }, this));
    }
    Object.freeze(this.evidence);

    // Parse policy definitions
    if (Array.isArray(policies)) {
      this.policies = policies.map((def) => new PolicyData(def, this));
    } else if (policies != null) {
      this.policies = Object.entries(policies).map(([key, def]) => new PolicyData({ id: key, ...def }, this));
    }
    Object.freeze(this.policies);
  }

  /**
   *
   */
  getCollectionStatus<TEntity extends JournalEntry | AppliedDataEntity>(entities: TEntity[]) {
    const output: LeadCollectionStatus<TEntity> = {
      id: this.id,
      completable: false,
      entities: [],
      critical: {
        completed: [],
        collected: [],
        total: [],
        percentCollected: 0,
        percentCompleted: 0,
      },
      bonus: {
        completed: [],
        collected: [],
        total: [],
        percentCollected: 0,
        percentCompleted: 0,
      },
      irrelevant: {
        completed: [],
        collected: [],
        total: [],
        percentCollected: 0,
        percentCompleted: 0,
      },
    };

    // Process facts
    this.evidence.forEach((evidence) => {
      const status = evidence.getCollectionStatus<TEntity>(entities);
      output.entities.push(...status.entities);
      output[evidence.importance].total.push(status);
      if (status.completed) {
        output[evidence.importance].completed.push(status);
      } else if (status.collected) {
        output[evidence.importance].collected.push(status);
      }
    });

    // Calculation collected/completed percentages.
    allImportances.forEach((importance) => {
      if (output[importance].total.length === 0) {
        output[importance].percentCollected = 1;
        output[importance].percentCompleted = 1;
        return;
      }
      output[importance].percentCollected = _round(
        output[importance].collected.length / output[importance].total.length,
        3,
      );
      output[importance].percentCompleted = _round(
        output[importance].completed.length / output[importance].total.length,
        3,
      );
    });

    if (output.critical.percentCompleted >= 1) {
      output.completable = true;
    }

    return output;
  }

  getChildren(): readonly (EvidenceData | PolicyData)[];
  getChildren<TKind extends GameDataKind | undefined>(filter?: TKind): readonly GameDataInstanceOf<TKind>[];
  @Memoize()
  getChildren(filter?: GameDataKind): readonly GameDataInstance[] {
    switch (filter) {
      case undefined:
        return Object.freeze([...this.evidence, ...this.policies]);
      case 'evidence':
        return this.evidence;
      case 'policy':
        return this.policies;
      default:
        return Object.freeze([]);
    }
  }

  /** Get associated PhaseData */
  getPhase() {
    return this.parent?.phases.find((phase) => phase.getLeads().includes(this));
  }
}
