import React from 'react';
import { Stack } from '@fluentui/react';
import type { IStackStyles, IStackTokens } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import Screen from '../../Global/Screen/Screen';
import Panel from '../../Global/Panel/Panel';
import { P, Heading } from '../../Global/Text';
import { useAppSelector } from '../../../store';
import { selectAllUsersConsensus, selectConsensus, validateUserVote } from '../../../store/multiplayer-slice';
import RTrans from '../../Global/RTrans/RTrans';

export interface IWaitingForTeammatesProps {
  consensusKey: string;
}

const contentStackStyles: IStackStyles = {
  root: {},
};
const contentStackTokens: IStackTokens = {
  childrenGap: '8px',
};

const WaitingForTeamates: React.FC<IWaitingForTeammatesProps> = ({ consensusKey }) => {
  const { t } = useTranslation('game01Common', { keyPrefix: 'consensus' });
  const endPhaseConsensus = useAppSelector((state) => selectConsensus(state, consensusKey));
  const { localUserConsensuses, usersWithNoVoteInConsensuses } = useAppSelector(selectAllUsersConsensus);
  const userVote = localUserConsensuses.find((consensus) => consensus.key === consensusKey);
  const userEndedPhase = validateUserVote(userVote);
  const usernamesWithNoVote = usersWithNoVoteInConsensuses
    .find((votes) => votes.key === consensusKey)
    ?.users.map((user) => user.display_name);
  const waitingForUsersText = t('voteStatus', {
    number: endPhaseConsensus?.context.length,
    names: usernamesWithNoVote?.toString(),
  });

  return (
    <Screen>
      <Panel fullscreen useDark justify={userEndedPhase && userVote?.status !== 'completed'}>
        <Stack horizontal horizontalAlign="center" verticalAlign="stretch">
          <Stack styles={contentStackStyles} tokens={contentStackTokens}>
            <Stack horizontal horizontalAlign="space-between" wrap>
              <Stack.Item>
                <Heading level={1} block useDark noMargin>
                  {t('waitingForTeamates')}
                </Heading>
                <P level={2} block noMargin useDark>
                  <RTrans t={t} i18nKey={waitingForUsersText} components={{ bold: <strong />, waitingForUsersText }} />
                </P>
              </Stack.Item>
            </Stack>
          </Stack>
        </Stack>
      </Panel>
    </Screen>
  );
};

export default WaitingForTeamates;
