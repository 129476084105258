/**
 * i18next NS: `Episode02.Task03`
 */
export default {
  common: {
    errorTitle: "Try Again",
    next: "Next",
    submit: "Submit",
    start: "Start",
    skipAhead: "Skip Ahead",
    ok: "OK",
    done: "Done",
  },
  C020: {
    error: {
      1: {
        title: "Try again.",
        body: "No, that's not the right place.",
      },
      2: {
        title: "Try again.",
        body: "That's not right either.",
      },
      3: {
        title: "Go Here!",
        body: "I need you to open this document: ecommerce_appservice_azureresources.docx",
      },
    },
  },
  C030: {
    title: "Here are the Azure resources used in the eCommerce app.",
    body: "Review the diagram closely, then answer some questions.",
  },
  C034: {
    body: "Which resource provides a central location for application secrets, so you don't have to store them in the app's code?",
    labels: {
      AzureStorage: "Azure Storage",
      SQLDatabase: "SQL Database",
      AzureKeyVault: "Azure Key Vault",
    },
    errors: {
      AzureStorage: {
        title: "No, not Azure Storage.",
        body: "An Azure Storage account contains all of your Azure Storage data objects: blobs, file shares, queues, tables, and disks.",
      },
      SQLDatabase: {
        title: "No, not SQL Database.",
        body: "SQL database is a fully managed platform as a service (PaaS) database engine. It provides a highly available and high-performance data storage layer for the applications and solutions in Azure.",
      },
    },
  },
  C036: {
    title: "The eCommerce app has a user-assigned managed identity. What does a managed identity do?",
    subtitle: "",
    body: "Choose your answer, then select DONE.",
    options: {
      1: {
        label: "Redirects HTTP to HTTPs",
      },
      2: {
        label: "Defines a list a list of IP addresses that are allowed to access the app",
      },
      3: {
        label: "Protects back-end resources with authenticated access",
      },
    },
    error: {
      title: "No, that's not it.",
      body: "Here's a hint: Managed identities allow App Service to access resources through role-based access control (RBAC).",
    },
    feedback: {
      title: "A user-assigned managed identity adds an extra layer of protection to the Azure resources.",
      subtitle: "",
      body: "",
    },
  },
  C040: {
    body: "Now that you've reviewed the architecture, which resource should you investigate further to see the possible depth of the breach?",
    labels: {
      WebAppDeploymentSlots: "Web App Deployment Slots",
      UserAssignedManagedIdentity: "User-Assigned Managed Identity",
      AzureStorage: "Azure Storage",
      SQLDatabase: "SQL Database",
      AzureKeyVault: "Azure Key Vault",
    },
    errors: {
      WebAppDeploymentSlots: {
        title: "The web app deployment slots look okay.",
        body: "The eCommerce app includes three Deployment slots - dev, test, and production. We don't need to investigate them right now.",
      },
      AzureStorage: {
        title: "Azure Storage looks okay.",
        body: "We don't need to investigate this right now.",
      },
      SQLDatabase: {
        title: "SQL Database looks okay.",
        body: "We don't need to investigate this right now.",
      },
      AzureKeyVault: {
        title: "Azure Key Vault looks okay.",
        body: "We don't need to investigate this right now.",
      },
    },
    correct: {
      UserAssignedManagedIdentity: {
        title: "Good idea!",
        body: "A user-assigned managed identity allows the web app running in the Azure App Service to securely access resources without requiring credentials. Because the attacker was able to run commands in the web app, they can access the same resources as the user-assigned managed identity.",
      },
    },
  },
  C060: {
    title: "When you investigate the user-assigned managed identity, what should you do first?",
    subtitle: "",
    body: "Choose your answer, then select DONE.",
    options: {
      1: {
        label: "Disable it",
      },
      2: {
        label: "Find out who created it",
      },
      3: {
        label: "Discover what permissions it has",
      },
    },
    error: {
      title: "No. not yet.",
      body: "You should do something else first.",
    },
    feedback: {
      title: "First, check the permissions assigned to the user-assigned managed identify.",
      subtitle: "",
      body: "",
    },
  },
  C070: {
    title: "Let's make sure our infrastructure isn't under attack. ",
    body: "Microsoft Defender for Cloud alerted us in time. No data was compromised. But we should investigate what the user-assigned managed identity has access to. Select the critical evidence to add it to your Journal, then select DONE.",
    evidenceAdded: {
      title: "All set!",
      body: "Evidence added to journal.",
      nextLabel: "OK",
    },
    feedback: {
      title: "You didn't get all the critical information.",
      body: "Add this evidence to your journal.",
    },
    evidence: {
      one: {
        title: "eCommerce app has a user-assigned managed identity.",
        subtitle: "eCommerce app has a user-assigned managed identity",
      },
      two: {
        title: "Name of user-assigned managed identity is \"eShopIdentity.\"",
        subtitle: "Name of user-assigned managed identity is eShopIdentity",
      },
      three: {
        title: "eCommerce app has 3 deployment slots.",
        subtitle: "eCommerce app has 3 deployment slots.",
      },
      four: {
        title: "Names of deployment slots are dev, test, and production.",
        subtitle: "Names of deployment slots are dev, test, and production.",
      },
    },
  },
  C080: {
    title: "Lead complete!",
    body: "You reviewed the eCommerce app deployment architecture and found some critical information.",
    nextLabel: "Back to evidence map",
  },
};
