/* eslint-disable @typescript-eslint/dot-notation, no-underscore-dangle */
import { InterpreterStatus, State } from 'xstate';

import type { EventObject, Interpreter, StateValue, Typestate } from 'xstate';

/**
 * Somewhat-hacky callback to load a new state/context into a running machine without stopping it.
 */
 export function setServiceState<
 TContext,
 TEvent extends EventObject = EventObject,
 TTypestate extends Typestate<TContext> = { value: any; context: TContext },
>(
 service: Interpreter<TContext, any, TEvent, TTypestate>,
 stateValue: StateValue | State<TContext, TEvent, any, TTypestate>,
 context?: TContext | Partial<TContext>,
) {
 const resolvedContext: TContext = { ...service.machine.initialState.context, ...context };
 const newState = State.from<TContext, TEvent>(stateValue, resolvedContext);

 // If the interpreter isn't running, start it with the new state.
 if (service.status !== InterpreterStatus.Running) {
   service.start(newState);
   return;
 }

 // If the interpreter _is_ running, do a hacky end-run around loading state into it.
 // (This is adapted from `Interpreter.start()`)
 const resolvedNewState = service.machine.resolveState(newState);

 // Extract `scheduler` and `update()` private fields from service.
 const serviceScheduler = service['scheduler'];
 const serviceUpdate = service['update'];

 // Flush pending events.
 serviceScheduler.flushEvents();

 // Re-initialize scheduler and update state/context.
 serviceScheduler.initialize(() => {
   // Re-bind this since we're calling the method directly.
   serviceUpdate.call(service, resolvedNewState, resolvedNewState._event);
 });
}
