import type { LocaleContent, CardOrEntryLocaleContent } from "../../types";

/**
 * i18Next `entries:Episode02.Task13` lead text content
 */
const Task13Lead: LocaleContent = {
  header: "Implement Conditional Access",
  role: "Identity Administrator",
  time: "5-10m",
  brief: "Let's add another layer of protection. The first thing I want to protect is the Azure portal itself. Configure conditional access in Azure Active Directory to manage user access risk. <br><br>-Andrea",
};

/**
 * i18Next `entries:Episode02.Task13` entry content
 */
const Task13Entries: CardOrEntryLocaleContent = {
  ConditionalAccessPolicySetup: {
    heading: "Conditional Access Policy Setup",
    AllusersIncluded: "Included: All users",
    BreakGlassAccountSet: "Break Glass Account: BFYO Admin",
    SelectedAppSet: "Selected App: Microsoft Azure Management",
    RequireMFASelected: "Grant Control Selected: Require multi-factor authentication, require device compliance",
    SignInFrequency: "Session: Sign-in frequency 30 days",
  },
  ConditionalAccessPolicyCreated: {
    heading: "Conditional Access Policy Status",
    SuccessfullyCreatedPolicy: "Policy Created: True",
  },
};

export default {
  ...Task13Lead,
  ...Task13Entries,
};
