import React from 'react';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { IStackStyles, IStackTokens, mergeStyles, Stack, Theme } from '@fluentui/react';
import Button from '../../Global/Button/Button';
import { Heading, P } from '../../Global/Text';
import { getCurrentGameTheme } from '../../../theme';
import { gradients } from '../../../theme/Game01/colors';
import { useGameService } from '../../../state/GlobalStateProvider';
import { selectAllAppliedLeads } from '../../../store/multiplayer-slice/applied-data';
import { useAppSelector, useAppStore } from '../../../store';
import type { LeadData } from '../../../lib/game-data/lead-data';
import Background from '../../../static/images/Game01/Episode01/characters/andrea/andrea_full_large.png';
import {
  emitConsensusUpdate,
  emitStartLead,
  getConsensusKeyPredefined,
  handleNewConsensus,
  selectAllUsersConsensus,
  validateUserVote,
} from '../../../store/multiplayer-slice';
import PlayerPersonaLive from '../../Global/PlayerPersona/PlayerPersonaLive';
import { selectAllUsers } from '../../../store/user-slice';
import { ConsensusUpdatePayload } from '../../../lib/multiplayer/schemas';

export interface ILeadPreviewPaneProps {
  lead?: LeadData;
  isComplete: boolean;
  phaseId?: string;
}

const leadPaneStackStyles = (theme: Theme) : IStackStyles => ({
  root: {
    'position': 'relative',
    'background': gradients.primaryLight,
    'borderRadius': '6px',
    'overflow': 'hidden',
    'boxShadow': theme.effects?.elevation8,
    'maxWidth': 916,
    'minHeight': 700,
    '@media screen and (max-width: 1520px)': {
      maxWidth: 550,
    },
  },
});

const leadPaneStackTokens: IStackTokens = {
  // childrenGap: '8px',
  padding: '0 32px 32px 32px',
};

const leadContentStyles: IStackStyles = {
  root: {
    'maxWidth': 450,
    'position': 'relative',
    'zIndex': 2,
    'marginLeft': 350,
    '@media screen and (max-width: 1520px)': {
      marginLeft: 0,
      maxWidth: '100%',
    },
  },
};

const contentFooterStyles: IStackStyles = {
  root: {
    paddingTop: 24,
  },
};

const characterStyles = mergeStyles({
  'position': 'absolute',
  'top': 0,
  'left': 0,
  'backgroundImage': `url(${Background})`,
  'backgroundRepeat': 'no-repeat',
  'backgroundPosition': '-110px bottom',
  'backgroundSize': '130%',
  'height': '100%',
  'width': 380,
  'zIndex': 1,
  '@media screen and (max-width: 1520px)': { display: 'none' },
});

const endInvestigationStyles = mergeStyles({
  'backgroundColor': 'transparent',
  'background': 'none',
  'boxShadow': 'none',
  '@media screen and (min-width: 1520px)': { width: 800 },
});

const LeadPreviewPane: React.FC<ILeadPreviewPaneProps> = ({ lead, isComplete }) => {
  const { send } = useGameService();
  const { t } = useTranslation(['entries', 'game01Common']);
  const theme = getCurrentGameTheme();
  const startedLeads = useAppSelector((state) =>
    state.multiplayer.leads.find((startedLead) => lead && startedLead.leadId === lead.id),
  );
  const username = useAppSelector((state) => state.user.username);
  const dispatch = useDispatch();
  const appStore = useAppStore();
  const { localUser } = useAppSelector(selectAllUsers);
  const { localUserConsensuses } = useAppSelector(selectAllUsersConsensus);

  const endPhaseConsensusKey = useAppSelector((state) => getConsensusKeyPredefined(state, 'end_investigation'));
  const userVote = localUserConsensuses.find((consensus) => consensus.key === endPhaseConsensusKey);
  const userEndedPhase = validateUserVote(userVote);
  const appliedLead = useAppSelector((state) => {
    if (lead == null) {
      return undefined;
    }
    return selectAllAppliedLeads(state).find(({ id }) => id === lead.id);
  });

  const endPhaseCallback = React.useCallback(() => {
    send({ type: 'DONE.INVESTIGATION' });
  }, [send]);

  const handleEndPhase = () => {
    const endPhasePayload: ConsensusUpdatePayload = {
      key: endPhaseConsensusKey,
      singleContext: { participantId: localUser.username, answer: 'end' },
      correctAnswer: 'end',
      action: endPhaseCallback,
    };

    // Todo: This needs to fire the action if there's only a single user!
    // Can't wait for a consensus of one when it requires a button click to advance
    // Also: do these need to be flipped???

    if (userVote) {
      // update if already voted
      handleNewConsensus(appStore, endPhasePayload);
    } else {
      // emit if not voted yet
      dispatch(emitConsensusUpdate({ data: endPhasePayload }));
    }
  };

  if (lead == null || isComplete) {
    return (
      <Stack
        grow={1}
        styles={leadPaneStackStyles(theme)}
        tokens={leadPaneStackTokens}
        className={endInvestigationStyles}
        verticalAlign="center"
        horizontalAlign="center"
      >
        <Stack.Item verticalFill grow>
          <Stack verticalFill verticalAlign="center">
            <Heading level={3} as={2} block useDark center>
              <Trans t={t} i18nKey={isComplete ? 'leadSelection.leadStatus.complete' : 'leadSelection.leadStatus.inProgress'} />
            </Heading>
          </Stack>
        </Stack.Item>
        <Stack.Item align="end">
          <Button
            isPrimary
            label={t('leadSelection.buttonLabels.end')}
            onClick={handleEndPhase}
            disabled={!isComplete || userEndedPhase}
          />
        </Stack.Item>
      </Stack>
    );
  }

  const startLabel = lead.flags.has('protection') ? 'protection' : 'investigation';
  let beginButton = <Stack />;

  // creating the begin investigation button
  if (appliedLead != null) {
    beginButton = (
      <Stack tokens={{ childrenGap: 6 }}>
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 6 }}>
          <Stack.Item>{appliedLead.user && <PlayerPersonaLive username={appliedLead.user} />}</Stack.Item>
          <Stack.Item>
            <P level={3}>{t('leadSelection.completedBy')}</P>
          </Stack.Item>
        </Stack>
      </Stack>
    );
  } else if (startedLeads && startedLeads.ownerId !== username && startedLeads.status !== 'abandoned') {
    beginButton = (
      <Stack tokens={{ childrenGap: 6 }}>
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 6 }}>
          <Stack.Item>
            <PlayerPersonaLive username={startedLeads.ownerId} />
          </Stack.Item>
          <Stack.Item>
            <P level={3}>{t('leadSelection.startedBy')}</P>
          </Stack.Item>
        </Stack>
      </Stack>
    );
  } else {
    beginButton = (
      <Stack verticalAlign="center" tokens={{ childrenGap: 6 }}>
        <Button
          isPrimary
          label={t(`commonEntry.start.${startLabel}`)}
          onClick={
            // eslint-disable-next-line no-confusing-arrow
            () =>
              startedLeads && startedLeads.ownerId === username && startedLeads.status === 'started'
                ? send({ type: 'CHOOSE.LEAD', leadId: lead.id })
                : dispatch(emitStartLead(lead.id))
            // eslint-disable-next-line react/jsx-curly-newline
          }
        />
      </Stack>
    );
  }

  return (
    <Stack grow={1} styles={leadPaneStackStyles(theme)} horizontalAlign="end">
      <div className={characterStyles} />
      <Stack styles={leadContentStyles} verticalFill tokens={leadPaneStackTokens}>
        <Stack.Item>
          <Heading level={1} as={2} block>
            {t(`${lead.id}.header`)}
          </Heading>
        </Stack.Item>
        <Stack.Item>
          <Heading level={4} as={3} block noMargin>
            {t(`commonEntry.activityType.${lead.type}`)}<br />
            {t('commonEntry.leadPreview.role')} {t(`${lead.id}.role`)}<br />
            {t('commonEntry.leadPreview.expectedTime')} {t(`${lead.id}.time`)}
          </Heading>
        </Stack.Item>

        <Stack.Item>
          <P level={3} block>
            {t('commonEntry.leadPreview.brief')}
          </P>
          <P level={3}>
            <Trans t={t} i18nKey={`${lead.id}.brief`} />
          </P>
        </Stack.Item>
        <Stack
          styles={contentFooterStyles}
          horizontalAlign="end"
          verticalFill
          verticalAlign="end"
          tokens={{ childrenGap: 24 }}
        >
          {beginButton}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default LeadPreviewPane;
