export type GlobalErrorCodes = {
  user: {
    'invalid-key': string,
  },
  game: {
    'lead-already-started': string,
    'cards-alread-applied': string,
    'lead-already-completed': string,
  },
};

export const errorCodes: GlobalErrorCodes = {
  user: {
    'invalid-key': '100',
  },
  game: {
    'lead-already-started': '300',
    'lead-already-completed': '310',
    'cards-alread-applied': '400',
  },
};
