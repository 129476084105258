import { createMachine, assign, send } from 'xstate';
import { TaskConfig, TaskOptions, InteractionTaskContext, TaskEvent } from '../../../task-types';
import { addScore, getDefaultOptions } from '../../../task-options';
import { store as reduxStore, getCurrentLeadId } from '../../../../store';

const initialState: InteractionTaskContext = {
  // One tracker for all attempts; will get reset. Consider changing this
  attempts: 0,
  walkthroughStep: 0,
  maxAttempts: 3,
  store: reduxStore,
};

const POINTS_VALUE = 42;
const TASK_ID = 'Game01.Episode01.Task14';

export const Task14Config: TaskConfig<InteractionTaskContext, TaskEvent> = {
  id: 'Task14',
  context: initialState,
  initial: 'A010',
  states: {
    A010: {
      // Desktop Select - 3 chances to click correctly
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          always: {
            target: 'hint',
            cond: (ctx) => ctx.attempts === 3,
          },
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        hint: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          // send fucntion will fire immediately after addScore advancing to the next state
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: { target: '#Task14.A020' },
          },
        },
      },
    },
    A020: {
      // Multi-choice single answer 3 buttons
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        hint: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: {
              target: '#Task14.A030',
            },
          },
        },
      },
    },

    A030: {
      // Interstitial
      entry: 'resetContext',
      on: {
        NEXT: {
          target: '#Task14.A040',
        },
      },
    },

    A040: {
      // Multiple Choice/Single Answer
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.SUBMIT_INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          always: {
            target: 'feedback',
            cond: (ctx) => ctx.attempts >= 2,
          },
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        feedback: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: {
              target: '#Task14.A050',
            },
          },
        },
      },
    },

    A050: {
      // Interstitial
      entry: 'resetContext',
      on: {
        NEXT: {
          target: '#Task14.A060',
        },
      },
    },

    A060: {
      // Multiple Choice/Single Answer
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.SUBMIT_INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          always: {
            target: 'feedback',
            cond: (ctx) => ctx.attempts >= 2,
          },
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        feedback: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: {
              target: '#Task14.A070',
            },
          },
        },
      },
    },

    A070: {
      // Interstitial
      entry: 'resetContext',
      on: {
        NEXT: {
          target: '#Task14.B020',
        },
      },
    },

    B020: {
      // Multi-choice single answer 3 buttons
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        hint: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: {
              target: '#Task14.B030',
            },
          },
        },
      },
    },

    B030: {
      // Interstitial
      entry: 'resetContext',
      on: {
        NEXT: {
          target: '#Task14.B035',
        },
      },
    },

    B035: {
      // Interstitial
      entry: 'resetContext',
      on: {
        NEXT: {
          target: '#Task14.B050',
        },
      },
    },

    B050: {
      // Multiple Choice/Single Answer
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.SUBMIT_INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          always: {
            target: 'feedback',
            cond: (ctx) => ctx.attempts >= 2,
          },
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        feedback: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: {
              target: '#Task14.B070',
            },
          },
        },
      },
    },
    B070: {
      // Interstitial with confirmation
      initial: 'interstitial',
      entry: 'resetContext',
      states: {
        interstitial: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          entry: 'resetContext',
          always: {
            target: '#Task14.B080',
          },
        },
      },
    },
    B080: {
      // Lead Complete
      entry: 'resetContext',
      on: {
        NEXT: {
          target: 'A010',
        },
      },
    },
  },
};

/**
 * Additional machine options.
 */
export const Task14Options: TaskOptions<InteractionTaskContext, TaskEvent> = {
  actions: {
    resetContext: assign({ attempts: () => 0, walkthroughStep: () => 0 }),
    incrementAttempts: assign({ attempts: (context) => context.attempts + 1 }),
    incrementWalkthroughStep: assign({ walkthroughStep: (context) => context.walkthroughStep + 1 }),
    setMaxAttemptsToTwo: assign({
      attempts: (context) => context.attempts,
      walkthroughStep: (context) => context.walkthroughStep,
      maxAttempts: (context) => {
        if (context.maxAttempts) {
          context.maxAttempts -= 1;
        }
        return 2;
      },
    }),
  },
  guards: {},
};

/**
 * Machine constructor.
 */
export function createTask14() {
  return createMachine<InteractionTaskContext, TaskEvent>(Task14Config, getDefaultOptions()).withConfig(Task14Options);
}
