import Storage1 from './010100A';
import Storage2 from './010102';
import Storage3 from './010202';
import Storage4 from './010401';
import Start from './Start';

/**
 * Episode01: All task components in a single namespace.
 */

export default {
  'StartA': Start,
  '010100A': Storage1,
  '010102': Storage2,
  '010202': Storage3,
  '010401': Storage4,
};
