/**
 * Schema validators and types for general multiplayer and multiplayer-adjacent features.
 */
/* eslint-disable @typescript-eslint/no-redeclare */
import { z } from 'zod';
import { ArrayOrSingle } from '../types';

/**
 *
 * SECTION: Applied Data
 *
 */

/** Base schema for applied data entities. */
export const AppliedDataEntityBase = z.object({
  /** The full GameData ID of the applied item. */
  id: z.string().min(1),
  /** Username of the collecting user. */
  user: z.string().nullable(),
  /** The timestamp in milliseconds when the item was applied. */
  date: z.number(),
  /** An optional identifier for the episode, phase, or other segment of the game when the item was applied. */
  phase: z.string().nullish(),
});
export type AppliedDataEntityBase = z.infer<typeof AppliedDataEntityBase>;

/**
 * Schema/type for applied Card entities.
 * These represent Cards applied to the Evidence Map.
 */
export const AppliedCardEntity = AppliedDataEntityBase.extend({
  type: z.literal('card'),
});
export type AppliedCardEntity = z.infer<typeof AppliedCardEntity>;

/**
 * Schema/type for applied Clue entities.
 * These represent Clues applied to Cards on the Evidence Map.
 */
export const AppliedClueEntity = AppliedDataEntityBase.extend({
  type: z.literal('clue'),
  facts: z.array(z.string().min(1)).nullish(),
});
export type AppliedClueEntity = z.infer<typeof AppliedClueEntity>;

/**
 * Schema/type for applied Clue entities.
 * These represent Clues applied to Cards on the Evidence Map.
 */
 export const AppliedLogEntity = AppliedDataEntityBase.extend({
  type: z.literal('log'),
  tries: z.number(),
});
export type AppliedLogEntity = z.infer<typeof AppliedLogEntity>;

/**
 * Schema/type for applied Lead entities.
 * These represent Leads that have been _completed_ in the game.
 */
export const AppliedLeadEntity = AppliedDataEntityBase.extend({
  type: z.literal('lead'),
});
export type AppliedLeadEntity = z.infer<typeof AppliedLeadEntity>;

/**
 * Schema/type for applied Activity entities.
 * These represent Activities that have been _completed_ in the game.
 */
export const AppliedActivityEntity = AppliedDataEntityBase.extend({
  type: z.literal('activity'),
});
export type AppliedActivityEntity = z.infer<typeof AppliedActivityEntity>;

/**
 * Schema/type for applied Question entities.
 * These represent Questions that have been _discovered_ in the game.
 */
export const AppliedQuestionEntity = AppliedDataEntityBase.extend({
  type: z.literal('question'),
});
export type AppliedQuestionEntity = z.infer<typeof AppliedQuestionEntity>;

/** Union of all concrete types for applied data entities. */
export type AppliedDataEntity =
AppliedCardEntity |
AppliedClueEntity |
AppliedLeadEntity |
AppliedActivityEntity |
AppliedQuestionEntity |
AppliedLogEntity;

/** Union of all `type` properties for AppliedDataEntity types. */
export type AppliedDataEntityType = AppliedDataEntity['type'];

export type ApplyDataPayload =
  | ArrayOrSingle<AppliedDataEntity>
  | {
      data: ArrayOrSingle<AppliedDataEntity>;
      options?: {
        overwriteOlder?: boolean;
        forceAll?: boolean;
        fromSocket?: boolean;
        isInitial?: boolean;
      };
    };

/**
 * Consensus schema
 */
export const NILAnswer = 'nilAnswer';
export const NoAnswer = 'noAnswer';

export const ConsensusAnswer = z.array(z.string().or(z.number())).or(z.string().or(z.number()));
export type ConsensusAnswer = z.infer<typeof ConsensusAnswer>;

export const ConsensusCondition = z.object({
  participantIds: z.array(z.string()), // An array of user Ids
  answer: ConsensusAnswer,
});
export type ConsensusCondition = z.infer<typeof ConsensusCondition>;

export const SingleConsensusContext = z.object({
  participantId: z.string(),
  answer: ConsensusAnswer,
  triedAgain: z.boolean(),
}).partial({ triedAgain: true });
export type SingleConsensusContext = z.infer<typeof SingleConsensusContext>;

export const ConsensusContext = z.array(SingleConsensusContext);
export type ConsensusContext = z.infer<typeof ConsensusContext>;

export const ConsensusKeys = z.enum([
  'end_investigation', 'end_phase_review', 'accuse_conclusion', 'accuse_confirm_conclusion', 'proof_conclusion', 'proof_confirm_conclusion',
]);
export type ConsensusKeys = z.infer<typeof ConsensusKeys>;

export const ConsensusStatus = z.enum(['waiting', 'completed']);
export type ConsensusStatus = z.infer<typeof ConsensusStatus>;

export const ConsensusBasics = z.object({
  key: z.string().or(ConsensusKeys),
  // eslint-disable-next-line no-underscore-dangle
  action: z.function(),
  condition: ConsensusCondition,
}).partial({ action: true });
export type ConsensusBasics = z.infer<typeof ConsensusBasics>;

export const Consensus = ConsensusBasics.extend({
  status: ConsensusStatus,
  context: ConsensusContext,
  finalAnswer: ConsensusAnswer,
  tryAgain: z.boolean(),
}).partial({ finalAnswer: true, tryAgain: true });
export type Consensus = z.infer<typeof Consensus>;

export const ConsensusUpdatePayload = ConsensusBasics.extend({
  singleContext: SingleConsensusContext,
  correctAnswer: ConsensusAnswer,
}).partial({ condition: true, correctAnswer: true });
export type ConsensusUpdatePayload = z.infer<typeof ConsensusUpdatePayload>;

export const UserConsensus = ConsensusBasics.extend({
  status: ConsensusStatus,
  singleContext: SingleConsensusContext,
});
export type UserConsensus = z.infer<typeof UserConsensus>;

// score schema
export type MPLeadScore = {

};
