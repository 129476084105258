export interface NavBarButtonConfigElement {
  type: string;
  icon: string;
  activeIcon?: string;
}
export interface NavBarButtonConfig {
  top: NavBarButtonConfigElement[];
  bottom: NavBarButtonConfigElement[];
}

export const navBarButtonConfigJournal: NavBarButtonConfig = {
  top: [
    {
      type: 'journal',
      icon: 'navBarJournal',
    },
    {
      type: 'chat',
      icon: 'navBarChat',
    },
    {
      type: 'help',
      icon: 'navBarHelp',
    },
  ],
  bottom: [
    // {
    //   type: 'settings',
    //   icon: 'navBarSettings',
    // },
  ],
};
export const navBarButtonConfigNotebook: NavBarButtonConfig = {
  top: [
    {
      type: 'notebook',
      icon: 'navBarJournal',
    },
    {
      type: 'chat',
      icon: 'navBarChat',
    },
    {
      type: 'help',
      icon: 'navBarHelp',
    },
  ],
  bottom: [
    // {
    //   type: 'settings',
    //   icon: 'navBarSettings',
    // },
  ],
};
export const navBarButtonConfig = (currentGameId: string): NavBarButtonConfig => {
  // forced override accepted, then game level override, then checks for current game info
  switch (currentGameId) {
    case 'Game01':
      return navBarButtonConfigJournal;
    case 'Game02':
      return navBarButtonConfigNotebook;
    default:
      return navBarButtonConfigJournal;
  }
};
