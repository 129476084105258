import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import LabFrame from '../../../../components/Global/LabFrame/LabFrame';
import { useSimVariableToAddEntryMap } from '../../../../lib/hooks/useSimVariableEntryMap';
import { devLog } from '../../../../lib/util';

import type { LabFrameOnMessage } from '../../../../components/Global/LabFrame/LabFrame';
import type { TSGSEvent } from '../../../types-tsgs';
import { useTaskMachine } from '../../../../lib/hooks/useTaskMachine';
import A015 from './components/A015';
import { createTask07 } from './machine';

const baseNS = 'Game01.Episode01.Task07';
const taskKey = 'Game01.Episode01.Task07';

const labURI = 'https://ctsdemos.azureedge.net/microsoft/SCI_Ep1Task7/index.html';

function createTaskMatch(parent: string, state: any) {
  return (matchState: string) => state.matches(`${parent}.${matchState}`);
}

const Task07: React.FC = () => {
  const [t] = useTranslation(baseNS);
  const dispatch = useDispatch();

  const labVariableEntries = useSimVariableToAddEntryMap(taskKey);
  devLog(labVariableEntries);

  // Setup task machine
  const [state, send] = useTaskMachine(createTask07, {
    taskKey,
  });

  const labMessageHandler = React.useCallback<LabFrameOnMessage<TSGSEvent>>(
    (message, frameWindow) => {
      const { data } = message;
      devLog('labMessageHandler', data, frameWindow);
      if (data.action === 'tsgs.VariableChanged') {
        labVariableEntries[data.key]?.forEach(dispatch);
      }
    },
    [dispatch, labVariableEntries],
  );

  return (
    <>
      {state.matches('A015') && <A015 t={t} send={send} state={state.context} match={createTaskMatch('A015', state)} />}
      {state.matches('A020') && <LabFrame src={labURI} title={t('title')} onMessage={labMessageHandler} />}
    </>
  );
  return <></>;
};

export default Task07;
