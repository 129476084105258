import * as React from 'react';
import Modal from '../../../../../components/Game01/Modal/Modal';
import background from '../images/04A.010.png';
import MultipleChoice, { useMultipleChoice } from '../../../../../components/Global/Interactions/MultipleChoice/MultipleChoice';
import Button from '../../../../../components/Global/Button/Button';
import { ComponentProps } from '../../../../task-types';

export default function A010({ t, send, match }: ComponentProps): JSX.Element {
  const { errorKey, handleIncorrect } = useMultipleChoice(() => send('CLICK.INCORRECT'));

  return (
    <>
      <MultipleChoice
        questionText={t('A010.body')}
        didComplete={match('final')}
        onComplete={() => send('NEXT')}
        backgroundImage={background}
      >
        <Button
          isPrimary
          onClick={() => handleIncorrect(1)}
          label={t('A010.options.1.label')}
          disabled={match('final')}
        />
        <Button
          isPrimary
          onClick={() => handleIncorrect(2)}
          label={t('A010.options.2.label')}
          disabled={match('final')}
        />
        <Button
          isPrimary
          onClick={() => !match('final') && send('NEXT')}
          label={t('A010.options.3.label')}
          icon={match('final') ? 'check' : undefined}
        />
      </MultipleChoice>
      <Modal
        sidebar="character"
        isOpen={match('incorrect')}
        footer={<Button isPrimary label={t('common.errorTitle')} onClick={() => send('NEXT')} />}
        onDismiss={() => send('NEXT')}
        title={t(`A010.options.${errorKey}.error.title`)}
        body={t(`A010.options.${errorKey}.error.body`)}
        hideDismissButton
      />
    </>
  );
}
