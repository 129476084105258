import * as React from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { ComponentProps } from '../../../../task-types';
import Dialog from '../../../../../components/Global/Dialog/Dialog';
import Screen from '../../../../../components/Global/Screen/Screen';
import Button from '../../../../../components/Global/Button/Button';

export default function A050({ t, send }: ComponentProps): JSX.Element {
  return (
    <Screen>
      <Dialog
        title={t('A050.header')}
        onClick={() => {}}
        hideNext
        useDark
        character="andrea"
        background="office"
      >
        <Stack.Item>
          <Stack horizontal tokens={{ childrenGap: 24 }}>
            <Button isPrimary label={t('A050.most')} onClick={() => send('CLICK.MOST')} />
            <Button isPrimary label={t('A050.elevated')} onClick={() => send('CLICK.ELEVATED')} />
            <Button isPrimary label={t('A050.least')} onClick={() => send('NEXT')} />
          </Stack>
        </Stack.Item>
      </Dialog>
    </Screen>
  );
}
