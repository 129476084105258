import { IStackStyles, IStackTokens, mergeStyles, Stack } from '@fluentui/react';
import * as React from 'react';
import { getCurrentGameTheme } from '../../../theme';
import { Heading } from '../../Global/Text';

interface Props {
  items: any[];
  title: string;
  width?: number;
}

const theme = getCurrentGameTheme('Game01');

const outerWrapper: IStackStyles = {
  root: {
    backgroundColor: 'rgba(255,255,255,.1)',
    borderRadius: theme.effects.roundedCorner6,
    borderLeft: '1px solid rgba(255,255,255,.1)',
    borderRight: '1px solid rgba(255,255,255,.1)',
    borderBottom: '2px solid rgba(255,255,255,.3)',
  },
};

const contentStackStyles: IStackStyles = {
  root: {
    overflowY: 'auto',
    overflowX: 'hidden',
    margin: '0px 10px 0 10px',
    backgroundColor: 'rgba(0,0,0,.2)',
    borderRadius: theme.effects.roundedCorner6,
    paddingBottom: 4,
  },
};

const headerStackTokens: IStackTokens = {
  padding: '12px 24px 12px 24px',
};

const contentStackTokens: IStackTokens = {
  padding: 3,
  childrenGap: '8px',
};

const footerStackStyles: IStackStyles = {
  root: {
    padding: 24,
    childrenGap: '8px',
  },
};

export default function Pane({ title, children, items, width = 428 }: React.PropsWithChildren<Props>) {
  const outerWrapperWidth = mergeStyles({
    width,
  });

  return (
    <Stack styles={outerWrapper}>
      <Stack.Item className={outerWrapperWidth}>
        <Stack tokens={headerStackTokens} as="header">
          <Stack.Item>
            <Heading level={2} useDark>
              {title}
            </Heading>
          </Stack.Item>
        </Stack>
        <Stack styles={contentStackStyles} tokens={contentStackTokens}>
          {items}
        </Stack>
        <Stack styles={footerStackStyles} as="footer" verticalAlign="end">
          {children}
        </Stack>
      </Stack.Item>
    </Stack>
  );
}
