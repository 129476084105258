import { z } from 'zod';
import type { XYPosition } from 'react-flow-renderer';

import type { EpisodeData } from './episode-data';
import type { EvidenceData } from './evidence-data';
import type { PhaseData } from './phase-data';
import type { LeadData } from './lead-data';
import type { FactData } from './fact-data';
import type { PolicyData } from './policy-data';
import type { GameData } from './game-data';
import type { ElementData } from './element-data';
import type { ActivityData } from './activity-data';
import { Characters } from '../hooks/useCharacter';

/**
 * Base utility interface ensuring that all GameData has an `id`.
 * (Used for utility types at bottom.)
 */
export interface GameDataDefinitionBase {
  id: string;
}

/**
 * Data definition for a Game
 */
export interface GameDefinition extends GameDataDefinitionBase {
  id: string;
  episodes: EpisodeDefinition[];
}
/**
 * Data definition for an Episode
 */
 export interface EpisodeDataDefinitionBase extends GameDataDefinitionBase {
  id: string;
  phases?: PhaseDefinition[];
  mapPositions?: Record<string, XYPosition>;
}

export interface Game01EpisodeDefinition {
  defaultcardsIDs?: string[];
  defaultclueIDs?: string[];
  leads?: DefinitionCollection<LeadDefinition>;
  activities?: DefinitionCollection<ActivityDefinition>;
  evidence?: DefinitionCollection<EvidenceDefinition>;
  elements?: DefinitionCollection<ElementDefinition>;
  policies?: DefinitionCollection<PolicyDefinition>;
}

export interface Game02EpisodeDefinition {
  activities?: DefinitionCollection<ActivityDefinition>;
  elements?: DefinitionCollection<ElementDefinition>;
  wirePositions?: Record<string, XYPosition[]>;
}

export interface EpisodeDefinition extends
  EpisodeDataDefinitionBase,
  Game01EpisodeDefinition,
  Game02EpisodeDefinition {}
/**
 * Data definition for an Episode Phase
 */
export interface PhaseDefinition extends GameDataDefinitionBase {
  id: string;
  leadIds?: string[];
  activityIds?: string[];
  review: ReviewItemDefinition[];
  reviewBasePointValue: number;
}

/**
 * Data definition for a Lead
 */
export interface LeadDefinition extends GameDataDefinitionBase {
  id: string;
  baseScore: number;
  type?: LeadType; // Default 'solo' in EvidenceData
  flags?: LeadFlags[];
  hybridCounters?: EvidenceHybridLeadType[];
  evidence?: DefinitionCollection<EvidenceDefinition>;
  policies?: DefinitionCollection<PolicyDefinition>;
}

/**
 * Data definition for a Lead
 */
 export interface ActivityDefinition extends GameDataDefinitionBase {
  id: string;
  baseScore: number;
  type?: ActivityType; // Default 'solo' in EvidenceData
  flags?: ActivityFlags[];
  elements?: DefinitionCollection<ElementDefinition>;
}

/**
 * Acceptable Lead types
 */
export type LeadType = 'solo' | 'group' | 'bonus';

/**
 * Acceptable Lead types
 */
 export type ActivityType = 'solo' | 'group' | 'bonus';

/**
 *
 */
export type LeadFlags = 'simulation' | 'protection' | 'narrative' | 'useCompleteSim';

/**
 *
 */
 export type ActivityFlags = 'simulation' | 'protection' | 'narrative' | 'useCompleteSim';

/**
 * Data definition for a Policy
 */
export interface PolicyDefinition extends GameDataDefinitionBase {
  id: string;
}

/**
 * Data definition for a piece of Evidence
 */
export interface EvidenceDefinition extends GameDataDefinitionBase {
  id: string;
  type?: EvidenceType; // Default 'clue' in EvidenceData
  importance?: EvidenceImportance; // Default 'irrelevant' in EvidenceData
  facts?: DefinitionCollection<FactDefinition>;
  locationId?: string;
  linkIds?: string[];
  unlockIds?: string[];
  image?: string;
  detailImage?: string;
  hybridLeadType?: EvidenceHybridLeadType;
}

/**
 * Data definition for a piece of Evidence
 */
 export interface ElementDefinition extends GameDataDefinitionBase {
  id: string;
  type?: ElementType; // Default 'clue' in EvidenceData
  taskId?: string,
  locationId?: string;
  linkIds?: string[];
  unlockIds?: string[];
  discipline?: DisciplineType,
  output?: number,
  capacity?: number,
  powerRange?: number[],
  unlocked?: true,
  hybridActivityType?: HybridActivityType;
  paths?: ElementAssetPath;
}

/**
 * Importance value for Evidence and Evidence states
 */
export type EvidenceImportance = 'critical' | 'bonus' | 'irrelevant';
/**
 * Whether the evidence gets unlocked during the sim or narrative part or non-sim in a hybrid lead
 */
export type EvidenceHybridLeadType = 'sim' | 'nonSim' | 'narrative';
/**
 * Acceptable Evidence types
 */
export type EvidenceType = 'clue' | 'card';

/**
 * Acceptable Element types
 */
export type ElementType = 'node' | 'wire' | 'lock' | 'log' | 'battery' | 'start';

export type DisciplineType = 'storage' | 'model' | 'dashboard' | 'multiple';

export type HybridActivityType = 'sim' | 'nonSim' | 'narrative' | 'hybrid';

export type ElementAssetPath = {
  activate: string,
  complete: string,
  deactivate: string,
};

/**
 * Data definition for Evidence facts.
 */
export interface FactDefinition extends GameDataDefinitionBase {
  id: string;
  importance?: EvidenceImportance;
  linkIds?: string[];
  unlockIds?: string[];
}

/**
 * Rewiew Item Definition. NOTE: Answer is probably not required
 */
export interface ReviewItemDefinition {
  type: ReviewType;
  title: string;
  body: string;
  nextLabel: string;
  answers?: {
    text: string;
    isCorrect: boolean;
    evidenceId?: string;
    target?: number;
  }[];
  feedbackTitle?: string;
  feedbackBody?: string;
  feedbackNextLabel?: string;
  character: Characters;
  scoreMultiplier?: number;
}

export const ReviewTypeEnum = z.enum(['Question', 'Dialog', 'MultipleSelect']);
export type ReviewType = z.infer<typeof ReviewTypeEnum>;
/**
 * An object, where the keys are `id` and values are definition objects which omit `id`.
 */
export type DefinitionRecord<T extends GameDataDefinitionBase> = Record<T['id'], Omit<T, 'id'>>;

/**
 * Union allowing definitions to be provided as an array or a record.
 */
export type DefinitionCollection<T extends GameDataDefinitionBase> = T[] | DefinitionRecord<T>;

/**
 * Any concrete data class.
 * (NOTE: These implement `.kind` as a string literal, for easy discriminated unions)
 */
export type GameDataInstance =
  GameData
  | EpisodeData
  | PhaseData
  | LeadData
  | EvidenceData
  | FactData
  | PolicyData
  | ElementData
  | ActivityData;

/**
 * All the different `kind` literals pf GameDataInstances.
 */
export type GameDataKind = GameDataInstance['kind'];

/**
 * Generic type to select GameDataInstance by GameDataKind
 */
export type GameDataInstanceOf<TKind extends GameDataKind | undefined> = TKind extends undefined
  ? GameDataInstance
  : Extract<GameDataInstance, { kind: TKind }>;

export interface EvidenceCollectionStatus<TEntity> {
  id: string;
  importance: EvidenceImportance;
  collected: boolean;
  completed: boolean;
  entities: TEntity[];
  leadType?: EvidenceHybridLeadType;
  critical: {
    collected: FactData[];
    total: FactData[];
    percent: number;
  };
  bonus: {
    collected: FactData[];
    total: FactData[];
    percent: number;
  };
  irrelevant: {
    collected: FactData[];
    total: FactData[];
    percent: number;
  };
}

export interface LeadCollectionStatus<TEntity> {
  id: string;
  completable: boolean;
  entities: TEntity[];
  critical: {
    completed: EvidenceCollectionStatus<TEntity>[];
    collected: EvidenceCollectionStatus<TEntity>[];
    total: EvidenceCollectionStatus<TEntity>[];
    percentCollected: number;
    percentCompleted: number;
  };
  bonus: {
    completed: EvidenceCollectionStatus<TEntity>[];
    collected: EvidenceCollectionStatus<TEntity>[];
    total: EvidenceCollectionStatus<TEntity>[];
    percentCollected: number;
    percentCompleted: number;
  };
  irrelevant: {
    completed: EvidenceCollectionStatus<TEntity>[];
    collected: EvidenceCollectionStatus<TEntity>[];
    total: EvidenceCollectionStatus<TEntity>[];
    percentCollected: number;
    percentCompleted: number;
  };
}
