import type { LocaleContent, CardOrEntryLocaleContent } from "../../types";

/**
 * i18Next `entries:Episode01.Task03` lead text content
 */
const Task03Lead: LocaleContent = {
  header: "Investigate Wenny’s Access",
  role: "Identity Administrator",
  time: "2-4m",
  brief: "We checked Wenny’s accounts in Microsoft Defender for Endpoint and Azure Active Directory Identity Protection but didn't find anything suspicious—Wenny was not marked as a risky user and there weren't any alerts on her device. But what other resources may be at risk? Use Azure Monitor to determine what else Wenny may recently have had access to. <br><br>-Andrea",
};

/**
 * i18Next `entries:Episode01.Task03` entry content
 */
const Task03Entries: CardOrEntryLocaleContent = {
  // TODO: Update this
  AddDelegatedPermissionGrant: {
    heading: "Add Delegated Permission Grant",
    default: "Operation Name: Add delegated permission grant",
    ActivityDateTimeAddDelegated: "ActivityDateTime: 2021-11-13T23:17:30.432Z",
    AADOperationTypeAddDelegated: "AADOperationType: Assign",
    AdditionalDetailsItem0KeyAddDelegated: "Additional Details item 0 key: User-Agent",
    AdditionalDetailsItem0valueAddDelegated: "Additional Details item 0 value: EvoSTS",
    AdditionalDetailsItem1keyAddDelegated: "Additional Details item 1 key: AppId",
    AdditionalDetailsItem1valueAddDelegated: "Additional Details item 1 value: 00000003-0000-0000-c000-000000000000",
    displayNameAddDelegated: "Display Name: null",
    idAddDelegated: "id: fabfd924-f66a-4d5c-9ff8-b40aae08c39d",
    ipAddressAddDelegated: "IP Address: 52.247.225.21",
    rolesAddDelegated: "Roles: []",
    UserPrincipalNameAddDelegated: "User Principal Name: wenny.gultom@bestforyouorganic.onmicrosoft.com",
    TargetResourcesItem0displayNameAddDelegated: "Target Resources item 0 Display Name: Microsoft Graph",
    TargetResourcesItem0modifiedPropertiesAddDelegated: "DelegatedPermissionGrant.Scope: openid profile email",
    // TargetResourcesItem0modifiedPropertiesAddDelegated: "DelegatedPermissionGrant.ConsentType: Principal",
    TargetResourcesitem0typeAddDelegated: "Target Resources item 0 type: ServicePrincipal",
    TargetResourcesItem1AdministrativeUnitsAddDelegated: "Administrative Units: []",
    TargetResourcesItem1TypeAddDelegated: "Target Resources item 1 type: ServicePrincipal",
  },
  ConsentToApplication: {
    heading: "Consent to Application",
    default: "Operation Name: Consent to application",
    ActivityDateTimeConsent: "ActivityDateTime: 2021-11-13T23:17:30.51Z",
    AADOperationTypeConsent: "AADOperationType: Assign",
    AdditionalDetailsItem0keyConsent: "Additional Details item 0 key: User-Agent",
    AdditionalDetailsItem0valueConsent: "Additional Details item 0 value: EvoSTS",
    AdditionalDetailsItem1keyConsent: "Additional Details item 1 key: AppId",
    AdditionalDetailsItem1valueConsent: "Additional Details item 1 value: 4f1148f3-d4f9-48cb-8dba-9d3aaa6fa4fb",
    displayNameConsent: "Display Name: null",
    idConsent: "id: fabfd924-f66a-4d5c-9ff8-b40aae08c39d",
    ipAddressConsent: "IP Address: 52.247.225.21",
    rolesConsent: "Roles: []",
    UserPrincipalNameConsent: "User Principal Name: wenny.gultom@bestforyouorganic.onmicrosoft.com",
    TargetResourceDisplayNameConsent: "Target Resource Display Name: BFYOVacationTool",
    ModifiedProperties0Consent: "TargetResources modifiedProperties ConsentContent.IsAdminConsent: True",
    ModifiedProperties1Consent: "TargetResources modifiedProperties ConsentContext.IsAppOnly: False",
    ModifiedProperties2Consent: "TargetResources modifiedProperties ConsentContext.OnBehalfOfAll: False",
    ModifiedProperties3Consent: "TargetResources modifiedProperties ConsentContext.Tags: WindowsAzureActiveDirectoryIntegratedApp",
    ModifiedProperties4Consent: "TargetResources modifiedProperties ConsentAction.Permissions: []",
    ModifiedProperties5Consent: "TargetResources modifiedProperties TargetId.ServicePrincipalNames:\"api://4f1148f3-d4f9-48cb-8dba-9d3aaa6fa4fb;4f1148f3-d4f9-48cb-8dba-9d3aaa6fa4fb\"",
  },
  AddAppRoleAssignment: {
    heading: "Add App Role Assignment",
    OperationNameAddApp: "Operation Name: Add app role assignment grant to user",
    ActivityDateTimeAddApp: "ActivityDateTime: 2021-11-13T23:17:30.51Z",
    AADOperationTypeAddApp: "AADOperationType: Assign",
    AdditionalDetailsItem0keyAddApp: "Additional Details item 0 key: User-Agent",
    AdditionalDetailsItem0valueAddApp: "Additional Details item 0 value: EvoSTS",
    AdditionalDetailsItem1keyAddApp: "Additional Details item 1 key: AppId",
    AdditionalDetailsItem1valueAddApp: "Additional Details item 1 value: 4f1148f3-d4f9-48cb-8dba-9d3aaa6fa4fb",
    displayNameAddApp: "Display Name: null",
    idAddApp: "id: fabfd924-f66a-4d5c-9ff8-b40aae08c39d",
    ipAddressAddApp: "IP Address: 52.247.225.21",
    rolesAddApp: "Roles: []",
    UserPrincipalNameAddApp: "Initiated by User Principal Name: wenny.gultom@bestforyouorganic.onmicrosoft.com",
    TargetResourceDisplayNameAddApp: "Target Resource Display Name: BFYOVacationTool",
  },
  SpearPhished: {
    heading: "Spear-Phished",
    WennyAccess: "Wenny Gultom granted access to the OAuth app via a spear-phishing email.",
  },
};

export default {
  ...Task03Lead,
  ...Task03Entries,
};
