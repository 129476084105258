/* eslint-disable jsx-a11y/media-has-caption */
import { mergeStyles } from '@fluentui/react';
import * as React from 'react';
import videojs, { VideoJsPlayerOptions } from 'video.js';
import 'video.js/dist/video-js.css';

export const useVideoJS = (videoJsOptions: VideoJsPlayerOptions, onEnded?: () => void) => {
  const videoNode = React.useRef<string | Element>();
  const player = React.useRef<any>(null);
  const changedKey = '';
  React.useEffect(() => {
    if (videoNode.current != null) player.current = videojs(videoNode.current, videoJsOptions);

    return () => {
      player.current.dispose();
    };
  }, [videoJsOptions, changedKey]);

  const videoStyles = mergeStyles({
    width: '100%',
    height: '100%',
  });

  const Video = React.useCallback(
    ({ children, ...props }) => {
      return (
        <div data-vjs-player key={changedKey}>
          <video ref={videoNode} className={`${videoStyles} video-js  vjs-big-play-centered`} onEnded={onEnded} {...props}>
            {children}
          </video>
        </div>
      );
    },
    [changedKey, onEnded, videoStyles],
  );
  return { Video, player: player.current };
};
