import { combineReducers, Reducer } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';

import type { TypedUseSelectorHook } from 'react-redux';
import type { PersistConfig, PersistorOptions } from 'redux-persist/lib/types';

import { storage } from './persist-storage';
import { gameSlice } from './game-slice';
import { journalSlice } from './journal-slice';
import { userSlice } from './user-slice';
import { multiplayerSlice } from './multiplayer-slice';
import { facilitatorSlice } from './facilitator-slice';
import { sessionSlice } from './session-slice';
import { isDev } from '../lib/util';
import { MPManager } from '../lib/socket';
import { MPManagerForThunk } from '../lib/socket/types';
import { notebookSlice } from './notebook-slice';

/**
 * Explicitly defined type for RootReducer, since we need it for inference
 * and redux-persist fixes.
 */
type RootReducerType = typeof appReducer;

/** Inferred `RootState` from the root reducer. */
export type RootState = RootReducerType extends Reducer<infer TState> ? TState : never;

/** Infered `dispatch()` type from the store itself */
export type AppDispatch = typeof store.dispatch;

/** A useful base ThunkApiConfig for `createAsyncThunk<Returned, ThunkArg, ThunkApiConfig>() */
export type ThunkApiConfig = {
  /** return type for `thunkApi.getState` */
  state: RootState;
  /** type for `thunkApi.dispatch` */
  dispatch: AppDispatch;
  /** type of the `extra` argument for the thunk middleware, which will be passed in as `thunkApi.extra` */
  extra: MPManagerForThunk;
  /** type to be passed into `rejectWithValue`'s first argument that will end up on `rejectedAction.payload` */
  rejectValue?: unknown;
  /** return type of the `serializeError` option callback */
  serializedErrorType?: unknown;
  /** type to be returned from the `getPendingMeta` option callback & merged into `pendingAction.meta` */
  pendingMeta?: unknown;
  /** type to be passed into the second argument of `fulfillWithValue` to finally be merged into `fulfilledAction.meta` */
  fulfilledMeta?: unknown;
  /** type to be passed into the second argument of `rejectWithValue` to finally be merged into `rejectedAction.meta` */
  rejectedMeta?: unknown;
};

/** Export type of the store */
export type StoreType = typeof store;

/** Base Redux app reducer */
const appReducer = combineReducers({
  game: gameSlice.reducer,
  journal: journalSlice.reducer,
  notebook: notebookSlice.reducer,
  user: userSlice.reducer,
  multiplayer: multiplayerSlice.reducer,
  facilitator: facilitatorSlice.reducer,
  session: sessionSlice.reducer,
});

/** Root reducer defined to handle RESET_STORE action. */
const rootReducer: RootReducerType = (state, action) => {
  if (action.type === 'RESET_STORE') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

/** redux-persist configuration options */
export const persistConfig: PersistConfig<RootState> = Object.freeze({
  key: 'MPS',
  storage,
  debug: isDev,
  throttle: 250,
  timeout: 250,
  blacklist: ['multiplayer'],
});

/** persisted rootReducer. Separated to annotate type before passing to `configStore()` */
const persistedRootReducer: RootReducerType = persistReducer(persistConfig, rootReducer);

/** Configured and persisted Redux root store with configured middlware. */
export const store = configureStore({
  reducer: persistedRootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: MPManager.instance,
      },
      // redis-persist requires serializableCheck middleware disabled.
      serializableCheck: false,
    }),
});

// Inject store into MPManager singleton.
MPManager.instance.injectStore(store);

/** redux-persist persistor options */
export const persistorOptions: PersistorOptions = Object.freeze({
  manualPersist: true,
});

/** redux-persist persistor */
export const persistor = persistStore(store, persistorOptions);

/** Typed `useDispatch()`. Use instead of default `useDispatch()` throughout the app. */
export const useAppDispatch = () => useDispatch<AppDispatch>();

/** Typed `useSelector()`. Use instead of default `useSelector()` throughout the app. */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

/** Typed `useStore()`. Use instead of default `useStore()` throughout the app. */
export const useAppStore = () => useStore<RootState>();

export const getCurrentLeadId = (fallback: string = 'noLeadIdFound') => {
  return store.getState().game.currentLeadId || fallback;
};
