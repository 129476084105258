import i18n from '../../../../i18n';
import { ReviewItemDefinition } from '../../../../lib/game-data/types';

export const Phase02Review: ReviewItemDefinition[] = [
  {
    type: 'Dialog',
    title: i18n.t('Game02.Episode01.Phase02Review:dialog.intro.title'),
    body: i18n.t('Game02.Episode01.Phase02Review:dialog.intro.body'),
    nextLabel: i18n.t('common:buttons.letsGo'),
    character: 'suchaow',
  },
  // Narrative Box 1 (Option 1 should go to Box 2a, Option 2 should go to Box 2b)
  {
    type: 'Question',
    title: i18n.t('Game02.Episode01.Phase02Review:question1.title'),
    body: i18n.t('Game02.Episode01.Phase02Review:question1.body'),
    nextLabel: i18n.t('common:buttons.submit'),
    scoreMultiplier: 4,
    answers: [
      {
        text: i18n.t('Game02.Episode01.Phase02Review:question1.optionOne'),
        isCorrect: true,
        target: 2,
      },
      {
        text: i18n.t('Game02.Episode01.Phase02Review:question1.optionTwo'),
        isCorrect: true,
        target: 3,
      },
    ],
    character: 'courtney',
  },
  // Narrative Box 2a (Should go to Box 3)
  {
    type: 'Question',
    title: i18n.t('Game02.Episode01.Phase02Review:question2.title'),
    body: i18n.t('Game02.Episode01.Phase02Review:question2.body'),
    nextLabel: i18n.t('common:buttons.submit'),
    scoreMultiplier: 4,
    answers: [
      {
        text: i18n.t('Game02.Episode01.Phase02Review:question2.optionOne'),
        isCorrect: true,
        target: 4,
      },
      {
        text: i18n.t('Game02.Episode01.Phase02Review:question2.optionTwo'),
        isCorrect: true,
        target: 4,
      },
    ],
    character: 'courtney',
  },
  // Narrative Box 2b (Should go to Box 3)
  {
    type: 'Question',
    title: i18n.t('Game02.Episode01.Phase02Review:question3.title'),
    body: i18n.t('Game02.Episode01.Phase02Review:question3.body'),
    nextLabel: i18n.t('common:buttons.submit'),
    scoreMultiplier: 4,
    answers: [
      {
        text: i18n.t('Game02.Episode01.Phase02Review:question3.optionOne'),
        isCorrect: true,
      },
      {
        text: i18n.t('Game02.Episode01.Phase02Review:question3.optionTwo'),
        isCorrect: true,
      },
    ],
    character: 'courtney',
  },
  // Narrative Box 3
  {
    type: 'Question',
    title: i18n.t('Game02.Episode01.Phase02Review:question4.title'),
    body: i18n.t('Game02.Episode01.Phase02Review:question4.body'),
    nextLabel: i18n.t('common:buttons.submit'),
    scoreMultiplier: 4,
    answers: [
      {
        text: i18n.t('Game02.Episode01.Phase02Review:question4.optionOne'),
        isCorrect: true,
      },
    ],
    character: 'courtney',
  },
];
