import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { shallowEqual } from 'react-redux';
import _pullAll from 'lodash/pullAll';
import _uniq from 'lodash/uniq';

import type { EntityId, EntityState, PayloadAction } from '@reduxjs/toolkit';

import { extractIdsFromEntry, matchesEntry, removeStates, updateEntry } from '../lib/journal/journal-entry';

import { gameData } from '../static/game-data';
import { generateJournalEntryProps, PolicyEntryComponentProps } from '../lib/entries';
import { devLogWarn } from '../lib/util';

import type { ThunkApiConfig } from '.';
import type { ArrayOrSingle } from '../lib/types';
import type {
  JournalEntry,
  JournalEntryQuery,
  JournalEntryQueryOptions,
  JournalEntryUpdate,
  JournalUnreadUpdate,
} from '../lib/journal/types';
import type { JournalEntryComponentProps } from '../lib/entries';
import type { EpisodeData } from '../lib/game-data/episode-data';
import type { LeadData } from '../lib/game-data/lead-data';
import type { EvidenceData } from '../lib/game-data/evidence-data';
import type { FactData } from '../lib/game-data/fact-data';

/**
 * Entity adapter for Journal entries.
 */
export const journalAdapter = createEntityAdapter<JournalEntry>({
  // Sort journal entries by date (descending).
  sortComparer: (a, b) => b.date - a.date,
});

export interface JournalStateInterface {
  entriesSeen: string[];
  entries: ReturnType<typeof journalAdapter.getInitialState>;
}

// the initial game state
const initialState: JournalStateInterface = {
  entriesSeen: [],
  entries: journalAdapter.getInitialState(),
};

/**
 * Selectors for journalAdapter.
 */
export const journalSelectors = journalAdapter.getSelectors();

/**
 * Selector to return all entries that are `evidence` entries.
 */
export const selectAllJournalEntities = createSelector(
  journalSelectors.selectAll,
  (entities) =>
    entities.filter(({ id }) => {
      const { entryId } = extractIdsFromEntry(id);
      return gameData.get(entryId)?.kind === 'evidence';
    }),
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
    },
  },
);

/** */
export const selectAllJournalEntryProps = createSelector(
  selectAllJournalEntities,
  (journalEntities) => generateJournalEntryProps(journalEntities) as JournalEntryComponentProps[],
);

/** */
export const selectAllJournalEntryPropsGrouped = createSelector(selectAllJournalEntryProps, (journalEntryProps) => {
  const entriesGrouped = new Map<EpisodeData | LeadData, JournalEntryComponentProps[]>();
  journalEntryProps.forEach((entryProps) => {
    const baseData = entryProps.baseData;
    if (baseData.kind !== 'lead' && baseData.kind !== 'episode') {
      devLogWarn(
        `selectAllJournalEntryPropsGrouped(): BaseData ${baseData.id} is not an Episode or Lead, skipping ${entryProps.entryData.id}.`,
      );
      return;
    }
    const leadEntries = entriesGrouped.get(baseData) ?? [];
    entriesGrouped.set(baseData, leadEntries);
    leadEntries.push(entryProps);
  });
  // Return a Map with keys sorted alphabetically and Episodes at the bottom.
  return new Map<EpisodeData | LeadData, JournalEntryComponentProps[]>(
    [...entriesGrouped].sort(([keyA], [keyB]) => {
      if (keyA.kind === 'episode' && keyA.kind !== keyB.kind) return 1;
      if (keyB.kind === 'episode' && keyA.kind !== keyB.kind) return -1;
      return keyA.id.localeCompare(keyB.id);
    }),
  );
});

/**
 * Selector to return all entries that are `policy` entries.
 */
export const selectAllPolicyEntities = createSelector(
  journalSelectors.selectAll,
  (entities) =>
    entities.filter(({ id }) => {
      const { entryId } = extractIdsFromEntry(id);
      return gameData.get(entryId)?.kind === 'policy';
    }),
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
    },
  },
);

/** */
export const selectAllPolicyEntryProps = createSelector(
  selectAllPolicyEntities,
  (policyEntities) => generateJournalEntryProps(policyEntities) as PolicyEntryComponentProps[],
);

/**
 * Generate a JournalEntryUpdate payload for a given unlock.
 *
 * @param unlock - The GameData instance for the unlock to generate.
 * @param entryData - The GameData instance from which the unlock originated, or
 *                    which any proxy Card entries should use as a base.
 * @param appliedDataIds - An array of applied entity IDs used to prevent
 *                         duplicate proxy Card entries.
 */
function getJournalEntryUpdateForUnlock(
  unlock: EvidenceData | FactData,
  entryData: EvidenceData,
  appliedDataIds: EntityId[],
): JournalEntryUpdate | undefined {
  /**
   * A Fact unlock will add the associated clue with the fact on it.
   * (If the clue is already added, this will simply add the fact to it.)
   */
  if (unlock.kind === 'fact') {
    const factLocation = unlock.getLocation();
    return factLocation == null
      ? undefined
      : {
          id: factLocation.id,
          states: [unlock.shortId],
        };
  }

  /**
   * A Clue unlock will add the associated clue.
   * (This will do nothing if the clue is already added.)
   */
  if (unlock.type === 'clue') {
    return {
      id: unlock.id,
      states: [],
    };
  }

  /**
   * A Card unlock will add the associated card, but _only_ if it has not yet
   * been applied to the Evidence Map.
   *
   * Also adds a `baseId` prefix to the Card JournalEntry consisting of either:
   *   a) the baseEntry's parent's ID; or b) its own ID (intended as fallback).
   *
   * Since EntryComponentProps assumes that the `baseData` (for grouping) is
   * equivalent to `entryData.parent` if not specified, this will generally
   * ensure that this Card entry's EntryComponentProps.baseData will match that
   * of the entryData (i.e. the Lead they will both sort under.)
   */
  if (unlock.type === 'card') {
    const baseId = entryData.parent?.id ?? entryData.id;
    return appliedDataIds.includes(unlock.id)
      ? undefined
      : {
          id: `${baseId}::${unlock.id}`,
        };
  }
  return undefined;
}

/**
 * Action creator for adding multiple Journal Entries and their associated
 * GameData unlocks.
 *
 * This action uses the Thunk Async API because it provides access to the root
 * Redux state, allowing the logic to access `multiplayer.appliedData` for the
 * purpose of deduplicating proxy Card entries for already-applied cards.
 */
export const addEntriesWithUnlocks = createAsyncThunk<void, ArrayOrSingle<JournalEntryUpdate>, ThunkApiConfig>(
  'journal/addEntriesWithUnlocks',
  (payload, { dispatch, getState }) => {
    const entriesIn = Array.isArray(payload) ? payload : [payload];
    if (entriesIn.length === 0) {
      return;
    }
    const appliedDataIds = getState().multiplayer.appliedData.ids;
    const entriesOut = entriesIn.reduce<JournalEntryUpdate[]>((accumulator, entry) => {
      const entryData = gameData.get(entry.id);
      if (entryData == null) {
        return accumulator;
      }

      // Pass through policy entries (they don't have unlocks).
      if (entryData.kind === 'policy') {
        accumulator.push(entry);
        return accumulator;
      }

      // Bail out if the entry isn't a Card or Clue.
      if (entryData.kind !== 'evidence') {
        return accumulator;
      }

      // Add entry for input Evidence entry (w/ facts).
      accumulator.push(entry);

      // Add unlocks on input Evidence.
      entryData.getUnlocks().forEach((unlock) => {
        const newEntry = getJournalEntryUpdateForUnlock(unlock, entryData, appliedDataIds);
        if (newEntry != null) {
          accumulator.push(newEntry);
        }
      });

      // Add unlocks on directly-added Facts of the input Evidence entry.
      entryData.facts.forEach((fact) => {
        // Skip facts that aren't being added.
        if (!entry.states?.includes(fact.shortId)) {
          return;
        }

        fact.getUnlocks().forEach((unlock) => {
          // Pass in input Evidence entry as the base, so that any proxy entries sort correctly.
          const newEntry = getJournalEntryUpdateForUnlock(unlock, entryData, appliedDataIds);
          if (newEntry != null) {
            accumulator.push(newEntry);
          }
        });
      });
      return accumulator;
    }, []);

    /* eslint-disable-next-line @typescript-eslint/no-use-before-define */
    dispatch(addEntries(entriesOut));
  },
);

/**
 * Journal Slice definition
 */
export const journalSlice = createSlice({
  name: 'journal',
  initialState,
  reducers: {
    addEntry: ({ entries }, { payload }: PayloadAction<JournalEntryUpdate>) => {
      const entity = journalSelectors.selectById(entries, payload.id);
      const newEntity = updateEntry(entity, payload);
      journalAdapter.upsertOne(entries, newEntity);
    },
    addEntries: ({ entries }, { payload }: PayloadAction<ArrayOrSingle<JournalEntryUpdate>>) => {
      (Array.isArray(payload) ? payload : [payload]).forEach((singleUpdate) => {
        const entity = journalSelectors.selectById(entries, singleUpdate.id);
        const newEntity = updateEntry(entity, singleUpdate);
        journalAdapter.upsertOne(entries, newEntity);
      });
    },
    removeEntry: ({ entries }, { payload }: PayloadAction<JournalEntryUpdate>) => {
      const entity = journalSelectors.selectById(entries, payload.id);
      const newEntity = removeStates(entity, payload.states);
      if (newEntity == null) {
        journalAdapter.removeOne(entries, payload.id);
        return;
      }
      journalAdapter.upsertOne(entries, newEntity);
    },
    removeEntries: ({ entries }, { payload }: PayloadAction<ArrayOrSingle<JournalEntryUpdate>>) => {
      (Array.isArray(payload) ? payload : [payload]).forEach((singleUpdate) => {
        const entity = journalSelectors.selectById(entries, singleUpdate.id);
        const newEntity = removeStates(entity, singleUpdate.states);
        if (newEntity == null) {
          journalAdapter.removeOne(entries, singleUpdate.id);
          return;
        }
        journalAdapter.upsertOne(entries, newEntity);
      });
    },
    setEntriesUnread: (state, { payload }: PayloadAction<JournalUnreadUpdate>) => {
      const ids = Array.isArray(payload.ids) ? payload.ids : [payload.ids];
      const unread = payload.unread;
      const updates = ids.map((id: string) => ({
        id,
        changes: {
          unread,
        },
      }));
      journalAdapter.updateMany(state.entries, updates);
      if (unread === false) {
        _pullAll(state.entriesSeen, ids);
      }
    },
    setEntriesSeen: (state, { payload }: PayloadAction<string | string[]>) => {
      const entryIds = Array.isArray(payload) ? payload : [payload];
      state.entriesSeen = _uniq([...state.entriesSeen, ...entryIds]);
    },
  },
});

/**
 * Grouped export for journalSlice actions.
 */
export const actions = Object.freeze({
  addEntriesWithUnlocks,
  ...journalSlice.actions,
});

/**
 * Individual exports for journalSlice actions.
 */
export const { addEntry, addEntries, removeEntry, removeEntries, setEntriesUnread, setEntriesSeen } = actions;

/**
 * Check if an entry exists in the journal, with optional state checks.
 */
export function hasEntry(state: EntityState<JournalEntry>, id: string, options?: JournalEntryQueryOptions): boolean;
export function hasEntry(state: EntityState<JournalEntry>, query: JournalEntryQuery): boolean;
export function hasEntry(
  state: EntityState<JournalEntry>,
  idOrQuery: string | JournalEntryQuery,
  options?: JournalEntryQueryOptions,
): boolean {
  // Process overload parameters
  let id: string;
  let queryOptions: typeof options;
  if (typeof idOrQuery === 'string') {
    id = idOrQuery;
    queryOptions = options;
  } else {
    id = idOrQuery.id;
    queryOptions = idOrQuery;
  }

  const entity = journalSelectors.selectById(state, id);
  return matchesEntry(entity, queryOptions);
}

/**
 * Grouped export for journalSlice query functions.
 */
export const queries = Object.freeze({
  hasEntry,
});
