/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { DefaultButton, IButtonStyles, IStackStyles, Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';
import { Heading } from '../../Text';
import { UserInfo } from '../schemas';
import { RootState, useAppSelector } from '../../../../store';
import SessionListItem from './SessionListItem';
import SessionChat from './SessionChat/SessionChat';
import FacilitatorBroadcastDialog from './FacilitatorBroadcastDialog';
import { toast } from './Toast/ToastManager';
import { getCurrentGameTheme } from '../../../../theme';

const theme = getCurrentGameTheme('Global');

interface SessionListProps {
  users?: UserInfo[];
  showDisconnect: boolean;
  eventStatus: string;
  onDisconnectUser: Function;
  onRemoveUser: Function;
}

const panelStyles: IStackStyles = {
  root: {
    padding: 24,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    borderRadius: 5,
    boxShadow: theme.effects.elevation8,
    overflowY: 'auto',
    h4: {
      marginTop: 0,
      marginBottom: 12,
    },
  },
};

const broadcastStyles: IButtonStyles = {
  root: {
    padding: 5,
    borderWidth: 0,
    fontSize: 14,
    marginLeft: 10,
    color: 'white',
    backgroundColor: theme.palette.themeLight,
  },
  rootHovered: {
    padding: 5,
    borderWidth: 0,
    color: 'white',
    backgroundColor: theme.palette.themeLight,
  },
  rootPressed: {
    padding: 5,
    borderWidth: 0,
    color: 'white',
    backgroundColor: theme.palette.themeDark,
  },
};

function getSessionUsers(sessionSlug: string, users: UserInfo[]): UserInfo[] {
  return users.filter((u) => {
    return u.session_slug === sessionSlug;
  });
}

const SessionListPanel: React.FC<SessionListProps> = (
  { users = [], showDisconnect = false, eventStatus, onDisconnectUser, onRemoveUser },
) => {
  const { t } = useTranslation('common');
  const color = theme.palette.themePrimary;
  const activeSession = useAppSelector((state: RootState) => state.facilitator.activeSession);
  const sessionGroups = useAppSelector((state: RootState) => state.facilitator.sessionGroups);
  const [showBroadcastDialog, { toggle: toggleBroadcastDialog }] = useBoolean(false);
  const routeParams: any = useParams();
  const { facilitator_slug } = routeParams;

  if (eventStatus !== 'started') {
    return (
      <Redirect to={`/facilitator/${facilitator_slug}`} />
    );
  }

  return (
    <Stack horizontal grow verticalFill>
      <Stack grow styles={panelStyles}>
        <Heading level={4} color={color}>
          {t('facilitator.sessions')}
          <DefaultButton
            iconProps={{ iconName: 'OfficeChatSolid' }}
            text="Broadcast"
            styles={broadcastStyles}
            onClick={toggleBroadcastDialog}
          />
        </Heading>
        <Stack.Item>
          {(sessionGroups.map((s) => (
            <SessionListItem
              key={`slpfsli_${s.slug}`}
              session={s}
              active={activeSession?.slug === s.slug}
              users={getSessionUsers(s.slug, users)}
              showDisconnect={showDisconnect}
              onDisconnectUser={onDisconnectUser}
              onRemoveUser={onRemoveUser}
            />
          )))}
        </Stack.Item>
      </Stack>
      {activeSession !== null && (
        <SessionChat
          users={users}
          session={activeSession}
        />
      )}
      {showBroadcastDialog && (
        <FacilitatorBroadcastDialog
          title={t('facilitator.broadcastMessage')}
          subText={t('facilitator.confirmBroadcastText')}
          showDialog={showBroadcastDialog}
          onDismiss={() => toggleBroadcastDialog()}
          onSubmit={
            (status) => {
              if (status === 'success') {
                toast.show({
                  title: 'Success!',
                  content: 'Message broadcasted successfully.',
                  type: 'success',
                  duration: 5000,
                });
              } else {
                toast.show({
                  title: 'Error!',
                  content: 'Error broadcasting message.',
                  type: 'error',
                  duration: 5000,
                });
              }
              toggleBroadcastDialog();
            }
          }
        />
      )}
    </Stack>

  );
};

export default SessionListPanel;
