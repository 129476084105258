import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Text } from '@fluentui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCardAlt } from '@fortawesome/pro-regular-svg-icons';
import { faInfo } from '@fortawesome/pro-solid-svg-icons';

import getFontVariant, { TextComponent } from '../../Global/Text/getFontVariant';
import { getCurrentGameTheme } from '../../../theme';

interface EntryIconColProps {
  type: string;
}
const theme = getCurrentGameTheme('Game01');

const EntryIconCol: React.FC<EntryIconColProps> = ({ type }) => {
  const { t } = useTranslation('game01Common');
  return (
    <Stack>
      <Stack.Item>
        <FontAwesomeIcon
          aria-label={t(`journalEntry.${type}`)}
          icon={type === 'card' ? faIdCardAlt : faInfo}
          style={{ color: theme.palette.white, fontSize: '48px', padding: '8px 0' }}
          fixedWidth
        />
      </Stack.Item>
      <Stack.Item>
        <Text
          variant={getFontVariant(TextComponent.enum.Label, 2)}
          styles={{ root: { color: theme.palette.white, textAlign: 'center' } }}
          block
        >
          {t(`journalEntry.${type}`)}
        </Text>
      </Stack.Item>
    </Stack>
  );
};
export default EntryIconCol;
