import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { setGameThemeOverride } from '../../../store/game-slice';

interface Props {
  children?: React.ReactNode;
  themeId?: string;
}

// HOC for forcing the application of a certain theme globally. Should only be used by top most component
const ForceThemeOverride: React.FC<Props> = ({ children, themeId }) => {
  const gameThemeOverride = useSelector((reduxState: RootState) => reduxState.game.gameThemeOverride);
  const dispatch = useDispatch();

  useEffect(() => {
    // setting to a discrete state in the game machine to disable unwanted functionality
    if (themeId && gameThemeOverride !== themeId) {
      dispatch(setGameThemeOverride(themeId));
    }
    return function cleanup() {
      if (gameThemeOverride !== null) {
        dispatch(setGameThemeOverride(null));
      }
    };
  });

  return <>{children || 'failed to load ForceThemeOverride children'}</>;
};

export default ForceThemeOverride;
