import { Memoize } from 'typescript-memoize';

import { GameDataBase } from './game-data-base';

import type { EvidenceData, EvidenceDataCard, EvidenceDataClue } from './evidence-data';
import type { FactDefinition, EvidenceImportance, GameDataKind, GameDataInstanceOf, GameDataInstance } from './types';
import { devLogWarn } from '../util';

/**
 * Fact Data
 */
export class FactData extends GameDataBase {
  readonly kind = 'fact';

  declare readonly parent: EvidenceData | undefined;

  readonly importance: EvidenceImportance;

  readonly linkIds: readonly string[] = [];

  readonly unlockIds: readonly string[] = [];

  constructor(data: FactDefinition, parent?: EvidenceData) {
    super(data, parent);

    const { importance, linkIds, unlockIds } = data;
    this.importance = importance ?? 'irrelevant';

    // Parse link IDs
    if (Array.isArray(linkIds)) {
      this.linkIds = [...linkIds];
    }
    Object.freeze(this.linkIds);

    // Parse unlock IDs
    if (Array.isArray(unlockIds)) {
      this.unlockIds = [...unlockIds];
    }
    Object.freeze(this.unlockIds);
  }

  getChildren(): readonly FactData[];
  getChildren<TKind extends GameDataKind | undefined>(filter?: TKind): readonly GameDataInstanceOf<TKind>[];
  @Memoize()
  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  getChildren(filter?: GameDataKind): readonly GameDataInstance[] {
    return Object.freeze([]);
  }

  /**
   * Get the Evidence Map location for this instance.
   * A Fact's location is _always_ its parent.
   * This will only return 'clue' type instances.
   */
  getLocation(): EvidenceDataClue | undefined {
    if (this.parent == null) {
      devLogWarn('FactData.getLocation() parent not found', this);
      return undefined;
    }

    if (this.parent.type !== 'clue') {
      devLogWarn('FactData.getLocation() parent is not a Clue', this);
      return undefined;
    }

    return this.parent as EvidenceDataClue;
  }

  /**
   * Get the Evidence Map Card links for this instance.
   * This will only return 'card' type instances.
   */
  @Memoize()
  getLinks(): readonly EvidenceDataCard[] {
    return Object.freeze([
      ...this.linkIds.reduce((accumulator, linkId) => {
        const result = this.find(linkId);
        if (result == null) {
          devLogWarn(`FactData.getLinks() link '${linkId}' not found`, this);
        } else if (result.kind !== 'evidence' || result.type !== 'card') {
          devLogWarn(`FactData.getLinks() link '${linkId}' is not a Card`, this);
        } else {
          accumulator.add(result as EvidenceDataCard);
        }
        return accumulator;
      }, new Set<EvidenceDataCard>()),
    ]);
  }

  /**
   * Get the unlocks for this instance.
   */
  @Memoize()
  getUnlocks() {
    return Object.freeze([
      ...this.unlockIds.reduce((accumulator, unlockId) => {
        const result = this.find(unlockId);
        if (result == null) {
          devLogWarn(`FactData.getUnlocks() unlock '${unlockId}' not found`, this);
        } else if (result.kind !== 'evidence' && result.kind !== 'fact') {
          devLogWarn(`FactData.getUnlocks() unlock '${unlockId}' is not evidence`, this);
        } else {
          accumulator.add(result);
        }
        return accumulator;
      }, new Set<EvidenceData | FactData>()),
    ]);
  }

  /** Get associated EpisodeData */
  getEpisode() {
    return this.getLead()?.parent;
  }

  /** Get associated LeadData */
  getLead() {
    return this.parent?.getLead();
  }

  /** Get associated PhaseData */
  getPhase() {
    return this.getLead()?.getPhase();
  }
}
