/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { useParams } from 'react-router';
import { initFacilitatorAPI } from '../../../lib/api/init';
import LoadGate, { LoadGateOnLoadCallback } from './LoadGate';

const FacilitatorLoadGate: React.FC = ({ children }: React.PropsWithChildren<{}>) => {
  const routeParams: any = useParams();

  const { facilitator_slug } = routeParams;
  const loadHandler = React.useCallback<LoadGateOnLoadCallback>(
    ({ done, setMessages }) => {
      initFacilitatorAPI({
        done,
        setMessages,
        onLoadParams: { facilitator_slug },
      });
    }, [facilitator_slug],
  );
  return (
    <LoadGate onLoad={loadHandler}>
      {children}
    </LoadGate>
  );
};

export default FacilitatorLoadGate;
