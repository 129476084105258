import { Icon, IconButton } from '@fluentui/react';
import React, { useEffect } from 'react';

export interface ToastProps {
  id: string;
  type?: string;
  destroy: () => void;
  title: string;
  content: string;
  duration?: number;
}

const Toast: React.FC<ToastProps> = (props) => {
  const { destroy, content, title, duration = 0, type = 'success' } = props;

  useEffect(() => {
    if (!duration) return;

    const timer = setTimeout(() => {
      destroy();
    }, duration);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timer);
  }, [destroy, duration]);

  return (
    <div>
      <div className="toast-header">
        {type === 'success' && (
          <Icon iconName="Completed" style={{ color: '#57af5c', fontSize: 20 }} />
        )}
        {type === 'error' && (
          <Icon iconName="Error" style={{ color: '#d54d4d', fontSize: 20 }} />
        )}
        {type === 'warning' && (
          <Icon iconName="Warning" style={{ color: '#e2b401', fontSize: 20 }} />
        )}
        <div style={{ marginLeft: 10, flex: 1, fontSize: 18 }}>{title}</div>
        <IconButton iconProps={{ iconName: 'Cancel' }} onClick={destroy} />
      </div>
      <div className="toast-body">{content}</div>
    </div>
  );
};

const shouldRerender = (prevProps: ToastProps, nextProps: ToastProps) => {
  return prevProps.id === nextProps.id;
};

export default React.memo(Toast, shouldRerender);
