/**
 * i18next NS: `Episode01.Task01`
 */
export default {
  common: {
    errorTitle: "Try Again",
    next: "Next",
    submit: "Submit",
    start: "Start",
    skipAhead: "Skip Ahead",
    ok: "OK",
    done: "Done",
  },
  A005: {
    error: {
      1: {
        title: "Try again.",
        body: "That's not the right place.",
      },
      2: {
        title: "Try again.",
        body: "That's not right either.",
      },
      3: {
        title: "Go Here!",
        body: "Start in Microsoft 365 Compliance Center.",
      },
    },
  },
  A010: {
    body: "Here's the Microsoft 365 Compliance Center. What tool do you want to use?",
    labels: {
      contentSearch: "Content Search",
      eDiscovery: "eDiscovery",
      communicationCompliance: "Communication compliance",
    },
    errors: {
      eDiscovery: {
        title: "No, not eDiscovery.",
        body: "Don't go there now. You’re just looking for the file, we aren't ready for this yet.",
      },
      communicationCompliance: {
        title: "No, not Communication Compliance.",
        body: "It might be helpful later, but you should search for the file location first.",
      },
    },
  },
  B010: {
    title: "New Search",
    body: "Select the best parameters to unlock the search results.",
    attempts: "Attempt % of 3",
    subhead: "<0>Search Name</0>: Leaked Q1 Purchasing Data File.",
    placeholder: "Select the correct answer",
    locks: {
      1: {
        label: "Locations",
        options: {
          1: "Exchange mailboxes",
          2: "SharePoint sites",
          3: "Exchange public folders",
        },
        hint: "Let’s break this down. First, search SharePoint to find out where Amari stored the file.",
      },
      2: {
        label: "Keywords",
        options: {
          1: "Purchasing Data Q1",
          2: "Microsoft Excel",
          3: "October 27 2021",
        },
        hint: "Use specific keywords to reduce the number of results.",
      },
      3: {
        label: "Conditions",
        options: {
          1: "Sender / Author",
          2: "Size (in bytes)",
          3: "Retention label",
          4: "Message kind",
        },
        hint: "What other condition will help you narrow the search? Hint: Check your evidence. ",
      },
    },
    error: {
      title: "Take another look at the Brief.",
      body: "You can always find the Brief in your Journal. It should give you the key information you need for this part of the investigation.",
    },
    walkthrough: {
      title: "Incorrect parameters.",
      body: "Hey what’s going on, do you have those search results yet?",
      nextLabel: "No, guide me",
    },
  },
  B050: {
    body: "Review your parameters, then select SUBMIT to begin the search.",
  },
  B060: {
    body: "Your search is underway! Searches can take a little time.",
  },
  C010: {
    body: "The search is complete! Now what?",
    options: {
      1: { label: "Export results" },
      2: {
        label: "Run reports",
        error: {
          title: "Wrong Way",
          body: "Don’t leave - stay in the content search area.",
          nextLabel: "Go To Permissions",
        },
      },
    },
    success: {
      title: "Good Call",
      body: "Your permissions haven’t been set up yet.",
      nextLabel: "Go To Permissions",
    },
  },
  C020a: {
    title: "What permissions do you need to export the Content Search results?",
    body: "Choose all the required permissions, then select DONE.",
    options: {
      1: {
        label: "Sharepoint Administrator",
      },
      2: {
        label: "Content Explorer List View",
      },
      3: {
        label: "Content Explorer Content View",
      },
      4: {
        label: "Global Reader",
      },
    },
    error: {
      title: "That’s not the right set of permissions.",
      body: "You need different roles to export those results.",
    },
    feedback: {
      title: "Here are the required permissions.",
      body: "The checked permissions are required to export the search results.",
    },
  },
  C030: {
    body: "Permissions set. Now you can export the Content Search results.",
    nextLabel: "Export Results",
  },
  C040a: {
    title: "Here are the exported search results.",
    body: "Select the key evidence to add it to your Journal, then select DONE.",
    evidenceAdded: {
      title: "All set!",
      body: "Evidence added to journal.",
      nextLabel: "OK",
    },
    feedback: {
      title: "You didn't get all the critical information.",
      body: "Add this evidence to your journal.",
    },
    evidence: {
      one: {
        title: "Target Path:",
        subtitle: "SharePoint\\amari_rivera_bestforyouorganic_onmicrosoft_com\\Documents\\Technology\\Purchasing Data Q1 Notes.docx",
      },
      two: {
        title: "Target Path:",
        subtitle: "SharePoint\\sites\\Technology\\Shared Documents\\Purchasing Data Q1 Notes.docx",
      },
      three: {
        title: "Target Path:",
        subtitle: "SharePoint\\Amari Rivera.zip\\amari_rivera_bestforyouorganic_onmicrosoft_com\\Documents\\Excel data files\\BFYO Purchasing Data - Q1.xlsx",
      },
      four: {
        title: "Target Path:",
        subtitle: "SharePoint\\amari_rivera_bestforyouorganic_onmicrosoft_com\\Attachments\\BFYO Q1 Purchasing Data Request.docx",
      },
    },
  },
  C050: {
    title: "Lead complete!",
    body: "You searched for the leaked file. Now it's time to put the information you found on the Evidence Map.",
    nextLabel: "Back to evidence map",
  },
};
