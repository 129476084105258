import * as React from 'react';

export default function useActivityFocus() {
  const focusRef = React.useRef<HTMLSpanElement | null>(null);

  React.useEffect(() => {
    // focus on heading
    if (focusRef.current) {
      focusRef.current.focus();
    }
  }, []);

  return {
    focusRef,
  };
}
