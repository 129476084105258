import * as React from 'react';
import { IStackStyles, IStackTokens, Stack } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

import { Swiper } from 'swiper/types';
import Panel from '../../Global/Panel/Panel';
import Screen from '../../Global/Screen/Screen';
import { Heading, P } from '../../Global/Text';
import CardPanel from '../CardPanel/CardPanel';
import EvidencePanel from '../EvidencePanel/EvidencePanel';
import { gameData } from '../../../static/game-data';
import Modal from '../Modal/Modal';
import { useAppSelector } from '../../../lib/hooks/useRedux';
import { selectAllJournalEntryProps, selectAllJournalEntryPropsGrouped } from '../../../store/journal-slice';
import { selectAllAppliedCardIds, selectAllAppliedClueIds } from '../../../store/multiplayer-slice/applied-data';
import { useAppDispatch } from '../../../store';
import { selectCurrentLead } from '../../../store/game-slice';
import { applyData, selectCurrentPhase } from '../../../store/multiplayer-slice';
import Button from '../../Global/Button/Button';
import { devLogErr } from '../../../lib/util';
import type { EvidenceData } from '../../../lib/game-data/evidence-data';
import type { AppliedDataEntityBase } from '../../../lib/multiplayer/schemas';

interface Props {
  cardId?: string;
}

interface CompletionState {
  isComplete: boolean;
  isCritical: boolean;
}

const contentStackStyles: IStackStyles = {
  root: {},
};
const contentStackTokens: IStackTokens = {
  childrenGap: '24px 16px',
};

export default function ApplyEvidence({ cardId }: Props) {
  const [showIntro, setShowIntro] = React.useState(true);
  const [swiper, setSwiper] = React.useState<Swiper | undefined>(undefined);

  const { t } = useTranslation(['entries', 'game01Common', 'common']);
  const dispatch = useAppDispatch();

  // Get the current lead path array
  const currentLead = React.useRef(useAppSelector(selectCurrentLead));
  const currentPhase = useAppSelector(selectCurrentPhase);
  const currentUsername = useAppSelector(({ user }) => user.username);

  // Grabbing epi/lead/phase
  const phaseId = currentPhase?.id;

  // Grabbing all entries, regardless of lead
  const journalEntryProps = useAppSelector(({ journal }) => selectAllJournalEntryProps(journal.entries));
  const journalEntryPropsGrouped = useAppSelector(({ journal }) => selectAllJournalEntryPropsGrouped(journal.entries));
  const journalEntryPropsForCurrent =
    (currentLead.current == null ? null : journalEntryPropsGrouped.get(currentLead.current))?.sort((a, b) => {
      // sorting cards ahead of clues
      const aVal = a.derivedType === 'card' ? 0 : 1;
      const bVal = b.derivedType === 'card' ? 0 : 1;
      if (aVal < bVal) {
        return -1;
      }
      if (aVal > bVal) {
        return 1;
      }
      return 0;
    }) ?? [];

  const isProtection = currentLead.current?.flags.has('protection');
  const appliedCardIds = useAppSelector(selectAllAppliedCardIds);
  const appliedClueIds = useAppSelector(selectAllAppliedClueIds);

  const [selectedCardId, setSelectedCardId] = React.useState<string | undefined>(cardId ?? appliedCardIds[0]);

  // Has all critical evidence been applied
  const completionStatus = React.useMemo(() => {
    return journalEntryProps.reduce((acc: { [key: string]: CompletionState }, entryProps) => {
      acc[entryProps.entryData.id] = {
        isComplete:
          appliedCardIds.includes(entryProps.entryData.id) || appliedClueIds.includes(entryProps.entryData.id),
        isCritical: (entryProps.entryData as EvidenceData).importance === 'critical',
      };

      return acc;
    }, {});
  }, [journalEntryProps, appliedCardIds, appliedClueIds]);

  const canComplete = Object.values(completionStatus)
    .filter((item) => item.isCritical)
    .every((item) => item.isComplete);
  // console.log('asdasda', completionStatus);

  const [isEvidenceWarningOpen, setIsEvidenceWarningOpen] = React.useState(false);

  const applyEvidence = React.useCallback(
    (evidenceId: string, facts: string[]) => {
      const evidenceInstance = gameData.get(evidenceId, 'evidence');
      if (evidenceInstance == null) {
        devLogErr('Evidence not found:', evidenceId);
        return;
      }
      const location = evidenceInstance?.getLocation();

      /**
       * If the location doesn't equal:
       *   a) itself (e.g. a Card); or
       *   b) the selected Card (e.g. a clue for that card)
       * then it is not valid to apply.
       */
      if (location !== evidenceInstance && location?.id !== selectedCardId) {
        setIsEvidenceWarningOpen(true);
        return;
      }

      // Common applyData payload for Cards and Clues.
      const basePayload: AppliedDataEntityBase = {
        id: evidenceInstance.id,
        user: currentUsername,
        date: Date.now(),
        phase: phaseId,
      };

      if (evidenceInstance.isCard()) {
        // Apply Card and set as selected in Card view.
        dispatch(applyData({ type: 'card', ...basePayload }));
        setSelectedCardId(evidenceInstance.id);
        // need to delay one frame so card can be added
        setTimeout(() => {
          swiper?.slideTo(swiper.slides.length - 1, 300);
        });
      } else if (evidenceInstance.isClue()) {
        // Apply Clue (with facts)
        dispatch(applyData({ type: 'clue', ...basePayload, facts }));
      }
    },
    [selectedCardId, phaseId, currentUsername, dispatch, setSelectedCardId, swiper],
  );

  return (
    <Screen>
      <Panel fullscreen useDark>
        <Stack>
          <Stack styles={contentStackStyles} tokens={contentStackTokens}>
            <Stack.Item>
              <Heading level={1} useDark>
                {isProtection ? t('applyEvidence.protectionTitle') : `${t('applyEvidence.title')}${t(`${currentLead.current?.id}.header`)}`}
              </Heading>
            </Stack.Item>
            <Stack.Item>
              <P level={2} useDark>
                {isProtection ? t('applyEvidence.protectionBody') : t('applyEvidence.body')}
              </P>
            </Stack.Item>
            <Stack horizontal verticalAlign="stretch" tokens={contentStackTokens}>
              <CardPanel
                selectCard={setSelectedCardId}
                selectedCardId={selectedCardId}
                onSwiper={(s: Swiper) => setSwiper(s)}
              />
              <EvidencePanel
                evidence={journalEntryPropsForCurrent}
                completionStatus={completionStatus}
                applyEvidence={applyEvidence}
                canComplete={canComplete}
              />
            </Stack>
          </Stack>
        </Stack>
      </Panel>
      <Modal
        sidebar="character"
        title={t('applyEvidence.incorrectSelectionError.title')}
        body={t('applyEvidence.incorrectSelectionError.body')}
        isOpen={isEvidenceWarningOpen}
        footer={
          <Button
            isPrimary
            label={t('buttons.again')}
            onClick={() => {
              setIsEvidenceWarningOpen(false);
            }}
          />
        }
      />

      <Modal
        sidebar="character"
        title={t('applyEvidence.intro.title')}
        isOpen={showIntro && !isProtection}
        hideDismissButton
        body={t('applyEvidence.intro.body')}
        footer={
          <Button
            isPrimary
            label={t('buttons.begin')}
            onClick={() => {
              setShowIntro(false);
            }}
          />
        }
      />
    </Screen>
  );
}
