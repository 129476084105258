import * as React from 'react';
import { Text as FluentText } from '@fluentui/react/lib/Text';
import getFontVariant, { TextComponent } from './getFontVariant';
import TextProps from './interface';
import { getCurrentGameTheme } from '../../../theme';

interface Props extends TextProps {
  level: 1 | 2 | 3 | 4 | 5;
  color?: string;
}

export default function Div({
  children,
  level,
  block = false,
  nowrap = false,
  noMargin = false,
  useDark = false,
  center = false,
  color,
}: React.PropsWithChildren<Props>): JSX.Element {
  const theme = getCurrentGameTheme();
  return (
    <FluentText
      as="div"
      block={block}
      nowrap={nowrap}
      variant={getFontVariant(TextComponent.enum.Body, level)}
      style={{
        ...(noMargin && { margin: 0 }),
        ...(useDark && { color: theme.palette.white }),
        ...(center && { textAlign: 'center' }),
        ...(color && { color }),
      }}
    >
      {children}
    </FluentText>
  );
}
