import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTaskMachine } from '../../../../lib/hooks/useTaskMachine';
import A005 from './components/A005';
import A010 from './components/A010';
import A020 from './components/A020';
import A030 from './components/A030';
import A040 from './components/A040';
import A060 from './components/A060';
import B010 from './components/B010';
import B050 from './components/B050';
import C010 from './components/C010';
import C020 from './components/C020';
import C030 from './components/C030';
import { createTask01 } from './machine';

const baseNS = 'Game01.Episode01.Task04';
const taskKey = 'Game01.Episode01.Task04';

// Todo: Refactor this so it's less cumbersome to get a scoped match function
function createTaskMatch(parent: string, state: any) {
  return (matchState: string) => state.matches(`${parent}.${matchState}`);
}

const Task04: React.FC = () => {
  const { t } = useTranslation([baseNS, 'common']);

  // Setup task machine
  const [state, send] = useTaskMachine(createTask01, {
    taskKey,
  });

  return (
    <>
      {state.matches('A005') && <A005 t={t} send={send} state={state.context} match={createTaskMatch('A005', state)} />}
      {state.matches('A010') && <A010 t={t} send={send} state={state.context} match={createTaskMatch('A010', state)} />}
      {state.matches('A020') && <A020 t={t} send={send} state={state.context} match={createTaskMatch('A020', state)} />}
      {state.matches('A030') && <A030 t={t} send={send} state={state.context} match={createTaskMatch('A030', state)} />}
      {state.matches('A040') && <A040 t={t} send={send} state={state.context} match={createTaskMatch('A040', state)} />}
      {state.matches('A060') && <A060 t={t} send={send} state={state.context} match={createTaskMatch('A060', state)} />}
      {state.matches('B010') && <B010 t={t} send={send} state={state.context} match={createTaskMatch('B010', state)} />}
      {state.matches('B050') && <B050 t={t} send={send} state={state.context} match={createTaskMatch('B050', state)} />}
      {/* {state.matches('B070') && <B070 t={t} send={send} state={state.context} match={createTaskMatch('B070', state)} />} */}
      {state.matches('C010') && <C010 t={t} send={send} state={state.context} match={createTaskMatch('C010', state)} />}
      {state.matches('C020') && <C020 t={t} send={send} state={state.context} match={createTaskMatch('C020', state)} />}
      {state.matches('C030') && <C030 t={t} send={send} state={state.context} match={createTaskMatch('C030', state)} />}
    </>
  );
};

export default Task04;
