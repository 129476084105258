import React from 'react';

import { useGameService } from '../../state/GlobalStateProvider';
import { GameContext, GameEvent } from '../../state/game-service';
import { getMatcherResults, StateMatcher } from '../../lib/xstate/matchers';
import { getDefaultStateMatcherOptions, getDefaultStateMatchers } from '../../state/defaultStateMatchers';
import { useAppDispatch } from '../../store';
import { setCurrentPhase } from '../../store/multiplayer-slice';
import useApplyTutorialCards from '../../lib/hooks/useApplyTutorialCards';

// Generate the list of content to match on once.
// NOTE: We're using the same basic matchers and options as normal `GameContent`
const stateMatchers: StateMatcher<GameContext, GameEvent>[] = [
  ...getDefaultStateMatchers(),
  {
    type: 'fallback',
    content: (state) => <>Nothing matched (State(s): {state.toStrings().join('; ')}).</>,
  },
];
const stateMatcherOptions = getDefaultStateMatcherOptions();

const Game01Episode01Phase01Test: React.FC = () => {
  const dispatch = useAppDispatch();
  const { state, setState } = useGameService();
  useApplyTutorialCards(true);

  // Jump to Choose Lead in Episode01.Phase01
  React.useEffect(() => {
    dispatch(setCurrentPhase('Game01.Episode01.Phase01'));
    setState({ investigationPhase: 'choose' }, {});
  }, [dispatch, setState]);

  // Generate matched content to display every time the state or matchers change.
  const matchedContent = React.useMemo(() => getMatcherResults(state, stateMatchers, stateMatcherOptions), [state]);

  return <>{matchedContent}</>;
};

export default Game01Episode01Phase01Test;
