/* eslint-disable max-len */
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import NavPanel from '../NavPanel/NavPanel';
import PanelUnavailable from '../NavPanelUnavailable/NavPanelUnavailable';
import { clearLocalSession } from '../../../store/local-session';

const Settings: React.FC = () => {
  const { t } = useTranslation('game01Common');
  const history = useHistory();

  const [isLogoutDialogHidden, { setTrue: hideLogoutDialog, toggle: toggleLogoutDialog }] = useBoolean(true);
  const logout = useCallback(() => {
    hideLogoutDialog();
    clearLocalSession({ full: true });
    history.push('/');
  }, [hideLogoutDialog, history]);

  return (
    <NavPanel activePanel="settings" headerText={t('settings.header')}>
      <Stack tokens={{ childrenGap: '16px', padding: '24px' }}>
        <Stack.Item>
          <PrimaryButton iconProps={{ iconName: 'signout' }} onClick={toggleLogoutDialog}>
            Logout
          </PrimaryButton>
        </Stack.Item>
      </Stack>
      <PanelUnavailable />
      <Dialog
        hidden={isLogoutDialogHidden}
        onDismiss={toggleLogoutDialog}
        dialogContentProps={{
          type: DialogType.close,
          title: t('settings.confirmationDialog.title'),
          subText: t('settings.confirmationDialog.body'),
        }}
      >
        <DialogFooter>
          <PrimaryButton onClick={logout} text={t('settings.confirmationDialog.confirmButton')} />
          <DefaultButton onClick={toggleLogoutDialog} text={t('settings.confirmationDialog.cancelButton')} />
        </DialogFooter>
      </Dialog>
    </NavPanel>
  );
};
export default Settings;
