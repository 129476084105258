import * as React from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { ComponentProps } from '../../../../task-types';
import Dialog from '../../../../../components/Global/Dialog/Dialog';
import Screen from '../../../../../components/Global/Screen/Screen';
import Button from '../../../../../components/Global/Button/Button';
import { useMultipleChoice } from '../../../../../components/Global/Interactions/MultipleChoice/MultipleChoice';
import Modal from '../../../../../components/Game01/Modal/Modal';

export default function A100({ t, send, match }: ComponentProps): JSX.Element {
  const { errorKey, handleIncorrect } = useMultipleChoice(() => send('CLICK.INCORRECT'));
  return (
    <Screen>
      <Dialog
        title={t('A100.header')}
        onClick={() => {}}
        hideNext
        useDark
        character="andrea"
        background="office"
      >
        <Stack.Item>
          <Stack tokens={{ childrenGap: 10 }}>
            <Button isPrimary label={t('A100.options.1')} onClick={() => handleIncorrect('1')} />
            <Button isPrimary label={t('A100.options.2')} onClick={() => send('NEXT')} />
            <Button isPrimary label={t('A100.options.3')} onClick={() => handleIncorrect('3')} />
          </Stack>
        </Stack.Item>
      </Dialog>
      <Modal
        sidebar="character"
        title={t(`A100.error.${errorKey}.title`)}
        body={t(`A100.error.${errorKey}.body`)}
        isOpen={match('incorrect')}
        footer={<Button isPrimary label={t('common.errorTitle')} onClick={() => send('NEXT')} />}
        onDismiss={() => send('NEXT')}
        hideDismissButton
      />
    </Screen>
  );
}
