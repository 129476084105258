import { IPalette, ISemanticColors } from '@fluentui/react';
import { IGradients } from './gradients';

export const greyScale = {
  1: '#ffffff',
  2: '#f2f2f2',
  3: '#e6e6e6',
  4: '#d2d2d2',
  5: '#737373',
  6: '#505050',
  7: '#2f2f2f',
  8: '#000000',
};

export const gradients: Partial<IGradients> = {
  primaryLight: 'linear-gradient(45deg, #597ab4 0%, #ffffff 50%)',
  primaryDark: 'linear-gradient(45deg, #1f184d 0%, #124297 100%)',
  primaryLightReversed: 'linear-gradient(45deg, #ffffff 50%, #597ab4 200%)',
  primaryDarkReversed: 'linear-gradient(45deg, #124297 0%, #1f184d 100%)',
};

export const palette: Partial<IPalette> = {
  themeDarker: '#162845',
  themeDark: '#263650',
  // themeDarkAlt: '#000000',
  themePrimary: '#243a5e',
  themeSecondary: '#0078d4',
  themeTertiary: '#50e6ff',
  themeLight: '#334f7e',
  // themeLighter: '#000000',
  // themeLighterAlt: '#000000',
  black: greyScale[8],
  // blackTranslucent40: '#000000',
  // neutralDark: '#000000',
  // neutralPrimary: '#000000',
  // neutralPrimaryAlt: '#000000',
  // neutralSecondary: '#000000',
  // neutralSecondaryAlt: '#000000',
  // neutralTertiary: '#000000',
  // neutralTertiaryAlt: '#000000',
  // neutralQuaternary: '#000000',
  // neutralQuaternaryAlt: '#000000',
  // neutralLight: '#000000',
  // neutralLighter: '#000000',
  // neutralLighterAlt: '#000000',
  // accent: '#000000',
  white: greyScale[1],
  // whiteTranslucent40: '#000000',
  // yellowDark: '#000000',
  yellow: '#dbdd22',
  yellowLight: '#ffeb44',
  // orange: '#000000',
  // orangeLight: '#000000',
  orangeLighter: '#ffb835',
  // redDark: '#000000',
  red: '#d40000',
  // magentaDark: '#000000',
  // magenta: '#000000',
  // magentaLight: '#000000',
  // purpleDark: '#000000',
  purple: '#8A2BE2',
  // purpleLight: '#000000',
  // blueDark: '#000000',
  // blueMid: '#000000',
  blue: '#33A1DE',
  // blueLight: '#000000',
  // tealDark: '#000000',
  // teal: '#000000',
  // tealLight: '#000000',
  greenDark: '#50C878',
  green: '#107c10',
  greenLight: '#45b847',
};

export const semanticColors: Partial<ISemanticColors> = {
  bodyBackground: greyScale[2],
  // bodyBackgroundHovered: '#000000',
  // bodyBackgroundChecked: '#000000',
  // bodyStandoutBackground: '#000000',
  // bodyFrameBackground: '#000000',
  // bodyFrameDivider: '#000000',
  // bodyDivider: '#000000',
  // disabledBackground: '#000000',
  // disabledBorder: '#000000',
  // focusBorder: '#000000',
  // cardStandoutBackground: '#000000',
  // cardShadow: '#000000',
  // cardShadowHovered: '#000000',
  // variantBorder: '#000000',
  // variantBorderHovered: '#000000',
  // defaultStateBackground: '#000000',
  infoBackground: palette.themeSecondary,
  errorBackground: palette.red,
  // blockingBackground: '#000000',
  warningBackground: palette.yellow,
  // severeWarningBackground: '#000000',
  successBackground: palette.green,
  // infoIcon: '#000000',
  // errorIcon: '#000000',
  // blockingIcon: '#000000',
  // warningIcon: '#000000',
  // severeWarningIcon: '#000000',
  // successIcon: '#000000',
  // messageLink: '#000000',
  // messageLinkHovered: '#000000',
  // inputBorder: '#000000',
  // smallInputBorder: '#000000',
  // inputBorderHovered: '#000000',
  // inputBackground: '#000000',
  // inputBackgroundChecked: '#000000',
  // inputBackgroundCheckedHovered: '#000000',
  // inputPlaceholderBackgroundChecked: '#000000',
  // inputForegroundChecked: '#000000',
  // inputFocusBorderAlt: '#000000',
  // inputIconDisabled: '#000000',
  // inputIcon: '#000000',
  // inputIconHovered: '#000000',
  buttonBackground: palette.white,
  // buttonBackgroundChecked: '#000000',
  buttonBackgroundHovered: palette.white,
  // buttonBackgroundCheckedHovered: '#000000',
  // buttonBackgroundDisabled: '#000000',
  // buttonBackgroundPressed: '#000000',
  buttonBorder: palette.themePrimary,
  // buttonBorderDisabled: '#000000',
  primaryButtonBackground: palette.themeSecondary,
  primaryButtonBackgroundHovered: palette.themeSecondary,
  // primaryButtonBackgroundPressed: '#000000',
  // primaryButtonBackgroundDisabled: '#000000',
  primaryButtonBorder: 'red',
  // accentButtonBackground: '#000000',
  // menuBackground: '#000000',
  // menuDivider: '#000000',
  // menuIcon: '#000000',
  // menuHeader: '#000000',
  // menuItemBackgroundHovered: '#000000',
  // menuItemBackgroundPressed: '#000000',
  // menuItemText: '#000000',
  // menuItemTextHovered: '#000000',
  // listBackground: '#000000',
  // listText: '#000000',
  // listItemBackgroundHovered: '#000000',
  // listItemBackgroundChecked: '#000000',
  // listItemBackgroundCheckedHovered: '#000000',
  // listHeaderBackgroundHovered: '#000000',
  // listHeaderBackgroundPressed: '#000000',
};
