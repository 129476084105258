import * as React from 'react';
import Modal from '../../../../../components/Game01/Modal/Modal';
import background from '../images/wh_ep2_lead_2.2_02.02.060.png';
import { ComponentProps } from '../../../../task-types';
import MultipleChoice, { useMultipleChoice } from '../../../../../components/Global/Interactions/MultipleChoice/MultipleChoice';
import Button from '../../../../../components/Global/Button/Button';
import RTrans from '../../../../../components/Global/RTrans/RTrans';

export default function A040({ t, send, match }: ComponentProps): JSX.Element {
  const { errorKey, handleIncorrect } = useMultipleChoice(() => send('CLICK.INCORRECT'));

  return (
    <>
      <MultipleChoice
        subtitle={t('A040.title')}
        questionText={<RTrans t={t} i18nKey="A040.body" />}
        didComplete={match('final')}
        onComplete={() => send('NEXT')}
        backgroundImage={background}
      >
        <Button
          isPrimary
          onClick={() => handleIncorrect('one')}
          label={t('A040.labels.one')}
          disabled={match('final')}
        />
        <Button
          isPrimary
          onClick={() => handleIncorrect('three')}
          label={t('A040.labels.three')}
          disabled={match('final')}
        />
        <Button
          isPrimary
          onClick={() => !match('final') && send('NEXT')}
          label={t('A040.labels.five')}
          icon={match('final') ? 'check' : undefined}
        />
      </MultipleChoice>
      <Modal
        sidebar="character"
        title={t(`A040.errors.${errorKey}.title`)}
        body={t(`A040.errors.${errorKey}.body`)}
        isOpen={match('incorrect')}
        footer={<Button isPrimary label={t('common.errorTitle')} onClick={() => send('NEXT')} />}
        onDismiss={() => send('NEXT')}
        hideDismissButton
      />
    </>
  );
}
