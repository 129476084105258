/**
 * i18next NS: `Episode01.Task14`
 */
export default {
  common: {
    errorTitle: "Try Again",
    next: "Next",
    submit: "Submit",
    start: "Start",
    skipAhead: "Skip Ahead",
    ok: "OK",
    done: "Done",
  },
  A010: {
    error: {
      1: {
        title: "Try again.",
        body: "That's not the right place.",
      },
      2: {
        title: "Try again.",
        body: "That's not right either.",
      },
      3: {
        title: "Go Here!",
        body: "I need you to go to Microsoft Sentinel.",
      },
    },
  },
  A020: {
    // Three button
    body: "Let's start by checking to see if Tomo has been involved in any security alerts. Select the query you want to run.",
    options: {
      1: {
        label: "search 'tomo.takanashi'",
        error: {
          title: "Try something else.",
          body: "This search results in over 3,000 records. This is too much information to investigate. Hint: The search operator is substantially less efficient than table-specific and column-specific text filtering. Try searching in tables for any security alerts about Tomo.",
        },
      },
      2: {
        label: "search 'tomo.takanashi'|distinct DeviceName",
        error: {
          title: "Try something else.",
          body: "Good idea, but we'll check the devices that Tomo used after we check for security alerts.",
        },
      },
      3: {
        label: "search in (Security Alert) 'tomo.takanashi'",
      },
    },
  },
  A030: {
    title: "Good work!",
    body: "It looks like Tomo is not involved in any security alerts. Now let's see if Tomo's devices were hijacked.",
  },
  A040: {
    // Single checkbox
    title: "First, we need to check what devices Tomo has used. Select the query you want to run.",
    subtitle: "",
    body: "",
    options: {
      1: {
        label: "search 'tomo.takanashi' | AuditLogs",
      },
      2: {
        label: "search 'tomo.takanashi' | distinct DeviceName",
      },
      3: {
        label: "search in (Security Alert) 'tomo.takanashi'",
      },
      4: {
        label: "search 'tomo.takanashi' | SecurityAlert",
      },
    },
    error: {
      title: "Try something else.",
      body: "That's not the right query.",
    },
    feedback: {
      title: "This is the query you want to run to see which devices Tomo used.",
      subtitle: "",
      body: "",
    },
    success: {
      title: "",
      body: "",
    },
  },
  A050: {
    title: "Good job!",
    body: "It looks like Tomo only used one device, pc034.",
  },
  A060: {
    // Single checkbox
    title: "Now, let's see if anyone has hijacked Tomo's device. Select the query you want to run.",
    subtitle: "",
    body: "",
    options: {
      1: {
        label: "search in (SecurityAlert) 'pc034'",
      },
      2: {
        label: "search in (SecurityAlert) 'pc067'",
      },
      3: {
        label: "search in (Security Alert) 'tomo.takanashi'",
      },
      4: {
        label: "search (pc034) 'SecurityAlert'",
      },
    },
    error: {
      title: "Try something else.",
      body: "That's not the right query.",
    },
    feedback: {
      title: "This is the query you want to run to see if anyone hijacked Tomo's device.",
      subtitle: "",
      body: "",
    },
    success: {
      title: "",
      body: "",
    },
  },
  A070: {
    title: "Excellent investigative work.",
    body: "Tomo's device, pc034, has no security alerts. Head to Microsoft 365 Defender to gather even more evidence.",
  },
  B020: {
    // Three button
    body: "Now let's take a closer look at Tomo's device. Which one did she use?",
    options: {
      1: {
        label: "pc034",
      },
      2: {
        label: "pc067",
        error: {
          title: "Try something else.",
          body: "Tomo did not use this device.",
        },
      },
      3: {
        label: "pc105",
        error: {
          title: "Try something else.",
          body: "Tomo did not use this device.",
        },
      },
    },
  },
  B030: {
    title: "Look at the Logged on user column. That tells you that only Tomo used the computer. Microsoft Defender didn't find anything strange. Let's see for ourselves in the Timeline.",
  },
  B035: {
    title: "Here is the device timeline. What do you see?",
    body: "Select NEXT to interpret the results.",
  },
  B050: {
    title: "These following events are on the pc034 timeline. Select the RDP event that you recognize from Angel's timeline.",
    subtitle: "",
    body: "Choose the event, then select DONE.",
    options: {
      1: {
        label: "crednetialuibroker.exe loaded module WinSCard.dll",
      },
      2: {
        label: "svchost.exe created process CredentialUIBroker.exe",
      },
      3: {
        label: "Visual Basic interpreter invoked by cscript.exe",
      },
      4: {
        label: "mstsc.exe established connection with 13.68.237.45:3389",
      },
    },
    error: {
      title: "That isn't right.",
      body: "Can you recognize the IP address from investigating Angel's device?",
    },
    feedback: {
      title: "This is the RDP event that was also found on Angel's device.",
      subtitle: "",
      body: "",
    },
    success: {
      title: "This is the RDP event that was also found on Angel's device.",
      body: "",
    },
  },
  B070: {
    title: "Nice work!",
    body: "That was a thorough investigation of Tomo Takanashi and her device. You found the RDP event you expected and confirmed there were no new alerts on pc034.",
  },
  B080: {
    title: "Lead complete!",
    body: "You investigated Tomo’s device in Microsoft Sentinel & Microsoft Defender. Now it's time to put the information you found on the Evidence Map.",
  },
};
