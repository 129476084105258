/* eslint-disable no-restricted-globals */
import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';
import _deburr from 'lodash/deburr';
import _forOwn from 'lodash/forOwn';
import _isObject from 'lodash/isObject';

import type { ArrayOrSingle, ExtractSingle } from './types';

export const MAX_DATE = new Date(8640000000000000);

/**
 * Whether the application is running on the local development server (`npm run start`),
 * rather than from a build (`npm run build`).
 */
export const isLocalDev = process.env.NODE_ENV === 'development';

/**
 * Whether the application is running in a development environment.
 */
export const isDev = process.env.REACT_APP_ENV === 'development';

export const isStaging = process.env.REACT_APP_BUILD_ENV === 'staging';

export function deepFreeze<T extends object>(obj: T): Readonly<T> {
  return Object.freeze(
    _forOwn(obj, (value) => {
      if (_isObject(value)) {
        deepFreeze(value);
      }
    }),
  );
}

export function isIterable(obj: any): boolean {
  if (obj == null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === 'function';
}

const numericRegexp = /^\s*[+-]?(?:[0-9]+(?:.[0-9]+)?(?:[eE][+-]?[0-9]+)?|Infinity)\s*$/;

/**
 * Test that the input is number that isn't NaN, or a string containing a
 * basic, parsable representation of a non-NaN number.
 */
export function isNumeric(val?: any): boolean {
  return (
    (typeof val === 'number' && !isNaN(val)) ||
    (typeof val === 'string' && numericRegexp.test(val) && !isNaN(parseFloat(val)))
  );
}

export function safeParseISO(date: string, fallback: Date = MAX_DATE): Date {
  const dateObj = parseISO(date);
  if (isValid(dateObj)) {
    return dateObj;
  }
  return fallback;
}

export const nameof = <T extends {}>(name: keyof T) => name;

export function checkEnvVar(v: string | undefined) {
  return v && v !== 'false';
}

export function getSubdomain() {
  return typeof location !== 'undefined' ? location.hostname.split('.')[0] : undefined;
}

export function getParams() {
  if (typeof location === 'undefined') {
    return {};
  }

  const url = location.href;
  const params = {};
  const parser = document.createElement('a');
  parser.href = url;
  const query = parser.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split('=');
    (params as any)[pair[0]] = decodeURIComponent(pair[1]);
  }
  return params;
}

export type EnumBase = Record<string, string | number | undefined>;
export type EnumValue<T extends EnumBase> = T extends Record<keyof T, infer X> ? X : never;

/**
 * Validate or lookup a value for an enum (with fallback).
 * @param {TEnum} enumObj - An Enum object.
 * @param {EnumValue<TEnum> | string | number | null | undefined} value - An enum value or name to lookup or validate.
 * @param {EnumValue<TEnum>} defaultValue - The value to return if the lookup fails.
 *
 * @return {EnumValue<TEnum>} A valid enum value.
 */
export function getEnumValue<TEnum extends EnumBase>(
  enumObject: TEnum,
  value: EnumValue<TEnum> | string | number | null | undefined,
  defaultValue: EnumValue<TEnum>,
): EnumValue<TEnum> {
  // Enum members can't have numeric names, so if the input is numeric we are
  // validating a value.
  if (isNumeric(value)) {
    // Enum members with numeric values have reverse mappings, so we can look up
    // the value as a key on the object. (This will also stringify if necessary.)
    const lookup = enumObject[value as string];
    if (lookup == null) {
      return defaultValue;
    }

    // If lookup was successful, we now have the name and need to reverse to
    // end up with the canonical value.
    return enumObject[lookup] as EnumValue<TEnum>;
  }

  // Enum members with string values only map name to value, so we can match
  // the input against keys & values and return the corresponding value.
  return (
    (Object.entries(enumObject).find(
      ([enumKey, enumVal]) => enumKey === value || enumVal === value,
    )?.[1] as EnumValue<TEnum>) ?? defaultValue
  );
}

/**
 * Console.log gated behind isDev
 */
export const devLog = (...data: any[]): void => {
  if (isDev) {
    // eslint-disable-next-line no-console
    console.log(...data);
  }
};

/**
 * Console.warn gated behind isDev
 */
export const devLogWarn = (...data: any[]): void => {
  if (isDev) {
    // eslint-disable-next-line no-console
    console.warn(...data);
  }
};

/**
 * Console.error gated behind isDev
 */
export const devLogErr = (...data: any[]): void => {
  if (isDev) {
    // eslint-disable-next-line no-console
    console.error(...data);
  }
};

/**
 * Generate an HTML-safe ID from one or more arguments.
 * Will accept null/undefined arguments but strip them from the result.
 */
export function toHTMLSafeId(...args: (string | number | null | undefined)[]) {
  // Remove null/undefined args.
  const realArgs = args.filter((arg) => arg != null);

  // Return undefined if args empty;
  if (realArgs.length === 0) {
    return undefined;
  }

  // 1. Remove diacritics (turns some accented characters into ASCII).
  // 2. Remove all whitespace.
  // 3. Replace any unsafe characters with '_'.
  return _deburr(realArgs.join('-'))
    .replace(/\s+/g, '')
    .replace(/[^A-Za-z0-9-_]+/g, '_');
}

/**
 * Coerce an argument into an array. Attempt to infer type.
 */
export function coerceArray<T>(arg: ArrayOrSingle<ExtractSingle<T>>): Array<ExtractSingle<T>> {
  return Array.isArray(arg) ? arg : [arg];
}

/**
 * Unwrap an array with a single value into that value, otherwise return the array.
 */
export function unwrapSingle<T>(arg: Array<ExtractSingle<T>>): ArrayOrSingle<ExtractSingle<T>> {
  return arg.length === 1 ? arg[0] : arg;
}

/** Constant function that does nothing. */
export function noOp(): void;
export function noOp(...args: any[]): void;
export function noOp() { }

/**
 * Get a value from URL search query debug flags.
 * Coerces `'true'` and `'false'` values to native booleans, and attempts to parse numbers.
 */
export function getDebugFlagValue(flagName: string): boolean | number | string | null {
  const params = new URLSearchParams(window.location.search);

  // Try to get value from `?[flagName]Debug=[value]`
  const singleFlagValue = params.get(`${flagName}Debug`);
  if (singleFlagValue != null) {
    // Normalize value and resolve if it's boolean-ish
    switch (singleFlagValue.toLowerCase()) {
      case '':
      case 'true':
        return true;
      case 'false':
        return false;
      // no default
    }
    // Otherwise, try to parse it as a number, or return the original string value if that fails.
    const tryParse = parseFloat(singleFlagValue);
    return Number.isNaN(tryParse) ? singleFlagValue : tryParse;
  }

  // Try to get value from `?debug=[flagName1],[flagName2]...` (boolean result only)
  const multiFlagValues = params.get('debug');
  if (multiFlagValues != null) {
    // Return boolean based on whether flag name exists in values.
    return multiFlagValues
      .trim()
      .split(/[\s;,]+/)
      .includes(flagName);
  }

  // Fallback to null.
  return null;
}

/**
 * Checks URL search query debug flag using `getDebugFlagValue()`.
 *
 * @returns `true` if the flag is set and its value is not `false` or `0`, otherwise `false`.
 */
export function hasDebugFlag(flagName: string): boolean {
  const flagValue = getDebugFlagValue(flagName);
  return flagValue !== null && flagValue !== false && flagValue !== 0;
}
