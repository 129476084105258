import * as React from 'react';
import Screen from '../../../../../components/Global/Screen/Screen';
import Modal from '../../../../../components/Game01/Modal/Modal';
import background from '../images/01C.010.png';
import { ComponentProps } from '../../../../task-types';
import MultipleChoice, { useMultipleChoice } from '../../../../../components/Global/Interactions/MultipleChoice/MultipleChoice';
import Button from '../../../../../components/Global/Button/Button';

export default function C010({ t, send, match }: ComponentProps): JSX.Element {
  const { errorKey, handleIncorrect } = useMultipleChoice(() => send('CLICK.INCORRECT'));
  return (
    <Screen>
      <MultipleChoice
        questionText={t('C010.body')}
        didComplete={match('final')}
        onComplete={() => send('NEXT')}
        backgroundImage={background}
      >
        <Button
          isPrimary
          onClick={() => !match('final') && send('NEXT')}
          label={t('C010.options.1.label')}
          icon={match('final') ? 'check' : undefined}
        />
        <Button
          isPrimary
          onClick={() => handleIncorrect(2)}
          label={t('C010.options.2.label')}
          disabled={match('final')}
        />
      </MultipleChoice>
      <Modal
        sidebar="character"
        isOpen={match('incorrect')}
        footer={<Button isPrimary label={t('common.errorTitle')} onClick={() => send('NEXT')} />}
        onDismiss={() => send('NEXT')}
        title={t(`C010.options.${errorKey}.error.title`)}
        body={t(`C010.options.${errorKey}.error.body`)}
        hideDismissButton
      />
      <Modal
        isOpen={match('success')}
        footer={<Button isPrimary label={t('C010.success.nextLabel')} onClick={() => send('NEXT')} />}
        onDismiss={() => send('NEXT')}
        hideDismissButton
        title={t('C010.success.title')}
        body={t('C010.success.body')}
      />
    </Screen>
  );
}
