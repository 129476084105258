/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { useForm } from 'react-hook-form';
import { IconButton, IStackStyles, Stack } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { ControlledTextField } from '../../../HookFormComponents/HookFormComponents';
import { IOSocket } from '../../../../../lib/socket/types';

interface SessionChatSubmitProps {
  slug: string;
  socket: IOSocket;
}

function footerStyles(): IStackStyles {
  return {
    root: {
      padding: 18,
      backgroundColor: '#e6e6e6',
      input: {
        fontWeight: 'normal',
      },
    },
  };
}

type Form = {
  message: string;
};

const SessionChatSubmit: React.FC<SessionChatSubmitProps> = ({ slug, socket }) => {
  const { t } = useTranslation('common');
  const { handleSubmit, control, setValue } = useForm<Form>({
    defaultValues: {
      message: '',
    },
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  const onSend = () => {
    handleSubmit(
      async (data) => {
        try {
          const { message } = data;
          if (!message.length) {
            return;
          }
          socket.emit('chat:send', {
            room: `session:${slug}`,
            message,
          });
          setValue('message', '');
        } catch (error: any) {
          throw new Error(error);
        }
      },
      (err) => {
        console.log('RegistrationError:', err);
      },
    )();
  };

  return (
    <Stack.Item styles={footerStyles()}>
      <form onSubmit={handleSubmit(onSend)}>
        <Stack horizontal>
          <Stack.Item grow>
            <ControlledTextField autoComplete="off" placeholder={t('facilitator.chat')} control={control} name="message" />
          </Stack.Item>
          <Stack.Item>
            <IconButton
              iconProps={{ iconName: 'Forward' }}
              type="submit"
              aria-label={t('facilitator.sendChatMessageButton')}
            />
          </Stack.Item>
        </Stack>
      </form>
    </Stack.Item>
  );
};

export default SessionChatSubmit;
