/**
 * i18next NS: `Episode01.Task05`
 */
export default {
  Q01: {
    title: "Which ReFED files do you want to ingest?",
    subtitle: "",
    body: "",
    options: {
      1: {
        label: "ReFED_US_Food_Waste_Solutions_Detail.csv",
      },
      2: {
        label: "ReFED_US_Food_Waste_Solutions_Financial _Summary_by_Stakeholder.csv",
      },
      3: {
        label: "ReFED_US_Food_Waste_Solutions_Summary.csv",
      },
      4: {
        label: "ReFED_US_State_Food_Waste_Solutions_Financial_Summary_by_Stakeholder.csv",
      },
      5: {
        label: "ReFED_US_State_Food_Waste_Solutions_Summary.csv",
      },
    },
    error: {
      title: "Something doesn’t look quite right here.",
      body: "Here’s a tip. Check the requirements we discussed, then give this another try.",
    },
    feedback: {
      title: "These 2 files summarize all the data you need.",
      subtitle: "The other 3 files all contain more granular, state-level data.",
      body: "Simulation mode lets you preview the results. So you can make sure that you are applying the label to the correct items before you turn on the policy and physically change the files.",
    },
  },
  Q02: {
    title: "Please take a moment to review.",
    subtitle: "",
    body: "Here is the data from ReFED_US_Food_Waste_Solutions_Summary.csv. Do you understand what you are working with?",
    options: {
      1: {
        label: "No, I need a hint.",
      },
      2: {
        label: "Yes, I’m ready!",
      },
    },
    hint: {
      title: "Look at the overall shape of the data.",
      body: "Make sure you understand it at a high level—what it’s about, how it’s structured, and how your team members will use it.",
    },
  },
  Q03: {
    title: "Take a moment to review.",
    subtitle: "",
    body: "Here is the data from ReFED_US_Food_Waste_Solutions_Financial _Summary_by_Stakeholder.csv. Do you understand it well enough to recommend a data storage solution?",
    options: {
      1: {
        label: "No, I need a hint.",
      },
      2: {
        label: "Yes, I’m ready!",
      },
    },
    hint: {
      title: "Take a minute to eyeball the data:",
      body: "Make sure you understand how it is structured and how it will be used. Just high-level.",
    },
  },
  Q04: {
    title: "How do you want to move the data?",
    subtitle: "",
    body: "",
    options: {
      1: {
        label: "Load the data directly",
      },
      2: {
        label: "Use a pipeline",
      },
    },
    error: {
      title: "Hmmm… this data doesn’t make sense.",
      body: "It looks like some headers are missing. What do the requirements recommend in this situation?",
    },
  },
  Q05: {
    title: "Where should you load the data?",
    subtitle: "Choose your answer, then select Done.",
    body: "",
    options: {
      1: {
        label: "stor1/refed",
      },
      2: {
        label: "stor1/CLEANED/refed",
      },
      3: {
        label: "stor1/DISTILLED/refed",
      },
      4: {
        label: "stor1/RAW/refed",
      },
    },
    error: {
      title: "Did you follow the data lake conventions?",
      body: "This location does not meet the business requirements.",
    },
    feedback: {
      title: "Use the RAW folder for data in its original state.",
      subtitle: "",
      body: "",
    },
  },
  Q06: {
    title: "How do you want to analyze the data?",
    subtitle: "",
    body: "",
    options: {
      1: {
        label: "Apache Spark pool",
        error: {
          title: "I’m afraid that resource does not exist.",
          body: "Spark isn’t set up for this task.",
        },
      },
      2: {
        label: "Dedicated SQL pool",
        error: {
          title: "That resource is not configured for this data structure.",
          body: "The requirements specify a different type of workspace for structured data.",
        },
      },
      3: {
        label: "Serverless SQL pool",
      },
    },
  },
  Q07: {
    title: "Where do you want to store the data?",
    subtitle: "",
    body: "",
    options: {
      1: {
        label: "app1db",
        error: {
          title: "That’s not the right resource.",
          body: "If you aren’t sure what resource to use, check the requirements.",
        },
      },
      2: {
        label: "rd1db",
        error: {
          title: "Connection to rd1db refused.",
          body: "Take another look at the requirements.",
        },
      },
      3: {
        label: "dw1db",
      },
      4: {
        label: "dw2db",
        error: {
          title: "I just got a message from the database administrator team.",
          body: "They want to know why you added files to dw2db. Did you check the requirements?",
        },
      },
    },
  },
};
