import { MaybeLazy } from '@xstate/react/lib/types';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Interpreter, State } from 'xstate';
import { EventObject, InterpreterOptions, MachineOptions, StateMachine, Typestate } from 'xstate/lib/types';
import { executeGlobalActions } from '../../state/GlobalActionListener';
import { RootState } from '../../store';
import { restoreTaskState, saveTaskState } from '../../tasks/task-helpers';
import { isDev } from '../util';
import { UseMachineOptions, useMachineReloadable } from './xstate/useMachineReloadable';

export interface UseTaskMachineOptions {
  /**
   * Name that the task machine state should persist under.
   * If undefined, machine state will nt persist.
   */
  taskKey: string | undefined | ((state: RootState) => string | undefined);
  /**
   * Boolean value or selector to determine whether the machine should persist.
   * If undefined, will check is if the user is registered and has a session UUID/
   */
  shouldPersist?: boolean | undefined | ((state: RootState) => boolean | undefined);
}

/**
 * A wrapper for XState's `useMachine()` that encapsulates serializing/deserializing state.
 */
export function useTaskMachine<
  TContext,
  TEvent extends EventObject,
  TTypestate extends Typestate<TContext> = { value: any; context: TContext },
>(
  getMachine: MaybeLazy<StateMachine<TContext, any, TEvent, TTypestate>>,
  options: Partial<InterpreterOptions> &
    Partial<UseMachineOptions<TContext, TEvent>> &
    Partial<MachineOptions<TContext, TEvent>> &
    Partial<UseTaskMachineOptions> = {},
): [
  State<TContext, TEvent, any, TTypestate>,
  Interpreter<TContext, any, TEvent, TTypestate>['send'],
  Interpreter<TContext, any, TEvent, TTypestate>,
] {
  /**
   * Evaluate taskKey, which is used for task history metadata and saveKey calculation.
   * Must result in a non-empty string, otherwise returns undefined.
   */
  const taskKey = useSelector((state: RootState) => {
    const taskKeyValue = typeof options.taskKey === 'function' ? options.taskKey(state) : options.taskKey;
    if (taskKeyValue == null || taskKeyValue.length === 0) {
      return undefined;
    }
    return taskKeyValue;
  });

  /**
   * Calculate save key. If this is undefined, the machine will not save.
   */
  const saveKey = useSelector((state: RootState) => {
    // Require a valid taskKey.
    if (taskKey == null) {
      return undefined;
    }

    // Evaluate user-provided shouldPersist check. Must evaluate to a boolean.
    const shouldPersist =
      typeof options.shouldPersist === 'function' ? options.shouldPersist(state) : options.shouldPersist;
    if (!shouldPersist) {
      return undefined;
    }

    // Save under this key.
    return taskKey;
  });

  // Memoize restored state based on `saveKey` so that it doesn't constantly reload.
  const restoredState = useMemo(() => restoreTaskState(saveKey), [saveKey]);

  // Setup task machine
  const [state, send, service] = useMachineReloadable(getMachine, {
    devTools: isDev,
    state: restoredState as any,
    ...options,
  });

  // Hook to serialize task
  useEffect(() => {
    const subscription = service.subscribe((newState) => {
      if (saveKey != null) {
        saveTaskState(saveKey, newState);
      }
    });
    return subscription.unsubscribe;
  }, [service, saveKey]);

  // Hook to subscribe global action listenr
  useEffect(() => {
    // subscribe global action listener to task services
    const subscription = service.subscribe((newState) => {
      executeGlobalActions(newState, 'TaskService');
    });

    return subscription.unsubscribe;
  }, [service]);

  useEffect(() => {
    const handleMessage = (message: MessageEvent) => {
      if (message.data?.action === 'TASK:SKIP.SIMULATION') {
        send('CHOOSE.SKIP');
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [send]);

  return [state, send, service];
}
