import * as React from 'react';
import { ThemeProvider as FluentThemeProvider } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { getCurrentGameTheme } from '.';
import { selectCurrentGame } from '../store/multiplayer-slice';
import { RootState, useAppSelector } from '../store';

export default function ThemeProvider({ children }: React.PropsWithChildren<{}>) {
  const currentGameId = useAppSelector(selectCurrentGame);
  const gameThemeOverride = useSelector((reduxState: RootState) => reduxState.game.gameThemeOverride);
  return (
    <FluentThemeProvider theme={getCurrentGameTheme(gameThemeOverride || currentGameId?.shortId)}>
      {children}
    </FluentThemeProvider>
  );
}
