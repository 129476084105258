import React from 'react';

import SummaryPlayerPersonaBase, { IPlayerPersonaBaseProps } from './SummaryPlayerPersonaBase';
import { useAppSelector } from '../../../store';
import { selectAllUsers, selectUserIsRegistered } from '../../../store/user-slice';

export interface IPlayerPersonaLiveProps extends Omit<IPlayerPersonaBaseProps, 'user' | 'color'> {
  username?: string;
}

/**
 * PlayerPersona component with Redux integration.
 */
const SummaryPlayerPersonaLive: React.FC<IPlayerPersonaLiveProps> = ({ username, ...props }) => {
  const { allUsers } = useAppSelector(selectAllUsers);
  const isRegistered = useAppSelector(selectUserIsRegistered);

  const userIndex = allUsers.findIndex((user) => username === user.username);
  const user = allUsers[userIndex];
  const userColor = !isRegistered ? undefined : userIndex;

  return <SummaryPlayerPersonaBase {...props} user={user} color={userColor} />;
};

export default SummaryPlayerPersonaLive;
