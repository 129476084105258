import React, { CSSProperties } from 'react';
import { Handle, Position } from 'react-flow-renderer';

import { TFunction } from 'react-i18next';

import { ElementDataNode } from '../../../lib/game-data/element-data';
import { isDev, hasDebugFlag } from '../../../lib/util';
import { useAppSelector } from '../../../store';
import { selectBatteryPower } from '../../../store/multiplayer-slice/applied-data';

export interface IMapActivityProps {
  data: MapActivityNodeData;
}

export interface MapActivityNodeData {
  element: ElementDataNode;
  onFocus?: () => void;
  t: TFunction;
}

export type NodeStatusType = 'deactivate' | 'activate' | 'completed';

const mapDebug: boolean = isDev && hasDebugFlag('map');

const nodeSizeBase = 50;

const batteryStyles = {
  minWidth: `${nodeSizeBase * 5}px`,
  maxWidth: `${nodeSizeBase * 5}px`,
  minHeight: `${nodeSizeBase * 10}px`,
  maxHeight: `${nodeSizeBase * 10}px`,
};

const handleStyles = {
  'east': { opacity: mapDebug ? 1 : 0 },
  'south-east': { opacity: mapDebug ? 1 : 0, top: '80%', right: '15%' },
  'south': { opacity: mapDebug ? 1 : 0 },
  'south-west': { opacity: mapDebug ? 1 : 0, top: '80%', left: '15%' },
  'west': { opacity: mapDebug ? 1 : 0 },
  'north-west': { opacity: mapDebug ? 1 : 0, top: '20%', left: '15%' },
  'north': { opacity: mapDebug ? 1 : 0 },
  'north-east': { opacity: mapDebug ? 1 : 0, top: '20%', right: '15%' },
};

function wrapperStyle(): CSSProperties {
  return {
    ...batteryStyles,
    paddingTop: '10px',
  };
}

function svgStyle(): CSSProperties {
  return {
    ...batteryStyles,
    border: '5px',
    borderStyle: 'solid',
    borderColor: 'black',
  };
}

function batteryBackgroundStyle(): CSSProperties {
  return {
    fill: '#fff',
    border: '10px',
  };
}

function batteryStyle(progress: number): CSSProperties {
  return {
      fill: '#0f0',
      transition: 'transform 0.6s ease-in-out',
      transform: `translate(0, ${100 - progress}%) scaleY(${progress})`,
  };
}

/**
 * Evidence Map React-Flow card component.
 */
const MapActivity: React.FC<IMapActivityProps> = () => {
  const insightPower = useAppSelector(selectBatteryPower);
  return (
    <>
      <div style={wrapperStyle()}>
        <svg style={svgStyle()}>
          <g id="battery_bg">
            <rect style={batteryBackgroundStyle()} width="100%" height="100%" />
          </g>
          <rect style={batteryStyle(insightPower)} width="100%" height="1%" />
        </svg>

        <Handle type="source" id="east" style={handleStyles.east} position={Position.Right} isConnectable={false} />
        <Handle type="source" id="south-east" style={handleStyles['south-east']} position={Position.Right} isConnectable={false} />
        <Handle type="source" id="south" style={handleStyles.south} position={Position.Bottom} isConnectable={false} />
        <Handle type="source" id="south-west" style={handleStyles['south-west']} position={Position.Left} isConnectable={false} />
        <Handle type="source" id="west" style={handleStyles.west} position={Position.Left} isConnectable={false} />
        <Handle type="source" id="north-west" style={handleStyles['north-west']} position={Position.Left} isConnectable={false} />
        <Handle type="source" id="north" style={handleStyles.north} position={Position.Top} isConnectable={false} />
        <Handle type="source" id="north-east" style={handleStyles['north-east']} position={Position.Right} isConnectable={false} />
      </div>
    </>
  );
};

export default MapActivity;
