import React from 'react';
import { gameData } from '../../static/game-data';
import { addEntriesWithUnlocks } from '../../store/journal-slice';

import type { SafeDictionary } from '../types';
import type { LeadData } from '../game-data/lead-data';

/**
 * Hook to generate 3Sharp Simulation variable -> `addEntriesWithUnlocks()` map for a Lead.
 *
 * Iterates over all evidence data in a Lead and returns an object that attempts
 * to match individual evidence states to Redux `addEntriesWithUnlocks()`
 * actions for that state on its associated piece of evidence.
 *
 * This means that any Evidence item or state IDs that exactly match a 3Sharp
 * simulation variable can be easily added by a simple lookup and `dispatch()`.
 */
export function useSimVariableToAddEntryMap(leadId: string): SafeDictionary<ReturnType<typeof addEntriesWithUnlocks>[]>;
export function useSimVariableToAddEntryMap(lead: LeadData): SafeDictionary<ReturnType<typeof addEntriesWithUnlocks>[]>;
export function useSimVariableToAddEntryMap(leadOrId: string | LeadData) {
  const leadData = typeof leadOrId === 'string' ? gameData.get(leadOrId, 'lead') : leadOrId;

  // Memoize results based on lead and appliedCardIds
  return React.useMemo(() => {
    const output: SafeDictionary<ReturnType<typeof addEntriesWithUnlocks>[]> = {};
    // Validate lead
    if (leadData == null || leadData.kind !== 'lead' || !leadData.flags.has('simulation')) {
      return output;
    }

    // Generate map of [simVariable] -> [addEntriesWithUnlocks actions]
    return leadData.evidence.reduce((accumulator, evidence) => {
      // Add entry for bare Evidence item.
      const itemAction = addEntriesWithUnlocks({ id: evidence.id, states: [] });
      const shortId = evidence.shortId;
      (accumulator[shortId] = accumulator[shortId] ?? []).push(itemAction);

      // Add entry for Evidence item, where fact `shortId` == entity `state`.
      evidence.facts.forEach((fact) => {
        const stateAction = addEntriesWithUnlocks({ id: evidence.id, states: [fact.shortId] });
        (accumulator[fact.shortId] = accumulator[fact.id] ?? []).push(stateAction);
      });
      return accumulator;
    }, output);
  }, [leadData]);
}
