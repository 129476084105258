import { Memoize } from 'typescript-memoize';

import type { XYPosition } from 'react-flow-renderer';

import { GameDataBase } from './game-data-base';
import { devLogWarn } from '../util';

import type { EpisodeData } from './episode-data';
import type { ActivityData } from './activity-data';
import type {
  DisciplineType,
  ElementDefinition,
  ElementType,
  EvidenceImportance,
  GameDataInstance,
  GameDataInstanceOf,
  GameDataKind,
  HybridActivityType,
} from './types';

export type ElementDataNode = ElementData & { type: 'node' | 'lock' | 'battery' | 'start' };
export type ElementDataLock = ElementData & { type: 'lock' };
export type ElementDataWire = ElementData & { type: 'wire' };

/**
 * Element Data
 */
export class ElementData extends GameDataBase {
  readonly kind = 'element';

  declare readonly parent: EpisodeData | ActivityData | undefined;

  readonly type: ElementType;

  readonly locationId: string | undefined;

  readonly linkIds: readonly string[] = [];

  readonly unlockIds: readonly string[] = [];

  readonly taskId?: string | undefined = '';

  readonly discipline?: DisciplineType = 'multiple';

  readonly output?: number = 0;

  readonly powerRange?: readonly number[] = [];

  readonly capacity?: number = 0;

  readonly unlocked?: boolean = false;

  readonly hybridActivityType?: HybridActivityType = 'nonSim';

  constructor(data: ElementDefinition, parent?: EpisodeData | ActivityData) {
    super(data, parent);

    const { type, taskId, discipline, output, powerRange,
      locationId, linkIds, unlockIds, capacity, unlocked, hybridActivityType } = data;
    this.type = type ?? 'log';
    this.discipline = discipline;
    this.output = output;
    this.powerRange = powerRange;
    this.taskId = taskId;
    this.capacity = capacity;
    this.unlocked = unlocked;
    this.hybridActivityType = hybridActivityType;

    // Handle locationId
    if (locationId != null) {
      if (this.type === 'node') {
        devLogWarn(`EvidenceData() a card's locationId is itself. '${locationId}' will be ignored.`, this);
      }
      this.locationId = type !== 'node' ? locationId : undefined;
    }

    // Parse link IDs
    if (Array.isArray(linkIds)) {
      this.linkIds = [...linkIds];
    }
    Object.freeze(this.linkIds);

    // Parse unlock IDs
    if (Array.isArray(unlockIds)) {
      this.unlockIds = [...unlockIds];
    }
    Object.freeze(this.unlockIds);
  }

  getChildren<TKind extends GameDataKind | undefined>(filter?: TKind): readonly GameDataInstanceOf<TKind>[];
  @Memoize()
  // eslint-disable-next-line class-methods-use-this
  getChildren(): readonly GameDataInstance[] {
    return Object.freeze([]);
  }

  /** Check this is a Card (TS type predicate). */
  isNode(): this is ElementDataNode {
    return this.type === 'node';
  }

  /** Check this is a Clue (TS type predicate). */
  isWire(): this is ElementDataWire {
    return this.type === 'wire';
  }

  /**
   * Get the Evidence Map Card location for this instance.
   * A card's location is _always_ itself.
   * This will only return 'card' type instances.
   */
  @Memoize()
  getLocation(): ElementDataNode | undefined {
    if (this.type === 'node' || this.type === 'lock' || this.type === 'battery' || this.type === 'start') {
      return this as ElementDataNode;
    }

    if (this.locationId == null) {
      return undefined;
    }

    const result = this.find(this.locationId);
    if (result == null) {
      devLogWarn(`ElementData.getLocation() location '${this.locationId}' not found`, this);
      return undefined;
    }

    if (result.kind !== 'element' ||
      (result.type !== 'node' && result.type !== 'lock' && result.type !== 'battery' && result.type !== 'start')) {
      devLogWarn(`ElementData.getLocation() location '${this.locationId}' is not a node or card`, this);
      return undefined;
    }

    return result as ElementDataNode;
  }

  /**
   * Get the Evidence Map node links for this instance.
   * This will only return 'node' type instances.
   */
  @Memoize()
  getLinks(): readonly ElementDataNode[] {
    return Object.freeze([
      ...this.linkIds.reduce((accumulator, linkId) => {
        const result = this.find(linkId);
        if (result == null) {
          devLogWarn(`ElementData.getLinks() link '${linkId}' not found`, this);
        } else if (result.kind !== 'element' ||
          (result.type !== 'node' && result.type !== 'lock' && result.type !== 'battery' && result.type !== 'start')) {
          devLogWarn(`ElementData.getLinks() link '${linkId}' is not a node or lock`, this);
        } else {
          accumulator.add(result as ElementDataNode);
        }
        return accumulator;
      }, new Set<ElementDataNode>()),
    ]);
  }

  /**
   * Get the unlocks for this instance.
   */
  @Memoize()
  getUnlocks() {
    return Object.freeze([
      ...this.unlockIds.reduce((accumulator, unlockId) => {
        const result = this.find(unlockId);
        if (result == null) {
          devLogWarn(`EvidenceData.getUnlocks() unlock '${unlockId}' not found`, this);
        } else if (result.kind !== 'element') {
          devLogWarn(`EvidenceData.getUnlocks() unlock '${unlockId}' is not Evidence or Fact`, this);
        } else {
          accumulator.add(result);
        }
        return accumulator;
      }, new Set<ElementData>()),
    ]);
  }

  /**
   * Get the associated task to this instance.
   */
  @Memoize()
  getTask() {
    const node = this.type !== 'node' ? this.getLocation()?.getLocation() : this;
    return node?.taskId ? node.find(node.taskId, 'activity') : undefined;
  }

  /**
  * Get the associated task to this instance.
  */
  @Memoize()
  getOutputWires() {
    const node = this.type !== 'node' && this.type !== 'lock' ? this.getLocation()?.getLocation() : this;
    return node?.getEpisode()?.getChildren('element').filter(
      (element) => element.type === 'wire' && element.getLocation()?.getLocation()?.id === node?.id);
  }

  /**
  * Get the associated task to this instance.
  */
   @Memoize()
   getInputputWires() {
     const node = this.type !== 'node' && this.type !== 'lock' ? this.getLocation()?.getLocation() : this;
     return node?.getEpisode()?.getChildren('element').filter(
        (element) =>
          element.type === 'wire' &&
          element.getLinks().find((link) => link.id === node?.id),
      );
   }

  /**
   * Get the evidence map position for this instance's Evidence Map location.
   */
  getMapPosition(defaultValue: XYPosition = { x: 0, y: 0 }): XYPosition {
    return this.getEpisode()?.getMapPosition(this.shortId, defaultValue) ?? defaultValue;
  }

  /**
   * Get the evidence map position for this instance's Evidence Map location.
   */
   getWirePosition(defaultValue: XYPosition[] = []): XYPosition[] {
    return this.getEpisode()?.getWirePositions(this.shortId, defaultValue) ?? defaultValue;
  }

  /**
   * Get dependent clues/facts for this instance.
   */
  getDependencies(): readonly (ElementData)[];
  getDependencies(filter: EvidenceImportance): readonly (ElementData)[];
  @Memoize()
  /** Implementation signature */
  getDependencies(filter?: EvidenceImportance): readonly (ElementData)[] {
    // Process filter
    if (filter != null) {
      const allDependencies = this.getDependencies();
      return allDependencies;
    }

    // Find parent Episode data.
    const episode = this.getEpisode();
    if (episode == null) {
      return Object.freeze([]);
    }

    const episodeEvidence = episode.getAllChildren('element');
    return Object.freeze(episodeEvidence.filter((evidence) => evidence !== this && evidence.getLocation() === this));
  }

  /** Get associated EpisodeData */
  getEpisode() {
    switch (this.parent?.kind) {
      case 'episode':
        return this.parent;
      default:
        return undefined;
    }
  }

  /** Get associated LeadData */
  getActivity() {
    return this.parent?.isKind('activity') ? this.parent : undefined;
  }

  /** Get associated PhaseData */
  getPhase() {
    return this.getActivity()?.getPhase();
  }

  inPowerRange(power: number) {
    if (!this.powerRange) {
      return false;
    }
    return power >= this.powerRange[0] && power <= this.powerRange[1];
  }
}
