import React from 'react';
import { useAppSelector } from '../../../store';
import { selectCurrentGame } from '../../../store/multiplayer-slice';
import Notebook from '../../Game02/Notebook/Notebook';
import LeadJournal from '../../Game01/LeadJournal/LeadJournal';

export default function JournalRouter() {
  const currentGameId = useAppSelector((selectCurrentGame));
  return (
    <>
      {`${currentGameId?.shortId}` === 'Game01' && <LeadJournal />}
      {`${currentGameId?.shortId}` === 'Game02' && <Notebook />}
    </>
  );
}
