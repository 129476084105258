import React from 'react';

import { useGameService } from '../../state/GlobalStateProvider';
import { applyData, setCurrentPhase } from '../../store/multiplayer-slice';
import { gameData } from '../../static/game-data';
import { devLog } from '../../lib/util';
import { addEntries } from '../../store/journal-slice';
import { useAppDispatch, useAppStore } from '../../store';
import {
  compileDataFromEvidence,
  generateAppliedDataFromCompiledData,
  generateJournalEntriesFromCompiledData,
} from '../../lib/game-data/util';
import { getMatcherResults } from '../../lib/xstate/matchers';
import { getDefaultStateMatcherOptions, getDefaultStateMatchers } from '../../state/defaultStateMatchers';
import type { EvidenceData } from '../../lib/game-data/evidence-data';
import type { AppliedLeadEntity } from '../../lib/multiplayer/schemas';
import type { StateMatcher } from '../../lib/xstate/matchers';
import type { GameContext, GameEvent } from '../../state/game-service';
import { extractIdsFromEntry } from '../../lib/journal/journal-entry';
// import useApplyTutorialCards from '../../lib/hooks/useApplyTutorialCards';

// Generate the list of content to match on once.
// NOTE: We're using the same basic matchers and options as normal `GameContent`
const stateMatchers: StateMatcher<GameContext, GameEvent>[] = [
  ...getDefaultStateMatchers(),
  {
    type: 'fallback',
    content: (state) => <>Nothing matched (State(s): {state.toStrings().join('; ')}).</>,
  },
];
const stateMatcherOptions = getDefaultStateMatcherOptions();

const Game02DevEvidenceTest: React.FC = () => {
  const { state: gameState, setState: setGameState } = useGameService();
  const store = useAppStore();
  const dispatch = useAppDispatch();

  // useApplyTutorialCards(true);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    // The episode to load
    const episode = gameData.get(urlParams.get('episodeId') ? `Game02.${urlParams.get('episodeId')}` : 'Game02.Episode01', 'episode');
    if (episode == null) {
      return;
    }

    // The phase to "load"
    const currentPhase = (() => {
      const phaseId = urlParams.get('phaseId') ?? episode.phases[0]?.id;
      return episode?.phases.find(({ id }) => id === phaseId);
    })();
    dispatch(setCurrentPhase(currentPhase));
    if (currentPhase == null) {
      return;
    }

    // Username for applied data entities.
    const username = urlParams.get('username') ?? store.getState().user.username;

    // Probability % of missing non-critical evidence.
    const missProbability = (() => {
      const tryParse = parseFloat(urlParams.get('missProbability') ?? '0');
      return Number.isNaN(tryParse) ? 0 : tryParse;
    })();

    // Set up the gameState machine;
    setGameState({ investigationPhase: 'choose' }, {});

    // Calculate phases and leads to complete.
    // This will be used for adding cards/clues and marking them as complete afterwards.
    const isPhaseEnd = urlParams.get('isPhaseEnd') != null;
    const currentPhaseIndex = episode.phases.indexOf(currentPhase);
    const phasesToComplete = episode.phases.slice(0, currentPhaseIndex + (isPhaseEnd ? 1 : 0));
    const phaseIdsToComplete = phasesToComplete.map(({ id }) => id);
    const leadsToComplete = phasesToComplete.flatMap((phase) => phase.getLeads());

    // Set of Evidence to add.
    const allEvidence = new Map<EvidenceData, Set<string>>();

    // Add episode-specific evidence to start.
    compileDataFromEvidence(episode.evidence, { memo: allEvidence, missProbability });

    // Add evidence from all leads.
    leadsToComplete.forEach((lead) => {
      compileDataFromEvidence(lead.evidence, { memo: allEvidence, missProbability });
    });

    // Generate AppliedData for all evidence, and try to guess at the Phase it was added.
    // Filter out any evidence that is from a phase beyond what is complete, and collect the Evidence IDs.
    const evidenceEntitiesToApply = generateAppliedDataFromCompiledData(allEvidence, {
      entryUsername: username,
    }).filter(({ phase }) => phase == null || phaseIdsToComplete.includes(phase));
    const evidenceEntityIds = new Set(evidenceEntitiesToApply.map(({ id }) => id));
    devLog('Applying cards and clues...', evidenceEntitiesToApply);
    dispatch(applyData(evidenceEntitiesToApply));

    // Apply completed Leads.
    const leadEntitiesToApply = leadsToComplete.map<AppliedLeadEntity>((lead) => ({
      id: lead.id,
      type: 'lead',
      user: username,
      date: Date.now(),
      phase: lead.getPhase()?.id,
    }));
    devLog('Applying completed leads...', leadEntitiesToApply);
    dispatch(applyData(leadEntitiesToApply));

    // Generate Journal Entries for all evidence.
    // Filter out any evidence that doesn't have associated AppliedData.
    const journalEntriesToAdd = generateJournalEntriesFromCompiledData(allEvidence).filter(({ id }) =>
      evidenceEntityIds.has(extractIdsFromEntry(id).entryId),
    );
    devLog('Adding journal entries...', journalEntriesToAdd);
    dispatch(addEntries(journalEntriesToAdd));
  }, [setGameState, store, dispatch]);

  // Generate matched content to display every time the state or matchers change.
  const matchedContent = React.useMemo(
    () => getMatcherResults(gameState, stateMatchers, stateMatcherOptions),
    [gameState],
  );

  return <>{matchedContent}</>;
};

export default Game02DevEvidenceTest;
