import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { shallowEqual } from 'react-redux';
import { NIL as NIL_UUID } from 'uuid';

import { UserInfo } from '../lib/socket/schemas';

import type { RootState } from '.';

export interface UserStateInterface extends UserInfo {}

// the initial user state
const initialState: UserStateInterface = {
  username: NIL_UUID,
  display_name: 'Local Player',
  requesting_help: false,
  location: '',
  session_slug: NIL_UUID,
  start_time: null,
  end_time: null,
};

/**
 * User Slice definition
 */
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserValues: (state, { payload }: PayloadAction<Partial<UserStateInterface>>) => Object.assign(state, payload),
  },
});

/**
 * Checks if username and session slug indicate an unregistered user or session.
 * Ideally, this should indicate if the player is running a "local" vs "online/event" session.
 */
export const selectUserIsRegistered = ({ user }: RootState) =>
  user.username !== NIL_UUID &&
  user.username.length !== 0 &&
  user.session_slug != null &&
  user.session_slug.length !== 0 &&
  user.session_slug !== NIL_UUID;

export interface SelectAllUsersResult {
  /* _All_ users (local and remote). Sorted by username. */
  allUsers: UserInfo[];
  /* The local user. */
  localUser: UserInfo;
  /* Remote users (users in the session that aren't the local user). */
  remoteUsers: UserInfo[];
}

/** Fancy memoizing selector for getting info about users. */
export const selectAllUsers = createSelector(
  [({ user }: RootState) => user, ({ session }: RootState) => session.users],
  (localUser, sessionUsers): SelectAllUsersResult => {
    const remoteUsers = sessionUsers.filter(({ username }) => username !== localUser.username);
    const allUsers = [localUser, ...remoteUsers].sort((a, b) => a.username.localeCompare(b.username));
    return Object.freeze({
      allUsers,
      localUser,
      remoteUsers,
    } as const);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: (a: SelectAllUsersResult, b: SelectAllUsersResult) => shallowEqual(a.allUsers, b.allUsers),
    },
  },
);

/**
 * Grouped export for userSlice actions.
 */
export const actions = Object.freeze({
  ...userSlice.actions,
});

/**
 * Grouped export for userSlice actions.
 */

export const { setUserValues } = actions;
