import * as React from 'react';
import { isEqual } from 'lodash';
import { TFunction } from 'react-i18next';
import Screen from '../../../../../components/Global/Screen/Screen';
import Modal from '../../../../../components/Game01/Modal/Modal';
import Unlock, { TaskState, useUnlock } from '../../../../../components/Game01/Interactions/Unlock/Unlock';
import Lock from '../../../../../components/Game01/Interactions/Unlock/Lock';
import Button from '../../../../../components/Global/Button/Button';
import { ComponentProps } from '../../../../task-types';

// Todo: Update all previous locks to use localization!
const initialTaskState = (t: TFunction<(string | 'common')[]>): TaskState => ({
  1: {
    order: 0,
    label: t('A030.locks.1.label'),
    data: [
      { key: 1, text: t('A030.locks.1.options.1'), disabled: false },
      { key: 2, text: t('A030.locks.1.options.2'), disabled: false },
      { key: 3, text: t('A030.locks.1.options.3'), disabled: false },
    ],
  },
  2: {
    order: 1,
    label: t('A030.locks.2.label'),
    data: [
      { key: 1, text: t('A030.locks.2.options.1'), disabled: false },
      { key: 2, text: t('A030.locks.2.options.2'), disabled: false },
      { key: 3, text: t('A030.locks.2.options.3'), disabled: false },
    ],
  },
  3: {
    order: 2,
    label: t('A030.locks.3.label'),
    data: [
      { key: 1, text: t('A030.locks.3.options.1'), disabled: false },
      { key: 2, text: t('A030.locks.3.options.2'), disabled: false },
      { key: 3, text: t('A030.locks.3.options.3'), disabled: false },
      { key: 4, text: t('A030.locks.3.options.4'), disabled: false },
    ],
  },
});

export default function A030({ t, state, send, match }: ComponentProps): JSX.Element {
  const { lockRefs, handleCombinationChange, taskState, resetOptions, combinationState } = useUnlock(
    initialTaskState(t),
    [3, 2, 4],
    match('walkthrough'),
    state.walkthroughStep,
  );

  const renderLocks = React.useMemo(() => {
    return Object.keys(taskState).map((key, optionsIndex) => {
      return (
        <Lock
          ref={lockRefs[taskState[key].order]}
          key={key}
          label={taskState[key].label}
          optionKey={key}
          options={taskState[key].data}
          hint={t(`A030.locks.${key}.hint`)}
          onChange={(optionKey: number | string) => handleCombinationChange(optionsIndex, optionKey)}
          answerKey={combinationState.combination[optionsIndex]}
          walkthroughCallback={() => send('NEXT')}
        />
      );
    });
  }, [handleCombinationChange, combinationState.combination, lockRefs, send, t, taskState]);

  return (
    <Screen>
      <Unlock
        title={t('A030.title')}
        description={t('A030.body')}
        hint={t('A030.subhead')}
        maxAttempts={3}
        attemptsMade={state.attempts}
        showWalkthrough={match('walkthrough')}
        onSuccess={() => send('NEXT')}
        onError={() => {
          resetOptions();
          send('CLICK.SUBMIT_INCORRECT');
        }}
        isUnlocked={isEqual(combinationState.combination, combinationState.currentCombination)}
      >
        {renderLocks}
      </Unlock>
      <Modal
        sidebar="character"
        isOpen={match('incorrect') && state.attempts < 2}
        footer={<Button isPrimary label={t('common.errorTitle')} onClick={() => send('NEXT')} />}
        title={t('A030.error.title')}
        body={t('A030.error.body')}
      />
      <Modal
        sidebar="character"
        isOpen={match('incorrect') && state.attempts === 2}
        footer={<Button isPrimary label={t('A030.walkthrough.nextLabel')} onClick={() => send('CLICK.WALKTHROUGH')} />}
        title={t('A030.walkthrough.title')}
      />
    </Screen>
  );
}
