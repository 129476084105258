import { createMachine, assign, send } from 'xstate';
import { TaskConfig, TaskOptions, InteractionTaskContext, TaskEvent } from '../../../task-types';
import { addScore, getDefaultOptions } from '../../../task-options';
import { store as reduxStore, getCurrentLeadId } from '../../../../store';

const initialState: InteractionTaskContext = {
  // One tracker for all attempts; will get reset. Consider changing this
  attempts: 0,
  walkthroughStep: 0,
  maxAttempts: 3,
  store: reduxStore,
};
// initialzing macro definitions
const POINTS_VALUE = 36;
const TASK_ID = 'Game01.Episode02.Task11';

export const Task11Config: TaskConfig<InteractionTaskContext, TaskEvent> = {
  id: 'Task11',
  context: initialState,
  initial: 'K020',
  states: {
    K020: {
      // Desktop Select - 3 chances to click correctly - 2 correct answers
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
            'CLICK.PARTIALCORRECT': {
              target: 'partialcorrect',
            },
          },
        },
        incorrect: {
          always: {
            target: 'hint',
            cond: (ctx) => ctx.attempts === 3,
          },
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        hint: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        partialcorrect: {
          always: {
            target: 'hint',
            actions: 'setAttempts',
          },
        },
        final: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: '#Task11.K030',
          },
        },
      },
    },
    K030: {
      // Multi-choice single answer 3 buttons
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        hint: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: {
              target: '#Task11.K035',
            },
          },
        },
      },
    },
    K035: {
      // Interstitial
      entry: 'resetContext',
      on: {
        NEXT: {
          target: '#Task11.K040',
        },
      },
    },
    K040: {
      // Combo unlock - slight variation from other combo locks - shows a succes modal at the end
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.SUBMIT_INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'CLICK.CORRECT': {
              target: 'correct',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          on: {
            'NEXT': {
              target: 'decision',
            },
            'CLICK.WALKTHROUGH': {
              target: 'walkthrough',
            },
          },
        },
        correct: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        walkthrough: {
          always: {
            target: 'correct',
            cond: (ctx) => ctx.walkthroughStep === 3,
          },
          on: {
            NEXT: {
              actions: 'incrementWalkthroughStep',
              target: 'walkthrough',
            },
          },
        },
        final: {
          // calling addscore on entry in the final state
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: { target: '#Task11.K050' },
          },
        },
      },
    },
    K050: {
      // Multi-choice single answer 3 buttons
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        final: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: {
              target: '#Task11.K060',
            },
          },
        },
      },
    },
    K060: {
      // Multi-choice single answer 3 buttons
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        final: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: {
              target: '#Task11.K070',
            },
          },
        },
      },
    },
    K070: {
      // Multi-choice single answer 3 buttons
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.INCORRECT': {
              actions: 'incrementAttempts',
              target: 'incorrect',
            },
            'NEXT': {
              target: 'final',
            },
          },
        },
        incorrect: {
          on: {
            NEXT: {
              target: 'decision',
            },
          },
        },
        final: {
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: {
              target: '#Task11.K080',
            },
          },
        },
      },
    },
    K080: {
      // Evidence collection
      initial: 'decision',
      entry: 'resetContext',
      states: {
        decision: {
          on: {
            'CLICK.SUBMIT_INCORRECT': {
              target: 'feedback',
            },
            'NEXT': {
              target: 'success',
            },
          },
        },
        feedback: {
          on: {
            NEXT: {
              target: 'feedbackComplete',
            },
          },
        },
        feedbackComplete: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        success: {
          on: {
            NEXT: {
              target: 'final',
            },
          },
        },
        final: {
          // calling addscore on entry in the final state
          entry: [
            addScore({ amount: POINTS_VALUE, id: getCurrentLeadId(TASK_ID) }),
            send('NEXT'),
          ],
          on: {
            NEXT: { target: '#Task11.K090' },
          },
        },
      },
    },
    K090: {
      // Interstitial
      entry: 'resetContext',
      on: {
        NEXT: {
          target: '#Task11.K020',
        },
      },
    },
  },
};

/**
 * Additional machine options.
 */
export const Task11Options: TaskOptions<InteractionTaskContext, TaskEvent> = {
  actions: {
    resetContext: assign({ attempts: () => 0, walkthroughStep: () => 0 }),
    incrementAttempts: assign({ attempts: (context) => context.attempts + 1 }),
    incrementWalkthroughStep: assign({ walkthroughStep: (context) => context.walkthroughStep + 1 }),
    setAttempts: (context) => {
      context.attempts = 4;
    },
    setMaxAttemptsToTwo: assign({
      attempts: (context) => context.attempts,
      walkthroughStep: (context) => context.walkthroughStep,
      maxAttempts: (context) => {
        if (context.maxAttempts) {
          context.maxAttempts -= 1;
        }
        return 2;
      },
    }),
  },

  guards: {},
};

/**
 * Machine constructor.
 */
export function createTask11() {
  return createMachine<InteractionTaskContext, TaskEvent>(Task11Config, getDefaultOptions()).withConfig(Task11Options);
}
