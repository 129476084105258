import type { CardOrEntryLocaleContent, LocaleContent } from "../../types";

/**
 * i18Next `entries:Episode02` Cards and  Entry content
 */
export const Episode02CardAndEntries: CardOrEntryLocaleContent = {
  //
  // Cards & Related Non-Lead Clues for Phase 1+
  //
  Player1Card: {
    heading: "Player 1",
    default: "Player 1 is one of the newest members of the Best For You Organics family. They're working directly with our CIO Andrea Divkovic to protect the company.",
  },
  Player2Card: {
    heading: "Player 2",
    default: "Player 2 is one of the newest members of the Best For You Organics family. They're working directly with our CIO Andrea Divkovic to protect the company.",
  },
  Player3Card: {
    heading: "Player 3",
    default: "Player 3 is one of the newest members of the Best For You Organics family. They're working directly with our CIO Andrea Divkovic to protect the company.",
  },
  BestForYouOrganics: {
    heading: "Best For You Organics",
    default: "Best For You Organics is one of the largest organic e-retailers in the U.S.",
  },
  AboutBFYO: {
    heading: "About Best For You Organics",
    default: "A selection of facts about Best For You Organics for new employees.",
    BFYOFact1: "Founded by Abigail and Olivia Jackson in 2009.",
    BFYOFact2: "Has over 10 million subscribers in the U.S.",
  },
  AndreaDivkovic: {
    heading: "Andrea Divkovic",
    default: "Andrea Divkovic (she/her) is the CIO of Best For You Organics.",
  },
  AboutAndrea: {
    heading: "About Andrea",
    default: "Age: 51, Alma Mater: University of Illinois at Urbana-Champaign, Hometown: Terre Haute, Indiana, Favorite food: coffee, Pets: a Philodendron named Bunny",
    AndreaFact1: "Reports directly to CEO Abigail Jackson.",
    AndreaFact2: "Joined Best For You Organics 8 months ago to shephard the IT department through the company's EU expansion.",
    AndreaFact3: "Personally chose the three newest employees of the IT team after an extensive search.",
  },
  AbigailJackson: {
    heading: "Abigail Jackson",
    default: "Abigail Jackson (she/her) is co-founder and CEO of Best For You Organics.",
  },
  AboutAbigail: {
    heading: "About Abigail",
    default: "Age: 50, Alma Mater: Stanford, Hometown: Richland, WA, Favorite food: Sushi, Pets: none (no time)",
    AbigailFact1: "Began her first start up while still in college in the Bay Area.",
    AbigailFact2: "Talked her older sister, Olivia, into turning the family farm's CSA into an e-retailer while home for the holidays in 2009.",
    AbigailFact3: "Abigail is determined to make Best For You Organics the largest organics e-commerce site in the world.",
  },
  OliviaJackson: {
    heading: "Olivia Jackson",
    default: "Olivia Jackson (she/her) is co-founder, VP of Quality, and Brand Ambassador of Best For You Organics.",
  },
  AboutOlivia: {
    heading: "About Olivia",
    default: "Age: 52, Alma Mater: Oregon State University, Hometown: Richland, WA, Favorite food: roasted brussel sprouts, Pets: none, but she maintains a 30 beehive apiary in her yard.",
    OliviaFact1: "Got her BS in agricultural sciences and turned the family farm organic for her thesis project.",
    OliviaFact2: "Chosen as the face of the company because she's an actual farmer and the co-founder of the company.",
    OliviaFact3: 'Olivia cares most that Best For You Organics lives up to its motto: "Best For the Planet, Best For You."',
  },
  MontyDubey: {
    heading: "Monty Dubey",
    default: "Monty Dubey (he/him) is the CPO of Best For You Organics",
  },
  AboutMonty: {
    heading: "About Monty",
    default: "Age: 50, Alma Mater: Brown University, Hometown: Delhi, India, Favorite food: his mother's aloo gosht, Pets: tortoisehell tomcat named Leonardo.",
    MontyFact1: "Reports directly to CEO Abigail Jackson.",
    MontyFact2: "Was one of the first employees Abigail and Olivia hired at the start of the company.",
  },
  AlexGagnon: {
    heading: "Alex Gagnon",
    default: "Alex Gagnon (she/they) is an HR Administrator with Best for You Organics.",
  },
  AboutAlex: {
    heading: "About Alex",
    default: "Age: 27, Alma Mater: Villanova, Hometown: Denver, CO, Favorite food: chili fries, Pets: Golden Labrador (Izzy)",
  },
  GaelTorres: {
    heading: "Gael Torres",
    default: "Gael Torres (he/him) is the CMO for Best For You Organics.",
  },
  AboutGael: {
    heading: "About Gael",
    default: "Age: 57, Alma Mater: Missouri State University, Hometown: St. Louis, MO, Favorite food: breakfast burrito, Pets: a hedgehog named Julian",
    GaelReport: "He answers directly to CEO Abigail Jackson.",
  },
  QuinnAnderson: {
    heading: "Quinn Anderson",
    default: "Quinn Anderson (he/him) is an ID admin for Managed Identities with Best for You Organics.",
  },
  AboutQuinn: {
    heading: "About Quinn",
    default: "Age: 52, Alma Mater: Columbia College, Hometown: Philadelphia, PA, Favorite food: lasagna, Pets: tropical fish (various)",
  },
  SecurityAlert: {
    heading: "Security Alert",
    default: "",
  },
  //
  // Cards & Related Non-Lead Clues for Phase 2+
  //
  PhishingEmail: {
    heading: "Phishing Email",
    default: "",
  },
  BFYOAdmin: {
    heading: "Best For You Organics Admin",
    shortHeading: "BFYO Admin",
    default: "This account has administrator level access to Best For You Organics' systems and databases.",
  },
  GitHubRepository: {
    heading: "GitHub Repository",
    default: "",
  },
  ManagedIdentity: {
    heading: "Managed Identity",
    default: "",
  },
  AppRegistration: {
    heading: "App Registration",
    default: "",
  },
  PolicySettings: {
    heading: "Policy Settings",
    default: "",
  },
  eCommerceApp: {
    heading: "eCommerce App",
    default: "",
  },
  WennyGultom: {
    heading: "Wenny Gultom",
    default: "Wenny Gultom (she/her) is a Commerce App Developer for Best For You Organics.",
  },
  AboutWenny: {
    heading: "About Wenny",
    default: "Age: 35, Alma Mater: University of Technology Bandung, Hometown: Jakarta, Indonesia, Favorite food: nasi goreng, Pets: German Shephard mobility service dog named Max",
  },
  OAuthApp: {
    heading: "OAuth App",
    default: "",
  },
  FreyMunch: {
    heading: "Frey Munch",
    default: "Frey Munch (he/him) is an Identity Admin for Cloud Apps for Best For You Organics.",
  },
  AboutFrey: {
    heading: "About Frey",
    default: "Age: 40, Alma Mater: IT University of Copenhagen, Hometown: Copenhagen, Denmark, Favorite food: sushi, Pets: Sphynx cat named Jordi.",
  },
};

export const Episode02Lead: LocaleContent = {
  header: "Episode 02 Non-Lead Content",
};
