import * as React from 'react';
import Screen from '../../../../../components/Global/Screen/Screen';
import Modal from '../../../../../components/Game01/Modal/Modal';
import background from '../images/Q02.jpeg';
import { ComponentProps } from '../../../../task-types';
import MultipleChoice, { useMultipleChoice } from '../../../../../components/Global/Interactions/MultipleChoice/MultipleChoice';
import Button from '../../../../../components/Global/Button/Button';

export default function Q02({ t, send, match }: ComponentProps): JSX.Element {
  const { handleIncorrect } = useMultipleChoice(() => send('CLICK.HINT'));
  return (
    <Screen>
      <MultipleChoice
        questionText={t('Q02.body')}
        didComplete={match('final')}
        onComplete={() => send('NEXT')}
        backgroundImage={background}
      >
        <Button
          isPrimary
          onClick={() => handleIncorrect(1)}
          label={t('Q02.options.1.label')}
          icon={match('final') ? 'check' : undefined}
        />
        <Button
          isPrimary
          label={t('Q02.options.2.label')}
          onClick={() => !match('final') && send('NEXT')}
          disabled={match('final')}
        />
      </MultipleChoice>
      <Modal
        sidebar="character"
        isOpen={match('hint')}
        footer={<Button isPrimary label={t('buttons.again')} onClick={() => send('NEXT')} />}
        onDismiss={() => send('NEXT')}
        title={t('Q02.hint.title')}
        body={t('Q02.hint.body')}
        hideDismissButton
      />
    </Screen>
  );
}
