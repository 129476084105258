import React from 'react';
import { Stack, IStackStyles, IStackItemStyles } from '@fluentui/react';
import DevGameRoutes from './DevGameRoutes';
import { getMatcherResults } from '../../../lib/xstate/matchers';
import { useGameService } from '../../../state/GlobalStateProvider';
import { getDefaultStateMatcherOptions, getDefaultStateMatchers } from '../../../state/defaultStateMatchers';
import { selectCurrentGame } from '../../../store/multiplayer-slice';
import { useAppStore } from '../../../store';

/**
 * Scrollable wrapper inside content area.
 * Allows content to overflow outer wrapper.
 */
const contentScrollableStackStyles: IStackStyles = {
  root: {
    overflow: 'auto',
    height: '100vh',
  },
};

/**
 * Content wrapper inside scrollable container.
 * Allows additional control over content inside scrollable area (like min-size).
 */
const contentStackItemStyles: IStackItemStyles = {
  root: {},
};

interface IGameContentProps {
  useDevRoutes?: boolean;
}

// Generate the list of content to match on once.
const stateMatcherOptions = getDefaultStateMatcherOptions();

const GameContent: React.FC<IGameContentProps> = ({ useDevRoutes }) => {
  const { state } = useGameService();
  const store = useAppStore();
  const currentGame = selectCurrentGame(store.getState());

  const stateMatchers = getDefaultStateMatchers(currentGame?.shortId);
  // Generate matched content to display every time the state or matchers change.
  const matchedContent = React.useMemo(() =>
    getMatcherResults(state, stateMatchers, stateMatcherOptions), [state, stateMatchers]);

  return (
    <Stack.Item>
      <Stack role="main" grow={1} styles={contentScrollableStackStyles}>
        <Stack.Item grow={1} styles={contentStackItemStyles}>
          {useDevRoutes ? <DevGameRoutes /> : <>{matchedContent}</>}
        </Stack.Item>
      </Stack>
    </Stack.Item>
  );
};

export default GameContent;
