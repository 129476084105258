import i18n from '../../../../i18n';
import { ReviewItemDefinition } from '../../../../lib/game-data/types';

export const Phase01Review: ReviewItemDefinition[] = [
  {
    type: 'Dialog',
    title: i18n.t('Game02.Episode01.Phase01Review:dialog.intro.title'),
    body: i18n.t('Game02.Episode01.Phase01Review:dialog.intro.body'),
    nextLabel: i18n.t('common:buttons.letsGo'),
    character: 'andrea',
  },
  {
    type: 'Question',
    title: i18n.t('Game02.Episode01.Phase01Review:question.title'),
    body: i18n.t('Game02.Episode01.Phase01Review:question.body'),
    nextLabel: i18n.t('common:buttons.submit'),
    scoreMultiplier: 4,
    feedbackTitle: i18n.t('Game02.Episode01.Phase01Review:question.feedbackTitle'),
    feedbackBody: i18n.t('Game02.Episode01.Phase01Review:question.feedbackBody'),
    feedbackNextLabel: i18n.t('Game02.Episode01.Phase01Review:question.feedbackNextLabel'),
    answers: [
      {
        text: i18n.t('Game02.Episode01.Phase01Review:question.optionOne'),
        isCorrect: false,
      },
      {
        text: i18n.t('Game02.Episode01.Phase01Review:question.optionTwo'),
        isCorrect: true,
      },
      {
        text: i18n.t('Game02.Episode01.Phase01Review:question.optionThree'),
        isCorrect: false,
      },
    ],
    character: 'andrea',
  },
  {
    type: 'Question',
    title: i18n.t('Game02.Episode01.Phase01Review:proof.title'),
    body: i18n.t('Game02.Episode01.Phase01Review:proof.body'),
    nextLabel: i18n.t('common:buttons.submit'),
    scoreMultiplier: 4,
    feedbackTitle: i18n.t('Game02.Episode01.Phase01Review:proof.feedbackTitle'),
    feedbackBody: i18n.t('Game02.Episode01.Phase01Review:proof.feedbackBody'),
    feedbackNextLabel: i18n.t('Game02.Episode01.Phase01Review:proof.feedbackNextLabel'),
    answers: [
      {
        text: i18n.t('Game02.Episode01.Phase01Review:proof.optionOne'),
        isCorrect: false,
        evidenceId: '',
      },
      {
        text: i18n.t('Game02.Episode01.Phase01Review:proof.optionTwo'),
        isCorrect: false,
        evidenceId: '',
      },
      {
        text: i18n.t('Game02.Episode01.Phase01Review:proof.optionThree'),
        isCorrect: true,
        evidenceId: '',
      },
    ],
    character: 'andrea',
  },
  {
    type: 'Dialog',
    title: i18n.t('Game02.Episode01.Phase01Review:dialog.final.title'),
    body: i18n.t('Game02.Episode01.Phase01Review:dialog.final.body'),
    nextLabel: i18n.t('Game02.Episode01.Phase01Review:dialog.final.nextBtn'),
    character: 'andrea',
  },
];
