import { z } from 'zod';

export const TextComponent = z.enum(['Heading', 'Label', 'Body']);
type TextComponentType = z.infer<typeof TextComponent>;

/**
 * Map props to a specific fluentUI font theme string
 */
export default function getFontVariant(name: TextComponentType, level: number) {
  if (name === TextComponent.enum.Heading) {
    switch (level) {
      case 1:
        return 'superLarge';
      case 2:
        return 'xxLargePlus';
      case 3:
        return 'xxLarge';
      case 4:
        return 'xLargePlus';
      case 5:
        return 'medium';
      default:
        return 'superLarge';
    }
  } else if (name === TextComponent.enum.Label) {
    switch (level) {
      case 1:
        return 'mediumPlus';
      case 2:
        return 'small';
      case 3:
        return 'xSmall';
      default:
        return 'mediumPlus';
    }
  } else if (name === TextComponent.enum.Body) {
    switch (level) {
      case 1:
        return 'xLarge';
      case 2:
        return 'large';
      case 3:
        return 'smallPlus';
      case 4:
        return 'xSmall';
      case 5:
        return 'tiny';
      default:
        return 'xLarge';
    }
  }

  return 'xLarge';
}
