import { Stack, Text, Modal } from '@fluentui/react';
import React from 'react';

import Button from '../../Global/Button/Button';
import getFontVariant, { TextComponent } from '../../Global/Text/getFontVariant';
import LeadReviewCollectedEvidence from './LeadReviewCollectedEvidence';
import LeadReviewUnCollectedEvidence from './LeadReviewUnCollectedEvidence';

interface ILeadReviewModalProps {
  isOpen: boolean;
  heading: string;
  description: string;
  buttonLabel: string;
  onClick?: () => void;
  isCollected: boolean;
}

const LeadReviewModal: React.FC<ILeadReviewModalProps> = ({
  isOpen,
  heading,
  description,
  buttonLabel,
  onClick,
  isCollected,
}) => {
  return (
    <Modal isOpen={isOpen} isBlocking styles={{ main: { maxWidth: '1040px' } }}>
      <Stack tokens={{ padding: '40px', childrenGap: '8px' }}>
        <Stack.Item>
          <Text variant={getFontVariant(TextComponent.enum.Heading, 2)}>{heading}</Text>
        </Stack.Item>
        <Stack.Item>
          <Text variant={getFontVariant(TextComponent.enum.Body, 1)}>{description}</Text>
        </Stack.Item>

        <Stack horizontal horizontalAlign="center" tokens={{ padding: '32px', childrenGap: '32px' }}>
          {isCollected ? <LeadReviewCollectedEvidence /> : <LeadReviewUnCollectedEvidence />}
        </Stack>
        <Stack.Item align="end">
          <Button isPrimary label={buttonLabel} onClick={onClick} />
        </Stack.Item>
      </Stack>
    </Modal>
  );
};

export default LeadReviewModal;
