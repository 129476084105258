/**
 * i18next NS: `Episode01.Task01`
 */
export default {
  N01: {
    body: `<p>You probably have a lot of questions about how you're going to set up the storage system for this data initiative.</p>

    <p>I've set up a meeting with you, Mr. Trentini, and Floris Krigel, our Chief Data Officer. They can give you all the information you need for this initial build—as long as you ask the right questions!</p>
          
    <p>-Suchaow</p>`,
    branches: [
      {
        option: "Start",
        goTo: "N02",
      },
    ],
  },

  N02: {
    body: `<p>Your meeting with the CEO and CDO (Chief Data Officer) starts out smoothly. Like most executives, they're pressed for time. You quickly get to the point.</p>

    <p>MATTIA TRENTINI: So, what do you want to know?</p>
          
    <p>FLORIS KRIGEL: I'm happy to answer any questions you have about the existing data infrastructure here at Trey Research.</p>
          
    <p>You say...</p>`,
    branches: [
      {
        option: "Okay, here's my first question...",
        goTo: "N03",
      },
    ],
  },

  N03: {
    body: `You ask...`,
    branches: [
      {
        option: "ReFed has provided 5 data files. Which of these will we need?",
        goTo: "N04",
      },
      {
        option: "Where should we store the data that we use?",
        goTo: "N05",
      },
      {
        option: "How often will we be running analysis operations?",
        goTo: "N06",
      },
      {
        option: "What else should I know about Trey Research's data infrastructure?",
        goTo: "N07",
      },
      {
        option: "What data security risks should we be considering at this point?",
        goTo: "N08",
      },
    ],
  },

  N04: {
    body: `<p>FLORIS KRIGEL: I'm not sure what's in those CSV files off-hand. I'd say use your best judgment.</p>

    <p>MATTIA TRENTINI: The data needs to help us analyze food waste data in relation to our key metrics. Check with the Data Analyst on your team for more details on what our metrics will be.</p>

    <p>You say...</p>`,
    branches: [
      {
        option: "Maybe we'll simply load all the CSV data into the data warehouse.",
        goTo: "N09",
      },
      {
        option: "I think we'll load all the CSV files into the data lake.",
        goTo: "N10",
      },
    ],
  },

  N05: {
    body: `<p>FLORIS KRIGEL: Well, that will be up to you. As you know, we have an Azure Storage account and four databases. Just be sure to adhere to our data lake conventions.</p>

    <p>You say...</p>`,
    branches: [
      {
        option: "Can't we simply pull the data from ReFed when we need it?",
        goTo: "N11",
      },
      {
        option: "Where can I find those conventions again?",
        goTo: "N12",
      },
      {
        option: "No problem!",
        goTo: "N03",
      },
    ],
  },

  N06: {
    body: `<p>MATTIA TRENTINI: As we narrow down what solution we want to execute, we'll bring in transactional data at least daily.</p>

    <p>You say...</p>`,
    branches: [
      {
        option: "Okay. I'm just thinking about how fast and responsive we need the dashboard to be.",
        goTo: "N13",
      },
      {
        option: "It sounds like we'll need to support automated analysis.",
        goTo: "N14",
      },
    ],
  },

  N07: {
    body: `FLORIS KRIGEL: In general, only the data that's needed to run a specific analysis should be housed in the data warehouse. We want to keep costs down, and make sure the warehouse infrastructure stays quick and responsive.`,
    branches: [
      {
        option: "Okay. I have one or two more things to ask.",
        goTo: "N03",
      },
      {
        option: "Great! I think I have what I need.",
        goTo: "N20",
      },
    ],
  },

  N08: {
    body: `<p>Mattia looks at Floris.</p>

    <p>MATTIA TRENTINI: This will be an internal tool, so I can't think of anything specific relating to security. Can you?</p>

    <p>FLORIS KRIGEL: I can't think of any specific considerations at this point, either. As long as your team follows the standard data practices that we have at Trey Research, we should be fine.</p>`,
    branches: [
      {
        option: "Okay, thank you.",
        goTo: "N03",
      },
      {
        option: "Well, that's all the questions I have for now.",
        goTo: "N20",
      },
    ],
  },

  N09: {
    body: `<p>FLORIS KRIGEL: That's your call. Just consider that having unnecessary data in the warehouse leads to higher storage costs for Trey Research, and it might eventually slow down your dashboard.</p>

    <p>You say...</p>`,
    branches: [
      {
        option: "So maybe we should only load the data that's needed for making a recommendation.",
        goTo: "N15",
      },
      {
        option: "I've got it. Now, for my next question...",
        goTo: "N03",
      },
    ],
  },

  N10: {
    body: `<p>FLORIS KRIGEL: Loading all the data from the CSVs into the data lake makes sense to me. Go for it!</p>

    <p>MATTIA TRENTINI: What else do you want to know?</p>`,
    branches: [
      {
        option: "I do have one or two more things to ask.",
        goTo: "N03",
      },
      {
        option: "I think I have what I need to get started.",
        goTo: "N20",
      },
    ],
  },

  N11: {
    body: `<p>FLORIS KRIGEL: I believe this data must be retained within the company for audit purposes.</p>

    <p>MATTIA TRENTINI: That's correct.</p>

    <p>You say...</p>`,
    branches: [
      {
        option: "That makes sense.",
        goTo: "N16",
      },
      {
        option: "Let me pivot to a different line of questioning.",
        goTo: "N03",
      },
    ],
  },

  N12: {
    body: `<p>FLORIS KRIGEL: I'll double-check that you have access to the SharePoint that has the documentation.</p>

    <p>HINT: Background information on Trey Research's infrastructure is available in the game's sidebar menu.</p>

    <p>You say...</p>`,
    branches: [
      {
        option: "Thanks, Floris.",
        goTo: "N17",
      },
    ],
  },

  N13: {
    body: `<p>MATTIA TRENTINI: I just can't say for certain. Once a day might do it.</p>

    <p>You say...</p>`,
    branches: [
      {
        option: "Okay, that's good to know.",
        goTo: "N03",
      },
      {
        option: "All right. I think I have what I need.",
        goTo: "N20",
      },
    ],
  },

  N14: {
    body: `<p>FLORIS KRIGEL: I agree; it may be best to do something more automated. That way, leadership won't have to request your team's efforts every time they need a slightly different report.</p>

    <p>MATTIA TRENTINI: And I imagine you wouldn't want us calling you that frequently, either...</p>

    <p>You say...</p>`,
    branches: [
      {
        option: "Got it. We'll set up automation so leadership can run reports with the freshest data at any time.",
        goTo: "N18",
      },
    ],
  },

  N15: {
    body: `<p>FLORIS KRIGEL: In general, only the data that's needed to run a specific analysis should be housed in the data warehouse. We want to keep costs down, and make sure the warehouse infrastructure stays quick and responsive.</p>`,
    branches: [
      {
        option: "Okay. I have one or two more things to ask.",
        goTo: "N03",
      },
      {
        option: "Great! I think I have what I need.",
        goTo: "N20",
      },
    ],
  },

  N16: {
    body: `<p>MATTIA TRENTINI: Plus, we should try to reduce the cost impact on ReFed when using the dataset. It's just the right thing to do.</p>

    <p>You say...</p>`,
    branches: [
      {
        option: "Absolutely.",
        goTo: "N19",
      },
    ],
  },

  N17: {
    body: `FLORIS KRIGEL: Again, I'm happy to help. Just tell me what you need for your team.`,
    branches: [
      {
        option: "I do have one or two more things to ask.",
        goTo: "N03",
      },
      {
        option: "I think I have all the info I need for now.",
        goTo: "N20",
      },
    ],
  },

  N18: {
    body: `FLORIS KRIGEL: Perfect. What else do you want to know?`,
    branches: [
      {
        option: "I do have one or two more things to ask.",
        goTo: "N03",
      },
      {
        option: "I think I have what I need.",
        goTo: "N20",
      },
    ],
  },

  N19: {
    body: `MATTIA TRENTINI: I'm glad you agree. What other topics do you have for us?`,
    branches: [
      {
        option: "I do have one or two more things to ask.",
        goTo: "N03",
      },
      {
        option: "I think I have what I need, actually.",
        goTo: "N20",
      },
    ],
  },

  N20: {
    body: `ACTIVITY COMPLETE</br></br>

          Your meeting with Floris and Mattia concludes. Here’s what you learned about the data storage requirements for your upcoming build:</br></br>

          ???
          
          ???
          
          ???
          
          ???
          
          ???
          
          <ul>
          
          <li>It's likely that the data analyst will need to support views of the data based on solution priority area, solution group, and solution name.</li>
          
          </ul>`,
    branches: [
      {
        option: "Start Over",
        goTo: "N01",
      },
    ],
  },
};
