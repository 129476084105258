import * as React from 'react';
import Screen from '../../../../../components/Global/Screen/Screen';
import { ComponentProps } from '../../../../task-types';
import Dialog from '../../../../../components/Global/Dialog/Dialog';
import { useCompleteActivity } from '../../../../../lib/hooks/useCompleteLead';

export default function B030({ t }: ComponentProps): JSX.Element {
  const { completeActivity, completeLabel } = useCompleteActivity();

  return (
    <Screen>
      <Dialog
        title={t('B030.title')}
        body={t('B030.body')}
        onClick={() => completeActivity()}
        nextLabel={completeLabel}
        character="andrea"
        background="office"
      />
    </Screen>
  );
}
