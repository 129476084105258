/**
 * Schema validators and types.
 */
/* eslint-disable @typescript-eslint/no-redeclare */
import { z } from 'zod';
import isValid from 'date-fns/isValid';
import parseJSON from 'date-fns/parseJSON';
import type { AckResult, AckResultCallback } from './typed-events';
import { Consensus, ConsensusKeys, ConsensusUpdatePayload } from '../multiplayer/schemas';

/**
 *
 * Schemas
 *
 */

/**
 * Schema for a string compatible with date-fns parseJSON that returns a Date.
 * (Includes most standard formats, including SQL dates.)
 *
 * See: https://date-fns.org/v2.25.0/docs/parseJSON
 */
export const DateString = z.string().transform(parseJSON).refine(isValid);

/**
 * Listen event params for `room:status`
 */
export const ClientErrorEvent = z
  .object({
    status: z.string(),
    code: z.string(),
    message: z.string(),
  })
  .partial({ code: true });

export type ClientErrorEvent = z.infer<typeof ClientErrorEvent>;

/**
 * Listen event params for `room:status`
 */
export const ClientEventRoomStatus = z.object({
  room: z.string().min(1),
  action: z.enum(['joined', 'left', 'who']),
  users: z.array(z.string()),
});

export type ClientEventRoomStatus = z.infer<typeof ClientEventRoomStatus>;

/**
 * Emit event params for `room:status`
 */
export const ClientEmitRoomStatus = z.object({
  room: z.string().min(1),
});
export type ClientEmitRoomStatus = z.infer<typeof ClientEmitRoomStatus>;

/**
 * Emit event params for `room:status`
 */
export const ClientEmitLoginUser = z
  .object({
    username: z.string(),
    key: z.string(),
    slug: z.string(),
  })
  .partial();
export type ClientEmitLoginUser = z.infer<typeof ClientEmitLoginUser>;

/**
 * Listen event parameters for 'status:user' event.
 * (Server-side type: {@link IOSocketDataSafe})
 */
export const ClientEventUserStatus = z.object({
  status: z.enum(['connected', 'logged-in']).nullable(),
  username: z.string().nullable(),
  events: z.array(z.string()),
  sessions: z.array(z.string()),
});
export type ClientEventUserStatus = z.infer<typeof ClientEventUserStatus>;

/**
 * Listen event params for `event:status`
 */
export const ClientEventStatus = z.object({
  room: z.string().nullable().optional(),
  need_for_update: z.boolean(),
  action: z.enum(['start', 'end']).nullable().optional(),
}).partial({ need_for_update: true });

export type ClientEventStatus = z.infer<typeof ClientEventStatus>;

/**
 *  Chat message
 */
export const ClientChatMessage = z.object({
  id: z.number(),
  room: z.string().nullable().optional(),
  username: z.string(),
  message: z.string().nullable(),
  user_type: z.enum(['player', 'facilitator']).nullable().optional(),
  message_type: z.enum(['message', 'info', 'broadcast']).nullable().optional(),
  info_type: z.enum(['info', 'success', 'warning', 'error']).nullable().optional(),
  time: DateString.nullable(),
});

export type ClientChatMessage = z.infer<typeof ClientChatMessage>;

/**
 *  User Info
 */
export const UserInfo = z.object({
  username: z.string(),
  display_name: z.string().optional(),
  requesting_help: z.boolean().optional(),
  session_slug: z.string().min(1).optional(),
  location: z.string().min(1).optional(),
  start_time: DateString.nullable().optional(),
  end_time: DateString.nullable().optional(),
});

export type UserInfo = z.infer<typeof UserInfo>;

export const UserInConsensus = z.object({
  key: ConsensusKeys,
  users: z.array(UserInfo),
});
export type UserInConsensus = z.infer<typeof UserInConsensus>;

/**
 *  Session Group Info
 */
export const SessionGroupInfo = z.object({
  slug: z.string().min(1),
  name: z.string(),
  event_slug: z.string().min(1),
  users: z.array(UserInfo),
  facilitators: z.array(z.string()).optional(),
});

export type SessionGroupInfo = z.infer<typeof SessionGroupInfo>;

export const ClientEventUserGameUpdateRequest = z.object({
  username: z.string(),
});
export type ClientEventUserGameUpdateRequest = z.infer<typeof ClientEventUserGameUpdateRequest>;

/**
 * Lead Info (from redis)
 */
 export const ClientEventStartLead = z.object({
  leadId: z.string(),
  ownerId: z.string(),
  status: z.enum(['started', 'abandoned', 'completed']),
  timestamp: z.number(),
});
export type ClientEventStartLead = z.infer<typeof ClientEventStartLead>;

export const ClientEventCompletedLead = z.object({
  leadId: z.string().or(z.array(z.string())),
});
export type ClientEventCompletedLead = z.infer<typeof ClientEventCompletedLead>;

export const LeadInfo = z.object({
  leadId: z.string(),
  ownerId: z.string(),
  status: z.enum(['started', 'abandoned', 'completed']),
  timestamp: z.number(),
}).partial({ status: true, timestamp: true });
export type LeadInfo = z.infer<typeof LeadInfo>;

export const ClientEventAbandonLead = z.object({
  ownerId: z.string(),
  leadId: z.string().or(z.array(z.string())),
  leadIds: z.array(z.string()),
}).partial();
export type ClientEventAbandonLead = z.infer<typeof ClientEventAbandonLead>;

export const ClientEventAbandonAll = z.object({
  username: z.string(),
});
export type ClientEventAbandonAll = z.infer<typeof ClientEventAbandonAll>;

/**
 * Event Record object (from Redis) for user login
 */
export const ClientEventRoomInfo = z.object({
  user: z
    .object({
      session_slug: z.string().nullable(),
      need_for_update: z.boolean(),
    })
    .partial(),
  event: z.object({
    event_id: z.number(),
    slug: z.string().min(1),
    catalog_code: z.string().nullable(),
    primary_language: z.string().nullable(),
    start_date: DateString,
    end_date: DateString,
    date_started: DateString.nullable(),
    date_ended: DateString.nullable(),
    total_users: z.number(),
    status: z.enum(['started', 'ended', 'scheduled', 'completed']).nullable().optional(),
  }),
});
export type ClientEventRoomInfo = z.infer<typeof ClientEventRoomInfo>;

export const EventInfo = z.object({
  event_id: z.number(),
  slug: z.string().min(1),
  catalog_code: z.string().nullable(),
  primary_language: z.string().nullable(),
  start_date: DateString,
  end_date: DateString,
  date_started: DateString.nullable(),
  date_ended: DateString.nullable(),
  total_users: z.number(),
  status: z.enum(['started', 'ended', 'scheduled', 'completed']).nullable().optional(),
});
export type EventInfo = z.infer<typeof EventInfo>;

/**
 * Event Record object (from Redis) for user authentication on handshake
 */
export const ClientEventUserAuth = z.object({
  user: z
    .object({
      session_slug: z.string().nullable(),
      need_for_update: z.boolean(),
    })
    .partial(),
  event: EventInfo,
});
export type ClientEventUserAuth = z.infer<typeof ClientEventRoomInfo>;

/**
 * Game update object
 */
export const AppliedDataEntity = z
  .object({
    /** The full GameData ID of the applied item. */
    id: z.string().min(1),
    /** Username of the collecting user. */
    user: z.string().nullable(),
    /** The timestamp in milliseconds when the item was applied. */
    date: z.number(),
    /** An optional identifier for the episode, phase, or other segment of the game when the item was applied. */
    phase: z.string().nullish(),
    /** applied entity type */
    type: z.enum(['card', 'clue', 'lead', 'question', 'log', 'activity']).nullable(),
    /** facts belong to applied clues */
    facts: z.array(z.string().min(1)).nullable(),
  })
  .partial({ facts: true });

export const ClientEventGameUpdate = z.object({
  room: z.string().nullish(),
  username: z.string().nullish(),
  applied_entities: z.array(AppliedDataEntity),
});

export type ClientEventGameUpdate = z.infer<typeof ClientEventGameUpdate>;

export const GameUpdate = z.object({
  room: z.string(),
  all_applied_entities: z.array(AppliedDataEntity),
  leads_info: z.array(ClientEventStartLead),
  current_phase_id: z.string(),
  consensuses: z.array(Consensus),
  scores: z.object({
    leadScores: z.any(),
    teamScores: z.any(),
  }),
}).partial();

export type GameUpdate = z.infer<typeof GameUpdate>;

export const UserData = z
  .object({
    location: z.string(),
    display_name: z.string(),
    requesting_help: z.boolean(),
    start_time: DateString.nullable(),
    end_time: DateString.nullable(),
  })
  .partial()
  .optional();

export type UserData = z.infer<typeof UserData>;
// export const UserLeadScore = z.object({ userId: z.string(), amount: z.number(), id: z.string(), attempt: z.number() });

export const ClientEventUpdateScores = z.object({
  leadScores: z.any(),
  teamScores: z.any(),
});

export type ClientEventUpdateScores = z.infer<typeof ClientEventUpdateScores>;
/**
 * Default value generator for StatusEventParameters (i.e. for Redux)
 */
export const getDefaultUserStatusEventParameters = (): ClientEventUserStatus => ({
  status: null,
  username: null,
  events: [],
  sessions: [],
});

export const ClientEventUpdateConsensus = z.object({
  vote: ConsensusUpdatePayload,
  activeUsers: z.array(z.string()),
});

export type ClientEventUpdateConsensus = z.infer<typeof ClientEventUpdateConsensus>;
/**
 *
 * Helper Methods
 *
 */

export const noopAckResultCallback: AckResultCallback = () => undefined;

/**
 * (Overload `1) Process raw event arguments and extract callback.
 *
 * @param {unknown[]} eventArgs - The raw arguments from the event (e.g. "rest" parameters)
 * @return {[unknown, AckResultCallback]} A tuple containing:
 *   `[0]ack`: An AckResultCallback (extracted from last argument, otherwise a no-op);
 *   `[1]args`: Unprocessed event parameter(s), if any (wrapped in an array if length > 1).
 */
export function processEventArgs<TResult extends AckResult = AckResult>(
  eventArgs: unknown[],
): [ack: AckResultCallback<TResult>, args: unknown | unknown[]];

/**
 * (Overload `2) Process and validate raw event arguments and extract callback.
 *
 * @param {unknown[]} eventArgs - The raw arguments from the event (e.g. "rest" parameters)
 * @param {z.Schema} schema - A parseable `Zod` schema object.
 * @return {[z.SafeParseReturnType, AckResultCallback]} A tuple containing:
 *   `[1]ack`: An `AckResultCallback` (extracted from last argument, otherwise a no-op);
 *   `[0]args`: A `z.SafeParseReturnType` result.
 */
export function processEventArgs<
  TResult extends AckResult = AckResult,
  Output = unknown,
  Def extends z.ZodTypeDef = z.ZodTypeDef,
  Input = Output,
>(
  eventArgs: unknown[],
  schema: z.Schema<Output, Def, Input>,
): [ack: AckResultCallback<TResult>, args: z.SafeParseReturnType<Input, Output>];

/**
 * Implementation of `processEventArgs()`
 */
export function processEventArgs<
  TResult extends AckResult = AckResult,
  Output = unknown,
  Def extends z.ZodTypeDef = z.ZodTypeDef,
  Input = Output,
>(
  eventArgs: unknown[],
  schema?: z.Schema<Output, Def, Input>,
): [ack: AckResultCallback<TResult>, args: unknown | unknown[] | z.SafeParseReturnType<Input, Output>] {
  // Clone the input array because we will probably mutate it.
  const args = [...eventArgs];

  // If last argument is a function, extract it as an AckResultCallback. Otherwise, provide a stub no-op).
  const ack = (
    typeof args.slice(-1)[0] === 'function' ? args.pop() : noopAckResultCallback
  ) as AckResultCallback<TResult>;

  // If 1 or fewer arguments remains, unwrap the array.
  const outArg = args.length <= 1 ? args[0] : args;

  // Parse arg(s) with schema or pass them through, and return tuple with callback.
  return [ack, schema == null ? outArg : schema.safeParse(outArg)];
}
