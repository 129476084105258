/**
 * i18next NS: `Episode01.Phase02Review`
 */
export default {
  dialog: {
    intro: {
      title: "Something’s come up.",
      body: "Ms. Lynch wants to see you. I think she needs your help with something unexpected.",
    },
  },
  question1: {
    title: "I know we’re throwing a lot at you.",
    body: "But I need you to stop what you’re doing and chase something down for me. I need you to create a chart that focuses on the profitability metric ONLY.",
    optionOne: "What’s going on?",
    optionTwo: "Yes, I can help.",
  },
  question2: {
    title: "I’m all about PRESERVERON’s benevolent mission.",
    body: "But in his zeal to change the world Mr. Trentini can occasionally lose sight of the bottom line. PRESERVERON will help no-one if we’re out of business.",
    optionOne: "Okay, I’m on it.",
    optionTwo: "Yikes.",
  },
  question3: {
    title: "Thank you.",
    body: "I don’t want you to lose sight of the important good PRESERVERON will do in the world. But I need a lieutenant who’s keeping an eye on our company’s bottom line.",
    optionOne: "All right.",
    optionTwo: "No problem.",
  },
  question4: {
    title: "So: I need the top 10 most profitable solutions,",
    body: "laid out in a bar chart. Put all other considerations aside for the moment. Get after it.",
    optionOne: "You can count on us.",
  },
};
