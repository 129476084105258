import { DefaultButton, IStackItemStyles, IStackStyles, ITheme, Stack, useTheme } from '@fluentui/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, P } from '../Text';
import Background from '../../../static/images/Game01/Episode01/backgrounds/screen-background-dark.jpg';
import Andrea from '../../../static/images/Game01/Episode01/characters/andrea/Episode_1_Andrea.png';
import { greyScale } from '../../../theme/Game01/colors';
import { MPManager } from '../../../lib/socket';

function outerStyles(theme: ITheme): IStackStyles {
  return {
    root: {
      backgroundColor: theme.palette.blueMid,
      backgroundImage: `url(${Background})`,
      backgroundSize: 'cover',
      color: theme.palette.themePrimary,
      height: '100vh',
      maxWidth: '100%',
      overflowY: 'auto',
    },
  };
}

function innerStyles(): IStackStyles {
  return {
    root: {
      width: '100%',
      height: '100%',
      margin: '0 auto',
      alignItems: 'start',
      justifyContent: 'space-evenly',
      boxSizing: 'border-box',
    },
  };
}

function leftStyles(theme: ITheme): IStackItemStyles {
  return {
    root: {
      backgroundColor: theme.palette.white,
      padding: 30,
      borderRadius: theme.effects.roundedCorner4,
      width: '40%',
      marginTop: 100,
      marginLeft: '10%',
      boxSizing: 'border-box',
    },
  };
}

function rightStyles(): IStackItemStyles {
  return {
    root: {
      height: '100%',
      minHeight: 500,
      width: '50%',
      overflow: 'hidden',
      marginLeft: '-50%',
      img: {
        display: 'block',
        height: '100%',
      },
    },
  };
}

function buttonStyle(theme: ITheme): IStackItemStyles {
  return {
    root: {
      float: 'right',
      backgroundColor: greyScale[7],
      color: theme.palette.white,
      boxSizing: 'border-box',
      padding: 25,
      marginTop: 25,
    },
  };
}

const Suspension: React.FC = () => {
  const { t } = useTranslation('common');
  const theme = useTheme();

  useEffect(() => {
    // tearing down here so the user location can update before disconnecting.
    MPManager.instance.teardownSocket(true);
  });

  return (
    <Stack styles={outerStyles(theme)}>
      <Stack horizontal styles={innerStyles()}>
        <Stack.Item styles={leftStyles(theme)}>
          <Heading level={2} block noMargin>{t('event.disconnected')}</Heading>
          <P level={3} block>{t('event.userDisconnected')}</P>
          <P level={3} block>{t('event.pleaseRefresh')}</P>
          <DefaultButton
            // eslint-disable-next-line no-restricted-globals
            onClick={() => location.reload()}
            text={t('event.refresh')}
            styles={buttonStyle(theme)}
          />
        </Stack.Item>
        <Stack.Item styles={rightStyles()}>
          <img src={Andrea} alt="" />
        </Stack.Item>
      </Stack>
    </Stack>
  );
};

export default Suspension;
