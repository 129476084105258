import { Memoize } from 'typescript-memoize';

import { GameDataBase } from './game-data-base';

import { ElementData } from './element-data';
import type { EpisodeData } from './episode-data';
import {
  ActivityDefinition,
  ActivityFlags,
  ActivityType,
  GameDataInstance,
  GameDataInstanceOf,
  GameDataKind,
} from './types';

/**
 * Lead Data
 */
export class ActivityData extends GameDataBase {
  readonly kind = 'activity';

  declare readonly parent: EpisodeData | undefined;

  readonly type: ActivityType;

  readonly flags: ReadonlySet<ActivityFlags>;

  readonly elements: readonly ElementData[] = [];

  readonly baseScore: number = 0;

  constructor(data: ActivityDefinition, parent?: EpisodeData) {
    super(data, parent);

    const { type, baseScore, flags, elements } = data;
    this.type = type ?? 'solo';
    this.flags = new Set(flags);
    this.baseScore = baseScore;

    // Parse evidence definitions
    if (Array.isArray(elements)) {
      this.elements = elements.map((def) => new ElementData(def, this));
    } else if (elements != null) {
      this.elements = Object.entries(elements).map(([key, def]) => new ElementData({ id: key, ...def }, this));
    }
    Object.freeze(this.elements);
  }

  getChildren(): readonly (ElementData)[];
  getChildren<TKind extends GameDataKind | undefined>(filter?: TKind): readonly GameDataInstanceOf<TKind>[];
  @Memoize()
  getChildren(filter?: GameDataKind): readonly GameDataInstance[] {
    switch (filter) {
      case undefined:
        return Object.freeze([...this.elements]);
      case 'element':
        return this.elements;
      default:
        return Object.freeze([]);
    }
  }

  @Memoize()
  getLocks(): readonly ElementData[] {
    return Object.freeze([
      ...this.elements.reduce<ElementData[]>((accumulator, element) => {
        if (element.type !== 'log') return accumulator;
        accumulator.push(...element.getUnlocks());
        return accumulator;
      }, []),
    ]);
  }

  /** Get associated PhaseData */
  getPhase() {
    return this.parent?.phases.find((phase) => phase.getActivities().includes(this));
  }
}
