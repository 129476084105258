import { ISpacing } from '@fluentui/react';

const spacing: Partial<ISpacing> = {
  // s2: '50px',
  // s1: '50px',
  // m: '50px',
  // l1: '50px',
  // l2: '50px',
};

export default spacing;
