import React from 'react';
import { useGameService } from '../../../state/GlobalStateProvider';
import Dialog from '../../Global/Dialog/Dialog';
import { ReviewTypeEnum } from '../../../lib/game-data/types';
import Button from '../../Global/Button/Button';
import { useAppSelector } from '../../../store';
import { selectCurrentPhase } from '../../../store/multiplayer-slice';

const NarrativeBreak = () => {
  const { send } = useGameService();
  const [currentReviewStep, setCurrentReviewStep] = React.useState(0);

  const currentPhase = useAppSelector(selectCurrentPhase);
  // Save phase-data as a ref so that we don't get a flash of undefined text when transitioning back to lead-selection
  const phaseData = React.useRef(currentPhase);
  const reviewLength = phaseData.current?.review.data.length;
  const nextQuestion = phaseData.current?.review.data[currentReviewStep];

  // Move to the next question/dialog or finish
  const handleNext = React.useCallback((picked = 0) => {
    // ensure the feedback modal is closed
    if (reviewLength && currentReviewStep < reviewLength - 1) {
      setCurrentReviewStep((val) => {
        let v = val + 1;
        if (nextQuestion && nextQuestion.answers && nextQuestion.answers[picked].target) {
          v = nextQuestion.answers[picked].target as number;
        }
        return v;
      });
    } else {
      send('DONE.QUESTION');
    }
  }, [nextQuestion, currentReviewStep, reviewLength, send]);

  React.useEffect(() => {
    if (!nextQuestion) {
      send('DONE.QUESTION');
    }
  }, [send, nextQuestion]);

  if (!nextQuestion) {
    return null;
  }

  const renderAnswers = () => {
    return nextQuestion.answers?.map(({ text }, index) => {
      const answerId = `answer_${index}`;

      return (
        <Button
          key={answerId}
          label={text}
          isPrimary
          onClick={() => handleNext(index)}
        />
      );
    });
  };

  // Deal with Dialog
  if (nextQuestion.type === ReviewTypeEnum.enum.Dialog) {
    return (
      <Dialog
        title={nextQuestion.title}
        body={nextQuestion.body}
        nextLabel={nextQuestion.nextLabel}
        onClick={handleNext}
        character={nextQuestion.character}
        background="office"
        useDark
      />
    );
  }

  // Deal with Questions
  if (nextQuestion.type === ReviewTypeEnum.enum.Question) {
    return (
      <Dialog
        title={nextQuestion.title}
        body={nextQuestion.body}
        onClick={() => {}}
        character={nextQuestion.character}
        background="office"
        disableSubmit
        hideNext
        useDark
      >
        {renderAnswers()}
      </Dialog>
    );
  }

  if (nextQuestion.type === ReviewTypeEnum.enum.MultipleSelect) {
    return (
      <Dialog
        title={nextQuestion.title}
        body={nextQuestion.body}
        onClick={handleNext}
        character={nextQuestion.character}
        background="office"
        disableSubmit
        hideNext
        useDark
      >
        {renderAnswers}
      </Dialog>
    );
  }

  return null;
};

export default NarrativeBreak;
