import * as React from 'react';
import { IStackItemStyles, IStackStyles, IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { mergeStyles } from '@fluentui/react';
import useActivityFocus from '../../../lib/hooks/useActivityFocus';
import Button from '../Button/Button';
import Screen from '../Screen/Screen';
import Panel from '../Panel/Panel';
import { Heading, P } from '../Text';
import useCharacter, { Characters } from '../../../lib/hooks/useCharacter';
import useBackground, { Backgrounds } from '../../../lib/hooks/useBackground';

interface Props {
  title: string;
  body?: string | JSX.Element;
  nextLabel?: string;
  onClick: () => void;
  useDark?: boolean;
  character?: Characters;
  secondCharacter?: Characters;
  background?: Backgrounds;
  hideNext?: boolean;
  disableSubmit?: boolean;
  customStyle?: IStackStyles;
  narrative?: boolean;
}

const interstitialWrapper: IStackStyles = {
  root: {
    'top': 500,
    'backgroundColor': 'white',
    'borderRadius': 6,
    'position': 'absolute',
    'zIndex': 2,
    '@media(min-width: 1040px)': {
      top: 50,
      maxWidth: 500,
    },
    '@media(min-width: 1240px)': {
      top: 150,
      maxWidth: 667,
    },
    '@media(min-width: 1650px)': {
      top: 150,
      maxWidth: 840,
    },
  },
};

const interstitialTokens: IStackTokens = {
  padding: 48,
  childrenGap: 32,
};

const itemStyles: IStackItemStyles = {
  root: {},
};

const oneCharacterWrapper: IStackStyles = {
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1,
  },
};

const twoCharacterWrapper: IStackStyles = {
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    position: 'absolute',
    justifyContent: 'space-between',
    alignContent: 'space-between',
    flexDirection: 'row',
    top: 0,
    right: 0,
    zIndex: 1,
  },
};

const characterImageStyles = mergeStyles({
  'width': 900,
  'marginRight': -100,
  'backgroundSize': 'cover',
  '@media(min-width: 1020px)': {
    width: 900,
    marginRight: -200,
  },
  '@media(min-width: 1440px)': {
    width: 1000,
    marginRight: -100,
  },
  '@media(min-width: 1650px)': {
    width: 1000,
    marginRight: 0,
  },
  '@media(min-width: 1920px)': {
    width: 1200,
  },
});

const firstCharacterImageStyles = mergeStyles({
  'width': 300,

  'backgroundSize': 'cover',
  '@media(min-width: 1020px)': {
    width: 300,
  },
  '@media(min-width: 1440px)': {
    width: 500,
  },
  '@media(min-width: 1650px)': {
    width: 500,
  },
  '@media(min-width: 1920px)': {
    width: 500,
  },
});

const secondCharacterImageStyles = mergeStyles({
  'width': 300,

  'backgroundSize': 'cover',
  '@media(min-width: 1020px)': {
    width: 300,
  },
  '@media(min-width: 1440px)': {
    width: 500,
  },
  '@media(min-width: 1650px)': {
    width: 500,
  },
  '@media(min-width: 1920px)': {
    width: 500,
  },
});

const childrenStackTokens: IStackTokens = {
  childrenGap: 24,
};

export default function Dialog({
  title,
  body,
  nextLabel = 'Next',
  onClick,
  useDark = false,
  character,
  secondCharacter,
  background,
  children,
  hideNext = false,
  disableSubmit = false,
  customStyle,
  narrative = false,
}: React.PropsWithChildren<Props>): JSX.Element {
  const { focusRef } = useActivityFocus();

  const characterImage = useCharacter(character);
  const secondCharacterImage = useCharacter(secondCharacter);
  const backgroundImage = useBackground(background);

  return (
    <Screen backgroundImage={backgroundImage} cover={!!backgroundImage}>
      <Panel useDark={useDark} fullscreen centerIt={!!secondCharacterImage || !characterImage}>
        {!!characterImage && !secondCharacterImage && (
          <Stack styles={oneCharacterWrapper} horizontalAlign="end">
            <img src={characterImage} alt="Character" className={characterImageStyles} />
          </Stack>
        )}
        {!!secondCharacterImage && (
          <Stack styles={twoCharacterWrapper} horizontalAlign="end">
            <img src={characterImage} alt="Character" className={firstCharacterImageStyles} />
            <img src={secondCharacterImage} alt="Second Character" className={secondCharacterImageStyles} />
          </Stack>
        )}

        <Stack styles={interstitialWrapper} tokens={interstitialTokens}>
          {title && (
            <Stack.Item styles={itemStyles}>
              {/* eslint-disable jsx-a11y/no-noninteractive-tabindex */}
              <span ref={focusRef} tabIndex={-1}>
                <Heading level={1} block noMargin>
                  {title}
                </Heading>
              </span>
            </Stack.Item>
          )}

          <Stack.Item styles={itemStyles}>
            {body && !narrative && (
              <P level={2} block>
                {body}
              </P>
            )}
          </Stack.Item>
          {children && (
            <Stack styles={customStyle || undefined} tokens={childrenStackTokens}>
              {children}
            </Stack>
          )}

          {!hideNext && (
            <Stack.Item styles={itemStyles}>
              <Button isPrimary label={nextLabel} onClick={onClick} disabled={disableSubmit} />
            </Stack.Item>
          )}
        </Stack>
      </Panel>
    </Screen>
  );
}
