import React, { forwardRef, PropsWithChildren, useEffect, useImperativeHandle, useRef } from 'react';
import { IStackItemStyles, IStackItemTokens, IStackTokens, Stack } from '@fluentui/react';
import _debounce from 'lodash/debounce';

const stackTokens: IStackTokens | IStackItemTokens = {
  padding: 0,
};

const baseWidth = 1920;
const baseHeight = 1080;
const ratio = baseWidth / baseHeight;
const frameMaxWidth = `${baseWidth}px`;
const frameMaxHeight = `min(${baseHeight}px, 100vh)`;
const aspectRatioTopPadding = `${(1 / ratio) * 100}%`;
const aspectRatioWidth = `min(100%, ${ratio * 100}vh)`;

const stackItemStyles: IStackItemStyles = {
  root: {
    maxWidth: frameMaxWidth,
    maxHeight: frameMaxHeight,
    overflow: 'hidden',
  },
};

const aspectRatioDivBaseStyles: React.CSSProperties = {
  position: 'relative',
  padding: `${aspectRatioTopPadding} 0 0 0`,
  width: aspectRatioWidth,
  margin: 'auto',
};

const iFrameStyles: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  border: 0,
  padding: 0,
  width: '100%',
  height: '100%',
  maxHeight: frameMaxHeight,
};

export const labFrameStyles = Object.freeze({
  stackTokens,
  stackItemStyles,
  aspectRatioDivBaseStyles,
  iFrameStyles,
});

export type LabFrameOnMessage<T = any> = (message: MessageEvent<T>, frameWindow?: Window | null) => void;

export interface LabFrameProps {
  id?: string;
  src: string;
  title: string;
  sectionIndex?: number;
  screenIndex?: number;
  onMessage?: LabFrameOnMessage;
}

export interface LabFrameRef {
  frameWindow: Window | null | undefined;
  postMessage: (event: any) => void;
}

const debouncedPostMetadata = _debounce(
  (frameWindow: Window) => {
    frameWindow.postMessage({ action: 'tsgs.Metadata' }, '*');
  },
  0,
  { maxWait: 33 },
);

/**
 *
 */
const LabFrame = forwardRef<LabFrameRef, LabFrameProps>((props: PropsWithChildren<LabFrameProps>, ref) => {
  // eslint-plugin-react is firing a false-positive here
  // eslint-disable-next-line react/prop-types
  const { id, src, title, sectionIndex, screenIndex, onMessage } = props;
  const frameRef = useRef<HTMLIFrameElement>(null);

  // Expose iframe interface to parent ref.
  useImperativeHandle(
    ref,
    () => {
      const frameWindow = frameRef.current?.contentWindow;
      return {
        frameWindow,
        postMessage: (message: any) => {
          if (frameWindow != null) {
            frameWindow.postMessage(message, '*');
          }
        },
      };
    },
    [frameRef],
  );

  // Listen for and handle `message` events from the iframe
  useEffect(() => {
    const handleMessage = (message: MessageEvent) => {
      const frameWindow = frameRef.current?.contentWindow;

      // Ignore messages not passed by this component's ifrae.
      if (frameWindow == null || message.source !== frameWindow) {
        return;
      }

      // Trigger callback
      if (typeof onMessage === 'function') {
        onMessage(message, frameWindow);
      }

      // If event was _not_ a metadata event, trigger one.
      if (message.data?.action !== 'tsgs.Metadata') {
        debouncedPostMetadata(frameWindow);
      }
    };

    // Add/remove listeners.
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [frameRef, onMessage]);

  // Listen for `keydown` events while a lab is open
  // NOTE: This can not capture events pressed when the lab is focused due to cross-origin frame security.
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (typeof onMessage !== 'function' || event.isComposing || !event.altKey) {
        return;
      }

      if (event.code === 'Backslash') {
        onMessage(new MessageEvent('message', { data: { action: 'ESCAPE' } }), null);
      }
    };

    // Add/remove listeners.
    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [onMessage]);

  // Iframe source load/restore.
  useEffect(() => {
    const contentWindow = frameRef.current?.contentWindow;
    if (contentWindow != null) {
      const srcURL = new URL(src);
      srcURL.hash = `#/${sectionIndex ?? 0}/${screenIndex ?? 0}`;
      contentWindow.location.replace(srcURL);
    }
  }, [src, sectionIndex, screenIndex]);

  return (
    <Stack grow={1} horizontal horizontalAlign="center" tokens={stackTokens} verticalAlign="start" verticalFill>
      <Stack.Item grow={1} styles={stackItemStyles} tokens={stackTokens}>
        <div className="aspect-ratio-box" style={aspectRatioDivBaseStyles}>
          <iframe
            id={id}
            className="lab-frame"
            ref={frameRef}
            title={title}
            src="about:blank"
            style={iFrameStyles}
            sandbox="allow-scripts allow-popups"
          />
        </div>
      </Stack.Item>
    </Stack>
  );
});

export default LabFrame;
