import React, { useEffect, useRef } from 'react';
import { IStackStyles, Stack } from '@fluentui/react';
import { ClientChatMessage, UserInfo } from '../../../lib/socket/schemas';
import ChatMessage from './ChatMessage';

interface IChatDisplayProps {
  users: UserInfo[];
  messages: ClientChatMessage[];
}

function bodyStyles(): IStackStyles {
  return {
    root: {
      padding: '18px 18px 8px',
    },
  };
}

const ChatDisplay: React.FC<IChatDisplayProps> = ({ users = [], messages = [] }) => {
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <Stack.Item grow styles={bodyStyles()}>
      {messages.map((m, i) => (
        <ChatMessage
          key={`cht_m_${m.id}_${i}`}
          user={users.find((u) => u.username === m.username)}
          message={m}
        />
      ))}
      <div ref={messagesEndRef} />
    </Stack.Item>
  );
};

export default ChatDisplay;
