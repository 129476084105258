import React from 'react';
import { Panel, PanelType } from '@fluentui/react';

import type { IPanel, IPanelProps, IPanelStyleProps, IPanelStyles, IStyleFunction, IRefObject } from '@fluentui/react';
import type { TFunction } from 'i18next';
import { useBoolean } from '@fluentui/react-hooks';

export interface IMapPanelProps {
  componentRef?: IRefObject<IMapPanel>;
  t: TFunction;
  layerHostId?: string;
}

export interface IMapPanel extends IPanel {
  toggle: IPanel['dismiss'];
  isOpen: boolean;
}

const panelStyles: IStyleFunction<IPanelStyleProps, IPanelStyles> = ({ isOpen, isAnimating, isHiddenOnDismiss }) => ({
  scrollableContent: {
    minHeight: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  commands: {
    display: 'none',
  },
  content: {
    padding: '0',
    flex: '1 0 auto',
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  // Need to add `opacity: 0` to panel hide state to prevent weird content flash on close.
  hiddenPanel: [
    !isOpen &&
      !isAnimating &&
      isHiddenOnDismiss && {
        visibility: 'hidden',
        opacity: 0,
        display: 'none',
      },
  ],
});

/**
 * The FluentUI Panel component handling the Evidence Map.
 */
const MapPanel: React.FC<IMapPanelProps> = ({ componentRef, layerHostId, t, children }) => {
  const [isOpen, { setTrue: setIsOpenTrue, setFalse: setIsOpenFalse, toggle: toggleIsOpen }] = useBoolean(false);
  const panelRef = React.useRef<IPanel>(null);

  React.useImperativeHandle(componentRef, () => ({
    open: () => setIsOpenTrue(),
    dismiss: () => setIsOpenFalse(),
    toggle: () => toggleIsOpen(),
    isOpen,
  }));

  const dismissHandler = React.useCallback<NonNullable<IPanelProps['onDismiss']>>(
    () => {
      setIsOpenFalse();
    },
    [setIsOpenFalse],
  );

  return (
    <Panel
      componentRef={panelRef}
      closeButtonAriaLabel={t('evidenceMap.closeButton')}
      hasCloseButton
      isBlocking
      isHiddenOnDismiss
      isOpen={isOpen}
      layerProps={{ hostId: layerHostId }}
      onDismiss={dismissHandler}
      type={PanelType.smallFluid}
      styles={panelStyles}
      onOuterClick={() => {}}
    >
      {children}
    </Panel>
  );
};

export default MapPanel;
