import React from 'react';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { IStackStyles, IStackTokens, mergeStyles, Stack, Theme } from '@fluentui/react';
import Button from '../../Global/Button/Button';
import { Heading, P } from '../../Global/Text';
import { getCurrentGameTheme } from '../../../theme';
import { gradients } from '../../../theme/Game02/colors';
import { useGameService } from '../../../state/GlobalStateProvider';
import { selectAllAppliedActivities } from '../../../store/multiplayer-slice/applied-data';
import Background from '../../../static/images/Game01/Episode01/characters/andrea/andrea_full_large.png';
import {
  emitStartLead, selectCurrentPhase, applyData,
} from '../../../store/multiplayer-slice';
import PlayerPersonaLive from '../../Global/PlayerPersona/PlayerPersonaLive';
import { ActivityData } from '../../../lib/game-data/activity-data';
import { useAppSelector } from '../../../store';
import { AppliedDataEntity } from '../../../lib/multiplayer/schemas';

export interface IActivityPreviewPaneProps {
  activity?: ActivityData;
  isComplete: boolean;
  phaseId?: string;
  handlePreview: () => void;
}

const leadPaneStackStyles = (theme: Theme): IStackStyles => ({
  root: {
    'position': 'relative',
    'background': gradients.primaryLight,
    'borderRadius': '6px',
    'overflow': 'hidden',
    'boxShadow': theme.effects?.elevation8,
    'maxWidth': 916,
    'minHeight': 700,
    '@media screen and (max-width: 1520px)': {
      maxWidth: 550,
    },
  },
});

const leadPaneStackTokens: IStackTokens = {
  // childrenGap: '8px',
  padding: '0 32px 32px 32px',
};

const leadContentStyles: IStackStyles = {
  root: {
    'maxWidth': 450,
    'position': 'relative',
    'zIndex': 2,
    'marginLeft': 350,
    '@media screen and (max-width: 1520px)': {
      marginLeft: 0,
      maxWidth: '100%',
    },
  },
};

const contentFooterStyles: IStackStyles = {
  root: {
    paddingTop: 24,
  },
};

const characterStyles = mergeStyles({
  'position': 'absolute',
  'top': 0,
  'left': 0,
  'backgroundImage': `url(${Background})`,
  'backgroundRepeat': 'no-repeat',
  'backgroundPosition': '-110px bottom',
  'backgroundSize': '130%',
  'height': '100%',
  'width': 380,
  'zIndex': 1,
  '@media screen and (max-width: 1520px)': { display: 'none' },
});

const ActivityPreviewPane: React.FC<IActivityPreviewPaneProps> = ({ activity, handlePreview }) => {
  const { send } = useGameService();
  const { t } = useTranslation(['entries', 'leadSelection']);
  const theme = getCurrentGameTheme();
  const startedActivities = useAppSelector((state) =>
    state.multiplayer.leads.find((startedActivity) => activity && startedActivity.leadId === activity.id),
  );
  const username = useAppSelector((state) => state.user.username);
  const dispatch = useDispatch();

  const appliedActivity = useAppSelector((state) => {
    if (activity == null) {
      return undefined;
    }
    return selectAllAppliedActivities(state).find(({ id }) => id === activity.id);
  });

  const currentPhase = useAppSelector(selectCurrentPhase);

  if (activity == null) {
    return (
      <>
      </>
    );
  }

  const startLabel = activity.flags.has('protection') ? 'protection' : 'investigation';
  let beginButton = <Stack />;

  // creating the begin investigation button
  if (appliedActivity != null) {
    beginButton = (
      <Stack tokens={{ childrenGap: 6 }}>
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 6 }}>
          <Stack.Item>{appliedActivity.user && <PlayerPersonaLive username={appliedActivity.user} />}</Stack.Item>
          <Stack.Item>
            <P level={3}>{t('completedBy')}</P>
          </Stack.Item>
        </Stack>
      </Stack>
    );
  } else if (startedActivities && startedActivities.ownerId !== username && startedActivities.status !== 'abandoned') {
    beginButton = (
      <Stack tokens={{ childrenGap: 6 }}>
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 6 }}>
          <Stack.Item>
            <PlayerPersonaLive username={startedActivities.ownerId} />
          </Stack.Item>
          <Stack.Item>
            <P level={3}>{t('startedBy')}</P>
          </Stack.Item>
        </Stack>
      </Stack>
    );
  } else {
    beginButton = (
      <Stack verticalAlign="center" tokens={{ childrenGap: 6 }}>
        <Button
          isPrimary
          label={t(`common.start.${startLabel}`)}
          onClick={
            // eslint-disable-next-line no-confusing-arrow
            () => {
              handlePreview();
              const appliedData: AppliedDataEntity[] = activity.elements.map((element) => {
                return {
                  id: element.id,
                  type: 'log',
                  user: username,
                  date: Date.now(),
                  phase: currentPhase?.id,
                  tries: 1,
                };
              });
              dispatch(applyData(appliedData));
              return startedActivities && startedActivities.ownerId === username && startedActivities.status === 'started'
                ? send({ type: 'CHOOSE.LEAD', leadId: activity.id })
                : dispatch(emitStartLead(activity.id));
            }
            // eslint-disable-next-line react/jsx-curly-newline
          }
        />
      </Stack>
    );
  }

  return (
    <Stack grow={1} styles={leadPaneStackStyles(theme)} horizontalAlign="end">
      <div className={characterStyles} />
      <Stack styles={leadContentStyles} verticalFill tokens={leadPaneStackTokens}>
        <Stack.Item>
          <Heading level={1} block>
            {t(`${activity.id}.header`)}
          </Heading>
        </Stack.Item>
        <Stack.Item>
          <Heading level={4} block noMargin>
            {t(`common.activityType.${activity.type}`)}
          </Heading>
          <Heading level={4} block noMargin>
            {t('common.leadPreview.role')} {t(`${activity.id}.role`)}
          </Heading>
          <Heading level={4} block noMargin>
            {t('common.leadPreview.expectedTime')} {t(`${activity.id}.time`)}
          </Heading>
        </Stack.Item>

        <Stack.Item>
          <P level={3} block>
            {t('common.leadPreview.brief')}
          </P>
          <P level={3}>
            <Trans t={t} i18nKey={`${activity.id}.brief`} />
          </P>
        </Stack.Item>
        <Stack
          styles={contentFooterStyles}
          horizontalAlign="end"
          verticalFill
          verticalAlign="end"
          tokens={{ childrenGap: 24 }}
        >
          {beginButton}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ActivityPreviewPane;
