import { IStackStyles, IStackTokens, Stack } from '@fluentui/react';
import * as React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import SwiperCore, { Navigation, A11y, Keyboard } from 'swiper';
import { Swiper as ISwiper } from 'swiper/types';

import CardCarouselCard from './CardCarouselCard';

import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import { getCurrentGameTheme } from '../../../theme';

SwiperCore.use([Navigation, A11y, Keyboard]);

const a11yOptions = {
  enabled: true,
  containerMessage: 'Container Message',
};

interface Props {
  cardIds: string[];
  onSelectCard: (cardId: string) => void;
  selectedCardId?: string;
  onSwiper?: (s: ISwiper) => void;
}

const theme = getCurrentGameTheme('Game01');

const contentStackStyles: IStackStyles = {
  root: {
    backgroundColor: '#E8E0D1', // Todo: Add to palette
    border: '3px solid #8D8678',
    borderRadius: theme.effects.roundedCorner6,
    width: '100%',
  },
};

const contentStackTokens: IStackTokens = {
  childrenGap: 10,
};

export default function CardCarousel({ cardIds, onSelectCard, selectedCardId, onSwiper = () => {} }: Props) {
  return (
    <Stack horizontal horizontalAlign="start" styles={contentStackStyles} tokens={contentStackTokens}>
      <Swiper
        id="CardCarousel"
        tag="section"
        wrapperTag="ul"
        spaceBetween={8}
        slidesPerView={5}
        simulateTouch={false}
        initialSlide={cardIds.findIndex((cid) => cid === selectedCardId)}
        // onSlideChange={(swiper) => console.log('slide change', swiper)}
        // onInit={(swiper) => console.log(swiper)}
        // onReachEnd={(swiper) => console.log(swiper)}
        onSwiper={onSwiper}
        navigation
        keyboard
        a11y={a11yOptions}
        centerInsufficientSlides
        focusableElements="button"
        slidesOffsetAfter={100}
        slidesOffsetBefore={30}
        breakpoints={{
          1920: {
            slidesPerView: 7,
          },
        }}
      >
        {cardIds.map((cardId) => (
          <SwiperSlide key={cardId}>
            <CardCarouselCard
              title={cardId}
              onClick={() => onSelectCard(cardId)}
              isSelected={selectedCardId === cardId}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Stack>
  );
}
